//@flow
import * as React from "react";
import ShareSectionSkeleton from "../ShareSectionSkeleton";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreModal,
  UICoreButton
} from "../../../../Component/UICore";
import { Helmet } from "react-helmet";
import ReactDOM from "react-dom";
import { logFeatureEvent } from "../../../../Library/Logger";
import "./style.css";
type Props = {|
  linkInfo: {| name: string, id: string |}
|};
type State = {|
  embedButtonText: string,
  showModal: boolean,
  copied: boolean
|};

const embedButtonPluginURL =
  "https://dashboard.wispform.com/plugin/buttonPopup/index.js";

const kVersion = 1;

class EmbedButtonSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _embed: ?HTMLInputElement;

  state = {
    embedButtonText: "Get started",
    showModal: false,
    copied: false
  };

  componentDidMount() {
    this._renderPluginFrame();
    logFeatureEvent("share/embedButtonSectionDisplayed");
  }

  componentDidUpdate() {
    this._updatePluginFrame();
  }

  _updatePluginFrame = () => {
    const iframe = document.getElementsByClassName(
      "Share-WispformPluginFrame"
    )[0];
    if (!iframe) return;
    if (!(iframe instanceof HTMLIFrameElement)) return;
    const frameDocument = iframe.contentWindow.document;
    const button = frameDocument.getElementById("wispformPlugin-buttonPopup");
    if (!button) return;
    button.innerText = this.state.embedButtonText;
  };

  _renderPluginFrame = () => {
    let iframe = document.getElementsByClassName(
      "Share-WispformPluginFrame"
    )[0];
    if (!(iframe instanceof HTMLIFrameElement)) return;
    let iframeBody = iframe.contentWindow.document.body;
    let el = iframe.contentWindow.document.createElement("div");
    iframeBody.appendChild(el);
    var script = iframe.contentWindow.document.createElement("script");
    script.type = "text/javascript";
    script.src = embedButtonPluginURL;
    iframeBody.appendChild(script);
    ReactDOM.render(
      <div
        className="Share-embedBrowserButtonPlugin"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
      >
        <div
          id="wispformPlugin-buttonPopup"
          data-name={this.props.linkInfo.name}
          data-id={this.props.linkInfo.id}
        >
          {this.state.embedButtonText}
        </div>
      </div>,
      iframeBody.querySelector("div")
    );
  };

  _getEmbedButtonPluginScript = () => {
    return (
      <Helmet
        script={[
          {
            src: embedButtonPluginURL
          }
        ]}
      />
    );
  };

  _handleCopyButtonClick = (e: SyntheticEvent<>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this._embed && this._embed.select) {
      this._embed.select();
    }
    try {
      document.execCommand("copy");
      this._embed && this._embed.blur();
      logFeatureEvent("share/embedButtonCodeCopied");
      setTimeout(() => {
        this.setState({
          copied: true
        });
      }, 300);
    } catch (err) {
      console.error("cant execute copy command");
    }
  };

  render() {
    return [
      <ShareSectionSkeleton
        settingColumn={
          <UICoreBox paddingLeft="sm" paddingRight="sm">
            <UICoreBox name="buttonText">
              <UICoreText weight="bold">Button text</UICoreText>
              <UICoreBox marginTop="xm">
                <UICoreInput
                  onChange={(e, value) => {
                    this.setState({ embedButtonText: value });
                  }}
                  value={this.state.embedButtonText}
                />
              </UICoreBox>
            </UICoreBox>
            <UICoreBox marginTop="md">
              <UICoreButton
                onClick={() => {
                  this.setState({ showModal: true });
                  logFeatureEvent("share/embedButtonGetCodeButtonClicked");
                }}
                size="md"
              >
                Get Code
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        }
        browserView={
          <UICoreBox width="100%" height="100%">
            <iframe
              style={{ width: "100%", height: "100%", borderWidth: "0px" }}
              className="Share-WispformPluginFrame"
            />
          </UICoreBox>
        }
      />,
      this.state.showModal && (
        <UICoreModal
          size="md"
          onDismiss={() => this.setState({ showModal: false })}
          header="Embed button code"
          body={
            <UICoreBox padding="md">
              <UICoreBox marginBottom="sm">
                <UICoreText overflow="wrap" color="black">
                  Copy and paste this code in your HTML's page where you want to
                  display this button.
                </UICoreText>
              </UICoreBox>
              <textarea
                ref={e => {
                  //$FlowFixMe
                  this._embed = e;
                }}
                autoSelect={true}
                // key={`${this.state.embedHeight}+${this.state.embedWidth}+${
                //   this.state.embedUnit.value
                // }`}
                className="Share-Embed"
                onCopy={() => logFeatureEvent("share/embedButtonCodeCopied")}
              >
                {(() => `<script defer src="https://dashboard.wispform.com/plugin/buttonPopup/index.js?v=${kVersion}"></script>

<div class="wispformPlugin-buttonPopup"
     data-name="${this.props.linkInfo.name}" data-id="${this.props.linkInfo.id}">
    Feedback
</div>`)()}
              </textarea>
              <UICoreBox marginTop="sm">
                <UICoreButton
                  color="lightBlue"
                  key={String(this.state.copied)}
                  onClick={this._handleCopyButtonClick}
                  size="md"
                >
                  {this.state.copied ? "Copied" : "Copy"}
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    ];
  }
}
export default EmbedButtonSection;
