import { serverAddress } from "../../../config";
import {
  getRequest,
  patchRequest,
  postRequest,
  deleteRequest
} from "../../../Library/Request";
import { logErrorInfo } from "../../../Helper_HOC/WithErrorBoundary";
import { safeGet } from "../../../Library/Util";
import { showNotification } from "../../WispTable/State/NotificationState/action";

export const SORT_RESPONSES = "SORT_RESPONSES";
export const FILTER_RESPONSES = "FILTER_RESPONSES";
export const WILL_FETCH_QUESTIONS = "WILL_FETCH_QUESTIONS";
export const DID_FETCH_QUESTIONS = "DID_FETCH_QUESTIONS";
export const WILL_FETCH_RESPONSES = "WILL_FETCH_RESPONSES";
export const DID_FETCH_RESPONSES = "DID_FETCH_RESPONSES";
export const DID_FETCH_EXPORTS = "DID_FETCH_EXPORTS";
export const WILL_FETCH_RESPONSE_META_DATA = "WILL_FETCH_RESPONSE_META_DATA";
export const WILL_FETCH_VIEW_RESPONSE_GRAPH_DATA =
  "WILL_FETCH_VIEW_RESPONSE_GRAPH_DATA";
export const WILL_FETCH_INSIGHT = "WILL_FETCH_INSIGHT";
export const SET_HIGHLIGHTED_RESPONSES = "SET_HIGHLIGHTED_RESPONSES";
export const DID_FETCH_RESPONSE_META_DATA = "DID_FETCH_RESPONSE_META_DATA";
export const DID_FETCH_VIEW_RESPONSE_GRAPH_DATA =
  "DID_FETCH_VIEW_RESPONSE_GRAPH_DATA";
export const DID_FETCH_INSIGHT = "DID_FETCH_INSIGHT";
export const DID_DELETE_SELECTED_RESPONSES = "DID_DELETE_SELECTED_RESPONSES";
export const DID_EXPORT_RESPONSES = "DID_EXPORT_RESPONSES";
export const DID_EXPORT_PDF = "DID_EXPORT_PDF";
export const CLEAR_RESPONSES = "CLEAR_RESPONSES";

/*




	onClickingSendEmails: (Array<string>, string, string) => mixed
	*/

export type sortDirectionType = "up" | "down";
export function sortResponses(
  question_id: string,
  direction: sortDirectionType
) {
  return {
    type: SORT_RESPONSES,
    question_id,
    direction
  };
}

export type filterParameter = {|
  keyword: string,
  question: string
|};

export function filterResponses(
  filter_parameter: Array<filterParameter>
): mixed {
  return {
    type: FILTER_RESPONSES,
    filter_parameter
  };
}

export function willFetchQuestions() {
  return {
    type: WILL_FETCH_QUESTIONS
  };
}

export function didFetchQuestions(questions) {
  return {
    type: DID_FETCH_QUESTIONS,
    questions
  };
}

export function willFetchResponses() {
  return {
    type: WILL_FETCH_RESPONSES
  };
}

export function clearResponses() {
  return {
    type: CLEAR_RESPONSES
  };
}

export function didFetchResponses(
  responses,
  current_page,
  next_page,
  total_pages
) {
  return {
    type: DID_FETCH_RESPONSES,
    responses,
    current_page,
    next_page,
    total_pages
  };
}

export function didFetchExports(exports) {
  return {
    type: DID_FETCH_EXPORTS,
    exports
  };
}

export function willFetchResponseMetaData() {
  return {
    type: WILL_FETCH_RESPONSE_META_DATA
  };
}

export function willFetchViewResponseGraphData() {
  return {
    type: WILL_FETCH_VIEW_RESPONSE_GRAPH_DATA
  };
}

export function willFetchInsight() {
  return {
    type: WILL_FETCH_INSIGHT
  };
}

export function setHighlightedResponses(highlighted_response) {
  return {
    type: SET_HIGHLIGHTED_RESPONSES,
    highlighted_response
  };
}

export function didFetchResponseMetaData(responseMetaData) {
  return {
    type: DID_FETCH_RESPONSE_META_DATA,
    responseMetaData
  };
}

export function didFetchViewResponseGraphData(viewResponseGraphData) {
  return {
    type: DID_FETCH_VIEW_RESPONSE_GRAPH_DATA,
    viewResponseGraphData
  };
}

export function didFetchInsight(insight) {
  return {
    type: DID_FETCH_INSIGHT,
    insight
  };
}

export function didDeleteSelectedResponses(deletedResponses) {
  return {
    type: DID_DELETE_SELECTED_RESPONSES,
    deletedResponses
  };
}

// Export to excel
export function didExportResponses(response) {
  return {
    type: DID_EXPORT_RESPONSES,
    response
  };
}

// Export to PDF
export function didExportPdf(response) {
  return {
    type: DID_EXPORT_PDF,
    response
  };
}

export function fetchQuestions(form_id) {
  return function (dispatch) {
    dispatch(willFetchQuestions());
    getRequest("api/v1/forms/" + form_id + "/question_details")
      .then(response => {
        dispatch(
          didFetchQuestions(response.data.data.question_details.questions)
        );
      })
      .catch(function (error) {});
  };
}

async function _synchrouslyFetchPaginatedResponses(
  form_id,
  maxRequests,
  startPage
) {
  let responses = [];
  let nextPage = null;
  let totalPages = null;
  let currentPage = null;
  let currentRequestNumber = 0;
  async function fetchPaginatedResponses(page) {
    currentRequestNumber++;
    let response = await getRequest("api/v1/forms/" + form_id + "/responses", {
      page: page,
      pageSize: _isOverView() ? 1000 : 100
    });
    responses = [...responses, ...response.data.data.responses];
    nextPage = response.data.data.next_page;
    currentPage = response.data.data.current_page;
    totalPages = response.data.data.total_pages;
    if (
      nextPage &&
      nextPage > page &&
      currentRequestNumber < maxRequests &&
      // When switching to other pages, we should immediately stop
      _isStillInResultPage()
    ) {
      await fetchPaginatedResponses(nextPage);
    } else {
      return;
    }
  }

  await fetchPaginatedResponses(startPage);
  return [responses, currentPage, nextPage, totalPages];
}

function _isOverView() {
  const url = String(safeGet(() => window.location.href));
  return url.includes("overview");
}

function _isStillInResultPage() {
  const url = String(safeGet(() => window.location.href));
  return url.includes("result");
}

function _getMaxPage() {
  if (_isOverView()) {
    return 50;
  } else {
    return 1;
  }
}

export function fetchExports(form_id) {
  return function (dispatch) {
    getRequest("api/v1/cloud_files?form_id=" + form_id)
      .then(response => {
        dispatch(didFetchExports(response.data.data.cloud_files));
      })
      .catch(function (error) {});
  };
}

export function fetchResponses(form_id, page) {
  let responses = [];
  return async function (dispatch) {
    dispatch(willFetchResponses());
    let responses = [];
    let results = [];
    let current_page = null;
    let next_page = null;
    let total_pages = null;
    try {
      results = await _synchrouslyFetchPaginatedResponses(
        form_id,
        _getMaxPage(),
        page
      );
      responses = results[0];
      current_page = results[1];
      next_page = results[2];
      total_pages = results[3];
    } catch (error) {
      logErrorInfo(String(error), "[result page]: error fetching responses");
    }
    dispatch(
      didFetchResponses(responses, current_page, next_page, total_pages)
    );
  };
}

export function fetchResponseMetaData(form_id) {
  return function (dispatch) {
    dispatch(willFetchResponseMetaData());
    getRequest("api/v1/forms/" + form_id + "/response_meta_data")
      .then(response => {
        dispatch(
          didFetchResponseMetaData(response.data.data.response_meta_datum)
        );
      })
      .catch(function (error) {});
  };
}

export function fetchViewResponseGraphData(form_id) {
  return function (dispatch) {
    dispatch(willFetchViewResponseGraphData());
    getRequest("api/v1/forms/" + form_id + "/form_view_graph")
      .then(response => {
        dispatch(didFetchViewResponseGraphData(response.data.data));
      })
      .catch(function (error) {});
  };
}

export function fetchInsight(form_id) {
  return function (dispatch) {
    dispatch(willFetchInsight());
    getRequest("api/v1/forms/" + form_id + "/dropoff_rate_insight")
      .then(response => {
        dispatch(didFetchInsight(response.data.data));
      })
      .catch(function (error) {});
  };
}

type highlightedResponseType = {
  [number]: string
};

export function updateResponseMetaData(
  form_id: number,
  highlighted_response: highlightedResponseType
): mixed {
  return function (dispatch) {
    dispatch(setHighlightedResponses(highlighted_response));
    patchRequest("api/v1/forms/" + form_id + "/response_meta_data/1", {
      highlighted_response: highlighted_response
    })
      .then(response => {})
      .catch(function (error) {});
  };
}

export function updateQuestionWidth(
  form_id: number,
  question_id: string,
  width: number
): mixed {
  return function (dispatch) {
    patchRequest("api/v1/forms/" + form_id + "/question_width", {
      question_id: question_id,
      width: width,
      form_id: form_id
    })
      .then(response => {})
      .catch(function (error) {});
  };
}

export function deleteSelectedResponses(
  form_id: number,
  selectedIDs: Array<number>
): mixed {
  return function (dispatch) {
    deleteRequest(
      "api/v1/forms/" + form_id + "/responses" + "?selectedIDs=" + selectedIDs
    )
      .then(response => {
        dispatch(
          didDeleteSelectedResponses(response.data.data.selectedIDs.split(","))
        );
      })
      .catch(function (error) {});
  };
}

export function exportResponses(form_id: string, type: string) {
  return function (dispatch) {
    if (type == "xlsx") {
      getRequest("api/v1/forms/" + form_id + "/export.xlsx")
        .then(response => {
          let s = atob(response.data);
          var buf = new ArrayBuffer(s.length);
          var view = new Uint8Array(buf);
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;

          const url = window.URL.createObjectURL(new Blob([buf]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "response.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {});
    } else if (type == "csv") {
      getRequest("api/v1/forms/" + form_id + "/export.csv")
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "response.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch(function (error) {});
    } else if (type == "csv-email") {
      getRequest("api/v1/forms/" + form_id + "/export" + "?type=csv-email")
        .then(response => {
          dispatch(fetchExports(form_id));
        })
        .catch(function (error) {});
      dispatch(
        showNotification(
          "succeed",
          "Your export has started",
          "Because you have a very large file, it might take 1-2 minutes for your export to complete. Please hit refresh button below until the status changes from pending to a download link."
        )
      );
    } else if (type == "xlsx-email") {
      getRequest("api/v1/forms/" + form_id + "/export" + "?type=xlsx-email")
        .then(response => {
          dispatch(fetchExports(form_id));
        })
        .catch(function (error) {});
      dispatch(
        showNotification(
          "succeed",
          "Your export has started",
          "Because you have a very large file, it might take 1-2 minutes for your export to complete. Please hit refresh button below until the status changes from pending to a download link."
        )
      );
    }
  };
}

export function exportPdf(form_id: string, selectedIDs: Array<number>): mixed {
  return function (dispatch) {
    getRequest(
      "api/v1/forms/" + form_id + "/export_pdf.pdf?response_ids=" + selectedIDs
    )
      .then(response => {
        const binaryString = atob(response.data);
        const len = binaryString.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i += 1) {
          view[i] = binaryString.charCodeAt(i);
        }
        const url = window.URL.createObjectURL(new Blob([view]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "response.pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch(function (error) {});
  };
}

export function sendEmails(
  emails: Array<string>,
  emailSubject: string,
  emailContent: string
): mixed {
  return function (dispatch) {
    postRequest("api/v1/mail", {
      emails: emails,
      emailSubject: emailSubject,
      emailContent: emailContent
    })
      .then(response => {})
      .catch(function (error) {});
  };
}
