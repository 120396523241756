//@flow
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  type columnIDType,
  type columnConfigType
} from "../../WispTable/Configuration";
import { updateColumnConfig } from "../State/TableState/action";

type State = {||};
type InjectedProps = {|
  updateColumnConfig: (columnIDType, columnConfigType) => void
|};

function withUpdateColumnConfig<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          updateColumnConfig={this.props.updateColumnConfig}
        />
      );
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateColumnConfig: (
      columnID: columnIDType,
      updatedColumnConfig: columnConfigType
    ) => {
      dispatch(updateColumnConfig(columnID, updatedColumnConfig));
    }
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withUpdateColumnConfig
);
