//@flow
import * as React from "react";
import { UICoreBox, UICoreText } from "../../UICore/";
import "./style.css";
import UICoreIconButton from "../UICoreIconButton";

export type buttonColor = "blue" | "grey" | "lightBlue" | "red" | "white";
type Props = {|
  children: string,
  link?: string,
  size?: "sm" | "md" | "lg",
  color?: buttonColor,
  icon?: string,
  loading?: boolean,
  disabled?: ?boolean,
  hexColor?: string,
  textHexColor?: string,
  fontFamily?: string,
  DEPRECATED_font_size?: "xxs" | "xs" | "sm" | "md" | "lg",
  actionCompletedText?: string,
  actionFailedText?: string,
  actionOnComplete?: () => void,
  hasShadow?: boolean,
  maxWidth?: number | string,
  newTab?: boolean,
  onClick?: (event: SyntheticEvent<>) => ?Promise<any>
|};
type State = {|
  showSpinner?: boolean,
  buttonText: string
|};
class UICoreButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSpinner: this.props.loading,
      buttonText: this.props.children
    };
  }

  _getColorClassName = () => {
    if (!this.props.color) {
      if (!this.props.hexColor) {
        return "UICoreButton-blue";
      } else {
        return "";
      }
    } else {
      return "UICoreButton-" + this.props.color;
    }
  };

  _getShadowClassName = () => {
    if (this.props.hasShadow) {
      return "UICoreButton-shadow";
    } else return "";
  };

  _getVerticalPadding = () => {
    switch (this.props.size) {
      case "sm":
        return "xxm";
      case "md":
        return "xm";

      default:
        return "xm";
    }
  };

  _getFontSize = () => {
    if (this.props.DEPRECATED_font_size) {
      return this.props.DEPRECATED_font_size;
    }
    switch (this.props.size) {
      case "sm":
        return "xs";
      case "md":
        return "sm";

      case "lg":
        return "md";

      default:
        return "xs";
    }
  };

  _getHorizontalPadding = () => {
    switch (this.props.size) {
      case "sm":
        return "xm";
      case "md":
        return "sm";

      default:
        return "sm";
    }
  };

  _renderSpinner = () => {
    return (
      <div class="UICoreButton-spinner">
        <div class="bounce1" />
        <div class="bounce2" />
        <div class="bounce3" />
      </div>
    );
  };

  _turnOnLoading = () =>
    this.setState({
      showSpinner: true
    });

  _turnOffLoading = () =>
    this.setState({
      showSpinner: false
    });

  _isLoading = () => this.state.showSpinner === true;

  _setActionCompletedButtonText = () => {
    if (this.props.actionCompletedText) {
      this.setState({
        buttonText: this.props.actionCompletedText
      });
    }
  };

  _setActionFailedButtonText = () => {
    if (this.props.actionFailedText) {
      this.setState({
        buttonText: this.props.actionFailedText
      });
    }
  };

  _handleButtonClick = (event: SyntheticEvent<>) => {
    //is a link button
    if (this.props.link) {
      if (this.props.newTab) {
        window.open(this.props.link);
      } else {
        window.location.href = this.props.link;
      }
      return;
    }
    //prevent duplicate clicking
    if (this._isLoading()) return;
    if (this.props.disabled) return;

    this._turnOnLoading();
    Promise.resolve(this.props.onClick && this.props.onClick(event))
      .then(e => {
        this._turnOffLoading();
        this._setActionCompletedButtonText();
        this.props.actionOnComplete && this.props.actionOnComplete();
      })
      .catch(err => {
        this._turnOffLoading();
        this._setActionFailedButtonText();
      });
  };

  render() {
    return (
      <UICoreBox
        className={`UICoreButton ${this._getColorClassName()} ${this._getShadowClassName()}`}
        shape="rounded"
        onClick={this._handleButtonClick}
        paddingTop={this._getVerticalPadding()}
        paddingBottom={this._getVerticalPadding()}
        paddingLeft={this._getHorizontalPadding()}
        paddingRight={this._getHorizontalPadding()}
        direction="row"
        justifyContent="center"
        alignItems="center"
        hexColor={this.props.hexColor}
        maxWidth={this.props.maxWidth}
      >
        {this.state.showSpinner && this._renderSpinner()}
        {this.props.icon && !this.state.showSpinner && (
          <UICoreBox marginRight="xm">
            <UICoreIconButton iconClassName={this.props.icon} />
          </UICoreBox>
        )}
        {!this.state.showSpinner && (
          <UICoreText
            alignment="center"
            size={this._getFontSize()}
            hexColor={this.props.textHexColor}
            fontFamily={this.props.fontFamily}
          >
            {this.state.buttonText}
          </UICoreText>
        )}
      </UICoreBox>
    );
  }
}
export default UICoreButton;
