//@flow
import * as React from "react";

import "./style.css";
import { UICoreBox, UICoreText, UICoreButton } from "../UICore";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { connect } from "react-redux";
import { closeNotification } from "../../Pages/WispTable/State/NotificationState/action";
import Mobile from "../Views/Mobile";
import Desktop from "../Views/Desktop";

export type notificationType = "error" | "succeed" | "primary";
export type Props = {|
  title: string,
  description: string,
  notificationType: notificationType,
  id: string,
  actionName?: string,
  onActionClick?: () => void,
  actionName2?: string,
  onActionClick2?: () => void,
  closeNotification: string => void
|};
type State = {||};
class Notification extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getTypeClass = () => {
    switch (this.props.notificationType) {
      case "error":
        return "Notification-error";
      case "succeed":
        return "Notification-succeed";
      case "primary":
        return "Notification-primary";
      default:
        return "Notification-succeed";
    }
  };

  _handleNotificationClose = () => {
    this.props.closeNotification(this.props.id);
  };

  render() {
    return [
      <Mobile>
        <ReactCSSTransitionGroup
          transitionName="NotificationMobile"
          transitionAppear={true}
          transitionLeave={true}
          transitionAppearTimeout={400}
          transitionLeaveTimeout={400}
        >
          <UICoreBox
            paddingLeft="xm"
            paddingRight="xm"
            position="relative"
            width="100vw"
          >
            <UICoreBox
              shape="rounded"
              position="relative"
              className={`Notification ${this._getTypeClass()}`}
              padding="xm"
            >
              <UICoreBox>
                <UICoreBox name="titleAndDescription">
                  <UICoreBox>
                    <UICoreText
                      overflow="wrap"
                      weight="bold"
                      size="md"
                      color="white"
                    >
                      {this.props.title}
                    </UICoreText>
                  </UICoreBox>

                  <UICoreBox marginTop="xm">
                    <UICoreText
                      weight="light"
                      overflow="wrap"
                      size="sm"
                      color="white"
                    >
                      {this.props.description}
                    </UICoreText>
                  </UICoreBox>
                </UICoreBox>
                {this.props.actionName && (
                  <UICoreBox marginTop="xm" name="Upgrade Button">
                    <UICoreButton
                      onClick={this.props.onActionClick}
                      color="white"
                    >
                      {this.props.actionName}
                    </UICoreButton>
                  </UICoreBox>
                )}
                {this.props.actionName2 && (
                  <UICoreBox marginTop="xm" name="Upgrade Button">
                    <UICoreButton
                      onClick={this.props.onActionClick2}
                      color="white"
                    >
                      {this.props.actionName2}
                    </UICoreButton>
                  </UICoreBox>
                )}
              </UICoreBox>
              <UICoreBox
                position="absolute"
                top="8px"
                right="8px"
                shape="circle"
                color="white"
                width="18px"
                height="18px"
                alignItems="center"
                justifyContent="center"
                hoverable={true}
                onClick={this._handleNotificationClose}
              >
                <i className="ion-close Notification-close" />
              </UICoreBox>
            </UICoreBox>
          </UICoreBox>
        </ReactCSSTransitionGroup>
      </Mobile>,
      <Desktop>
        <ReactCSSTransitionGroup
          transitionName="Notification"
          transitionAppear={true}
          transitionLeave={true}
          transitionAppearTimeout={400}
          transitionLeaveTimeout={400}
        >
          <UICoreBox
            shape="rounded"
            position="relative"
            width="250px"
            className={`Notification ${this._getTypeClass()}`}
            padding="sm"
            marginRight="xm"
          >
            <UICoreBox>
              <UICoreBox name="titleAndDescription">
                <UICoreBox>
                  <UICoreText
                    overflow="wrap"
                    weight="bold"
                    size="md"
                    color="white"
                  >
                    {this.props.title}
                  </UICoreText>
                </UICoreBox>

                <UICoreBox marginTop="xm">
                  <UICoreText overflow="wrap" size="sm" color="white">
                    {this.props.description}
                  </UICoreText>
                </UICoreBox>
              </UICoreBox>
              {this.props.actionName && (
                <UICoreBox marginTop="xm" name="Upgrade Button">
                  <UICoreButton
                    onClick={this.props.onActionClick}
                    color="white"
                  >
                    {this.props.actionName}
                  </UICoreButton>
                </UICoreBox>
              )}
              {this.props.actionName2 && (
                <UICoreBox marginTop="xm" name="Upgrade Button">
                  <UICoreButton
                    onClick={this.props.onActionClick2}
                    color="white"
                  >
                    {this.props.actionName2}
                  </UICoreButton>
                </UICoreBox>
              )}
            </UICoreBox>
            <UICoreBox
              position="absolute"
              top="8px"
              right="8px"
              shape="circle"
              color="white"
              width="18px"
              height="18px"
              alignItems="center"
              justifyContent="center"
              hoverable={true}
              onClick={this._handleNotificationClose}
            >
              <i className="ion-close Notification-close" />
            </UICoreBox>
          </UICoreBox>
        </ReactCSSTransitionGroup>
      </Desktop>
    ];
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    closeNotification: (id: string) => {
      dispatch(closeNotification(id));
    }
  };
};

export default connect(null, mapDispatchToProps)(Notification);
