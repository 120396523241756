//@flow
import { PAYMENT, QuestionTypeEnum } from "../../Pages/QuestionTypes";
import {
  SHOW_DIALOG,
  CLOSE_DIALOG
} from "../../Pages/WispTable/State/DialogState/action";
import { UICoreBox, UICoreImage, UICoreText } from "../../Component/UICore";
import { hasConnectedWithStripe } from "../../Library/UserInfo";
import * as React from "react";

//$FlowFixMe
const AddPaymentQuestionMiddleware = store => next => action => {
  switch (action.type) {
    case "ADD_QUESTION_TYPE":
      if (
        action.question &&
        (action.question.type === QuestionTypeEnum.Payment ||
          action.question.type === QuestionTypeEnum.Subscription) &&
        !hasConnectedWithStripe()
      ) {
        return next({
          type: SHOW_DIALOG,
          dialog: {
            header: "Connect with Stripe",
            acceptDisplayName: "connect",
            rejectDisplayName: "cancel",
            onAccept: redirectToStripe,
            onDismiss: () => next({ type: CLOSE_DIALOG }),
            onReject: () => next({ type: CLOSE_DIALOG }),
            body: (
              <UICoreBox alignItems="center">
                <UICoreBox paddingLeft="lg" paddingBottom="sm">
                  <UICoreImage
                    width="250px"
                    src="https://s3.us-east-2.amazonaws.com/wispformapp/guide/stripeConnect2X.png"
                  />
                </UICoreBox>
                <UICoreBox width="100%">
                  <UICoreText overflow="wrap">
                    To collect payment using Wispform, you will need to create
                    and link a Stripe account. Wispform uses Stripe to process
                    payment. Stripe will take payment information from your
                    responder and deposit money into your bank account. Wispform
                    does not deal with any payment information. Hit connect to
                    create/link your Stripe account.
                  </UICoreText>
                </UICoreBox>
              </UICoreBox>
            )
          }
        });
      } else {
        return next(action);
      }

    default:
      return next(action);
  }
};

function redirectToStripe() {
  window.location.href =
    "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_EYwPoBMsqR7Wce6WYjJCegNYPqTNudLH&scope=read_write";
}

export default AddPaymentQuestionMiddleware;
