//@flow
import { FinalPageType, PreviewListSection } from "./FormConfigEnums";
import { IFormConfig } from "./IFormConfig";

export default class BaseFormConfig {
  toolBars = ["view", "theme", "setting"];
  previewListSection = [PreviewListSection.QUESTION];
  finalPageType = FinalPageType.THANKYOUPAGE;
  notSupportedContentTypes = [];
}
