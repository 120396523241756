//@flow
import * as React from "react";
import ShortTextInput from "../ShortTextInput";
import { type questionComponentTypes } from "../../../FlowTypes/questionComponentTypes";
import "./style.css";

type Props = {|
  ...questionComponentTypes
|};
type State = {||};

class UI_ShortText extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <ShortTextInput {...this.props} />;
  }
}

export default UI_ShortText;
