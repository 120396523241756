import React, { Component } from "react";
import "./style.css";
import "./style.mobile.css";
import PropTypes from "prop-types";
import { isNumber } from "../../../Library/Util";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { isMobile } from "../../Views/helper";
import { UICoreBox, UICoreText } from "../../UICore";
import TextSubmitButton from "../ShortTextInput/TextSubmitButton";
import { isTablet } from "../../../Library/Window";
import WithConfigurableButtonText, {
  type InjectedProps as InjectedPropsFromWithConfigurableButtonText
} from "../../../Helper_HOC/WithConfigurableButtonText";
import {
  ButtonType,
  kDefaultTextForNewlineInstruction
} from "../../../Helper_HOC/WithConfigurableButtonText/config";

/*
  props:
    className:CSS attach to className will be attach to the container div.
    index: the index of each choices, it should be upper case letter A B C D.etc.
    choiceContent: the content of each choice.
*/

const enterKeyCode = 13;
const tabKeyCode = 9;
//
const enterKey = (function () {
  let isEnterKeyPressed = false;
  return {
    pressEnterKey: () => {
      isEnterKeyPressed = true;
    },
    releaseEnterKey: () => {
      isEnterKeyPressed = false;
    },
    isEnterKeyPressed: () => isEnterKeyPressed
  };
})();

type Props = {|
  ...InjectedPropsFromWithConfigurableButtonText
|};
class UI_LongText extends Component<Props> {
  constructor(props) {
    super(props);
  }

  state = {
    builderTextareaInputValue: "",
    wordCount: 0
  };

  componentDidMount() {
    //form loaded, and is the first question
    if (this.props.isCurrentQuestion) {
      this._focusTextArea();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    //just become the current question
    if (nextProps.isCurrentQuestion && !this.props.isCurrentQuestion) {
      this._focusTextArea();
    }
  }

  _textAreaElement: ?HTMLElement = null;

  _handleKeyDown = event => {
    const keyCode = event.keyCode;
    const hasShiftKeyPressed = event.shiftKey;
    //when hit enter key on desktop (without shift)
    if (keyCode === enterKeyCode && !hasShiftKeyPressed && !isMobile()) {
      enterKey.pressEnterKey();
    } else {
      enterKey.releaseEnterKey();
    }

    if (keyCode === tabKeyCode) {
      event.preventDefault();
    }
  };

  onUserInput(element) {
    this.setState({ wordCount: element.target.value.split(" ").length });
    //submit when enter key is cliced
    if (!enterKey.isEnterKeyPressed()) {
      //update
      this.props.onQuestionUpdate &&
        this.props.onQuestionUpdate(
          element.target.value,
          true /* should validate */
        );
    }
  }

  _handleKeyUp = () => {
    if (enterKey.isEnterKeyPressed() && !isMobile()) {
      //submit
      this._submitAnswer();
    }
  };

  getAnswer() {
    return this.props.answer && this.props.answer.answer;
  }

  _focusTextArea = () => {
    //need to wait for animationTime to prevent glitch
    const animationTime = 200;
    if (!isMobile() && !isTablet()) {
      setTimeout(() => {
        this._textAreaElement && this._textAreaElement.focus();
      }, animationTime);
    }
  };

  _blurTextArea = () => {
    this._textAreaElement && this._textAreaElement.blur();
  };

  _submitAnswer = () => {
    if (isMobile() && this._textAreaElement === document.activeElement) {
      //close keyboard first
      //otherwise, the scroll position will be fucked
      this._blurTextArea();
      setTimeout(() => {
        this.props.onQuestionComplete &&
          this.props.onQuestionComplete(this.getAnswer());
      }, 200);
    } else {
      this.props.onQuestionComplete &&
        this.props.onQuestionComplete(this.getAnswer());
      this._blurTextArea();
    }
  };

  _renderSubmit = () => {
    if (this.props.isInBuilder) {
      return null;
    } else {
      return (
        <UICoreBox width="100%" marginTop="xm">
          <TextSubmitButton
            hideDescriptionText={true}
            onClick={() => {
              this._submitAnswer();
            }}
          />
        </UICoreBox>
      );
    }
  };

  _renderNewLineInstruction = () => {
    if (!this.props.isInBuilder && this.getAnswer() != "" && !isMobile()) {
      const useDefaultText =
        this.props.buttonTextConfig[ButtonType.newlineInstructionText] ===
        kDefaultTextForNewlineInstruction;

      const defaultTextUI = [
        <span className="UI_LongText-newlineInstruction-bold">Shift</span>,
        " + ",
        <span className="UI_LongText-newlineInstruction-bold">Enter</span>,
        " to make a new line"
      ];

      return (
        <div
          className="UI_LongText-newlineInstruction"
          style={{
            color: this.props.styles.answer
          }}
        >
          {useDefaultText
            ? defaultTextUI
            : this.props.buttonTextConfig[ButtonType.newlineInstructionText]}
        </div>
      );
    } else {
      return null;
    }
  };

  _renderKeyboardSignifier = () => {
    if (
      (!this.props.isInBuilder &&
        !this.getAnswer() &&
        !(this.props.contents && isNumber(this.props.contents.maxWordLimit))) ||
      (this.props.isInBuilder &&
        this.state.builderTextareaInputValue === "" &&
        !(this.props.contents && isNumber(this.props.contents.maxWordLimit)))
    ) {
      // show keyboard
      return (
        <div className="UI_LongText-keyboard">
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-keyboard-o"
          />
        </div>
      );
    } else if (
      (!this.props.isInBuilder &&
        this.props.contents &&
        isNumber(this.props.contents.maxWordLimit)) ||
      (this.props.isInBuilder &&
        this.state.builderTextareaInputValue === "" &&
        this.props.contents &&
        isNumber(this.props.contents.maxWordLimit))
    ) {
      // show word count
      return (
        <UICoreBox position="absolute" bottom="0px" right="0px" padding="xxm">
          {this.state.wordCount > this.props.contents.maxWordLimit ? (
            <UICoreText color="red">
              {this.state.wordCount}/{this.props.contents.maxWordLimit}
            </UICoreText>
          ) : (
            <UICoreText hexColor={this.props.answerPrimaryColor}>
              {this.state.wordCount}/{this.props.contents.maxWordLimit}
            </UICoreText>
          )}
        </UICoreBox>
      );
    }
  };

  render() {
    return (
      <div className={"UI_LongText " + this.props.className}>
        {this.props.isInBuilder ? (
          <textarea
            disabled
            value={this.state.builderTextareaInputValue}
            onChange={e =>
              this.setState({
                builderTextareaInputValue: e.target.value
              })
            }
            className="UI_LongText-TextArea"
            style={{
              backgroundColor: this.props.answerSecondaryColor,
              color: this.props.styles.answer,
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
          />
        ) : (
          <textarea
            ref={e => {
              this._textAreaElement = e;
            }}
            value={this.getAnswer()}
            onChange={e => this.onUserInput(e)}
            onKeyDown={this._handleKeyDown}
            onKeyUp={this._handleKeyUp}
            className="UI_LongText-TextArea"
            style={{
              backgroundColor: this.props.answerSecondaryColor,
              color: this.props.styles.answer,
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
          />
        )}

        {this._renderNewLineInstruction()}
        {this._renderSubmit()}
        {this._renderKeyboardSignifier()}
      </div>
    );
  }
}

// props validation
UI_LongText.propTypes = {
  onQuestionComplete: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  answer: PropTypes.object.isRequired
};

export default WithConfigurableButtonText(WithStyles(UI_LongText));
