//@flow
import { type QuestionsStateType } from "./reducer";
import {
  toArray,
  isNonEmptyArray,
  toNumber,
  getMaxFromNumberArray,
  isNonEmptyString,
  isNullOrUndefined,
  safeGet
} from "../../../Library/Util";
import { createSelector } from "reselect";
import {
  type questionScoreCalculationsType,
  QuestionTypeEnum
} from "../../QuestionTypes";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import * as TagInputManager from "../../../Component/UICore/UICoreTagInput/InputManager";
import QuestionType from "../Components/QuestionType";
import { hasSubscriptionQuestion } from "../../Form/States/Answers/selectors";
import { type TGenericContentOutcomeLayout } from "../../../Component/GenericFormContent/type";

export function hasThankyouPage(state: QuestionsStateType) {
  return (
    state.pages &&
    state.pages.thankyouPages &&
    isNonEmptyArray(state.pages.thankyouPages.pages) !== null
  );
}

export function getThankyouPages(state: QuestionsStateType) {
  return (
    (state.pages &&
      state.pages.thankyouPages &&
      state.pages.thankyouPages.pages) ||
    []
  );
}

export function getThankyouPagesDisplayLogic(state: QuestionsStateType) {
  return (
    (state.pages &&
      state.pages.thankyouPages &&
      state.pages.thankyouPages.displayLogic) ||
    {}
  );
}

export function getThankyouPagesCalculatorConfig(state: QuestionsStateType) {
  return (
    (state.pages &&
      state.pages.thankyouPages &&
      state.pages.thankyouPages.calculatorConfig) ||
    {}
  );
}

export function getOutcomePageContentConfig(state: QuestionsStateType) {
  return (
    state.pages &&
    state.pages.outcomePage &&
    state.pages.outcomePage.pageContentConfig
  );
}

export const getOutcomeLayout: QuestionsStateType => TGenericContentOutcomeLayout =
  createSelector([getOutcomePageContentConfig], outcomePageConfig => {
    return safeGet(_ => outcomePageConfig.outcomeConfig.layout) || "horizontal";
  });

export function getOutcomes(state: QuestionsStateType) {
  return (
    (state.pages &&
      state.pages.outcomePage &&
      state.pages.outcomePage.pageContentConfig &&
      state.pages.outcomePage.pageContentConfig.outcomeConfig &&
      state.pages.outcomePage.pageContentConfig.outcomeConfig.outcomes) ||
    []
  );
}

export function isEditingThankyouPage(state: QuestionsStateType) {
  return state.last_edited_question === "thankyouPage";
}

export function isEditingOucomePage(state: QuestionsStateType) {
  return state.last_edited_question === QuestionTypeEnum.OutcomePage;
}
function _filterHiddenFieldQuestions(
  questions: Array<QuestionType>
): Array<QuestionType> {
  return toArray(questions).filter(question => question.type !== "HiddenField");
}

export const getVisibleQuestionsWithAnswerPipeDecorator = createSelector(
  [getAllQuestionsWithAnswerPipeDecorator],
  allQuestions => {
    return _filterHiddenFieldQuestions(allQuestions);
  }
);

export const getVisibleQuestions = createSelector(
  [getAllQuestions],
  allQuestions => {
    return _filterHiddenFieldQuestions(allQuestions);
  }
);

export function getAllQuestionsWithAnswerPipeDecorator(
  state: QuestionsStateType
): Array<QuestionType> {
  return toArray(state.questions);
}

export function getLastEditedThankyouPageIndex(
  state: QuestionsStateType
): ?number {
  return state.last_edited_thankyou_page;
}

export function getAllQuestions(
  state: QuestionsStateType
): Array<QuestionType> {
  return toArray(state.questions).map(question => {
    return {
      ...question,
      // For question title with pipe, we convert the title to a more human redable format.
      title: TagInputManager.convertStringRep2HumanRedableString(question.title)
    };
  });
}

export const getVisibleQuestionsNumber = createSelector(
  [getVisibleQuestions],
  questions => {
    return toArray(questions).length;
  }
);

export const getQuestionIDIndexMap = createSelector(
  [getAllQuestions],
  questions => {
    return toArray(questions).reduce((idIndexMap, question, index) => {
      return {
        ...idIndexMap,
        [question.question_id]: index
      };
    }, {});
  }
);

export const hasAnyJumpLogic = (function () {
  let hasJumpLogic = false;
  return function (state: QuestionsStateType) {
    if (!hasJumpLogic) {
      hasJumpLogic = toArray(state.questions).some(
        question => question.jumpLogic != null
      );
    }
    return hasJumpLogic;
  };
})();

export function hasPaymentType(state: QuestionsStateType) {
  return toArray(state.questions).some(
    q => q.type === QuestionTypeEnum.Payment
  );
}

export function hasSubscriptionType(state: QuestionsStateType) {
  return toArray(state.questions).some(
    q => q.type === QuestionTypeEnum.Subscription
  );
}

export const hasMoneyChargingType = createSelector(
  [hasPaymentType, hasSubscriptionType],
  (hasPayment, hasSubscription) => {
    return hasPayment || hasSubscription;
  }
);

export function getPaymentQuestion(state: QuestionsStateType) {
  return toArray(state.questions).find(
    q => q.type === QuestionTypeEnum.Payment
  );
}

export const getPaymentQuestionPaymentAmount = createSelector(
  [getPaymentQuestion],
  paymentQuestion => {
    if (!paymentQuestion) return "";
    const paymentAmount =
      paymentQuestion.config && paymentQuestion.config.paymentAmount;
    return isNullOrUndefined(paymentAmount) ? "" : paymentAmount;
  }
);

export const hasDynamicPricingLogic = createSelector(
  [getPaymentQuestion],
  paymentQuestion => {
    if (!paymentQuestion) return false;
    return isNonEmptyArray(
      paymentQuestion.config &&
        paymentQuestion.config.dynamicPricing &&
        paymentQuestion.config.dynamicPricing.branches
    );
  }
);

export function getEmailQuestion(
  state: QuestionsStateType
): Array<QuestionType> {
  return toArray(state.questions).filter(q => q.type === "Email");
}

export const listEmailQuestions = createSelector(
  [getEmailQuestion],
  emailQuestions => {
    return emailQuestions;
  }
);

export const numberOfQuestionsWithScoreSetting = createSelector(
  [getVisibleQuestions],
  questions => {
    return toArray(questions).reduce((number, question) => {
      if (isNonEmptyArray(question.scoreCalculations)) {
        return number + 1;
      } else {
        return number;
      }
    }, 0);
  }
);

export const hasScoreSetting = createSelector(
  [numberOfQuestionsWithScoreSetting],
  numberOfQuestionsWithScoreSetting => {
    return numberOfQuestionsWithScoreSetting > 0;
  }
);

export const firstQuestionIDWithScoreSetting = createSelector(
  [getVisibleQuestions],
  questions => {
    const firstQuestionWithScoreSetting = toArray(questions).find(question =>
      isNonEmptyArray(question.scoreCalculations)
    );
    return (
      firstQuestionWithScoreSetting && firstQuestionWithScoreSetting.question_id
    );
  }
);

function getMaxScore(scoreCalculations: questionScoreCalculationsType) {
  const getScore = scoreCalculation => toNumber(scoreCalculation.score);
  return getMaxFromNumberArray(toArray(scoreCalculations).map(getScore));
}

export const getTotalAvailableScore = createSelector(
  [getVisibleQuestions],
  allQuestions => {
    return toArray(allQuestions).reduce((totalScore, question) => {
      if (question && isNonEmptyArray(question.scoreCalculations)) {
        return totalScore + getMaxScore(question.scoreCalculations);
      } else {
        return totalScore;
      }
    }, 0);
  }
);

export function getFormConfiguration(state: QuestionsStateType) {
  return state.configurations || {};
}

export const getRedirectURL = createSelector(
  [getFormConfiguration],
  formConfiguration => {
    return formConfiguration.redirect_url;
  }
);

export const getHiddenFieldVariables = createSelector(
  [getFormConfiguration],
  configuration => configuration.hiddenFieldVariables
);

export const hasHiddenFieldVariables = createSelector(
  [getHiddenFieldVariables],
  hiddenFields => isNonEmptyArray(hiddenFields)
);

export const getHiddenFieldValues = createSelector(
  [getFormConfiguration],
  configuration => configuration.hiddenFieldVariableValues || []
);

export const getButtonText = createSelector(
  [getFormConfiguration],
  formConfiguration => {
    return formConfiguration.buttonText || {};
  }
);

export const hasRedirectURL = createSelector([getRedirectURL], redirectURL => {
  return isNonEmptyString(redirectURL);
});

export const logo = createSelector(
  [getFormConfiguration],
  formConfiguration => {
    return formConfiguration.logo;
  }
);

export const getStyle = (state: QuestionsStateType): ?formStyleType => {
  return state.styles || {};
};

export const getStyleBackground = createSelector(
  [getStyle],
  (style: formStyleType) => {
    return style.background || {};
  }
);

export const hasWelcomePage = createSelector(
  [getVisibleQuestions],
  questions => {
    const firstQuestion = toArray(questions)[0];
    if (firstQuestion && firstQuestion.type === "WelcomePage") {
      return true;
    }
    return false;
  }
);
