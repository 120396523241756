//@flow
import * as React from "react";
import "./style.css";
import UICoreBox from "../UICoreBox";
import Placeholder from "../../Placeholder";
import { isIos } from "../../../Library/DOM";
type Props = {|
  src: string,
  controls?: boolean,
  autoplay?: boolean,
  muted?: boolean,
  loop?: boolean,
  width?: number | string,
  height?: number | string,
  aspect?: number
|};
type State = {||};

class UICoreVideo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  selfNode: ?HTMLElement = null;

  _placeHolderNode: ?HTMLElement = null;

  componentDidMount() {
    this._adjustSize();
    this._hidePlaceHolder();
  }

  _adjustSize = () => {
    if (this.selfNode) {
      const width = this.selfNode.offsetWidth;
      if (this.props.aspect && !this.props.height) {
        this.selfNode.style.height = width * (1 / this.props.aspect) + "px";
      }
    }
  };

  _handleLoadStart = () => {
    this._showPlaceHolder();
  };

  _handleLoadEnd = () => {
    this._hidePlaceHolder();
  };

  _showPlaceHolder = () => {
    if (this._placeHolderNode) {
      this._placeHolderNode.style.display = "block";
    }
  };

  _hidePlaceHolder = () => {
    if (this._placeHolderNode) {
      this._placeHolderNode.style.display = "none";
    }
  };

  render() {
    return (
      <UICoreBox
        innerRef={e => {
          this.selfNode = e;
        }}
        width={this.props.width}
        height={this.props.height}
        overflowX="hidden"
        overflowY="hidden"
        shape="rounded"
        position="relative"
      >
        <video
          style={{
            position: "absolute",
            "object-fit": "fill"
          }}
          src={this.props.src}
          controls={this.props.controls}
          autoPlay={this.props.autoplay}
          muted={this.props.muted || this.props.autoplay}
          loop={this.props.loop}
          width="100%"
          height="100%"
          onLoadStart={this._handleLoadStart}
          onLoadedData={this._handleLoadEnd}
          playsInline
        />
        <UICoreBox
          innerRef={e => {
            this._placeHolderNode = e;
          }}
          position="absolute"
          top="0px"
          left="0px"
          width="100%"
          height="100%"
        >
          <Placeholder />
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default UICoreVideo;
