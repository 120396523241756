//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreButton
} from "../../../../../Component/UICore";
import { connect } from "react-redux";
import {
  type hiddenFieldVariablesType,
  type hiddenFieldVariableValuesType
} from "../../../../../FlowTypes/hiddenFieldTypes";
import "./style.css";
import {
  getHiddenFieldVariables,
  getHiddenFieldValues
} from "../../../States/selectors";
import {
  updateHiddenFields,
  addHiddenFields,
  removeHiddenFields,
  updateHiddenFieldValues
} from "../../../States/actions";
import {
  replace,
  toArray,
  insertElementAt,
  removeElemenent,
  isNonEmptyArray,
  safeGet,
  isNonEmptyString
} from "../../../../../Library/Util";
import uniqid from "uniqid";
import { Scrollbars } from "react-custom-scrollbars";
import * as HiddenFieldUtil from "../../../../../Library/HiddenFieldUtil";
import { getShareLink } from "../../../../Share";
import { isUrlParamAllowed } from "../../../../../Library/URL";

type HiddenFieldVariableEditorType = {|
  variableName: string,
  variableValue: ?string,
  showValueEditor: ?boolean,
  updateHiddenVariable: string => void,
  updateHiddenFieldValue: string => void,
  remove: () => void
|};

const HiddenFieldVariableEditor = (
  props: HiddenFieldVariableEditorType
): React.Node => {
  return (
    <UICoreBox paddingBottom="sm" direction="row">
      <UICoreInput
        placeholder="field name"
        width={130}
        value={props.variableName}
        onChange={(_, value) =>
          props.updateHiddenVariable(String(value).toLowerCase())
        }
        validator={(input: string) => {
          if (isUrlParamAllowed(input)) {
            return "";
          } else {
            return "can only contain letters, numbers, and -_.!~*'() characters";
          }
        }}
      ></UICoreInput>
      {props.showValueEditor && (
        <UICoreBox paddingLeft="xm">
          <UICoreInput
            placeholder="value"
            width={200}
            value={props.variableValue}
            onChange={(_, value) =>
              props.updateHiddenFieldValue(String(value).toLowerCase())
            }
          ></UICoreInput>
        </UICoreBox>
      )}
      <UICoreBox
        shape="rounded"
        color="lightGrey"
        hoverable={true}
        padding="xm"
        width={38}
        height={38}
        justifyContent="center"
        alignItems="center"
        marginLeft="xm"
        onClick={props.remove}
      >
        <UICoreText dangerous_style={{ paddingTop: "2px", fontSize: "10px" }}>
          <i className="ion-close" />
        </UICoreText>
      </UICoreBox>
    </UICoreBox>
  );
};

type Props = {|
  hiddenFieldVariables: hiddenFieldVariablesType,
  hiddenFieldValues: hiddenFieldVariableValuesType,
  updateHiddenFields: (string, number) => void,
  updateHiddenFieldValues: hiddenFieldVariableValuesType => void,
  addHiddenFields: (string, number) => void,
  removeHiddenFields: number => void,
  config: {|
    showValueEditor?: boolean,
    showTitle?: boolean
  |}
|};
type State = {||};

class HiddenFieldSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getUrlWithParam = () => {
    return HiddenFieldUtil.getUrlWithHiddenParamsWithDefaultValue(
      getShareLink(),
      this.props.hiddenFieldVariables,
      safeGet(_ => this.props.hiddenFieldValues[0])
    );
  };

  render() {
    const hiddenFieldVariables: hiddenFieldVariablesType = isNonEmptyArray(
      this.props.hiddenFieldVariables
    )
      ? this.props.hiddenFieldVariables
      : [{ variableName: "", hiddenFieldQuestionID: "" }];
    return (
      <UICoreBox height="100%" alignItems="start">
        <Scrollbars>
          <UICoreBox padding="sm">
            {this.props.config.showTitle && (
              <UICoreBox>
                <UICoreText color="black" size="md" weight="bold">
                  Add Hidden Fields to Your Wispform
                </UICoreText>
              </UICoreBox>
            )}

            <UICoreBox
              paddingTop={this.props.config.showTitle ? "sm" : undefined}
            >
              <UICoreText size="sm">
                Hidden fields let you pass in information you already know about
                the user, via <b>URL parameters</b>. You can use hidden filds
                within the form and have them displayed in the result page.{" "}
                <br />
                <br />
                Sample URL with hidden fields filled in:
              </UICoreText>
            </UICoreBox>
            <UICoreBox marginTop="xm">
              <UICoreText
                dangerous_style={{ fontStyle: "italic" }}
                color="lightBlue"
                size="sm"
              >
                {this._getUrlWithParam()}
              </UICoreText>
            </UICoreBox>
            <UICoreBox paddingTop="md">
              {hiddenFieldVariables.map((variable, index) => (
                <HiddenFieldVariableEditor
                  updateHiddenVariable={updatedVariable => {
                    if (
                      isNonEmptyString(
                        safeGet(
                          _ => hiddenFieldVariables[index].hiddenFieldQuestionID
                        )
                      )
                    ) {
                      this.props.updateHiddenFields(updatedVariable, index);
                    } else {
                      this.props.addHiddenFields(updatedVariable, index);
                    }
                  }}
                  remove={() => {
                    this.props.removeHiddenFields(index);
                  }}
                  updateHiddenFieldValue={updatedValue => {
                    this.props.updateHiddenFieldValues([
                      {
                        ...this.props.hiddenFieldValues[0],
                        [variable.variableName]: updatedValue
                      }
                    ]);
                  }}
                  variableName={variable.variableName}
                  variableValue={safeGet(
                    _ => this.props.hiddenFieldValues[0][variable.variableName]
                  )}
                  showValueEditor={
                    this.props.config && this.props.config.showValueEditor
                  }
                />
              ))}
            </UICoreBox>
            <UICoreBox
              width={this.props.config.showValueEditor ? "384px" : "176px"}
              paddingTop="xxm"
            >
              <UICoreButton
                onClick={() => {
                  this.props.addHiddenFields(
                    "",
                    toArray(hiddenFieldVariables).length
                  );
                }}
                color="grey"
              >
                + Another Field
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        </Scrollbars>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hiddenFieldVariables: getHiddenFieldVariables(state.Question),
    hiddenFieldValues: getHiddenFieldValues(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateHiddenFields: (
      updatedHiddenFieldVariableName: string,
      index: number
    ) => {
      dispatch(updateHiddenFields(updatedHiddenFieldVariableName, index));
    },
    addHiddenFields: (
      initialHiddenFieldVariableName: ?string,
      index: number
    ) => {
      dispatch(addHiddenFields(initialHiddenFieldVariableName, index));
    },
    removeHiddenFields: (index: number) => {
      dispatch(removeHiddenFields(index));
    },
    updateHiddenFieldValues: (
      updatedHiddenFieldValues: hiddenFieldVariableValuesType
    ) => {
      dispatch(updateHiddenFieldValues(updatedHiddenFieldValues));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HiddenFieldSection);
