import React, {Component} from 'react';
import {ProgressBar} from 'react-bootstrap';
import './style.css';
import PropTypes from 'prop-types';
class UI_HeaderBar extends Component {
  constructor(props) {
    super(props);
  }

  renderHeaderBarTitle() {
    const {currentQuestionNumber, question_count} = this.props;
    if (currentQuestionNumber > question_count) {
      return 'Review Your Answers';
    }
    else{
      return (
        currentQuestionNumber-1+ ' of ' + question_count + ' Answeared'
      );
    }
  }

  render() {
    return(
      <div>
        <div className="UI_HeaderBar">
          <div>
            <i
              onClick={this.props.previousQuestion}
              /* eslint-disable */
              className="fa fa-arrow-circle-o-left fa-2x UI_HeaderBar-leftIcon" aria-hidden="true"
              /* eslint-enable */
               />
          </div>
          <div className="UI_HeaderBar-progressiveBar">
            <div className="UI_HeaderBar-progressiveBar-stats">
            {this.renderHeaderBarTitle()}
            </div>
          </div>
          <div>
            <i
            onClick={this.props.nextQuestion}
            className="fa fa-arrow-circle-o-right fa-2x UI_HeaderBar-rightIcon"
            aria-hidden="true" />
          </div>
        </div>
        <ProgressBar
          /* eslint-disable */
          now={(this.props.currentQuestionNumber-1) * 100 / this.props.question_count}
          /* eslint-enable */
          />
      </div>
    );
  }
}

UI_HeaderBar.propTypes = {
  currentQuestionNumber: PropTypes.string.isRequired,
  question_count: PropTypes.number.isRequired,
  previousQuestion: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired,
};

export default UI_HeaderBar;
