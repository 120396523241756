//@flow
import * as React from "react";
import "./style.css";
import CellPopup from "../../../CellPopup";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreDivider,
  UICoreButton
} from "../../../../../../Component/UICore";
import { connect } from "react-redux";
import {
  getContactsInRow,
  getCurrentTableID
} from "../../../../State/TableState/selectors";
import {
  type tableIDType,
  type tableType,
  type columnConfigType,
  type contactCellType,
  type columnIDType,
  type rowIDType,
  type formCellStateType,
  type contactIDType,
  type wispformIDType
} from "../../../../Configuration";
import { isEmptyArray, unNullString } from "../../../../../../Library/Util";
import { getUserInfo } from "../../../../../../Library/UserInfo";
import { domainName } from "../../../../../../config";
import { sendFormsToContacts as apiSendFormsToContacts } from "../../../../api";
import { getWispformInfo as apiGetWispformInfo } from "../../../../../Form/api";
type Props = {|
  currentTableID: tableIDType,
  columnID: columnIDType,
  rowId: rowIDType,
  config: ?columnConfigType,
  showCell: () => void,
  updateCellState: formCellStateType => void,
  getContactsInRow: rowIDType => Array<contactCellType>
|};
type State = {|
  formName: ?string
|};
class SendForm extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this._fetchFromName();
  }

  state = {
    formName: null
  };

  _fetchFromName = () => {
    if (!this.props.config || !this.props.config.formID) {
      return;
    }
    apiGetWispformInfo(unNullString(this.props.config.formID)).then(info => {
      this.setState({
        formName: info.name
      });
    });
  };

  _getFromName = () => {
    if (!this.props.config || !this.props.config.formID) {
      return " ";
    }
    return unNullString(this.state.formName);
  };

  _getSuggestedContactName = () => {
    const contactCells = this.props.getContactsInRow(this.props.rowId);
    if (isEmptyArray(contactCells)) return "";
    return contactCells[0].name;
  };

  _getSuggestedContactID = () => {
    const contactCells = this.props.getContactsInRow(this.props.rowId);
    if (isEmptyArray(contactCells)) return 0;
    return contactCells[0].id;
  };

  _getSuggestedContactEmail = () => {
    const contactCells = this.props.getContactsInRow(this.props.rowId);
    if (isEmptyArray(contactCells)) return "";
    return contactCells[0].email;
  };

  _hasSuggestedContactName = (): boolean =>
    this._getSuggestedContactName() != "";

  _getFormURL = () => {
    const userInfo = getUserInfo();
    if (!userInfo) return "";
    const publisherName = userInfo.name;
    //$FlowFixMe
    const formID = this.props.config && this.props.config.formID;
    if (!formID) return "";
    const rowID = this.props.rowId;
    if (!rowID) return "";
    const columnID = this.props.columnID;
    if (!columnID) return "";
    const contactID = this._getSuggestedContactID();
    const currentTableID = this.props.currentTableID;
    if (!currentTableID) return "";

    return getWisptableFormViewURL(
      publisherName,
      domainName,
      formID,
      rowID,
      columnID,
      contactID,
      currentTableID
    );
  };

  _sendFormButtonClick = () => {
    const email = this._getSuggestedContactEmail();
    const name = this._getSuggestedContactName();
    if (!email || !name) return;

    return apiSendFormsToContacts([[email, name, this._getFormURL()]]);
  };

  render() {
    return (
      <CellPopup width={350} onDismiss={this.props.showCell}>
        <UICoreBox padding="xm">
          <UICoreBox paddingBottom="sm">
            <UICoreText weight="bold" size="sm">
              {`Send Wispform: ${this._getFromName()}`}
            </UICoreText>
          </UICoreBox>

          <UICoreBox>
            <UICoreBox paddingBottom="xm">
              <UICoreText overflow="wrap" size="xs" color="Grey">
                Use this url to get responses and have them linked in current
                row
              </UICoreText>
            </UICoreBox>
            <UICoreBox>
              <UICoreInput
                autoSelect={true}
                value={this._getFormURL()}
                onChange={() => {}}
              />
            </UICoreBox>
          </UICoreBox>

          {this._hasSuggestedContactName() && (
            <UICoreBox paddingTop="xm" paddingBottom="xm">
              <UICoreDivider size="xs" text="OR" />
            </UICoreBox>
          )}

          {this._hasSuggestedContactName() && (
            <UICoreBox>
              <UICoreButton
                actionCompletedText="Sent 👌"
                onClick={this._sendFormButtonClick}
                size="sm"
              >
                {`Send to contact "${this._getSuggestedContactName()}"`}
              </UICoreButton>
            </UICoreBox>
          )}
        </UICoreBox>
      </CellPopup>
    );
  }
}

export function getWisptableFormViewURL(
  publisherName: string,
  domainName: string,
  formID: wispformIDType,
  rowID: rowIDType,
  columnID: columnIDType,
  contactID: contactIDType,
  currentTableID: tableIDType
) {
  return `https://${publisherName}.${domainName}/${formID}?row=${rowID}&col=${columnID}&contact=${contactID}&table=${currentTableID}`;
}

const mapStateToProps = (state, ownProps) => {
  return {
    getContactsInRow: getContactsInRow(state.WispTable),
    currentTableID: getCurrentTableID(state.WispTable)
  };
};

export default connect(
  mapStateToProps,
  null
)(SendForm);
