import React, { Component } from "react";
import QuestionListItem from "../../Components/QuestionListItem";
import { connect } from "react-redux";
import AddButton from "../../Components/AddButton";
import {
  delete_question,
  delete_thankyou_page,
  set_current_editing_question,
  set_current_editing_thankyou_page,
  reorder_preview_window,
  add_thankyou_page
} from "../../States/actions";
import { getThankyouPages, getVisibleQuestions } from "../../States/selectors";
// import '../../CSS/UI_Library/ViewWindow.css'
import "./style.css";
import PropTypes from "prop-types";
import QuestionTypeContainer from "../QuestionTypeContainer";
import { Scrollbars } from "react-custom-scrollbars";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import {
  UICoreBox,
  UICoreButton,
  UICoreDivider,
  UICoreFlyOut,
  UICoreIconButton,
  UICoreReorderable,
  UICoreText,
  UICoreTooltip,
  UICoreLink
} from "../../../../Component/UICore";
import { logFeatureEvent } from "../../../../Library/Logger";
import { toArray } from "../../../../Library/Util";
import { QuestionTypeEnum } from "../../../QuestionTypes";
import WithFormConfig from "../../../../Helper_HOC/WithFormConfig";
import { onlyContains } from "../../../../Library/Primitives/Array";
import { PreviewListSection } from "../../../../Library/Forms/FormConfigs/FormConfigEnums";
import { getScreenWidth } from "../../../../Library/Window";

class QuestionListContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionSelectorAnchorElement: null,
      questionSelectorDirection: "right",
      questionSelectorMargin: 0,
      questionSelectorSize: "sm",
      questionSelectorRelativeTop: null,
      questionSelectorShowCaret: true
    };
  }

  renderSelector() {
    if (this.props.show_selector) {
      return (
        <UICoreFlyOut
          size={this.state.questionSelectorSize}
          anchor={this.state.questionSelectorAnchorElement}
          idealDirection={this.state.questionSelectorDirection}
          forceDirection={true}
          onDismiss={this.props.hideSelector}
          margin={0}
          relativeTop={this.state.questionSelectorRelativeTop}
          showCaret={this.state.questionSelectorShowCaret}
        >
          <QuestionTypeContainer hideSelector={this.props.hideSelector} />
        </UICoreFlyOut>
      );
    } else {
      return <div />;
    }
  }

  _handleSortEnd = ({ oldIndex, newIndex }) => {
    this.props.set_current_editing_question(newIndex);
    this.props.reorder_preview_window(oldIndex, newIndex);
  };

  _isCurrentEditingOutcomePage = () => {
    return this.props.last_edited_question === QuestionTypeEnum.OutcomePage;
  };

  _isCurrentEditingThankyouPage = thankyouPageIndex => {
    return (
      this.props.last_edited_question === "thankyouPage" &&
      this.props.last_edited_thankyou_page === thankyouPageIndex
    );
  };

  _renderOutcomePage = () => {
    const questionNum = this.props.questions.length;
    return (
      <QuestionListItem
        delete_question={() => {
          // no-op. You are not suppose to delete an outcome page
        }}
        display={"outcome"}
        onChange={() => {
          this.props.set_current_editing_question(QuestionTypeEnum.OutcomePage);
        }}
        pageNumber={questionNum}
        thankyouPageIndex={0}
        className={
          this._isCurrentEditingOutcomePage() ? "PreviewWindow-Current" : ""
        }
        type={QuestionTypeEnum.OutcomePage}
      />
    );
  };

  _renderThankyouPages = () => {
    const thankyouPageBaseNumber = this.props.questions.length;
    return toArray(this.props.thankyouPages).map((page, thankyouPageIndex) => {
      return (
        <QuestionListItem
          delete_question={() =>
            this.props.delete_thankyou_page(thankyouPageIndex)
          }
          display={String(page.thankyouNote)}
          onChange={() => {
            this.props.set_current_editing_question("thankyouPage");
            this.props.set_current_editing_thankyou_page(thankyouPageIndex);
          }}
          pageNumber={thankyouPageBaseNumber + thankyouPageIndex}
          thankyouPageIndex={thankyouPageIndex}
          className={
            this._isCurrentEditingThankyouPage(thankyouPageIndex)
              ? "PreviewWindow-Current"
              : ""
          }
          type={QuestionTypeEnum.ThankyouPage}
        />
      );
    });
  };

  _getQuestionList(questions, last_edited_question, previewListSections) {
    if (
      previewListSections &&
      previewListSections.includes(PreviewListSection.RESULT)
    ) {
      return [
        ...questions.map((question, index) => {
          const CurrentSelectedStyle =
            index === last_edited_question ? "PreviewWindow-Current" : "";
          return (
            <QuestionListItem
              delete_question={this.props.delete_question}
              display={String(question.title)}
              onChange={this.props.set_current_editing_question}
              pageNumber={index}
              className={CurrentSelectedStyle}
              type={question.type}
            />
          );
        })
      ];
    } else {
      return [
        ...questions.map((question, index) => {
          const CurrentSelectedStyle =
            index === last_edited_question ? "PreviewWindow-Current" : "";
          return (
            <QuestionListItem
              delete_question={this.props.delete_question}
              display={String(question.title)}
              onChange={this.props.set_current_editing_question}
              pageNumber={index}
              className={CurrentSelectedStyle}
              type={question.type}
            />
          );
        }),
        ...this._renderThankyouPages()
      ];
    }
  }

  _renderSectionHeaderButton(buttonType: string) {
    if (buttonType === "questions") {
      return (
        <UICoreButton
          size="sm"
          color="grey"
          onClick={e => {
            e.stopPropagation();
            this.props.showSelector();
            this.setState({
              questionSelectorAnchorElement: e.currentTarget,
              questionSelectorDirection: "right",
              questionSelectorMargin: null,
              questionSelectorRelativeTop: 15,
              questionSelectorShowCaret: false
            });
          }}
        >
          {" + "}
        </UICoreButton>
      );
    } else if (buttonType === "thankyoupages") {
      return (
        <UICoreButton
          size="sm"
          color="grey"
          onClick={e => {
            e.stopPropagation();
            this.props.add_thankyou_page();
          }}
        >
          {" + "}
        </UICoreButton>
      );
    }
  }

  _renderSectionHeader(
    sectionName: string,
    showDivider: boolean,
    buttonType: string,
    toolTip: ?HTMLElement
  ) {
    return (
      <UICoreBox>
        <UICoreBox
          alignItems="center"
          justifyContent="between"
          direction="row"
          verticalMargin="xm"
        >
          <UICoreBox alignItems="center" direction="row">
            <UICoreText color="black" size="xs" weight="medium">
              {sectionName}
            </UICoreText>
            {toolTip}
          </UICoreBox>
          {this._renderSectionHeaderButton(buttonType)}
        </UICoreBox>
      </UICoreBox>
    );
  }

  _onlyHasQuestionSection(previewListSections) {
    return onlyContains(previewListSections, PreviewListSection.QUESTION);
  }

  render() {
    const {
      last_edited_question,
      delete_question,
      delete_thankyou_page,
      questions,
      set_current_editing_question,
      reorder_preview_window,
      thankyouPages
    } = this.props;
    const hasWelcomePage =
      questions && questions[0] && questions[0].type === "WelcomePage";
    const previewListSections =
      (this.props.formConfig && this.props.formConfig.previewListSection) || [];
    return (
      <UICoreBox
        width="100%"
        height="100%"
        className="PreviewWindowWrapper-Container"
        paddingTop="xm"
        paddingBottom="xm"
        paddingLeft="xm"
        paddingRight="xm"
      >
        <UICoreBox height="100%" width="100%" position="relative">
          <UICoreBox width="100%" height="100%" paddingBottom="lg">
            <div className="PreviewWindowWrapper">
              <Scrollbars autoHide={true}>
                {this._onlyHasQuestionSection(previewListSections) ? (
                  <UICoreReorderable
                    direction="column"
                    onSortEnd={this._handleSortEnd}
                    disableLastN={toArray(thankyouPages).length}
                    disableFirst={hasWelcomePage}
                  >
                    {this._getQuestionList(
                      questions,
                      last_edited_question,
                      previewListSections
                    )}
                  </UICoreReorderable>
                ) : (
                  previewListSections.map((section, index) => {
                    if (section === PreviewListSection.QUESTION) {
                      return (
                        <UICoreBox width={"100%"} minHeight="450px">
                          <UICoreBox
                            hexColor="#e4fbef"
                            borderRadius="lg"
                            paddingTop="xm"
                            paddingBottom="xm"
                            paddingLeft="xm"
                            paddingRight="xm"
                            marginTop="xm"
                            marginBottom="xm"
                            direction="row"
                          >
                            <UICoreText size="xxs" hexColor="#00b25d">
                              Need some help? View our{" "}
                            </UICoreText>
                            {this.props.formConfig &&
                            this.props.formConfig.availableQuestionOperations &&
                            this.props.formConfig.availableQuestionOperations.includes(
                              "calculator"
                            ) ? (
                              <UICoreLink
                                href={"https://wispform.com/guide/create-an-online-calculator-for-your-business-or-service"}
                                dangerous_style={{
                                  fontSize: "12px",
                                  color: "#00b25d",
                                  fontWeight: 800,
                                  textDecoration: "underline"
                                }}
                                same_tab={false}
                                logger="calculatorIntroDocumentationClicked"
                              >
                                tutorial
                              </UICoreLink>
                            ) : (
                              <UICoreLink
                                href={
                                  "https://wispform.com/guide/create-a-quiz-with-outcomes-that-map-to-users-choices"
                                }
                                dangerous_style={{
                                  fontSize: "12px",
                                  color: "#00b25d",
                                  fontWeight: 800,
                                  textDecoration: "underline"
                                }}
                                same_tab={false}
                                logger="outcomeQuizIntroDocumentationClicked"
                              >
                                tutorial
                              </UICoreLink>
                            )}
                          </UICoreBox>
                          {this._renderSectionHeader(
                            "Questions",
                            index !== 0,
                            "questions"
                          )}
                          <UICoreReorderable
                            direction="column"
                            onSortEnd={this._handleSortEnd}
                            disableFirst={hasWelcomePage}
                          >
                            {this._getQuestionList(
                              questions,
                              last_edited_question,
                              previewListSections
                            )}
                          </UICoreReorderable>
                        </UICoreBox>
                      );
                    } else if (section === PreviewListSection.OUTCOME) {
                      return (
                        <UICoreBox width={"100%"}>
                          {this._renderSectionHeader(
                            "Outcomes",
                            index !== 0,
                            "none",
                            <UICoreBox paddingLeft="xxm">
                              <UICoreTooltip.QuestionMark
                                relativeLeft={-35}
                                text="Use outcome page to display suggested content and actions based on user's choice"
                                size={210}
                              />
                            </UICoreBox>
                          )}
                          {this._renderOutcomePage()}
                        </UICoreBox>
                      );
                    } else if (section === PreviewListSection.RESULT) {
                      {
                        return (
                          <UICoreBox paddingTop="sm" width={"100%"}>
                            {this._renderSectionHeader(
                              "Result Pages",
                              index !== 0,
                              "thankyoupages",
                              <UICoreBox paddingLeft="xxm">
                                <UICoreTooltip.QuestionMark
                                  relativeLeft={-35}
                                  text="Use result pages to define formulas and show your calculated results"
                                  size={210}
                                />
                              </UICoreBox>
                            )}
                            {this._renderThankyouPages()}
                          </UICoreBox>
                        );
                      }
                    }
                  })
                )}
              </Scrollbars>
            </div>
          </UICoreBox>
          {this._onlyHasQuestionSection(previewListSections) && (
            <UICoreBox
              position="absolute"
              bottom="0px"
              width="100%"
              alignItems="center"
              justifyContent="center"
              onClick={e => {
                e.stopPropagation();
                logFeatureEvent("builder/addNewQuestionButtonClick");
                this.props.showSelector();
                this.setState({
                  questionSelectorAnchorElement: e.currentTarget,
                  questionSelectorDirection: "up",
                  questionSelectorMargin: 0,
                  questionSelectorSize: getScreenWidth() < 1000 ? "xs" : "sm"
                });
              }}
            >
              <AddButton />
            </UICoreBox>
          )}
          {this.renderSelector()}
        </UICoreBox>
      </UICoreBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    last_edited_question: state.Question.last_edited_question,
    last_edited_thankyou_page: state.Question.last_edited_thankyou_page,
    questions: getVisibleQuestions(state.Question),
    thankyouPages: getThankyouPages(state.Question)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    /* eslint-disable */
    set_current_editing_question: pageNumber =>
      dispatch(set_current_editing_question(pageNumber)),
    set_current_editing_thankyou_page: thankyouPageNumber =>
      dispatch(set_current_editing_thankyou_page(thankyouPageNumber)),
    /* eslint-enable */
    delete_question: pageNumber => dispatch(delete_question(pageNumber)),
    delete_thankyou_page: (index: number) =>
      dispatch(delete_thankyou_page(index)),
    reorder_preview_window: (oldIndex, newIndex) =>
      dispatch(reorder_preview_window(oldIndex, newIndex)),
    add_thankyou_page: () => {
      dispatch(add_thankyou_page());
    }
  };
}

QuestionListContainer.propTypes = {
  last_edited_question: PropTypes.number.isRequired,
  delete_question: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  set_current_editing_question: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithFormConfig(QuestionListContainer));
