// @flow
import * as React from "react";
import ListHighlighter from "../../../../Component/ListHighlighter";
import WithStyles from "../../../../Helper_HOC/WithStyles";
import {
  type formStyleType,
  type background,
  styleAttributes,
  designPanelSectionNames,
  designPanelSections
} from "../../../../FlowTypes/wispformStyleTypes";
import {
  UICoreSection,
  UICoreBox,
  UICoreText
} from "../../../../Component/UICore";
import "./style.css";
import DesignPanelBackgroundSection from "./DesignPanelBackgroundSection";
import DesignPanelColorSection from "./DesignPanelColorSection";
import ColorPicker from "../../../../Component/ColorPicker";
import DesignPanelFontSection from "./DesignPanelFontSection";
import DesignPanelThemeSection from "./DesignPanelThemeSection";

const DEFAULT_COLOR = "#ffffff";
type Props = {
  updateStyle: ($Keys<formStyleType>, string | background) => any,
  styles: formStyleType
};

type State = {
  current_section: number
};

class StyleInspector extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      current_section: designPanelSections.theme
    };
  }

  _getBackgroundColor(backgroundStyle: background): string {
    if (backgroundStyle) {
      return String(backgroundStyle.color);
    }
    return DEFAULT_COLOR;
  }

  getColor(name: ?$Keys<formStyleType>): string {
    const styleValue = this.props.styles && this.props.styles[String(name)];
    if (styleValue == null) return DEFAULT_COLOR;
    if (typeof styleValue === "string") {
      return styleValue;
    } else {
      return this._getBackgroundColor(styleValue);
    }
  }

  render() {
    return (
      <UICoreBox
        direction="row"
        boxShadow={true}
        padding="sm"
        color="white"
        shape="rounded"
      >
        {/* Section Selectors */}
        <UICoreBox
          dangerous_style={{ borderRight: "1px solid #ececec" }}
          paddingRight="md"
          direction="column"
        >
          <UICoreBox paddingBottom="xm">
            <UICoreText size="lg" weight="bold" color="black">
              Design
            </UICoreText>
          </UICoreBox>
          <UICoreSection.Vertical
            activeSectionIndex={this.state.current_section}
            sections={[
              designPanelSectionNames.theme,
              designPanelSectionNames.background,
              designPanelSectionNames.color,
              designPanelSectionNames.font
            ]}
            onChange={(_, index) => {
              this.setState({ current_section: index });
            }}
          ></UICoreSection.Vertical>
        </UICoreBox>

        {/* Sections */}
        <UICoreBox paddingLeft="sm">
          {this.state.current_section === designPanelSections.theme && (
            <UICoreBox width={600}>
              <DesignPanelThemeSection />
            </UICoreBox>
          )}
          {this.state.current_section === designPanelSections.background && (
            <UICoreBox width={600}>
              <DesignPanelBackgroundSection />
            </UICoreBox>
          )}
          {this.state.current_section === designPanelSections.color && (
            <UICoreBox width={600}>
              <DesignPanelColorSection />
            </UICoreBox>
          )}
          {this.state.current_section === designPanelSections.font && (
            <UICoreBox width={600}>
              <DesignPanelFontSection />
            </UICoreBox>
          )}
        </UICoreBox>
      </UICoreBox>
    );
  }
}

export default WithStyles(StyleInspector);
