//@flow
import * as React from "react";
import { serverAddress, domainName } from "../../config";
import { getUrlVars, getOrgName } from "../../Library";
import { getRequest } from "../../Library/Request";
import {
  saveUserInfo,
  getUserEmail,
  saveUserInfoWithOrg,
  getUserInfo
} from "../../Library/UserInfo";
import { browserHistory } from "react-router";
import { safeGet } from "../../Library/Util";
import { isInvalidProAccount } from "../../Library/Account";
import * as ProfitWell from "../../Library/ProfitWell";

type State = {||};
type InjectedProps = {||};
export default function withAuthentication<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    constructor(props) {
      super(props);
    }

    componentDidMount() {
      if (!this.props.isAuthenticated) {
        let org_name = getOrgName();
        getRequest("api/v1/authentication?org_name=" + org_name)
          .then(response => {
            if (
              response.data.data.organization &&
              !response.data.data.is_downgraded_owner
            ) {
              if (
                isInvalidProAccount(
                  safeGet(() => response.data.data.organization.plan)
                )
              ) {
                browserHistory.push("/setting/account");
              }
              saveUserInfoWithOrg(
                response.data.data.publisher,
                response.data.data.organization,
                response.data.data.organization_role
              );
              localStorage.setItem(
                "plan",
                response.data.data.organization.plan
              );
            } else {
              if (
                isInvalidProAccount(
                  safeGet(() => response.data.data.publisher.plan)
                )
              ) {
                browserHistory.push("/setting/account");
              }
              saveUserInfo(response.data.data.publisher);
              localStorage.setItem("plan", response.data.data.publisher.plan);
            }

            // log into profitwell
            ProfitWell.logActiveUser(getUserEmail());
            //Todo: @tx convert the call-site of these localstroage params to use userInfo module
            localStorage.setItem("name", response.data.data.publisher.name);
          })
          .catch(error => {
            localStorage.removeItem("token");
            this._redirectToLandingPage();
          });

        const info = getUserInfo();
      }
    }

    _redirectToLandingPage = () => {
      window.location.assign(`https://${domainName}/sign_out`);
    };

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
}
