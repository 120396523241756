//@flow
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getUrlVars, getOrgName } from "../Library";
import { browserHistory } from "react-router";
import { serverAddress, domainName } from "../config";
import LoadingScreen from "../Component/LoadingScreen";
import axios from "axios";
import {
  saveUserInfo,
  type userInfoType,
  saveUserInfoWithOrg,
  type orgInfoType,
  getUserInfo
} from "../Library/UserInfo";
import { safeGet } from "../Library/Util";
import { isInvalidProAccount } from "../Library/Account";

type State = {|
  isAuthenticated: boolean
|};
type InjectedProps = {|
  isAuthenticated: boolean
|};

function withAuthCode<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    _authCode: string;
    _organization: string;

    state = {
      isAuthenticated: false
    };

    componentWillMount() {
      this._authCode = this._getAuthCodeFromURL();
      this._organization = this._getOrgNameFromURL();
      // this._removeCodeFromURL();

      if (this._authCode) {
        axios
          .get(
            serverAddress +
              "api/v1/get_access_token?auth_code=" +
              this._authCode +
              "&org_name=" +
              this._organization,
            {
              headers: {
                "Cache-Control":
                  "no-store, no-cache, must-revalidate, max-age=0"
              }
            }
          )
          .then(response => {
            this.setState({
              isAuthenticated: true
            });
            if (
              response.data.data.organization &&
              !response.data.data.is_downgraded_owner
            ) {
              saveUserInfoWithOrg(
                response.data.data.publisher,
                response.data.data.organization,
                response.data.data.organization_role
              );
              localStorage.setItem(
                "plan",
                response.data.data.organization.plan
              );
              localStorage.setItem("name", response.data.data.publisher.name);
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem(
                "refreshToken",
                response.data.data.refresh_token
              );
              if (
                isInvalidProAccount(
                  safeGet(() => response.data.data.organization.plan)
                )
              ) {
                browserHistory.push("/setting/account");
              }
            } else {
              saveUserInfo(response.data.data.publisher);
              localStorage.setItem("plan", response.data.data.publisher.plan);
              localStorage.setItem("name", response.data.data.publisher.name);
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem(
                "refreshToken",
                response.data.data.refresh_token
              );
              if (
                isInvalidProAccount(
                  safeGet(() => response.data.data.publisher.plan)
                )
              ) {
                browserHistory.push("/setting/account");
              }
            }
          })
          .catch(function (error) {
            // Yet to Implement, token expiration
            window.location.assign(`https://${domainName}/sign_out`);
          });
      }
    }

    _getAuthCodeFromURL = () => {
      return getUrlVars()["code"];
    };

    _getOrgNameFromURL = () => {
      return getOrgName();
    };

    _removeCodeFromURL = () => {
      browserHistory.push("/workspace/0");
    };

    render() {
      if (this.state.isAuthenticated || !this._authCode) {
        return (
          <WrappedComponent
            isAuthenticated={this.state.isAuthenticated}
            {...this.props}
          />
        );
      } else {
        return <LoadingScreen />;
      }
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withAuthCode
);
