//@flow
import * as React from "react";
import { UICoreBox, UICoreText } from "../../index";
import {
  type tagInputTagType,
  tagInputNodeEnum
} from "../../../../FlowTypes/UICoreTagInputTypes";
import { type Props as UICoreTextProps } from "../../UICoreText";
import "./style.css";
import { getHexCode } from "../../../../Library/Colors";
import { ColorTypeEnum } from "../../../../FlowTypes/colorTypes";
type Props = {|
  tag: tagInputTagType,
  tagTextStyle?: UICoreTextProps,
  tagHexColor?: string,
  onTagDelete: () => void
|};
type State = {||};
class UICoreTagInputTag extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <span
        contentEditable={false}
        data-content={this.props.tag.id}
        data-type={tagInputNodeEnum.tag}
      >
        <UICoreBox
          dangerous_style={{
            display: "inline-flex",
            paddingTop: "2px",
            paddingBottom: "2px",
            transform: "translateY(-3px)"
          }}
          shape="rounded"
          contentEditable={false}
          justifyContent="center"
          alignItems="center"
          direction="row"
          hexColor={
            this.props.tagHexColor || getHexCode(ColorTypeEnum.lightGrey)
          }
        >
          <UICoreBox maxWidth="160px" paddingLeft="xm" paddingRight="xm">
            <UICoreText
              disableNewLine={true}
              overflow="ellipsis"
              dangerous_style={{ lineHeight: "normal" }}
              size="md"
              {...this.props.tagTextStyle}
            >
              {this.props.tag.content}
            </UICoreText>
          </UICoreBox>
          <UICoreText weight="light" {...this.props.tagTextStyle}>
            {"| "}
          </UICoreText>
          <i
            onClick={this.props.onTagDelete}
            style={{ fontSize: "12px", paddingRight: "8px", paddingTop: "3px" }}
            className="ion-close UICoreTagInputTag-close"
          ></i>
        </UICoreBox>
      </span>
    );
  }
}
export default UICoreTagInputTag;
