import React, { Component } from "react";
import "./Toggle.css";
import "./style.css";
import PropTypes from "prop-types";
import { UICoreText } from "../../../../Component/UICore";

export class Toggle extends Component {
  render() {
    const toggle_name = this.props.toggleName;
    if (this.props.isOn) {
      return (
        <div className="ToggleWrapper">
          <UICoreText color="black" size="xs">
            {toggle_name ? toggle_name : "No Toggle Name Given"}
          </UICoreText>
          <div
            onClick={this.props.handleToggleClick}
            className="ToggleWrapper-Toggle"
          >
            <div className="ToggleWrapper-Toggle-ball" />
          </div>
        </div>
      );
    } else {
      return (
        <div className="ToggleWrapper">
          <UICoreText color="black" size="xs">
            {toggle_name ? toggle_name : "No Toggle Name Given"}
          </UICoreText>
          <div
            onClick={this.props.handleToggleClick}
            className="ToggleWrapper-Toggle ToggleWrapper-Toggle-close"
          >
            <div className="ToggleWrapper-Toggle-ball ToggleWrapper-Toggle-ball-close" />
          </div>
        </div>
      );
    }
  }
}

class ToggleWrapper extends Component {
  handleToggleClick() {
    const toggle_name = this.props.ToggleName;
    const current =
      this.props.question && this.props.question.config[toggle_name];
    this.props.update_question_config(
      this.props.ToggleName,
      !current,
      this.props.question_id
    );
  }
  render() {
    return (
      <Toggle
        handleToggleClick={() => this.handleToggleClick()}
        toggleName={this.props.ToggleName}
        isOn={
          this.props.question &&
          this.props.question.config &&
          this.props.question.config[this.props.ToggleName]
        }
      />
    );
  }
}

ToggleWrapper.propTypes = {
  ToggleName: PropTypes.string.isRequired
};

export default ToggleWrapper;
