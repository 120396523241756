//@flow
import * as React from "react";
import {
  type yesNoCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import YesNoComponent from "../../CellComponents/YesNoComponent";
import "./style.css";
import { UICoreBox } from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: yesNoCellType => mixed
|};
type State = {||};
class YesNoDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox alignItems="start">
        <YesNoComponent {...this.props} />
      </UICoreBox>
    );
  }
}
export default YesNoDataUI;
