//@flow
import * as React from "react";
import "./style.css";
import {
  type columnConfigType,
  type columnIDType,
  type multipleSelectCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import {
  UICoreText,
  UICoreBox,
  UICoreSearchBox
} from "../../../../../Component/UICore";
import ChoiceComponent from "./ChoiceComponent";
import CellPopupWrapper from "../../CellPopup/CellPopupWrapper";
import { toStringArray, isNonEmptyArray } from "../../../../../Library/Util";
import withUpdateColumnConfig from "../../../HOC/withUpdateColumnConfig";
import { updateColumnConfig } from "../../../State/TableState/action";
import { Scrollbars } from "react-custom-scrollbars";
import { toArray } from "../../../../../Library/Util";

type Props = {|
  ...DataComponentPropsType,
  updateCell: multipleSelectCellType => mixed,
  updateColumnConfig: (columnIDType, columnConfigType) => void
|};
type State = {|
  uiState: $Keys<typeof UIState>,
  choiceSearchTerm: string
|};

export const colors = [
  "lightLime",
  "lightRed",
  "lightOrgange",
  "lightYellow",
  "lightGreen",
  "skyBlue",
  "lightPurple"
];

export const getChoiceColor = (choice: string, choices: Array<string>) => {
  const index = choices.indexOf(choice);
  return colors[index % 7];
};

const UIState = {
  cellUI: "cellUI",
  expandedUI: "expandedUI",
  selectChoicesUI: "selectChoicesUI"
};
class MultiselectComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    uiState: UIState.cellUI,
    choiceSearchTerm: ""
  };

  _renderCreateChoice = () => {
    return (
      <UICoreBox
        padding="xm"
        direction="row"
        alignItems="start"
        hoverable={true}
      >
        <UICoreBox marginRight="xm">
          <UICoreText size="xs">create option</UICoreText>
        </UICoreBox>
        <ChoiceComponent text={this.state.choiceSearchTerm} />
      </UICoreBox>
    );
  };

  _renderChoices = (choices: any, componentRenderer: string => React.Node) => {
    return toStringArray(choices).map(choice => componentRenderer(choice));
  };

  _showChoiceViewer = () => {
    this.setState({
      uiState: UIState.expandedUI
    });
  };

  _closeChoiceViewer = () => {
    this.setState({
      uiState: UIState.cellUI
    });
  };

  _handleCellClick = () => {
    if (isNonEmptyArray(this.props.value)) {
      this._showChoiceViewer();
    } else {
      this._showChoiceSelector();
    }
  };

  _deleteChoice = (choiceText: string) => {
    this.props.updateCell(
      toStringArray(this.props.value).filter(choice => choice !== choiceText)
    );
  };

  _showChoiceSelector = () => {
    this.setState({
      uiState: UIState.selectChoicesUI,
      choiceSearchTerm: ""
    });
  };

  _getAvailableChoices = () => {
    return toStringArray(this._getCurrentChoices())
      .filter(choice => !toStringArray(this.props.value).includes(choice))
      .filter(choice =>
        choice.toLowerCase().includes(this.state.choiceSearchTerm.toLowerCase())
      );
  };

  _addChoice = (choice: string) => {
    this.props.updateCell([...toStringArray(this.props.value), choice]);
  };

  _handleChoiceSelect = (choice: string) => {
    this._addChoice(choice);
  };

  _handleChoiceSearch = (
    e: SyntheticInputEvent<HTMLInputElement>,
    input: string
  ) => {
    this.setState({
      choiceSearchTerm: input
    });
  };

  _getCurrentChoices = (): Array<string> => {
    let currentChoices = [];
    if (this.props.config && this.props.config.choices) {
      currentChoices = this.props.config.choices;
    }
    return toArray(currentChoices);
  };

  _handleNewChoiceClick = () => {
    this.props.updateColumnConfig(this.props.columnID, {
      choices: [
        ...toStringArray(this._getCurrentChoices()),
        this.state.choiceSearchTerm
      ]
    });
    this._addChoice(this.state.choiceSearchTerm);
    this.setState({
      uiState: UIState.expandedUI
    });
  };

  _shouldRenderAddChoice = (): boolean => {
    if (!this.state.choiceSearchTerm) return false;
    //show add choice option when there is no exact match
    return !toStringArray(this._getCurrentChoices())
      .map(choice => choice.toLowerCase())
      .includes(this.state.choiceSearchTerm.toLowerCase());
  };

  _renderChoicesSelector = () => {
    const choiceListUI = this._renderChoices(
      this._getAvailableChoices(),
      (choice: string) => (
        <UICoreBox
          onClick={() => {
            this._handleChoiceSelect(choice);
          }}
          hoverable={true}
          padding="xm"
        >
          {choice}
        </UICoreBox>
      )
    );

    const newChoiceUI = (
      <UICoreBox
        onClick={this._handleNewChoiceClick}
        direction="row"
        hoverable={true}
        padding="xm"
        overflowX="hidden"
      >
        <UICoreText size="xs">create choice </UICoreText>
        <UICoreBox marginLeft="xm">
          <ChoiceComponent text={this.state.choiceSearchTerm} />
        </UICoreBox>
      </UICoreBox>
    );

    return (
      <UICoreBox
        marginTop="xm"
        paddingTop="xm"
        shape="rounded"
        color="white"
        boxShadow={true}
      >
        <UICoreBox paddingLeft="xm" paddingRight="xm" paddingBottom="xm">
          <UICoreSearchBox
            onChange={this._handleChoiceSearch}
            autoFocus={true}
            placeholder="Find or create a choice"
            value={this.state.choiceSearchTerm}
          />
        </UICoreBox>
        <UICoreBox>
          <Scrollbars autoHeight autoHeightMax={150}>
            {choiceListUI}
          </Scrollbars>
        </UICoreBox>

        {this._shouldRenderAddChoice() && newChoiceUI}
      </UICoreBox>
    );
  };

  render() {
    const choicesWithDelete = this._renderChoices(
      this.props.value,
      cellValue => (
        <UICoreBox paddingBottom="xm">
          <ChoiceComponent
            deleteChoice={this._deleteChoice}
            withDeleteButton={true}
            text={cellValue}
            color={getChoiceColor(cellValue, this._getCurrentChoices())}
          />
        </UICoreBox>
      )
    );
    const choices = this._renderChoices(this.props.value, cellValue => (
      <ChoiceComponent
        color={getChoiceColor(cellValue, this._getCurrentChoices())}
        text={cellValue}
      />
    ));
    if (this.state.uiState === UIState.cellUI) {
      return (
        <UICoreBox
          onClick={this._handleCellClick}
          direction="row"
          className="MultiselectComponent"
          width="100%"
          height="100%"
          padding="xm"
        >
          {choices}
        </UICoreBox>
      );
    } else {
      return (
        <CellPopupWrapper width={250} onDismiss={this._closeChoiceViewer}>
          <UICoreBox color="white" shape="rounded" boxShadow={true}>
            <UICoreBox
              wrap="wrap"
              direction="row"
              paddingTop="xm"
              paddingLeft="xm"
              paddingRight="xm"
            >
              {choicesWithDelete}
              <UICoreBox
                onClick={this._showChoiceSelector}
                width={20}
                height={20}
                shape="rounded"
                color="lightGrey"
                alignItems="center"
                justifyContent="center"
                className="MultiselectComponent-addButton"
              >
                <i className="fa fa-plus" />
              </UICoreBox>
            </UICoreBox>
          </UICoreBox>
          {this.state.uiState === UIState.selectChoicesUI &&
            this._renderChoicesSelector()}
        </CellPopupWrapper>
      );
    }
  }
}
export default withUpdateColumnConfig(MultiselectComponent);
