// @flow

import {
  buttonTextConfigWithDefaultFilled,
  type buttonConfigType
} from "../Helper_HOC/WithConfigurableButtonText";
import { ButtonType } from "../Helper_HOC/WithConfigurableButtonText/config";

const validatorTypeCustomTextMap = {
  require: ButtonType.requiredValidation,
  email: ButtonType.emailValidation,
  fileSize: ButtonType.fileSizeValidation,
  date: ButtonType.dateValidation,
  maxWordCount: ButtonType.wordCountValidation
};

export function getCustomValidationErrorMessage(
  customizedButtonText: ?buttonConfigType,
  validatorName: string
): string {
  if (!customizedButtonText) {
    return "";
  }
  const buttonTextConfig = buttonTextConfigWithDefaultFilled(
    customizedButtonText
  );
  return buttonTextConfig[validatorTypeCustomTextMap[validatorName]];
}
