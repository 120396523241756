import React, { Component } from "react";
import "./style.css";

/*
    label
    value
    num_data
*/
class Bar extends Component {
  constructor(props) {
    super(props);
    this.node = null;
  }

  getPercentage() {
    const percentage = (this.props.value * 100) / this.props.num_data;
    const percentage_str = percentage.toString();
    const pos_of_dot = percentage_str.indexOf(".");
    const rounded_percentage =
      pos_of_dot == -1
        ? percentage_str
        : percentage_str.substr(0, pos_of_dot + 2);
    return rounded_percentage + "%";
  }

  renderLabel() {
    const percentage = (this.props.value * 100) / this.props.num_data;
    const CharCount = this.props.label.length;
    if ((percentage < 15 && CharCount > 10) || percentage < 5) {
      return <div className="Bar-label-dark">{this.props.label}</div>;
    } else {
      return <div className="Bar-label-dark">{this.props.label}</div>;
    }
  }

  render() {
    return (
      <div className="Bar">
        <div className="Bar-Percentage">{this.getPercentage()}</div>
        <div className="Bar-Bar">
          <div
            ref={e => {
              this.bar_node = e;
            }}
            className="Bar-active"
            style={{ width: this.getPercentage() }}
          ></div>
          {this.renderLabel()}
          <div className="Bar-response">
            {this.props.value +
              (this.props.type != "plain" ? " Responses" : "")}
          </div>{" "}
        </div>
      </div>
    );
  }
}

export default Bar;
