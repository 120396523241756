//@flow
import { type formViewerContextType } from "../../FlowTypes/viewerContextTypes";
import { type formPlan as formPlanType } from "../../Pages/WispformTypings";
import { safeGet } from "../Util";
import { getFormUID, getFirstSubDomain } from "..";

let _formViewerContext: ?formViewerContextType = null;

export function initFormViewerContext(
  formID: number,
  formPlan: formPlanType,
  publisherName: ?string,
  publisherID: number
) {
  _formViewerContext = {
    ..._formViewerContext,
    formID: formID,
    formPlan: formPlan,
    publisherName: publisherName,
    formUid: getFormUID(),
    publisherID: publisherID
  };
}

export function getFormViewerContext() {
  return _formViewerContext;
}

export function getFormUid() {
  return (_formViewerContext && _formViewerContext.formUid) || getFormUID();
}

export function getPublisherID(): ?number {
  return _formViewerContext && _formViewerContext.publisherID;
}

export function getPublisherName() {
  return (
    (_formViewerContext && _formViewerContext.publisherName) ||
    getFirstSubDomain()
  );
}

export function isFormFromPaidPlan() {
  if (_formViewerContext && _formViewerContext.formPlan) {
    return (
      _formViewerContext.formPlan === "Premium" ||
      _formViewerContext.formPlan === "Pro"
    );
  }
  return false;
}
