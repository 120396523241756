//@flow
import * as React from "react";
import { UICoreText } from "../index";
import "./style.css";
type Props = {|
  value: string,
  autoFocus?: boolean,
  width?: number | string,
  placeholder?: string,
  onChange: (SyntheticInputEvent<HTMLInputElement>, value: string) => void
|};
type State = {||};
class UICoreSearchBox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (this.props.autoFocus) {
      setTimeout(() => {
        this._inputNode && this._inputNode.focus();
      }, 100);
    }
  }

  _inputNode: ?HTMLInputElement = null;

  _handleInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChange(e, e.target.value);
  };

  _getStyle = () => {
    return {
      width: this.props.width ? this.props.width : null
    };
  };

  render() {
    return (
      <div className="UICoreSearchBox">
        <input
          ref={ref => (this._inputNode = ref)}
          style={this._getStyle()}
          className="UICoreSearchBox-input"
          onChange={this._handleInputChange}
          value={this.props.value}
          placeholder={this.props.placeholder}
        />
        <div className="UICoreSearchBox-SearchIcon">
          <i className="fa fa-search" />
        </div>
      </div>
    );
  }
}
export default UICoreSearchBox;
