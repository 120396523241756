//@flow

import { ADD_QUESTION_TYPE, UPDATE_QUESTION_TITLE } from "../actions";
import {
  type actionType,
  CLOSE_ONBOARDING_BACKGROUND,
  START_ONBOARDING_PROCESS,
  SHARE_TOOLTIP_CLOSE
} from "./actions";
import { logNewUserOnboarding } from "../../../../Library/Logger";

const HAS_ONBOARDING_SHOWN = "HAS_ONBOARDING_SHOWN";
export const ONBOARDING_STAGE = {
  emptyBuilder: "emptyBuilder",
  firstQuestionSelected: "firstQuestionSelected",
  firstQuestionTitleTyped: "firstQuestionTitleTyped",
  secondQuestionSelected: "secondQuestionSelected",
  shareTooltipClosed: "shareTooltipClosed"
};

export type onBoardingStageType =
  | "emptyBuilder"
  | "firstQuestionSelected"
  | "firstQuestionTitleTyped"
  | "secondQuestionSelected"
  | "shareTooltipClosed";

let defaultState = {
  showOnboardingBackground: false,
  onboardingStage: null
};

type onboardingState = {|
  showOnboardingBackground: boolean,
  onboardingStage: ?onBoardingStageType
|};

export default function OnBoardingState(
  state: onboardingState = defaultState,
  action: actionType
) {
  switch (action.type) {
    case START_ONBOARDING_PROCESS:
      logNewUserOnboarding("onboardingStarted");
      return {
        ...state,
        showOnboardingBackground: true,
        onboardingStage: ONBOARDING_STAGE.emptyBuilder
      };

    case ADD_QUESTION_TYPE:
      if (state.onboardingStage === ONBOARDING_STAGE.emptyBuilder) {
        logNewUserOnboarding("firstQuestionSelected");
        return {
          //turn off on-boarding at this point to see if engagement improves
          ...state,
          showOnboardingBackground: false,
          // onboardingStage: ONBOARDING_STAGE.firstQuestionSelected
          onboardingStage: ONBOARDING_STAGE.secondQuestionSelected
        };
      } else if (
        state.onboardingStage === ONBOARDING_STAGE.firstQuestionSelected ||
        state.onboardingStage === ONBOARDING_STAGE.firstQuestionTitleTyped
      ) {
        logNewUserOnboarding("secondQuestionSelected");
        return {
          ...state,
          showOnboardingBackground: false,
          onboardingStage: ONBOARDING_STAGE.secondQuestionSelected
        };
      } else {
        return state;
      }

    case CLOSE_ONBOARDING_BACKGROUND:
      logNewUserOnboarding("onboardingDismissed");
      return {
        ...state,
        showOnboardingBackground: false
      };

    case UPDATE_QUESTION_TITLE:
      if (state.onboardingStage === ONBOARDING_STAGE.firstQuestionSelected) {
        logNewUserOnboarding("firstQuestionTitleTyped");
        return {
          ...state,
          showOnboardingBackground: true,
          onboardingStage: ONBOARDING_STAGE.firstQuestionTitleTyped
        };
      }
      return state;

    case SHARE_TOOLTIP_CLOSE:
      return {
        ...state,
        onboardingStage: ONBOARDING_STAGE.shareTooltipClosed
      };
    default:
      return state;
  }
}

/*
  show tooltip when user is new to wispform
  definition of new:
  No dismiss log in localstorage
  No question created in this page
*/
function shouldShowOnboardingProcess() {
  const has_onboarding_shown = localStorage.getItem(HAS_ONBOARDING_SHOWN);
  return !has_onboarding_shown;
}
