import React, { Component } from "react";
import DropDown from "./index";

class builderDropdown extends Component {
  constructor(props) {
    super(props);
    this.dropdown = null;
  }
  componentDidMount() {
    if (this.props.contents && this.props.contents.length > 0) {
      this.dropdown.open();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.props.contents && this.props.contents.length > 0) {
      this.dropdown.open();
    }
  }

  render() {
    return (
      <DropDown
        fakeRef={dropdown => (this.dropdown = dropdown)}
        {...this.props}
      />
    );
  }
}

export default builderDropdown;
