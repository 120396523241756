//@flow
import React, { Component } from "react";
import Loading from "../../../../Component/Loading";
import { connect } from "react-redux";
import { initWithDefaultWispformTrackingID } from "../../../../GA";
import "./style.css";
import "./style.mobile.css";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import withStyles from "../../../../Helper_HOC/WithStyles";
import withConfigurableButtonTextHOC, {
  type InjectedProps as injectedPropsFromConfigurableButtonTextHOC,
  ButtonTypeFromHOC
} from "../../../../Helper_HOC/WithConfigurableButtonText";
import { UICoreText } from "../../../../Component/UICore";

const ReactGA = initWithDefaultWispformTrackingID();
type Props = {|
  ...injectedPropsFromConfigurableButtonTextHOC,
  onClick: () => mixed,
  submitting: boolean,
  styles: formStyleType,
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  buttonTextColor: string,
  buttonPrimaryColor: string,
  buttonBorderColor: string,
  buttonTextSecondaryColor: string
|};
class SubmitButton extends Component<Props> {
  componentDidMount() {
    /*
            cancel the touchend event
            to prevent click event of submit button from being canceled
            by touchend event registered in submit-form area
        */
    const SubmitButtonNode = document.getElementsByClassName(
      "Submit-Button"
    )[0];
    const Handler: EventListener = (e: Event) => {
      e.stopPropagation();
    };
    if (SubmitButtonNode) {
      SubmitButtonNode.addEventListener("touchend", Handler);
    }
  }

  handleClick(e) {
    e.stopPropagation();
    //dont submit again if already submitting
    if (this.props.submitting) {
      return;
    } else {
      this.props.onClick();
      ReactGA.event({
        category: "Form",
        action: "Submit Form",
        label: window.location.href
      });
    }
  }

  _submitTextStyle = () => {
    return {
      color: this.props.buttonTextColor
    };
  };

  _descriptionTextStyle = () => {
    return {
      color: this.props.buttonTextSecondaryColor
    };
  };

  renderContent() {
    if (this.props.submitting) {
      return [
        <UICoreText
          alignment="center"
          hexColor={this.props.buttonTextColor}
          className="Submit-Button-text Submit-Button-Opacity"
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {this.props.buttonTextConfig[ButtonTypeFromHOC.submitButton]}
        </UICoreText>,
        <UICoreText
          alignment="center"
          hexColor={this.props.buttonTextSecondaryColor}
          className="Submit-Button-warning Submit-Button-Opacity"
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {this.props.buttonTextConfig[ButtonTypeFromHOC.submitButtonSubText]}
        </UICoreText>,
        <Loading className="Submit-Button-Loading" />
      ];
    } else {
      return [
        <UICoreText
          alignment="center"
          hexColor={this.props.buttonTextColor}
          className="Submit-Button-text"
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {this.props.buttonTextConfig[ButtonTypeFromHOC.submitButton]}
        </UICoreText>,
        <UICoreText
          alignment="center"
          hexColor={this.props.buttonTextSecondaryColor}
          className="Submit-Button-warning"
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {this.props.buttonTextConfig[ButtonTypeFromHOC.submitButtonSubText]}
        </UICoreText>
      ];
    }
  }

  _submitButtonStyle = () => {
    return {
      backgroundColor: this.props.buttonPrimaryColor,
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: this.props.buttonBorderColor,
      color: this.props.buttonTextColor
    };
  };

  //<div className="Submit-Button-text">Submit</div>
  //<div className="Submit-Button-warning">Never submit Passwords!</div>
  render() {
    return (
      <div
        style={this._submitButtonStyle()}
        onClick={this.handleClick.bind(this)}
        className="Submit-Button"
      >
        {this.renderContent()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    submitting: state.Answers.submitting
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(withConfigurableButtonTextHOC(SubmitButton)));
