//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox, UICoreText, UICoreFlyOut } from "../../UICore";
import ColorPicker from "..";
type Props = {|
  selectedHexColor: string,
  onColorSelected: string => void
|};
type State = {|
  showColorPicker: boolean
|};

class DropdownColorPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showColorPicker: false
    };
  }

  _dropDownHeadNode: ?HTMLElement;

  render() {
    return (
      <UICoreBox>
        <UICoreBox
          shape="rounded"
          color="lightGrey"
          direction="row"
          alignItems="center"
          direction="row"
          width={110}
          paddingTop="xxm"
          paddingBottom="xxm"
          paddingLeft="xm"
          position="relative"
          hoverable={true}
          onClick={() => this.setState({ showColorPicker: true })}
          innerRef={targetNode => {
            this._dropDownHeadNode = targetNode;
          }}
        >
          <UICoreBox
            hexColor={this.props.selectedHexColor}
            width={12}
            height={12}
            dangerous_style={{ borderRadius: "3px" }}
          >
            {""}
          </UICoreBox>
          <UICoreBox paddingLeft="xxm">
            <UICoreText size="xs">
              {String(this.props.selectedHexColor)}
            </UICoreText>
          </UICoreBox>
          <UICoreBox position="absolute" right="10px">
            <i className="fa fa-caret-down"></i>
          </UICoreBox>
        </UICoreBox>
        {this.state.showColorPicker && (
          <UICoreFlyOut
            idealDirection="down"
            forceDirection={true}
            onDismiss={() => this.setState({ showColorPicker: false })}
            anchor={this._dropDownHeadNode}
          >
            <UICoreBox padding="sm">
              <ColorPicker
                selectedHexColor={this.props.selectedHexColor}
                onColorSelected={this.props.onColorSelected}
              />
            </UICoreBox>
          </UICoreFlyOut>
        )}
      </UICoreBox>
    );
  }
}
export default DropdownColorPicker;
