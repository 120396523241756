//@flow
import * as React from "react";
import "./style.css";
import { UICoreInput, UICoreText } from "../../UICore";
type Props = {|
  value: string,
  fontFamily: ?string,
  onChange: string => void,
  color: string,
  questionContent: any
|};
type State = {||};
class MobileDateInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return [
      <UICoreText
        fontFamily={this.props.fontFamily || "Roboto"}
        hexColor={this.props.color}
        size="lg"
      >
        {this.props.value ||
          (this.props.questionContent &&
          this.props.questionContent.dateFormat &&
          this.props.questionContent.dateFormat === "DD/MM/YYYY"
            ? "DD/MM/YYYY"
            : "MM/DD/YYYY")}
      </UICoreText>,
      <input
        style={{
          opacity: 0,
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "100%",
          minWidth: "100%",
          height: "100%"
        }}
        type="date"
        onChange={(event: SyntheticEvent<HTMLInputElement>) => {
          this.props.onChange(event.currentTarget.value);
        }}
      />
    ];
  }
}
export default MobileDateInput;
