// @flow
import { type redirectParamConfigType } from "../FlowTypes/formRedirectTypes";
import { isNullOrUndefined, nonNullOrThrow } from "./Util";
import { type AnswerType } from "../Pages/QuestionTypes";
import { getQuestionIdPipedAnswerMap } from "./AnswerPiping";
import { constructURL, searchStringToMap } from "./URL";
import { convertStringRep2TagFilledString } from "../Component/UICore/UICoreTagInput/InputManager";

function _injectedSearchStringMap(
  redirectParam: redirectParamConfigType,
  questionIdPipedAnswerMap: { [string]: string },
  questionIdTitleMap: { [string]: string }
) {
  return redirectParam.reduce((prev, current) => {
    const paramName =
      current.paramName || questionIdTitleMap[current.questionID];
    return {
      ...prev,
      [paramName]: questionIdPipedAnswerMap[current.questionID]
    };
  }, {});
}

function _questionIdTitleMap(answers: Array<AnswerType>): { [string]: string } {
  return answers.reduce((prev, current) => {
    return {
      ...prev,
      [current.question_id]: current.title
    };
  }, {});
}

export function getFormRedirectLinkWithAnswerParam(
  rawUrl: ?string,
  redirectParam: ?redirectParamConfigType,
  answers: Array<AnswerType>
): string {
  const answerFilledUrl = convertStringRep2TagFilledString(
    rawUrl || "",
    getQuestionIdPipedAnswerMap(answers)
  );
  if (isNullOrUndefined(redirectParam)) {
    return answerFilledUrl;
  }
  try {
    const rawUrlObject = new URL(answerFilledUrl);
    const questionIdPipedAnswerMap = getQuestionIdPipedAnswerMap(answers);
    const originalSearchStringMap = searchStringToMap(rawUrlObject.search);
    return constructURL(rawUrlObject.origin, rawUrlObject.pathname, {
      ...originalSearchStringMap,
      ..._injectedSearchStringMap(
        nonNullOrThrow(redirectParam),
        questionIdPipedAnswerMap,
        _questionIdTitleMap(answers)
      )
    });
  } catch (error) {
    return answerFilledUrl;
  }
}
