//@flow
import {
  getRequest,
  postRequest,
  getRequestWithoutAuth
} from "../../Library/Request";
import {
  getReferral,
  safeGet,
  isLocalStorageAvailable
} from "../../Library/Util";
import { type formDataType, type AnswerReduxStateType } from "../QuestionTypes";
import { type wispformIDType } from "../../Pages/WispTable/Configuration";
import { getFirstSubDomain } from "../../Library";
import { loadGoogleFont } from "../../Library/GoogleFont";

export function getFormData(formID: string): Promise<formDataType> {
  return getRequestWithoutAuth(
    `api/v1/forms/${formID}/question_details/${formID}`,
    {
      existing_form_view_uuid: isLocalStorageAvailable()
        ? localStorage.getItem(formID)
        : null
    },
    {
      referral: getReferral(),
      form_name: getFirstSubDomain()
    }
  ).then(response => {
    localStorage.setItem(
      response.data.data.form_uuid,
      response.data.data.form_view_uuid
    );
    const questionDetails = response.data.data.question_details;
    loadGoogleFont(safeGet(_ => questionDetails.styles.fontFamily));
    return {
      ...questionDetails,
      form_type: response.data.data.form_type,
      form_plan: response.data.data.form_plan,
      publisherID: response.data.data.publisher_id
    };
  });
}

export function postForm(
  answer: AnswerReduxStateType,
  formID: string
): Promise<any> {
  return postRequest(`api/v1/forms/${formID}/responses`, answer).then(
    response => response.data.data
  );
}

export type wispformInfoType = {|
  created_at: string,
  id: number,
  name: string,
  response_count: number,
  updated_at: string,
  uuid: string
|};

export function getWispformInfo(
  formID: wispformIDType
): Promise<wispformInfoType> {
  return getRequest(`api/v1/forms/${formID}`).then(
    response => response.data.data.form
  );
}
