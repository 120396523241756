//@flow

export const UPDATE_FORM_CONFIG = "UPDATE_FORM_CONFIG";

export type actionType = updateFormConfigType;

type updateFormConfigType = {|
  type: typeof UPDATE_FORM_CONFIG
|};
export function updateFormConfig() {
  return {
    type: UPDATE_FORM_CONFIG
  };
}
