//@flow
import {
  DID_GET_QUESTIONS,
  SUBMIT_ANSWER,
  UPDATE_ANSWER,
  update_visible_questions,
  set_current_question
} from "../../Pages/Form/States/Answers/actions";
import {
  type questionType,
  type QuestionTypes
} from "../../Pages/QuestionTypes";
import {
  getAllQuestions,
  getVisibleQuestions,
  getAllAnswers,
  getAllQuestionsIdIndexMap
} from "../../Pages/Form/States/Answers/selectors";
import {
  type logicJumpType,
  type branchType,
  type jointLogicType,
  DEFAULT_END
} from "../../FlowTypes/jumpLogicTypes";
import { toArray } from "../../Library/Util";
import { evaluateBooleanExpression, getJumptoQuestionID } from "./helper";
import { type questionIDType } from "../../Pages/WispformTypings";

const offset = 1;

//$FlowFixMe
const LogicJumpMiddleware = store => next => action => {
  switch (action.type) {
    case DID_GET_QUESTIONS:
      //initializa visible questions queue
      next({
        ...action,
        questions: {
          ...action.questions,
          visibleQuestions: initializeQuestions(
            action.questions && action.questions.questions
          )
        }
      });
      break;

    case UPDATE_ANSWER:
      updateVisibleQuestions(store, next, action);
      next(action);
      break;
    case SUBMIT_ANSWER:
      const jumpToQuestionIndexInVisibleQuestionsQueue = updateVisibleQuestions(
        store,
        next,
        action
      );
      if (jumpToQuestionIndexInVisibleQuestionsQueue == null) {
        next(action);
      } else {
        next({
          ...action,
          currentQuestionNumber:
            jumpToQuestionIndexInVisibleQuestionsQueue + offset
        });
      }

      break;

    default:
      return next(action);
  }
};

function initializeQuestions(
  questions: Array<questionType>
): Array<questionType> {
  return getAllQuestionsTillBranch(questions, 0);
}

function updateVisibleQuestions(store, next, action): ?number {
  const answerState = store.getState().Answers;
  const currentAnswer = action.answer;
  const allQuestions = getAllQuestions(answerState);
  const allQuestionsIdIndexMap = getAllQuestionsIdIndexMap(answerState);
  const allAnswers = getAllAnswers(answerState);
  const currentQuestionIndexInAllQuestions = allQuestions.findIndex(
    question => question.question_id === currentAnswer.questionID
  );
  const currentQuestion = allQuestions[currentQuestionIndexInAllQuestions];
  const visibleQuestions = getVisibleQuestions(answerState);
  if (currentQuestion && currentQuestion.jumpLogic) {
    let updatedVisibleQuestions = [];

    /*
      step1: determine where to jump to
    */
    const jumpToQuestionID = getJumptoQuestionID(
      currentQuestion,
      allAnswers,
      currentAnswer,
      allQuestions
    );
    if (jumpToQuestionID === null) return;
    //jump to the end of the form
    if (jumpToQuestionID === DEFAULT_END) {
      //we need to update visible queue because visible queue might include
      //questions after current question eg. short text will dynamically update on each type
      for (let index = 0; index < visibleQuestions.length; index++) {
        const visibleQuestion = visibleQuestions[index];
        updatedVisibleQuestions.push(visibleQuestion);
        if (visibleQuestion.question_id === currentAnswer.questionID) break;
      }
      next(update_visible_questions(updatedVisibleQuestions));
      return toArray(visibleQuestions).length;
    }

    const jumpToQuestionIndex = allQuestions.findIndex(
      q => q.question_id === jumpToQuestionID
    );
    if (jumpToQuestionIndex === -1) return;

    /*
      Step2: update visible questions queue
    */
    //=== jump backward OR jump to itself
    if (jumpToQuestionIndex <= currentQuestionIndexInAllQuestions) {
      const jumpToQuestionIndexInVisibleQueues = visibleQuestions.findIndex(
        q => q.question_id === jumpToQuestionID
      );
      //jump back to a non-existent question in the visible queue
      if (jumpToQuestionIndexInVisibleQueues === -1) {
        //dont change
        updatedVisibleQuestions = [...visibleQuestions];
      } else {
        //jump back to an existed question in the visible queue
        // Update the visible queue until the current question
        for (let index = 0; index < visibleQuestions.length; index++) {
          const iteratingQuestion = visibleQuestions[index];
          const iteratingQuestionIndexInAllQuestions =
            allQuestionsIdIndexMap[iteratingQuestion.question_id];
          updatedVisibleQuestions.push(iteratingQuestion);
          if (
            iteratingQuestionIndexInAllQuestions >=
            currentQuestionIndexInAllQuestions
          )
            break;
        }
      }
    }
    //=== jump forwards
    else {
      //add all the questions in visible queue until current question
      for (let index = 0; index < visibleQuestions.length; index++) {
        const iteratingQuestion = visibleQuestions[index];
        const iteratingQuestionIndexInAllQuestions =
          allQuestionsIdIndexMap[iteratingQuestion.question_id];
        updatedVisibleQuestions.push(iteratingQuestion);
        if (
          iteratingQuestionIndexInAllQuestions >=
          currentQuestionIndexInAllQuestions
        )
          break;
      }
      updatedVisibleQuestions = [
        ...updatedVisibleQuestions,
        ...getAllQuestionsTillBranch(allQuestions, jumpToQuestionIndex)
      ];
    }

    next(update_visible_questions(updatedVisibleQuestions));

    const jumptoQuestionIndexInUpdatedVisibleQueue = updatedVisibleQuestions.findIndex(
      vq => vq.question_id === jumpToQuestionID
    );
    return jumptoQuestionIndexInUpdatedVisibleQueue === -1
      ? null
      : jumptoQuestionIndexInUpdatedVisibleQueue;
  }
}

function getAllQuestionsTillBranch(
  questions: Array<questionType>,
  startingIndex: number
): Array<questionType> {
  const visibleQuestions = [];
  for (let index = startingIndex; index < questions.length; index++) {
    visibleQuestions.push(questions[index]);
    //has a branch
    if (
      questions[index].jumpLogic &&
      toArray(questions[index].jumpLogic.branches).length > 0
    )
      return visibleQuestions;
    //only has a default
    if (questions[index].jumpLogic && questions[index].jumpLogic.default) {
      const defaultJumptToQuestionID = questions[index].jumpLogic.default;
      const defaultJumptToQuestionIndexInAllQuestions = questions.findIndex(
        q => q.question_id === defaultJumptToQuestionID
      );
      //always jump to end, no need to add any of the following questions
      if (defaultJumptToQuestionID === DEFAULT_END) return visibleQuestions;

      //always jump to is after current pos, update current pos(index) so we can add this question to the queue
      if (defaultJumptToQuestionIndexInAllQuestions > index) {
        index = defaultJumptToQuestionIndexInAllQuestions - offset;
        continue;
      }
    }
  }

  return visibleQuestions;
}

export default LogicJumpMiddleware;
