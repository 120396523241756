//@flow
import * as React from "react";
import ShareSectionSkeleton from "../ShareSectionSkeleton";
import { logFeatureEvent } from "../../../../Library/Logger";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreModal,
  UICoreButton,
  UICoreDropdown
} from "../../../../Component/UICore";
import "./style.css";
import { type choiceType } from "../../../../Component/UICore/UICoreDropdown";
type Props = {|
  shareLink: string
|};
type State = {|
  copied: boolean,
  embedHeight: string,
  embedWidth: string,
  embedUnit: choiceType,
  showEmbedFormModal: boolean
|};

const embedUnit = {
  px: "px",
  ["%"]: "% of container",
  ["viewport"]: "% of screen"
};

const EmbedSizeEditor = ({
  text,
  onChange,
  value,
  unit,
  onUnitSelect
}: {
  text: string,
  onChange: (SyntheticInputEvent<HTMLInputElement>, value: string) => void,
  value: string,
  unit: choiceType,
  onUnitSelect: any => void
}): React.Node => {
  return (
    <UICoreBox marginBottom="md" direction="column">
      <UICoreText weight="bold">{text}:</UICoreText>
      <UICoreBox marginTop="xm" direction="row">
        <UICoreBox marginRight="xm">
          <UICoreInput
            value={value}
            onChange={onChange}
            size="xs"
            width="90px"
          />
        </UICoreBox>
        <UICoreDropdown
          onChoiceClick={onUnitSelect}
          width="120px"
          selectChoice={unit}
          choices={[
            { value: "px", display: embedUnit["px"] },
            { value: "viewport", display: embedUnit["viewport"] },
            { value: "%", display: embedUnit["%"] }
          ]}
        />
      </UICoreBox>
    </UICoreBox>
  );
};

class EmbedFormSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    copied: false,
    embedHeight: "840",
    embedWidth: "640",
    embedUnit: { value: "px", display: "px" },
    showEmbedFormModal: false
  };

  _embed: ?HTMLInputElement = null;

  componentDidMount() {
    logFeatureEvent("share/embedFormSectionDisplayed");
  }

  _updateWidth = (e: any, width: string) =>
    this.setState({ embedWidth: width });

  _updateHeight = (e: any, height: string) =>
    this.setState({ embedHeight: height });

  _handleUnitSelect = (value: any) =>
    this.setState({ embedUnit: { value: value, display: embedUnit[value] } });

  _getEmbedUnitInCss = (value: string, position: "height" | "width") => {
    if (value === "viewport") {
      if (position === "height") {
        return "vh";
      } else if (position === "width") {
        return "vw";
      } else {
        return "";
      }
    } else {
      return value;
    }
  };

  _handleCopyButtonClick = (e: SyntheticEvent<>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this._embed && this._embed.select) {
      this._embed.select();
    }
    try {
      document.execCommand("copy");
      this._embed && this._embed.blur();
      logFeatureEvent("share/embedFormCodeCopied");
      setTimeout(() => {
        this.setState({
          copied: true
        });
      }, 300);
    } catch (err) {
      console.error("cant execute copy command");
    }
  };

  render() {
    return (
      <ShareSectionSkeleton
        settingColumn={
          <UICoreBox
            paddingLeft="sm"
            paddingRight="sm"
            width="100%"
            height="300px"
          >
            <UICoreText overflow="wrap" color="Grey">
              Embed Wispform to your web page.
            </UICoreText>
            <UICoreBox marginTop="md">
              <UICoreBox direction="column">
                <EmbedSizeEditor
                  onChange={this._updateHeight}
                  value={this.state.embedHeight}
                  unit={this.state.embedUnit}
                  text="Embed Form Height"
                  onUnitSelect={this._handleUnitSelect}
                />
                <UICoreBox>
                  <EmbedSizeEditor
                    onChange={this._updateWidth}
                    value={this.state.embedWidth}
                    unit={this.state.embedUnit}
                    text="Embed Form Width"
                    onUnitSelect={this._handleUnitSelect}
                  />
                </UICoreBox>
              </UICoreBox>
              {this.state.showEmbedFormModal && (
                <UICoreModal
                  onDismiss={() => {
                    this.setState({ showEmbedFormModal: false });
                  }}
                  size="md"
                  header="Copy embed code"
                  body={
                    <UICoreBox padding="md">
                      <UICoreBox marginBottom="sm">
                        <UICoreText color="black" overflow="wrap">
                          Copy and paste this HTML code in the place where you
                          want to display this wispform.
                        </UICoreText>
                      </UICoreBox>
                      <textarea
                        ref={e => {
                          //$FlowFixMe
                          this._embed = e;
                        }}
                        autoSelect={true}
                        key={`${this.state.embedHeight}+${
                          this.state.embedWidth
                        }+${this.state.embedUnit.value}`}
                        className="Share-Embed"
                        onCopy={() =>
                          logFeatureEvent("share/embedFormCodeCopied")
                        }
                      >
                        {(() => `
            <iframe
              src="${this.props.shareLink}"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              style="height:${this.state.embedHeight}${this._getEmbedUnitInCss(
                          this.state.embedUnit.value,
                          "height"
                        )}; width:${
                          this.state.embedWidth
                        }${this._getEmbedUnitInCss(
                          this.state.embedUnit.value,
                          "width"
                        )}">
                Loading...
            </iframe>`)()}
                      </textarea>
                      <UICoreBox marginTop="sm">
                        <UICoreButton
                          color="lightBlue"
                          key={String(this.state.copied)}
                          onClick={this._handleCopyButtonClick}
                          size="md"
                        >
                          {this.state.copied ? "Copied" : "Copy"}
                        </UICoreButton>
                      </UICoreBox>
                    </UICoreBox>
                  }
                />
              )}

              <UICoreBox width="100%" marginTop="md">
                <UICoreButton
                  size="md"
                  maxWidth="100%"
                  onClick={() => {
                    logFeatureEvent("share/embedFormGetCodeButtonClicked");
                    this.setState({ showEmbedFormModal: true });
                  }}
                >
                  Get Code
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          </UICoreBox>
        }
        browserView={
          <iframe className="Share-frame" src={this.props.shareLink} />
        }
      />
    );
  }
}
export default EmbedFormSection;
