import React, { Component } from "react";
import "./style.css";
import PropTypes from "prop-types";
/*
  props:
    buttonContent : string
*/
class Button extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div
        className={"UI_Helper_Components-Button " + this.props.className}
        onClick={this.props.onClick}
      >
        {this.props.buttonContent ? this.props.buttonContent : "Default"}
      </div>
    );
  }
}

Button.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonContent: PropTypes.string.isRequired
};

export default Button;
