//@flow
import * as React from "react";
import {
  UICoreModal,
  UICoreBox,
  UICoreSection
} from "../../../../Component/UICore";
import RedirectSection from "./RedirectSection";
import EmailSection from "./EmailSection";
import RespondentEmailSection from "./RespondentEmailSection";
import RestrictAccessSection from "./RestrictAccessSection";
import BrandingSection from "./BrandingSection";
import "./style.css";
import ButtonTextSection from "./ButtonTextSection";
import HiddenFieldSection from "./HiddenFieldSection";
import { saveToBackend } from "../../States/actions";
import { connect } from "react-redux";
type Props = {|
  onDismiss: () => void,
  saveToBackend: () => void
|};
type State = {|
  activeSectionIndex: number
|};

const SettingType = {
  redirect: 0,
  hiddenField: 1,
  branding: 2,
  buttonText: 3,
  email: 4,
  respondentEmail: 5,
  access: 6
};

class FormSetting extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    activeSectionIndex: 0
  };

  render() {
    return (
      <UICoreModal
        dismissText="Save"
        header="Setting"
        onDismiss={() => {
          this.props.saveToBackend && this.props.saveToBackend();
          this.props.onDismiss && this.props.onDismiss();
        }}
        size="lg"
        body={
          <UICoreBox height="430px" direction="row">
            <UICoreBox
              className="FormSetting-nav"
              width="200px"
              paddingLeft="sm"
              paddingRight="sm"
              paddingTop="xm"
            >
              <UICoreSection.Vertical
                onChange={(_, index) =>
                  this.setState({
                    activeSectionIndex: index
                  })
                }
                activeSectionIndex={this.state.activeSectionIndex}
                sections={[
                  "Redirect",
                  "Hidden Fields",
                  "Branding",
                  "Language & Text",
                  "Self Notification",
                  "Respondent Notification",
                  "Restrict Access"
                ]}
              />
            </UICoreBox>
            <UICoreBox width="calc(100% - 200px)">
              {this.state.activeSectionIndex === SettingType.redirect && (
                <RedirectSection />
              )}
              {this.state.activeSectionIndex === SettingType.email && (
                <EmailSection />
              )}
              {this.state.activeSectionIndex === SettingType.buttonText && (
                <ButtonTextSection />
              )}
              {this.state.activeSectionIndex === SettingType.branding && (
                <BrandingSection />
              )}
              {this.state.activeSectionIndex === SettingType.hiddenField && (
                <HiddenFieldSection config={{ showTitle: true }} />
              )}
              {this.state.activeSectionIndex ===
                SettingType.respondentEmail && <RespondentEmailSection />}
              {this.state.activeSectionIndex === SettingType.access && (
                <RestrictAccessSection />
              )}
            </UICoreBox>
          </UICoreBox>
        }
      />
    );
  }
}

const mapDispatchToProps = {
  saveToBackend: saveToBackend
};

export default connect(null, mapDispatchToProps)(FormSetting);
