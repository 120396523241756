//@flow
import * as React from "react";
import { UICoreModal, UICoreBox, UICoreButton, UICoreText } from "../../UICore";
import { type buttonColor } from "../../UICore/UICoreButton";
import "./style.css";
import { isString } from "../../../Library/Util";
export type Props = {|
  acceptDisplayName: string,
  acceptSuccessdDisplayName?: string,
  acceptFailedDisplayName?: string,
  acceptOnSuccess?: () => void,
  acceptButtonColor?: buttonColor,
  body?: string | React.Node,
  disableAcceptButton?: boolean,
  disableAcceptAfterSuccess?: boolean,
  header: string,
  onReject: () => void,
  onDismiss: () => void,
  onAccept: () => any,
  rejectSuccessdDisplayName?: string,
  rejectDisplayName?: string
|};
type State = {|
  disableAcceptButton: ?boolean
|};
class UICoreDialog extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      disableAcceptButton: this.props.disableAcceptButton
    };
  }

  _onAcceptButtonClick = () => this.props.onAccept();

  _handleButtonActionOnComplete = () => {
    this.props.acceptOnSuccess && this.props.acceptOnSuccess();
    if (this.props.disableAcceptAfterSuccess) {
      this.setState({ disableAcceptButton: true });
    }
  };

  render() {
    return (
      <UICoreModal
        body={
          <UICoreBox padding="sm">
            {isString(this.props.body) ? (
              <UICoreText overflow="wrap" size="sm">
                {String(this.props.body)}
              </UICoreText>
            ) : (
              this.props.body || null
            )}
          </UICoreBox>
        }
        onDismiss={this.props.onDismiss}
        header={this.props.header}
        footer={
          <UICoreBox
            justifyContent="end"
            paddingLeft="sm"
            paddingRight="sm"
            paddingBottom="sm"
            direction="row"
          >
            {this.props.rejectDisplayName && (
              <UICoreBox marginRight="sm">
                <UICoreButton onClick={this.props.onReject} color="grey">
                  {this.props.rejectDisplayName}
                </UICoreButton>
              </UICoreBox>
            )}
            <UICoreBox>
              <UICoreButton
                color={this.props.acceptButtonColor}
                disabled={this.state.disableAcceptButton}
                actionFailedText={this.props.acceptFailedDisplayName}
                actionCompletedText={this.props.acceptSuccessdDisplayName}
                actionOnComplete={this._handleButtonActionOnComplete}
                onClick={this._onAcceptButtonClick}
              >
                {this.props.acceptDisplayName}
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        }
      />
    );
  }
}
export default UICoreDialog;
