// @flow
import axios from "axios";
import { nodeServiceAddress, serverAddress, domainName } from "../../config";
import { logErrorInfo } from "../../Helper_HOC/WithErrorBoundary";
import { getUserID } from "../UserInfo";
import WebStorage from "../WebStorage";
import { ErrorCode } from "./errorCode";
import { safeGet } from "../Util";

//TODO: @taoxiang move getAwsTemporaryCredential to formPage api.js
type awsCredentialType = {|
  temporaryAccessKeyId: string,
  temporarySecretAccessKey: string,
  temporarySessionToken: string,
  serverTime: number
|};

export function getAwsTemporaryCredential(): Promise<?awsCredentialType> {
  return new Promise((resolve, reject) => {
    axios
      .get(`${nodeServiceAddress}/api/fileupload/awsTemporaryCredential`)
      .then(response => {
        const temporaryCredential = response.data;
        resolve(temporaryCredential);
      })
      .catch(err => {
        resolve(null);
      });
  });
}

async function withRefreshTokenAndRetry(
  apiRequest: () => Promise<any>
): Promise<any> {
  try {
    //first try
    let response = await apiRequest();
    return response;
  } catch (error) {
    if (safeGet(() => error.response.status) === ErrorCode.UNAUTHORIZED) {
      // Only kick off retry if 401
      try {
        //first failure:
        //get refresh token, save to localstorage, and try again
        const refreshTokenResponse = await genRefreshToken();
        if (!refreshTokenResponse) {
          //no refresh token
          logErrorInfo(
            "[Request] Not able to refresh token. Most likely because there is no refresh token in the localstorage",
            ""
          );
          window.location.assign(`https://${domainName}/sign_out`);
          return;
        }
        WebStorage.saveToWebStorage("token", refreshTokenResponse.token);
        WebStorage.saveToWebStorage(
          "refreshToken",
          refreshTokenResponse.refresh_token
        );
        //second try
        let response = await apiRequest();
        return response;
      } catch (error) {
        //second failure: log out
        logErrorInfo("[Request] second api request failure", String(error));
        window.location.assign(`https://${domainName}/sign_out`);
        return error;
      }
    } else {
      throw error;
    }
  }
}

type refreshTokenResponse = {|
  token: string,
  access_token: string,
  refresh_token: string
|};

/**
 * function generator which creates genRefreshToken
 */
function generateGetRefreshToken(): () => Promise<?refreshTokenResponse> {
  /**
   * To prevent race condition where multiple genRefreshToken calls invalidate each
   * other in the backend,
   * we need to make sure there is only one active genRefreshToken call.
   *
   * Subsequent genRefreshToken call should just return the promise from the active
   * genRefreshToken call.
   */
  let activeRefreshTokenPromise: ?Promise<?refreshTokenResponse> = null;

  return (): Promise<?refreshTokenResponse> => {
    //Already have active ggenRefreshToken call
    if (activeRefreshTokenPromise) {
      return activeRefreshTokenPromise;
    }
    //Ineligible to refresh token
    if (
      getUserID() === null ||
      WebStorage.getFromWebStorage("refreshToken") == null
    ) {
      return Promise.resolve(null);
    }
    //Otherwise, make a refresh token call
    activeRefreshTokenPromise = new Promise((resolve, reject) => {
      axios
        .post(serverAddress + "api/v1/refresh_access_token", {
          id: getUserID(),
          refresh_token: String(WebStorage.getFromWebStorage("refreshToken"))
        })
        .then(response => {
          resolve(response.data.data);
          activeRefreshTokenPromise = null;
        })
        .catch(function(error) {
          logErrorInfo("[Request] refresh token failure", String(error));
          reject(error);
          activeRefreshTokenPromise = null;
        });
    });
    return activeRefreshTokenPromise;
  };
}

const genRefreshToken = generateGetRefreshToken();

export function getRequest(
  url: string,
  params?: Object,
  header?: Object
): Promise<any> {
  return withRefreshTokenAndRetry(() => {
    return new Promise((resolve, reject) => {
      axios
        .get(serverAddress + url, {
          headers: {
            Authorization:
              "Bearer " + String(WebStorage.getFromWebStorage("token")),
            ...header
          },
          params: params
        })
        .then(response => {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
}

export function getRequestWithoutAuth(
  url: string,
  params?: Object,
  header?: Object
): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .get(serverAddress + url, {
        headers: {
          ...header
        },
        params: params
      })
      .then(response => {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function deleteRequest(url: string, data?: Object): Promise<any> {
  return withRefreshTokenAndRetry(() => {
    return new Promise((resolve, reject) => {
      axios
        .delete(serverAddress + url, {
          headers: {
            Authorization:
              "Bearer " + String(WebStorage.getFromWebStorage("token"))
          },
          data: data
        })
        .then(response => {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
}

export function postRequest(url: string, data: Object): Promise<any> {
  return withRefreshTokenAndRetry(() => {
    return new Promise((resolve, reject) => {
      axios
        .post(serverAddress + url, data, {
          headers: {
            Authorization:
              "Bearer " + String(WebStorage.getFromWebStorage("token"))
          },
          validateStatus: function(status) {
            return status >= 200 && status < 300;
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
}

export function postRequestToNode(url: string, data: Object): Promise<any> {
  return new Promise((resolve, reject) => {
    axios
      .post(nodeServiceAddress + "/" + url, data, {})
      .then(response => {
        resolve(response);
      })
      .catch(function(error) {
        reject(error);
      });
  });
}

export function patchRequest(url: string, data: Object): Promise<any> {
  return withRefreshTokenAndRetry(() => {
    return new Promise((resolve, reject) => {
      axios
        .patch(serverAddress + url, data, {
          headers: {
            Authorization:
              "Bearer " + String(WebStorage.getFromWebStorage("token"))
          }
        })
        .then(response => {
          resolve(response);
        })
        .catch(function(error) {
          reject(error);
        });
    });
  });
}
