//@flow
/*
    withFormData 
    (1) get form responses from wispform
    (2) convert data into the formate of wisptable
    (3) inject into wrapping component 

*/
import * as React from "react";
import { type wispformIDType } from "../../../Configuration";
import {
  getWispformResponses as apiGetWispformResponses,
  type wispformResponseType
} from "../../../api";
import { getFormData as apiGetFormData } from "../../../../Form/api";
import formConfiguration, {
  type formDataType,
  type questionType,
  type AnswerType
} from "../../../../QuestionTypes";
import {
  type tableSchemaColumnType,
  type tableDataRowType
} from "../../../Configuration";
import { toArray } from "../../../../../Library/Util";

type State = {|
  formResponses: ?Array<wispformResponseType>,
  formQuestions: ?Array<questionType>
|};
export type InjectedProps = {|
  columns: Array<tableSchemaColumnType> | void,
  rows: Array<tableDataRowType> | void,
  getFormQuestionAndResponses: (
    formID: wispformIDType,
    responseIDs: Array<number>
  ) => void
|};

const questionTypes = new formConfiguration().types;

export default function withFormData<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    state = {
      formResponses: [],
      formQuestions: []
    };

    _getFormResponses = (
      formID: wispformIDType,
      responseIDs: Array<number>
    ) => {
      apiGetWispformResponses(formID, responseIDs).then(
        (responses: Array<wispformResponseType>) => {
          this.setState({
            formResponses: responses
          });
        }
      );
    };

    _getFormQuestion = (formID: wispformIDType) => {
      apiGetFormData(formID).then(formData => {
        this.setState({
          formQuestions: formData.questions
        });
      });
    };

    _getFormQuestionAndResponses = (
      formID: wispformIDType,
      responseIDs: Array<number>
    ) => {
      this._getFormResponses(formID, responseIDs);
      this._getFormQuestion(formID);
    };

    _toWisptableColumnSchemas = (
      questions: ?Array<questionType>
    ): Array<tableSchemaColumnType> => {
      return toArray(questions)
        .map((question: questionType) => {
          const toWisptableSchema =
            questionTypes[question.type] &&
            questionTypes[question.type].toWisptableColumnSchema;
          if (toWisptableSchema) {
            return toWisptableSchema(question);
          } else {
            //$FlowFixMe handled in the filter
            return null;
          }
        })
        .filter(
          (wisptableSchema: ?tableSchemaColumnType) => wisptableSchema !== null
        );
    };

    _toWisptableRow = (
      responses: ?Array<wispformResponseType>
    ): Array<tableDataRowType> => {
      return toArray(responses).map((response: wispformResponseType) => {
        return {
          id: response.id,
          created_at: response.created_at,
          cellColumnIdMap: response.response_detail.reduce(
            (prev, answer: AnswerType) => {
              const toWisptableData =
                questionTypes[answer.type] &&
                questionTypes[answer.type].toWisptableData;
              if (!toWisptableData) return prev;
              return {
                ...prev,
                [answer.question_id]: toWisptableData(answer.answer)
              };
            },
            {}
          )
        };
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getFormQuestionAndResponses={this._getFormQuestionAndResponses}
          columns={this._toWisptableColumnSchemas(this.state.formQuestions)}
          rows={this._toWisptableRow(this.state.formResponses)}
        />
      );
    }
  };
}
