// @flow

export type questionScoreCalculationsType = Array<questionScoreCalculationType>;

export type questionScoreCalculationOperationType = "add" | "subtract";

export type questionScoreCalculationType = {|
  targetAnswer: number | string,
  operation: questionScoreCalculationOperationType,
  score: ?number
|};

export const PAYMENT = "Payment";
