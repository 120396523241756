import { isNonEmptyString, nullSafe } from "./Util";

export function getUrlVars() {
  let vars = [];
  let hash = {};
  let hashes = window.location.href;

  // This is to remove ugly facebook omniauth that comes with token
  if (hashes.indexOf("#_=_") > 0) {
    hashes = hashes.replace(/#.*/, "");
  }

  hashes = hashes.slice(window.location.href.indexOf("?") + 1).split("&");
  for (let i = 0; i < hashes.length; i++) {
    hash = hashes[i].split("=");
    vars.push(hash[0]);
    vars[hash[0]] = hash[1];
  }
  return vars;
}

export function getOrgName() {
  let host = window.location.host;
  let subdomain = host.split(".")[0];
  if (
    subdomain === "localhost:3001" ||
    subdomain === "dashboard" ||
    subdomain === "www"
  )
    return null;
  else return subdomain;
}

export function getFormUID() {
  return String(window.location.pathname)
    .split("/")
    .filter(s => isNonEmptyString(s))[0];
}

export function asyncExe(cb) {
  setTimeout(cb, 0);
}

export function getFirstSubDomain() {
  return window.location.host.split(".")[0];
}
