// @flow

export const PreviewListSection = {
  QUESTION: "QUESTION",
  RESULT: "RESULT",
  OUTCOME: "OUTCOME"
};

export const FinalPageType = {
  THANKYOUPAGE: "THANKYOUPAGE",
  OUTCOMEPAGE: "OUTCOMEPAGE"
};
