//@flow
import FileComponent from "../../Components/CellComponents/FileComponent";
import FileDataUI from "../../Components/DataUIComponents/FileDataUI";
import { type DataType, type fileUploadCellType } from "../index";

const FileType = {
  icon: "fa fa-file",
  cellComponent: FileComponent,
  dataUIComponent: FileDataUI,
  displayName: "File",
  sortFunction: null,
  filter: null
};

export default FileType;
