//@flow
import * as React from "react";
import TextareaAutosize from "react-autosize-textarea";
import "./style.css";
type Props = {|
  size?: "xs" | "xxs" | "sm" | "md" | "lg" | "xl" | "xxxs",
  value: string,
  autoFocus?: boolean,
  autoSelect?: boolean,
  onBlur?: () => void,
  onChange: (SyntheticInputEvent<HTMLInputElement>, value: string) => void,
  width?: number | string,
  maxRows?: number,
  minRows?: number,
  fontFamily?: string
|};
type State = {||};
class UICoreTextArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _textAreaNode: ?HTMLInputElement = null;

  componentDidMount() {
    setTimeout(() => {
      if (this.props.autoFocus) {
        this._textAreaNode && this._textAreaNode.focus();
      }
      if (this.props.autoSelect) {
        this._textAreaNode && this._textAreaNode.select();
      }
    }, 100);
  }

  _getFontSoze = (): string => {
    switch (this.props.size) {
      case "xs":
        return "14px";
      case "xxs":
        return "12px";
      case "sm":
        return "16px";
      case "md":
        return "18px";
      case "lg":
        return "22px";
      case "xl":
        return "26px";
      default:
        return "16px";
    }
  };

  _getStyle = () => {
    return {
      width: this.props.width ? this.props.width : null,
      fontSize: this._getFontSoze(),
      fontFamily: this.props.fontFamily
    };
  };

  _handleInputChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.props.onChange(e, e.target.value);
  };

  render() {
    return (
      <TextareaAutosize
        innerRef={(e: HTMLInputElement) => {
          this._textAreaNode = e;
        }}
        onChange={this._handleInputChange}
        onBlur={this.props.onBlur}
        style={this._getStyle()}
        className="UICoreTextArea"
        value={this.props.value}
        maxRows={this.props.maxRows}
        rows={this.props.minRows}
      />
    );
  }
}
export default UICoreTextArea;
