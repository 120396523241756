//@flow
import {
  type actionType,
  SHOW_ACCOUNT_UPGRADE_MODAL,
  CLOSE_ACCOUNT_UPGRADE_MODAL
} from "./actions";

let defaultState = {
  showAccountUpgradeModal: false,
  featureName: "",
  featureAction: null
};

export type accountUpgradeModalstateType = {|
  showAccountUpgradeModal: boolean,
  featureName: string,
  featureAction: ?() => void
|};

export default function accountUpgradeModalstate(
  state: accountUpgradeModalstateType = defaultState,
  action: actionType
) {
  switch (action.type) {
    case SHOW_ACCOUNT_UPGRADE_MODAL:
      return {
        ...state,
        showAccountUpgradeModal: true,
        featureName: action.featureName,
        featureAction: action.featureAction
      };

    case CLOSE_ACCOUNT_UPGRADE_MODAL:
      return {
        ...state,
        showAccountUpgradeModal: false
      };

    default:
      return state;
  }
}
