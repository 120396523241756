//@flow
import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { update_style } from "../Pages/FormBuilderPage/States/actions";
import { BUILDER_PATH } from "../App";
import {
  type formStyleType,
  type backgroundCssType,
  type footerStyleCssType,
  type background,
  backgroundType
} from "../FlowTypes/wispformStyleTypes";
import {
  getBackgroundImageWithAutoPlatformDetection,
  getMobileBackgroundImage
} from "../Library/FormStyleDataStructureConversion";

const kMaxWindowSize = 5000;
export const kDefaultFilterOpacity = 0.7;

type Props = any;

function calculateButtonPrimaryColor(color, answerColor) {
  if (color && color != "") {
    return color;
  } else {
    return hexToRGB(answerColor, 0.2);
  }
}

function calculateButtonTextColor(color, answerColor) {
  if (color && color != "") {
    return hexToRGB(getContrastColor(color), 1);
  } else {
    return hexToRGB(answerColor, 1);
  }
}

function calculateButtonTextSecondaryColor(color, answerColor) {
  if (color && color != "") {
    return hexToRGB(getContrastColor(color), 0.5);
  } else {
    return hexToRGB(answerColor, 0.5);
  }
}

function calculateButtonBorderColor(color, answerColor) {
  if (color && color != "") {
    return color;
  } else {
    return hexToRGB(answerColor, 1);
  }
}

function getContrastColor(hexcolor) {
  hexcolor = hexcolor.replace("#", "");
  var r = parseInt(hexcolor.substr(0, 2), 16);
  var g = parseInt(hexcolor.substr(2, 2), 16);
  var b = parseInt(hexcolor.substr(4, 2), 16);
  var yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? "#555555" : "#ffffff";
}

function hexToRGB(hex: string, alpha: number) {
  if (!hex) {
    return;
  }
  hex = hex.toUpperCase();
  var h = "0123456789ABCDEF";
  var r = h.indexOf(hex[1]) * 16 + h.indexOf(hex[2]);
  var g = h.indexOf(hex[3]) * 16 + h.indexOf(hex[4]);
  var b = h.indexOf(hex[5]) * 16 + h.indexOf(hex[6]);
  if (alpha) return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  else return "rgb(" + r + ", " + g + ", " + b + ")";
}

function shadeColor(color: string, percent: number) {
  let f = parseInt(color.slice(1), 16),
    t = percent < 0 ? 0 : 255,
    p = percent < 0 ? percent * -1 : percent,
    R = f >> 16,
    G = (f >> 8) & 0x00ff,
    B = f & 0x0000ff;
  return (
    "#" +
    (
      0x1000000 +
      (Math.round((t - R) * p) + R) * 0x10000 +
      (Math.round((t - G) * p) + G) * 0x100 +
      (Math.round((t - B) * p) + B)
    )
      .toString(16)
      .slice(1)
  );
}

function getBackgroundOverlayFilter(background: background): number {
  return background.filter || kDefaultFilterOpacity;
}

function getBackgroundFilter(background: background): string {
  const filter = getBackgroundOverlayFilter(background);
  if (filter < 0.5) {
    // white based
    return `rgba(255, 255, 255, ${(0.5 - filter) * 2})`;
  } else {
    //black based
    return `rgba(0, 0, 0, ${(filter - 0.5) * 2})`;
  }
}

function getBackgroundStyleCss(
  styles: formStyleType,
  useMobile?: boolean
): backgroundCssType {
  switch (styles.background.type) {
    case backgroundType.color:
      return {
        backgroundColor: styles.background.color
      };
    case backgroundType.image:
      return {
        backgroundImage: `url('${
          useMobile
            ? getMobileBackgroundImage(styles.background)
            : getBackgroundImageWithAutoPlatformDetection(styles.background)
        }')`,
        backgroundSize: "cover",
        boxShadow: `inset ${kMaxWindowSize}px 0 0 0 ${getBackgroundFilter(
          styles.background
        )}`,
        backgroundPosition: "center"
      };
    default:
      return {
        backgroundColor: styles.background.color
      };
  }
}

function getFooterStyleCss(styles: formStyleType): footerStyleCssType {
  switch (styles.background.type) {
    case backgroundType.color:
      return {
        backgroundColor: shadeColor(String(styles.background.color), -0.15)
      };
    case backgroundType.image:
      const filter = getBackgroundOverlayFilter(styles.background);
      return {
        background: `rgba(${filter >= 0.5 ? `0,0,0,0.2)` : "255,255,255,0.2)"}`
      };
    default:
      return {
        backgroundColor: shadeColor(String(styles.background.color), -0.15)
      };
  }
}

function WithStyles(BaseComponent) {
  return class WrapComponent extends Component<Props> {
    render() {
      const isBuilder =
        window.location &&
        window.location.pathname &&
        window.location.pathname.includes(BUILDER_PATH);
      if (isBuilder) {
        return (
          <BaseComponent
            ref={this.props.fakeRef}
            {...this.props}
            backgroundCss={getBackgroundStyleCss(this.props.questionStyles)}
            mobileBackgroundCss={getBackgroundStyleCss(
              this.props.questionStyles,
              true
            )}
            styles={{
              ...this.props.questionStyles,
              fontFamily:
                (this.props.questionStyles &&
                  this.props.questionStyles.fontFamily) ||
                "Roboto"
            }}
            answerPrimaryColor={hexToRGB(this.props.questionStyles.answer, 0.5)}
            answerSecondaryColor={hexToRGB(
              this.props.questionStyles.answer,
              0.2
            )}
            buttonTextColor={calculateButtonTextColor(
              this.props.questionStyles.buttonColor,
              this.props.questionStyles.answer
            )}
            buttonPrimaryColor={calculateButtonPrimaryColor(
              this.props.questionStyles.buttonColor,
              this.props.questionStyles.answer
            )}
            buttonTextSecondaryColor={calculateButtonTextSecondaryColor(
              this.props.questionStyles.buttonColor,
              this.props.questionStyles.answer
            )}
            buttonBorderColor={calculateButtonBorderColor(
              this.props.questionStyles.buttonColor,
              this.props.questionStyles.answer
            )}
          />
        );
      } else {
        return (
          <BaseComponent
            ref={this.props.fakeRef}
            {...this.props}
            backgroundCss={getBackgroundStyleCss(this.props.answerStyles)}
            mobileBackgroundCss={getBackgroundStyleCss(
              this.props.questionStyles,
              true
            )}
            styles={{
              ...this.props.answerStyles,
              fontFamily:
                (this.props.answerStyles &&
                  this.props.answerStyles.fontFamily) ||
                "Roboto"
            }}
            answerPrimaryColor={hexToRGB(this.props.answerStyles.answer, 0.5)}
            answerSecondaryColor={hexToRGB(this.props.answerStyles.answer, 0.2)}
            buttonTextColor={calculateButtonTextColor(
              this.props.answerStyles.buttonColor,
              this.props.answerStyles.answer
            )}
            buttonTextSecondaryColor={calculateButtonTextSecondaryColor(
              this.props.answerStyles.buttonColor,
              this.props.answerStyles.answer
            )}
            buttonPrimaryColor={calculateButtonPrimaryColor(
              this.props.answerStyles.buttonColor,
              this.props.answerStyles.answer
            )}
            buttonBorderColor={calculateButtonBorderColor(
              this.props.answerStyles.buttonColor,
              this.props.answerStyles.answer
            )}
            footerStyleCss={getFooterStyleCss(this.props.answerStyles)}
          />
        );
      }
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    questionStyles: state.Question.styles,
    answerStyles: state.Answers.styles
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateStyle: (style, value) => dispatch(update_style(style, value))
  };
};

const composedWithStyles = compose(
  connect(mapStateToProps, mapDispatchToProps),
  WithStyles
);

export default composedWithStyles;
