// @flow

export const SizeTypeEnum = {
  xxm: 0,
  xm: 1,
  sm: 2,
  md: 3,
  lg: 4,
  xl: 5,
  xxl: 6
};
