//@flow
import { delayOneCycle } from "./Util";

/*
    helpers about DOM manipulation
*/

export function scrollToTheRight(
  scrollContainer: ?HTMLElement,
  duration?: number = 200,
  cb?: () => void //called after animation finished
) {
  if (!scrollContainer) {
    return;
  }
  const leftOffSet = scrollContainer.scrollWidth - window.innerWidth;
  let currentTime = 0;
  let increment = 20;
  let start = scrollContainer.scrollLeft;
  const animateScroll = function() {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, leftOffSet, duration);
    scrollContainer && (scrollContainer.scrollLeft = val);
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    } else {
      cb && cb();
    }
  };
  animateScroll();
}

function easeInOutQuad(t, s, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + s;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + s;
}

export function preventWindowFromPushingUpOnIosInAppBrowser() {
  document.addEventListener("focusout", () => {
    //Immediately move window will cancel the button click event,
    //result in long text question fail to submit after submit button click
    delayOneCycle(() => {
      window.scrollTo(0, 0);
      document.body && (document.body.scrollTop = 0);
      /**
       * Sending focusout event to parent window in the case of form plugin
       * This is part of the effort of solving ios IAB keyboad pushing up page issue.
       * In the case of plugin, not only frame itself need to scroll down, parent window need
       * the adjustment as well.
       */
      window.parent.postMessage(
        {
          focusOutEvent: true
        },
        "*"
      );
    });
  });
}

export function isIos() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
