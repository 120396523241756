//@flow
import * as React from "react";
import Dropzone from "react-dropzone";
import { UICoreButton } from "../UICore";
import { type buttonColor } from "../UICore/UICoreButton";
import {
  type awsCredentialType,
  uploadFileToAWS
} from "../../Library/AwsHelper";
import imageCompression from "browser-image-compression";

export const Status = {
  default: 0,
  uploading: 1,
  uploaded: 2,
  failed: 3
};

export type successPayload = {|
  url: string
|};

type Props = {|
  buttonText: string,
  buttonTextInUploadedState?: string,
  buttonColor?: buttonColor,
  buttonSize?: "sm" | "md" | "lg",
  status?: $Values<typeof Status>,
  awsFileName?: string,
  maxSizeMb?: number,
  onUploaded: successPayload => void,
  onUploading?: () => void,
  onError?: Error => void,
  buttonClickedInUploadedState?: () => void
|};
type State = {|
  isUploading: boolean
|};

class FileUploadButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    isUploading: false
  };

  _upload = (file: File) => {
    uploadFileToAWS(
      file,
      {
        optionalBucketKey: this.props.awsFileName
      }, //config
      () => {
        this.props.onUploading && this.props.onUploading();
        this.setState({
          isUploading: true
        });
      },
      event => {},
      data => {
        this.props.onUploaded &&
          this.props.onUploaded({
            url: data.Location
          });
        this.setState({
          isUploading: false
        });
      },
      error => {
        this.props.onError && this.props.onError(error);
      }
    );
  };

  _handleFileDrop = (files: Array<File>) => {
    const file = files[0];
    if (this.props.maxSizeMb) {
      imageCompression(file, {
        maxSizeMB: this.props.maxSizeMb,
        useWebWorker: true
      }).then((compressedFile: File) => {
        this._upload(compressedFile);
      });
    }
    this._upload(file);
  };

  render() {
    if (this.state.isUploading) {
      return (
        <UICoreButton key={1} loading={true}>
          {""}
        </UICoreButton>
      );
    } else if (this.props.status === Status.uploaded) {
      return (
        <UICoreButton
          key={2}
          color="red"
          onClick={this.props.buttonClickedInUploadedState}
        >
          {this.props.buttonTextInUploadedState || "Remove"}
        </UICoreButton>
      );
    } else {
      return (
        <Dropzone onDrop={this._handleFileDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <UICoreButton
                size={this.props.buttonSize}
                color={this.props.buttonColor}
              >
                {this.props.buttonText}
              </UICoreButton>
            </div>
          )}
        </Dropzone>
      );
    }
  }
}
export default FileUploadButton;
