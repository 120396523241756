//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreRadioButtons,
  UICoreBox,
  UICoreImage,
  UICoreText,
  UICoreButton,
  UICoreScaler,
  UICoreImageUploader
} from "../../../../../Component/UICore";
import FileUploadButton from "../../../../../Component/FileUploadButton";
import { connect } from "react-redux";
import { getStyleBackground } from "../../../States/selectors";
import {
  type background,
  backgroundType
} from "../../../../../FlowTypes/wispformStyleTypes";
import {
  type questionMediaTypeEnumType,
  questionMediaTypeEnum
} from "../../../../../FlowTypes/questionTypes";
import { updateStyleBackground, update_styles } from "../../../States/actions";
import { isNonEmptyString, stringReverse } from "../../../../../Library/Util";
import { logFeatureEvent } from "../../../../../Library/Logger";
import {
  getDekstopBackgroundImage,
  getMobileBackgroundImage,
  getBackgroundImageWithAutoPlatformDetection
} from "../../../../../Library/FormStyleDataStructureConversion";
import Placeholder from "../../../../../Component/Placeholder";
import DropdownColorPicker from "../../../../../Component/ColorPicker/DropdownColorPicker";
import WithStyles, {
  kDefaultFilterOpacity
} from "../../../../../Helper_HOC/WithStyles";
import {
  type backgroundCssType,
  type formStyleType
} from "../../../../../FlowTypes/wispformStyleTypes";

type Props = {|
  backgroundCss: backgroundCssType,
  mobileBackgroundCss: backgroundCssType,
  background: background,
  updateStyleBackground: background => void,
  updateStyles: ($Shape<formStyleType>) => any
|};
type State = {|
  mobileUploadState: ?number,
  desktopUploadState: ?number,
  showImageModal: boolean,
  editImagePlatform: ?number
|};

const kMobilePreviewDimension = {
  width: 80,
  height: 146
};

const kDesktopPreviewDimension = {
  width: 254,
  height: 146
};

const platform = {
  mobile: 0,
  desktop: 1,
  both: 2
};

const getBackgroundSection = (type: $Keys<typeof backgroundType>): number => {
  if (type === backgroundType.image) {
    return backgroundSectionIndex.image;
  } else {
    return backgroundSectionIndex.color;
  }
};

const backgroundSectionIndex = {
  color: 0,
  image: 1
};

const getBackgroundTypeFromSectionIndex = (
  sectionIndex: number
): $Keys<typeof backgroundType> => {
  if (sectionIndex === backgroundSectionIndex.image) {
    return backgroundType.image;
  } else {
    return backgroundType.color;
  }
};

const uploadingState = {
  uploading: 0,
  uploaded: 1
};

class DesignPanelBackgroundSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      mobileUploadState: null,
      desktopUploadState: null,
      showImageModal: false,
      editImagePlatform: platform.both
    };
  }

  _handleSliderChange = number => {
    this.props.updateStyleBackground({
      ...this.props.background,
      filter: number
    });
  };

  _storeImage = (
    url: string,
    type: ?questionMediaTypeEnumType,
    originalWidth: ?number,
    originalHeight: ?number
  ) => {
    if (this.state.editImagePlatform === platform.both) {
      this.props.updateStyleBackground({
        ...this.props.background,
        type: backgroundType.image,
        imageMobile: url,
        imageDesktop: url
      });
      this.props.updateStyles({
        text: "#ffffff",
        answer: "#ffffff"
      });
    } else if (this.state.editImagePlatform === platform.mobile) {
      this.props.updateStyleBackground({
        ...this.props.background,
        imageMobile: url
      });
    } else {
      this.props.updateStyleBackground({
        ...this.props.background,
        imageDesktop: url
      });
    }
  };

  _renderAddImageModal = () => {
    return (
      this.state.showImageModal && (
        <UICoreImageUploader
          onDismiss={() => {
            this.setState({ showImageModal: false });
          }}
          storeImage={this._storeImage}
          defaultSearchText="nature background"
        />
      )
    );
  };

  _renderImageUploadButton = () => (
    <UICoreBox width={150}>
      <UICoreButton
        color="lightBlue"
        onClick={() => {
          logFeatureEvent(
            "formbuilderPage/uiCoreImageUploaderOpenedBackgroundImage"
          );
          this.setState({
            showImageModal: true,
            editImagePlatform: platform.both
          });
        }}
      >
        Upload an Image
      </UICoreButton>
    </UICoreBox>
  );

  _renderEditButtons = (editPlatform: number) => {
    return (
      <UICoreBox
        className="DesignPanelBackgroundSection-editButtons"
        position="absolute"
        top="0px"
        left="0px"
        width="100%"
        height="100%"
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <UICoreBox paddingBottom="sm">
          <UICoreButton
            color="white"
            size="sm"
            onClick={() => {
              logFeatureEvent(
                "formbuilderPage/uiCoreImageUploaderOpenedBackgroundImage"
              );
              this.setState({
                showImageModal: true,
                editImagePlatform: editPlatform
              });
            }}
          >
            Update
          </UICoreButton>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderUploadingPlaceholder = () => {
    return (
      <UICoreBox
        position="absolute"
        height="100%"
        width="100%"
        top="0px"
        left="0px"
      >
        <Placeholder />
      </UICoreBox>
    );
  };

  _renderImageEditor = () => {
    return (
      <UICoreBox>
        <UICoreBox direction="row">
          {/* Desktop */}
          <UICoreBox alignItems="center">
            <UICoreBox
              position="relative"
              overflowY="hidden"
              overflowX="hidden"
              color="lightGrey"
              shape="rounded"
              width={kDesktopPreviewDimension.width}
              height={kDesktopPreviewDimension.height}
              className="DesignPanelBackgroundSection-desktop"
              dangerous_style={{
                ...this.props.backgroundCss
              }}
            >
              {this._renderEditButtons(platform.desktop)}
              {this.state.desktopUploadState === uploadingState.uploading &&
                this._renderUploadingPlaceholder()}
            </UICoreBox>
            <UICoreBox paddingTop="xxm">
              <UICoreText size="xxs" color="Grey">
                Desktop View
              </UICoreText>
            </UICoreBox>
          </UICoreBox>

          {/* Mobile */}
          <UICoreBox marginLeft="sm" alignItems="center">
            <UICoreBox
              position="relative"
              overflowY="hidden"
              overflowX="hidden"
              shape="rounded"
              width={kMobilePreviewDimension.width}
              height={kMobilePreviewDimension.height}
              className="DesignPanelBackgroundSection-mobile"
              dangerous_style={{
                ...this.props.mobileBackgroundCss
              }}
            >
              {this._renderEditButtons(platform.mobile)}
              {this.state.mobileUploadState === uploadingState.uploading &&
                this._renderUploadingPlaceholder()}
            </UICoreBox>
            <UICoreBox paddingTop="xxm">
              <UICoreText size="xxs" color="Grey">
                Mobile View
              </UICoreText>
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
        <UICoreBox direction="row" paddingTop="md" width={1010}>
          <UICoreBox width="xl" paddingLeft="sm" paddingTop="xm">
            <UICoreScaler
              leftLabel="white"
              rightLabel="black"
              bottomLabel="Brightness"
              sliderTrackWidth={300}
              initValue={
                this.props.background.filter
                  ? this.props.background.filter * 100
                  : kDefaultFilterOpacity * 100
              }
              onScalerDragging={(scale: number) => {
                this._handleSliderChange(scale * 0.01);
              }}
            />
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  };

  render() {
    return (
      <UICoreBox>
        {this._renderAddImageModal()}
        <UICoreRadioButtons
          onChange={index => {
            this.props.updateStyleBackground({
              ...this.props.background,
              type: getBackgroundTypeFromSectionIndex(index)
            });
          }}
          selectedIndex={getBackgroundSection(this.props.background.type)}
          labels={[
            { text: "Use Plain Color" },
            { text: "Use Background Image" }
          ]}
          sectionComponents={{
            [0]: (
              <UICoreBox
                width={310}
                direction="row"
                alignItems="center"
                paddingTop="sm"
                paddingBottom="sm"
              >
                <UICoreText size="sm" color="black">
                  Select a background color:
                </UICoreText>
                <UICoreBox paddingLeft="xm">
                  <DropdownColorPicker
                    selectedHexColor={String(this.props.background.color)}
                    onColorSelected={color => {
                      this.props.updateStyleBackground({
                        ...this.props.background,
                        color
                      });
                    }}
                  />
                </UICoreBox>
              </UICoreBox>
            ),
            [1]: (
              <UICoreBox paddingTop="sm">
                {isNonEmptyString(
                  getBackgroundImageWithAutoPlatformDetection(
                    this.props.background
                  )
                )
                  ? this._renderImageEditor()
                  : this._renderImageUploadButton()}
              </UICoreBox>
            )
          }}
        />
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    background: getStyleBackground(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateStyleBackground: (background: background) => {
      dispatch(updateStyleBackground(background));
    },
    updateStyles: styles => dispatch(update_styles(styles))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithStyles(DesignPanelBackgroundSection));
