import { combineReducers } from "redux";
import Question from "./Pages/FormBuilderPage/States/reducer";
import Workspace from "./Pages/Workspace/States/reducer";
import TryoutPage from "./Pages/TryoutPage/state/reducer";
import ResultPage from "./Pages/ResultPage/States/reducer";
import Answers from "./Pages/Form/States/Answers/reducer";
import OnBoarding from "./Pages/FormBuilderPage/States/OnBoarding/reducer";
import WispTable from "./Pages/WispTable/State/TableState/reducer";
import Notifications from "./Pages/WispTable/State/NotificationState/reducer";
import Dialog from "./Pages/WispTable/State/DialogState/reducer";
import AccountUpgradeModal from "./Pages/FormBuilderPage/States/AccountUpgradeModal/reducers";
import FormConfig from "./State/FormConfig/reducers";

let rootUsers = combineReducers({
  Question,
  Workspace,
  ResultPage,
  TryoutPage,
  Answers,
  OnBoarding,
  WispTable,
  Notifications,
  Dialog,
  AccountUpgradeModal,
  FormConfig
});
export default rootUsers;
