//@flow
import {
  type tableType,
  type tableSchemaType,
  type tableDatasType,
  type tableMetaDataType,
  type tableIDType,
  type columnIDType
} from "../../Configuration";
import { mockData as getTableReturnedData, mockTablesData } from "../mockData";
import {
  ADD_TABLE_FROM_WISPFORM,
  CREATE_EMPTY_TABLE,
  UPDATE_CELL,
  CREATE_ROW,
  DELETE_ROWS,
  ADD_COLUMN,
  DELETE_CURRENT_EDITING_COLUMN,
  SET_CURRENT_EDITING_COLUMN_ID,
  DID_FETCH_TABLES,
  DID_FETCH_TABLE,
  UPDATE_CURRENT_EDITING_COLUMN,
  DID_FETCH_WISPFORMS_AND_WORKSPACES,
  SET_CURRENT_TABLE_ID,
  DELETE_TABLE,
  UPDATE_TABLE_NAME,
  UPDATE_COLUMN_CONFIG,
  type actionTypes,
  UPDATE_COLUMN_WIDTH,
  UPDATE_TABLE_META_DATAS
} from "./action";
import { type apiFormsAndWorkspacesreturnType } from "../../api";
import { filterOutUnsupporttedType } from "./helper";

export type stateType = {|
  tables: Array<tableType>,
  tableSchemas: tableSchemaType,
  tableDatas: tableDatasType,
  tableMetaDatas: ?tableMetaDataType,
  currentTableID: ?tableIDType,
  currentEditingColumnID?: columnIDType,
  formsAndWorkspaces: apiFormsAndWorkspacesreturnType
|};

let defaultState = {
  tables: [],
  tableSchemas: { columns: [], id: 0, primary_column_id: "0", views: [] },
  tableDatas: [],
  tableMetaDatas: {
    colorGroup: null,
    filterGroup: null,
    sortGroup: null,
    columnWidth: null,
    id: 0
  },
  currentTableID: null,
  formsAndWorkspaces: []
};

export default function Answers(
  state: stateType = defaultState,
  action: actionTypes
) {
  switch (action.type) {
    case UPDATE_CELL:
      return {
        ...state,
        tableDatas: action.tableDatas
      };

    case CREATE_EMPTY_TABLE:
    case ADD_TABLE_FROM_WISPFORM:
      return {
        ...state,
        tables: action.tables,
        currentTableID: action.addedTableID
      };

    case DELETE_TABLE:
      return {
        ...state,
        tables: action.tables,
        currentTableID: action.currentTableID
      };

    case UPDATE_TABLE_NAME: {
      return {
        ...state,
        tables: action.tables
      };
    }
    case DELETE_ROWS:
    case CREATE_ROW:
      return {
        ...state,
        tableDatas: action.rows
      };

    case ADD_COLUMN:
      return {
        ...state,
        tableSchemas: {
          ...state.tableSchemas,
          columns: action.updatedColumns
        },
        currentEditingColumnID: action.newColumnID
      };

    case UPDATE_COLUMN_WIDTH:
      return {
        ...state,
        tableMetaDatas: action.tableMetaDatas
      };

    case DELETE_CURRENT_EDITING_COLUMN:
      return {
        ...state,
        tableSchemas: {
          ...state.tableSchemas,
          columns: action.updatedColumns
        },
        currentEditingColumnID: null
      };

    case SET_CURRENT_EDITING_COLUMN_ID: {
      return {
        ...state,
        currentEditingColumnID: action.columnID
      };
    }

    case UPDATE_COLUMN_CONFIG:
    case UPDATE_CURRENT_EDITING_COLUMN:
      return {
        ...state,
        tableSchemas: {
          ...state.tableSchemas,
          columns: action.updatedColumns
        }
      };

    case DID_FETCH_TABLES:
      return {
        ...state,
        tables: action.tables
      };
    case DID_FETCH_TABLE: {
      return {
        ...state,
        tableSchemas: filterOutUnsupporttedType(action.table.table_detail),
        tableDatas: action.table.rows,
        tableMetaDatas: action.table.table_meta_datum
          ? action.table.table_meta_datum.detail
          : {}
      };
    }
    case DID_FETCH_WISPFORMS_AND_WORKSPACES: {
      return {
        ...state,
        formsAndWorkspaces: action.formsAndWorkspaces
      };
    }
    case SET_CURRENT_TABLE_ID: {
      return {
        ...state,
        currentTableID: action.currentTableID
      };
    }

    case UPDATE_TABLE_META_DATAS: {
      return {
        ...state,
        tableMetaDatas: action.tableMetaDatas
      };
    }
    default:
      return state;
  }
}
