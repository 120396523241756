//@flow
import React, { Component } from "react";
import withStyles from "../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import ClickFlash from "../../Animations/ClickFlash";
import "./style.css";
import withConfigurableButtonTextHOC, {
  type InjectedProps as InjectedPropsFromConfigurableButtonTextHOC,
  ButtonTypeFromHOC
} from "../../../Helper_HOC/WithConfigurableButtonText";

type Props = {|
  ...InjectedPropsFromConfigurableButtonTextHOC,
  styles: formStyleType,
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  onClick?: (string, ?boolean) => mixed,
  isChoiceSelected?: boolean,
  isInBuilder?: boolean
|};

type State = {|
  userInput: string,
  isInputFocused: boolean
|};

class MultichoiceOtherOptionComponent extends Component<Props, State> {
  state = {
    userInput: "",
    isInputFocused: false
  };

  _inputStyle = () => {
    return {
      borderColor: this.props.styles.answer,
      backgroundColor: this.props.isChoiceSelected
        ? this.props.answerPrimaryColor
        : this.props.answerSecondaryColor,
      color: this.props.styles.answer,
      borderWidth: this.props.isChoiceSelected ? "2px" : "1px",
      pointerEvents: this.props.isInBuilder ? "none" : "auto",
      fontFamily: this.props.styles && this.props.styles.fontFamily
    };
  };

  _inputDefaultTextStyle = () => {
    return {
      color: this.props.answerPrimaryColor,
      fontFamily: this.props.styles && this.props.styles.fontFamily
    };
  };

  _handleUserInput = (e: SyntheticInputEvent<>) => {
    this.setState({
      userInput: e.target.value
    });
  };

  _renderDefaultText = () => {
    return !this.state.userInput ? (
      <div
        style={this._inputDefaultTextStyle()}
        className="MultichoiceOtherOptionComponent-other"
      >
        {this.props.buttonTextConfig[ButtonTypeFromHOC.multichoiceOther]}
      </div>
    ) : null;
  };

  _renderSubmitButton = () => {
    if (this.state.isInputFocused) {
      return (
        <ClickFlash
          onClick={() =>
            this.props.onClick && this.props.onClick(this.state.userInput)
          }
        >
          <div
            style={{ backgroundColor: this.props.answerSecondaryColor }}
            className="MultichoiceOtherOptionComponent-submit"
          >
            <i className="fa fa-1x fa-arrow-right" />
          </div>
        </ClickFlash>
      );
    }
  };

  _handleInputFocus = () => {
    this.setState({
      isInputFocused: true
    });
  };

  _handleInputBlur = () => {
    const ANIMATION_DELAY = 400;
    setTimeout(() => {
      this.setState({
        isInputFocused: false
      });
    }, ANIMATION_DELAY);
  };

  render() {
    return (
      <div
        style={this._inputStyle()}
        className="MultichoiceOtherOptionComponent"
      >
        <div className="MultichoiceOtherOptionComponent-index">
          <i className={"fa fa-1x fa-pencil"} aria-hidden="true" />
        </div>
        {this._renderDefaultText()}
        <input
          onFocus={this._handleInputFocus}
          onBlur={this._handleInputBlur}
          onChange={this._handleUserInput}
          style={{
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
          className={
            "MultichoiceOtherOptionComponent-input " +
            (this.state.isInputFocused
              ? "MultichoiceOtherOptionComponent-inputRightPadding"
              : "")
          }
        />
        {this._renderSubmitButton()}
      </div>
    );
  }
}

export default withConfigurableButtonTextHOC(
  withStyles(MultichoiceOtherOptionComponent)
);
