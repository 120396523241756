import{SAVE_TO_BACK_END, PREVIEW, WILLSAVEPREVIEW, DIDSAVEPREVIEW, CLOSE_PREVIEW} from './action';
import update from 'react-addons-update';

let defaultState = {
    willSavePreview:false,
    shouldShowPreview: false
};

export default function TryoutPage(state = defaultState, action) {
    switch(action.type){
        case WILLSAVEPREVIEW:
            return update(state, {
                willSavePreview: {$set: true},
            });
            break;
        
        case DIDSAVEPREVIEW:
            return update(state, {
                willSavePreview: {$set: false},
                previewURL: {$set: action.previewURL},
                shouldShowPreview: {$set: true},
            });
            break;

        case CLOSE_PREVIEW:
            return update(state, {
                shouldShowPreview:{$set: false}
            })
        
        default:
            return state;
    }
}