//@flow
import * as React from "react";
import "./style.css";
import { domainName } from "../../config";
import { getUrlVars } from "../../Library";
type Props = {|
  onDismiss: () => void
|};
type State = {||};
class FormPreviewModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _sendPreviewMessage = (event: SyntheticEvent<HTMLIFrameElement>) => {
    const previewIfram = window.frames["formPreview"];
    //$FlowFixMe
    if (event && event.target && event.target.contentWindow) {
      event.target.contentWindow.postMessage(
        {
          enablePreview: true
        },
        "*"
      );
    }
  };

  render() {
    return (
      <div className="FormBuilderPage-MainContent-ViewWindow-Window-Preview-background">
        <div
          onClick={this.props.onDismiss}
          className="FormBuilderPage-MainContent-ViewWindow-Window-close"
        >
          <i className="ion-close-circled" />
        </div>
        <iframe
          onLoad={this._sendPreviewMessage}
          name="formPreview"
          frameBorder="0"
          allowFullScreen
          src={`https://${String(localStorage.getItem("name"))}.${domainName}/${
            getUrlVars()["id"]
          }`}
          className="FormBuilderPage-MainContent-ViewWindow-Window-Preview"
        />
      </div>
    );
  }
}
export default FormPreviewModal;
