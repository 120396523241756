//@flow
import { SHOW_DIALOG, CLOSE_DIALOG, type actionTypes } from "./action";
import { type Props as DialogType } from "../../../../Component/UICore/UICoreDialog";

export type stateType = {|
  dialog: ?DialogType
|};

let defaultState = {
  dialog: null
};

export default function Dialog(
  state: stateType = defaultState,
  action: actionTypes
) {
  switch (action.type) {
    case SHOW_DIALOG:
    case CLOSE_DIALOG:
      return {
        ...state,
        dialog: action.dialog
      };
    default:
      return state;
  }
}
