// @flow
import * as React from "react";
import { connect } from "react-redux";
import { UICoreBox, UICoreDropdown } from "../../../../../Component/UICore";
import { safeGet } from "../../../../../Library/Util";
import { type TGenericContentConfig } from "../../../../../Component/GenericFormContent/type";
import {
  update_outcome_max_num,
  update_outcome_page
} from "../../../States/actions";
import { getOutcomes } from "../../../States/selectors";
import { type TOutcomeData } from "../../../../../Component/GenericFormContent/type";
type Props = {|
  maxOutcomes: number,
  outcomes: Array<TOutcomeData>,
  update_outcome_max_num: number => void
|};
type State = {||};

const kOffeset = 1;
const kDefaultMaxOutcome = 1;

class MaxOutcome2DisplayDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // this.state = {};
  }
  render() {
    return (
      <UICoreBox>
        <UICoreDropdown
          dropdownWidth={120}
          width="60px"
          onChoiceClick={value => {
            this.props.update_outcome_max_num(value);
          }}
          selectChoice={{
            value: this.props.maxOutcomes || kDefaultMaxOutcome,
            display: String(this.props.maxOutcomes || kDefaultMaxOutcome)
          }}
          choices={this.props.outcomes.map((_, index) => {
            return {
              value: index + kOffeset,
              display: String(index + kOffeset)
            };
          })}
        />
      </UICoreBox>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update_outcome_max_num: (maxNum: number) => {
      dispatch(update_outcome_max_num(maxNum));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    maxOutcomes: safeGet(_ => state.Question.pages.outcomePage.maxOutcomes),
    outcomes: getOutcomes(state.Question)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaxOutcome2DisplayDropdown);
