// @flow
import { isDeveloper, userIDMatch } from "./builderViewerCondition";
import { isDevEnv } from "../Environment";
import { getUserID, getUserInfo, isPaidUser } from "../UserInfo";
import { isNullOrUndefined, nonNullOrThrow } from "../Util";

// @flow
export function enableSubscriptionQuestionType() {
  return true;
}

export function showFormCreationModalInEmptyWorkspace() {
  const userID = getUserID();
  if (isNullOrUndefined(userID)) {
    return false;
  }

  return nonNullOrThrow(userID) % 2 === 0;
}

export function hideTemplatesInCreateFormModal() {
  const userID = getUserID();
  if (isNullOrUndefined(userID)) {
    return false;
  }

  return nonNullOrThrow(userID) % 2 === 0;
}

export function enableFormSubmissionPasswordWarningCustomization() {
  if (isPaidUser()) {
    return true;
  }
  return false;
}

export function enableEngagementContentCreation() {
  // return (
  //   isDeveloper() || userIDMatch(25941) || userIDMatch(40785) || isPaidUser()
  // );
  return true;
  // return isDeveloper() && isDevEnv();
}

export function enableAdvancedAnalytics() {
  return true;
}
