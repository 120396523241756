import React, { Component } from 'react';
import './style.css';

/*
    props
    active_class_name {String}
    default_active_index {Number}
    cb {Function}
*/

class ListHighlighter extends Component {
    constructor(props){
        super(props);
        this.list_highlighter_container = null;
    }
    componentDidMount() {
        const items = this.list_highlighter_container && this.list_highlighter_container.childNodes;
        //add class name to each element
        if (items){
            Array.prototype.forEach.call(items, (item)=>{
                item.classList.add('ListHightlighterItem');
            })
        }
        //set default active item
        const default_index = this.props.default_active_index?this.props.default_active_index:0;
        const default_item = items[default_index];
        default_item.classList.add(this.props.active_class_name);
    }
    
    handleClick(e){
        e.stopPropagation();
        const current_active = document.getElementsByClassName(this.props.active_class_name)[0];
        if (current_active){
            current_active.classList.remove(this.props.active_class_name);
        }
        const clicked_item = e.target.closest('.ListHightlighterItem');
        if (clicked_item){
            clicked_item.classList.add(this.props.active_class_name);
        }
        this.props.cb&&this.props.cb();
    }
    render() {
        return (
            <div ref={(e)=>this.list_highlighter_container = e} onClick={this.handleClick.bind(this)}>
                {this.props.children}
            </div>
        );
    }
}

export default ListHighlighter;