//@flow
import * as React from "react";
import PaymentCard from "../Shared/PaymentCard/index.js";
import { UICoreText, UICoreBox, UICoreTextEditable } from "../../UICore";
import WithStyles from "../../../Helper_HOC/WithStyles";
import {
  type questionConfig,
  QuestionTypeEnum,
  type QuestionTypes
} from "../../../Pages/QuestionTypes";
import TextSubmitButton from "../ShortTextInput/TextSubmitButton";
import { isMobile } from "../../Views/helper";
import { toArray, isNumber, safeGet } from "../../../Library/Util";
import { connect } from "react-redux";
import {
  update_question_config,
  saveToBackend
} from "../../../Pages/FormBuilderPage/States/actions";
import {
  getDynamicPriceAmount,
  firstMonetaryQuestionType
} from "../../../Pages/Form/States/Answers/selectors";
import { hasDynamicPricingLogic } from "../../../Pages/FormBuilderPage/States/selectors";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import { isInBuilder } from "../../../Library/ViewerContext/index.js";
type Props = {|
  fontSize: string,
  answerSecondaryColor: string,
  answerPrimaryColor: string,
  styles: formStyleType,
  isInBuilder?: boolean,
  last_edited_question?: number,
  config: questionConfig,
  hasDynamicPricingLogic: ?boolean,
  onQuestionComplete: string => void,
  onQuestionUpdateWithouUpdatingCurrentQuestionIndex: string => void,
  update_question_config: (string, string, number) => void,
  saveToBackend: () => void,
  dynamicPriceAmount: ?number,
  answer: any,
  firstMonetaryQuestionType: QuestionTypes,
  contents: any,
|};
type State = {|
  amount: number
|};

class UI_Payment extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    amount: 0
  };

  _submitAnswer = () => {
    if (isMobile()) {
      //give it some time to close keyboard first
      //otherwise, the scroll position will be fucked
      setTimeout(() => {
        this.props.onQuestionComplete && this.props.onQuestionComplete("");
      }, 350);
    } else {
      this.props.onQuestionComplete && this.props.onQuestionComplete("");
    }
  };

  _renderSubmit = () => {
    if (this.props.isInBuilder) {
      return null;
    } else {
      return (
        <UICoreBox width="100%" marginTop="xm">
          <TextSubmitButton
            onClick={() => {
              this._submitAnswer();
            }}
          />
        </UICoreBox>
      );
    }
  };

  _handleAmountInput = (
    e: SyntheticInputEvent<HTMLInputElement>,
    value: string
  ) => {
    this.props.update_question_config &&
      this.props.last_edited_question != null &&
      this.props.update_question_config(
        "paymentAmount",
        value,
        this.props.last_edited_question
      );
  };

  _getPaymentAmount = () => {
    if (isInBuilder()) {
      // Builder should always show what publisher input
      // TODO: make paymentAmount a const
      if (
        this.props.config &&
        this.props.config["paymentAmount"] !== undefined
      ) {
        return String(this.props.config["paymentAmount"]);
      } else {
        return "0";
      }
    } else {
      // dynamicPriceAmount would fallback to static input if no dynamicAmount
      return String(this.props.dynamicPriceAmount);
    }
  };

  render() {
    return (
      <div>
        <UICoreBox
          width="100%"
          direction="row"
          alignItems="center"
          justifyContent="start"
        >
          <UICoreText weight="bold" hexColor={this.props.answerPrimaryColor}>
            {`Total amount: `}
          </UICoreText>
          <UICoreBox alignItems="center" paddingLeft="xm" direction="row">
            <UICoreText weight="bold" hexColor={this.props.styles.answer}>
              {(this.props.contents && this.props.contents.currency) ? "" : "$"}
            </UICoreText>
            {this.props.isInBuilder ? (
              this.props.hasDynamicPricingLogic ? (
                <UICoreBox paddingLeft="xm" paddingRight="xm">
                  <UICoreText hexColor={this.props.styles.answer}>
                    Based on user's answers
                  </UICoreText>
                </UICoreBox>
              ) : (
                <UICoreTextEditable
                  type="numeric"
                  placeholder={"Enter the amount to charge"}
                  onInput={this._handleAmountInput}
                  autoFocus={true}
                  weight="bold"
                  alignment="left"
                  hexColor={this.props.styles.answer}
                  onBlur={this.props.saveToBackend}
                >
                  {this._getPaymentAmount()}
                </UICoreTextEditable>
              )
            ) : (
              <UICoreText hexColor={this.props.styles.answer} weight="bold">
                {this._getPaymentAmount()}
              </UICoreText>
            )}
            <UICoreText weight="bold" hexColor={this.props.styles.answer}>
              {(this.props.contents && this.props.contents.currency) ? this.props.contents.currency : ""}
            </UICoreText>
          </UICoreBox>
        </UICoreBox>
        {/* In form, only show payment card for the first monetary question */}
        {!this.props.isInBuilder &&
        this.props.firstMonetaryQuestionType !==
          QuestionTypeEnum.Payment ? null : (
          <PaymentCard
            onQuestionUpdateWithouUpdatingCurrentQuestionIndex={
              this.props.onQuestionUpdateWithouUpdatingCurrentQuestionIndex
            }
            answerSecondaryColor={this.props.answerSecondaryColor}
            answerPrimaryColor={this.props.answerPrimaryColor}
            styles={this.props.styles}
            answer={safeGet(_ => this.props.answer && this.props.answer.answer)}
          />
        )}
        <UICoreBox>
          <UICoreText
            overflow="wrap"
            size="xs"
            hexColor={this.props.answerSecondaryColor}
          >
            {`* You will be charged $${this._getPaymentAmount()} when submitting this form. Transaction is processed by stripe.com`}
          </UICoreText>
        </UICoreBox>
        {this._renderSubmit()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    last_edited_question: state.Question.last_edited_question,
    dynamicPriceAmount: getDynamicPriceAmount(state.Answers),
    hasDynamicPricingLogic: hasDynamicPricingLogic(state.Question),
    firstMonetaryQuestionType: firstMonetaryQuestionType(state.Answers)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update_question_config: (
      configName: string,
      value: string,
      pageNumber: number
    ) => {
      dispatch(update_question_config(configName, value, pageNumber));
    },
    saveToBackend: () => dispatch(saveToBackend())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithStyles(UI_Payment));
