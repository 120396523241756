//@flow
import * as React from "react";
import WisptableConfig, {
  type cellValueType,
  type columnTypes,
  type columnConfigType,
  type columnIDType,
  type DataComponentPropsType
} from "../../Configuration";

import "./style.css";
type Props = {|
  ...DataComponentPropsType,
  type: columnTypes
|};
type State = {||};
class DataUIContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    const CustomDisplayComponent =
      WisptableConfig.dataTypes[this.props.type].dataUIComponent;
    return (
      <CustomDisplayComponent
        rowId={this.props.rowId}
        config={this.props.config}
        updateCell={() => null}
        value={this.props.value}
        columnID={this.props.columnID}
      />
    );
  }
}
export default DataUIContainer;
