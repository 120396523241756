//@flow
import {
  SHOW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  type actionTypes
} from "./action";
import { type Props as notificationProps } from "../../../../Component/Notification";

export type stateType = {|
  notifications: Array<{|
    ...notificationProps,
    id: string
  |}>
|};

let defaultState = {
  notifications: []
};

export default function Notifications(
  state: stateType = defaultState,
  action: actionTypes
) {
  switch (action.type) {
    case SHOW_NOTIFICATION:
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notifications: action.notifications
      };
    default:
      return state;
  }
}
