import {
  questionPosition,
  getCurrentQuestion,
  getInnerHeight,
  getPaddingTop
} from "./ScrollView";
import { isIos } from "../../../../Library/DOM";
import { isNullOrUndefined } from "../../../../Library/Util";

function getContainer() {
  return document.getElementsByClassName("Questions")[0];
}

function addTransition(e) {
  if (!isNullOrUndefined(e.style)) {
    e.style.transition = "all 250ms ease-out";
  }
}

function removeTransition(e) {
  e.style.transition = "";
}

function removeActive(e) {
  e.classList.remove("active");
}

export function translateToQuestion(id) {
  //save window height, in case fking keyboard pop out
  if (!window.window_height) {
    window.window_height = window.innerHeight;
  }
  const container = getContainer();
  addTransition(container);
  const questions = container.children;
  const question = questions[id];
  const position = questionPosition(question);
  const original_transform_y = getTransformY(container);
  container.style.transform = `translateY(${
    original_transform_y - position
  }px)`;
}

function getTransformY(e) {
  const transform = parseFloat(e.style.transform.split("(")[1]);
  if (isNaN(transform)) {
    return 0;
  } else {
    return transform;
  }
}

function isKeyboardOn() {
  return (
    document.activeElement.type == "textarea" ||
    document.activeElement.type == "text" ||
    document.activeElement.type == "select-one"
  );
}

function isInputElement(e) {
  if (e.target.type == "textarea" || e.target.type == "text") {
    return true;
  } else {
    return false;
  }
}

function isFirstQuestion(question) {
  const id = question.id; //"wispform8"
  const index = parseInt(question.id.slice(8)); //"1"
  return index == 0;
}

export function handleMove(next, prev) {
  const container = getContainer();
  let initialTouchPosition = 0;
  let initialTransform = 0;
  let question = null;
  let difference = 0;
  container.addEventListener("touchstart", e => {
    removeTransition(container);
    initialTouchPosition = e.targetTouches[0].clientY;
    initialTransform = getTransformY(container);
    question = getCurrentQuestion();
    hideButton();
  });

  container.addEventListener("touchmove", e => {
    const current_position = e.targetTouches[0].clientY;
    difference = current_position - initialTouchPosition;
    /*
            if touch move over input element, such as textarea or text
            dont move element
            Otherwise, user can not scroll over text element
    */
    if (isInputElement(e) && isKeyboardOn()) {
      return;
    }
    e.preventDefault();
    container.style.transform = `translateY(${
      initialTransform + difference
    }px)`;
  });

  container.addEventListener("touchend", e => {
    if (!question) {
      return false;
    }
    const style = getComputedStyle(question);
    const padding_top = parseInt(style.paddingTop);
    const padding_bottom = parseInt(style.paddingBottom);
    const top = question.getBoundingClientRect().top + padding_top;
    const bottom = top + getInnerHeight(question);
    const middle = window.innerHeight / 2;
    const significance = 5;

    /*
            ignore the touch event if keyboard is on
            Otherwise, user can  scroll input box and switch questions
        */
    if (isKeyboardOn()) {
      addTransition(container);
      container.style.transform = `translateY(${initialTransform}px)`;
      return;
    }
    //swipe down on the first question
    //go back to original position
    else if (isFirstQuestion(question) && difference > 0) {
      addTransition(container);
      container.style.transform = `translateY(${initialTransform}px)`;
    } else if (bottom < middle && Math.abs(difference) > significance) {
      next();
    } else if (top > middle && Math.abs(difference) > significance) {
      prev();
    }
  });
}

function getSubmitButton() {
  return document.getElementsByClassName("Form-Submit")[0];
}

function showButton() {
  const button = getSubmitButton();
  button.classList.add("Form-Submit-Show");
}

export function hideButton() {
  // const button = getSubmitButton();
  // button.classList.remove('Form-Submit-Show');
}

export function translateToSubmitButton() {
  /*
        Scroll up last question and add opacity
    */
  const container = getContainer();
  const original_transform_y = getTransformY(container);
  const questions = container.children;
  const num = questions.length;
  const question = questions[num - 1];
  const top = question.getBoundingClientRect().top;
  const bottom = top + getInnerHeight(question) + getPaddingTop(question);
  const target = window.innerHeight / 3;
  const difference = bottom - target;
  addTransition(container);
  container.style.transform = `translateY(${
    original_transform_y - difference
  }px)`;
  removeActive(question);
  /*
        show submit button
    */
  showButton();
}

export function registerButtonHandler(isEndOfQuestions, previousQuestion) {
  if (isEndOfQuestions()) {
    // hideButton();
    previousQuestion();
  }
}

/*
    Adjust the input element to the center of window
    when keyboard is on
*/
export function adjustInputPositionWhenKeyboardIsUpOnNonIosDevice() {
  if (!isIos()) {
    document.addEventListener("focusin", focusHandler);
  }
}

//position top of input box to the 1/5 of window.
function focusHandler(e) {
  setTimeout(() => {
    const container = getContainer();
    const input = e.target;
    const top = input.getBoundingClientRect().top;
    const target = window.innerHeight / 4;
    const diff = target - top;
    const original_transform = getTransformY(container);
    addTransition(container);
    container.style.transform = `translateY(${original_transform + diff}px)`;
  }, 500);
}
