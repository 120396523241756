//@flow
import * as React from "react";
import JumpLogic from "../JumpLogic";
import { type questionType } from "../../../../QuestionTypes";
import {
  getVisibleQuestions,
  getQuestionIDIndexMap,
  hasDynamicPricingLogic,
  getPaymentQuestionPaymentAmount
} from "../../../States/selectors";
import { connect } from "react-redux";
import {
  type logicJumpType,
  type branchType,
  type conditionType,
  type jointLogicType,
  type dynamicPricingOutcomeType,
  comperators,
  DEFAULT_END
} from "../../../../../FlowTypes/jumpLogicTypes";
import {
  toArray,
  removeDuplicate,
  isNullOrUndefined,
  unNullString
} from "../../../../../Library/Util";
import {
  update_jump_logic,
  updateDynamicPricing,
  update_question_config
} from "../../../States/actions";
import { type questionIDType } from "../../../../WispformTypings";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreDropdown
} from "../../../../../Component/UICore";
import { operators } from "../../../../../Library/ScoreCalculatorHelper";

type Props = {|
  questionID: questionIDType,
  questions: Array<questionType>,
  hasDynamicPricingLogic: boolean,
  questionIndex: number,
  paymentAmountFromBuilder: string,
  questionIdIndexMap: { [questionIDType]: number },
  updateDynamicPricing: (questionIDType, logicJumpType) => void,
  updateQuestionConfig: ("paymentAmount", string, number) => void
|};
type State = {||};

const endOfForm = "End of form";
const arrayOffSet = 1;

class DynamicPricing extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getQuestionIndexByID = (questionID: ?questionIDType): number => {
    return this.props.questions.findIndex(q => q.question_id === questionID);
  };

  _getDisplayTitle = (title: ?string, questionID: ?questionIDType): string => {
    return `${this._getQuestionIndexByID(questionID) + arrayOffSet}.${String(
      title
    )}`;
  };

  _getJumpToTargets = () => {
    const defaultEnd = {
      value: DEFAULT_END,
      display: endOfForm
    };

    return [
      ...this.props.questions.map((question, index) => {
        return {
          value: question.question_id,
          display: this._getDisplayTitle(question.title, question.question_id)
        };
      }),
      defaultEnd
    ];
  };

  _getCurrentQuestion = () => {
    return toArray(this.props.questions).find(
      question => question.question_id === this.props.questionID
    );
  };

  _getDynamicPricingLogic = () => {
    const currentQuestion = this._getCurrentQuestion();
    return (
      currentQuestion &&
      currentQuestion.config &&
      currentQuestion.config.dynamicPricing
    );
  };

  _getJumptoCandidates = () => {
    const jumpLogic = this._getDynamicPricingLogic();
    const branches = (jumpLogic && jumpLogic.branches) || [];

    let jumptoCandidates = [];
    jumptoCandidates = [
      ...toArray(branches).map(branch => {
        return branch.jumpToQuestionID === DEFAULT_END
          ? "end"
          : this.props.questionIdIndexMap[branch.jumpToQuestionID] +
              arrayOffSet;
      })
    ];
    const defaultJumpto = jumpLogic && jumpLogic.default;
    if (defaultJumpto) {
      if (defaultJumpto === DEFAULT_END) {
        jumptoCandidates.push("end");
      } else {
        jumptoCandidates.push(
          this.props.questionIdIndexMap[defaultJumpto] + arrayOffSet
        );
      }
    }

    return removeDuplicate(
      jumptoCandidates
        .filter(candidate => candidate === "end" || !isNaN(candidate))
        .map(candidate => (candidate === "end" ? "end" : "#" + candidate))
    );
  };

  _getAddButtonHint = () => {
    return "Update";
  };

  _getDefaultTargetQuestion = () => {
    //default to the next question
    const currentQuestionIndex = this.props.questions.findIndex(
      q => q.question_id === this.props.questionID
    );
    const nextQuestion = this.props.questions[currentQuestionIndex + 1];
    return nextQuestion
      ? {
          display: "Next question",
          value: nextQuestion.question_id
        }
      : {
          display: endOfForm,
          value: DEFAULT_END
        };
  };

  static _getOutcomeText = operator => {
    if (operator.value === "add") {
      return "To Price.";
    } else {
      return "From Price.";
    }
  };

  _renderPriceUnit = () => {
    return (
      <UICoreBox
        justifyContent="center"
        alignItems="center"
        shape="rounded"
        color="lightGrey"
        padding="xm"
        marginLeft="xm"
      >
        <UICoreText size="xs">USD</UICoreText>
      </UICoreBox>
    );
  };

  _getSelectedOperation = (outcome: ?dynamicPricingOutcomeType) => {
    if (isNullOrUndefined(outcome)) {
      return operators[0];
    }
    return {
      // $FlowFixMe
      value: String(outcome.operation),
      // $FlowFixMe
      display: String(outcome.operation)
    };
  };

  _renderBranchOutcome = (
    branch: branchType,
    updateBranchOutcome: branchType => void
  ) => {
    const outcome = branch.outcome;
    return [
      <UICoreBox marginTop="sm">
        <UICoreText color="darkGray" size="xs" weight="bold">
          Then:
        </UICoreText>
      </UICoreBox>,
      <UICoreBox direction="row" width="100%" marginTop="xm">
        <UICoreDropdown
          onChoiceClick={operation => {
            updateBranchOutcome({
              ...branch,
              outcome: {
                ...outcome,
                operation: operation
              }
            });
          }}
          selectChoice={this._getSelectedOperation(outcome)}
          choices={operators}
        />
        <UICoreBox width={100} paddingLeft="xm">
          <UICoreInput
            size="xs"
            placeholder="Amount"
            type="numeric"
            value={unNullString(outcome && outcome.amount)}
            onChange={(_, amount) => {
              updateBranchOutcome({
                ...branch,
                outcome: {
                  ...outcome,
                  amount: amount
                }
              });
            }}
          />
        </UICoreBox>
        {this._renderPriceUnit()}
      </UICoreBox>,
      <UICoreBox marginTop="sm">
        <UICoreText color="darkGray" size="xs" weight="bold">
          {DynamicPricing._getOutcomeText(this._getSelectedOperation(outcome))}
        </UICoreText>
      </UICoreBox>
    ];
  };

  _renderDefaultBlock = () => {
    return (
      <UICoreBox
        marginTop="sm"
        marginBottom="sm"
        boxShadow={true}
        shape="rounded"
        marginLeft="sm"
        marginRight="sm"
        padding="sm"
      >
        <UICoreText color="darkGray" size="xs" weight="bold">
          {"If non of the above conditions are met, charge:"}
        </UICoreText>
        <UICoreBox direction="row" paddingTop="xm">
          <UICoreBox width={100}>
            <UICoreInput
              size="xs"
              placeholder="Amount"
              type="numeric"
              onChange={(_, amount) => {
                this.props.updateQuestionConfig(
                  "paymentAmount",
                  amount,
                  this.props.questionIndex
                );
              }}
              value={this.props.paymentAmountFromBuilder}
            />
          </UICoreBox>
          {this._renderPriceUnit()}
        </UICoreBox>
      </UICoreBox>
    );
  };

  render() {
    return (
      <JumpLogic
        jumpLogic={this._getDynamicPricingLogic()}
        jumpToTargets={this._getJumpToTargets()}
        textContent={{
          modalTitle: "Dynamic Pricing",
          addNewBranchButton: "Add a Price Calculation",
          addAnotherBranchButton: "Add Another Cumulative Price Calculation",
          branchTarget: "Then:",
          fallbackTarget: "If non of the above conditions are met, charge:",
          branchTitlePrefix: "Price calculation",
          toolTip:
            "Use jump logic to redirect users to different questions of the form based on their answer."
        }}
        showUpdateButtonState={this.props.hasDynamicPricingLogic}
        addButtonHint={this._getAddButtonHint()}
        updateJumpLogic={this.props.updateDynamicPricing}
        defaultTargetQuestion={this._getDefaultTargetQuestion()}
        renderDefaultBlock={this._renderDefaultBlock}
        renderBranchOutcome={this._renderBranchOutcome}
        branchOutcomeDefaultValue={{ operation: "add", amount: "" }}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: getVisibleQuestions(state.Question),
    questionIdIndexMap: getQuestionIDIndexMap(state.Question),
    hasDynamicPricingLogic: hasDynamicPricingLogic(state.Question),
    paymentAmountFromBuilder: getPaymentQuestionPaymentAmount(state.Question),
    questionIndex: state.Question.last_edited_question
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateDynamicPricing: (
      questionID: questionIDType,
      updatedJumpLogic: logicJumpType
    ) => {
      dispatch(updateDynamicPricing(questionID, updatedJumpLogic));
    },
    updateQuestionConfig: (
      // TODO: add concrete typing
      config_name: "paymentAmount",
      value: string,
      page_number: number
    ) => {
      dispatch(update_question_config(config_name, value, page_number));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DynamicPricing);
