//@flow
import * as React from "react";
import "./style.css";
import {
  type textCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import { UICoreInput, UICoreBox } from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: textCellType => mixed
|};
type State = {||};
class TextDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox width="100%">
        <UICoreInput
          onChange={() => {}}
          value={String(this.props.value ? this.props.value : "")}
        />
      </UICoreBox>
    );
  }
}
export default TextDataUI;
