// @flow

import {
  toArray,
  replaceElementByAnchor,
  removeElemenent,
  isNullOrUndefined
} from "../Util";

export function insertElementsByAnchor<T>(
  list: Array<T>,
  anchor: T => boolean,
  inserter: T => T
): Array<T> {
  const indexToInsert = list.findIndex(anchor);
  return [
    ...list.slice(0, indexToInsert + 1),
    inserter(list[indexToInsert]),
    ...list.slice(indexToInsert + 1)
  ];
}

export function nonnullArray<T>(arr: ?Array<T>): Array<T> {
  if (arr) {
    return arr;
  }
  return [];
}

export function convertArrayToDict<T>(
  arr: Array<T>,
  keyGetter: T => any
): { [any]: T } {
  let returnMap = {};
  toArray(arr).forEach(item => {
    returnMap[keyGetter(item)] = item;
  });
  return returnMap;
}

export function updateOrInsert<T>(
  arr: Array<T>,
  targeter: T => boolean,
  item: T
): Array<T> {
  if (arr.some(targeter)) {
    return replaceElementByAnchor(arr, targeter, _ => item);
  } else {
    return [...arr, item];
  }
}

export function insertIfNonExist<T>(arr: Array<T>, item: T): Array<T> {
  if (arr.some(i => i === item)) {
    return arr;
  } else {
    return [...arr, item];
  }
}

export function removeIfExist<T>(arr: Array<T>, value: T): Array<T> {
  const index = arr.indexOf(value);
  if (index > -1) {
    // only splice array when item is found
    return removeElemenent(arr, index);
  }
  return arr;
}

export function onlyContains(arr: Array<any>, item: any): boolean {
  return arr.length === 1 && arr[0] === item;
}

export function onlyNonNull<T>(arr: Array<?T>): Array<T> {
  return toArray(arr).filter(a => !isNullOrUndefined(a));
}
