//@flow
import * as React from "react";
import { UICoreIconTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreIconType";
import "./style.css";
import { ColorTypeEnum } from "../../../FlowTypes/colorTypes";
import * as Colors from "../../../Library/Colors";
import * as Font from "../../../Library/Font";
import { SizeTypeEnum } from "../../../FlowTypes/sizeTypes";
import { UICoreListMediaTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreListType";
type Props = {|
  name: $Values<typeof UICoreIconTypeEnum>,
  color?: $Values<typeof ColorTypeEnum>,
  size?: $Values<typeof SizeTypeEnum>
|};
type State = {||};

class UICoreIcon extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _renderIonicIcon = (name: string) => {
    return (
      <i
        style={{
          color: Colors.getHexCode(this.props.color),
          fontSize: Font.getFontSize(this.props.size)
        }}
        className={`${name}`}
      />
    );
  };

  _renderFontAwesomeIcon = (name: string) => {
    return (
      <i
        style={{
          color: Colors.getHexCode(this.props.color),
          fontSize: Font.getFontSize(this.props.size)
        }}
        className={`fa ${name}`}
      />
    );
  };

  render() {
    switch (this.props.name) {
      case UICoreIconTypeEnum.image:
        return this._renderFontAwesomeIcon("fa-image");
      case UICoreIconTypeEnum.video:
        return this._renderFontAwesomeIcon("fa-video-camera");
      case UICoreIconTypeEnum.youtube:
        return this._renderFontAwesomeIcon("fa-youtube");
      case UICoreIconTypeEnum.vimeo:
        return this._renderFontAwesomeIcon("fa-vimeo");
      case UICoreIconTypeEnum.calculator:
        return this._renderFontAwesomeIcon("fa-calculator");
      case UICoreIconTypeEnum.checkMark:
        return this._renderIonicIcon("ion-checkmark");
      case UICoreIconTypeEnum.clone:
        return this._renderFontAwesomeIcon("fa-clone");
      case UICoreIconTypeEnum.trash:
        return this._renderFontAwesomeIcon("fa-trash");
      case UICoreIconTypeEnum.plus:
        return this._renderIonicIcon("ion-plus-round");
      case UICoreIconTypeEnum.bolt:
        return this._renderFontAwesomeIcon("fa-rocket");
      case UICoreIconTypeEnum.rocket:
        return this._renderFontAwesomeIcon("fa-newspaper-o");
      case UICoreIconTypeEnum.unlock:
        return this._renderFontAwesomeIcon("fa-unlock");
      case UICoreIconTypeEnum.close:
        return this._renderIonicIcon("ion-close");
      case UICoreIconTypeEnum.clipboard:
        return this._renderIonicIcon("ion-clipboard");
      case UICoreIconTypeEnum.network:
        return this._renderIonicIcon("ion-network");
      case UICoreIconTypeEnum.iosCalculator:
        return this._renderIonicIcon("ion-calculator");

      default:
        return <i className="" />;
    }
  }
}
export default UICoreIcon;
