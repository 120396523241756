//@flow
import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../../States/Answers/actions";
import ReviewAnswers from "../../Containers/ReviewAnswers";
import UI_Footer from "../../Components/UI_Footer";
import Questions from "../../Components/Questions";
import CompletePage from "../../Components/CompletePage";
import PropTypes from "prop-types";
import LoadingScreen from "../../../../Component/LoadingScreen";
import Scrollable from "../../../../Component/Scrollable";
import Desktop from "../../../../Component/Views/Desktop";
import Mobile from "../../../../Component/Views/Mobile";
import questionTypeConfig, {
  type AnswerReduxStateType
} from "../../../QuestionTypes";
import {
  initWithCustomTrackingId,
  initWithDefaultWispformTrackingID
} from "../../../../GA";
import {
  type formStyleType,
  type backgroundCssType
} from "../../../../FlowTypes/wispformStyleTypes";
import "./style.css";
import { Helmet } from "react-helmet";
import { logFormPageLoad, logFormEvent } from "../../../../Library/Logger";
import withFormDataFetcher from "../../../../Helper_HOC/withFormDataFetcher";
import { type thankyouPageType } from "../../../WispformTypings";
import { isFormFromPaidPlan } from "../../States/Answers/selectors";
import withFeatureCheck from "../../../../Helper_HOC/WithFeatureCheck";
import withCloseFormSetting from "../../../../Helper_HOC/WithCloseFormSetting";
import {
  getVisibleQuestions,
  getVisibleQuestionsCount,
  getNumOfVisibleQuestionsAnswered,
  logo,
  getThankyouPageDisplayLogic
} from "../../States/Answers/selectors";
import { preventWindowFromPushingUpOnIosInAppBrowser } from "../../../../Library/DOM";
import { logClientCustomEvent } from "../../../../Library/ClientCustomLogger";
import initGoogleTagManagerWithID from "../../../../Library/GoogleTagManager";
import { UICoreImage, UICoreBox } from "../../../../Component/UICore";
import { getJumptoQuestionID } from "../../../../Middlewares/LogicJumpMiddleware/helper";
import { type logicJumpType } from "../../../../FlowTypes/jumpLogicTypes";
import withStyles from "../../../../Helper_HOC/WithStyles";
import { toNumber } from "../../../../Library/Util";
import WithFormConfig, {
  type WithFormConfigInjectedProps
} from "../../../../Helper_HOC/WithFormConfig";
import { FinalPageType } from "../../../../Library/Forms/FormConfigs/FormConfigEnums";
import OutcomePage from "../../Components/OutcomePage";

let ReactGA = initWithDefaultWispformTrackingID();

export const FormPageContainerMobileLogoContainerClassName =
  "FormPageContainer-Logo";

type Props = {
  ...WithFormConfigInjectedProps,
  answers: Array<any>, //TODO: taoxiang: give more specific type
  answers_posted: boolean,
  add_time_to_complete: () => any, //TODO: taoxiang: give more specific type
  currentQuestionNumber: number,
  isSubmitting: boolean,
  className: string,
  post_answers: (string, string, (AnswerReduxStateType) => Promise<any>) => any, //TODO: taoxiang: give more specific type
  prev_question: () => any,
  formData: {
    created_at: string,
    form_id: number,
    id: number,
    last_edited_question: number,
    question_count: number,
    questions: Array<any>, //TODO: taoxiang: give more specific type
    styles: formStyleType,
    pages: {
      thankyouPages: {
        pages: Array<thankyouPageType>
      }
    },
    updated_at: string,
    form_name: string
  },
  questions: Array<any>, //TODO: taoxiang: give more specific type,
  invalid_answers: Array<any>,
  next_question: () => any,
  submit_answer: any => any,
  styles: formStyleType,
  backgroundCss: backgroundCssType,
  set_current_question: number => any,
  update_answer: (any, boolean) => any, //TODO: taoxiang: give more specific type
  update_answer_without_update_current_question_index: any => any,
  apiPostForm: AnswerReduxStateType => Promise<any>,
  isFormFromPaidPlan: boolean,
  visibleQuestionsLength: number,
  numOfVisibleQuestionsAnswered: number,
  isInPreview?: boolean,
  configurations: { ga_tracker_code: ?string, gtm_tracker_code: ?string },
  logo: ?string,
  thankyouPageDisplayLogic: logicJumpType,
  viewed_questions: any,
  update_viewed_questions: any => any
};

class FormPage extends React.Component<Props> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //saved to be used as window height when
    //keyboard pop out
    window.window_height = window.innerHeight;
    preventWindowFromPushingUpOnIosInAppBrowser();
    ReactGA.pageview(window.location.href);
    logFormPageLoad("formPageLoaded");
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.configurations &&
      this.props.configurations.ga_tracker_code
    ) {
      initWithCustomTrackingId(this.props.configurations.ga_tracker_code);
    }

    if (
      this.props.configurations &&
      this.props.configurations.gtm_tracker_code
    ) {
      initGoogleTagManagerWithID(this.props.configurations.gtm_tracker_code);
    }
  }

  getPublisherName() {
    let str = window.location.href;
    let publisher_name = str
      .substring(str.indexOf(":") + 1)
      .slice(2)
      .split(".")[0];
    return publisher_name;
  }

  submitForm() {
    this.props.add_time_to_complete();
    setTimeout(() => {
      this.props.post_answers(
        this.props.formData.form_name,
        this.getPublisherName(),
        this.props.apiPostForm
      );
    }, 0);
  }

  submitAnswer(answer) {
    /*
      if the question triggered keyboard
      close the keyboard first, then submit answer
      otherwise will miscalculate next question position
      due to shrinked window size squezzed by keyboard
    */
    //$FlowFixMe
    const questionConfig = new questionTypeConfig().types;
    const type = answer.type;
    const shouldDelay = questionConfig[type].delaySubmit;
    if (shouldDelay) {
      const activeElement: activeElement = document.activeElement;
      activeElement.blur();
      setTimeout(() => {
        this.props.submit_answer(answer);
      }, 250);
    } else {
      this.props.submit_answer(answer);
    }
  }

  renderDesktop() {
    return (
      <Desktop>
        <Scrollable center={true}>
          {this.props.logo && (
            <UICoreBox zIndex={10} padding="md" position="fixed">
              <UICoreImage width={75} height={75} src={this.props.logo} />
            </UICoreBox>
          )}
          <Questions
            styles={this.props.styles}
            invalid_answers={this.props.invalid_answers}
            viewed_questions={this.props.viewed_questions}
            questions={this.props.questions}
            isSubmitting={this.props.isSubmitting}
            currentQuestionNumber={this.props.currentQuestionNumber}
            submit_answer={this.submitAnswer.bind(this)}
            update_answer={this.props.update_answer}
            update_viewed_questions={this.props.update_viewed_questions}
            update_answer_without_update_current_question_index={
              this.props.update_answer_without_update_current_question_index
            }
            nextQuestion={this.props.next_question}
            previousQuestion={this.props.prev_question}
            set_current_question={this.props.set_current_question}
            submitForm={this.submitForm.bind(this)}
            isFormFromPaidPlan={this.props.isFormFromPaidPlan}
          />
        </Scrollable>
      </Desktop>
    );
  }

  renderMobile() {
    return (
      <Mobile>
        <div className="Mobile-Wrapper">
          {this.props.logo && this.props.currentQuestionNumber === 1 && (
            <UICoreBox
              className={FormPageContainerMobileLogoContainerClassName}
              zIndex={10}
              padding="sm"
              position="absolute"
            >
              <UICoreImage width={75} height={75} src={this.props.logo} />
            </UICoreBox>
          )}
          <Questions
            styles={this.props.styles}
            invalid_answers={this.props.invalid_answers}
            viewed_questions={this.props.viewed_questions}
            questions={this.props.questions}
            currentQuestionNumber={this.props.currentQuestionNumber}
            submit_answer={this.submitAnswer.bind(this)}
            update_answer={this.props.update_answer}
            update_viewed_questions={this.props.update_viewed_questions}
            isSubmitting={this.props.isSubmitting}
            update_answer_without_update_current_question_index={
              this.props.update_answer_without_update_current_question_index
            }
            nextQuestion={this.props.next_question}
            previousQuestion={this.props.prev_question}
            set_current_question={this.props.set_current_question}
            submitForm={this.submitForm.bind(this)}
            isFormFromPaidPlan={this.props.isFormFromPaidPlan}
          />
        </div>
      </Mobile>
    );
  }

  formStyle() {
    return {
      ...this.props.backgroundCss
    };
  }

  _getDisplayThankyouPageIndex = () => {
    return (
      getJumptoQuestionID(
        // $FlowFixMe
        { jumpLogic: this.props.thankyouPageDisplayLogic },
        this.props.answers,
        {},
        this.props.questions
      ) || 0
    );
  };

  _renderOutcomePage = () => {
    return <OutcomePage isEditMode={false} />;
  };

  _renderThankyouPage = () => {
    return (
      <CompletePage
        isInPreview={this.props.isInPreview}
        thankyouPageConfig={
          this.props.formData.pages &&
          this.props.formData.pages.thankyouPages &&
          this.props.formData.pages.thankyouPages.pages &&
          this.props.formData.pages.thankyouPages.pages[
            toNumber(this._getDisplayThankyouPageIndex())
          ]
        }
      />
    );
  };

  _renderForm = () => {
    if (this.props.answers_posted) {
      if (this.props.formConfig.finalPageType == FinalPageType.OUTCOMEPAGE) {
        return (
          <div
            style={this.formStyle()}
            className={"Form " + this.props.className}
          >
            {this._renderOutcomePage()}
          </div>
        );
      } else {
        return (
          <div
            style={this.formStyle()}
            className={"Form " + this.props.className}
          >
            {this._renderThankyouPage()}
          </div>
        );
      }
    } else if (this.props.formData == null) {
      return <LoadingScreen style="formLoadingScreen" />;
    } else {
      return (
        <div
          style={this.formStyle()}
          className={"Form " + this.props.className}
        >
          <div className="Form-Body">
            {this.renderDesktop()}
            {this.renderMobile()}
          </div>
          <UI_Footer
            styles={this.props.styles}
            nextQuestion={this.props.next_question}
            className="Form-Footer"
            previousQuestion={this.props.prev_question}
            currentQuestionNumber={this.props.currentQuestionNumber}
            numAnsweared={this.props.numOfVisibleQuestionsAnswered}
            question_count={this.props.visibleQuestionsLength}
          />
        </div>
      );
    }
  };

  _title = () => {
    if (this.props.formData && this.props.formData.form_name) {
      return `Wispform | ${this.props.formData.form_name}`;
    } else {
      return "Wispform";
    }
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>{this._title()}</title>
          <meta
            name="description"
            content="Trusted by thousands of happy users since launching in early 2018, Wispform offers an easy form builder for creating and managing beautiful forms and surveys."
          />
          <meta
            name="keywords"
            content="forms,surveys,online form builder,online survey tool,beautiful surveys, easy, simple, stunning, online survey builder"
          />
        </Helmet>
        {this._renderForm()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    // submit_answer: (answer, question) => {
    //   submitAnswer(answer, dispatch, question)
    // },
    submit_answer: answer => {
      dispatch(action.submit_answer(answer));
    },
    update_answer: (answer, should_validate) => {
      dispatch(action.update_answer(answer, should_validate));
    },
    update_answer_without_update_current_question_index: answer => {
      dispatch(action.updateAnswerWithouUpdateCurrentQuestionIndex(answer));
    },
    update_viewed_questions: answer => {
      dispatch(action.update_viewed_questions(answer));
    },
    next_question: () => {
      dispatch(action.next_question());
    },
    prev_question: () => {
      dispatch(action.prev_question());
    },
    post_answers: (form_name, publisher_name, apiPostAnswer) => {
      dispatch(action.post_answers(form_name, publisher_name, apiPostAnswer));
    },
    set_current_question: id => {
      dispatch(action.set_current_question(id));
    },
    add_time_to_complete: () => {
      dispatch(action.add_time_to_complete());
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    currentQuestionNumber: state.Answers.currentQuestionNumber,
    isSubmitting: state.Answers.submitting,
    answers: state.Answers.answers,
    answers_posted: state.Answers.answers_posted,
    configurations: state.Answers.configurations,
    formData: state.Answers.questions,
    visibleQuestionsLength: getVisibleQuestionsCount(state.Answers),
    questions: getVisibleQuestions(state.Answers),
    invalid_answers: state.Answers.invalid_answers,
    viewed_questions: state.Answers.viewed_questions,
    time_to_complete: state.Answers.time_to_complete,
    numOfVisibleQuestionsAnswered: getNumOfVisibleQuestionsAnswered(
      state.Answers
    ),
    thankyouPageDisplayLogic: getThankyouPageDisplayLogic(state.Answers),
    logo: logo(state.Answers),
    isFormFromPaidPlan: isFormFromPaidPlan(state.Answers)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithFormConfig(withFeatureCheck(withCloseFormSetting(withStyles(FormPage)))));
