//@flow
import * as React from "react";
import "./style.css";
import {
  type numberCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import RatingComponent from "../../CellComponents/RatingComponent";
import { UICoreBox } from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: numberCellType => mixed
|};
type State = {||};
class RatingDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox alignItems="start">
        <RatingComponent {...this.props} />
      </UICoreBox>
    );
  }
}
export default RatingDataUI;
