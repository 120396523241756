import {
  INITIALIZE_ANSWER_OBJ,
  DID_POST_ANSWERS,
  SUBMIT_ANSWER,
  NEXT_QUESTION,
  PREV_QUESTION,
  DID_GET_QUESTIONS,
  SET_CURRENT_QUESTION,
  POSTING_ANSWERS,
  UPDATE_ANSWER,
  UPDATE_VIEWED_QUESTIONS,
  ADD_VALIDATION_MESSAGE,
  REMOVE_ALL_VALIDATION_MESSAGES,
  submit_answer,
  FAILED_POSTING,
  ADD_TIME_TO_COMPLETE,
  UPDATE_VISIBLE_QUESTIONS,
  UPDATE_ANSWER_WITHOUT_UPDATE_CURRENT_QUESTION
} from "./actions";

import update from "react-addons-update";
import questionConfig, { type questionType } from "../../../QuestionTypes";
import { type formPlan } from "../../../WispformTypings";
import { type QuestionsStateType } from "../../../FormBuilderPage/States/reducer";
import WebStorage from "../../../../Library/WebStorage";
import { type buttonConfigType } from "../../../../Helper_HOC/WithConfigurableButtonText";
import { convertToNewThankYouPageDataStructureIfNecessary } from "../../../../Library/thankyouPageHelper";
import {
  type formStyleType,
  backgroundType
} from "../../../../FlowTypes/wispformStyleTypes";
import { getReformattedStyles } from "../../../../Library/FormStyleDataStructureConversion";
import { toArray, safeGet } from "../../../../Library/Util";
import { type redirectParamConfigType } from "../../../../FlowTypes/formRedirectTypes";

const question_type = new questionConfig();
const default_styles = question_type.styles;

export type AnswersState = {|
  form_id: ?string,
  answers: Array<any>,
  currentQuestionNumber: number,
  answers_posted: boolean,
  questions: { ...QuestionsStateType, visibleQuestions: Array<questionType> },
  publisher_name: ?string,
  submitting: boolean,
  invalid_answers: {},
  viewed_questions: {},
  submit_attempted: boolean,
  time_to_complete: number,
  styles: formStyleType,
  form_plan: formPlan,
  configurations: {
    ga_tracker_code: ?string,
    gtm_tracker_code: ?string,
    redirect_url: ?string,
    logo: ?string,
    buttonText: ?buttonConfigType,
    redirectParam: ?redirectParamConfigType
  }
|};

let defaultState = {
  form_id: null,
  answers: [],
  currentQuestionNumber: 1,
  answers_posted: false,
  questions: null,
  allQuestions: null,
  publisher_name: null,
  submitting: false,
  invalid_answers: {},
  viewed_questions: {},
  submit_attempted: false,
  time_to_complete: 0,
  styles: default_styles,
  form_plan: "basic",
  configurations: {
    ga_tracker_code: null,
    gtm_tracker_code: null,
    logo: null,
    buttonText: null
  }
};

function _getQuestionCount(state: AnswersState): number {
  return toArray(safeGet(_ => state.questions.questions)).length;
}

export default function Answers(state = defaultState, action) {
  switch (action.type) {
    case SUBMIT_ANSWER:
      return submitAnswer(state, action);
      break;

    case UPDATE_ANSWER:
      return Object.assign({}, state, {
        answers: updateAnswer(state.answers, action.answer),
        currentQuestionNumber: action.answer.index + 1
      });
      break;

    case UPDATE_ANSWER_WITHOUT_UPDATE_CURRENT_QUESTION:
      return Object.assign({}, state, {
        answers: updateAnswer(state.answers, action.answer)
      });
      break;

    case UPDATE_VIEWED_QUESTIONS:
      return update(state, {
        viewed_questions: {
          [action.answer.question_id]: { $set: true }
        }
      });

    case INITIALIZE_ANSWER_OBJ:
      return Object.assign({}, state, {
        form_id: action.answer_info.form_id,
        publisher_name: window.location.host.split(".")[0],
        answers: action.answer_info.answers
      });
      break;

    case ADD_VALIDATION_MESSAGE:
      return add_validation_message(state, action.questionID, action.message);
      break;

    case REMOVE_ALL_VALIDATION_MESSAGES:
      return remove_all_validation_message(state, action.questionID);
      break;
    case ADD_TIME_TO_COMPLETE:
      return Object.assign({}, state, {
        time_to_complete: getSeconds()
      });
      break;
    case NEXT_QUESTION:
      return Object.assign({}, state, {
        currentQuestionNumber: addCurrentQuestionNumber(
          state.currentQuestionNumber,
          _getQuestionCount(state)
        )
      });
      break;

    case PREV_QUESTION:
      return Object.assign({}, state, {
        currentQuestionNumber: subtractCurrentQuestionNumber(
          state.currentQuestionNumber
        )
      });
      break;

    case DID_POST_ANSWERS:
      return Object.assign({}, state, {
        answers_posted: true,
        submitting: false
      });
      break;
    case FAILED_POSTING:
      return Object.assign({}, state, {
        submitting: false
      });

    case DID_GET_QUESTIONS:
      return Object.assign({}, state, {
        questions: {
          ...action.questions,
          pages: convertToNewThankYouPageDataStructureIfNecessary(
            action.questions.pages
          )
        },
        styles: getReformattedStyles(action.questions.styles) || state.styles,
        pages:
          action.questions &&
          convertToNewThankYouPageDataStructureIfNecessary(
            action.questions.pages
          ),
        form_plan: action.questions.form_plan,
        configurations: action.questions.configurations
      });
      break;

    case UPDATE_VISIBLE_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          visibleQuestions: action.questions
        }
      };

    case SET_CURRENT_QUESTION:
      return Object.assign({}, state, {
        currentQuestionNumber: action.id + 1
      });
      break;

    case POSTING_ANSWERS:
      return Object.assign({}, state, {
        submitting: true,
        submit_attempted: true,
        //add 1 to stay at submitarea
        //change currentQuestionNumber to allow transition back to invalid question
        currentQuestionNumber:
          state.questions.questions && state.questions.questions.length + 1
      });
      break;

    default:
      return state;
      break;
  }
}

// add newAnswer to state.answers
// return a new state.answers
function updateAnswer(currentAnswers, newAnswer) {
  let newAnswers = currentAnswers.map(function (item, index) {
    if (item.question_id == newAnswer.questionID) {
      return {
        type: item.type,
        answer: newAnswer.answer,
        question_id: item.question_id,
        title: item.title
      };
    } else {
      return item;
    }
  });
  return newAnswers;
}

function addCurrentQuestionNumber(currentQuestionNumber, question_count) {
  return currentQuestionNumber > question_count
    ? currentQuestionNumber
    : currentQuestionNumber + 1;
}

function subtractCurrentQuestionNumber(currentQuestionNumber) {
  return currentQuestionNumber <= 1
    ? currentQuestionNumber
    : currentQuestionNumber - 1;
}

function add_validation_message(state, questionID, message) {
  const invalid_answers = state.invalid_answers;
  //already has that message
  if (
    invalid_answers[questionID] &&
    invalid_answers[questionID].indexOf(message) != -1
  ) {
    return state;
  } else {
    if (invalid_answers[questionID]) {
      return update(state, {
        invalid_answers: {
          [questionID]: { $push: [message] }
        }
      });
    } else {
      return update(state, {
        invalid_answers: {
          [questionID]: { $set: [message] }
        }
      });
    }
  }
}

function remove_all_validation_message(state, questionID) {
  return update(state, {
    invalid_answers: {
      [questionID]: { $set: null }
    }
  });
}

function submitAnswer(state, action) {
  return Object.assign({}, state, {
    answers: updateAnswer(state.answers, action.answer),
    //optional currentQuestionNumber used in logicJumpMiddleware to jump to any question
    currentQuestionNumber: action.currentQuestionNumber
      ? action.currentQuestionNumber
      : action.answer.index + 2
  });
}

function getSeconds() {
  const start_time = parseInt(WebStorage.getFromWebStorage("start_time"));
  const end_time = new Date().getTime();
  const time_to_complete = end_time - start_time;
  const time_to_complete_second = time_to_complete * 0.001;
  return time_to_complete_second || 0;
}
