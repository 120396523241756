import React, { Component } from "react";
import { VelocityComponent, velocityHelpers } from "velocity-react";
import WithStyles from "../../../../Helper_HOC/WithStyles";
import "./style.css";

class UI_Rating_Icon extends Component {
  constructor(props) {
    super(props);
  }

  starType(index) {
    let selectedStarIndex = this.props.SelectedStarIndex;
    if (index <= selectedStarIndex) return "fa-star";
    else return "fa-star-o";
  }
  handleIconClicked() {
    this.props.onQuestionComplete(this.props.index);
  }
  render() {
    return (
      <VelocityComponent duration={200} {...this.props.animationProps}>
        <i
          id={this.props.id}
          className={`fa ${this.starType(
            this.props.index
          )} fa-2x UI_Rating-Star`}
          aria-hidden="true"
          style={{ color: this.props.styles.answer }}
          onClick={this.handleIconClicked.bind(this)}
        />
      </VelocityComponent>
    );
  }
}

export default WithStyles(UI_Rating_Icon);
