//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreInput,
  UICoreTooltip,
  UICoreDropdown
} from "../../../../../Component/UICore";
import { updateQuestionContent } from "../../../../FormBuilderPage/States/actions";
import { connect } from "react-redux";
import "./style.css";

type longTextQuestionContentsType = {|
  dateFormat?: string
|};

type Props = {|
  questionIndex: number,
  contents?: longTextQuestionContentsType,
  updateQuestionContent: (number, longTextQuestionContentsType) => {}
|};
type State = {||};

class DateFormatDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleFormatUpdate = (targetAnswer: string) => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(this.props.questionIndex, {
        ...this.props.contents,
        dateFormat: targetAnswer
      });
  };

  _getCurrentFormat = () => {
    if (
      this.props.contents &&
      this.props.contents.dateFormat &&
      this.props.contents.dateFormat != null
    ) {
      return {
        value: this.props.contents.dateFormat,
        display: this.props.contents.dateFormat
      };
    } else {
      return {
        value: "MM/DD/YYYY",
        display: "MM/DD/YYYY"
      };
    }
  };

  render() {
    return (
      <UICoreBox>
        <UICoreDropdown
          selectChoice={this._getCurrentFormat()}
          dropdownMaxHeight={150}
          choices={[
            { value: "MM/DD/YYYY", display: "MM/DD/YYYY" },
            { value: "DD/MM/YYYY", display: "DD/MM/YYYY" }
          ]}
          width="120px"
          dropdownWidth={160}
          onChoiceClick={answer => this._handleFormatUpdate(answer)}
        />
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(
        updateQuestionContent(
          pageNumber,
          contents,
          true /* should save to server */
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DateFormatDropdown);
