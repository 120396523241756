//@flow
import * as React from "react";
import Dropzone from "react-dropzone";
import {
  UICoreBox,
  UICoreButton,
  UICoreTooltip,
  UICoreFlyOut,
  UICoreList,
  UICoreText,
  UICoreModal,
  UICoreInput,
  UICoreImageUploader
} from "../../../../../Component/UICore";
import withNotifications, {
  type notificationTypes,
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import { browserHistory } from "react-router";
import { logErrorInfo } from "../../../../../Helper_HOC/WithErrorBoundary";
import { uploadFileToAWS } from "../../../../../Library/AwsHelper";
import { logFeatureEvent } from "../../../../../Library/Logger";
import { UICoreListMediaTypeEnum } from "../../../../../FlowTypes/UICoreTypes/UICoreListType";
import { UICoreIconTypeEnum } from "../../../../../FlowTypes/UICoreTypes/UICoreIconType";
import {
  type questionMediaTypeEnumType,
  questionMediaTypeEnum
} from "../../../../../FlowTypes/questionTypes";
import { injectArgs } from "../../../../../Library/Util";
import { hasMediaSpec } from "../../../../../Component/QuestionMediaRender";
import QuestionType from "../../QuestionType";
import AWS from "aws-sdk";
import "./style.css";
import { isPaidUser } from "../../../../../Library/UserInfo";
import { getRequest } from "../../../../../Library/Request";
import { getImageFileDimension } from "../../../../../Library/File";

type Props = {|
  ...InjectedProps,
  hasMedia: boolean,
  question: QuestionType,
  questionIndex: number,
  onMediaUploadSuccess: (
    string,
    type: questionMediaTypeEnumType,
    ?number,
    ?number
  ) => void,
  onRemoveMediaButtonClick: () => void
|};
type State = {|
  uploadingMedia: boolean,
  showMediaMenu: boolean,
  uploadingProgress: number,
  showYoutubeModal: boolean,
  showVimeoModal: boolean,
  showImageModal: boolean,
  youtubeURL: string,
  vimeoURL: string
|};

const oneMB = 1000000;
class UploadMediaButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      uploadingMedia: false,
      showMediaMenu: false,
      uploadingProgress: 0,
      showYoutubeModal: false,
      showVimeoModal: false,
      showImageModal: false,
      youtubeURL: "",
      vimeoURL: ""
    };
  }

  _addButtonNode: ?HTMLElement = null;

  _startUplaodingMedia = () =>
    this.setState({
      uploadingMedia: true,
      showMediaMenu: false
    });

  _finishUploadingMedia = () => {
    this.setState({ uploadingMedia: false, showImageModal: false });
  };

  _showInvalidFileNotification = () => {
    this.props.showNotification(
      "error",
      "Invalid image file",
      "You can only upload image file with .jpeg .png"
    );
  };

  _handleProgress = (progress: AWS.S3.ManagedUpload.Progress) => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100
    );
    this.setState({
      uploadingProgress: progressInPercentage
    });
  };

  _handleMediaUpload = (
    mediaType: questionMediaTypeEnumType,
    files: Array<File>,
    rejectedFiles: Array<File>
  ) => {
    const file = files[0];
    if (!file) {
      this._showInvalidFileNotification();
      return;
    }
    let maxMB = 5;
    if (isPaidUser()) {
      maxMB = 50;
    }
    if (file.size > maxMB * oneMB) {
      logErrorInfo("fileupload too big", "");
      this.props.showNotification(
        "error",
        "Failed to upload image",
        `Image size should be less than ${maxMB} MB`
      );
      return;
    }
    uploadFileToAWS(
      file,
      {}, //config
      this._startUplaodingMedia,
      this._handleProgress,
      data => {
        getImageFileDimension(file, dimension => {
          this.props.onMediaUploadSuccess(
            data.Location,
            mediaType,
            dimension.width,
            dimension.height
          );
          this._finishUploadingMedia();
        });
      },
      null
    );
  };

  _handleAddYoutubeEmbed = event => {
    let youtubeID = this._getYoutubeVideoID(this.state.youtubeURL);
    if (typeof youtubeID === "string" || youtubeID instanceof String) {
      this.props.onMediaUploadSuccess(
        youtubeID,
        questionMediaTypeEnum.youtube,
        null,
        null
      );
      this.setState({ showYoutubeModal: false });
    } else {
      this.props.showNotification(
        "error",
        "Invalid Youtube URL",
        "Youtube URL incorrect, please obtain it from your browser."
      );
    }
  };

  _handleAddVimeoEmbed = event => {
    fetch("https://vimeo.com/api/oembed.json?url=" + this.state.vimeoURL)
      .then(response => response.json())
      .then(data => {
        let vimeoID = data.video_id;
        if (typeof vimeoID === "number" || vimeoID instanceof Number) {
          this.props.onMediaUploadSuccess(
            vimeoID,
            questionMediaTypeEnum.vimeo,
            null,
            null
          );
          this.setState({ showVimeoModal: false });
        } else {
          this.props.showNotification(
            "error",
            "Invalid Vimeo URL",
            "Vimeo URL incorrect, please obtain it from your browser."
          );
        }
      })
      .catch(e =>
        this.props.showNotification(
          "error",
          "Invalid Vimeo URL",
          "Vimeo URL incorrect, please obtain it from your browser."
        )
      );
  };

  _validateYoutubeURL = (input: string) => {
    let youtubeID = this._getYoutubeVideoID(input);
    if (typeof youtubeID === "string" || youtubeID instanceof String) {
      return "";
    }
    return "Youtube URL incorrect, please obtain it from your browser.";
  };

  _getYoutubeVideoID = (input: string) => {
    let regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    let match = input.match(regExp);
    let finalURL = match && match[7].length == 11 ? match[7] : false;
    return finalURL;
  };

  _imageUploadListItemWrapper = (itemUI: React.Element<any>) => {
    // return (
    //   <Dropzone
    //     accept="image/*"
    //     onDrop={injectArgs(
    //       this._handleMediaUpload,
    //       questionMediaTypeEnum.image
    //     )}
    //   >
    //     {({ getRootProps, getInputProps }) => {
    //       return (
    //         <div {...getRootProps()}>
    //           <input {...getInputProps()} />
    //           {itemUI}
    //         </div>
    //       );
    //     }}
    //   </Dropzone>
    // );
    return (
      <UICoreBox
        onClick={_ => {
          logFeatureEvent(
            "formbuilderPage/uiCoreImageUploaderOpenedQuestionImage"
          );
          this.setState({ showImageModal: true });
        }}
      >
        {itemUI}
      </UICoreBox>
    );
  };

  _videoUploadListItemWrapper = (itemUI: React.Element<any>) => {
    return (
      <Dropzone
        accept="video/*"
        onDrop={injectArgs(
          this._handleMediaUpload,
          questionMediaTypeEnum.video
        )}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              {itemUI}
            </div>
          );
        }}
      </Dropzone>
    );
  };

  _youtubeEmbedItemWrapper = (itemUI: React.Element<any>) => {
    return (
      <UICoreBox
        onClick={_ => {
          this.setState({ showYoutubeModal: true });
        }}
      >
        {itemUI}
      </UICoreBox>
    );
  };

  _vimeoEmbedItemWrapper = (itemUI: React.Element<any>) => {
    return (
      <UICoreBox
        onClick={_ => {
          this.setState({ showVimeoModal: true });
        }}
      >
        {itemUI}
      </UICoreBox>
    );
  };

  _renderMediaUploadButton = () => {
    if (this.props.hasMedia) {
      return (
        <UICoreButton
          onClick={_ => {
            this.props.onRemoveMediaButtonClick();
            this.setState({ youtubeURL: "" });
            this.setState({ vimeoURL: "" });
          }}
          color="red"
          size="sm"
        >
          {"Remove"}
        </UICoreButton>
      );
    } else if (this.state.uploadingMedia) {
      return (
        <UICoreBox
          shape="rounded"
          color="blue"
          paddingLeft="xm"
          paddingRight="xm"
          paddingTop="xxm"
          paddingBottom="xxm"
        >
          <UICoreText color="white" size="xs">
            {this.state.uploadingProgress + "%"}
          </UICoreText>
        </UICoreBox>
      );
    } else {
      return (
        <UICoreBox
          innerRef={e => {
            this._addButtonNode = e;
          }}
        >
          <UICoreTooltip.WrapAround
            size={200}
            text="Add image or video to provide richer context about your question"
          >
            <UICoreButton
              onClick={() => this.setState({ showMediaMenu: true })}
              size="sm"
              color="lightBlue"
            >
              {" Add "}
            </UICoreButton>
          </UICoreTooltip.WrapAround>
        </UICoreBox>
      );
    }
  };

  _renderAddImageModal = () => {
    return (
      this.state.showImageModal && (
        <UICoreImageUploader
          onDismiss={() => {
            this.setState({ showImageModal: false });
          }}
          storeImage={this.props.onMediaUploadSuccess}
          unsplashOrientation="landscape"
        />
      )
    );
  };

  _renderAddYoutubeModal = () => {
    return (
      this.state.showYoutubeModal && (
        <UICoreModal
          header="Add Youtube URL"
          onDismiss={() => {
            this.setState({ showYoutubeModal: false });
          }}
          body={
            <UICoreBox
              name="Body"
              paddingLeft="sm"
              paddingRight="sm"
              paddingBottom="md"
              paddingTop="md"
              direction="column"
            >
              <UICoreBox paddingBottom="sm">
                <UICoreText overflow="wrap">
                  Please enter your Youtube's URL below so we can embed it on
                  your form.
                </UICoreText>
              </UICoreBox>

              <UICoreBox paddingBottom="sm">
                <UICoreInput
                  onChange={e => {
                    this.setState({ youtubeURL: e.target.value });
                  }}
                  placeholder="e.g. https://www.youtube.com/watch?v=caFWRzM-QfQ"
                  value={this.state.youtubeURL}
                  validator={this._validateYoutubeURL}
                />
              </UICoreBox>

              <UICoreBox>
                <UICoreButton size="md" onClick={this._handleAddYoutubeEmbed}>
                  Embed Youtube Video
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    );
  };

  _renderAddVimeoModal = () => {
    return (
      this.state.showVimeoModal && (
        <UICoreModal
          header="Add Vimeo Embed"
          onDismiss={() => {
            this.setState({ showVimeoModal: false });
          }}
          body={
            <UICoreBox
              name="Body"
              paddingLeft="sm"
              paddingRight="sm"
              paddingBottom="md"
              paddingTop="md"
              direction="column"
            >
              <UICoreBox paddingBottom="sm">
                <UICoreText overflow="wrap">
                  Please enter your Vimeo's URL below so we can embed it on your
                  form.
                </UICoreText>
              </UICoreBox>

              <UICoreBox paddingBottom="sm">
                <UICoreInput
                  onChange={e => {
                    this.setState({ vimeoURL: e.target.value });
                  }}
                  placeholder="e.g. https://vimeo.com/336812660"
                  value={this.state.vimeoURL}
                  // validator={this._validateVimeoURL}
                />
              </UICoreBox>

              <UICoreBox>
                <UICoreButton size="md" onClick={this._handleAddVimeoEmbed}>
                  Embed Vimeo Video
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    );
  };

  render() {
    return [
      this._renderMediaUploadButton(),
      this._renderAddYoutubeModal(),
      this._renderAddVimeoModal(),
      this._renderAddImageModal(),
      this.state.showMediaMenu && (
        <UICoreBox position="absolute" top="0px">
          <UICoreFlyOut
            idealDirection="down"
            onDismiss={() => this.setState({ showMediaMenu: false })}
            anchor={this._addButtonNode}
            showCaret={false}
            size="xs"
          >
            <UICoreBox className="UploadMediaButton-Menu" width="100%">
              <UICoreList
                itemWrapper={(itemUI, item) => {
                  if (item.title === "Image")
                    return this._imageUploadListItemWrapper(itemUI);
                  if (item.title === "Video Upload")
                    return this._videoUploadListItemWrapper(itemUI);
                  if (item.title === "Embed Youtube")
                    return this._youtubeEmbedItemWrapper(itemUI);
                  if (item.title === "Embed Vimeo")
                    return this._vimeoEmbedItemWrapper(itemUI);
                  return itemUI;
                }}
                items={[
                  {
                    title: "Image",
                    leftMediaSpec: {
                      type: UICoreListMediaTypeEnum.icon,
                      src: UICoreIconTypeEnum.image
                    }
                  },
                  {
                    title: "Video Upload",
                    leftMediaSpec: {
                      type: UICoreListMediaTypeEnum.icon,
                      src: UICoreIconTypeEnum.video
                    }
                  },
                  {
                    title: "Embed Youtube",
                    leftMediaSpec: {
                      type: UICoreListMediaTypeEnum.icon,
                      src: UICoreIconTypeEnum.youtube
                    }
                  },
                  {
                    title: "Embed Vimeo",
                    leftMediaSpec: {
                      type: UICoreListMediaTypeEnum.icon,
                      src: UICoreIconTypeEnum.vimeo
                    }
                  }
                ]}
              ></UICoreList>
            </UICoreBox>
          </UICoreFlyOut>
        </UICoreBox>
      )
    ];
  }
}

export default withNotifications(UploadMediaButton);
