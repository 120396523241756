//@flow
import * as React from "react";
import * as action from "../Pages/Form/States/Answers/actions";
import questionTypeConfig, {
  type formDataType,
  type AnswerReduxStateType
} from "../Pages/QuestionTypes";
import { response_question } from "../Pages/Form/States/Answers/fakeQuestions";
import { compose } from "redux";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { routeFormNotFoundPage } from "../App";
import { getHiddenFieldQueestionIdValueMap } from "../Library/HiddenFieldUtil";
import { safeGet } from "../Library/Util";
import { type hiddenFieldVariablesType } from "../FlowTypes/hiddenFieldTypes";
import * as formViewerContext from "../Library/ViewerContext/formViewerContext";
import { getFirstSubDomain } from "../Library";
import { updateFormConfig } from "../State/FormConfig/actions";
import { FormTypeEnum } from "../FlowTypes/forms";
import Form from "../Library/Forms";

/*
    withFormDataFetcher gives us the flexibility to choose where to fetch the form data and where to post the form data:
    either to wispform db or to wisptable db. 

    This flexibility enables the transformation of a wisptable into wispform view. 

    To use this HOC, three functions are needed. 
    (1) apiGetFormData: supply a function to fetch data (either from wispform or wisptable). The returning data should be of the formate of wispform
    (2) getFormID: to return the form ID to fetch.
    (3) apiPostForm: supply a function to post form data. apiPostForm will always receive Answer redux state as first argument, and it is this function's responsibility to do data transforming to fit into target endpoint.
*/

type State = {||};
type InjectedProps = {|
  initialize_answer_obj: any => void,
  did_get_questions: formDataType => void,
  updateFormConfig: () => void
|};

function withFormDataFetcher(
  apiGetFormData: string => Promise<formDataType>,
  getFormID: () => string,
  apiPostForm: (AnswerReduxStateType, formID: string) => Promise<any>
) {
  return function <PassedProps: Object>(
    WrappedComponent: React.ComponentType<PassedProps>
  ): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
    return class Wrapper extends React.Component<PassedProps, State> {
      componentDidMount() {
        const formID = getFormID();
        apiGetFormData(formID)
          .then((formData: formDataType) => {
            formViewerContext.initFormViewerContext(
              formData.form_id,
              formData.form_plan,
              safeGet(_ => getFirstSubDomain()),
              formData.publisherID
            );
            Form.setFormConfig(formData.form_type || FormTypeEnum.Classic);
            this.props.updateFormConfig();
            this.props.initialize_answer_obj({
              form_id: getFormID(),
              publisher_name: formData ? formData.publisher_name : null,
              answers: defaultAnswers(
                formData.questions,
                formData.configurations &&
                  formData.configurations.hiddenFieldVariables
              )
            });
            this.props.did_get_questions(formData);
          })
          .catch(error => {
            browserHistory.push(routeFormNotFoundPage);
          });
      }

      render() {
        return (
          <WrappedComponent
            apiPostForm={answer => apiPostForm(answer, getFormID())}
            {...this.props}
          />
        );
      }
    };
  };
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    initialize_answer_obj: answer_info =>
      dispatch(action.initialize_answer_obj(answer_info)),
    did_get_questions: questions =>
      dispatch(action.did_get_questions(questions)),
    updateFormConfig: () => dispatch(updateFormConfig())
  };
};

//create default answer object for empty questions
function defaultAnswers(
  questions,
  hiddenFieldVariables: ?hiddenFieldVariablesType
) {
  let questionConfig = new questionTypeConfig();
  const hiddenFieldQueestionIdValueMap =
    hiddenFieldVariables &&
    getHiddenFieldQueestionIdValueMap(hiddenFieldVariables);
  return questions.map(question => {
    return {
      question_id: question.question_id,
      type: question.type,
      title: question.title,
      answer:
        question.type === "HiddenField" && hiddenFieldQueestionIdValueMap
          ? hiddenFieldQueestionIdValueMap[question.question_id]
          : //$FlowFixMe
            questionConfig.types[question.type].defaultResponse.slice(0)
    };
  });
}

export default function withFormDataSource(
  apiGetFormData: string => Promise<formDataType>,
  getFormID: () => string,
  apiPostForm: (AnswerReduxStateType, string) => Promise<any>
) {
  return compose(
    connect(mapStateToProps, mapDispatchToProps),
    withFormDataFetcher(apiGetFormData, getFormID, apiPostForm)
  );
}
