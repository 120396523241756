//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "..";
import {
  type UICoreListItemType,
  type UICoreListMediaSpecType,
  type UICoreListItemTypeEnumType,
  UICoreListItemTypeEnum,
  UICoreListMediaTypeEnum
} from "../../../FlowTypes/UICoreTypes/UICoreListType";
import UICoreText from "../UICoreText";
import UICoreImage from "../UICoreImage";
import UICoreIcon from "../UICoreIcon";
import { toNumber, executeCallbackSafely } from "../../../Library/Util";
import { SizeTypeEnum } from "../../../FlowTypes/sizeTypes";
import { UICoreIconTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreIconType";
type Props = {|
  items: Array<UICoreListItemType>,
  itemWrapper?: (React.Element<any>, UICoreListItemType) => React.Element<any>,
  onItemClick?: (event: SyntheticEvent<HTMLDivElement>, extra: any) => void,
  onItemMouseDown?: (
    event: SyntheticMouseEvent<HTMLDivElement>,
    extra: any
  ) => void
|};
type State = {||};

class UICoreList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _renderCheckBox = (child: React.Element<any>) => {
    return (
      <UICoreBox
        width="16px"
        height="16px"
        color="lightGrey"
        alignItems="center"
        justifyContent="center"
        dangerous_style={{ borderRadius: "3px" }}
      >
        {child}
      </UICoreBox>
    );
  };

  renderMedia = (mediaSpec: UICoreListMediaSpecType) => {
    switch (mediaSpec.type) {
      case UICoreListMediaTypeEnum.icon:
        return (
          <UICoreIcon size={SizeTypeEnum.md} name={toNumber(mediaSpec.src)} />
        );
      case UICoreListMediaTypeEnum.image:
        return <UICoreImage src={String(mediaSpec.src)} />;
      case UICoreListMediaTypeEnum.checkBox:
        let isSelected = Boolean(mediaSpec.src);
        return isSelected
          ? this._renderCheckBox(
              <UICoreIcon
                size={SizeTypeEnum.xxm}
                name={UICoreIconTypeEnum.checkMark}
              />
            )
          : this._renderCheckBox(<div />);
      default:
        return <UICoreBox>{""}</UICoreBox>;
    }
  };

  _getItemUI = (item: UICoreListItemType) => {
    const stringItemUI = (
      <UICoreBox
        paddingTop="xm"
        paddingBottom="xm"
        paddingLeft="sm"
        paddingRight="sm"
        alignItems="center"
        justifyContent="between"
        direction="row"
        hoverable={true}
        onClick={e => {
          if (item.extra && item.extra.disabled) {
            e.preventDefault();
          } else {
            executeCallbackSafely(item.onClick, e, item.extra);
            executeCallbackSafely(this.props.onItemClick, e, item.extra);
          }
        }}
        onMouseDown={e => {
          if (item.extra && item.extra.disabled) {
            e.preventDefault();
          } else {
            executeCallbackSafely(this.props.onItemMouseDown, e, item.extra);
          }
        }}
      >
        <UICoreBox
          width={item.rightMediaSpec ? "calc(100% - 50px)" : "100%"}
          direction="row"
          alignItems="center"
        >
          {item.leftMediaSpec && (
            <UICoreBox paddingRight="xm">
              {this.renderMedia(item.leftMediaSpec)}
            </UICoreBox>
          )}
          {item.extra && item.extra.disabled ? (
            <UICoreBox direction="row" alignItems="center" width="100%">
              <UICoreText
                disableNewLine={true}
                overflow="ellipsis"
                color="Grey"
              >
                {item.title}
              </UICoreText>
              <UICoreBox
                paddingLeft="xxm"
                paddingRight="xxm"
                color="Grey"
                marginLeft="auto"
                borderRadius="lg"
              >
                <UICoreText color="white" size="xxs">
                  {item.extra.disabledReason}
                </UICoreText>
              </UICoreBox>
            </UICoreBox>
          ) : (
            <UICoreText disableNewLine={true} overflow="ellipsis" color="black">
              {item.title}
            </UICoreText>
          )}
        </UICoreBox>
        {item.rightMediaSpec && (
          <UICoreBox paddingRight="xm">
            {this.renderMedia(item.rightMediaSpec)}
          </UICoreBox>
        )}
      </UICoreBox>
    );
    switch (item.itemType) {
      case UICoreListItemTypeEnum.stringItem:
        return stringItemUI;
      case UICoreListItemTypeEnum.header:
        return (
          <UICoreBox
            paddingTop="xm"
            paddingBottom="xm"
            paddingLeft="sm"
            paddingRight="sm"
            alignItems="center"
            direction="row"
          >
            <UICoreText color="black" weight="bold">
              {item.title}
            </UICoreText>
          </UICoreBox>
        );
      default:
        return stringItemUI;
    }
  };

  render() {
    return (
      <UICoreBox width="100%">
        {this.props.items.map(item => {
          const itemUI = this._getItemUI(item);
          return this.props.itemWrapper
            ? this.props.itemWrapper(itemUI, item)
            : itemUI;
        })}
      </UICoreBox>
    );
  }
}
export default UICoreList;
