//@flow
import * as React from "react";
import "./style.css";
import {
  type fileUploadCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import ContactInfo from "../../CellComponents/ContactComponent/ContactInfo";
import { UICoreBox } from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: fileUploadCellType => mixed
|};

type State = {||};
class ContactDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    if (this.props.value && this.props.value.name && this.props.value.email) {
      const name = String(this.props.value.name);
      //$FlowFixMe
      const email = String(this.props.value.email);
      return (
        <UICoreBox width="100%">
          <ContactInfo
            onNameChange={() => {}}
            onEmailChange={() => {}}
            name={String(name)}
            email={String(email)}
          />
        </UICoreBox>
      );
    } else return null;
  }
}
export default ContactDataUI;
