// @flow
import * as React from "react";
import { connect } from "react-redux";
import {
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreMultiSelectDropdown,
  UICoreText,
  UICoreInput
} from "../../../../../Component/UICore";
import { Scrollbars } from "react-custom-scrollbars";
import {
  update_question_value,
  update_outcome_page,
  set_current_editing_question,
  saveToBackend
} from "../../../../FormBuilderPage/States/actions";
import { type TquestionValue } from "../../../../../FlowTypes/questionValueTypes";
import { type questionIDType } from "../../../../WispformTypings";
import formConfiguration, {
  QuestionTypeEnum,
  type questionType
} from "../../../../QuestionTypes";
import {
  insertIfNonExist,
  nonnullArray,
  removeIfExist
} from "../../../../../Library/Primitives/Array";

import {
  getOutcomePageContentConfig,
  getOutcomes
} from "../../../States/selectors";
import { type TOutcomeData } from "../../../../../Component/GenericFormContent/type";
import {
  isEmptyArray,
  isNonEmptyArray,
  toArray,
  unNullString,
  isStringNumericOrNull
} from "../../../../../Library/Util";
import { type TGenericContentConfig } from "../../../../../Component/GenericFormContent/type";
import uniqid from "uniqid";

type Props = {|
  question: questionType,
  outcomes: Array<TOutcomeData>,
  outcomePageConfig: TGenericContentConfig,
  saveToBackend: () => void,
  updateQuestionValue: (
    questionID: questionIDType,
    updatedQuestionValue: TquestionValue
  ) => void,
  updateOutcomePage: (pageConfig: TGenericContentConfig) => void,
  set_current_editing_question: (string | number) => void
|};
type State = {|
  showModal: boolean
|};

class QuestionValue extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false
    };
  }

  _getQuestionValue = (): TquestionValue => {
    if (this.props.question.questionValue) {
      return this.props.question.questionValue;
    } else {
      return {
        choiceValueMapping: {}
      };
    }
  };

  _onChoiceValueUpdated = (event: any, choiceContent: string) => {
    if (isStringNumericOrNull(event.target.value)) {
      const questionValue = this._getQuestionValue();
      this.props.updateQuestionValue(this.props.question.question_id, {
        ...questionValue,
        choiceValueMapping: {
          ...questionValue.choiceValueMapping,
          [choiceContent]: event.target.value
        }
      });
    }
  };

  _renderChoiceValueMap = (choiceDisplay: any, choiceContent: any) => {
    const value = this.props.question.questionValue;
    const choiceValue =
      value &&
      value.choiceValueMapping &&
      value.choiceValueMapping[choiceContent];
    return (
      <UICoreBox
        verticalPadding="xm"
        horizontalPadding="xm"
        shape="rounded"
        borderColor="#ECECEC"
        marginTop="sm"
      >
        <UICoreText color="black">If user choose:</UICoreText>
        <UICoreBox
          hexColor="#daedff"
          shape="rounded"
          marginTop="xm"
          marginBottom="xm"
          paddingLeft="xm"
          paddingRight="xm"
          paddingTop="xxm"
          paddingBottom="xxm"
          maxWidth="60%"
          width="min-content"
        >
          {choiceDisplay ? (
            <UICoreText
              overflow="ellipsis"
              color="lightBlue"
              size="xs"
              disableNewLine={true}
            >
              {choiceDisplay}
            </UICoreText>
          ) : (
            <UICoreText
              overflow="ellipsis"
              color="lightBlue"
              size="xs"
              disableNewLine={true}
            >
              Undefined
            </UICoreText>
          )}
        </UICoreBox>
        <UICoreBox paddingTop="xm">
          <UICoreText color="black">
            This question will be assigned the value
          </UICoreText>
        </UICoreBox>
        <UICoreBox paddingTop="xm">
          <UICoreInput
            onChange={e => this._onChoiceValueUpdated(e, choiceContent)}
            value={unNullString(choiceValue)}
            placeholder={"Enter number value"}
          />
        </UICoreBox>
      </UICoreBox>
    );
  };

  _hasQuestionValue = () => {
    return (
      (this.props.question &&
        this.props.question.questionValue &&
        this.props.question.questionValue.choiceValueMapping &&
        isNonEmptyArray(
          Object.keys(this.props.question.questionValue.choiceValueMapping)
        )) ||
      (this.props.question &&
        (this.props.question.type === "Number" ||
          this.props.question.type === "OpinionScale"))
    );
  };

  _renderQuestionValueButton = () => {
    if (this._hasQuestionValue()) {
      return (
        <UICoreBox onClick={_ => this.setState({ showModal: true })}>
          <UICoreButton key={0} color="blue" size="sm">
            {" Update "}
          </UICoreButton>
        </UICoreBox>
      );
    } else {
      return (
        <UICoreBox onClick={_ => this.setState({ showModal: true })}>
          <UICoreButton key={1} color="lightBlue" size="sm">
            {" Add "}
          </UICoreButton>
        </UICoreBox>
      );
    }
  };

  renderModalContentForChoiceQuestions(choiceContent) {
    return (
      <UICoreBox paddingLeft="sm" paddingRight="sm" paddingBottom="sm">
        <UICoreBox paddingTop="sm">
          <UICoreText size="xs">
            Set a value for this question based on your user's choice so that it
            can be used for formula based calculation at the end of your form
            (result page)
          </UICoreText>
        </UICoreBox>
        <UICoreBox>
          {nonnullArray(choiceContent).map(content => {
            // $FlowFixMe
            const questionsConfigs = new formConfiguration().types;
            const questionConfig = questionsConfigs[this.props.question.type];
            if (
              //hide when rating is 0 (which is not possible)
              this.props.question &&
              this.props.question.type === "Rating" &&
              content.display === "None"
            ) {
              return;
            } else {
              return this._renderChoiceValueMap(content.display, content.value);
            }
          })}
        </UICoreBox>
      </UICoreBox>
    );
  }

  renderModalContentForNumberQuestion(choiceContent) {
    return (
      <UICoreBox paddingLeft="sm" paddingRight="sm" paddingBottom="sm">
        <UICoreBox paddingTop="sm">
          <UICoreText size="xs">
            This question will default to using user's input as the value for
            formula based calculation.{" "}
          </UICoreText>
        </UICoreBox>

        <UICoreBox
          verticalPadding="xm"
          horizontalPadding="xm"
          shape="rounded"
          borderColor="#ECECEC"
          marginTop="sm"
        >
          <UICoreText color="darkGray" size="xs" weight="bold">
            Default question value is
          </UICoreText>
          <UICoreBox shape="rounded" color="lightGrey" marginTop="xm">
            <UICoreBox horizontalPadding="sm" verticalMargin="xm">
              <UICoreText>User Input</UICoreText>
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }

  render() {
    // $FlowFixMe
    const questionsConfigs = new formConfiguration().types;
    const questionConfig = questionsConfigs[this.props.question.type];
    const choiceContent =
      questionConfig.getAnswerChoices &&
      questionConfig.getAnswerChoices(this.props.question);
    return [
      this._renderQuestionValueButton(),
      this.state.showModal && (
        <UICoreModal
          size="md"
          dismissText="Save"
          onDismiss={_ => {
            this.props.saveToBackend();
            this.setState({ showModal: false });
          }}
          header="Calculator Value"
          body={
            <Scrollbars autoHeight autoHeightMax="90vh">
              {this.props.question.type === "Number" ||
              this.props.question.type === "OpinionScale"
                ? this.renderModalContentForNumberQuestion(choiceContent)
                : this.renderModalContentForChoiceQuestions(choiceContent)}
            </Scrollbars>
          }
        />
      )
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    outcomes: getOutcomes(state.Question),
    outcomePageConfig: getOutcomePageContentConfig(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveToBackend: () => {
      dispatch(saveToBackend());
    },
    updateQuestionValue: (
      questionID: questionIDType,
      updatedQuestionValue: TquestionValue
    ) => {
      dispatch(update_question_value(questionID, updatedQuestionValue));
    },
    updateOutcomePage: (pageConfig: TGenericContentConfig) => {
      dispatch(update_outcome_page(pageConfig));
    },
    set_current_editing_question: pageNumber =>
      dispatch(set_current_editing_question(pageNumber))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionValue);
