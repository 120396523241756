import React, { Component } from "react";
import PropTypes from "prop-types";
import WithStyles from "../../../Helper_HOC/WithStyles";
import TextareaAutosize from "react-autosize-textarea";
import "./style.css";

/*
  props:
    buttonContent : string
*/

class Button extends Component {
  choiceNode: ?HTMLDivElement;

  getButtonMouseOverColor() {
    if (this.props.styles && this.props.styles.buttonColor) {
      return this.props.styles.buttonColor;
    } else {
      return this.props.answerPrimaryColor;
    }
  }

  render() {
    if (this.props.disable) {
      return (
        <div
          style={{
            color: this.props.buttonTextColor,
            borderColor: this.props.buttonBorderColor,
            backgroundColor: this.props.buttonPrimaryColor,
            fontFamily: this.props.styles && this.props.styles.fontFamily,
            borderWidth: "1px"
          }}
          className={"UI_Helper_Components-Button " + this.props.className}
          ref={e => (this.choiceNode = e)}
        >
          {this.props.buttonContent}
        </div>
      );
    } else if (this.props.editable) {
      return (
        <div
          style={{
            color: this.props.buttonTextColor,
            borderColor: this.props.buttonBorderColor,
            backgroundColor: this.props.buttonPrimaryColor,
            borderWidth: "1px",
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
          onMouseOver={e =>
            this.choiceNode &&
            (this.choiceNode.style.backgroundColor = this.getButtonMouseOverColor())
          }
          onMouseLeave={e =>
            this.choiceNode &&
            (this.choiceNode.style.backgroundColor = this.props.buttonPrimaryColor)
          }
          ref={e => (this.choiceNode = e)}
          className={"UI_Helper_Components-Button " + this.props.className}
          ref={e => (this.choiceNode = e)}
        >
          <TextareaAutosize
            onBlur={this.props.onButtonEditComplete}
            onChange={this.props.onChange}
            className="UI_Helper_Components-Button-textArea"
            value={this.props.buttonContent}
          />
        </div>
      );
    } else {
      return (
        <div
          style={{
            color: this.props.buttonTextColor,
            borderColor: this.props.buttonBorderColor,
            backgroundColor: this.props.buttonPrimaryColor,
            borderWidth: "1px",
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
          className={"UI_Helper_Components-Button " + this.props.className}
          onClick={this.props.onClick}
          onMouseOver={e =>
            this.choiceNode &&
            (this.choiceNode.style.backgroundColor = this.getButtonMouseOverColor())
          }
          onMouseLeave={e =>
            this.choiceNode &&
            (this.choiceNode.style.backgroundColor = this.props.buttonPrimaryColor)
          }
          ref={e => (this.choiceNode = e)}
        >
          {this.props.buttonContent}
        </div>
      );
    }
  }
}

Button.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  buttonContent: PropTypes.string.isRequired
};

export default WithStyles(Button);
