//@flow
import {
  type QuestionTypes,
  type AnswerType
} from "../../../Pages/QuestionTypes";
import QuestionType, { type questionType } from "../../../Pages/QuestionTypes";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import React from "react";
import "./style.css";
import PropTypes from "prop-types";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { UICoreBox, UICoreText } from "../../UICore";
import UICoreTagInput from "../../UICore/UICoreTagInput";
import { getAllQuestions } from "../../../Pages/FormBuilderPage/States/selectors";
import { getAllAnswers } from "../../../Pages/Form/States/Answers/selectors";
import {
  getTagInputOptions,
  getQuestionIdPipedAnswerMap
} from "../../../Library/AnswerPiping";
import {
  saveToBackend,
  addAnswerPipe
} from "../../../Pages/FormBuilderPage/States/actions";
import { connect } from "react-redux";
import { isNonEmptyString, isNullOrUndefined } from "../../../Library/Util";

//$FlowFixMe
const kZeroWidthNoBreakSpace = "\uFEFF";
const kNewLine = "\n";
const DEFAULT_FONT_COLOR = "#ffffff";

type Props = {
  allQuestions: Array<questionType>,
  questionNumberBase0: number,
  last_edited_question: number,
  className: string,
  description: string,
  styles: formStyleType,
  editable?: boolean,
  answers: Array<AnswerType>,
  answerSecondaryColor: string,
  update_question_description?: (string, number) => any,
  saveToBackend: () => any,
  addAnswerPipe: () => void
};

class UI_Description extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getFontColor() {
    if (this.props.styles && this.props.styles.text) {
      return this.props.styles.text;
    } else {
      return DEFAULT_FONT_COLOR;
    }
  }

  handleDescriptionInput = (newDescription: string) => {
    //TODO: @taoxiang disable enter key
    // const newTitle = e.currentTarget.value;
    // adjustHeight(e.currentTarget);
    this.props.update_question_description &&
      this.props.update_question_description(
        newDescription,
        this.props.last_edited_question
      );
  };

  _handleDropdownActionClick = () => {
    //This is actually a no-op
    //but to signal to the upsell system
    this.props.addAnswerPipe();
  };

  _renderQuestionDescription = () => {
    return (
      <UICoreTagInput.Render
        key={this.props.description}
        textStyleProps={{
          overflow: "wrap",
          size: "md",
          weight: "light",
          className:
            "UI_MultiChoices-mainContent-descriptionSection " +
            this.props.className,
          hexColor: this.getFontColor(),
          fontFamily: this.props.styles && this.props.styles.fontFamily,
          children: null
        }}
        tags={getQuestionIdPipedAnswerMap(this.props.answers)}
        input={this.props.description}
      />
    );
  };

  _renderQuestionDescriptionEditorWithAnswerPipe = () => {
    return (
      <UICoreTagInput.Editor
        key={this.props.last_edited_question}
        className={"UI_QuestionDescription-textarea " + this.props.className}
        contentEditableClassName="UI_QuestionDescription-textarea-editable"
        width="100%"
        textStyleProps={{
          weight: "light",
          children: null,
          size: "md",
          fontFamily: this.props.styles && this.props.styles.fontFamily,
          hexColor: this.getFontColor(),
          overflow: "wrap"
        }}
        tagTextStyle={{
          children: null,
          size: "xs",
          hexColor: this.getFontColor()
        }}
        tagHexColor={this.props.answerSecondaryColor}
        onInputUpdate={this.handleDescriptionInput}
        onInputBlur={() => this.props.saveToBackend()}
        onDropdownOptionClick={this._handleDropdownActionClick}
        input={this.props.description}
        tagOptionGroups={[
          {
            header: "Answer from Questions:",
            tags: getTagInputOptions(
              this.props.allQuestions,
              this.props.questionNumberBase0
            )
          }
        ]}
      />
    );
  };

  _isNonEmptyDescription = (description: ?string): boolean => {
    if (isNullOrUndefined(description) || !isNonEmptyString(description)) {
      return false;
    }
    return (
      String(description)
        .split("")
        .filter(char => char !== kZeroWidthNoBreakSpace && char !== kNewLine)
        .join("").length > 0
    );
  };

  render() {
    if (this.props.editable) {
      return (
        <UICoreBox position="relative">
          {!this._isNonEmptyDescription(this.props.description) && (
            <span
              style={{ color: this.getFontColor() }}
              className="Question-Description-Placeholder"
            >
              {"Description (Optional)"}
            </span>
          )}
          {this._renderQuestionDescriptionEditorWithAnswerPipe()}
          <span
            style={{ color: this.getFontColor() }}
            className="UI_QuestionDescription-Editor"
          >
            <i className="fa fa-pencil" />
          </span>
        </UICoreBox>
      );
    } else {
      return <UICoreBox>{this._renderQuestionDescription()}</UICoreBox>;
    }
  }
}

UI_Description.propTypes = {};

const mapStateToProps = state => {
  return {
    allQuestions: getAllQuestions(state.Question),
    answers: getAllAnswers(state.Answers)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addAnswerPipe: () => {
      dispatch(addAnswerPipe());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithStyles(UI_Description));
