//@flow
import * as React from "react";
import "./style.css";
import {
  type fileUploadCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import {
  UICoreInput,
  UICoreBox,
  UICoreText
} from "../../../../../Component/UICore";
import { getFileIcon } from "../../CellComponents/FileComponent";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: fileUploadCellType => mixed
|};
type State = {||};
class FileDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleFileClick = () => {
    if (this.props.value && this.props.value.link) {
      window.open(this.props.value.link);
    }
  };

  render() {
    const fileType =
      this.props.value && this.props.value.fileType
        ? this.props.value.fileType
        : null;
    const fileName = String(
      this.props.value && this.props.value.fileName
        ? this.props.value.fileName
        : ""
    );
    const fileUI = (
      <UICoreBox
        className="FileDataUI-File"
        width={150}
        shape="rounded"
        padding="sm"
        onClick={this._handleFileClick}
      >
        <UICoreBox
          height={80}
          className="FileDataUI-Icon"
          justifyContent="center"
          alignItems="center"
        >
          {getFileIcon(fileType)}
        </UICoreBox>
        <UICoreBox marginTop="md">
          <UICoreText size="xs">{fileName}</UICoreText>
        </UICoreBox>
      </UICoreBox>
    );

    const emptyUI = (
      <UICoreText size="xs" color="lightGray">
        No file uploaded
      </UICoreText>
    );
    return (
      <UICoreBox width="100%" direction="row" alignItems="start">
        {this.props.value ? fileUI : emptyUI}
      </UICoreBox>
    );
  }
}
export default FileDataUI;
