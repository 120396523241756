import React, { Component } from "react";

/*
    props:
    className {string}
    children {react component}

*/

class ModalContainer extends Component {
  constructor(props) {
    super(props);
    this.close = this.close.bind(this);
    this.container = null;
    this.state = {
      show_modal: false
    };
  }

  componentDidMount() {
    document.body.addEventListener("click", this.close);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.close);
  }

  open() {
    this.setState({
      show_modal: true
    });
  }

  close(e) {
    //click anywhere outside the modal container
    if (
      !e.target.closest(".ModalContainer") &&
      !e.target.closest(".UICoreImageUploader")
    ) {
      this.setState({
        show_modal: false
      });
    }
  }

  render() {
    return (
      <div className={"ModalContainer " + this.props.className}>
        {this.state.show_modal ? this.props.children : null}
      </div>
    );
  }
}

export default ModalContainer;
