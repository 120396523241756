// @flow

import {
  type formStyleType,
  type background,
  backgroundType
} from "../FlowTypes/wispformStyleTypes";
import { isNullOrUndefined } from "./Util";
import { isMobileScreen } from "./Window";

/*
We have updated the style data structure to accomodate backgground image.
Detail see here: https://www.notion.so/wispform2/Theme-9d298cce140346bdbf215cf1bfb28696
*/
export function getReformattedStyles(styles: formStyleType): ?formStyleType {
  if (!styles) {
    return null;
  }

  // is old data structure
  if (typeof styles.background === "string") {
    return {
      ...styles,
      background: {
        type: backgroundType.color,
        color: styles.background
      }
    };
  } else {
    // is new data structure
    return styles;
  }
}

export function getBackgroundImageWithAutoPlatformDetection(
  background: background
): string {
  if (isNullOrUndefined(background)) {
    return "";
  }
  if (isMobileScreen()) {
    return getMobileBackgroundImage(background);
  } else {
    return getDekstopBackgroundImage(background);
  }
}

export function getDekstopBackgroundImage(background: background): string {
  return background.imageDesktop || background.imageMobile || "";
}

export function getMobileBackgroundImage(background: background): string {
  return background.imageMobile || background.imageDesktop || "";
}
