//@flow
import AWS from "aws-sdk";
import { getAwsTemporaryCredential as getAwsTemporaryCredentialApi } from "../Library/Request";
import { AWS_REGION } from "../config";
import { logErrorInfo } from "../Helper_HOC/WithErrorBoundary";
import {
  isNumber,
  isNonEmptyString,
  getNonEmptyStringWithFallback
} from "./Util";
export type awsCredentialType = {|
  temporaryAccessKeyId: string,
  temporarySecretAccessKey: string,
  temporarySessionToken: string,
  serverTime: number
|};

export type uploadConfigType = {|
  optionalBucketKey?: string
|};

export const awsS3UrlPrefix =
  "https://wispform-file.s3.us-east-2.amazonaws.com/";

export function generateBucketKey(fileName: string) {
  const currentTime = new Date().getTime();
  if (!fileName) {
    return `${currentTime}`;
  }
  const fileNameArray = fileName.split(".");
  const fileNameWithOutExtention = fileNameArray
    .slice(0, fileNameArray.length - 1)
    .join("");
  const fileExtention = fileNameArray[fileNameArray.length - 1];
  return `${fileNameWithOutExtention}_${currentTime}.${fileExtention}`;
}

export function uploadFileToAWS(
  file: File,
  config: uploadConfigType,
  onUploadStart: () => void,
  onUploadProcessing: ?(AWS.S3.ManagedUpload.Progress) => void,
  onUploadSucceed: ?(AWS.S3.ManagedUpload.SendData) => void,
  onUploadError?: ?(Error) => void
) {
  getAwsTemporaryCredentialApi().then((credential: ?awsCredentialType) => {
    if (!credential) {
      return;
    }
    // Update client time if necessary
    const serverTime = credential.serverTime;
    const clientTime = new Date().getTime();
    if (isNumber(serverTime) && serverTime > 0) {
      AWS.config.systemClockOffset = serverTime - clientTime;
    }
    //initialize S3 with temporary token
    AWS.config.credentials = new AWS.Credentials(
      credential.temporaryAccessKeyId,
      credential.temporarySecretAccessKey,
      credential.temporarySessionToken
    );
    const s3 = new AWS.S3({ region: AWS_REGION });
    //upload file to S3
    onUploadStart && onUploadStart();
    const bucketKey = getNonEmptyStringWithFallback(
      config.optionalBucketKey,
      generateBucketKey(String(file.name))
    );
    //TODO: @taoxiang remove public access and handle this in node
    s3.upload(
      {
        Bucket: "wispform-file",
        Key: bucketKey,
        Body: file,
        ACL: "public-read"
      },
      (err, data) => {
        if (err) {
          onUploadError && onUploadError(err);
          console.log(err, err.stack);
          //log to table
          logErrorInfo(err, err.stack);
        } else {
          onUploadSucceed && onUploadSucceed(data);
          console.log(data);
        }
      }
    ).on("httpUploadProgress", event => {
      onUploadProcessing && onUploadProcessing(event);
    });
  });
}
