//@flow
import {
  MULTICHOICES,
  RATING,
  DROPDOWN,
  SHORTTEXT,
  EMAIL,
  LONGTEXT,
  STATEMENT,
  WELCOMEPAGE,
  FILEUPLOAD,
  YESNO,
  type QuestionTypes,
  type AnswerType
} from "../../../Pages/QuestionTypes";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import PropTypes from "prop-types";
import React from "react";
import TextareaAutosize from "react-autosize-textarea";

import "./style.css";
import {
  saveToBackend,
  addAnswerPipe
} from "../../../Pages/FormBuilderPage/States/actions";
import WithStyles from "../../../Helper_HOC/WithStyles";
import QuestionType, { type questionType } from "../../../Pages/QuestionTypes";
import { UICoreText, UICoreBox } from "../../../Component/UICore";
import { connect } from "react-redux";
import {
  hasWelcomePage as hasWelcomePageInBuilder,
  getAllQuestions
} from "../../../Pages/FormBuilderPage/States/selectors";
import {
  hasWelcomePage as hasWelcomePageInForm,
  getAllAnswers
} from "../../../Pages/Form/States/Answers/selectors";
import withConfigurableButtonTextHOC, {
  type InjectedProps as InjectedPropsFromConfigurableButtonTextHOC,
  ButtonTypeFromHOC
} from "../../../Helper_HOC/WithConfigurableButtonText";
import { shouldUseNormalWeightTitle } from "../../../Library/GateKeeper/formViewerGateKeeper";
import UICoreTagInput from "../../UICore/UICoreTagInput";
import {
  getTagInputOptions,
  getQuestionIdPipedAnswerMap
} from "../../../Library/AnswerPiping";
import { isNullOrUndefined } from "../../../Library/Util";

//$FlowFixMe
const QuestionTypeConfig = new QuestionType().types;

const DEFAULT_FONT_COLOR = "#ffffff";
const EMPTY_SPACES = "     ";
const NUM_HEADING_SPACES = 5;
const offset = 1;
const kZeroWidthNoBreakSpace = "\uFEFF";
const kNewLine = "\n";

type Props = {
  ...InjectedPropsFromConfigurableButtonTextHOC,
  className: string,
  editable?: boolean,
  answerSecondaryColor: string,
  last_edited_question: number,
  questionNumberBase0: number,
  QuestionType: QuestionTypes,
  allQuestions: Array<questionType>,
  answerPrimaryColor: string,
  styles: formStyleType,
  hasWelcomePageInBuilder: ?boolean,
  hasWelcomePageInForm: ?boolean,
  isInBuilder?: boolean,
  saveToBackend: () => any,
  titleContent: string,
  answers: Array<AnswerType>,
  update_question_title?: (string, number) => any,
  addAnswerPipe: () => void
};

function adjustHeight(el) {
  const minHeight = el.scrollHeight;
  // compute the height difference which is caused by border and outline
  var outerHeight = parseInt(window.getComputedStyle(el).height, 10);
  var diff = outerHeight - el.clientHeight;

  // set the height to 0 in case of it has to be shrinked
  el.style.height = 0;

  // set the correct height
  // el.scrollHeight is the full height of the content, not just the visible part
  el.style.height = Math.max(minHeight, el.scrollHeight + diff) + "px";
}

function focusAndSetCursorToEnd(element) {
  const textLength = element.value && element.value.length;
  element.focus && element.focus();
  element.setSelectionRange &&
    element.setSelectionRange(element.value.length, element.value.length);
}

class UI_QuestionTitle extends React.Component<Props> {
  textarea: HTMLTextAreaElement;

  componentDidMount() {
    this.textarea && focusAndSetCursorToEnd(this.textarea);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.last_edited_question !== this.props.last_edited_question) {
      this.textarea && focusAndSetCursorToEnd(this.textarea);
    }
  }

  renderQuestionTypeIcon() {
    return (
      <UICoreBox paddingRight="xxm">
        <i
          style={{ color: this.props.answerPrimaryColor, fontSize: "18px" }}
          /* eslint-disable */
          className={`fa ${
            QuestionTypeConfig[this.props.QuestionType] &&
            QuestionTypeConfig[this.props.QuestionType].iconClassName
          } UI_MultiChoices-mainContent-titleSection-Icon `}
          /* eslint-enable */
          aria-hidden="true"
        />
      </UICoreBox>
    );
  }

  getFontColor() {
    if (this.props.styles && this.props.styles.text) {
      return this.props.styles.text;
    } else {
      return DEFAULT_FONT_COLOR;
    }
  }

  handleTitleInput = (newTitle: string) => {
    //TODO: @taoxiang disable enter key
    // const newTitle = e.currentTarget.value;
    // adjustHeight(e.currentTarget);
    this.props.update_question_title &&
      this.props.update_question_title(
        newTitle,
        this.props.last_edited_question
      );
  };

  handleClassicTitleInput = (e: SyntheticKeyboardEvent<any>) => {
    //TODO: @taoxiang disable enter key
    const newTitle = e.currentTarget.value;
    adjustHeight(e.currentTarget);
    this.props.update_question_title &&
      this.props.update_question_title(
        newTitle,
        this.props.last_edited_question
      );
  };

  _getQuestionNumber() {
    const hasWelcomePage = this.props.isInBuilder
      ? this.props.hasWelcomePageInBuilder
      : this.props.hasWelcomePageInForm;

    if (hasWelcomePage) {
      return this.props.questionNumberBase0;
    } else {
      return this.props.questionNumberBase0 + offset;
    }
  }

  _renderQuestionNumber() {
    const questionNumberText =
      this.props.buttonTextConfig[ButtonTypeFromHOC.questionNumberText];

    if (this._isWelcomePage()) {
      return null;
    }

    if (questionNumberText === "") {
      return null;
    }

    return (
      <UICoreBox direction="row" alignItems="center" paddingBottom="xm">
        {this.renderQuestionTypeIcon()}
        <UICoreText
          weight="light"
          size="md"
          hexColor={this.props.answerPrimaryColor}
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {`${questionNumberText} ${this._getQuestionNumber()}`}
        </UICoreText>
      </UICoreBox>
    );
  }

  _renderQuestionTitle() {
    return (
      <UICoreTagInput.Render
        key={this.props.titleContent}
        textStyleProps={{
          parseLink: true,
          overflow: "wrap",
          size: "xl",
          weight: shouldUseNormalWeightTitle() ? "normal" : "medium",
          className:
            "UI_MultiChoices-mainContent-titleSection " + this.props.className,
          hexColor: this.getFontColor(),
          fontFamily: this.props.styles && this.props.styles.fontFamily,
          children: null
        }}
        tags={getQuestionIdPipedAnswerMap(this.props.answers)}
        input={this.props.titleContent}
      />
    );
  }

  _isWelcomePage = question => {
    if (this.props.QuestionType === "WelcomePage") {
      return true;
    }
    return false;
  };

  _isNonEmptyTitle = (title: ?string): boolean => {
    if (isNullOrUndefined(title)) {
      return false;
    }
    return (
      String(title)
        .split("")
        .filter(char => char !== kZeroWidthNoBreakSpace && char !== kNewLine)
        .join("").length > 0
    );
  };

  _handleDropdownActionClick = () => {
    //This is actually a no-op
    //but to signal to the upsell system
    this.props.addAnswerPipe();
  };

  _renderQuestionTitleEditorWithAnswerPipe = () => {
    return (
      <UICoreTagInput.Editor
        key={this.props.last_edited_question}
        className={"UI_QuestionTitle-textarea " + this.props.className}
        contentEditableClassName="UI_QuestionTitle-textarea-editable"
        width="100%"
        textStyleProps={{
          weight: "medium",
          children: null,
          size: "xl",
          fontFamily: this.props.styles && this.props.styles.fontFamily,
          hexColor: this.getFontColor()
        }}
        tagTextStyle={{
          children: null,
          size: "sm",
          hexColor: this.getFontColor()
        }}
        tagHexColor={this.props.answerSecondaryColor}
        onInputUpdate={this.handleTitleInput}
        onInputBlur={() => this.props.saveToBackend()}
        onDropdownOptionClick={this._handleDropdownActionClick}
        input={this.props.titleContent}
        tagOptionGroups={[
          {
            header: "Answer from Questions:",
            tags: getTagInputOptions(
              this.props.allQuestions,
              this.props.questionNumberBase0
            )
          }
        ]}
      />
    );
  };

  render() {
    if (this.props.editable) {
      return (
        <UICoreBox position="relative">
          {this._renderQuestionNumber()}
          {!this._isNonEmptyTitle(this.props.titleContent) && (
            <span
              style={{ color: this.getFontColor() }}
              className="UI_MultiChoices-Placeholder"
            >
              {QuestionTypeConfig[this.props.QuestionType] &&
                QuestionTypeConfig[this.props.QuestionType].titlePlaceHolder}
            </span>
          )}
          {this._renderQuestionTitleEditorWithAnswerPipe()}

          <span
            style={{ color: this.getFontColor() }}
            className="UI_MultiChoices-Editor"
          >
            <i className="fa fa-pencil" />
          </span>
        </UICoreBox>
      );
    } else {
      return (
        <UICoreBox>
          {this._renderQuestionNumber()}
          {this._renderQuestionTitle()}
        </UICoreBox>
      );
    }
  }
}

UI_QuestionTitle.propTypes = {
  titleContent: PropTypes.string.isRequired,
  QuestionType: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    allQuestions: getAllQuestions(state.Question),
    hasWelcomePageInBuilder: hasWelcomePageInBuilder(state.Question),
    hasWelcomePageInForm: hasWelcomePageInForm(state.Answers),
    answers: getAllAnswers(state.Answers)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addAnswerPipe: () => {
      dispatch(addAnswerPipe());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withConfigurableButtonTextHOC(WithStyles(UI_QuestionTitle)));
