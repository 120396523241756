//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "../../../../../Component/UICore";
import TextareaAutosize from "react-autosize-textarea";
import {
  type textCellType,
  type columnIDType,
  type DataComponentPropsType
} from "../../../Configuration";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: textCellType => mixed
|};
type State = {||};
class LongTextDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox width="100%">
        <TextareaAutosize className="LongTextDataUI" value={this.props.value} />
      </UICoreBox>
    );
  }
}
export default LongTextDataUI;

{
  /* <TextareaAutosize
            innerRef={ref => (this.textarea = ref)}
            style={{
              color: this.getFontColor(),
              borderBottomColor: this.getFontColor()
            }}
            className={"UI_QuestionTitle-textarea " + this.props.className}
            value={EMPTY_SPACES + this.props.titleContent}
            onInput={this.handleTitleInput}
            onBlur={this.props.saveToBackend}
          /> */
}
