//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "../../../../../Component/UICore";
import TextareaAutosize from "react-autosize-textarea";
import {
  type textCellType,
  type columnIDType,
  type DataComponentPropsType
} from "../../../Configuration";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "../../CellComponents/CodeBlockComponent/prism.css";

type Props = {|
  ...DataComponentPropsType,
  updateCell?: textCellType => mixed
|};
type State = {||};
class CodeBlockDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox shape="rounded" className="CodeBlockDataUI" width="100%">
        <Editor
          placeholder="Type your code here..."
          value={String(this.props.value)}
          onValueChange={() => {}}
          highlight={code =>
            Prism.highlight(code, Prism.languages.javascript, "javascript")
          }
          padding={10}
          style={{
            fontFamily: '"Fira code", "Fira Mono", monospace',
            fontSize: 14
          }}
        />
      </UICoreBox>
    );
  }
}
export default CodeBlockDataUI;
