//@flow
import * as React from "react";
import "./style.css";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "./prism.css";
import { UICoreBox, UICoreButton } from "../../UICore";
import { type QuestionTypes } from "../../../Pages/QuestionTypes";
import ClickFlash from "../../Animations/ClickFlash";

type Props = {|
  onQuestionUpdate: string => void,
  onQuestionComplete: string => void,
  answer: {|
    answer: string,
    question_id: string,
    title: string,
    type: QuestionTypes
  |},
  config: {},
  contents: Array<string>,
  isCurrentQuestion: boolean,
  isInBuilder: boolean
|};
type State = {|
  code: string
|};

class UI_CodeBlock extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      code: ""
    };
  }

  _getValue = () => {
    if (this.props.answer && this.props.answer.answer) {
      return this.props.answer.answer;
    } else {
      return "";
    }
  };

  _onUserInput = (value: string) => {
    this.props.onQuestionUpdate && this.props.onQuestionUpdate(value);
  };

  _hasCode = () => {
    return this._getValue() !== "";
  };

  _onSubmitButtonClick = () => {
    this.props.onQuestionComplete &&
      this.props.onQuestionComplete(this._getValue());
  };

  render() {
    if (this.props.isInBuilder) {
      return (
        <UICoreBox minHeight="250px" className="UI_CodeBlock" shape="rounded">
          <Editor
            disabled
            placeholder="Type your code here..."
            value={this.state.code}
            onValueChange={value => this.setState({ code: value })}
            highlight={code =>
              Prism.highlight(code, Prism.languages.javascript, "javascript")
            }
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14
            }}
          />
        </UICoreBox>
      );
    } else {
      //in form page
      return (
        <UICoreBox
          minHeight="250px"
          position="relative"
          className="UI_CodeBlock"
          shape="rounded"
          justifyContent="between"
        >
          <Editor
            placeholder="Type your code here..."
            value={this._getValue()}
            onValueChange={this._onUserInput.bind(this)}
            highlight={code =>
              Prism.highlight(code, Prism.languages.javascript, "javascript")
            }
            onKeyDown={e => {
              //tab would switch to next question
              //shouldnt happen in editor
              e.stopPropagation();
            }}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14
            }}
          />
          {this._hasCode() && (
            <UICoreBox width="100%" alignItems="end">
              <UICoreBox padding="xm" right="0px" direction="row">
                <ClickFlash onClick={this._onSubmitButtonClick}>
                  <UICoreButton size="sm" color="blue">
                    Submit
                  </UICoreButton>
                </ClickFlash>
              </UICoreBox>
            </UICoreBox>
          )}
        </UICoreBox>
      );
    }
  }
}
export default UI_CodeBlock;
