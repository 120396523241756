//@flow

/**
 *
 * withTableData HOC provides rows and columns of a table as props
 * it also provides functions to update rows and columns of that table
 *
 * table data fetching needs to be triggered by calling getTableData()
 */

import * as React from "react";
import {
  type tableSchemaColumnType,
  type tableDataRowType,
  type tableIDType
} from "../../../Configuration";
import { getTable as apiGetTable, type apiTableReturnType } from "../../../api";
import "./style.css";

type State = {|
  columns: Array<tableSchemaColumnType>,
  rows: Array<tableDataRowType>
|};

export type InjectedProps = {|
  columns: Array<tableSchemaColumnType> | void,
  rows: Array<tableDataRowType> | void,
  getTableData: (tableIDType => void) | void
|};

export default function withTableData<PassedProps: {}>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    constructor(props: PassedProps) {
      super(props);
    }

    state = {
      columns: [],
      rows: []
    };

    _getTableData = (tableID: tableIDType) => {
      apiGetTable(tableID).then((tableData: apiTableReturnType) => {
        if (!tableData.table_detail) return;
        this.setState({
          columns: tableData.table_detail.columns,
          rows: tableData.rows
        });
      });
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          columns={this.state.columns}
          rows={this.state.rows}
          getTableData={this._getTableData}
        />
      );
    }
  };
}
