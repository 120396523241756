import {
  SORT_RESPONSES,
  FILTER_RESPONSES,
  DID_FETCH_QUESTIONS,
  DID_FETCH_RESPONSES,
  DID_FETCH_EXPORTS,
  DID_FETCH_RESPONSE_META_DATA,
  DID_FETCH_INSIGHT,
  DID_DELETE_SELECTED_RESPONSES,
  SET_HIGHLIGHTED_RESPONSES,
  WILL_FETCH_RESPONSES,
  WILL_FETCH_VIEW_RESPONSE_GRAPH_DATA,
  WILL_FETCH_INSIGHT,
  CLEAR_RESPONSES,
  DID_FETCH_VIEW_RESPONSE_GRAPH_DATA
} from "./actions";
import update from "react-addons-update";
import { toArray } from "../../../Library/Util";

let defaultState = {
  questions: [{}],
  immutable_responses: [{}],
  responses: [{}],
  exports: [{}],
  pages: {},
  responseMetaData: [{}],
  raw_responses: [],
  raw_questions: [],
  view_response_graph_data: {},
  insight: {},
  fetching: false
};

export default function ResultPage(state = defaultState, action) {
  switch (action.type) {
    case SORT_RESPONSES:
      return update(state, {
        responses: {
          $set: state.responses.slice().sort((a, b) => {
            let response1 = "",
              response2 = "";
            if (action.question_id) {
              response1 = a[action.question_id] && a[action.question_id].answer;
              response2 = a[action.question_id] && b[action.question_id].answer;
            } else {
              response1 = a["created_at"];
              response2 = b["created_at"];
            }
            if (action.direction === "up") {
              if (response1 > response2 || response2 === undefined) {
                return 1;
              } else {
                return -1;
              }
            } else {
              if (response1 < response2 || response1 === undefined) {
                return 1;
              } else {
                return -1;
              }
            }
          })
        }
      });

    case FILTER_RESPONSES:
      return update(state, {
        responses: {
          $set: state.immutable_responses.filter(function (response, index) {
            for (let a = 0; a < action.filter_parameter.length; a++) {
              if (response[action.filter_parameter[a].question]) {
                if (
                  !response[action.filter_parameter[a].question].answer
                    .toString()
                    .includes(action.filter_parameter[a].keyword) &&
                  !response[action.filter_parameter[a].question].answer
                    .toString()
                    .toLowerCase()
                    .includes(action.filter_parameter[a].keyword)
                ) {
                  return false;
                }
              } else {
                if (action.filter_parameter[a].question != "") {
                  return false;
                }
              }
            }
            return true;
          })
        }
      });
      break;

    case CLEAR_RESPONSES:
      return {
        ...state,
        // Reset responses to prevent overload the grid view
        raw_responses: [],
        responses: [],
        immutable_responses: []
      };

    case WILL_FETCH_RESPONSES:
      return {
        ...state,
        fetching: true
      };
      return update(state, {
        fetching: { $set: true }
      });

    case WILL_FETCH_VIEW_RESPONSE_GRAPH_DATA:
      return {
        ...state,
        fetching: true
      };

    case WILL_FETCH_INSIGHT:
      return {
        ...state,
        fetching: true
      };

    case DID_FETCH_QUESTIONS:
      let raw_questions = [];
      let questions = [{ question: "" }];
      if (action.questions != null) {
        questions = action.questions.map(function (element) {
          return {
            question: element.title,
            width: element.resultPageWidth,
            question_id: element.question_id,
            type: element.type,
            contents: element.contents
          };
        });
        raw_questions = action.questions;
      }

      return update(state, {
        raw_questions: { $set: raw_questions },
        questions: { $set: questions }
      });
      break;

    case DID_FETCH_RESPONSES:
      let responses = action.responses.map(function (form_response) {
        let processed_form_response = {};
        form_response.response_detail.forEach(function (question_response) {
          processed_form_response[question_response.question_id] = {
            type: question_response.type,
            answer: question_response.answer
          };
        });
        processed_form_response["id"] = form_response.id;
        processed_form_response["created_at"] = new Date(
          form_response.created_at
        ).toLocaleString();
        if (
          form_response["variables"] &&
          form_response["variables"]["score"] &&
          form_response["variables"]["score"] != "" &&
          form_response["variables"]["score"] != "0/0"
        ) {
          processed_form_response["score"] =
            form_response["variables"]["score"];
        } else {
          processed_form_response["score"] = "Not Configured";
        }
        return processed_form_response;
      });
      return update(state, {
        raw_responses: { $set: action.responses },
        responses: { $set: responses },
        immutable_responses: { $set: responses },
        pages: {
          $set: {
            next_page: action.next_page,
            current_page: action.current_page,
            total_pages: action.total_pages
          }
        },
        fetching: { $set: false }
      });
      break;

    case DID_FETCH_EXPORTS:
      return update(state, {
        exports: { $set: toArray(action.exports) }
      });

    case SET_HIGHLIGHTED_RESPONSES:
      var newResponseMetaData = state.responseMetaData;
      newResponseMetaData.highlighted_response = action.highlighted_response;
      return update(state, {
        responseMetaData: { $set: newResponseMetaData }
      });
      break;

    case DID_FETCH_RESPONSE_META_DATA:
      return update(state, {
        responseMetaData: { $set: action.responseMetaData }
      });
      break;

    case DID_FETCH_VIEW_RESPONSE_GRAPH_DATA:
      return update(state, {
        view_response_graph_data: {
          $set: {
            form_view_graph_data:
              action.viewResponseGraphData.form_view_graph_data
          }
        },
        fetching: {
          $set: false
        }
      });
      break;

    case DID_FETCH_INSIGHT:
      return update(state, {
        insight: {
          $set: {
            dropoff_insight: action.insight.dropoff_insight,
            view_count: action.insight.view_count,
            start_count: action.insight.start_count,
            response_count: action.insight.response_count,
            engagement_count: action.insight.engagement_count,
            question_view_funnel: action.insight.question_view_funnel
          }
        },
        fetching: {
          $set: false
        }
      });
      break;

    case DID_DELETE_SELECTED_RESPONSES:
      return update(state, {
        responses: {
          $set: state.responses.filter(function (response, index) {
            return !action.deletedResponses.includes(response.id.toString());
          })
        }
      });
      break;

    default:
      return state;
      break;
  }
}
