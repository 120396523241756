//@flow
import * as React from "react";
import { delayOneCycle } from "../../../Library/Util";
import { UICoreFlyOut, UICoreText, UICoreBox } from "../index";
import "./style.css";
type Props = {|
  children: React.Node,
  text: string,
  size?: number,
  relativeTop?: number,
  relativeLeft?: number
|};
type State = {|
  wrapper: ?HTMLElement
|};

function _tooltipUI(props: Props, anchor: ?HTMLElement) {
  return (
    <UICoreFlyOut
      size={props.size}
      color="darkGray"
      forceDirection={true}
      idealDirection="down"
      onDismiss={() => {}}
      anchor={anchor}
      showCaret={false}
      relativeTop={props.relativeTop}
      relativeLeft={props.relativeLeft}
    >
      <UICoreBox
        justifyContent="center"
        alignItems="center"
        width="100%"
        paddingLeft="xm"
        paddingRight="xm"
        paddingTop="xxm"
        paddingBottom="xxm"
      >
        <UICoreText overflow="wrap" color="white" size="xs">
          {props.text}
        </UICoreText>
      </UICoreBox>
    </UICoreFlyOut>
  );
}
class WrapAround extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    wrapper: null
  };

  render() {
    return (
      <div
        className="UICoreTooltip"
        ref={e => {
          !this.state.wrapper && this.setState({ wrapper: e });
        }}
      >
        {this.props.children}
        {this.state.wrapper && (
          <div className="UICoreTooltip-flyout">
            {_tooltipUI(this.props, this.state.wrapper)}
          </div>
        )}
      </div>
    );
  }
}

class QuestionMark extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    wrapper: null
  };

  render() {
    return (
      <div className="UICoreTooltip">
        <UICoreBox
          justifyContent="center"
          alignItems="center"
          color="lightGrey"
          shape="circle"
          height="15px"
          width="15px"
          hoverable={true}
          innerRef={ref => {
            delayOneCycle(_ => {
              if (!this.state.wrapper) {
                this.setState({ wrapper: ref });
              }
            });
          }}
        >
          <UICoreText dangerous_style={{ fontSize: "10px" }}>?</UICoreText>
        </UICoreBox>
        {this.state.wrapper && (
          <div className="UICoreTooltip-flyout">
            {_tooltipUI(this.props, this.state.wrapper)}
          </div>
        )}
      </div>
    );
  }
}

export default {
  WrapAround: WrapAround,
  QuestionMark: QuestionMark
};
