//@flow
import Form from "../../Library/Forms";
import { IFormConfig } from "../../Library/Forms/FormConfigs/IFormConfig";
import { UPDATE_FORM_CONFIG, type actionType } from "./actions";

type FormConfigState = {|
  formConfig: ?IFormConfig
|};

const defaultFormConfigState = {
  formConfig: null
};

export default function FormConfig(
  state: FormConfigState = defaultFormConfigState,
  action: actionType
) {
  switch (action.type) {
    case UPDATE_FORM_CONFIG:
      return {
        ...state,
        // init in  "../../Library/Forms"
        formConfig: Form.config
      };
    default:
      return state;
  }
}
