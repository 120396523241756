//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreBox,
  UICoreText,
  UICoreFlyOut,
  UICoreButton
} from "../../UICore";
import { Scrollbars } from "react-custom-scrollbars";
import UICoreList from "../UICoreList";
import {
  UICoreListMediaTypeEnum,
  UICoreListItemTypeEnum,
  type UICoreListMediaSpecType
} from "../../../FlowTypes/UICoreTypes/UICoreListType";
import { UICoreIconTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreIconType";

export type dropdownFooterButtonType = {|
  onClick: () => void,
  buttonText: string
|};

export type choiceType = {|
  type?: $Values<typeof UICoreListItemTypeEnum>,
  value: any,
  display: string,
  leftMediaSpec?: UICoreListMediaSpecType
|};
type Props = {|
  selectChoice: ?choiceType,
  placeholder?: string,
  width?: number | string,
  height?: number,
  dropdownMaxHeight?: number,
  dropdownWidth?: number,
  choices: Array<choiceType>,
  onChoiceClick?: any => void,
  dropdownFooterButton?: dropdownFooterButtonType,
  direction?: "up" | "down",
  disabled?: boolean
|};
type State = {|
  showDropdown: boolean
|};
class UICoreDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showDropdown: false
  };

  _dropdownHeader: ?HTMLElement;

  _getCurrentChoice = () =>
    this.props.selectChoice
      ? this.props.selectChoice.display
      : this.props.placeholder
      ? this.props.placeholder
      : "Select a choice";

  _renderDropdownHeaderContent = () => {
    if (!this.props.selectChoice) {
      return null;
    }
    if (typeof this.props.selectChoice.display === "string") {
      return <UICoreText>{this.props.selectChoice.display}</UICoreText>;
    } else return <UICoreBox>{this.props.selectChoice.display}</UICoreBox>;
  };

  _hasSelectedChoice = (): boolean => {
    return this.props.selectChoice != null;
  };

  _getwidth = () => (this.props.width ? this.props.width : 100);

  _getDropdownWidth = () =>
    this.props.dropdownWidth
      ? this.props.dropdownWidth
      : parseInt(this._getwidth());

  _getStyle = () => {
    return {
      width: this._getwidth()
    };
  };

  _closeDropdown = () => this.setState({ showDropdown: false });

  _showDropdown = () => this.setState({ showDropdown: true });

  _getDropdownDirection = () =>
    this.props.direction ? this.props.direction : "down";

  _renderButton = () => {
    return (
      <UICoreBox paddingLeft="xm" paddingTop="xm" paddingRight="xm">
        <UICoreButton
          onClick={() => {
            this.props.dropdownFooterButton &&
              this.props.dropdownFooterButton.onClick();
          }}
          color="lightBlue"
        >
          {(this.props.dropdownFooterButton &&
            this.props.dropdownFooterButton.buttonText) ||
            ""}
        </UICoreButton>
      </UICoreBox>
    );
  };

  _renderDropdown = () => {
    if (this.state.showDropdown) {
      return (
        <UICoreFlyOut
          size={this._getDropdownWidth()}
          idealDirection={this._getDropdownDirection()}
          showCaret={false}
          forceDirection={true}
          onDismiss={this._closeDropdown}
          anchor={this._dropdownHeader}
        >
          <UICoreBox
            width="100%"
            overflowX="hidden"
            paddingTop="xm"
            paddingBottom="xm"
          >
            <Scrollbars
              autoHeight
              autoHeightMax={
                this.props.dropdownMaxHeight
                  ? this.props.dropdownMaxHeight
                  : 200
              }
            >
              <UICoreList
                onItemClick={(_, choice) => {
                  this.props.onChoiceClick &&
                    this.props.onChoiceClick(choice.value);
                  this._closeDropdown();
                }}
                items={this.props.choices.map(choice => {
                  const isSelected =
                    this.props.selectChoice &&
                    choice.value === this.props.selectChoice.value;
                  return {
                    title: choice.display,
                    itemType: choice.type || UICoreListItemTypeEnum.stringItem,
                    extra: choice,
                    leftMediaSpec: choice.leftMediaSpec,
                    rightMediaSpec: isSelected
                      ? {
                          type: UICoreListMediaTypeEnum.icon,
                          src: UICoreIconTypeEnum.checkMark
                        }
                      : undefined
                  };
                })}
              />
            </Scrollbars>
            {this.props.dropdownFooterButton && this._renderButton()}
          </UICoreBox>
        </UICoreFlyOut>
      );
    }
  };

  render() {
    return (
      <UICoreBox
        disabled={this.props.disabled}
        height={this.props.height}
        width={this._getwidth()}
        dangerous_style={{
          opacity: this.props.disabled ? 0.4 : 1
        }}
      >
        <UICoreBox
          innerRef={e => {
            this._dropdownHeader = e;
          }}
          height="100%"
          alignItems="start"
          padding="xm"
          shape="rounded"
          color="lightGrey"
          position="relative"
          onClick={this._showDropdown}
          minHeight="36px"
          hoverable={true}
        >
          {this._hasSelectedChoice() ? (
            <UICoreText size="xs">{this._getCurrentChoice()}</UICoreText>
          ) : (
            <UICoreText color="Grey" size="xs">
              {this._getCurrentChoice()}
            </UICoreText>
          )}
          <UICoreBox className="UICoreDropdown-caret" position="absolute">
            <i className="fa fa-caret-down" />
          </UICoreBox>
        </UICoreBox>
        {this._renderDropdown()}
      </UICoreBox>
    );
  }
}
export default UICoreDropdown;
