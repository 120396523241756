//@flow
import * as React from "react";
import {
  type contactCellType,
  type contactIDType
} from "../../../Configuration";
import {
  getContacts as apiGetContacts,
  createContact as apiCreateContact,
  updateContact as apiUpdateContact
} from "../../../api";
type State = {|
  contacts: Array<contactCellType>
|};
type InjectedProps = {|
  getContacts: () => void,
  createContact: (string, string) => Promise<contactCellType>,
  updateContact: (contactIDType, string, string) => Promise<contactCellType>,
  contacts: Array<contactCellType>
|};
export default function withContacts<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    constructor(props) {
      super(props);
      this.state = {
        contacts: []
      };
    }
    _getContacts = () => {
      apiGetContacts()
        .then((contacts: Array<contactCellType>) =>
          this.setState({
            contacts: contacts
          })
        )
        .catch(e =>
          this.setState({
            contacts: []
          })
        );
    };

    _createContact = (name: string, email: string) => {
      return apiCreateContact(name, email);
    };

    _updateContact = (id: contactIDType, name: string, email: string) => {
      return apiUpdateContact(id, name, email);
    };

    render() {
      return (
        <WrappedComponent
          createContact={this._createContact}
          contacts={this.state.contacts}
          updateContact={this._updateContact}
          getContacts={this._getContacts}
          {...this.props}
        />
      );
    }
  };
}
