//@flow
import * as React from "react";
import "./style.css";
import {
  type multipleSelectCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import ChoiceComponent from "../../CellComponents/MultiselectComponent/ChoiceComponent";
import { UICoreBox } from "../../../../../Component/UICore";
import { getChoiceColor } from "../../CellComponents/MultiselectComponent";
import { toArray } from "../../../../../Library/Util";
type Props = {|
  ...DataComponentPropsType,
  updateCell?: multipleSelectCellType => mixed
|};
type State = {||};
class MultiselectDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getCurrentChoices = (): Array<string> => {
    let currentChoices = [];
    if (this.props.config && this.props.config.choices) {
      currentChoices = this.props.config.choices;
    }
    return toArray(currentChoices);
  };

  _renderChoices = () => {
    if (!Array.isArray(this.props.value)) {
      return null;
    }
    const choiceComponents = [];
    for (let choice of this.props.value) {
      if (typeof choice !== "string") {
        continue;
      }
      choiceComponents.push(
        <UICoreBox marginBottom="xm">
          <ChoiceComponent
            color={getChoiceColor(choice, this._getCurrentChoices())}
            text={choice}
          />
        </UICoreBox>
      );
    }
    return choiceComponents;
  };

  render() {
    return (
      <UICoreBox wrap="wrap" width="100%" direction="row" alignItems="start">
        {this._renderChoices()}
      </UICoreBox>
    );
  }
}
export default MultiselectDataUI;
