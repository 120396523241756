import { serverAddress } from "../../../config";
import uniqid from "uniqid";
import { isNumber } from "util";
import { type thankyouPageType } from "../../WispformTypings";
import { logNewUserOnboarding } from "../../../Library/Logger";
import { type logicJumpType } from "../../../Middlewares/LogicJumpMiddleware";
import { type questionID } from "../../QuestionTypes";
import { getRequest, patchRequest } from "../../../Library/Request";
import { isClassComponent } from "recompose";
import { type background } from "../../../FlowTypes/wispformStyleTypes";
import {
  safeGet,
  toArray,
  insertElementAt,
  replace,
  removeElemenent
} from "../../../Library/Util";
import { getUrlVars } from "../../../Library";
import { getHiddenFieldValues } from "./selectors";
import { type questionMediaType } from "../../../FlowTypes/questionTypes";
import { loadGoogleFont } from "../../../Library/GoogleFont";
import { setFormConfig } from "../../../Library/Forms";
import { FormTypeEnum } from "../../../FlowTypes/forms";
import { updateFormConfig } from "../../../State/FormConfig/actions";
import Form from "../../../Library/Forms";
import { type ToutcomeDisplayLogic } from "../../../FlowTypes/outcomeDisplayLogicTypes";
import {
  type thankyouPageCalculatorConfigType,
  type calculatorConditionType
} from "../../../FlowTypes/thankyouPageCalculatorConfigType";
import { type TquestionValue } from "../../../FlowTypes/questionValueTypes";

export const ADD_QUESTION_TYPE = "ADD_QUESTION_TYPE";
export const UPDATE_QUESTION_TITLE = "UPDATE_QUESTION_TITLE";
export const UPDATE_QUESTION_DESCRIPTION = "UPDATE_QUESTION_DESCRIPTION";
export const SET_CURRENT_EDITING_QUESTION = "SET_CURRENT_EDITING_QUESTION";
export const SET_CURRENT_EDITING_THANKYOU_PAGE =
  "SET_CURRENT_EDITING_THANKYOU_PAGE";

export const EDIT_CHOICE = "EDIT_CHOICE";
export const ADD_CHOICE = "ADD_CHOICE";
export const REMOVE_CHOICE = "REMOVE_CHOICE";
export const ADD_DROPDOWN_CHOICE = "ADD_DROPDOWN_CHOICE";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const WILL_SAVE_TO_BACKEND = "WILL_SAVE_TO_BACKEND";
export const DID_SAVE_TO_BACKEND = "DID_SAVE_TO_BACKEND";
export const WILL_FETCH_FORM_DETAIL = "WILL_FETCH_FORM_DETAIL";
export const DID_FETCH_FORM_DETAIL = "DID_FETCH_FORM_DETAIL";
export const SHOW_WARNING = "SHOW_WARNING";
export const HIDE_WARNING = "HIDE_WARNING";
export const Question_Changed = "Question_Changed";
export const UPDATE_FORM = "UPDATE_FORM";
export const UPDATE_QUESTION_CONFIG = "UPDATE_QUESTION_CONFIG";
export const UPDATE_QUESTION_IMAGE = "UPDATE_QUESTION_IMAGE";
export const UPDATE_QUESTION_MEDIA = "UPDATE_QUESTION_MEDIA";
export const UPDATE_THANKYOU_PAGE = "UPDATE_THANKYOU_PAGE";
export const UPDATE_STYLE = "UPDATE_STYLE";
export const UPDATE_STYLES = "UPDATE_STYLES";
export const UPDATE_STYLE_BACKGROUND = "UPDATE_STYLE_BACKGROUND";
export const REORDER_PREVIEW_WINDOW = "REORDER_PREVIEW_WINDOW";
export const REORDER_MULTICHOICE_CHOICE = "REORDER_MULTICHOICE_CHOICE";
export const COPY_QUESTION = "COPY_QUESTION";
export const COPY_THANKYOU_PAGE = "COPY_THANKYOU_PAGE";
export const ADD_THANKYOU_PAGE = "ADD_THANKYOU_PAGE";
export const DELETE_THANKYOU_PAGE = "DELETE_THANKYOU_PAGE";
export const UPDATE_JUMP_LOGIC = "UPDATE_JUMP_LOGIC";
export const UPDATE_CALCULATOR_CONDITION = "UPDATE_CALCULATOR_CONDITION";
export const UPDATE_THANKYOU_PAGE_DISPLAY_LOGIC =
  "UPDATE_THANKYOU_PAGE_DISPLAY_LOGIC";
export const UPDATE_DYNAMIC_PRICING = "UPDATE_DYNAMIC_PRICING";
export const UPDATE_SCORE_CALCULATION = "UPDATE_SCORE_CALCULATION";
export const ADD_REDIRECT_URL = "ADD_REDIRECT_URL";
export const UPDATE_HIDDEN_FIELD_VALUES = "UPDATE_HIDDEN_FIELD_VALUES";
export const ADD_GA_TRACKER_CODE = "ADD_GA_TRACKER_CODE";
export const ADD_GTM_TRACKER_CODE = "ADD_GTM_TRACKER_CODE";
export const UPDATE_BUTTON_TEXT = "UPDATE_BUTTON_TEXT";
export const SET_SCORE_VARIABLE = "SET_SCORE_VARIABLE";
export const TOGGLE_SET_SCORE_MODAL = "TOGGLE_SET_SCORE_MODAL";
export const UPDATE_QUESTION_CONTENT = "UPDATE_QUESTION_CONTENT";
export const ADD_LOGO = "ADD_LOGO";
export const UPDATE_CLOSE_FORM_SETTING = "UPDATE_CLOSE_FORM_SETTING";
export const UPDATE_HIDDEN_FIELDS = "UPDATE_HIDDEN_FIELDS";
export const ADD_ANSWER_PIPE = "ADD_ANSWER_PIPE";
export const UPDATE_OUTCOME_PAGE = "UPDATE_OUTCOME_PAGE";
export const UPDATE_OUTCOME_DISPLAY_LOGIC = "UPDATE_OUTCOME_DISPLAY_LOGIC";
export const UPDATE_THANKYOU_PAGE_CALCULATOR_CONFIG =
  "UPDATE_THANKYOU_PAGE_CALCULATOR_CONFIG";
export const UPDATE_OUTCOME_MAX_NUM = "UPDATE_OUTCOME_MAX_NUM";
export const UPDATE_QUESTION_VALUE = "UPDATE_QUESTION_VALUE";
export const RESET_THANKYOU_PAGES_SHOW_SCORE =
  "RESET_THANKYOU_PAGES_SHOW_SCORE";

// TODO: add flow type to the action file

type updateJumpLogicPayloadType = {|
  type: typeof UPDATE_JUMP_LOGIC,
  questionID: questionIDType,
  updatedJumpLogic: logicJumpType
|};

export function update_jump_logic(
  questionID: questionIDType,
  updatedJumpLogic: logicJumpType
) {
  return {
    type: UPDATE_JUMP_LOGIC,
    questionID: questionID,
    updatedJumpLogic: updatedJumpLogic
  };
}

export function update_calculator_condition(
  questionID: questionIDType,
  calculatorResultID: String,
  updatedCondition: Array<calculatorConditionType>
) {
  return {
    type: UPDATE_CALCULATOR_CONDITION,
    questionID: questionID,
    calculatorResultID: calculatorResultID,
    updatedCondition: updatedCondition
  };
}

export type updateOutcomeDisplayLogicPayloadType = {|
  type: typeof UPDATE_OUTCOME_DISPLAY_LOGIC,
  questionID: questionIDType,
  updatedOutcomeDisplayLogic: ToutcomeDisplayLogic
|};

export function update_outcome_display_logic(
  questionID: questionIDType,
  updatedOutcomeDisplayLogic: ToutcomeDisplayLogic
) {
  return {
    type: UPDATE_OUTCOME_DISPLAY_LOGIC,
    questionID: questionID,
    updatedOutcomeDisplayLogic: updatedOutcomeDisplayLogic
  };
}

export type updateThankYouPageCalculatorConfigPayloadType = {|
  type: typeof UPDATE_THANKYOU_PAGE_CALCULATOR_CONFIG,
  updatedThankYouPageCalculatorConfig: thankyouPageCalculatorConfigType
|};

export function update_calculator_config(
  updatedCalculatorConfig: thankyouPageCalculatorConfigType
) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_THANKYOU_PAGE_CALCULATOR_CONFIG,
      updatedCalculatorConfig: updatedCalculatorConfig
    });
    dispatch(saveToBackend());
  };
}

export function update_question_value(
  questionID: questionIDType,
  updatedQuestionValue: TquestionValue
) {
  return {
    type: UPDATE_QUESTION_VALUE,
    questionID: questionID,
    updatedQuestionValue: updatedQuestionValue
  };
}

export function update_outcome_max_num(maxNum: number) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_OUTCOME_MAX_NUM,
      maxNum: maxNum
    });
    dispatch(saveToBackend());
  };
}

export function update_thankyou_page_display_logic(
  updatedDisplayLogic: logicJumpType
) {
  return {
    type: UPDATE_THANKYOU_PAGE_DISPLAY_LOGIC,
    updatedDisplayLogic: updatedDisplayLogic
  };
}

type updateThankyouPageDisplayLogic = {|
  type: typeof UPDATE_DYNAMIC_PRICING,
  questionID: questionIDType,
  updatedLogic: logicJumpType
|};

export function updateDynamicPricing(
  questionID: questionIDType,
  updatedLogic: logicJumpType
) {
  return {
    type: UPDATE_DYNAMIC_PRICING,
    questionID: questionID,
    updatedLogic: updatedLogic
  };
}

export function setScore(hasScore: boolean) {
  return {
    type: SET_SCORE_VARIABLE,
    value: hasScore
  };
}

export function toggleSetScoreModal(show: boolean) {
  return {
    type: TOGGLE_SET_SCORE_MODAL,
    show
  };
}

type updateScoreCalculationPayloadType = {|
  type: typeof UPDATE_SCORE_CALCULATION,
  questionID: questionIDType,
  updatedJumpLogic: updatedScoreCalculation
|};

export function updateScoreCalculation(
  questionID: questionIDType,
  updatedScoreCalculation: questionScoreCalculationsType
) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_SCORE_CALCULATION,
      questionID: questionID,
      updatedScoreCalculation: updatedScoreCalculation
    });
    dispatch(saveToBackend());
  };
}

export function addLogo(url: string) {
  return function (dispatch, getState) {
    dispatch({
      type: ADD_LOGO,
      url: url
    });
    dispatch(saveToBackend());
  };
}

export function setCloseFormSetting(
  close_form_setting: string,
  custom_close_form_text: string
) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_CLOSE_FORM_SETTING,
      close_form_setting: close_form_setting,
      custom_close_form_text: custom_close_form_text
    });
    dispatch(saveToBackend());
  };
}

export function updateButtonText(updatedButtonText: buttonTextConfigType) {
  return {
    type: UPDATE_BUTTON_TEXT,
    updatedButtonText: updatedButtonText
  };
}

function _addHiddenFieldQuestion(
  questionID: string,
  initialHiddenFieldVariableName: string
) {
  return (dispatch, getState) => {
    dispatch({
      type: ADD_QUESTION_TYPE,
      question: {
        type: "HiddenField",
        question_id: questionID,
        title: initialHiddenFieldVariableName || ""
      },
      // Hidden question should always inserted at the end
      insertIndex: safeGet(_ => getState().Question.questions.length),
      disableUpdatingLastEditingQuestionIndex: true
    });
    dispatch(saveToBackend());
  };
}

function _getQuestionIndexByID(
  questionState: mixed,
  questionID: string
): number {
  const questions: ?Array<questionType> = safeGet(_ => questionState.questions);
  return toArray(questions).findIndex(
    question => question.question_id === questionID
  );
}

function _removeQuestionByID(
  questionID: string,
  shouldUpdateLastEditedIndex: boolean
) {
  return (dispatch, getState) => {
    const targetQuestionIndex = _getQuestionIndexByID(
      getState().Question,
      questionID
    );
    if (targetQuestionIndex >= 0) {
      dispatch(
        _deleteQuestionAndUpdateLastEditedIndex(
          targetQuestionIndex,
          shouldUpdateLastEditedIndex
        )
      );
    }
  };
}

export function addHiddenFields(
  initialHiddenFieldVariableName: ?string,
  index: number
) {
  return (dispatch, getState) => {
    const questionID = uniqid.time();
    const currentHiddenField: hiddenFieldVariablesType = toArray(
      safeGet(_ => getState().Question.configurations.hiddenFieldVariables)
    );

    // Update hidden fields config
    dispatch({
      type: UPDATE_HIDDEN_FIELDS,
      updatedHiddenFeilds: insertElementAt(
        currentHiddenField,
        {
          variableName: initialHiddenFieldVariableName || "",
          hiddenFieldQuestionID: questionID
        },
        index
      )
    });

    // Createe hidden field question
    dispatch(
      _addHiddenFieldQuestion(questionID, initialHiddenFieldVariableName)
    );
  };
}

export function removeHiddenFields(index: number) {
  return (dispatch, getState) => {
    const currentHiddenFields: hiddenFieldVariablesType = toArray(
      safeGet(_ => getState().Question.configurations.hiddenFieldVariables)
    );
    // update hidden field config
    dispatch({
      type: UPDATE_HIDDEN_FIELDS,
      updatedHiddenFeilds: removeElemenent(currentHiddenFields, index)
    });
    // remove hidden field question
    dispatch(
      _removeQuestionByID(
        safeGet(_ => currentHiddenFields[index].hiddenFieldQuestionID),
        false
      )
    );
    // clear hidden field value
    const hiddenFieldValues = getHiddenFieldValues(getState().Question);
    const variableName = safeGet(_ => currentHiddenFields[index].variableName);
    if (variableName && safeGet(_ => hiddenFieldValues[0][variableName])) {
      dispatch(
        updateHiddenFieldValues(
          replace(
            hiddenFieldValues,
            {
              ...hiddenFieldValues[0],
              [variableName]: null
            },
            0
          )
        )
      );
    }
  };
}

export function updateHiddenFields(
  updatedHiddenFieldVariableName: string,
  index: number
) {
  return (dispatch, getState) => {
    const currentHiddenFields: hiddenFieldVariablesType = toArray(
      safeGet(_ => getState().Question.configurations.hiddenFieldVariables)
    );
    // update hidden field config
    dispatch({
      type: UPDATE_HIDDEN_FIELDS,
      updatedHiddenFeilds: replace(
        currentHiddenFields,
        {
          ...currentHiddenFields[index],
          variableName: updatedHiddenFieldVariableName
        },
        index
      )
    });
    // update hidden field question
    const targetQuestionIndex = _getQuestionIndexByID(
      getState().Question,
      safeGet(_ => currentHiddenFields[index].hiddenFieldQuestionID)
    );
    if (targetQuestionIndex >= 0) {
      dispatch(
        update_question_title(
          updatedHiddenFieldVariableName,
          targetQuestionIndex
        )
      );
    }
  };
}

export function updateHiddenFieldValues(
  updatedHiddenFieldValues: hiddenFieldVariableValuesType
) {
  return {
    type: UPDATE_HIDDEN_FIELD_VALUES,
    updatedHiddenFieldValues
  };
}

export function addRedirectURL(url: string) {
  return function (dispatch, getState) {
    dispatch({
      type: ADD_REDIRECT_URL,
      url: url
    });
    dispatch(saveToBackend());
  };
}

export function addAnswerPipe() {
  return {
    type: ADD_ANSWER_PIPE
  };
}

export function addGoogleAnalyticsCode(code: string) {
  return function (dispatch, getState) {
    dispatch({
      type: ADD_GA_TRACKER_CODE,
      code: code
    });
    dispatch(saveToBackend());
  };
}

export function addGoogleTagManagerCode(code: string) {
  return function (dispatch, getState) {
    dispatch({
      type: ADD_GTM_TRACKER_CODE,
      code: code
    });
    dispatch(saveToBackend());
  };
}

type set_current_editing_question_type = {|
  type: typeof SET_CURRENT_EDITING_QUESTION,
  questionNumber: number
|};
export function set_current_editing_question(questionNumber) {
  return {
    type: SET_CURRENT_EDITING_QUESTION,
    questionNumber
  };
}

type set_current_editing_thankyou_page_type = {|
  type: typeof SET_CURRENT_EDITING_THANKYOU_PAGE,
  thankyouPageNumber: number
|};
export function set_current_editing_thankyou_page(thankyouPageNumber) {
  return {
    type: SET_CURRENT_EDITING_THANKYOU_PAGE,
    thankyouPageNumber
  };
}

type reorder_preview_window_type = {|
  type: typeof REORDER_PREVIEW_WINDOW,
  currentIndex: number,
  newIndex: number
|};
export function reorder_preview_window(currentIndex, newIndex) {
  return {
    type: REORDER_PREVIEW_WINDOW,
    currentIndex,
    newIndex
  };
}

export function copy_question(questionToCopy: number) {
  return (dispatch, getState) => {
    dispatch({
      type: COPY_QUESTION,
      questionToCopy: questionToCopy
    });
    dispatch(saveToBackend());
  };
}

export function copy_thankyou_page(thankyouPageToCopy: number) {
  return (dispatch, getState) => {
    dispatch({
      type: COPY_THANKYOU_PAGE,
      thankyouPageToCopy: thankyouPageToCopy
    });
    dispatch(saveToBackend());
  };
}

export function add_question_type(questionType, index) {
  logNewUserOnboarding("questionCreated");
  return function addQuestionType(dispatch, getState) {
    dispatch({
      type: ADD_QUESTION_TYPE,
      question: {
        index: index,
        type: questionType,
        question_id: uniqid.time()
      }
    });
    dispatch(saveToBackend());
  };
}

export function add_thankyou_page() {
  return function addThankyouPage(dispatch, getState) {
    dispatch({
      type: ADD_THANKYOU_PAGE
    });
    dispatch(saveToBackend());
  };
}

export function delete_thankyou_page(index: number) {
  return function (dispatch, getState) {
    dispatch({
      type: DELETE_THANKYOU_PAGE,
      index
    });
    dispatch(saveToBackend());
  };
}

export function update_question_title(title, pageNumber) {
  return {
    type: UPDATE_QUESTION_TITLE,
    title,
    pageNumber
  };
}

export function update_question_image(imageURL: string, pageNumber) {
  //$FlowFixMe
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_QUESTION_IMAGE,
      imageURL,
      pageNumber
    });
    dispatch(saveToBackend());
  };
}

export function update_question_media(
  media: ?questionMediaType,
  index: number
) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_QUESTION_MEDIA,
      media,
      index
    });
    dispatch(saveToBackend());
  };
}

export function update_outcome_page(pageConfig: TGenericContentConfig) {
  //$FlowFixMe
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_OUTCOME_PAGE,
      pageConfig
    });
    dispatch(saveToBackend());
  };
}

export function update_outcome_page_just_redux(
  pageConfig: TGenericContentConfig
) {
  //$FlowFixMe
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_OUTCOME_PAGE,
      pageConfig
    });
  };
}

export function reset_thankyou_pages_show_score() {
  return function (dispatch, getState) {
    dispatch({
      type: RESET_THANKYOU_PAGES_SHOW_SCORE
    });
    dispatch(saveToBackend());
  };
}

export function update_thankyou_page(pages: Array<thankyouPageType>) {
  //$FlowFixMe
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_THANKYOU_PAGE,
      pages
    });
    dispatch(saveToBackend());
  };
}

export function update_thankyou_page_just_redux(
  pages: Array<thankyouPageType>
) {
  //$FlowFixMe
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_THANKYOU_PAGE,
      pages
    });
  };
}

export function update_question_description(description, pageNumber) {
  return {
    type: UPDATE_QUESTION_DESCRIPTION,
    description,
    pageNumber
  };
}

export function update_question_config(config_name, value, page_number) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_QUESTION_CONFIG,
      config_name,
      value,
      page_number
    });
    dispatch(saveToBackend());
  };
}

export function edit_choice(Choice_Index, Choice_Content, pageNumber) {
  return {
    type: EDIT_CHOICE,
    Choice_Index,
    Choice_Content,
    pageNumber
  };
}

export function remove_choice(pageNumber, Choice_Index) {
  return {
    type: REMOVE_CHOICE,
    pageNumber,
    Choice_Index
  };
}

export function add_choice(pageNumber, index?: ?number) {
  return {
    type: ADD_CHOICE,
    choiceIndex: Number.isInteger(index) ? index : null,
    pageNumber
  };
}

export function updateQuestionContent(
  pageNumber,
  contents,
  shouldPersist?: boolean
) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_QUESTION_CONTENT,
      contents,
      pageNumber
    });
    if (shouldPersist) {
      dispatch(saveToBackend());
    }
  };
}

export function updateQuestionContentWithouPersistence(pageNumber, contents) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_QUESTION_CONTENT,
      contents,
      pageNumber
    });
  };
}

export function reorder_multichoice_choice(pageNumber, currentIndex, newIndex) {
  return {
    type: REORDER_MULTICHOICE_CHOICE,
    pageNumber,
    currentIndex,
    newIndex
  };
}

export function add_dropdown_choice(pageNumber, choice_content) {
  return {
    type: ADD_DROPDOWN_CHOICE,
    pageNumber,
    choice_content
  };
}

export function delete_question(pageNumber) {
  return _deleteQuestionAndUpdateLastEditedIndex(pageNumber, true);
}

function _deleteQuestionAndUpdateLastEditedIndex(
  pageNumber,
  shouldUpdateLastEditedIndex
) {
  return function (dispatch, getState) {
    dispatch({
      type: DELETE_QUESTION,
      shouldUpdateLastEditedIndex,
      pageNumber
    });

    dispatch(saveToBackend());
  };
}

export function update_form(json) {
  return {
    type: update_form,
    json
  };
}

export function willFetchFormDetail() {
  return {
    type: WILL_FETCH_FORM_DETAIL
  };
}

export function didFetchFormDetail(form, form_name, responseCount) {
  return {
    type: DID_FETCH_FORM_DETAIL,
    form,
    form_name,
    responseCount
  };
}

export function willSaveToBackend() {
  return {
    type: WILL_SAVE_TO_BACKEND
  };
}

export function didSaveToBackend() {
  return {
    type: DID_SAVE_TO_BACKEND
  };
}

export function showWarning() {
  return {
    type: SHOW_WARNING
  };
}

export function hideWarning() {
  return {
    type: HIDE_WARNING
  };
}

export function update_style(style, value) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_STYLE,
      style,
      value
    });

    dispatch(saveToBackend());
  };
}

export function update_styles(styles) {
  return function (dispatch, getState) {
    if (styles && styles.fontFamily) {
      // theme font might not be loaded to the client.
      // manually load it
      loadGoogleFont(safeGet(_ => styles.fontFamily));
    }
    dispatch({
      type: UPDATE_STYLES,
      styles
    });
    dispatch(saveToBackend());
  };
}

export function updateStyleBackground(background: background) {
  return function (dispatch, getState) {
    dispatch({
      type: UPDATE_STYLE_BACKGROUND,
      background
    });

    dispatch(saveToBackend());
  };
}

export function isPreviousBuilderStateSameAsCurrentBuilderState(
  previousFormBuilderState,
  currentFormBuilderState,
  escapedProperties = []
) {
  if (previousFormBuilderState == null || currentFormBuilderState == null) {
    return false;
  }
  for (let property in currentFormBuilderState) {
    if (escapedProperties.includes(property)) continue;
    if (
      currentFormBuilderState[property] !== previousFormBuilderState[property]
    ) {
      return false;
    }
  }
  //Up to this point, nothing has changed
  return true;
}

const hasFormBuilderDataChanged = (function () {
  let previousFormBuilderState = null;
  //These properties change don't need a db save
  const escapedProperties = ["last_edited_question", "isQuestionChanged"];

  //Closure function to capture the previousBuilder State
  return function (currentFormBuilderState) {
    if (
      isPreviousBuilderStateSameAsCurrentBuilderState(
        previousFormBuilderState,
        currentFormBuilderState,
        escapedProperties
      )
    ) {
      return false;
    } else {
      previousFormBuilderState = currentFormBuilderState;
      return true;
    }
  };
})();

export function saveToBackend() {
  return function (dispatch, getState) {
    if (
      !hasFormBuilderDataChanged(getState().Question) ||
      safeGet(_ => getState().Question.fetchingFormDetail) ||
      // Bug fix: user has reported that the form is cleared to empty
      // add this additional check
      safeGet(_ => getState().Question.form_id) == null
    ) {
      return;
    }
    let json_save_to_backend = {
      last_edited_question: getState().Question.last_edited_question,
      questions: getState().Question.questions,
      styles: getState().Question.styles,
      pages: getState().Question.pages,
      variables: getState().Question.variables,
      configurations: getState().Question.configurations,
      question_count: toArray(getState().Question.questions).length
    };
    dispatch(willSaveToBackend());
    patchRequest("api/v1/forms/" + window.form_id, json_save_to_backend)
      .then(response => {
        dispatch(didSaveToBackend(response.data.data));
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function fetchFormDetail() {
  return function (dispatch) {
    //Reset form fonfig
    Form.setFormConfig(null);
    dispatch(updateFormConfig());

    dispatch(willFetchFormDetail());
    window.form_id = getUrlVars()["id"];
    getRequest("api/v1/forms/" + window.form_id + "/question_details")
      .then(response => {
        if (response.data.data.question_details == null) {
          dispatch(showWarning());
        }
        const questionDetails = response.data.data.question_details;
        //init form config. default to classic for now
        Form.setFormConfig(
          response.data.data.form_type || FormTypeEnum.Classic
        );
        dispatch(updateFormConfig());
        loadGoogleFont(safeGet(_ => questionDetails.styles.fontFamily));
        dispatch(
          didFetchFormDetail(
            questionDetails,
            response.data.data.form_name,
            response.data.data.count
          )
        );
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function questionChanged() {
  return {
    type: Question_Changed
  };
}

//TODO: @taoxiang add types
export type actionTypes = any;
