import React, { Component } from 'react';
import VerticalBar from './VerticalBar';
import './style.css';

class VerticalBarChart extends Component {
    constructor(props){
        super(props);
        this.num_data = null;
    }

    
    componentWillMount() {
        if (this.props.data){
            this.num_data = this.getNumData(this.props.data.data)
        }
    }

    getNumData(data){
        return data.reduce((accu, data)=>{
            return accu + data.value
        },0)
    }
    

    renderBar(){
        return this.props.data && this.props.data.data.map(data=>{
            return (<VerticalBar
                        label={data.label}
                        value={data.value}
                        num_data = {this.num_data}
                    />);
        })
    }
    render() {
        if (this.props.data.data){
            return (
                <div className="VerticalBarChart">
                    {this.renderBar()}
                </div>
            );
        }
        else{
            return (
                <div>Loading..</div>
            )
        }
    }
}

export default VerticalBarChart;