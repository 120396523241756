import React, { Component } from "react";
import "./style.css";
import Option from "./option";
import Scrollable from "../../Scrollable";
import Desktop from "../../Views/Desktop";
import Mobile from "../../Views/Mobile";
import "./style.mobile.css";
import WithStyles from "../../../Helper_HOC/WithStyles";
import withConfigurableButtonTextHOC, {
  type InjectedProps as InjectedPropsFromConfigurableButtonTextHOC,
  ButtonTypeFromHOC
} from "../../../Helper_HOC/WithConfigurableButtonText";
import { safeGet } from "../../../Library/Util";

class MobileDropdownDefaultText extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <option value="" selected disabled hidden>
        {safeGet(
          _ =>
            this.props.buttonTextConfig[ButtonTypeFromHOC.dropdownDefaultText]
        ) || ""}
      </option>
    );
  }
}

MobileDropdownDefaultText = withConfigurableButtonTextHOC(
  MobileDropdownDefaultText
);

class DesktopDropdownInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <input
        {...this.props}
        disabled
        placeholder={
          safeGet(
            _ =>
              this.props.buttonTextConfig[ButtonTypeFromHOC.dropdownDefaultText]
          ) || ""
        }
      />
    );
  }
}
DesktopDropdownInput = withConfigurableButtonTextHOC(DesktopDropdownInput);

class DropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true
    };
    this.options = null;
  }

  isSelected(index) {
    let selectedIndex =
      this.props.answer &&
      this.props.answer.answer[0] &&
      this.props.answer.answer[0].index;
    return selectedIndex == index;
  }

  submitAnswer(answer) {
    this.setState(
      {
        collapsed: true
      },
      () => {
        //delay 220 for 0.2s transition height animation to complete
        //other wise, next question will be positioned in wrong place
        setTimeout(() => {
          this.props.onQuestionComplete &&
            this.props.onQuestionComplete(answer);
        }, 220);
      }
    );
  }

  renderOption() {
    return (
      this.props.contents &&
      this.props.contents.map((option, index) => {
        return [
          <Desktop>
            <Option
              selected={this.isSelected(index)}
              onQuestionComplete={this.submitAnswer.bind(this)}
              option={option}
              index={index}
            />
          </Desktop>,
          <Mobile>
            <option>{option}</option>
          </Mobile>
        ];
      })
    );
  }

  renderOptions() {
    if (this.state.collapsed) {
      return <div />;
    } else {
      return <Scrollable>{this.renderOption()}</Scrollable>;
    }
  }

  handleExpandClick(e) {
    e.stopPropagation();
    let currentState = this.state.collapsed;
    this.setState({
      collapsed: !currentState
    });
  }

  renderArrow() {
    if (this.state.collapsed) {
      return (
        <div
          style={{ borderColor: this.props.styles.answer }}
          className="dropDownHead-expandButton-arrow-down"
        />
      );
    } else {
      return (
        <div
          style={{ borderColor: this.props.styles.answer }}
          className="dropDownHead-expandButton-arrow-up"
        />
      );
    }
  }

  renderDots() {
    if (
      !this.state.collapsed &&
      this.props.contents &&
      this.props.contents.length > 4
    ) {
      return (
        <div className="dropDown-dotdotdot">
          <div className="dropDown-dotdotdot-dot" />
          <div className="dropDown-dotdotdot-dot" />
          <div className="dropDown-dotdotdot-dot" />
        </div>
      );
    } else {
      return <div className="dropDown-dotdotdot" />;
    }
  }

  handleInputFocus() {
    this.setState({
      collapsed: false
    });
  }

  open() {
    this.setState({
      collapsed: false
    });
  }

  close() {
    this.setState({
      collapsed: true
    });
  }

  dropdownOptionHeight() {
    if (this.props.contents && this.props.contents.length >= 4) {
      return "240px";
    } else if (this.props.contents && this.props.contents.length < 4) {
      return this.props.contents.length * 60 + "px";
    } else {
      return "0px";
    }
  }

  getAnswer() {
    return (
      this.props.answer &&
      this.props.answer.answer[0] &&
      this.props.answer.answer[0].choiceContent
    );
  }

  addDefaultOption(options) {
    return [<MobileDropdownDefaultText />].concat(options);
  }

  renderMobile() {
    return (
      <Mobile>
        <select
          className="dropDown-Mobile-Selector"
          style={{
            border: `solid 1px ${this.props.styles.answer}`,
            color: this.props.styles.answer,
            backgroundColor: this.props.answerSecondaryColor
          }}
          onChange={e => {
            this.props.onQuestionComplete([
              {
                choiceContent: e.target.value,
                index: e.target.selectedIndex
              }
            ]);
          }}
        >
          {this.addDefaultOption(this.renderOption())}
        </select>
        <div
          style={{ borderColor: this.props.styles.answer }}
          className="dropDown-Mobile-Selector-Tri"
        />
      </Mobile>
    );
  }

  renderDesktop() {
    return (
      <Desktop>
        <div
          onClick={this.handleInputFocus.bind(this)}
          className="dropDownHead"
        >
          <DesktopDropdownInput
            value={this.getAnswer()}
            className="dropDownHead-typeAhead"
            style={{
              border: `solid 1px ${this.props.styles.answer}`,
              color: this.props.styles.answer,
              backgroundColor: this.props.answerSecondaryColor,
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
          />
          <div
            onClick={this.handleExpandClick.bind(this)}
            className="dropDownHead-expandButton"
            style={{
              border: `solid 1px ${this.props.styles.answer}`,
              backgroundColor: this.props.answerSecondaryColor
            }}
          >
            {this.renderArrow()}
          </div>
        </div>
        <div ref={e => (this.options = e)} className="dropDownOptions">
          {this.renderOptions()}
        </div>
        {this.renderDots()}
      </Desktop>
    );
  }

  render() {
    if (this.state.collapsed) {
      this.options && (this.options.style.height = "0px");
    } else {
      this.options && (this.options.style.height = this.dropdownOptionHeight());
    }
    /*
            Click tpyhead to open dropdown
            because input is disabled for now
    */
    return (
      <div className="dropDown">
        {this.renderDesktop()}
        {this.renderMobile()}
      </div>
    );
  }
}

export default WithStyles(DropDown);
