import React, { Component } from "react";
import logo_svg from "../newLogoSVG";
import logo_svg2 from "../newLogoSVG2";
import "./style.css";
import name from "./Wispform.png";

class LogoWithName extends Component {
  render() {
    return (
      <div className="LogoWithName">
        <div
          className="LogoWithName-logo"
          dangerouslySetInnerHTML={{ __html: logo_svg2(35, 35) }}
        />
        <div className="LogoWithName-Name">Wispform</div>
      </div>
    );
  }
}

export default LogoWithName;
