//@flow strict

if (process.env.NODE_ENV == "production") {
  var serverAddress_var = "https://fingerform.herokuapp.com/";
  var domainName_var = "wispform.com";
  var dashboardAddress_var = "dashboard.wispform.com";
  var nodeServiceAddress_var = "https://dashboard.wispform.com";
} else {
  var serverAddress_var = "http://localhost:3000/";
  var domainName_var = "localhost:3000";
  var dashboardAddress_var = "localhost:3001";
  var nodeServiceAddress_var = "http://localhost:3002";
}

export const serverAddress = serverAddress_var;
export const domainName = domainName_var;
export const dashboardAddress = dashboardAddress_var;
export const wispfromLandingPage = "https://wispform.com";
export const nodeServiceAddress = nodeServiceAddress_var;
export const AWS_REGION = "us-east-2";
