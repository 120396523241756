//@flow
import * as React from "react";
import { type questionComponentTypes } from "../../../FlowTypes/questionComponentTypes";
import "./style.css";
import DateInputComponent, { DateFormats } from "../../DateInput";
import MobileDateInput from "../../DateInput/MobileDateInput";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import { UICoreBox, UICoreText } from "../../UICore";
import { isNonEmptyString, executeCallbackSafely } from "../../../Library/Util";
import { isMobileScreen } from "../../../Library/Window";
import TextSubmitButton from "../ShortTextInput/TextSubmitButton";

type Props = {|
  ...questionComponentTypes,
  styles: formStyleType,
  answerSecondaryColor: string,
  answerPrimaryColor: string
|};
type State = {||};
class UI_Date extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _dateInoputComponent: ?DateInputComponent = null;

  componentWillUpdate(nextProps, nextState) {
    // when scrolling to this question, focus on the first section
    if (!this.props.isCurrentQuestion && nextProps.isCurrentQuestion) {
      setTimeout(_ => {
        this._dateInoputComponent &&
          executeCallbackSafely(this._dateInoputComponent.focusOnFirstSection);
      }, 400);
    }
  }

  _getFormattedDate(date: string) {
    if (isNonEmptyString(date)) {
      const parsedValue = date.split("-");
      return `${parsedValue[1]} / ${parsedValue[2]} / ${parsedValue[0]}`;
    }
    return "";
  }

  _submit = () => {
    this.props.onQuestionComplete &&
      this.props.onQuestionComplete(
        (this.props.answer && this.props.answer.answer) || ""
      );
  };

  _renderSubmit = () => {
    if (this.props.isInBuilder) {
      return null;
    } else {
      return (
        <UICoreBox width="100%" marginTop="sm">
          <TextSubmitButton
            onClick={() => {
              this._submit();
            }}
          />
        </UICoreBox>
      );
    }
  };

  _renderBuilderComponent() {
    return (
      <UICoreText
        disableSelect={true}
        hexColor={this.props.answerPrimaryColor}
        size="lg"
        fontFamily={this.props.styles && this.props.styles.fontFamily}
      >
        {this.props.contents &&
        this.props.contents.dateFormat &&
        this.props.contents.dateFormat === "DD/MM/YYYY"
          ? "DD/MM/YYYY"
          : "MM/DD/YYYY"}
      </UICoreText>
    );
  }

  _getSavedAnswer() {
    return (
      (this.props.answer &&
        this.props.answer.answer &&
        this.props.answer.answer[0]) ||
      ""
    );
  }

  _handleAnswerUpdate = (value: string) => {
    this.props.onQuestionUpdate && this.props.onQuestionUpdate([value]);
  };

  render() {
    return (
      <UICoreBox>
        <UICoreBox
          position="relative"
          width="100%"
          shape="rounded"
          hexColor={this.props.answerSecondaryColor}
          padding="xm"
          justifyContent="center"
        >
          {this.props.isInBuilder ? (
            this._renderBuilderComponent()
          ) : isMobileScreen() ? (
            <MobileDateInput
              value={this._getFormattedDate(this._getSavedAnswer())}
              questionContent={this.props.contents}
              onChange={this._handleAnswerUpdate}
              fontFamily={this.props.styles && this.props.styles.fontFamily}
              color={
                isNonEmptyString(this.props.answer && this.props.answer.answer)
                  ? this.props.styles.answer
                  : this.props.answerPrimaryColor
              }
            />
          ) : (
            <DateInputComponent
              ref={ref => {
                this._dateInoputComponent = ref;
              }}
              isEditing={this.props.isCurrentQuestion}
              style={{
                fontSize: "20px",
                color: isNonEmptyString(this._getSavedAnswer())
                  ? this.props.styles.answer
                  : this.props.answerPrimaryColor,
                fontFamily: this.props.styles && this.props.styles.fontFamily
              }}
              onChange={this._handleAnswerUpdate}
              value={this._getSavedAnswer()}
              dateFormat={
                this.props.contents &&
                this.props.contents.dateFormat &&
                this.props.contents.dateFormat === "DD/MM/YYYY"
                  ? DateFormats.DDMMYYYY
                  : DateFormats.MMDDYYYY
              }
              onEnter={() => {
                this._submit();
              }}
            />
          )}
        </UICoreBox>
        {this._renderSubmit()}
      </UICoreBox>
    );
  }
}
export default WithStyles(UI_Date);
