// @flow
import {
  safeGet,
  isNullOrUndefined,
  toArray,
  delayOneCycle
} from "../../../Library/Util";
import * as InputManager from "./InputManager";
import {
  type cursorUpdateEventEnumType,
  cursorUpdateEventEnum
} from "../../../FlowTypes/UICoreTagInputTypes";

const kEnterKeyCode = 13;
const kPlaceHolderKeyCode = -1;

export default class CursorManager {
  _cursorListenerRegistered: boolean;
  _cursorOffset: number;
  _nodeOffset: number;
  _parentElement: ?HTMLElement;
  constructor() {
    this._cursorListenerRegistered = false;
    this._cursorOffset = 0;
    this._nodeOffset = 0;
    this._parentElement = null;
  }

  updateCursorState(
    keyCode: number,
    event: cursorUpdateEventEnumType,
    tagIndex?: number
  ) {
    switch (event) {
      case cursorUpdateEventEnum.focus:
      case cursorUpdateEventEnum.keyUp:
        this._nodeOffset = this._getNodeOffsetFromCurrentEditingNode();
        if (keyCode === kEnterKeyCode) {
          this._cursorOffset = this._cursorOffset + 1;
        } else {
          this._cursorOffset = this.getCurrentCursorOffsetWithinNode();
        }
        break;

      case cursorUpdateEventEnum.tagClick:
        this._cursorOffset = 0;
        this._nodeOffset += 2;
        break;

      case cursorUpdateEventEnum.tagDelete:
        this._cursorOffset = 0;
        this._nodeOffset = 0;
        break;

      default:
        break;
    }
  }

  getCurrentCursorOffsetWithinNode() {
    const node = this._getCurrentNodeFromIndex();
    const ele = node && node.parentElement;
    if (ele) {
      return this._getFlatSelectionStartOffset(ele);
    }
    return 0;
  }

  resetCursorOffset() {
    const currentNode = this._getCurrentNodeFromIndex();
    if (currentNode) {
      this._setCursorOffset(currentNode, this._cursorOffset);
    }
  }

  registerCursorListener() {
    if (!this._cursorListenerRegistered) {
      this._cursorListenerRegistered = true;
      document.addEventListener(
        "selectionchange",
        this._handleCurosrChange.bind(this)
      );
    }
  }

  unregisterCursorListner() {
    document.removeEventListener(
      "selectionchange",
      this._handleCurosrChange.bind(this)
    );
  }

  updateParentElement(e: HTMLElement) {
    this._parentElement = e;
  }

  /**
   * Private Helpers
   */
  _handleCurosrChange() {
    this.updateCursorState(kPlaceHolderKeyCode, cursorUpdateEventEnum.focus);
  }

  _getNodeOffsetFromCurrentEditingNode() {
    const currentNode = safeGet(
      _ => InputManager.getCurrentEditingTextNode().parentNode
    );
    let index = -1;
    if (this._parentElement && this._parentElement.childNodes) {
      index = Array.prototype.indexOf.call(
        this._parentElement.childNodes,
        currentNode
      );
    }
    return index;
  }

  _setCursorOffset(node: Node, offset: number) {
    var range = document.createRange();
    var sel = window.getSelection();

    try {
      range.setStart(node, offset);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
    } catch (error) {
      try {
        //set to begining
        range.setStart(node, 0);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      } catch (error) {}
    }
  }

  _getCurrentNodeFromIndex() {
    return safeGet(
      // $FlowFixMe
      _ => this._parentElement.childNodes[this._nodeOffset].childNodes[0]
    );
  }

  _getFlatSelectionStartOffset(rootElement: Element): number {
    const sel = window.getSelection();
    let selOffset = -1;
    if (sel) {
      let selNode = sel.anchorNode;
      if (selNode && rootElement.contains(selNode)) {
        let offset =
          sel.anchorOffset ||
          (selNode.nodeType === Node.ELEMENT_NODE
            ? selNode.innerText.length
            : 0);
        for (
          selOffset = offset;
          selNode !== rootElement;
          // $FlowFixMe
          selNode = safeGet(_ => selNode.parentNode)
        ) {
          for (
            // $FlowFixMe
            let sibling = safeGet(_ => selNode.previousSibling);
            sibling;
            // $FlowFixMe
            sibling = safeGet(_ => sibling.previousSibling)
          ) {
            switch (sibling.nodeType) {
              case Node.ELEMENT_NODE:
                const sibElem = sibling;
                selOffset += (
                  sibElem.innerText ||
                  (sibElem.tagName.toLowerCase() === "br" ? "\n" : "")
                ).length;
                break;
              case Node.TEXT_NODE:
                // $FlowFixMe
                selOffset += (safeGet(_ => sibling.nodeValue) || "").length;
                break;
            }
          }
        }
      }
    }
    return selOffset;
  }
}
