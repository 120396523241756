// @flow
import { initWithDefaultWispformTrackingID } from "../../GA";
import { getUserInfo, type userInfoType } from "../UserInfo";
import { getFormUID } from "../index";
const ReactGA = initWithDefaultWispformTrackingID();
/**
 * Form Builder Event
 *
 */

type builderEventCategoryType =
  | "Acquisition"
  | "Activation"
  | "Retention"
  | "Collaboration"
  | "ResultPage"
  | "Monetization"
  | "FeatureEvent";
type builderEventActionType =
  | newUserOnboardingType
  | collaborationType
  | resultPageEvent
  | monetizationType
  | featureEventType;

export function logBuilderEvent(
  category: builderEventCategoryType,
  action: builderEventActionType
) {
  const userInfo: ?userInfoType = getUserInfo();
  let userID: string = "";
  if (userInfo) {
    userID = String(userInfo.id);
  } else {
    userID = "unknow";
  }
  ReactGA.event({
    category: category,
    action: action,
    label: userID
  });
}

type newUserOnboardingType =
  | "workspaceLanded"
  | "createFormButtonInEmptyPageClicked"
  | "createFormButtonInClicked"
  | "onboardingStarted"
  | "firstQuestionSelected"
  | "onboardingDismissed"
  | "questionCreated"
  | "questionDeleted"
  | "firstQuestionTitleTyped"
  | "addQuestionButtonClicked"
  | "secondQuestionSelected"
  | "shareButtonTooltipClosed"
  | "shareButtonClicked"
  | "shareButtonFacebookClicked"
  | "shareButtonTwitterClicked"
  | "shareButtonWorkplaceClicked"
  | "shareButtonLinkedinClicked"
  | "shareButtonPinterestClicked"
  | "shareButtonWhatsappClicked"
  | "shareButtonRedditClicked"
  | "shareLinkinSharePageCopied"
  | "shareLinkInWorkspaceCopied"
  | "shareButtonInWorkspaceClicked"
  | "shareEmbedTabClicked"
  | "shareEmbedCodeCopied"
  | "feedbackButtonInWorkspaceClicked"
  | "previewTabClicked"
  | "previewButtonClicked"
  | "logOutButtonInHeaderClicked"
  | "settingButtonInHeaderClicked"
  | "upgradeButtonInHeaderClicked"
  | "howtoButtonInHeaderClicked"
  | "connectToStripeClicked"
  | "connectToStripeUpgradeClicked"
  | "connectToZapierClicked"
  | "connectToZapierUpgradeClicked"
  | "helpButtonInWorkspaceClicked"
  | "embedSectionClicked"
  | "feedbackFormShown"
  | "configurePageZapierClicked"
  | "configurePageStripeClicked"
  | "configurePageGoogleAnalyticsClicked"
  | "outcomeQuizIntroDocumentationClicked"
  | "calculatorIntroDocumentationClicked";

export function logNewUserOnboarding(action: newUserOnboardingType) {
  logBuilderEvent("Activation", action);
}

type collaborationType =
  | "collaborationButtonClicked"
  | "collaborationAddButtonClicked"
  | "collaboratorAdded"
  | "AddCollaboratorFailed";

export function logCollaborationEvent(action: collaborationType) {
  logBuilderEvent("Collaboration", action);
}

type monetizationType =
  | "modalViewed-Add Image"
  | "upgradeClicked-Add Image"
  | "tryClicked-Add Image"
  | "modalViewed-Add Jump Logic"
  | "upgradeClicked-Add Jump Logic"
  | "tryClicked-Add Jump Logic"
  | "modalViewed-Thankyou Page"
  | "upgradeClicked-Thankyou Page"
  | "tryClicked-Thankyou Page"
  | "modalViewed-Create more than 10 questions"
  | "upgradeClicked-Create more than 10 questions"
  | "tryClicked-Create more than 10 questions";
export function logMonetizationEvent(action: monetizationType) {
  logBuilderEvent("Monetization", action);
}

type resultPageEvent =
  | "resultPageViewed"
  | "gridViewViewed"
  | "overviewViewed"
  | "dropoffInsightViewed"
  | "dropoffInsightDocumentationClicked"
  | "userInsightViewed"
  | "userInsightDocumentationClicked"
  | "filterButtonClicked"
  | "deleteButtonClicked"
  | "colorButtonClicked"
  | "emailButtonClicked"
  | "sortButtonClicked"
  | "exportButtonClicked"
  | "shareButtonClicked";
export function logResultPageEvent(action: resultPageEvent) {
  logBuilderEvent("ResultPage", action);
}

/**
 * Form Event
 *
 */

type formEventCategoryType = "Referral" | "Performance";
type formEventActionType = referralType | performanceType;

export function logFormEvent(
  category: formEventCategoryType,
  action: formEventActionType
) {
  const formID: string = getFormUID();
  ReactGA.event({
    category: category,
    action: action,
    label: formID
  });
}

type referralType =
  | "wispformLogoClicked"
  | "thankyouPageReferalButtonClicked"
  | "freeThankYouPageReferalButtonClicked"
  | "outcomePageReferalButtonClicked";

export function logReferralEvent(action: referralType) {
  logFormEvent("Referral", action);
}

type performanceType = "formPageLoaded";
export function logFormPageLoad(action: performanceType) {
  logFormEvent("Performance", action);
}

type featureEventType =
  | "pictureChoice/uploadPictureChoiceFailed"
  | "pictureChoice/uploadPictureChoiceClick"
  | "pictureChoice/startUploadingPictureChoice"
  | "theme/themeButtonClick"
  | "calculator/scoreCalculationButtonClick"
  //Sharing experience
  | "share/linkSectionDisplayed"
  | "share/linkcopied"
  | "share/embedFormSectionDisplayed"
  | "share/embedFormGetCodeButtonClicked"
  | "share/embedFormCodeCopied"
  | "share/embedButtonSectionDisplayed"
  | "share/embedButtonGetCodeButtonClicked"
  | "share/embedButtonCodeCopied"
  | "configurePage/navTabClicked"
  | "configurePage/ZapierClicked"
  | "configurePage/RedirectOnSubmissionClicked"
  | "configurePage/StripeClicked"
  | "configurePage/addRedirectURLClicked"
  | "configurePage/addRedirectURLUpgradeClicked"
  | "configurePage/addRedirectURLSubmitted"
  | "configurePage/removeRedirectURLClicked"
  | "configurePage/connectToGoogleAnalyticsClicked"
  | "configurePage/connectToGoogleAnalyticsUpgradeClicked"
  | "configurePage/connectToGoogleAnalyticsCodeSubmitted"
  | "configurePage/removeGoogleAnalyticsCodeClicked"
  | "configurePage/connectToGoogleTagManagerClicked"
  | "configurePage/connectToGoogleTagManagerUpgradeClicked"
  | "configurePage/connectToGoogleTagManagerCodeSubmitted"
  | "configurePage/showAddWebhookModalClicked"
  | "configurePage/showAddWebhookModalUpgradeClicked"
  | "configurePage/additionalWebhookSubmitted"
  | "configurePage/removeGoogleTagManagerCodeClicked"
  | "configurePage/removeWebhookClicked"
  | "configurePage/connectToZapierClicked"
  | "configurePage/connectToZapierUpgradeClicked"
  | "configurePage/connectToStripeClicked"
  | "configurePage/connectToStripeUpgradeClicked"
  | "configurePage/GoogleAnalyticsClicked"
  | "configurePage/GoogleTagManagerClicked"
  | "configurePage/WebhookClicked"
  | "resultPage/rowExpandClicked"
  | "resultPage/upgradePremiumClickedFromDropoffInsight"
  | "resultPage/upgradePremiumClickedFromUserInsight"
  | "workspace/templateClick"
  | "builder/addNewQuestionButtonClick"
  | "settingPage/teamPageShowAddMemberModalClicked"
  | "settingPage/teamPageBusinessUpgradeClicked"
  | "settingPage/teamPageOpened"
  | "workspace/createQuizAttempted"
  | "workspace/createQuizUpgradeClicked"
  | "workspace/createQuizUpgradeDocumentationClicked"
  | "workspace/createCalculatorAttempted"
  | "workspace/createCalculatorUpgradeClicked"
  | "workspace/createCalculatorUpgradeDocumentationClicked"
  | "workspace/createOutcomeQuizClicked"
  | "workspace/createCalculatorClicked"
  | "formbuilderPage/unsplashImageUsed"
  | "formbuilderPage/unsplashImageSearched"
  | "formbuilderPage/unsplashImageScrolled"
  | "formbuilderPage/uiCoreImageUploaderOpenedPictureChoice"
  | "formbuilderPage/imageUploaderOutcomePage"
  | "formbuilderPage/uiCoreImageUploaderOpenedQuestionImage"
  | "formbuilderPage/uiCoreImageUploaderOpenedBackgroundImage"
  | "formbuilderPage/customImageUsed";
export function logFeatureEvent(action: featureEventType) {
  logBuilderEvent("FeatureEvent", action);
}

// base logger
export function logEvent(category: string, action: string, label: string) {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
}
