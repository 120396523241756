import React, { Component } from "react";
import PropTypes from "prop-types";
import "./style.css";

const AddButton = ({ add_question }) => {
  return (
    <div className="AddButton">
      <div className="AddButton-Add">
        <div className="AddButton-Add-horizontal">
          <div className="AddButton-Add-vertical" />
        </div>
      </div>
    </div>
  );
};

AddButton.propTypes = {
  add_question: PropTypes.func.isRequired
};

export default AddButton;
