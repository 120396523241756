//@flow
import "./style.css";

export function flash(element: HTMLElement, time?: number, cb?: () => void) {
  element && element.classList.add("AnimationFlash");
  setTimeout(() => {
    element && element.classList.remove("AnimationFlash");
    cb && cb();
  }, time || 300);
}
