//@flow
import { getUserName } from "./UserInfo";

function isDeveloper() {
  const developerNames = ["xiangtao1995", "wispe"];
  const userName = getUserName();
  return developerNames.includes(getUserName());
}

function addHotJarRecordingFactory() {
  let isAlreadyAdded = false;
  return function() {
    if (
      !isAlreadyAdded &&
      process.env.NODE_ENV === "production" &&
      !isDeveloper()
    ) {
      var script = document.createElement("script");
      script.innerHTML = ` (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:1420783,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
      document.getElementsByTagName("head")[0].appendChild(script);
      isAlreadyAdded = true;
    }
  };
}

export const addHotJarRecording = addHotJarRecordingFactory();
