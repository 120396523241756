//@flow
import {
  type tableSchemaColumnType,
  type columnIDType,
  type tableDataRowType
} from "../../../Configuration";

export function getRecordName(
  columns: Array<tableSchemaColumnType>,
  row: tableDataRowType
) {
  const FirstStringTypeColumn: ?tableSchemaColumnType = columns.find(
    (tableColumn: tableSchemaColumnType) =>
      tableColumn.type === "ShortText" ||
      tableColumn.type === "LongText" ||
      tableColumn.type === "Email"
  );
  const FirstStringTypeColumnID: ?columnIDType =
    FirstStringTypeColumn && FirstStringTypeColumn.id;
  if (
    !FirstStringTypeColumnID ||
    !row.cellColumnIdMap ||
    !row.cellColumnIdMap[FirstStringTypeColumnID]
  ) {
    return "Unnamed Record";
  } else {
    return String(row.cellColumnIdMap[FirstStringTypeColumnID]);
  }
}
