//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreInput,
  UICoreTooltip,
  UICoreDropdown
} from "../../../../../Component/UICore";
import { updateQuestionContent } from "../../../../FormBuilderPage/States/actions";
import { connect } from "react-redux";
import "./style.css";

type longTextQuestionContentsType = {|
  currency?: string
|};

type Props = {|
  questionIndex: number,
  contents?: longTextQuestionContentsType,
  updateQuestionContent: (number, longTextQuestionContentsType) => {}
|};
type State = {||};

class CurrencyDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleCurrencyUpdate = (targetAnswer: string) => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(this.props.questionIndex, {
        ...this.props.contents,
        currency: targetAnswer
      });
  };

  _getCurrentCurrency = () => {
    if (
      this.props.contents &&
      this.props.contents.currency &&
      this.props.contents.currency != null
    ) {
      return {
        value: this.props.contents.currency,
        display: this.props.contents.currency
      };
    } else {
      return {
        value: "USD",
        display: "USD"
      };
    }
  };

  render() {
    return (
      <UICoreBox>
        <UICoreDropdown
          selectChoice={this._getCurrentCurrency()}
          dropdownMaxHeight={150}
          choices={[
            { value: "USD", display: "USD" },
            { value: "EUR", display: "EUR" },
            { value: "GBP", display: "GBP" },
            { value: "AUD", display: "AUD" },
            { value: "CAD", display: "CAD" },
            { value: "MXN", display: "MXN" },
            { value: "BRL", display: "BRL" },
            { value: "SEK", display: "SEK" },
            { value: "NZD", display: "NZD" },
            { value: "CZK", display: "CZK" }
          ]}
          width="120px"
          dropdownWidth={120}
          onChoiceClick={answer => this._handleCurrencyUpdate(answer)}
        />
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(
        updateQuestionContent(
          pageNumber,
          contents,
          true /* should save to server */
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyDropdown);
