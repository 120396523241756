//@flow
import * as React from "react";
import { connect } from "react-redux";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import { browserHistory } from "react-router";
import { logFeatureEvent } from "../../../../../Library/Logger";
import { isPaidUser } from "../../../../../Library/UserInfo";
import { addRedirectURL } from "../../../../FormBuilderPage/States/actions";
import {
  UICoreText,
  UICoreImage,
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreInput
} from "../../../../../Component/UICore";
import { resolveInTime } from "../../../../../Library/Util";
import UICoreTagInput from "../../../../../Component/UICore/UICoreTagInput";
import { getAllQuestions } from "../../../States/selectors";
import { type questionType } from "../../../../QuestionTypes";
import { getTagInputOptions } from "../../../../../Library/AnswerPiping";
import { convertStringRep2HumanRedableString } from "../../../../../Component/UICore/UICoreTagInput/InputManager";

type State = {|
  showAddURLModal: boolean,
  redirectURL: string
|};

type Props = {|
  ...InjectedProps,
  allQuestions: Array<questionType>,
  addRedirectURL: string => void,
  configurations: { redirect_url: ?string }
|};
class RedirectSection extends React.Component<Props, State> {
  state: State = {
    showAddURLModal: false,
    redirectURL: ""
  };

  constructor(props: Props) {
    super(props);
  }

  _handleAddRedirectURLClick = event => {
    logFeatureEvent("configurePage/addRedirectURLClicked");
    //for now, we open this feature to all
    if (!isPaidUser()) {
      this.props.showNotification(
        "primary",
        "Adding redirect URL on submission is a Pro feature",
        "Upgrade to Wispform Pro to redirect users on submission",
        "Upgrade",
        () => {
          logFeatureEvent("configurePage/addRedirectURLUpgradeClicked");
          browserHistory.push("/setting/checkout");
        }
      );
    } else {
      this.setState({ showAddURLModal: true });
    }
  };

  _handleAddRedirectURL = event => {
    logFeatureEvent("configurePage/addRedirectURLSubmitted");
    this.props.addRedirectURL(this.state.redirectURL);
    return resolveInTime(1500, () => {
      this.setState({ showAddURLModal: false });
      this.props.showNotification(
        "succeed",
        "Redirect URL Updated",
        "You have successfully added a redirect URL for your form."
      );
    });
  };

  _handleRemoveClick = event => {
    logFeatureEvent("configurePage/removeRedirectURLClicked");
    return resolveInTime(1500, () => {
      this.props.addRedirectURL("");
      this.props.showNotification(
        "succeed",
        "Redirect URL Removed",
        "You have successfully removed your redirect URL. Users will no longer be redirected on submission."
      );
    });
  };

  _renderAddURLModal = () => {
    return (
      this.state.showAddURLModal && (
        <UICoreModal
          header="Add Redirect URL"
          onDismiss={() => {
            this.setState({ showAddURLModal: false });
          }}
          body={
            <UICoreBox
              name="Body"
              paddingLeft="sm"
              paddingRight="sm"
              paddingBottom="md"
              paddingTop="md"
              direction="column"
            >
              <UICoreBox paddingBottom="sm">
                <UICoreText overflow="wrap">
                  Please add the URL you want to redirect users to after they
                  submit their form. Please include 'https://' in your url.
                </UICoreText>
                <UICoreBox paddingTop="xm">
                  <UICoreText color="Grey" size="xs">
                    Tip: To add question answer to the URL, type "@" to select
                    an asnwer
                  </UICoreText>
                </UICoreBox>
              </UICoreBox>

              <UICoreBox paddingBottom="sm">
                <UICoreBox
                  color="lightGrey"
                  shape="rounded"
                  padding="xm"
                  hoverable={true}
                >
                  <UICoreTagInput.Editor
                    textStyleProps={{
                      children: null
                    }}
                    onInputUpdate={(content: string) => {
                      this.setState({ redirectURL: content });
                    }}
                    input={this.state.redirectURL}
                    tagHexColor="#88C8FE"
                    tagTextStyle={{
                      color: "darkGray",
                      children: null,
                      size: "xs"
                    }}
                    tagOptionGroups={[
                      {
                        header: "Answer from Questions: ",
                        tags: getTagInputOptions(
                          this.props.allQuestions,
                          Infinity
                        )
                      }
                    ]}
                    placeHolder="Redirect URL e.g. https://www.google.com"
                  />
                </UICoreBox>
              </UICoreBox>

              <UICoreBox>
                <UICoreButton onClick={this._handleAddRedirectURL} size="md">
                  Add Redirect URL
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    );
  };

  _hasRedirectURL = () =>
    this.props.configurations != null &&
    this.props.configurations.redirect_url != null &&
    this.props.configurations.redirect_url != "";

  _renderAddURLButton = () => {
    if (this._hasRedirectURL()) {
      return (
        <UICoreButton
          color="red"
          maxWidth="200px"
          onClick={this._handleRemoveClick}
          key="Remove"
        >
          Remove
        </UICoreButton>
      );
    } else {
      return (
        <UICoreButton
          maxWidth="200px"
          onClick={this._handleAddRedirectURLClick}
          key="Add Redirect URL"
        >
          Add Redirect URL
        </UICoreButton>
      );
    }
  };

  render() {
    return (
      <UICoreBox padding="sm" alignItems="start">
        {this._renderAddURLModal()}
        <UICoreBox alignItems="start" width="100%">
          <UICoreBox paddingBottom="sm">
            <UICoreText color="black" size="md" weight="bold">
              Redirect on Submission
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              Add a redirect URL to your form so users will be automatically
              redirected to your link after they submit their response.{" "}
              <span style={{ color: "red", textDecoration: "underline" }}>
                Note:
              </span>{" "}
              if you have already created a <strong>"Thank You Page"</strong> in
              your form, adding this will skip users from landing on that page.
              Instead, you can add a redirect URL for your{" "}
              <strong>"Thank You page"</strong> by clicking on the button in the
              form builder.
            </UICoreText>
          </UICoreBox>
          {this._hasRedirectURL() && (
            <UICoreBox paddingBottom="md">
              <UICoreText weight="bold" color="lightBlue">
                Redirect URL:{" "}
                {convertStringRep2HumanRedableString(
                  this.props.configurations.redirect_url || ""
                )}
              </UICoreText>
            </UICoreBox>
          )}
          {this._renderAddURLButton()}
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    allQuestions: getAllQuestions(state.Question),
    configurations: state.Question.configurations
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addRedirectURL: url => {
      dispatch(addRedirectURL(url));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(RedirectSection));
