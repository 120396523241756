import React, {Component} from 'react';
import './style.css';
import PropTypes from "prop-types";

/*
  props:
  answer:
    index : 1,
    type : 'MultiChoices',
    title : 'How are you feeling?',
    choices : ['good', 'bad', 'not so bad'],
    answer: 'good'
*/

class AnswerReviewBox extends Component {
  constructor(props) {
    super(props);
  }

  answerContent(){
      return this.props.answer.answer
  }

  getChoiceContent(answer){
    if (answer[0]){
      return answer[0].choiceContent;
    }
    else{
      return "You did not select an option";
    }
  }

  getQuestionAnswer(){
    switch(this.props.answer.type){
      case "MultiChoices":
        return this.getChoiceContent(this.props.answer.answer);
      case "DropDown":
        return this.getChoiceContent(this.props.answer.answer);
      default:
        return this.props.answer.answer;
    }
  }

  render() {
    return(
      <div className={ 'AnswerReviewBox '+ this.props.className}>
        <div className="AnswerReviewBox-question">{this.props.answer.title}</div>
        <div className="AnswerReviewBox-answer">{this.getQuestionAnswer()}</div>
      </div>
    );
  }
}

AnswerReviewBox.propTypes = {
  answer: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default AnswerReviewBox;
