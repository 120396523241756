//@flow
import * as React from "react";
import "./style.css";
import {
  type cellValueType,
  type DataComponentPropsType
} from "../../../Configuration";
import {
  UICoreBox,
  UICoreText,
  UICoreLink
} from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,
  updateCell: cellValueType => mixed
|};
type State = {||};

export const getFileIcon = (fileType: any) => {
  //need this check just for flow to work
  if (fileType) {
    switch (fileType) {
      case "application/pdf":
        return <i className="FileComponent-icon fa fa-file-pdf-o" />;

      case "image/jpeg":
      case "image/png":
        return <i className="FileComponent-icon fa fa-picture-o" />;

      default:
        return <i className="FileComponent-icon fa fa-file-text" />;
    }
  } else {
    return <i className="FileComponent-icon fa fa-file-text" />;
  }
};
class FileComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _renderFile = () => {
    const fileName = String(
      this.props.value && this.props.value.fileName
        ? this.props.value.fileName
        : ""
    );
    const link = String(
      this.props.value && this.props.value.link ? this.props.value.link : ""
    );

    const fileType =
      this.props.value && this.props.value.fileType
        ? this.props.value.fileType
        : null;

    if (!link || !fileName) {
      return null;
    }

    return (
      <UICoreBox
        direction="row"
        padding="xm"
        shape="rounded"
        alignItems="center"
        className="FileComponent-Wrapper"
      >
        {getFileIcon(fileType)}
        <UICoreText color="darkGray" size="xs">
          <UICoreLink href={link}>{fileName}</UICoreLink>
        </UICoreText>
      </UICoreBox>
    );
  };

  render() {
    return (
      <UICoreBox
        overflowX="hidden"
        direction="row"
        padding="xm"
        height="100%"
        width="100%"
      >
        {this._renderFile()}
      </UICoreBox>
    );
  }
}
export default FileComponent;
