//@flow
import * as React from "react";
import "./style.css";
import UICoreText, { type Props as UICoreTextProps } from "../UICoreText";
import UICoreBox, { type Props as UICoreBoxProps } from "../UICoreBox";
import { nonNullOrThrow } from "../../../Library/Util";

export type UICoreRadioButtonLabelType = {|
  text: string,
  extra?: any
|};
type Props = {|
  selectedIndex?: number,
  selectedLocator?: UICoreRadioButtonLabelType => boolean,
  labels: Array<UICoreRadioButtonLabelType>,
  sectionComponents?: { [number]: React.Node },
  onChange: (number, ?any) => void,
  customStyling?: {|
    selectedLabelBoxStyle?: $Shape<UICoreBoxProps>,
    defaultLabelBoxStyle?: $Shape<UICoreBoxProps>,
    selectedLabelTextStyle?: $Shape<UICoreTextProps>,
    defaultLabelTextStyle?: $Shape<UICoreTextProps>,
    innerCircleColor?: string,
    outterCircleColor?: string
  |}
|};
type State = {||};

const kRadioButtonWidth = 21;
const kRadioInnerButtonWidth = 13;

const UICoreRadioButtonsUnselectedRadioButton = (props: {
  innerCircleColor?: string,
  outterCircleColor?: string
}) => {
  return (
    <UICoreBox
      width={kRadioButtonWidth}
      height={kRadioButtonWidth}
      hoverable={true}
      color="lightGrey"
      shape="circle"
      justifyContent="center"
      alignItems="center"
      hexColor={props.outterCircleColor}
    >
      <UICoreBox
        className="UICoreRadioButtons-unselectedRadio"
        width={kRadioInnerButtonWidth}
        height={kRadioInnerButtonWidth}
        color="lightBlue"
        shape="circle"
      >
        {""}
      </UICoreBox>
    </UICoreBox>
  );
};

const UICoreRadioButtonsSelectedRadioButton = (props: {
  innerCircleColor?: string,
  outterCircleColor?: string
}) => {
  return (
    <UICoreBox
      width={kRadioButtonWidth}
      height={kRadioButtonWidth}
      hoverable={true}
      color="lightGrey"
      shape="circle"
      justifyContent="center"
      alignItems="center"
      hexColor={props.outterCircleColor}
    >
      <UICoreBox
        width={kRadioInnerButtonWidth}
        height={kRadioInnerButtonWidth}
        color="blue"
        shape="circle"
        hexColor={props.innerCircleColor}
      >
        {""}
      </UICoreBox>
    </UICoreBox>
  );
};

class UICoreRadioButtons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox>
        {this.props.labels.map((label, index) => {
          const isSelected = this.props.selectedLocator
            ? this.props.selectedLocator(label)
            : index === this.props.selectedIndex;
          return (
            <UICoreBox
              className="UICoreRadioButtons-section"
              paddingBottom="sm"
              onClick={_ => this.props.onChange(index, label.extra)}
              {...(this.props.customStyling
                ? isSelected
                  ? this.props.customStyling.selectedLabelBoxStyle
                  : nonNullOrThrow(this.props.customStyling)
                      .defaultLabelBoxStyle
                : null)}
            >
              <UICoreBox alignItems="center" direction="row">
                <UICoreBox paddingRight="xm">
                  {isSelected ? (
                    <UICoreRadioButtonsSelectedRadioButton
                      innerCircleColor={
                        this.props.customStyling &&
                        this.props.customStyling.innerCircleColor
                      }
                      outterCircleColor={
                        this.props.customStyling &&
                        this.props.customStyling.outterCircleColor
                      }
                    />
                  ) : (
                    <UICoreRadioButtonsUnselectedRadioButton
                      outterCircleColor={
                        this.props.customStyling &&
                        this.props.customStyling.outterCircleColor
                      }
                    />
                  )}
                </UICoreBox>
                <UICoreText
                  weight={isSelected ? "medium" : "normal"}
                  size="md"
                  color="black"
                  {...(this.props.customStyling
                    ? isSelected
                      ? this.props.customStyling.selectedLabelTextStyle
                      : nonNullOrThrow(this.props.customStyling)
                          .defaultLabelTextStyle
                    : null)}
                >
                  {label.text}
                </UICoreText>
              </UICoreBox>
              {this.props.sectionComponents && (
                <UICoreBox
                  dangerous_style={{ opacity: isSelected ? 1 : 0.2 }}
                  disabled={!isSelected}
                >
                  {this.props.sectionComponents[index]}
                </UICoreBox>
              )}
            </UICoreBox>
          );
        })}
      </UICoreBox>
    );
  }
}
export default UICoreRadioButtons;
