//@flow

import { executeCallbackSafely } from "./Util";

let loaded = false;

export function logActiveUser(email: string) {
  (function (i, s, o, g, r, a, m, c) {
    i[o] =
      i[o] ||
      function () {
        (i[o].q = i[o].q || []).push(arguments);
      };
    a = s.createElement(g);
    m = s.getElementsByTagName(g)[0];
    a.async = true;
    a.src =
      //$FlowFixMe
      r + "?auth=" + s.getElementById(o + "-js").getAttribute("data-pw-auth");
    //$FlowFixMe
    m.parentNode.insertBefore(a, m);
    a.onload = a.onreadystatechange = function () {
      if (!loaded && (!this.readyState || this.readyState == "complete")) {
        loaded = true;
        executeCallbackSafely(c);
      }
    };
  })(
    window,
    document,
    "profitwell",
    "script",
    "https://public.profitwell.com/js/profitwell.js",
    null,
    null,
    () => {
      executeCallbackSafely(_ => {
        //$FlowFixMe
        window.profitwell("start", { user_email: email });
      });
    }
  );
}
