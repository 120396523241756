// @flow

import * as React from "react";
import ViewWindow from "../../Containers/ViewWindow";
import "./style.css";
import PropTypes from "prop-types";
import {
  UICoreBox,
  UICoreText,
  UICoreFlyOut
} from "../../../../Component/UICore";
import formConfiguration, {
  type QuestionTypes,
  QuestionTypeEnum
} from "../../../QuestionTypes";
import { logNewUserOnboarding } from "../../../../Library/Logger";
import { convertStringRep2HumanRedableString } from "../../../../Component/UICore/UICoreTagInput/InputManager";
import ReactDOM from "react-dom";
import QuestionListActionMenu from "./QuestionListActionMenu";

const config: any = new formConfiguration();
const questionType = config.types;

type Props = {|
  onChange: number => void,
  pageNumber: number,
  thankyouPageIndex?: number,
  className: string,
  display: string,
  delete_question: number => void,
  type: QuestionTypes
|};

type State = {|
  showActionDropdown: boolean
|};

export default class QuestionListItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showActionDropdown: false
    };
  }

  _anchorNode: ?HTMLElement = null;

  _handleRightClick = (e: SyntheticInputEvent<HTMLDivElement>) => {
    e.preventDefault();
    const isOutcome = this.props.type === QuestionTypeEnum.OutcomePage;
    if (isOutcome) {
      //outcome type doesn't support actions
      return;
    }
    this.props.onChange(this.props.pageNumber);
    this.setState({
      showActionDropdown: true
    });
  };

  render() {
    const isThankyouPage = this.props.type === QuestionTypeEnum.ThankyouPage;
    const isOutcome = this.props.type === QuestionTypeEnum.OutcomePage;
    const deletable = !isOutcome;
    return [
      <UICoreBox
        onContextMenu={this._handleRightClick}
        innerRef={ref => {
          this._anchorNode = ref;
        }}
        className={"PreviewWindow-Container "}
      >
        <UICoreBox
          position="relative"
          onClick={() => {
            this.props.onChange(this.props.pageNumber);
          }}
          /* eslint-disable */
          className={"PreviewWindow " + " " + this.props.className}
          /* eslint-enable */
        >
          <div className="PreviewWindow-Content">
            <div className="PreviewWindow-QuestionType">
              <i
                className={`fa ${questionType[this.props.type].iconClassName}`}
                aria-hidden="false"
              />
            </div>
            <p className="PreviewWindow-Question">
              {convertStringRep2HumanRedableString(this.props.display)}
            </p>
          </div>

          <UICoreBox position={"absolute"} bottom="4px" right="8px">
            <UICoreText color="Grey" size="xs">
              {this.props.pageNumber + 1}
            </UICoreText>
          </UICoreBox>
          {deletable && (
            <UICoreBox
              position="absolute"
              top="4px"
              right="4px"
              className="PreviewWindow-CloseIcon"
              shape="circle"
              color="lightGrey"
              width="18px"
              height="18px"
              justifyContent="center"
              alignItems="center"
            >
              <i
                onClick={e => {
                  // stop propagation to prevent triggering preview click handler
                  e.stopPropagation();
                  logNewUserOnboarding("questionDeleted");
                  this.props.delete_question(this.props.pageNumber);
                }}
                className="ion-close"
                aria-hidden="true"
              />
            </UICoreBox>
          )}
        </UICoreBox>
        {ReactDOM.createPortal(
          <UICoreBox>
            {this.state.showActionDropdown && (
              <UICoreFlyOut
                positionRelativeToAnchor={false}
                size="xs"
                idealDirection="right"
                anchor={this._anchorNode}
                onDismiss={() => {
                  this.setState({ showActionDropdown: false });
                }}
                forceDirection={true}
                showCaret={false}
                relativeTop={40}
                relativeLeft={-10}
              >
                <QuestionListActionMenu
                  questionType={this.props.type}
                  closeDropdown={() => {
                    this.setState({ showActionDropdown: false });
                  }}
                  isThankyouPage={isThankyouPage}
                  questionIndex={
                    isThankyouPage
                      ? this.props.thankyouPageIndex
                      : this.props.pageNumber
                  }
                />
              </UICoreFlyOut>
            )}
          </UICoreBox>,
          document.getElementsByClassName("FormApp")[0]
        )}
      </UICoreBox>
    ];
  }
}
