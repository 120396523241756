//@flow
import * as React from "react";
import JumpLogic from "../JumpLogic";
import { type logicJumpType } from "../../../../../FlowTypes/jumpLogicTypes";
import {
  getThankyouPages,
  getThankyouPagesDisplayLogic
} from "../../../States/selectors";
import { connect } from "react-redux";
import {
  type thankyouPageType,
  type questionIDType
} from "../../../../WispformTypings";
import {
  update_thankyou_page_display_logic,
  add_thankyou_page
} from "../../../States/actions";
import { type dropdownFooterButtonType } from "../../../../../Component/UICore/UICoreDropdown";

type Props = {|
  questionID: questionIDType,
  displayLogic: logicJumpType,
  updateThankyouPageDisplayLogic: (?questionIDType, logicJumpType) => void,
  thankyouPages: Array<thankyouPageType>,
  addThankyouPage: () => {}
|};
type State = {||};

const kArrayOffset = 1;

class ThankyouPageDisplayLogic extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getFormattedTitle = (title: string, index: number) => {
    return (
      <span>
        <b>{`Thank you page ${index + kArrayOffset}: `}</b>
        {title}
      </span>
    );
  };

  _getJumpToTargets = () => {
    return this.props.thankyouPages.map((page, index) => {
      return {
        value: index,
        display: this._getFormattedTitle(page.thankyouNote, index)
      };
    });
  };

  _getDefaultTargetQuestion = () => {
    //default to the next question
    return this._getJumpToTargets()[0];
  };

  _handleAddThankyouPageClick = () => {
    this.props.addThankyouPage();
  };

  render() {
    return (
      <JumpLogic
        {...this.props}
        jumpLogic={this.props.displayLogic}
        jumpToTargets={this._getJumpToTargets()}
        updateJumpLogic={this.props.updateThankyouPageDisplayLogic}
        addButtonHint={"Update"}
        textContent={{
          modalTitle: "Thank You Page Display Logic",
          addNewBranchButton: "Add a Display Logic",
          addAnotherBranchButton: "Add Another Display Logic",
          branchTarget: "Then display thank you page:",
          fallbackTarget: "In all other cases, display thank you page:",
          branchTitlePrefix: "Display logic",
          toolTip:
            "Use display logic to show different thank you pages based on user's answers."
        }}
        defaultTargetQuestion={this._getDefaultTargetQuestion()}
        jumpToTargetsDropdownFooterButton={{
          onClick: this._handleAddThankyouPageClick,
          buttonText: "Create a New Thank You Page"
        }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    thankyouPages: getThankyouPages(state.Question),
    displayLogic: getThankyouPagesDisplayLogic(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateThankyouPageDisplayLogic: (
      _?: questionIDType,
      updatedDisplayLogic: logicJumpType
    ) => {
      dispatch(update_thankyou_page_display_logic(updatedDisplayLogic));
    },
    addThankyouPage: () => {
      dispatch(add_thankyou_page());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThankyouPageDisplayLogic);
