// @flow
import {
  type tagInputStringRepresentationType,
  type tagInputNodesType,
  type dropdownTriggerDetectorStateEnumType,
  type tagInputTagMapType,
  type tagInputTagType,
  type tagInputRenderTagContentMapType,
  dropdownTriggerDetectorStateEnum,
  tagInputNodeEnum
} from "../../../FlowTypes/UICoreTagInputTypes";
import {
  replace,
  safeGet,
  toNumber,
  isNonEmptyString,
  lastItem,
  isNonEmptyArray,
  isEmptyArray,
  countOccurenceOfChar,
  isNullOrUndefined,
  filterNull
} from "../../../Library/Util";
import CursorManager from "./CursorManager";

const kElementNodeType = 1;
const kTextNodeType = 3;
const k2KeyCode = 50;
const kShiftKeyCode = 16;
const kZeroWidthNoBreakSpace = "\uFEFF";

/**
 *  Private Variable
 */
let _dropdownTriggerState: dropdownTriggerDetectorStateEnumType =
  dropdownTriggerDetectorStateEnum.waitingForTrigger;

/**
 * Input is represented as a string structure: abcd@{1}efgh@{2}ijk
 * @{id} represents a tag
 *
 * Return a string with tag id replaced by the real value
 */
export function convertStringRep2TagFilledString(
  stringRep: tagInputStringRepresentationType,
  tags: tagInputRenderTagContentMapType
): string {
  return convertStringRep2Array(stringRep)
    .map(item => {
      if (item.type === tagInputNodeEnum.string) {
        return item.content;
      } else if (item.type === tagInputNodeEnum.tag) {
        return tags[item.content];
      }
    })
    .join("")
    .split("")
    .filter(char => char !== kZeroWidthNoBreakSpace)
    .join("");
}

/**
 * Input is represented as a string structure: abcd@{1}efgh@{2}ijk
 * @{id} represents a tag
 *
 * return a structured array of type tagInputNodeType
 *
 */
export function convertStringRep2Array(
  stringRep: tagInputStringRepresentationType
): tagInputNodesType {
  let tokenized = stringRep.split(/(@{[^@|{|}]*})/);
  let arrayRep = tokenized
    .filter(token => isNonEmptyString(token))
    .map(token => {
      if (token.includes("@{")) {
        return {
          type: tagInputNodeEnum.tag,
          content: token.slice(2, token.length - 1)
        };
      } else {
        return {
          type: tagInputNodeEnum.string,
          content: token
        };
      }
    });
  if (!isNonEmptyArray(arrayRep)) {
    arrayRep = [];
  } else if (arrayRep[0].type === tagInputNodeEnum.tag) {
    arrayRep = [
      { type: tagInputNodeEnum.string, content: kZeroWidthNoBreakSpace },
      ...arrayRep
    ];
  } else if (lastItem(arrayRep).type === tagInputNodeEnum.tag) {
    arrayRep = [
      ...arrayRep,
      { type: tagInputNodeEnum.string, content: kZeroWidthNoBreakSpace }
    ];
  }
  return arrayRep;
}

/**
 *
 * remove the @{id} part in the string and convert it into __ instead
 * Example: Hey @{123}! ==> Hey __!
 */
export function convertStringRep2HumanRedableString(
  stringRep: tagInputStringRepresentationType
): string {
  return convertStringRep2Array(stringRep)
    .map(token => {
      if (token.type === tagInputNodeEnum.string) {
        return token.content;
      } else if (token.type === tagInputNodeEnum.tag) {
        return "__";
      } else {
        return "";
      }
    })
    .join("");
}

/**
 *
 * remove the @{id} part in the string and convert it into 1 instead for calculator formula input
 * Example: Hey @{123}! ==> Hey 1!
 */
export function convertStringRep2HumanRedableStringWithNumber(
  stringRep: tagInputStringRepresentationType
): string {
  return convertStringRep2Array(stringRep)
    .map(token => {
      if (token.type === tagInputNodeEnum.string) {
        return token.content;
      } else if (token.type === tagInputNodeEnum.tag) {
        return "1";
      } else {
        return "";
      }
    })
    .join("");
}

/**
 *
 * remove the @{id} part in the string and convert it into actual value of calculator
 * Example: @{123}+@{456}! ==> 23+34!
 */

export function convertStringRep2HumanRedableStringWithSanitizedNumber(
  stringRep: tagInputStringRepresentationType,
  calculatorValueMap: any
): string {
  let calculatorValueMap1 = calculatorValueMap;
  return convertStringRep2Array(stringRep)
    .map(token => {
      if (token.type === tagInputNodeEnum.string) {
        return token.content;
      } else if (token.type === tagInputNodeEnum.tag) {
        if (
          token.content &&
          calculatorValueMap[token.content] &&
          calculatorValueMap[token.content] != ""
        ) {
          return calculatorValueMap[token.content];
        } else {
          return null;
        }
      } else {
        return "";
      }
    })
    .join("");
}

export function _convertDOMRep2Array(nodes: NodeList<Node>): tagInputNodesType {
  const nodeArray = [].slice.call(nodes);
  let arrayRep = nodeArray.map(node => {
    if (isNullOrUndefined(safeGet(_ => node.dataset.type))) {
      return null;
    }
    const content = safeGet(_ => node.dataset.content) || "";
    const type = toNumber(node.dataset.type);
    if (type === tagInputNodeEnum.tag) {
      return {
        type: type,
        content: content
      };
    } else {
      return {
        type: type,
        content: node.innerText
      };
    }
  });
  return filterNull(arrayRep).filter(item => isNonEmptyString(item.content));
}

export function convertArrayRep2String(
  tokens: tagInputNodesType
): tagInputStringRepresentationType {
  return tokens
    .map(token => {
      if (token.type === tagInputNodeEnum.tag) {
        return `@{${token.content}}`;
      } else {
        return token.content;
      }
    })
    .join("");
}

export function getCurrentEditingElement(): HTMLElement {
  return window.getSelection().anchorNode.parentElement;
}

export function getCurrentEditingTextNode(): Node {
  if (window.getSelection().anchorNode.nodeType === kElementNodeType) {
    return window.getSelection().anchorNode.firstChild;
  }
  return window.getSelection().anchorNode;
}

export function getCurrentEditingElementIndexOfParentElement(): number {
  const element = getCurrentEditingElement();
  if (element.parentElement) {
    return Array.from(element.parentElement.childNodes).indexOf(element);
  }
  return -1;
}

export function getCurrentEditingElementInnerText(): string {
  const element = getCurrentEditingElement();
  return element.innerText || "";
}

export function getUpdatedInputForTextUpdate(
  input: tagInputStringRepresentationType,
  parentElement: HTMLElement
): tagInputStringRepresentationType {
  return convertArrayRep2String(_convertDOMRep2Array(parentElement.childNodes));
}

export function getUpdatedInputForTagDelete(
  input: tagInputStringRepresentationType,
  nodeIndex: number
) {
  const tokens = convertStringRep2Array(input);
  const updatedTokens = [
    ...tokens.slice(0, nodeIndex),
    ...tokens.slice(nodeIndex + 1)
  ];
  return convertArrayRep2String(updatedTokens);
}

export function getUpdatedInputForTagUpdate(
  cursorManager: CursorManager,
  input: tagInputStringRepresentationType,
  tagID: string
) {
  const tokens = convertStringRep2Array(input);
  const editingIndex = getCurrentEditingElementIndexOfParentElement();
  const cursorOffset = cursorManager.getCurrentCursorOffsetWithinNode();
  const updatedTokens = [
    ...tokens.slice(0, editingIndex),
    {
      type: tagInputNodeEnum.string,
      content: tokens[editingIndex].content.slice(0, cursorOffset - 1)
    },
    {
      type: tagInputNodeEnum.tag,
      content: tagID
    },
    {
      type: tagInputNodeEnum.string,
      content: tokens[editingIndex].content.slice(cursorOffset)
    },
    ...tokens.slice(editingIndex + 1)
  ];
  return convertArrayRep2String(updatedTokens);
}

export function shouldShowDropdownMenu(
  input: tagInputStringRepresentationType
): boolean {
  return (
    _dropdownTriggerState === dropdownTriggerDetectorStateEnum.showDropdown
  );
}

export function shouldHideDropdownMenu(
  input: tagInputStringRepresentationType
): boolean {
  return (
    _dropdownTriggerState === dropdownTriggerDetectorStateEnum.closeDropdown
  );
}

export function resetDropdownTriggerDetectorState() {
  _dropdownTriggerState = dropdownTriggerDetectorStateEnum.waitingForTrigger;
}

export function keyDownEventHandler(
  e: SyntheticKeyboardEvent<HTMLSpanElement>,
  input: tagInputStringRepresentationType
) {
  const tokens = convertStringRep2Array(input);
  const editingIndex = getCurrentEditingElementIndexOfParentElement();
  const previousText = safeGet(_ => tokens[editingIndex].content);
  const numTriggerPrev = countOccurenceOfChar(String(previousText), "@");
  const numTriggerCurrent = countOccurenceOfChar(
    getCurrentEditingElementInnerText(),
    "@"
  );

  switch (_dropdownTriggerState) {
    case dropdownTriggerDetectorStateEnum.waitingForTrigger:
      if (numTriggerCurrent > numTriggerPrev) {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.showDropdown;
      }
      break;
    case dropdownTriggerDetectorStateEnum.showDropdown:
      if (numTriggerCurrent < numTriggerPrev) {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.closeDropdown;
        // handle the edge case of release shift and @ key
      } else if (e.keyCode === k2KeyCode || e.keyCode === kShiftKeyCode) {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.showDropdown;
      } else {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.dropdownActive;
      }
      break;
    case dropdownTriggerDetectorStateEnum.dropdownActive:
      if (numTriggerCurrent < numTriggerPrev) {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.closeDropdown;
      } else {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.dropdownActive;
      }
      break;
    case dropdownTriggerDetectorStateEnum.closeDropdown:
      if (numTriggerCurrent > numTriggerPrev) {
        _dropdownTriggerState = dropdownTriggerDetectorStateEnum.showDropdown;
      } else {
        _dropdownTriggerState =
          dropdownTriggerDetectorStateEnum.waitingForTrigger;
      }
      break;
    default:
      break;
  }
}

export function getTagContentListFromMap(
  tagMap: tagInputTagMapType
): Array<tagInputTagType> {
  // $FlowFixMe
  return Object.values(tagMap);
}

export function getTagIdContentMapFromTagOptionGroups(
  tagOptionGroups: Array<{|
    header?: string,
    tags: tagInputTagMapType
  |}>
): tagInputTagMapType {
  return tagOptionGroups.reduce((prev, optionGroup) => {
    return {
      ...prev,
      ...optionGroup.tags
    };
  }, {});
}
