//@flow
import * as React from "react";
import { UICoreBox, UICoreInput } from "../UICore";
import "./style.css";
import { isValidHexCode } from "../../Library/Colors";

type Props = {|
  selectedHexColor: string,
  onColorSelected: string => void
|};
type State = {|
  input_hex: string
|};

const sample_colors: Array<string> = [
  "#fcf6ec",
  "#007aff",
  "#37D67A",
  "#2BCDE4",
  "#555555",
  "#DCE774",
  "#FF8965",
  "#ffffff"
];

class ColorPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      input_hex: props.selectedHexColor
    };
  }

  _handleHexInput(e: SyntheticInputEvent<any>) {
    const input_char = e.currentTarget.value;
    this.setState({
      input_hex: input_char
    });
  }

  _handleKeyup(e: SyntheticKeyboardEvent<any>) {
    const enter_key = 13;
    const input_char = e.keyCode;
    if (this._isValidHex(this.state.input_hex)) {
      this.props.onColorSelected(this.state.input_hex);
    }
  }

  _renderSamples(): Array<React.Node> {
    return sample_colors.slice(0, 8).map((color: string) => {
      return (
        <div
          onClick={e => {
            e.stopPropagation();
            this.props.onColorSelected(color);
          }}
          className="SI-Platte-Samples-Sample"
          style={{ background: color }}
        />
      );
    });
  }

  _isValidHex(hex: string) {
    return /^#[0-9A-F]{6}$/i.test(hex);
  }

  render() {
    return (
      <UICoreBox width={200}>
        <div className="SI-Platte">
          <div
            style={{
              background: this.props.selectedHexColor
            }}
            className="SI-Platte-View"
          >
            {this.props.selectedHexColor}
          </div>
          <div className="SI-Platte-Samples">{this._renderSamples()}</div>
          <div className="SI-Platte-Input">
            <UICoreInput
              size="xs"
              onKeyUp={this._handleKeyup.bind(this)}
              value={this.state.input_hex}
              onChange={this._handleHexInput.bind(this)}
            />
          </div>
        </div>
      </UICoreBox>
    );
  }
}

export default ColorPicker;
