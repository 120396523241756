//@flow
import * as React from "react";
import {
  type yesNoCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import "./style.css";
import { UICoreBox } from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,

  updateCell: yesNoCellType => mixed
|};
type State = {||};
class YesNoComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleClick = () => {
    if (this.props.value === "yes") {
      this.props.updateCell("no");
    } else {
      this.props.updateCell("yes");
    }
  };

  render() {
    return (
      <UICoreBox
        padding="xm"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <div onClick={this._handleClick} className="YesNoComponent-box">
          {this.props.value === "yes" ? (
            <i className="YesNoComponent-check fa fa-check" />
          ) : (
            ""
          )}
        </div>
      </UICoreBox>
    );
  }
}
export default YesNoComponent;
