import React, { Component } from 'react';
import VerticalBarChart from '../PrimitiveCharts/VerticalBarChart';
import './style.css';

/*
    data:{
		1:100,
		2:150,
		3:120,
		4:100,
		5:80
	}
*/
class RatingChart extends Component {
    getData(){
        let data = [];
        for (let i = 1; i<=5; i++){
            data.push({
                label:(<div>{i} <i className="fa fa-star"/></div>),
                value:this.props.data.data[i]? this.props.data.data[i]:0
            })
        }
        return {data};
    }
    render() {
        return(
            <VerticalBarChart
                data = {this.getData()}
            />
        )
    }
}

export default RatingChart;