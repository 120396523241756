//@flow
import { type comperatorNameTypes } from "./jumpLogicComperatorTypes";
import { type questionIDType } from "../Pages/WispformTypings";
import * as React from "react";

export type jointLogicType = "&&" | "||";

export type conditionType = {|
  targetQuestionID: questionIDType,
  comperator: comperatorNameTypes,
  targetAnswer: string | number
|};

export type branchType = {|
  conditions: Array<conditionType>,
  jointLogics: Array<jointLogicType>,
  jumpToQuestionID: ?questionIDType,
  outcome?: ?dynamicPricingOutcomeType
|};

export type userInputType = {|
  targetQuestionID: string
|};

export type dynamicPricingOutcomeType = {|
  operation: "add" | "subtract",
  amount: string
|};

export type logicJumpType = {|
  branches: Array<branchType>,
  default: ?questionIDType
|};

export const comperators = ["is", "is not"];

export const DEFAULT_END = "DEFAULT_END";

export type branchOutcomeRenderPropTypes = (
  branchType,
  updateBranch: (branchType) => void
) => React.Node;
