import React, { Component } from "react";
import Button from "../Button";
import "./style.css";
import ClickFresh from "../../Animations/ClickFlash";
import PropTypes from "prop-types";
import {
  updateQuestionContentWithouPersistence,
  saveToBackend
} from "../../../Pages/FormBuilderPage/States/actions";
import { connect } from "react-redux";

const UI_Statement = ({
  onQuestionComplete,
  className,
  isInBuilder,
  contents,
  updateQuestionContentWithouPersistence,
  pageNumber,
  saveToBackend
}) => {
  function handleStatementButtonUpdate(e) {
    updateQuestionContentWithouPersistence &&
      updateQuestionContentWithouPersistence(pageNumber, e.target.value);
  }

  function handleButtonEditComplete() {
    saveToBackend && saveToBackend();
  }

  //test agianst empty array for backward compatibility
  //contensts were initiliazed as [""]
  const buttonText =
    contents == null || Array.isArray(contents) ? "Continue" : contents;

  return isInBuilder ? (
    <Button
      editable={true}
      onButtonEditComplete={handleButtonEditComplete}
      onChange={handleStatementButtonUpdate}
      className={"UI_Statement-Button " + className}
      buttonContent={buttonText}
    />
  ) : (
    <ClickFresh
      onClick={() => onQuestionComplete && onQuestionComplete("Continue")}
      composedAnswer={null}
    >
      <Button
        className={
          "UI_Statement-Button UI_Statement-InteractableButton " + className
        }
        buttonContent={buttonText}
      />
    </ClickFresh>
  );
};

UI_Statement.propTypes = {
  className: PropTypes.string.isRequired,
  onQuestionComplete: PropTypes.func.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContentWithouPersistence: (pageNumber, contents) => {
      dispatch(updateQuestionContentWithouPersistence(pageNumber, contents));
    },
    saveToBackend: () => {
      dispatch(saveToBackend());
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(UI_Statement);
