// external dependencies
import React, { Component } from "react";
import { connect } from "react-redux";
// internal dependencies
import DropDownEditor from "../../Components/DropDownEditor";
import { add_dropdown_choice, saveToBackend } from "../../States/actions";
import PropTypes from "prop-types";

class DropDownEditorContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      contents,
      pageNumber,
      add_dropdown_choice,
      saveToBackend
    } = this.props;
    return (
      <DropDownEditor
        contents={contents}
        pageNumber={pageNumber}
        add_dropdown_choice={add_dropdown_choice}
        saveToBackend={saveToBackend}
      />
    );
  }
}

function mapStateToProps(state) {
  const { last_edited_question, questions } = state.Question;
  return {
    pageNumber: last_edited_question,
    contents: questions[last_edited_question].contents
  };
}

function mapDispatchToProps(dispatch) {
  return {
    add_dropdown_choice: (pageNumber, choice_content) => {
      dispatch(add_dropdown_choice(pageNumber, choice_content));
    },
    saveToBackend: () => {
      dispatch(saveToBackend());
    }
  };
}

DropDownEditorContainer.propTypes = {
  contents: PropTypes.array.isRequired,
  pageNumber: PropTypes.number.isRequired,
  add_dropdown_choice: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DropDownEditorContainer);
