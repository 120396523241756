//@flow
import React, { Component } from "react";
import UI_Choice from "../../QuestionComponents/UI_Choice";
import withStyles from "../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import { SortableElement } from "react-sortable-hoc";
import "./style.css";

type Props = {|
  addChoice: () => mixed,
  removeChoice: () => mixed,
  choiceContent: string,
  isInBuilder: boolean,
  prev_answer: Array<any>,
  index: number,
  className: string,
  updateChoice: string => mixed,
  saveToBackend: () => mixed,
  styles: formStyleType,
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  choiceNumber: number
|};

class ChoiceBuilderComponent extends Component<Props> {
  _contentChoiceNode: ?HTMLInputElement = null;

  _setContentChoiceNode = (e: ?HTMLInputElement) => {
    this._contentChoiceNode = e;
  };

  _handleContentChoiceChange = (e: Event) => {
    // $FlowFixMe
    this.props.updateChoice(e.target.value);
  };

  _getEditorStyles = () => {
    return {
      borderColor: this.props.styles.answer,
      backgroundColor: this.props.answerSecondaryColor,
      color: this.props.styles.answer
    };
  };

  componentDidMount() {
    if (this._contentChoiceNode) {
      this._contentChoiceNode.addEventListener(
        "input",
        this._handleContentChoiceChange
      );
      // $FlowFixMe already checked
      this._contentChoiceNode.addEventListener(
        "blur",
        this.props.saveToBackend
      );
    }
  }

  componentWillUnmount() {
    if (this._contentChoiceNode) {
      this._contentChoiceNode.removeEventListener(
        "input",
        this._handleContentChoiceChange
      );
      // $FlowFixMe already checked
      this._contentChoiceNode.removeEventListener(
        "blur",
        this.props.saveToBackend
      );
    }
  }

  render() {
    return (
      <div className="ChoiceBuilderComponent">
        <div className="ChoiceBuilderComponent-Choice">
          <UI_Choice
            {...this.props}
            setContentChoiceNode={this._setContentChoiceNode}
            index={this.props.choiceNumber}
          />
        </div>
        <div
          onClick={this.props.addChoice}
          style={this._getEditorStyles()}
          className="ChoiceBulderComponent-Edit ChoiceBulderComponent-Add"
        >
          +
        </div>
        <div
          onClick={this.props.removeChoice}
          style={this._getEditorStyles()}
          className="ChoiceBulderComponent-Edit ChoiceBulderComponent-Remove"
        >
          -
        </div>
        <div className="ChoiceBulderComponent-DragIcon">
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-ellipsis-v"
            aria-hidden="true"
          />
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-ellipsis-v"
            aria-hidden="true"
          />
        </div>
      </div>
    );
  }
}

const SortableChoiceItem = SortableElement(ChoiceBuilderComponent);

export default withStyles(SortableChoiceItem);
