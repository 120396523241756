//@flow
import ReactGA from "react-ga";

export const clientTrackerName = "client";
let customGAInitialized = false;

export function initWithDefaultWispformTrackingID() {
  ReactGA.initialize("UA-91428321-1");
  return ReactGA;
}

export function initWithCustomTrackingId(trackingID: string) {
  customGAInitialized = true;
  ReactGA.initialize(
    [
      {
        trackingId: "UA-91428321-1"
      },
      {
        trackingId: trackingID,
        gaOptions: { name: clientTrackerName }
      }
    ],
    { alwaysSendToDefaultTracker: false }
  );
  return ReactGA;
}

export function isCustomGAInitialized() {
  return customGAInitialized;
}

export default ReactGA;
