//@flow
export const CLOSE_ONBOARDING_BACKGROUND = "CLOSE_ONBOARDING_BACKGROUND";
export const START_ONBOARDING_PROCESS = "START_ONBOARDING_PROCESS";
export const SHARE_TOOLTIP_CLOSE = "SHARE_TOOLTIP_CLOSE";

export type actionType =
  | closeOnboardingBackgroundType
  | startOnboardingProcessType;

type closeOnboardingBackgroundType = {|
  type: typeof CLOSE_ONBOARDING_BACKGROUND
|};
export function closeOnboardingBackground() {
  return {
    type: CLOSE_ONBOARDING_BACKGROUND
  };
}

type startOnboardingProcessType = {|
  type: typeof START_ONBOARDING_PROCESS
|};
export function startOnboardingProcess() {
  return {
    type: START_ONBOARDING_PROCESS
  };
}

export function closeShareButtonTooltip() {
  return {
    type: SHARE_TOOLTIP_CLOSE
  };
}
