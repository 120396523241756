//@flow
import React, { Component } from "react";
import QuestionType from "../../Components/QuestionType";
import { add_question_type, add_thankyou_page } from "../../States/actions";
import { connect } from "react-redux";
import questionTypes, {
  type QuestionTypes,
  type questionType,
  QuestionTypeEnum
} from "../../../QuestionTypes";
import Scrollable from "../../../../Component/Scrollable";
import "./style.css";
import PropTypes from "prop-types";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {
  hasThankyouPage,
  hasPaymentType,
  getAllQuestions,
  hasSubscriptionType,
  hasMoneyChargingType
} from "../../../FormBuilderPage/States/selectors";
import {
  getUserInfo,
  type userInfoType,
  isPremium
} from "../../../../Library/UserInfo";
import { safeGet, toArray } from "../../../../Library/Util";
import { enableSubscriptionQuestionType } from "../../../../Library/GateKeeper/builderViewerGateKeeper";
import WithFormConfig, {
  type WithFormConfigInjectedProps
} from "../../../../Helper_HOC/WithFormConfig";

type Props = {|
  ...WithFormConfigInjectedProps,
  firstQuestionType: QuestionTypes,
  hasThankyouPage: boolean,
  hasPaymentType: boolean,
  hasSubscriptionType: boolean,
  hasMoneyChargingType: boolean,
  last_edited_question: number,
  allQuestions: Array<questionType>,
  add_question_type: (QuestionTypes, number) => void,
  add_thankyou_page: () => void,
  hideSelector: () => void
|};
type State = {||};
class QuestionTypeContainer extends Component<Props, State> {
  constructor(props) {
    super(props);
    //$FlowFixMe
    this.QuestionType = new questionTypes();
  }

  shouldShowWelcomePageType() {
    return !this._hasQuestionTypeCreated(QuestionTypeEnum.WelcomePage);
  }

  addQuestion(question_type, last_edited_question) {
    this.props.add_question_type(question_type, last_edited_question);
    this.props.hideSelector();
  }

  addThankyouPage = () => {
    this.props.add_thankyou_page();
    this.props.hideSelector();
  };

  _renderThankyouPageType = () => {
    return (
      <QuestionType
        index={0}
        onClick={this.addThankyouPage}
        Type="ThankyouPage"
      />
    );
  };

  renderWelcomePageType() {
    const { add_question_type, last_edited_question } = this.props;
    if (this.shouldShowWelcomePageType())
      return (
        <QuestionType
          index={0}
          onClick={() => {
            this.addQuestion("WelcomePage", last_edited_question);
          }}
          Type="WelcomePage"
        />
      );
    else return "";
  }

  _hasQuestionTypeCreated(questionType: QuestionTypes) {
    return toArray(this.props.allQuestions).some(
      question => question.type === questionType
    );
  }

  renderQuestionTypesIcon() {
    const { add_question_type, last_edited_question } = this.props;
    let renderQuestionIconArray = [];
    //$FlowFixMe
    let questionTypes = this.QuestionType.types;
    let index = 1;
    for (let questionType in questionTypes) {
      if (
        questionTypes.hasOwnProperty(questionType) &&
        questionType != QuestionTypeEnum.WelcomePage &&
        questionType != QuestionTypeEnum.ThankyouPage &&
        (questionType != QuestionTypeEnum.Payment ||
          !this.props.hasPaymentType) &&
        (questionType != QuestionTypeEnum.Subscription ||
          (!this.props.hasSubscriptionType &&
            enableSubscriptionQuestionType())) &&
        safeGet(
          _ => questionTypes[questionType].hideInQuestionSelector !== true
        )
      ) {
        const type_name = questionTypes[questionType].typeName;
        renderQuestionIconArray.push(
          <QuestionType
            index={index++}
            onClick={() => {
              this.addQuestion(type_name, last_edited_question);
            }}
            Type={questionTypes[questionType].typeName}
          />
        );
      }
    }
    return renderQuestionIconArray;
  }

  _isThankyouPageSupported = () => {
    return !this.props.formConfig.notSupportedContentTypes.includes(
      QuestionTypeEnum.ThankyouPage
    );
  };

  render() {
    return (
      <div
        key={1}
        onClick={e => e.stopPropagation()}
        className="QuestionTypeContainer-Wrapper"
      >
        <div className="QuestionTypeContainer">
          <Scrollable>
            {this._isThankyouPageSupported() && this._renderThankyouPageType()}
            {this.renderQuestionTypesIcon()}
            {this.renderWelcomePageType()}
          </Scrollable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    add_question_type: (QuestionType, index) => {
      dispatch(add_question_type(QuestionType, index));
    },
    add_thankyou_page: () => {
      dispatch(add_thankyou_page());
    }
  };
}

function mapStateToProps(state) {
  const { last_edited_question, questions } = state.Question;
  return {
    last_edited_question: last_edited_question,
    firstQuestionType: questions && questions[0] ? questions[0].type : "",
    hasThankyouPage: hasThankyouPage(state.Question),
    hasPaymentType: hasPaymentType(state.Question),
    allQuestions: getAllQuestions(state.Question),
    hasSubscriptionType: hasSubscriptionType(state.Question),
    hasMoneyChargingType: hasMoneyChargingType(state.Question)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithFormConfig(QuestionTypeContainer));
