//@flow
import * as React from "react";
import uniqid from "uniqid";
import {
  UICoreBox,
  UICoreButton,
  UICoreText,
  UICoreModal,
  UICoreInput
} from "../../../../../Component/UICore";
import { toArray } from "../../../../../Library/Util";
import {
  updateQuestionContent,
  update_calculator_config
} from "../../../States/actions";
import {
  getThankyouPages,
  getThankyouPagesCalculatorConfig
} from "../../../States/selectors";
import { connect } from "react-redux";
import CalculatorConfigResult from "./../CalculatorConfigResult";
import "./style.css";

import { type thankyouPageCalculatorConfigType } from "../../../../../FlowTypes/thankyouPageCalculatorConfigType";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";

type Props = {|
  ...InjectedProps,
  updateCalculatorConfig: (
    updatedCalculatorConfig: thankyouPageCalculatorConfigType
  ) => void,
  calculatorConfig: thankyouPageCalculatorConfigType
|};

type State = {|
  showModal: boolean,
  userInput: string
|};

class CalculatorConfig extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
      userInput: ""
    };
  }

  _addNewResult = name => {
    const currentConfigResults = toArray(
      this.props.calculatorConfig && this.props.calculatorConfig.results
    );
    this.props.updateCalculatorConfig({
      ...this.props.calculatorConfig,
      results: [
        ...currentConfigResults,
        {
          name: name,
          id: "calc_result_" + uniqid.time(),
          type: null,
          formula: null,
          condition: null
        }
      ]
    });
  };

  _updateResult = (resultName, newResult) => {
    let newCalculatorConfig = this.props.calculatorConfig;
    if (newCalculatorConfig && newCalculatorConfig.results) {
      newCalculatorConfig.results = newCalculatorConfig.results.map(result => {
        if (result.name === resultName) {
          return newResult;
        } else {
          return result;
        }
      });
      this.props.updateCalculatorConfig(newCalculatorConfig);
    }
  };

  _deleteResult = resultName => {
    let newCalculatorConfig = this.props.calculatorConfig;
    if (newCalculatorConfig && newCalculatorConfig.results) {
      newCalculatorConfig.results = newCalculatorConfig.results.filter(
        result => {
          return result.name !== resultName;
        }
      );
      this.props.updateCalculatorConfig(newCalculatorConfig);
    }
  };

  _renderCalculatorVariables() {
    return (
      this.props.calculatorConfig &&
      this.props.calculatorConfig.results &&
      this.props.calculatorConfig.results.map(result => {
        if (result) {
          return (
            <CalculatorConfigResult
              result={result}
              updateResult={(resultName, result) =>
                this._updateResult(resultName, result)
              }
              deleteResult={(resultName, result) =>
                this._deleteResult(resultName)
              }
              {...this.props}
            />
          );
        }
      })
    );
  }

  _renderNameModal() {
    return (
      this.state.showModal && (
        <UICoreModal
          size="md"
          dismissText=""
          onDismiss={_ => {
            this.setState({ showModal: false });
            // this.props.saveToBackend();
          }}
          header={"Create a variable"}
          body={
            <UICoreBox
              name="Body"
              paddingLeft="sm"
              paddingRight="sm"
              paddingBottom="md"
              paddingTop="md"
              direction="column"
            >
              <UICoreBox paddingBottom="sm">
                <UICoreText overflow="wrap">
                  Provide a name for your variable
                </UICoreText>
              </UICoreBox>

              <UICoreBox paddingBottom="sm">
                <UICoreInput
                  autoFocus={true}
                  placeholder={"Name of variable"}
                  value={this.state.userInput}
                  onChange={(_, value) => {
                    this.setState({ userInput: value });
                  }}
                />
              </UICoreBox>

              <UICoreBox>
                <UICoreButton
                  onClick={() => {
                    if (
                      this.props.calculatorConfig &&
                      this.props.calculatorConfig.results &&
                      this.props.calculatorConfig.results.length &&
                      this.props.calculatorConfig.results.length >= 5
                    ) {
                      this.props.showNotification(
                        "error",
                        "You have reached the maximum number of variables",
                        "We support a maximum of 5 calculator variables. Please reach out to admin@wispform.com if this continues to be a problem for you."
                      );
                    } else {
                      if (this.state.userInput === "") {
                        this.props.showNotification(
                          "error",
                          "You must enter a variable name",
                          "Please reach out to admin@wispform.com if this continues to be a problem for you."
                        );
                      } else {
                        this._addNewResult(this.state.userInput);
                        this.setState({ userInput: "", showModal: false });
                      }
                    }
                  }}
                  size="md"
                >
                  Create Variable
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    );
  }

  render() {
    return (
      <UICoreBox>
        <UICoreBox
          direction="row"
          justifyContent="between"
          alignItems="center"
          marginBottom="sm"
        >
          <UICoreText color="black" size="xs" weight="bold">
            Calculator Results
          </UICoreText>
          <UICoreButton
            size="sm"
            color="grey"
            onClick={e => {
              e.stopPropagation();
              this.setState({ showModal: true });
              // this._addNewResult();
            }}
          >
            {" + "}
          </UICoreButton>
        </UICoreBox>
        {this._renderNameModal()}
        {this._renderCalculatorVariables()}
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    thankyouPages: getThankyouPages(state.Question),
    calculatorConfig: getThankyouPagesCalculatorConfig(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(updateQuestionContent(pageNumber, contents));
    },
    updateCalculatorConfig: (
      updatedCalculatorConfig: thankyouPageCalculatorConfigType
    ) => {
      dispatch(update_calculator_config(updatedCalculatorConfig));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(CalculatorConfig));
