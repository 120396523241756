//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "../../Component/UICore";
import { logNewUserOnboarding } from "../../Library/Logger";
import {
  FacebookShareCount,
  GooglePlusShareCount,
  LinkedinShareCount,
  PinterestShareCount,
  VKShareCount,
  OKShareCount,
  RedditShareCount,
  TumblrShareCount,
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  PinterestShareButton,
  VKShareButton,
  OKShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  RedditShareButton,
  EmailShareButton,
  TumblrShareButton,
  LivejournalShareButton,
  MailruShareButton,
  ViberShareButton,
  WorkplaceShareButton,
  LineShareButton,
  WeiboShareButton,
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LinkedinIcon,
  PinterestIcon,
  VKIcon,
  OKIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  MailruIcon,
  EmailIcon,
  LivejournalIcon,
  ViberIcon,
  WorkplaceIcon,
  LineIcon
} from "react-share";
type Props = {|
  link: string,
  title: string
|};
type State = {||};

function createLoggingFunction(eventName) {
  return () => logNewUserOnboarding(eventName);
}
class SocialShareIcons extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox width="100%" wrap="wrap" direction="row">
        <UICoreBox
          onClick={createLoggingFunction("shareButtonFacebookClicked")}
          margin="xm"
        >
          <FacebookShareButton url={this.props.link} quote={this.props.title}>
            <FacebookIcon size={32} round />
          </FacebookShareButton>
        </UICoreBox>
        <UICoreBox
          onClick={createLoggingFunction("shareButtonTwitterClicked")}
          margin="xm"
        >
          <TwitterShareButton url={this.props.link} title={this.props.title}>
            <TwitterIcon size={32} round />
          </TwitterShareButton>
        </UICoreBox>
        <UICoreBox
          onClick={createLoggingFunction("shareButtonWhatsappClicked")}
          margin="xm"
        >
          <WhatsappShareButton url={this.props.link} title={this.props.title}>
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </UICoreBox>
        <UICoreBox
          onClick={createLoggingFunction("shareButtonLinkedinClicked")}
          margin="xm"
        >
          <LinkedinShareButton url={this.props.link} title={this.props.title}>
            <LinkedinIcon size={32} round />
          </LinkedinShareButton>
        </UICoreBox>
        <UICoreBox
          onClick={createLoggingFunction("shareButtonRedditClicked")}
          margin="xm"
        >
          <RedditShareButton url={this.props.link} title={this.props.title}>
            <RedditIcon size={32} round />
          </RedditShareButton>
        </UICoreBox>
        <UICoreBox
          onClick={createLoggingFunction("shareButtonWorkplaceClicked")}
          margin="xm"
        >
          <WorkplaceShareButton url={this.props.link} quote={this.props.title}>
            <WorkplaceIcon size={32} round />
          </WorkplaceShareButton>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default SocialShareIcons;
