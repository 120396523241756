//@flow
import FormComponent from "../../Components/CellComponents/FormComponent";
import FormDataUI from "../../Components/DataUIComponents/FormDataUI";
import {
  type DataType,
  type textCellType,
  type tableDatasType,
  type tableSchemaColumnType,
  type tableDataRowType,
  type showDialogFunctionType
} from "../index";
import { Store } from "../../../../App";
import {
  getContactsInRow,
  getCurrentTableID
} from "../../State/TableState/selectors";
import { isEmptyArray } from "../../../../Library/Util";
import { getUserInfo } from "../../../../Library/UserInfo";
import { domainName } from "../../../../config";
import { getWisptableFormViewURL } from "../../Components/CellComponents/FormComponent/SendForm";
import { sendFormsToContacts as apiSendFormsToContacts } from "../../api";

const FormType = {
  icon: "fa fa-file-text",
  cellComponent: FormComponent,
  dataUIComponent: FormDataUI,
  displayName: "Form",
  sortFunction: null,
  filter: null,
  columnActions: {
    sendFormToContacts: {
      actionName: "Send to all contacts in table",
      actionIconClass: "fa fa-share-square",
      actionFunction: sendFormToContactsFunc
    }
  }
};

function sendFormToContactsFunc(
  rows: tableDatasType,
  column: tableSchemaColumnType,
  showDialog?: showDialogFunctionType
) {
  const WispTableReduxStore = Store.getState().WispTable;
  const columnID = column.id;
  //get publisher name
  const userInfo = getUserInfo();
  if (!userInfo) return;
  const publisherName = userInfo.name;
  //get form tableID
  if (!column.config || !column.config.formID) return;
  const formID = String(column && column.config.formID);
  //get current tableID
  const currentTableID = getCurrentTableID(WispTableReduxStore);
  if (!currentTableID) return;
  //construct receivers info [email, name, url]
  const receiversInfo = rows
    .map((row: tableDataRowType) => {
      const rowID = row.id;
      const contactCells = getContactsInRow(WispTableReduxStore)(rowID);
      if (isEmptyArray(contactCells)) return null;
      const email = contactCells[0].email;
      const name = contactCells[0].name;
      const contactID = contactCells[0].id;
      const url = getWisptableFormViewURL(
        publisherName,
        domainName,
        formID,
        rowID,
        columnID,
        contactID,
        currentTableID
      );
      return [email, name, url];
    })
    .filter(info => info != null);

  showDialog &&
    showDialog({
      header: "Send to contacts",
      bodyString: `You are about to send wispform to all ${
        receiversInfo.length
      } contacts visible in this table`,
      acceptedDisplayName: "Sent 👌",
      acceptDisplayName: "Send",
      acceptFailedDisplayName: "Error, please try again",
      //$FlowFixMe: fuck flow! null has been filtered
      onAccept: () => apiSendFormsToContacts(receiversInfo),
      rejectDisplayName: "Cancel"
    });
}

export default FormType;
