//@flow
import * as React from "react";
import ShortTextInput, { kInputType } from "../ShortTextInput";
import { type questionComponentTypes } from "../../../FlowTypes/questionComponentTypes";
import { autoCompleteEnum } from "../../../FlowTypes/autoCompleteTypes";

type Props = {|
  ...questionComponentTypes
|};
type State = {||};

class UI_Email extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <ShortTextInput
        inputType={kInputType.email}
        autoComplete={autoCompleteEnum.email}
        {...this.props}
      />
    );
  }
}

export default UI_Email;
