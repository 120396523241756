// @flow
import {
  type logicJumpType,
  type branchType,
  type jointLogicType,
  DEFAULT_END
} from "../../FlowTypes/jumpLogicTypes";
import { type calculatorConditionType } from "../../FlowTypes/thankyouPageCalculatorConfigType";
import {
  type ComperatorsType,
  type comperatorNameTypes
} from "../../FlowTypes/jumpLogicComperatorTypes";
import { toArray, nonNullOrThrow, isNullOrUndefined } from "../../Library/Util";
import Comperators from "./comperators";
import {
  type questionType,
  type QuestionTypes
} from "../../Pages/QuestionTypes";
import { type questionIDType } from "../../Pages/WispformTypings";
import { Store } from "../../App";
import { getTotalScore } from "../../Pages/Form/States/Answers/selectors";
import { isTargetFormScore } from "../../Library/JumpLogic";

export function evaluateBooleanExpression(
  conditions: Array<boolean>,
  jointLogics: Array<jointLogicType>
): boolean {
  /**
   * && takes precedence over ||
   */

  //step1: zip conditions and joinLogics

  let conditionsWithLogicsString = jointLogics.reduce(
    (conditionsWithLogicsString, joinLogic, joinLogicIndex) => {
      conditionsWithLogicsString += String(conditions[joinLogicIndex]);
      conditionsWithLogicsString += joinLogic;
      return conditionsWithLogicsString;
    },
    ""
  );

  conditionsWithLogicsString += String(conditions[conditions.length - 1]);
  return eval(conditionsWithLogicsString);
}

export function getJumptoQuestionID(
  currentQuestion: questionType,
  answers: Array<any>,
  currentAnswer: any,
  allQuestions: Array<questionType>
): ?questionIDType {
  const logicJump: logicJumpType = nonNullOrThrow(currentQuestion.jumpLogic);
  if (!logicJump.branches) return logicJump.default;
  //go through each branch
  //return the jump-to questionID of the first branch whose conditions are met
  const firstMatchedBranch = logicJump.branches.find(branch =>
    isConditionSatisfied(branch, answers, currentAnswer)
  );
  if (firstMatchedBranch) {
    return firstMatchedBranch.jumpToQuestionID;
  }

  //no branches matched
  if (logicJump.default) {
    return logicJump.default;
  } else {
    //default not set, jump to next question in allQuestions
    const currentQuestionIndexInAllQuestions = allQuestions.findIndex(
      q => q.question_id === currentQuestion.question_id
    );
    if (currentQuestionIndexInAllQuestions === -1) return null;
    const nextQuestion = allQuestions[currentQuestionIndexInAllQuestions + 1];
    if (!nextQuestion) return null;
    return nextQuestion.question_id;
  }
}

export function isConditionSatisfied(
  branch: branchType | calculatorConditionType, // One for logic jump, other for calculator condition
  answers: Array<any>,
  currentAnswer: any
) {
  const conditions = branch.conditionRule
    ? branch.conditionRule
    : branch.conditions;
  const jointLogics = branch.jointLogics;

  const conditionResults = toArray(conditions).map(condition => {
    let targetQuestionUserAnswer;

    //get user's answer to target question
    if (isTargetFormScore(condition.targetQuestionID)) {
      targetQuestionUserAnswer = {
        answer: getTotalScore(Store.getState().Answers)
      };
    } else if (currentAnswer.questionID === condition.targetQuestionID) {
      targetQuestionUserAnswer = currentAnswer;
    } else {
      targetQuestionUserAnswer = answers.find(
        ans => ans.question_id === condition.targetQuestionID
      );
    }

    if (
      !isNullOrUndefined(targetQuestionUserAnswer) &&
      compare(
        nonNullOrThrow(targetQuestionUserAnswer).answer,
        condition.targetAnswer,
        condition.comperator,
        currentAnswer.type
      )
    ) {
      return true;
    } else {
      return false;
    }
  });
  return evaluateBooleanExpression(conditionResults, jointLogics);
}

function compare(
  userAnswer,
  targetAnswer,
  comperatorName: comperatorNameTypes,
  questionType: QuestionTypes
): boolean {
  let userAnswerValueValue = userAnswer;

  if (Comperators[comperatorName]) {
    return Comperators[comperatorName].compare(
      userAnswerValueValue,
      targetAnswer
    );
  } else {
    //default comperator is to simply do a equality comparism
    return Comperators.is.compare(userAnswerValueValue, targetAnswer);
  }
}
