//@flow
import * as React from "react";
import { type questionType } from "../../Pages/QuestionTypes";
import {
  type questionMediaType,
  questionMediaTypeEnum
} from "../../FlowTypes/questionTypes";
import "./style.css";
import { UICoreImage, UICoreBox } from "../UICore";
import UICoreVideo from "../UICore/UICoreVideo";
import { thankyouPage } from "../../Pages/FormBuilderPage/States/reducer";
import { type thankyouPageType } from "../../Pages/WispformTypings";
import { isIos } from "../../Library/DOM";
import ReactDOM from "react-dom";
import { toUnNullable } from "../../Library/Util";
type Props = {|
  question?: questionType,
  thankyouPage?: thankyouPageType,
  questionMedia?: questionMediaType,
  maxHeight?: number | string,
  maxWidth?: number | string
|};
type State = {||};

/**
 * We are in the process of migrating media rendring away from question.image to question.media
 *
 * To ensure backward compatibility, we want to read question.media first, if empty, fallback to  question.image
 */

export function getMediaSpec(
  question: ?questionType,
  thankyouPage: ?thankyouPageType,
  questionMedia: ?questionMediaType //for outcome page
): ?questionMediaType {
  if (question && question.media) {
    return question.media;
  } else if (question && question.image) {
    return {
      type: questionMediaTypeEnum.image,
      src: question.image
    };
  } else if (thankyouPage && thankyouPage.mediaSpec) {
    return thankyouPage.mediaSpec;
  } else if (thankyouPage && thankyouPage.backgroundImage) {
    return {
      type: questionMediaTypeEnum.image,
      src: thankyouPage.backgroundImage
    };
  } else if (questionMedia) {
    return questionMedia;
  }
  return null;
}

export function hasMediaSpec(
  question: ?questionType,
  thankyouPage: ?thankyouPageType,
  questionMedia: ?questionMediaType
): boolean {
  const mediaSpec = getMediaSpec(question, thankyouPage, questionMedia);
  return mediaSpec !== null;
}

class QuestionMediaRender extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _imageNode: ?HTMLDivElement;

  componentDidMount() {
    this.readjustWidthandHeight();
  }

  componentDidUpdate() {
    this.readjustWidthandHeight();
  }

  readjustWidthandHeight = () => {
    const width = this._imageNode && this._imageNode.clientWidth;
    const mediaSpec = getMediaSpec(
      this.props.question,
      this.props.thankyouPage,
      this.props.questionMedia
    );
    if (
      this._imageNode &&
      width &&
      mediaSpec &&
      mediaSpec.originalHeight &&
      mediaSpec.originalWidth
    ) {
      try {
        toUnNullable(this._imageNode).style.height = `${String(
          (width * mediaSpec.originalHeight) / mediaSpec.originalWidth
        )}px`;
        if (
          width &&
          mediaSpec &&
          mediaSpec.originalHeight &&
          mediaSpec.originalWidth &&
          (width * mediaSpec.originalHeight) / mediaSpec.originalWidth > 400
        ) {
          let originalWidth: number = mediaSpec.originalWidth;
          let originalHeight: number = mediaSpec.originalHeight;

          toUnNullable(this._imageNode).style.width = `${String(
            (400 * mediaSpec.originalWidth) / mediaSpec.originalHeight
          )}px`;
          toUnNullable(this._imageNode).style.height = `${String(
            (((400 * originalWidth) / originalHeight) * originalHeight) /
              originalWidth
          )}px`;
        }
      } catch (e) {}
    }
  };

  render() {
    const mediaSpec = getMediaSpec(
      this.props.question,
      this.props.thankyouPage,
      this.props.questionMedia
    );
    if (mediaSpec) {
      switch (mediaSpec.type) {
        case questionMediaTypeEnum.image:
          return (
            <UICoreImage
              ref={ref => {
                //$FlowFixMe
                this._imageNode = ReactDOM.findDOMNode(ref);
              }}
              width="100%"
              maxHeight={this.props.maxHeight}
              maxWidth={this.props.maxWidth}
              src={mediaSpec.src}
            />
          );
        case questionMediaTypeEnum.video:
          return (
            <UICoreVideo
              src={mediaSpec.src}
              width="100%"
              aspect={16 / 9}
              muted={false}
              loop={true}
              controls={true}
            />
          );
        case questionMediaTypeEnum.youtube:
          return (
            <div className="embed-youtube">
              <iframe
                width="560"
                height="315"
                src={"https://www.youtube.com/embed/" + mediaSpec.src}
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          );

        case questionMediaTypeEnum.vimeo:
          return (
            <div className="embed-youtube">
              <iframe
                src={"https://player.vimeo.com/video/" + mediaSpec.src}
                width="640"
                height="360"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          );

        default:
          return null;
      }
    }
    return null;
  }
}
export default QuestionMediaRender;
