//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreBox,
  UICoreText,
  UICoreInput,
  UICoreButton,
  UICoreModal
} from "../../../../Component/UICore";
import { logFeatureEvent } from "../../../../Library/Logger";
import SocialShareIcons from "../../SocialShareIcons";
import BrowserUI from "../BrowserUI";
import ShareSectionSkeleton from "../ShareSectionSkeleton";
import HiddenFieldSection from "../../../FormBuilderPage/Components/FormSetting/HiddenFieldSection";
import { connect } from "react-redux";
import { saveToBackend } from "../../../FormBuilderPage/States/actions";
import { hasHiddenFieldVariables } from "../../../FormBuilderPage/States/selectors";

type Props = {|
  shareLink: string,
  hasHiddenFieldVariables: boolean,
  saveToBackend: () => void
|};
type State = {|
  copied: boolean,
  showHiddenFieldConfig: boolean,
  iframeLink: string // We need to copy shareLink explicitly to this state to prevent iframe being refreshed on every input
|};
class LinkSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    copied: false,
    showHiddenFieldConfig: false,
    iframeLink: this.props.shareLink
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      !prevProps.hasHiddenFieldVariables &&
      this.props.hasHiddenFieldVariables
    ) {
      this.setState({
        iframeLink: this.props.shareLink
      });
    }
  }

  _inputElement: ?HTMLInputElement;

  componentDidMount() {
    logFeatureEvent("share/linkSectionDisplayed");
  }

  _handleSharelinkCopy = () => {
    logFeatureEvent("share/linkcopied");
  };

  _handleCopyButtonClick = (e: SyntheticEvent<>) => {
    e.preventDefault();
    e.stopPropagation();
    if (this._inputElement && this._inputElement.select) {
      this._inputElement.select();
    }
    try {
      document.execCommand("copy");
      this._inputElement && this._inputElement.blur();
      logFeatureEvent("share/linkcopied");
      setTimeout(() => {
        this.setState({
          copied: true
        });
      }, 300);
    } catch (err) {
      console.error("cant execute copy command");
    }
  };

  render() {
    return [
      <ShareSectionSkeleton
        settingColumn={
          <UICoreBox
            paddingLeft="sm"
            paddingRight="sm"
            width="100%"
            height="250px"
            name="section-link"
          >
            <UICoreBox name="copylink">
              <UICoreText color="Grey">Share via link</UICoreText>
              <UICoreBox marginTop="sm">
                <UICoreInput
                  clickToSelect={true}
                  autoSelect={true}
                  size="xs"
                  onChange={e => {}}
                  value={this.props.shareLink}
                  onCopy={this._handleSharelinkCopy()}
                  getInputRef={e => {
                    this._inputElement = e;
                  }}
                />
              </UICoreBox>
              <UICoreBox marginTop="sm">
                <UICoreButton
                  key={String(this.state.copied)}
                  onClick={this._handleCopyButtonClick}
                  size="md"
                >
                  {this.state.copied ? "Copied" : "Copy link"}
                </UICoreButton>
              </UICoreBox>

              <UICoreBox marginTop="sm">
                {this.props.hasHiddenFieldVariables ? (
                  <UICoreButton
                    key="0"
                    onClick={() => {
                      this.setState({ showHiddenFieldConfig: true });
                    }}
                    color="lightBlue"
                    size="md"
                    DEPRECATED_font_size="xs"
                  >
                    Pass Hidden Field via URL
                  </UICoreButton>
                ) : (
                  <UICoreButton
                    key="1"
                    onClick={() => {
                      this.setState({ showHiddenFieldConfig: true });
                    }}
                    color="grey"
                    size="md"
                    DEPRECATED_font_size="xs"
                  >
                    Pass Hidden Field via URL
                  </UICoreButton>
                )}
              </UICoreBox>
            </UICoreBox>

            <UICoreBox marginTop="xl" name="social network">
              <UICoreText color="Grey">Share via social network</UICoreText>
              <UICoreBox direction="row" marginTop="xm">
                <SocialShareIcons
                  link={this.props.shareLink}
                  title="Please help me complete this form: "
                />
              </UICoreBox>
            </UICoreBox>
          </UICoreBox>
        }
        browserView={
          <iframe className="Share-frame" src={this.state.iframeLink} />
        }
      />,
      this.state.showHiddenFieldConfig && (
        <UICoreModal
          onDismiss={() => {
            this.setState({
              showHiddenFieldConfig: false,
              iframeLink: this.props.shareLink
            });
            this.props.saveToBackend();
          }}
          header="Set Hidden Fields"
          body={
            <UICoreBox paddingLeft="xm" height="300px">
              <HiddenFieldSection
                config={{
                  showValueEditor: true
                }}
              />
            </UICoreBox>
          }
          size="md"
          dismissText="Save"
        ></UICoreModal>
      )
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hasHiddenFieldVariables: hasHiddenFieldVariables(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveToBackend: () => {
      dispatch(saveToBackend());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkSection);
