//@flow
import * as React from "react";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { explode, isNumber, isNonEmptyString } from "../../../Library/Util";
import "./style.css";
import { UICoreBox, UICoreText } from "../../UICore";
import { applyFlashAnimation } from "../../Animations/Animations";
import MobileView from "../../Views/Mobile";
import DesktopView from "../../Views/Desktop";
import { isMobile } from "../../Views/helper";
type Props = {|
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  styles: formStyleType,
  onQuestionComplete?: (?string) => mixed,
  onQuestionUpdate?: (?string) => mixed,
  isInBuilder: boolean,
  contents: {|
    steps: number,
    leftUnit?: string,
    rightUnit?: string
  |},
  answer?: { answer: string }
|};
type State = {|
  selectedIndex: ?number
|};

type sliderBarRecType = {|
  left: number,
  right: number,
  length: number
|};

const animationTime = 400;
// From 0 - 10
const defaultScaleNumber = 11;
const sliderWidth = 40;

const getInitialSelectedIndex = answer => {
  try {
    if (answer && isNonEmptyString(answer.answer)) {
      return parseInt(answer.answer);
    } else return null;
  } catch (error) {
    return null;
  }
};

class UI_OpinionScale extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    selectedIndex: getInitialSelectedIndex(this.props.answer)
  };

  _mobileTooltip: ?HTMLElement = null;

  _mobileSlider: ?HTMLElement = null;

  _mobileSliderContainer: ?HTMLElement = null;

  _mobileSliderBar: ?HTMLElement = null;

  _initialTouchPointPosX: number = 0;

  _initialSliderLeft: number = 0;

  _mobileSliderBarRec: sliderBarRecType = {
    left: 0,
    right: 0,
    length: 0
  };

  componentDidMount() {
    this._initSliderBarRec();
    this._mobileSlider &&
      this._mobileSlider.addEventListener(
        "touchstart",
        this._handleSliderTouchStart
      );
    this._mobileSlider &&
      this._mobileSlider.addEventListener(
        "touchmove",
        this._handleSliderTouchMove
      );
    this._mobileSlider &&
      this._mobileSlider.addEventListener(
        "touchend",
        this._handleSliderTouchEnd
      );
    this._mobileSliderContainer &&
      this._mobileSliderContainer.addEventListener("touchmove", (e: Event) => {
        e.stopPropagation();
        e.preventDefault();
      });
  }

  _getSteps = () => {
    if (this.props.contents && isNumber(this.props.contents.steps)) {
      return this.props.contents.steps;
    } else {
      return defaultScaleNumber;
    }
  };

  _initSliderBarRec = () => {
    if (!this._mobileSliderBar) return;
    const sliderBarRec = this._mobileSliderBar.getBoundingClientRect();
    this._mobileSliderBarRec.left = sliderBarRec.left;
    this._mobileSliderBarRec.length = sliderBarRec.width;
    this._mobileSliderBarRec.right = sliderBarRec.left + sliderBarRec.width;
  };

  _getSelectedIndex = () => {
    return this.state.selectedIndex;
  };

  _handleSliderTouchStart = (e: Event) => {
    // $FlowFixMe
    this._initialTouchPointPosX = e.targetTouches[0].clientX;
    this._initialSliderLeft =
      // $FlowFixMe
      this._mobileSlider.getBoundingClientRect().left -
      this._mobileSliderBarRec.left;
  };

  _getSliderClientX = (movement: number): number => {
    //for flow typing
    if (!this._mobileSliderBarRec || !this._mobileSlider) return 0;

    const targetSliderLeft = this._initialSliderLeft + movement;
    // moved out of slider bar
    if (targetSliderLeft < 0) return 0;
    // mover out of slider bar
    if (targetSliderLeft + sliderWidth > this._mobileSliderBarRec.length)
      return this._mobileSliderBarRec.length - sliderWidth;
    // moved within range
    return targetSliderLeft;
  };

  _updateSelectedIndex = (sliderLeft: number) => {
    const range = this._mobileSliderBarRec.length - sliderWidth;
    const percentage = sliderLeft / range;
    const selectedIndex = Math.floor(percentage * (this._getSteps() - 1));
    if (selectedIndex !== this.state.selectedIndex) {
      this.setState({
        selectedIndex: selectedIndex
      });
    }
  };

  _handleSliderTouchMove = (e: Event) => {
    e.stopPropagation();
    e.preventDefault();

    // Only slider is draggable
    if (e.target != this._mobileSlider) return;
    // $FlowFixMe
    const movement = e.targetTouches[0].clientX - this._initialTouchPointPosX;
    const targetSliderLeft = this._getSliderClientX(movement);
    this._updateSelectedIndex(targetSliderLeft);
    // $FlowFixMe
    e.target.style.transform = `translateX(${targetSliderLeft}px)`;
  };

  _handleSliderTouchEnd = (e: Event) => {
    applyFlashAnimation(this._mobileTooltip, animationTime);
    setTimeout(() => {
      this.props.onQuestionComplete &&
        //$FlowFixMe
        this.props.onQuestionComplete(String(this.state.selectedIndex));
    }, animationTime);
  };

  _handleCellClick = (
    e: SyntheticInputEvent<HTMLDivElement>,
    index: number
  ) => {
    this.setState({
      selectedIndex: index
    });
    applyFlashAnimation(e.currentTarget, animationTime);
    setTimeout(() => {
      this.props.onQuestionComplete &&
        // $FlowFixMe ??
        this.props.onQuestionComplete(String(index));
    }, animationTime);
  };

  _renderCell = () => {
    return explode(this._getSteps()).map((_, index) => {
      const cellColor =
        index == this._getSelectedIndex() ? this.props.answerPrimaryColor : "";
      return this.props.isInBuilder ? (
        <UICoreBox
          width={`${100 / this._getSteps()}%`}
          paddingTop="sm"
          paddingBottom="sm"
          justifyContent="center"
          alignItems="center"
          hexColor={cellColor}
          dangerous_style={{
            borderColor: this.props.answerSecondaryColor,
            borderStyle: "solid",
            borderWidth: "1px",
            borderLeftWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            borderRightWidth: index == this._getSteps() - 1 ? "0px" : "1px"
          }}
        >
          <UICoreText
            fontFamily={this.props.styles && this.props.styles.fontFamily}
            disableSelect={true}
            hexColor={this.props.styles.answer}
          >
            {index}
          </UICoreText>
        </UICoreBox>
      ) : (
        <UICoreBox
          className="UI_OpinionScale-Cell"
          width={`${100 / this._getSteps()}%`}
          paddingTop="sm"
          paddingBottom="sm"
          justifyContent="center"
          alignItems="center"
          onMouseOver={(e: SyntheticInputEvent<HTMLDivElement>) => {
            e.currentTarget.style.backgroundColor = this.props.answerPrimaryColor;
          }}
          onMouseLeave={(e: SyntheticInputEvent<HTMLDivElement>) => {
            e.currentTarget.style.backgroundColor = cellColor;
          }}
          hexColor={cellColor}
          dangerous_style={{
            borderColor: this.props.answerSecondaryColor,
            borderStyle: "solid",
            borderWidth: "1px",
            borderLeftWidth: "0px",
            borderTopWidth: "0px",
            borderBottomWidth: "0px",
            borderRightWidth: index == this._getSteps() - 1 ? "0px" : "1px"
          }}
          onClick={e => this._handleCellClick(e, index)}
        >
          <UICoreText
            fontFamily={this.props.styles && this.props.styles.fontFamily}
            disableSelect={true}
            hexColor={this.props.styles.answer}
          >
            {index}
          </UICoreText>
        </UICoreBox>
      );
    });
  };

  _renderDesktopUI = () => {
    return (
      <UICoreBox>
        <UICoreBox
          direction="row"
          shape="rounded"
          justifyContent="between"
          hexColor={this.props.answerSecondaryColor}
          overflowX="hidden"
          overflowY="hidden"
        >
          {this._renderCell()}
        </UICoreBox>
        {this._renderStartAndEndDescriptionText()}
      </UICoreBox>
    );
  };

  _renderSelectedScaleTooltip = () => {
    return (
      this.state.selectedIndex !== null && (
        <UICoreBox
          innerRef={e => {
            this._mobileTooltip = e;
          }}
          position="absolute"
          top="-60px"
          shape="rounded"
          paddingLeft="sm"
          paddingRight="sm"
          paddingTop="xm"
          paddingBottom="xm"
          justifyContent="center"
          alignItems="center"
          hexColor={this.props.answerPrimaryColor}
          dangerous_style={{ borderRadius: "10px" }}
        >
          <UICoreBox
            className="UI_OpinionScale-tooltip"
            position="absolute"
            top="100%"
            dangerous_style={{
              borderWidth: "6px",
              borderLeftWidth: "8px",
              borderRightWidth: "8px",
              borderStyle: "solid",
              borderColor: this.props.answerPrimaryColor,
              borderLeftColor: "transparent",
              borderRightColor: "transparent",
              borderBottomColor: "transparent"
            }}
          >
            {""}
          </UICoreBox>
          <UICoreText
            weight="bold"
            size="md"
            hexColor={this.props.styles.answer}
          >
            {String(this.state.selectedIndex)}
          </UICoreText>
        </UICoreBox>
      )
    );
  };

  _renderStartAndEndDescriptionText = () => {
    /**
     * Show scaler unit only if:
     * (1) is in mobile view
     * (2) OR has set either leftnit or right unit
     */
    if (
      isMobile() ||
      (this.props.contents &&
        (this.props.contents.leftUnit || this.props.contents.rightUnit))
    ) {
      return (
        <UICoreBox
          paddingTop="md"
          direction="row"
          width="100%"
          justifyContent="between"
        >
          <UICoreText
            weight="bold"
            size="md"
            hexColor={this.props.styles.answer}
            fontFamily={this.props.styles && this.props.styles.fontFamily}
          >
            {(this.props.contents && this.props.contents.leftUnit) || "0"}
          </UICoreText>
          <UICoreText
            weight="bold"
            size="md"
            hexColor={this.props.styles.answer}
            fontFamily={this.props.styles && this.props.styles.fontFamily}
          >
            {(this.props.contents && this.props.contents.rightUnit) ||
              this._getSteps() - 1}
          </UICoreText>
        </UICoreBox>
      );
    } else {
      return null;
    }
  };

  _renderMobileUI = () => {
    return (
      <UICoreBox
        innerRef={e => {
          this._mobileSliderContainer = e;
        }}
        marginTop="md"
        alignItems="center"
        justifyContent="center"
      >
        <UICoreBox
          width="95%"
          marginTop="xl"
          innerRef={e => {
            this._mobileSliderBar = e;
          }}
          hexColor={this.props.styles.answer}
          shape="rounded"
          height="5px"
          position="relative"
        >
          <UICoreBox
            position="absolute"
            top="-17px"
            left="0px"
            width={sliderWidth}
            height={sliderWidth}
            hexColor={this.props.styles.answer}
            shape="circle"
            innerRef={e => {
              this._mobileSlider = e;
            }}
            justifyContent="center"
            alignItems="center"
          >
            {this._renderSelectedScaleTooltip()}
          </UICoreBox>
        </UICoreBox>
        {this._renderStartAndEndDescriptionText()}
      </UICoreBox>
    );
  };

  render() {
    return [
      <MobileView>{this._renderMobileUI()}</MobileView>,
      <DesktopView>{this._renderDesktopUI()}</DesktopView>
    ];
  }
}
export default WithStyles(UI_OpinionScale);
