//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreInput,
  UICoreTooltip
} from "../../../../../Component/UICore";
import { updateQuestionContent } from "../../../../FormBuilderPage/States/actions";
import { connect } from "react-redux";
import "./style.css";
import { stringReverse } from "../../../../../Library/Util";

type opinionScaleQuestionContentsType = {|
  steps?: number,
  leftUnit?: string,
  rightUnit?: string
|};

type Props = {|
  questionIndex: number,
  contents?: opinionScaleQuestionContentsType,
  position: "left" | "right",
  updateQuestionContent: (number, opinionScaleQuestionContentsType) => {}
|};
type State = {||};

class ScalerUnit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleScalerUnitUpdate = (_: any, value: string) => {
    if (this.props.position === "left") {
      this.props.updateQuestionContent &&
        this.props.updateQuestionContent(this.props.questionIndex, {
          ...this.props.contents,
          leftUnit: value
        });
    } else {
      this.props.updateQuestionContent &&
        this.props.updateQuestionContent(this.props.questionIndex, {
          ...this.props.contents,
          rightUnit: value
        });
    }
  };

  _getScalerUnit = () => {
    if (this.props.contents && this.props.position === "left") {
      return this.props.contents.leftUnit || "";
    }
    if (this.props.contents && this.props.position === "right") {
      return this.props.contents.rightUnit || "";
    }
    return "";
  };

  _getDefaultRightLabel = () => {
    if (this.props.contents && this.props.contents.steps) {
      return String(this.props.contents.steps);
    } else {
      return "10";
    }
  };

  _getDefaultLeftLabel = () => {
    return "0";
  };

  _getDefaultLabel = () => {
    return this.props.position === "left"
      ? this._getDefaultLeftLabel()
      : this._getDefaultRightLabel();
  };

  render() {
    return (
      <UICoreBox>
        <UICoreInput
          clickToSelect={true}
          onChange={this._handleScalerUnitUpdate}
          width={80}
          size="xxs"
          placeholder={this._getDefaultLabel()}
          value={String(this._getScalerUnit())}
        />
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(
        updateQuestionContent(
          pageNumber,
          contents,
          true /* should save to server */
        )
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScalerUnit);
