//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreInput,
  UICoreTooltip,
  UICoreButton,
  UICoreModal,
  UICoreText
} from "../../../../../Component/UICore";
import { updateQuestionContent } from "../../../../FormBuilderPage/States/actions";
import { connect } from "react-redux";
import "./style.css";

type fileUploadQuestionContentsType = {|
  imageCompressionMaxSizeInMB: ?number
|};

type Props = {|
  questionIndex: number,
  contents?: fileUploadQuestionContentsType,
  updateQuestionContent: (number, fileUploadQuestionContentsType) => {}
|};
type State = {|
  showModal: boolean
|};

class ImageCompressionButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showModal: false
  };

  _renderButton = () => {
    if (this._getMaxFileSize()) {
      return (
        <UICoreButton
          onClick={() => {
            this.setState({ showModal: true });
          }}
          key={this._getMaxFileSize()}
          color="blue"
          size="sm"
        >
          {`${this._getMaxFileSize()} MB`}
        </UICoreButton>
      );
    } else {
      return (
        <UICoreButton
          onClick={() => {
            this.setState({ showModal: true });
          }}
          key={0}
          color="lightBlue"
          size="sm"
        >
          {" Add "}
        </UICoreButton>
      );
    }
  };

  _handleSizeInput = (_: any, value: string) => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(this.props.questionIndex, {
        ...this.props.contents,
        imageCompressionMaxSizeInMB: parseInt(value)
      });
  };

  _validateSizeInput = (input: string) => {
    if (isNaN(input)) {
      return "Maximum file size needs to be a number.";
    } else if (parseInt(input) > 10 || parseInt(input) < 1) {
      return "Maximum file size needs to be a number between 1 - 10";
    }
    return "";
  };

  _getMaxFileSize = () => {
    if (
      this.props.contents &&
      this.props.contents.imageCompressionMaxSizeInMB &&
      !isNaN(this.props.contents.imageCompressionMaxSizeInMB)
    ) {
      return String(this.props.contents.imageCompressionMaxSizeInMB);
    } else {
      return "";
    }
  };

  _renderModal = () => {
    return (
      this.state.showModal && (
        <UICoreModal
          dismissText="save"
          header="Image Compression"
          onDismiss={() => {
            this.setState({
              showModal: false
            });
          }}
          body={
            <UICoreBox padding="sm">
              <UICoreBox>
                <UICoreText size="xs" overflow="wrap">
                  You can enable image compression when a user uploads an image
                  file.
                </UICoreText>
                <UICoreBox paddingTop="xm">
                  <UICoreText size="xs" overflow="wrap">
                    To enable image compression, set a maximum image size (1 -
                    10 MB) below. Any image larger than this size will be
                    compressed to fit the maximum size.
                  </UICoreText>
                </UICoreBox>
              </UICoreBox>
              <UICoreBox
                paddingBottom="sm"
                direction="row"
                alignItems="center"
                paddingTop="sm"
              >
                <UICoreInput
                  clickToSelect={true}
                  value={this._getMaxFileSize()}
                  placeholder="Currently no size limit applied"
                  size="xxs"
                  width={200}
                  validator={this._validateSizeInput}
                  onChange={this._handleSizeInput}
                ></UICoreInput>
                <UICoreBox marginLeft="xm">
                  <UICoreText size="xxs" color="Grey" weight="bold">
                    MB
                  </UICoreText>
                </UICoreBox>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    );
  };

  render() {
    return [this._renderButton(), this._renderModal()];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(updateQuestionContent(pageNumber, contents));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageCompressionButton);
