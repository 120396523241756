//@flow
import { createSelector } from "reselect";
import { type stateType } from "./reducer";
import WisptableConfig, {
  type columnIDType,
  type tableSchemaColumnType,
  type columnTypes,
  type rowIDType,
  type tableType,
  type tableIDType,
  type tableDatasType,
  type tableDataRowType,
  type tableMetaDataType,
  type sortType,
  type filterType,
  type filterGroupType,
  type filterConjunctionType,
  type contactCellType,
  type wispformIDType
} from "../../Configuration";
import { nullSafe, applyLogic } from "../../../../Library/Util";
import { getRow } from "./helper";

export const getCurrentEditingColumnID = (state: stateType) =>
  state.currentEditingColumnID;

export const getColumns = (state: stateType) => state.tableSchemas.columns;

const getTables = (state: stateType) => state.tables;

export const getTableDatas = (state: stateType) => state.tableDatas;

export const getMetaDatas = (state: stateType): tableMetaDataType =>
  state.tableMetaDatas
    ? state.tableMetaDatas
    : {
        colorGroup: null,
        filterGroup: null,
        sortGroup: null,
        columnWidth: {},
        id: 0
      };

export const getSorts = createSelector(
  [getMetaDatas],
  (metaDatas: tableMetaDataType) =>
    metaDatas.sortGroup && metaDatas.sortGroup.sorts
      ? metaDatas.sortGroup.sorts
      : []
);

export const hasAppliedSorts = createSelector(
  [getSorts],
  (sorts: Array<sortType>) => {
    return sorts.length > 0;
  }
);

/*
  given two rows a and b,
  sort it based on the sorts applied
*/

export type sorterType = {|
  sortFunction: (any, any) => number,
  columnID: columnIDType,
  ascending: boolean
|};
function applySortOrder(result: number, ascending: boolean) {
  return ascending ? result : -result;
}

function applySorters(
  a: tableDataRowType,
  b: tableDataRowType,
  sorts: Array<?sorterType>
): number {
  return sorts.reduce((prevResult: number, sort: ?sorterType) => {
    if (prevResult !== 0 || !sort) return prevResult;
    return applySortOrder(
      sort.sortFunction(
        a.cellColumnIdMap && a.cellColumnIdMap[sort.columnID],
        b.cellColumnIdMap && b.cellColumnIdMap[sort.columnID]
      ),
      sort.ascending
    );
  }, 0);
}

export const getSortedTableDatas = (
  tableDatas: tableDatasType,
  sorts: Array<sortType>,
  columns: Array<tableSchemaColumnType>
): tableDatasType => {
  const getSorter = (sort: sortType): ?sorterType => {
    const columnToSort = columns.find(column => column.id === sort.columnID);
    const sortFunc = nullSafe(
      //$FlowFixMe
      () => WisptableConfig.dataTypes[columnToSort.type].sortFunction
    );
    if (!sortFunc || !sort.columnID) return null;
    return {
      columnID: sort.columnID,
      sortFunction: sortFunc,
      ascending: sort.ascending
    };
  };

  const sorters = sorts.map(getSorter);

  return tableDatas.slice().sort((a, b) => applySorters(a, b, sorters));
};

export const getFilterGroup = createSelector(
  [getMetaDatas],
  (metaDatas: tableMetaDataType) =>
    metaDatas.filterGroup
      ? metaDatas.filterGroup
      : { conjunction: "and", filters: [] }
);

type appliedFilterTpye = {|
  columnID: columnIDType,
  filterDetail: filterType,
  filterFunction: (value: any, filterDetail: filterType) => boolean
|};

function applyFilters(
  row: tableDataRowType,
  appliedFilters: Array<?appliedFilterTpye>,
  conjunction: filterConjunctionType
) {
  if (appliedFilters.length === 0) return true;
  return appliedFilters.reduce(
    (prevResult: ?boolean, appliedFilter: ?appliedFilterTpye) =>
      !appliedFilter
        ? true
        : applyLogic(
            prevResult,
            appliedFilter.filterFunction(
              row.cellColumnIdMap &&
                row.cellColumnIdMap[appliedFilter.columnID],
              appliedFilter.filterDetail
            ),
            conjunction
          ),
    null
  );
}

export const hasAppliedFilters = createSelector(
  [getFilterGroup],
  (filterGroup: filterGroupType) => {
    return filterGroup.filters.length > 0;
  }
);

//cellValue and filterType and filterFunction

export const getFilteredTableDatas = (
  tableDatas: tableDatasType,
  filterGroup: filterGroupType,
  columns: Array<tableSchemaColumnType>
): tableDatasType => {
  const getFilter = (filter: filterType): ?appliedFilterTpye => {
    const columnToFilter = columns.find(
      column => column.id === filter.columnID
    );
    const filterFunc = nullSafe(
      //$FlowFixMe
      () => WisptableConfig.dataTypes[columnToFilter.type].filter.filterFunction
    );
    if (!filterFunc || !filter.columnID) return null;
    return {
      columnID: filter.columnID,
      filterDetail: filter,
      filterFunction: filterFunc
    };
  };

  const appliedfilters = filterGroup.filters.map(getFilter);
  return tableDatas.filter((row: tableDataRowType) =>
    applyFilters(row, appliedfilters, filterGroup.conjunction)
  );
};

export const getSortedAndFilteredTableData = createSelector(
  [getTableDatas, getSorts, getFilterGroup, getColumns],
  (
    tableDatas: tableDatasType,
    sorts: Array<sortType>,
    filterGroup: filterGroupType,
    columns: Array<tableSchemaColumnType>
  ) => {
    const filteredTableData = getFilteredTableDatas(
      tableDatas,
      filterGroup,
      columns
    );

    return getSortedTableDatas(filteredTableData, sorts, columns);
  }
);

export const getCurrentTableID = (state: stateType): ?tableIDType =>
  state.currentTableID;

export const getCurrentRows = (state: stateType): tableDatasType =>
  state.tableDatas;

export const getCurrentColumn = createSelector(
  [getCurrentEditingColumnID, getColumns],
  (
    currentEditingColumnID: columnIDType,
    columns: Array<tableSchemaColumnType>
  ): ?tableSchemaColumnType => {
    const currentEditingColumn = columns.find(
      (column: tableSchemaColumnType) => column.id === currentEditingColumnID
    );
    if (currentEditingColumn) {
      return currentEditingColumn;
    } else {
      return null;
    }
  }
);

export const getCurrentEditingColumnName = createSelector(
  getCurrentColumn,
  (currentColumn: ?tableSchemaColumnType): string => {
    return currentColumn ? currentColumn.name : "";
  }
);

export const getCurrentEditingColumnType = createSelector(
  getCurrentColumn,
  (currentColumn: ?tableSchemaColumnType): ?columnTypes => {
    return currentColumn ? currentColumn.type : null;
  }
);

//return null if current editing table is not of linker type
//or link-in table is not specified.
export const getCurrentEditingColumnLinkedInTableName = createSelector(
  [getCurrentColumn, getTables],
  (
    currentColumn: ?tableSchemaColumnType,
    tables: Array<tableType>
  ): ?string => {
    if (
      !currentColumn ||
      !currentColumn.config ||
      !currentColumn.config.linkingTableID
    )
      return null;
    const linkedInTableID = currentColumn.config.linkingTableID;
    const linkedInTable = tables.find(
      (table: tableType) => table.id === linkedInTableID
    );

    return linkedInTable ? linkedInTable.name : null;
  }
);

export const getCurrentEditingColumnLinkedInFormName = createSelector(
  [getCurrentColumn],
  (currentColumn: ?tableSchemaColumnType): ?wispformIDType => {
    if (!currentColumn || !currentColumn.config || !currentColumn.config.formID)
      return null;
    return String(currentColumn.config.formID);
  }
);

export const getTableByTableID = createSelector(
  getTables,
  (tables: Array<tableType>) => {
    return (tableID: tableIDType): ?tableType => {
      return tables.find(table => table.id === tableID);
    };
  }
);

export const getContactColumns = createSelector(
  getColumns,
  (columns: Array<tableSchemaColumnType>) =>
    columns.filter(column => column.type === "Contact")
);

export const hasContactColumn = createSelector(
  getContactColumns,
  (columns: Array<tableSchemaColumnType>) => columns.length > 0
);

const getContactColumnIDs = createSelector(
  getContactColumns,
  (contactColumns: Array<tableSchemaColumnType>) =>
    contactColumns.map(contactColumn => contactColumn.id)
);

export const getContactsInRow = createSelector(
  [getTableDatas, getContactColumnIDs],
  (rows: Array<tableDataRowType>, contactColumnIDs: Array<columnIDType>) => {
    return (rowID: rowIDType) => {
      const row = getRow(rows, rowID);
      const cells = row && row.cellColumnIdMap;
      if (!cells) return [];
      return (
        contactColumnIDs
          .map((contactColumnID: columnIDType) => cells[contactColumnID])
          //$FlowFixMe
          .filter(contactCell => contactCell != null && contactCell.name)
      );
    };
  }
);
