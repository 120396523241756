import React, { Component } from "react";
import "./style.css";
import PropTypes from "prop-types";

/* eslint-disable */
const MultiChoicesCreator = ({
  value,
  index,
  edit_choice,
  last_edited_question,
  add_choice,
  remove_choice,
  saveToBackend
}) => {
  /* eslint-enable */
  return (
    <div className="MultiChoicesCreator">
      <div className="MultiChoicesCreator-IndexLetter">
        {String.fromCharCode(65 + index)}
      </div>
      <div className="MultiChoicesCreator-Input">
        <input
          value={value}
          onBlur={() => saveToBackend()}
          onChange={e =>
            edit_choice(index, e.target.value, last_edited_question)
          }
        />
      </div>
      <div className="MultiChoicesCreator-Icon MultiChoicesCreator-Add">
        <i
          onClick={() => {
            add_choice(last_edited_question);
            saveToBackend();
          }}
          className="fa fa-plus-circle"
          aria-hidden="true"
        />
      </div>
      <div className="MultiChoicesCreator-Icon MultiChoicesCreator-Delete">
        <i
          onClick={() => {
            remove_choice(last_edited_question, index);
            saveToBackend();
          }}
          className="fa fa-minus-circle"
          aria-hidden="true"
        />
      </div>
      <div className="MultiChoicesCreator-Icon MultiChoicesCreator-Move">
        <i className="fa fa-arrows-v" aria-hidden="true" />
      </div>
    </div>
  );
};

MultiChoicesCreator.propTypes = {
  value: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  edit_choice: PropTypes.func.isRequired,
  add_choice: PropTypes.func.isRequired,
  remove_choice: PropTypes.func.isRequired,
  last_edited_question: PropTypes.number.isRequired
};

export default MultiChoicesCreator;
