//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreBox,
  UICoreText,
  UICoreImage,
  UICoreButton
} from "../../../Component/UICore";
import Logo from "../../../Component/Logo/Logo";
import privatePageImage from "../../../asset/PrivatePage.png";
import Desktop from "../../../Component/Views/Desktop";
import Mobile from "../../../Component/Views/Mobile";
import { browserHistory } from "react-router";
import { domainName } from "../../../config";
type Props = {| customClosedFormText?: string |};
type State = {||};
class ClosedPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <UICoreBox
        justifyContent="center"
        alignItems="center"
        padding="sm"
        height="100vh"
        width="100vw"
        position="relative"
      >
        {/* <UICoreBox
          position="absolute"
          width="100%"
          direction="row"
          alignItems="center"
          top="0px"
          left="0px"
          padding="sm"
        >
          <UICoreBox paddingRight="xm">
            <Logo />
          </UICoreBox>
          <UICoreText size="md" weight="bold">
            Wispform
          </UICoreText>
        </UICoreBox> */}

        <UICoreBox>
          <UICoreImage width="300px" src={privatePageImage} />
        </UICoreBox>

        <UICoreBox width="70%" alignItems="center">
          <UICoreText
            overflow="wrap"
            alignment="center"
            size="lg"
            weight="bold"
          >
            {this.props.customClosedFormText
              ? this.props.customClosedFormText
              : "You can no longer access this form as the form creator has closed it already. Please reach out to the form creator."}
          </UICoreText>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default ClosedPage;
