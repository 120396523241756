//@flow
import * as React from "react";
import "./style.css";
import {
  type cellValueType,
  type DataComponentPropsType
} from "../../../Configuration";
type Props = {|
  ...DataComponentPropsType,
  updateCell: cellValueType => mixed
|};
type State = {||};
class FormDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <div>FormDataUI</div>;
  }
}
export default FormDataUI;
