import { isNullOrUndefined, isNonEmptyString, isNonNull } from "./Util";
import { connect } from "ngrok";

// @flow
export function convertToNewThankYouPageDataStructureIfNecessary(
  pages: Object
) {
  if (!pages) return null;
  return {
    ...pages,
    thankyouPages: isOldDataStructure(pages)
      ? {
          displayLogic: {},
          pages: [pages.thankyouPage]
        }
      : pages.thankyouPages
  };
}

export function isOldDataStructure(pages: Object) {
  // It is consider as an existing old thankyou page, if
  // (1) thankyouPages doesn't exist
  // (2) thankyouPage is nonnull (old data field)
  const hasOldThankyouPageField = pages && pages.thankyouPage;
  return (
    isNullOrUndefined(pages && pages.thankyouPages) && hasOldThankyouPageField
  );
}

export function getSocialShareContent(content: ?string): string {
  if (isNonNull(content)) {
    return content;
  } else {
    return "I have just completed a wispform! Give it a try: ";
  }
}
