// @flow

const id = "dynamic-google-font";

export function loadGoogleFont(googleFontName: ?string) {
  if (!googleFontName) return;
  let font: ?HTMLLinkElement = null;

  if (document.getElementById(id)) {
    font = ((document.getElementById(id): any): HTMLLinkElement);
  } else {
    font = document.createElement("link");
    font.rel = "stylesheet";
    font.id = id;
    if (document.head) {
      document.head.appendChild(font);
    }
  }
  font.href = `https://fonts.googleapis.com/css2?family=${googleFontName}:wght@300;400;500;700&display=swap`;
}
