// @flow
import * as React from "react";
import {
  UICoreBox,
  UICoreText,
  UICoreFlyOut,
  UICoreButton
} from "../../UICore";
import { Scrollbars } from "react-custom-scrollbars";
import UICoreList from "../UICoreList";
import {
  UICoreListMediaTypeEnum,
  UICoreListItemTypeEnum,
  type UICoreListMediaSpecType
} from "../../../FlowTypes/UICoreTypes/UICoreListType";
import { UICoreIconTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreIconType";
import UICoreIcon from "../UICoreIcon";
import { SizeTypeEnum } from "../../../FlowTypes/sizeTypes";
import { type TUICoreMultiSelectDropdownChoice } from "./type";
import {
  isEmptyArray,
  isNonEmptyArray,
  isNullOrUndefined,
  nonNullOrThrow,
  toArray
} from "../../../Library/Util";
import { type dropdownFooterButtonType } from "../UICoreDropdown";

export type TUICoreMultiSelectDropdownNullState = {|
  title: string,
  description?: string
|};

type Props = {|
  choices: Array<TUICoreMultiSelectDropdownChoice>,
  selected: Array<any>,
  onItemClick?: (value: any, isAlreadySelected: boolean) => void,
  dropdownFooterButton?: dropdownFooterButtonType,
  bottomCTAClick?: () => void,
  onItemDeleteClick?: (value: any) => void,
  nullState?: TUICoreMultiSelectDropdownNullState
|};
type State = {|
  showDropdown: boolean
|};

const kDefaultWidth = 400;
class UICoreMultiSelectDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showDropdown: false
    };
  }

  _dropdownHeader: ?HTMLElement;

  _showDropdown = () => {
    this.setState({
      showDropdown: true
    });
  };

  _closeDropdown = () => {
    this.setState({
      showDropdown: false
    });
  };

  _renderButton = () => {
    return (
      <UICoreBox paddingLeft="xm" paddingTop="xm" paddingRight="xm">
        <UICoreButton
          onClick={() => {
            this.props.dropdownFooterButton &&
              this.props.dropdownFooterButton.onClick();
          }}
          color="lightBlue"
        >
          {(this.props.dropdownFooterButton &&
            this.props.dropdownFooterButton.buttonText) ||
            ""}
        </UICoreButton>
      </UICoreBox>
    );
  };

  _renderNullState = () => {
    if (isNullOrUndefined(this.props.nullState)) return;
    let nullState = nonNullOrThrow(this.props.nullState);
    return (
      <UICoreBox verticalPadding="sm" alignItems="center">
        <UICoreBox>
          <UICoreText weight="bold" color="black">
            {nullState.title}
          </UICoreText>
        </UICoreBox>
        {nullState.description && (
          <UICoreBox paddingTop="xm">
            <UICoreText color="Grey">{nullState.description}</UICoreText>
          </UICoreBox>
        )}
      </UICoreBox>
    );
  };

  _renderDropdown = () => {
    const header = this._dropdownHeader;
    if (this.state.showDropdown) {
      return (
        <UICoreFlyOut
          size={(header && header.clientWidth) || 400}
          idealDirection={"down"}
          showCaret={false}
          forceDirection={true}
          onDismiss={this._closeDropdown}
          anchor={this._dropdownHeader}
        >
          <UICoreBox
            width="100%"
            overflowX="hidden"
            paddingTop="xm"
            paddingBottom="xm"
          >
            {isEmptyArray(this.props.choices) && this._renderNullState()}
            <Scrollbars autoHeight autoHeightMax={200}>
              <UICoreList
                onItemClick={(_, choice) => {
                  const onItemClick = this.props.onItemClick;
                  if (onItemClick) {
                    onItemClick(choice, this._isSelected(choice));
                  }
                }}
                items={this.props.choices.map(choice => {
                  return {
                    title: choice.display,
                    itemType: UICoreListItemTypeEnum.stringItem,
                    extra: choice.value,
                    leftMediaSpec: {
                      type: UICoreListMediaTypeEnum.checkBox,
                      src: this._isSelected(choice.value)
                    }
                  };
                })}
              />
            </Scrollbars>
            {this.props.dropdownFooterButton && this._renderButton()}
          </UICoreBox>
        </UICoreFlyOut>
      );
    }
  };

  _isSelected = (value: any) => {
    return this.props.selected.includes(value);
  };

  _renderSelectedItems = () => {
    const selectedItems = this.props.choices
      .filter(choice => this._isSelected(choice.value))
      .map(choice => {
        return this._renderSelectedItem(choice.display, choice.value);
      });
    const hasSelectedItems = isNonEmptyArray(selectedItems);
    return (
      <UICoreBox
        dangerous_style={{
          marginBottom: hasSelectedItems ? "-8px" : "0px",
          flexWrap: "wrap"
        }}
        direction="row"
      >
        {hasSelectedItems ? (
          selectedItems
        ) : (
          <UICoreText color="Grey">click to select outcomes</UICoreText>
        )}
      </UICoreBox>
    );
  };

  _renderSelectedItem = (display: string, value: any) => {
    return (
      <UICoreBox
        shape="rounded"
        direction="row"
        justifyContent="center"
        color="Grey"
        alignItems="center"
        horizontalPadding="xm"
        verticalPadding="xxm"
        marginRight="xm"
        marginBottom="xm"
      >
        <UICoreBox maxWidth="200px">
          <UICoreText
            size="xs"
            color="black"
            dangerous_style={{ whiteSpace: "nowrap" }}
          >
            {display}
          </UICoreText>
        </UICoreBox>
        <UICoreBox
          onClick={e => {
            e.stopPropagation();
            this.props.onItemDeleteClick && this.props.onItemDeleteClick(value);
          }}
          paddingLeft="xm"
        >
          <UICoreIcon size={SizeTypeEnum.xxm} name={UICoreIconTypeEnum.close} />
        </UICoreBox>
      </UICoreBox>
    );
  };

  render() {
    return (
      <UICoreBox>
        <UICoreBox
          innerRef={e => {
            this._dropdownHeader = e;
          }}
          height="100%"
          alignItems="start"
          padding="xm"
          shape="rounded"
          color="lightGrey"
          position="relative"
          onClick={this._showDropdown}
          minHeight="36px"
          hoverable={true}
        >
          {this._renderSelectedItems()}

          <UICoreBox className="UICoreDropdown-caret" position="absolute">
            <i className="fa fa-caret-down" />
          </UICoreBox>
        </UICoreBox>
        {this._renderDropdown()}
      </UICoreBox>
    );
  }
}
export default UICoreMultiSelectDropdown;
