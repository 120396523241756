//@flow
import * as React from "react";
import { UICoreBox } from "../../../../Component/UICore";
import "./style.css";
type Props = {|
  children: React.Node
|};
type State = {||};
class BrowserUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox
        marginTop="sm"
        alignItems="center"
        className="Share-Preview"
        height="100%"
        width="100%"
      >
        <UICoreBox
          className="Share-PreviewContainer"
          width="100%"
          height="100%"
          shape="rounded"
          color="white"
        >
          <UICoreBox
            className="Share-PreviewContainerHeader"
            color="lightGrey"
            width="100%"
            height="40px"
            direction="row"
            alignItems="center"
          >
            <UICoreBox
              hexColor="#FF8B8B"
              shape="circle"
              width="15px"
              height="15px"
              margin="xm"
            >
              <p />
            </UICoreBox>
            <UICoreBox
              shape="circle"
              hexColor="#D0DF30"
              width="15px"
              height="15px"
              margin="xm"
            >
              <p />
            </UICoreBox>
            <UICoreBox
              shape="circle"
              hexColor="#4DE254"
              width="15px"
              height="15px"
              margin="xm"
            >
              <p />
            </UICoreBox>
          </UICoreBox>
          <UICoreBox
            overflowY="hidden"
            height="calc(100% - 40px)"
            name="browserContent"
          >
            {this.props.children}
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default BrowserUI;
