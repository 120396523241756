// @flow
import * as React from "react";
import { connect } from "react-redux";
import { update_style, update_styles } from "../../../States/actions";
import {
  type formStyleType,
  styleAttributes,
  type background,
  backgroundType,
  type TTheme
} from "../../../../../FlowTypes/wispformStyleTypes";
import { updateStyleBackground } from "../../../States/actions";
import { Scrollbars } from "react-custom-scrollbars";
import "./style.css";
import {
  UICoreText,
  UICoreBox,
  UICoreButton,
  UICoreImage
} from "../../../../../Component/UICore";
import { template } from "handlebars";
import {
  isNullOrUndefined,
  nonNullOrThrow,
  toUnNullable
} from "../../../../../Library/Util";

type Props = {|
  styles: formStyleType,
  updateStyle: ($Keys<formStyleType>, string | background) => any,
  updateStyles: ($Shape<formStyleType>) => any,
  updateStyleBackground: background => void
|};
type State = {||};

const kDesktopPreviewDimension = {
  width: 150,
  height: 100
};

const kDefaultThemeID = 1;

class DesignPanelThemeSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  isSelectedTemplate(id) {
    if (
      this.props.styles &&
      this.props.styles.theme &&
      this.props.styles.theme === id
    ) {
      return true;
    }
    return false;
  }

  renderThemes() {
    return themes.map(template => {
      return (
        <UICoreBox paddingBottom="md" width="30%" marginRight="sm">
          <ThemeCard
            onThemeCardClicked={() => {
              this.onThemeCardClicked(
                template.id,
                template.isPro,
                template.backgroundColor,
                template.textColor,
                template.answerColor,
                template.buttonColor,
                template.font,
                template.image,
                template.mobileImage
              );
            }}
            cardImageURL={template.cardImage}
            templateName={template.name}
            isPro={template.isPro}
            templateID={template.id}
            isSelectedTemplate={this.isSelectedTemplate(template.id)}
          />
        </UICoreBox>
      );
    }, this);
  }

  render() {
    return (
      <UICoreBox>
        <Scrollbars
          width={"100%"}
          autoHide={true}
          autoHeight
          autoHeightMax={475}
        >
          <UICoreBox
            width="100%"
            wrap="wrap"
            direction="row"
            paddingLeft="sm"
            paddingRight="sm"
            paddingTop="sm"
          >
            {this.renderThemes()}
          </UICoreBox>
        </Scrollbars>
      </UICoreBox>
    );
  }

  onThemeCardClicked(
    id: number,
    isPro: boolean,
    backgroundColor: string,
    textColor: string,
    answerColor: string,
    buttonColor: string,
    font: string,
    image: ?string,
    mobileImage: ?string
  ) {
    // We should have at least one nonnull background field
    if (image && isNullOrUndefined(mobileImage)) {
      return;
    }
    if (!image && isNullOrUndefined(backgroundColor)) {
      return;
    }
    this.props.updateStyles({
      theme: id,
      fontFamily: font,
      text: textColor,
      answer: answerColor,
      buttonColor: buttonColor,
      background: image
        ? {
            type: backgroundType.image,
            imageMobile: nonNullOrThrow(mobileImage),
            imageDesktop: nonNullOrThrow(image),
            filter: 0.5
          }
        : {
            type: backgroundType.color,
            color: nonNullOrThrow(backgroundColor)
          }
    });
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    styles: state.Question.styles
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateStyle: (style, value) => dispatch(update_style(style, value)),
    updateStyles: styles => dispatch(update_styles(styles)),
    updateStyleBackground: (background: background) => {
      dispatch(updateStyleBackground(background));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignPanelThemeSection);

const ThemeCard = function ({
  cardImageURL,
  templateName,
  onThemeCardClicked,
  isPro,
  isSelectedTemplate
}: {
  cardImageURL: string,
  templateName: string,
  onThemeCardClicked: () => void,
  isPro: boolean,
  isSelectedTemplate: boolean
}) {
  return (
    <UICoreBox
      onClick={() => {
        onThemeCardClicked();
      }}
      className={isSelectedTemplate ? "ThemeCardSpecial" : "ThemeCard"}
      shape="rounded"
      width="100%"
    >
      <UICoreImage shape="topRounded" height="120px" src={cardImageURL} />
      <UICoreBox
        padding="xm"
        direction="row"
        justifyContent="between"
        className="ThemeCard-Text"
      >
        <UICoreText color="black" size="xs">
          {templateName}
        </UICoreText>
        {isPro && (
          <UICoreBox
            width="35px"
            alignItems="center"
            justifyContent="center"
            shape="rounded"
            color="skyBlue"
          >
            <UICoreText size="xxs" color="lightBlue">
              Pro
            </UICoreText>
          </UICoreBox>
        )}
      </UICoreBox>
    </UICoreBox>
  );
};

export const themes: Array<TTheme> = [
  {
    name: "Default Theme",
    id: 1,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/default.png",
    isPro: false,
    backgroundColor: "#ffffff",
    textColor: "#555555",
    answerColor: "#555555",
    buttonColor: "#007aff",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Plain Green",
    id: 2,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/plain-green.png",
    isPro: false,
    backgroundColor: "#ffffff",
    textColor: "#555555",
    answerColor: "#1D5F66",
    buttonColor: "#1D5F66",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Plain Orange",
    id: 3,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/plain-orange.png",
    isPro: false,
    backgroundColor: "#ffffff",
    textColor: "#555555",
    answerColor: "#CE6246",
    buttonColor: "#CE6246",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Plain Blue",
    id: 4,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/plain-blue.png",
    isPro: false,
    backgroundColor: "#ffffff",
    textColor: "#555555",
    answerColor: "#515D90",
    buttonColor: "#515D90",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Black and White",
    id: 5,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/black-and-white.png",
    isPro: false,
    backgroundColor: "#000000",
    textColor: "#ffffff",
    answerColor: "#ffffff",
    buttonColor: "#ffffff",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Plain Purple",
    id: 6,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/plain-purple.png",
    isPro: false,
    backgroundColor: "#ffffff",
    textColor: "#555555",
    answerColor: "#8044BD",
    buttonColor: "#8044BD",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Blue Chill",
    id: 7,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/blue-chill.png",
    isPro: false,
    backgroundColor: "#007AFF",
    textColor: "#ffffff",
    answerColor: "#ffffff",
    buttonColor: "#ffffff",
    font: "Roboto",
    image: null,
    mobileImage: null
  },
  {
    name: "Nature",
    id: 8,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/nature.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/nature.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/nature-mobile.png",
    isPro: true,
    backgroundColor: "#515D90",
    textColor: "#0B263C",
    answerColor: "#515D90",
    buttonColor: "#515D90",
    font: "IBM Plex Sans"
  },
  {
    name: "Futuristic",
    id: 9,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/futuristic.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/futuristic.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/futuristic-mobile.png",
    isPro: true,
    backgroundColor: "#8044BD",
    textColor: "#0B263C",
    answerColor: "#8044BD",
    buttonColor: "#8044BD",
    font: "IBM Plex Sans"
  },
  {
    name: "Travel",
    id: 10,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/travel.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/travel.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/travel-mobile.png",
    isPro: true,
    backgroundColor: "#1D5F66",
    textColor: "#0B263C",
    answerColor: "#1D5F66",
    buttonColor: "#1D5F66",
    font: "IBM Plex Sans"
  },
  {
    name: "Event",
    id: 11,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/event.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/event.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/event-mobile.png",
    isPro: true,
    backgroundColor: "#422B1B",
    textColor: "#422B1B",
    answerColor: "#572518",
    buttonColor: "#CE6246",
    font: "IBM Plex Sans"
  },
  {
    name: "Crypto",
    id: 12,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/crypto.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/crypto.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/crypto-mobile.png",
    isPro: true,
    backgroundColor: "#112C57",
    textColor: "#0B263C",
    answerColor: "#112C57",
    buttonColor: "#112C57",
    font: "IBM Plex Sans"
  },
  {
    name: "Classroom",
    id: 13,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/classroom.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/classroom.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/classroom-mobile.png",
    isPro: true,
    backgroundColor: "#1D5F66",
    textColor: "#0B263C",
    answerColor: "#1D5F66",
    buttonColor: "#1D5F66",
    font: "IBM Plex Sans"
  },
  {
    name: "Romance",
    id: 14,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/romance.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/romance.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/romance-mobile.png",
    isPro: true,
    backgroundColor: "#A66044",
    textColor: "#0B263C",
    answerColor: "#A66044",
    buttonColor: "#A66044",
    font: "IBM Plex Sans"
  },
  {
    name: "Collaboration",
    id: 15,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/collaboration.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/collaboration.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/collaboration-mobile.png",
    isPro: true,
    backgroundColor: "#CE6246",
    textColor: "#422B1B",
    answerColor: "#CE6246",
    buttonColor: "#CE6246",
    font: "IBM Plex Sans"
  },
  {
    name: "Art",
    id: 16,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/art.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/art.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/art-mobile.png",
    isPro: true,
    backgroundColor: "#5A241A",
    textColor: "#5A241A",
    answerColor: "#5A241A",
    buttonColor: "#5A241A",
    font: "IBM Plex Sans"
  },
  {
    name: "Survey",
    id: 17,
    cardImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/card/survey.png",
    image:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/survey.png",
    mobileImage:
      "https://wispformapp.s3.us-east-2.amazonaws.com/Theme/background/survey-mobile.png",
    isPro: true,
    backgroundColor: "#384106",
    textColor: "#0B263C",
    answerColor: "#384106",
    buttonColor: "#384106",
    font: "IBM Plex Sans"
  }
];
