import React, { Component } from "react";
import { Link } from "react-router";
import { logNewUserOnboarding, logFeatureEvent } from "../../../Library/Logger";
import "./style.css";

class Links extends Component {
  componentDidMount() {
    this.removeCurrentActive();
    this.addActive(this.props.page_name);
  }

  componentWillUpdate(nextProps, nextState) {
    const current_page = nextProps.page_name;
    this.removeCurrentActive();
    this.addActive(current_page);
  }

  removeCurrentActive() {
    const current_active = document.getElementsByClassName(
      "GlobalNavBar-active"
    )[0];
    current_active && current_active.classList.remove("GlobalNavBar-active");
  }

  addActive(current_page) {
    const pages = ["formbuilder", "configure", "share", "result"];
    const index = pages.indexOf(current_page);
    if (index == -1) return;
    const link =
      document.getElementsByClassName("GlobalNavBar-Links")[0] &&
      document.getElementsByClassName("GlobalNavBar-Links")[0].children[index];
    link && link.classList.add("GlobalNavBar-active");
  }

  _handleShareButtonClick = () => {
    logNewUserOnboarding("shareButtonClicked");
  };

  render() {
    return (
      <div className="GlobalNavBar-Links">
        <Link
          to={"/form/formbuilder?id=" + this.props.form_id}
          data-name="Create"
          className="GlobalNavBar-Link"
        >
          Create
        </Link>
        <Link
          to={"/form/configure?id=" + this.props.form_id}
          data-name="Configure"
          className="GlobalNavBar-Link"
          onClick={() => {
            logFeatureEvent("configurePage/navTabClicked");
          }}
        >
          Integrate
        </Link>
        {/*
          <Link
          onClick={e => {
            e.stopPropagation();
            this.props.showFormPreviewModal();
          }}
          data-name="Collaborate"
          className="GlobalNavBar-Link"
          >
            Preview
          </Link>
        */}
        <Link
          onClick={this._handleShareButtonClick}
          to={"/form/share?id=" + this.props.form_id}
          data-name="Share"
          className="GlobalNavBar-Link"
        >
          Share
        </Link>
        <Link
          to={"/form/result/gridview?id=" + this.props.form_id}
          data-name="Results"
          className="GlobalNavBar-Link"
        >
          Results
        </Link>
      </div>
    );
  }
}

export default Links;
