import React, { Component } from "react";
import { connect } from "react-redux";
import QuestionBasics from "../../Components/QuestionBasics";
import QuestionSettings from "../../Components/QuestionSettings";
import Scrollable from "../../../../Component/Scrollable";
import { Scrollbars } from "react-custom-scrollbars";
import {
  update_question_config,
  update_question_title,
  update_question_description,
  update_question_image,
  update_thankyou_page,
  update_question_media,
  updateQuestionContent,
  update_outcome_page
} from "../../States/actions";
import "./style.css";
import PropTypes from "prop-types";
import {
  isEditingThankyouPage,
  hasScoreSetting,
  isEditingOucomePage,
  getThankyouPages,
  getOutcomePageContentConfig
} from "../../States/selectors";
import { UICoreBox } from "../../../../Component/UICore";

class ToolBoxContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      question,
      last_edited_question,
      update_question_description,
      update_question_title,
      last_edited_thankyou_page
    } = this.props;
    return (
      <div className="ToolBoxContainer">
        <div className="ToolBoxContainer-QuestionConfig">
          <Scrollbars autoHide style={{ height: "100%" }}>
            <UICoreBox dangerous_style={{ paddingBottom: 100 }}>
              {!this.props.isEditingThankyouPage &&
                !this.props.isEditingOucomePage && (
                  <div className="ToolBoxContainer-QuestionConfig-QuestionBasics">
                    <QuestionBasics
                      question={question}
                      last_edited_question={last_edited_question}
                      /* eslint-disable */
                      onDescriptionChange={(description, pageNumber) =>
                        update_question_description(description, pageNumber)
                      }
                      onTitleChange={(title, pageNumber) =>
                        update_question_title(title, pageNumber)
                      }
                      /* eslint-enable */
                    />
                  </div>
                )}
              <div className="ToolBoxContainer-QuestionConfig-QuestionSettings">
                <QuestionSettings
                  hasScoreSetting={this.props.hasScoreSetting}
                  questionIndex={last_edited_question}
                  thankyouPageIndex={last_edited_thankyou_page}
                  update_question_config={this.props.update_question_config}
                  update_question_media={this.props.update_question_media}
                  update_thankyou_page={this.props.update_thankyou_page}
                  updateQuestionContent={this.props.updateQuestionContent}
                  question={this.props.question}
                  thankyouPages={this.props.thankyouPages}
                  update_outcome_page={this.props.update_outcome_page}
                  outcomePageConfig={this.props.outcomePageConfig}
                />
              </div>
            </UICoreBox>
          </Scrollbars>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    last_edited_question: state.Question.last_edited_question,
    last_edited_thankyou_page: state.Question.last_edited_thankyou_page,
    question: state.Question.questions[state.Question.last_edited_question],
    thankyouPages: getThankyouPages(state.Question),
    isEditingThankyouPage: isEditingThankyouPage(state.Question),
    isEditingOucomePage: isEditingOucomePage(state.Question),
    hasScoreSetting: hasScoreSetting(state.Question),
    outcomePageConfig: getOutcomePageContentConfig(state.Question)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    /* eslint-disable */
    update_question_title: (title, pageNumber) =>
      dispatch(update_question_title(title, pageNumber)),
    update_question_description: (description, pageNumber) =>
      dispatch(update_question_description(description, pageNumber)),
    update_question_config: (config_name, value, page_number) =>
      dispatch(update_question_config(config_name, value, page_number)),
    update_question_image: (imageURL: string, pageNumber: number) => {
      dispatch(update_question_image(imageURL, pageNumber));
    },
    update_question_media: (media: questionMediaType, index: number) => {
      dispatch(update_question_media(media, index));
    },
    update_thankyou_page: pages => dispatch(update_thankyou_page(pages)),
    update_outcome_page: updatedPage =>
      dispatch(update_outcome_page(updatedPage)),
    updateQuestionContent: (
      index: number,
      content: any,
      shouldPersist: boolean
    ) => dispatch(updateQuestionContent(index, content, shouldPersist))

    /* eslint-enable */
  };
}

ToolBoxContainer.propTypes = {
  question: PropTypes.object.isRequired,
  last_edited_question: PropTypes.number.isRequired,
  update_question_description: PropTypes.func.isRequired,
  update_question_title: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ToolBoxContainer);
