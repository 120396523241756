// @flow
import { type questionType } from "../Pages/QuestionTypes";
import { safeGet, toArray } from "./Util";
import { type TOutcomeData } from "../Component/GenericFormContent/type";

function _getInitializedOutcomeIdScoreMap(outcomes: Array<TOutcomeData>): {
  [string]: number
} {
  return outcomes.reduce((prev, outcome) => {
    prev[outcome.id] = 0;
    return prev;
  }, {});
  //   return outcomeIdScoreMap;
}

export function getEligibleOutcomeIDs(
  questions: Array<questionType>,
  answerMap: { [string]: { answer: Array<{ choiceContent: string }> } },
  outcomes: Array<TOutcomeData>,
  maxOutcomeNum: number
): Array<string> {
  const outcomeIdScoreMap = _getInitializedOutcomeIdScoreMap(outcomes);
  for (let question of questions) {
    let choiceOutcomesMapping =
      question.outcomeDisplayLogic &&
      question.outcomeDisplayLogic.choiceOutcomesMapping;
    if (choiceOutcomesMapping) {
      if (question.type === "YesNo") {
        let answers = [safeGet(_ => answerMap[question.question_id].answer)];
        for (let answer of toArray(answers)) {
          let targetedOutcomes = choiceOutcomesMapping[answer];
          for (let outcome of toArray(targetedOutcomes)) {
            outcomeIdScoreMap[outcome] = outcomeIdScoreMap[outcome] + 1;
          }
        }
      } else if (question.type === "Rating") {
        let answers = [safeGet(_ => answerMap[question.question_id].answer)];
        for (let answer of toArray(answers)) {
          let targetedOutcomes = choiceOutcomesMapping[answer];
          for (let outcome of toArray(targetedOutcomes)) {
            outcomeIdScoreMap[outcome] = outcomeIdScoreMap[outcome] + 1;
          }
        }
      } else {
        let answers = safeGet(_ => answerMap[question.question_id].answer);
        for (let answer of toArray(answers)) {
          let targetedOutcomes = safeGet(
            _ => choiceOutcomesMapping[answer.choiceContent]
          );
          for (let outcome of toArray(targetedOutcomes)) {
            outcomeIdScoreMap[outcome] = outcomeIdScoreMap[outcome] + 1;
          }
        }
      }
    }
  }
  let outcomeIDs = Object.keys(outcomeIdScoreMap);
  let sortedOutcomeIDs = outcomeIDs.sort(
    (a, b) => outcomeIdScoreMap[b] - outcomeIdScoreMap[a]
  );
  return sortedOutcomeIDs.slice(0, maxOutcomeNum);
}
