//@flow
import CodeBlockComponent from "../../Components/CellComponents/CodeBlockComponent";
import CodeBlockDataUI from "../../Components/DataUIComponents/CodeBlockDataUI";
import { type DataType, type textCellType } from "../index";

const LongTextType = {
  icon: "fa fa-code",
  cellComponent: CodeBlockComponent,
  dataUIComponent: CodeBlockDataUI,
  displayName: "Code Block",
  sortFunction: sorter,
  filter: null
};

function sorter(first: textCellType, second: textCellType) {
  if (first < second) {
    return 1;
  } else {
    return 0;
  }
}

export default LongTextType;
