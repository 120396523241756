//@flow
import { toArray, isNonEmptyArray, isEmptyArray } from "../../Library/Util";

import { saveToBackend } from "../../Pages/FormBuilderPage/States/actions";
import {
  numberOfQuestionsWithScoreSetting,
  firstQuestionIDWithScoreSetting
} from "../../Pages/FormBuilderPage/States/selectors";
import {
  UPDATE_SCORE_CALCULATION,
  add_question_type,
  setScore,
  reset_thankyou_pages_show_score
} from "../../Pages/FormBuilderPage/States/actions";

function hasNoMoreThanOneQuestionWithScoreSetting(questionState) {
  return numberOfQuestionsWithScoreSetting(questionState) <= 1;
}

function isUpdatingFirstQuestionWithScoreSetting(
  questionState,
  updatingQuestionID
) {
  return firstQuestionIDWithScoreSetting(questionState) === updatingQuestionID;
}

//$FlowFixMe
const ScoreCalculationMiddleware = store => next => action => {
  switch (action.type) {
    case UPDATE_SCORE_CALCULATION:
      if (isNonEmptyArray(action.updatedScoreCalculation)) {
        next(setScore(true));
        next(action);
      } else if (
        //removed the last score calculation setting in the form
        isEmptyArray(action.updatedScoreCalculation) &&
        hasNoMoreThanOneQuestionWithScoreSetting(store.getState().Question) &&
        isUpdatingFirstQuestionWithScoreSetting(
          store.getState().Question,
          action.questionID
        )
      ) {
        next(reset_thankyou_pages_show_score());
        next(setScore(false));
        next(action);
      }

    default:
      return next(action);
  }
};

export default ScoreCalculationMiddleware;
