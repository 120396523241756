// @flow
import "./style.css";
import * as React from "react";
import { connect } from "react-redux";
import {
  UICoreBox,
  UICoreDropdown,
  UICoreText
} from "../../../../../Component/UICore";
import { safeGet } from "../../../../../Library/Util";
import {
  type TGenericContentConfig,
  type TGenericContentOutcomeLayout
} from "../../../../../Component/GenericFormContent/type";
import {
  update_outcome_max_num,
  update_outcome_page
} from "../../../States/actions";
import {
  getOutcomeLayout,
  getOutcomes,
  getOutcomePageContentConfig
} from "../../../States/selectors";
import { type TOutcomeData } from "../../../../../Component/GenericFormContent/type";
import { colors } from "../../../../WispTable/Components/CellComponents/MultiselectComponent";
type Props = {|
  outcomePageConfig: TGenericContentConfig,
  layout: TGenericContentOutcomeLayout,
  update_outcome_page: TGenericContentConfig => void
|};
type State = {||};

const horizontalLayoutUI = (isLast: boolean) => {
  return (
    <UICoreBox
      shape="rounded"
      color="lightGrey"
      width="90%"
      height="17px"
      marginTop={isLast ? "xxm" : undefined}
    >
      <div />
    </UICoreBox>
  );
};
const verticalLayoutUI = () => {
  return (
    <UICoreBox shape="rounded" color="lightGrey" width="45%" height="85%">
      <div />
    </UICoreBox>
  );
};

const LayoutUI = (
  layoutType: "horizontal" | "vertical",
  isSelected: boolean,
  onClick: () => void
) => {
  return (
    <UICoreBox
      name="horizontal"
      width="45%"
      alignItems="center"
      onClick={onClick}
    >
      <UICoreBox
        shape="rounded"
        width="100%"
        height="60px"
        borderColor={isSelected ? "#6D6D6D" : "#D2D2D2"}
        borderWidth={isSelected ? 2 : 1}
        className="OutcomeLayout-choice"
      >
        {layoutType === "horizontal" ? (
          <UICoreBox
            direction="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            {horizontalLayoutUI(false)}
            {horizontalLayoutUI(true)}
          </UICoreBox>
        ) : (
          <UICoreBox
            direction="row"
            alignItems="center"
            justifyContent="between"
            horizontalPadding="xm"
            height="100%"
          >
            {verticalLayoutUI()}
            {verticalLayoutUI()}
          </UICoreBox>
        )}
      </UICoreBox>
      <UICoreBox paddingTop="xxm">
        <UICoreText color={isSelected ? "black" : "darkGray"} size="xs">
          {layoutType}
        </UICoreText>
      </UICoreBox>
    </UICoreBox>
  );
};

class OutcomeLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // this.state = {};
  }
  render() {
    return (
      <UICoreBox paddingTop="xm" direction="row" justifyContent="between">
        {LayoutUI("horizontal", this.props.layout === "horizontal", () => {
          this.props.update_outcome_page({
            ...this.props.outcomePageConfig,
            outcomeConfig: {
              ...safeGet(_ => this.props.outcomePageConfig.outcomeConfig),
              layout: "horizontal"
            }
          });
        })}
        {LayoutUI("vertical", this.props.layout === "vertical", () => {
          this.props.update_outcome_page({
            ...this.props.outcomePageConfig,
            outcomeConfig: {
              ...safeGet(_ => this.props.outcomePageConfig.outcomeConfig),
              layout: "vertical"
            }
          });
        })}
      </UICoreBox>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update_outcome_page: (outcomePage: TGenericContentConfig) => {
      dispatch(update_outcome_page(outcomePage));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    outcomePageConfig: getOutcomePageContentConfig(state.Question),
    layout: getOutcomeLayout(state.Question)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutcomeLayout);
