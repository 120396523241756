import questionTypeConfig from "../../../QuestionTypes";
import {
  add_validation_message,
  remove_all_validation_message
} from "./actions";
import { toArray, safeGet } from "../../../../Library/Util";
import { getCustomValidationErrorMessage } from "../../../../Library/FormValidation";

function _getValidatorsForQuestion(question) {
  const questionConfig = new questionTypeConfig();
  const validator_names = safeGet(
    _ => questionConfig.types[question.type].validators
  );
  return toArray(validator_names);
}

function _getCustomValidationErrorMessage(customized_button_text) {}

/* 
    retrun true if answer is valid
    return false if answer is not valid, 
        at the same time, log the error messages to redux
*/
export function validateAnswer(
  answer,
  question,
  dispatch,
  customized_button_text
) {
  const allValidators = new questionTypeConfig().validators;
  let is_valid = true;
  //remove previous warning
  dispatch(remove_all_validation_message(answer.questionID));
  //check each validators
  const validators = _getValidatorsForQuestion(question);
  validators.forEach(validator_name => {
    const validatorFunction = allValidators[validator_name];
    const message = validatorFunction(answer.answer, question);
    if (message != "") {
      is_valid = false;
      dispatch(
        add_validation_message(
          answer.questionID,
          getCustomValidationErrorMessage(
            customized_button_text,
            validator_name
          ) || message
        )
      );
    }
  });
  return is_valid;
}

/*
    get next invalid id
*/
export function getNextInvalidQuestionID(
  currentQuestionID,
  invalid_answers,
  allVisibleQuestions
) {
  const currentQuestionIndexInVisibleQuestionQueue = allVisibleQuestions.findIndex(
    q => q.question_id === currentQuestionID
  );
  //not found, jump to the submit button
  if (currentQuestionIndexInVisibleQuestionQueue === -1)
    return allVisibleQuestions.length + 1;

  //return next invalid question index
  for (
    let i = currentQuestionIndexInVisibleQuestionQueue + 1;
    i < allVisibleQuestions.length;
    i++
  ) {
    const questionID = allVisibleQuestions[i].question_id;
    if (toArray(invalid_answers[questionID]).length > 0) {
      return i;
    }
  }

  //no more invalid question, jump to submit button
  return allVisibleQuestions.length + 1;
}

/*
    if invalid, return true
    if invalid, return first invalid index  
*/
export function validateForm(
  questions,
  answers,
  customized_button_text,
  dispatch
) {
  const questionConfig = new questionTypeConfig();
  const allValidators = questionConfig.validators;
  let is_valid = true;
  let first_index = Infinity;
  //iterate each questions
  questions &&
    questions.forEach((question, index) => {
      const validator_names = _getValidatorsForQuestion(question);
      const currentAnswer = toArray(answers).find(
        answer => answer.question_id === question.question_id
      );
      //iterate each validators the question
      validator_names &&
        validator_names.forEach(validator_name => {
          const validator = allValidators[validator_name];
          const message = validator(currentAnswer.answer, question);
          if (message != "") {
            dispatch(
              add_validation_message(
                question.question_id,
                getCustomValidationErrorMessage(
                  customized_button_text,
                  validator_name
                ) || message
              )
            );
            is_valid = false;
            first_index = index < first_index ? index : first_index;
          }
        });
      //check requrie
      const is_required = question.config.Required;
      if (is_required) {
        const require_validator = allValidators["require"];
        const message = require_validator(
          currentAnswer.answer,
          currentAnswer.type
        );
        if (message != "") {
          if (customized_button_text && customized_button_text[11]) {
            dispatch(
              add_validation_message(
                question.question_id,
                customized_button_text[11]
              )
            );
          } else {
            dispatch(add_validation_message(question.question_id, message));
          }
          is_valid = false;
          first_index = index < first_index ? index : first_index;
        }
      }
    });

  if (is_valid) {
    return "true";
  } else {
    return first_index;
  }
}
