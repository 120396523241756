//@flow
import * as React from "react";
import { UICoreBox } from "..";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { toArray, isNullOrUndefined } from "../../../Library/Util";
import "./style.css";

type directionType = "row" | "column" | "both";
type axisType = "x" | "y" | "xy" | null;

type Props = {|
  children: React.Node,
  direction?: directionType,
  disableLast?: boolean,
  disableFirst?: boolean,
  disableLastN?: number,
  onSortEnd: (
    {
      oldIndex: number,
      newIndex: number,
      collection: Array<any>,
      isKeySorting: boolean
    },
    e: HTMLElement
  ) => void
|};
type State = {||};

const offSet = 1;

const directionToAxisMap: { [directionType]: axisType } = {
  row: "x",
  column: "y",
  both: "xy"
};

const directionToLockAxisMap: { [directionType]: axisType } = {
  row: "x",
  column: "y",
  both: null
};

function getUIBoxDirection(direction: ?directionType): "row" | "column" {
  if (direction === "both") {
    return "row";
  }
  return direction || "column";
}

const SortableItem = SortableElement(({ value }) => value);

const SortableList = SortableContainer(
  ({ items, direction, disableLast, disableFirst, disableLastN }) => {
    return (
      <UICoreBox
        direction={getUIBoxDirection(direction)}
        wrap="wrap"
        width="100%"
      >
        {toArray(items)
          .filter(item => item != null)
          .map((component, index, array) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                value={component}
                disabled={
                  (disableFirst && index === 0) ||
                  (disableLast && array.length === index + offSet) ||
                  (disableLastN && index >= array.length - disableLastN)
                }
              />
            );
          })}
      </UICoreBox>
    );
  }
);

class UICoreReorderable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getDirection = () => {
    return this.props.direction || "column";
  };

  _getAxis = () => {
    return directionToAxisMap[this._getDirection()];
  };

  _getLockAxis = () => {
    return directionToLockAxisMap[this._getDirection()];
  };

  render() {
    return (
      <SortableList
        distance={5}
        direction={this.props.direction}
        lockToContainerEdges={true}
        axis={this._getAxis()}
        lockAxis={this._getLockAxis()}
        items={this.props.children}
        onSortEnd={this.props.onSortEnd}
        disableLast={this.props.disableLast}
        disableFirst={this.props.disableFirst}
        disableLastN={this.props.disableLastN}
      />
    );
  }
}
export default UICoreReorderable;
