//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox, UICoreText } from "../../../UICore";
import ClickFlash from "../../../Animations/ClickFlash";
import WithStyles from "../../../../Helper_HOC/WithStyles";
import withConfigurableButtonTextHOC, {
  type InjectedProps as InjectedPropsFromConfigurableButtonTextHOC,
  ButtonTypeFromHOC
} from "../../../../Helper_HOC/WithConfigurableButtonText";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import { kDefaultTextForPressEnterToContinue } from "../../../../Helper_HOC/WithConfigurableButtonText/config";

type Props = {|
  onClick: () => void,
  styles: formStyleType,
  answerSecondaryColor: string,
  buttonTextColor: string,
  buttonPrimaryColor: string,
  buttonBorderColor: string,
  hideDescriptionText?: boolean,
  ...InjectedPropsFromConfigurableButtonTextHOC
|};
type State = {||};
class TextSubmitButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const pressEnterText = this.props.buttonTextConfig[
      ButtonTypeFromHOC.pressEnterText
    ];
    return (
      <UICoreBox
        width="100%"
        direction="row"
        alignItems="center"
        justifyContent="end"
        className="TextSubmitButton-wrap"
      >
        {!this.props.hideDescriptionText && (
          <UICoreBox marginRight="xm">
            <div
              className="TextSubmitButton"
              style={{
                color: this.props.styles.answer
              }}
            >
              {pressEnterText === kDefaultTextForPressEnterToContinue ? (
                ["press ", <span className="TextSubmitButton-bold">enter</span>]
              ) : (
                <span className="TextSubmitButton-bold">
                  {
                    this.props.buttonTextConfig[
                      ButtonTypeFromHOC.pressEnterText
                    ]
                  }
                </span>
              )}
            </div>
          </UICoreBox>
        )}
        <ClickFlash onClick={this.props.onClick}>
          <div
            style={{
              backgroundColor: this.props.buttonPrimaryColor,
              borderColor: this.props.buttonBorderColor,
              color: this.props.buttonTextColor,
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
            className="TextSubmitButton-OK"
          >
            OK
          </div>
        </ClickFlash>
      </UICoreBox>
    );
  }
}
export default withConfigurableButtonTextHOC(WithStyles(TextSubmitButton));
