//@flow
import {
  type formStyleType,
  type backgroundCssType
} from "../../FlowTypes/wispformStyleTypes";

import { type questionMediaType } from "../../FlowTypes/questionTypes";

export type TOutcomeCTAData = {|
  text: string,
  url: string
|};

export type TOutcomeData = {|
  id: string,
  title: string,
  description?: string,
  media?: string,
  primaryCTA?: TOutcomeCTAData,
  secondaryCTA?: TOutcomeCTAData
|};

export const GENERIC_FORM_CONTENT_SECTION_TYPE = {
  HEADER: "HEADER",
  SUB_HEADER: "SUB_HEADER",
  OUTCOMES: "OUTCOMES",
  MEDIA: "MEDIA"
};

export type TGenericContentOutcomeLayout = "horizontal" | "vertical";
export type TGenericContentOutcomeConfig = {|
  layout: TGenericContentOutcomeLayout,
  outcomes: Array<TOutcomeData>
|};

export type TGenericContentConfig = {|
  supportedSections: Array<$Keys<typeof GENERIC_FORM_CONTENT_SECTION_TYPE>>,
  header?: {|
    title: string,
    description: string
  |},
  outcomeConfig?: TGenericContentOutcomeConfig,
  media?: questionMediaType
|};

export type TGenericFormComponentPro = {|
  config: TGenericContentConfig,
  backgroundCss: backgroundCssType,
  styles: formStyleType,
  backgroundCss: backgroundCssType,
  answerSecondaryColor: string,
  answerPrimaryColor: string,
  buttonTextColor: string,
  buttonPrimaryColor: string,
  isEditMode: boolean,
  onUpdate: (TGenericContentConfig, isHighFrequencyUpdate: boolean) => void
|};
