// @flow
export type formStyleType = {|
  background: background,
  button: string,
  text: string,
  answer: string,
  fontFamily?: string,
  buttonColor?: string,
  theme?: number
|};

export type background = {|
  color?: string,
  imageMobile?: string,
  imageDesktop?: string,
  type: $Keys<typeof backgroundType>,
  filter?: number
|};

export type backgroundCssType = {
  backgroundColor?: string,
  backgroundImage?: string
};

export const backgroundType = {
  color: "color",
  image: "image"
};

export const styleAttributes: {
  [$Keys<formStyleType>]: $Keys<formStyleType>
} = {
  background: "background",
  button: "button",
  text: "text",
  answer: "answer",
  fontFamily: "fontFamily",
  buttonColor: "buttonColor",
  theme: "theme"
};

export const designPanelSections = {
  theme: 0,
  background: 1,
  color: 2,
  font: 3
};

export const designPanelSectionNames = {
  theme: "Theme",
  background: "Background",
  font: "Font",
  color: "Color"
};

export type footerStyleCssType = {
  backgroundColor?: string
};

export type TTheme = {|
  name: string,
  id: number,
  answerColor: string,
  backgroundColor: string,
  buttonColor: string,
  cardImage: string,
  font: string,
  isPro: boolean,
  image: ?string,
  mobileImage: ?string,
  textColor: string
|};
