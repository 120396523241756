import React, { Component } from 'react';
import './style.css';

class Loading extends Component {
    render() {
        return (
            <div className={"Loading-spinner "+this.props.className}>
                <div className="double-bounce1"></div>
                <div className="double-bounce2"></div>
            </div>
        );
    }
}

export default Loading;