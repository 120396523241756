import React, { Component } from "react";
import LoadingScreen from "../Component/LoadingScreen";

// TODO: Remove loadingScreenType argument
export default function WithAsync(importComponent, loadingScreenType) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: importedComponent } = await importComponent();
      this.setState({
        component: importedComponent
      });
    }

    render() {
      const RenderComponent = this.state.component;
      if (RenderComponent) {
        return <RenderComponent {...this.props} />;
      } else {
        return <LoadingScreen style={loadingScreenType} />;
      }
    }
  }

  return AsyncComponent;
}
