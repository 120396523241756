//@flow

import { type Props as DialogType } from "../../../../Component/UICore/UICoreDialog";

export const SHOW_DIALOG = "SHOW_DIALOG";
export const CLOSE_DIALOG = "CLOSE_DIALOG";

type showDialogPayloadType = {|
  type: typeof SHOW_DIALOG,
  dialog: DialogType
|};

export function showDialog(dialog: DialogType) {
  //$FlowFixMe
  return function(dispatch, getState) {
    dispatch({
      type: SHOW_DIALOG,
      dialog: dialog
    });
  };
}

type closeDialogPayloadType = {|
  type: typeof CLOSE_DIALOG,
  dialog: null
|};
export function closeDialog() {
  //$FlowFixMe
  return function(dispatch, getState) {
    dispatch({
      type: CLOSE_DIALOG,
      dialog: null
    });
  };
}

export type actionTypes = showDialogPayloadType | closeDialogPayloadType;
