//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreText,
  UICoreImage,
  UICoreButton,
  UICoreImageUploader
} from "../../../UICore";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import {
  type questionMediaTypeEnumType,
  questionMediaTypeEnum
} from "../../../../FlowTypes/questionTypes";
import { uploadFileToAWS } from "../../../../Library/AwsHelper";
import Dropzone from "react-dropzone";
import TextareaAutosize from "react-autosize-textarea";
import { flash } from "../../../../Library/Animation";
import { logFeatureEvent } from "../../../../Library/Logger";
import "./style.css";

type SingleChoiceProps = {|
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  styles: formStyleType,
  choiceText: string,
  choicePicUrl: string,
  isInBuilder: boolean,
  isSelected: boolean,
  removePictureChoice: () => void,
  updateChoiceText: string => void,
  updateChoiceTextWithoutPersistence: string => void,
  updateChoicePic: string => void,
  onChoiceClick: () => void
|};
type SingleChoiceState = {|
  uploading: boolean,
  showImageModal: boolean
|};

export type pictureChoice = {|
  text: string,
  imageUrl: string
|};

export default class UI_PictureChoiceSingleChoice extends React.Component<
  SingleChoiceProps,
  SingleChoiceState
> {
  constructor(props: SingleChoiceProps) {
    super(props);
  }

  state = {
    uploading: false,
    showImageModal: false
  };

  _storeImage = (
    url: string,
    type: ?questionMediaTypeEnumType,
    originalWidth: ?number,
    originalHeight: ?number
  ) => {
    this.props.updateChoicePic(url);
  };

  _renderAddImageModal = () => {
    return (
      this.state.showImageModal && (
        <UICoreImageUploader
          onDismiss={() => {
            this.setState({ showImageModal: false });
          }}
          storeImage={this._storeImage}
          unsplashOrientation="landscape"
        />
      )
    );
  };

  _renderDefaultImageUploader = () => {
    return (
      <UICoreBox
        hexColor={this.props.answerPrimaryColor}
        height="143px"
        shape="rounded"
        justifyContent="center"
        alignItems="center"
        className="UI_PictureChoice-uploader"
        onClick={_ => {
          logFeatureEvent(
            "formbuilderPage/uiCoreImageUploaderOpenedPictureChoice"
          );
          this.setState({ showImageModal: true });
        }}
      >
        <UICoreBox
          direction="column"
          className="UI_PictureChoice-uploader-uploader"
          alignItems="center"
          justifyContent="center"
        >
          <UICoreBox paddingBottom="xm">
            <i
              style={{
                color: this.props.styles.answer,
                fontSize: "28px"
              }}
              className="fa fa-image"
            />
          </UICoreBox>
          <UICoreText hexColor={this.props.styles.answer} size="xs">
            Upload an image
          </UICoreText>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderSpinner = () => {
    return (
      <div class="UI_PictureChoice-spinner">
        <div
          style={{ backgroundColor: this.props.styles.answer }}
          class="bounce1"
        />
        <div
          style={{ backgroundColor: this.props.styles.answer }}
          class="bounce2"
        />
        <div
          style={{ backgroundColor: this.props.styles.answer }}
          class="bounce3"
        />
      </div>
    );
  };

  _handleChoiceClick = (event: SyntheticInputEvent<>) => {
    if (this.props.isInBuilder) return;
    //$FlowFixMe
    flash(event.currentTarget, 400, () => {
      this.props.onChoiceClick();
    });
  };

  render() {
    return (
      <UICoreBox
        className={
          "UI_PictureChoice-Choice " +
          (this.props.isInBuilder ? "" : "UI_PictureChoice-FormChoice")
        }
        position="relative"
        padding="xm"
        width="50%"
        onClick={this._handleChoiceClick}
      >
        {this.props.isInBuilder && this._renderAddImageModal()}

        {this.props.isInBuilder && (
          <UICoreBox
            color="white"
            width="25px"
            height="25px"
            shape="circle"
            justifyContent="center"
            alignItems="center"
            hoverable={true}
            boxShadow={true}
            position="absolute"
            top="0px"
            right="0px"
            className="UI_PictureChoice-CloseButton"
            onClick={this.props.removePictureChoice}
          >
            <i className="ion-close UI_PictureChoice-Close" />
          </UICoreBox>
        )}
        <UICoreBox
          borderColor={this.props.styles.answer}
          shape="rounded"
          hexColor={
            this.props.isSelected
              ? this.props.answerPrimaryColor
              : this.props.answerSecondaryColor
          }
          borderWidth={this.props.isSelected ? 3 : 1}
          padding="xm"
          height="100%"
        >
          <UICoreBox paddingBottom="xxm">
            {this.state.uploading ? (
              <UICoreBox
                hexColor={this.props.answerPrimaryColor}
                height="143px"
                shape="rounded"
                justifyContent="center"
                alignItems="center"
              >
                {this._renderSpinner()}
              </UICoreBox>
            ) : this.props.choicePicUrl || !this.props.isInBuilder ? (
              <UICoreBox className="UI_PictureChoice-pic" position="relative">
                <UICoreImage height="143px" src={this.props.choicePicUrl} />
                {this.props.isInBuilder && (
                  <UICoreBox
                    className="UI_PictureChoice-update"
                    position="absolute"
                    width="100%"
                    height="100%"
                    top="0px"
                    left="0px"
                    shape="rounded"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <UICoreButton
                      size="sm"
                      color="white"
                      onClick={_ => {
                        logFeatureEvent(
                          "formbuilderPage/uiCoreImageUploaderOpenedPictureChoice"
                        );
                        this.setState({ showImageModal: true });
                      }}
                    >
                      Update
                    </UICoreButton>
                  </UICoreBox>
                )}
              </UICoreBox>
            ) : (
              this._renderDefaultImageUploader()
            )}
          </UICoreBox>
          <UICoreBox marginTop="xm" position="relative" minHeight="50px">
            {this.props.isInBuilder ? (
              [
                !this.props.choiceText && (
                  <span
                    style={{ color: this.props.styles.answer }}
                    className="UI_PictureChoice-default"
                  >
                    choice text
                  </span>
                ),
                <TextareaAutosize
                  style={{
                    color: this.props.styles.answer,
                    borderBottomColor: this.props.styles.answer,
                    fontFamily:
                      this.props.styles && this.props.styles.fontFamily
                  }}
                  className="UI_PictureChoice-textArea"
                  value={this.props.choiceText}
                  onBlur={(e: SyntheticInputEvent<any>) =>
                    this.props.updateChoiceText(e.target.value)
                  }
                  onInput={(e: SyntheticInputEvent<any>) =>
                    this.props.updateChoiceTextWithoutPersistence(
                      e.target.value
                    )
                  }
                />
              ]
            ) : (
              <UICoreText
                fontFamily={this.props.styles && this.props.styles.fontFamily}
                overflow="wrap"
                hexColor={this.props.styles.answer}
              >
                {this.props.choiceText}
              </UICoreText>
            )}
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
