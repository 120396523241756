import { serverAddress } from "../../../../config";
import { asyncExe } from "../../../../Library";
import {
  validateAnswer,
  getNextInvalidQuestionID,
  validateForm
} from "./validation";
import { getUrlVars } from "../../../../Library";
import {
  toStringOrNull,
  executeCallbackSafely,
  safeGet,
  toArray,
  toNumber,
  isLocalStorageAvailable
} from "../../../../Library/Util";
import {
  getVisibleQuestions,
  getScorePercentage,
  getSubscriptionPlanIfAny,
  hasSubscriptionQuestion,
  needToGetStripeToken,
  getFirstEmailAnswer,
  getTotalScore,
  getEligibleOutcomes
} from "./selectors";
import { getToken } from "../../../../Component/QuestionComponents/Shared/PaymentCard/stripeToken";
import {
  paymentQuestionID,
  paymentQuestionCurrency,
  subscriptionQuestionID,
  hasPaymentQuestion,
  paymentQuestionIndexInAllQuestions,
  subscriptionQuestionIndexInAllQuestions,
  paymentQuestionLogic,
  paymentQuestionDefaultAmount,
  getRedirectURL
} from "./selectors";
import { showNotification } from "../../../WispTable/State/NotificationState/action";
import WebStorage from "../../../../Library/WebStorage";
import {
  logQuestionSubmitClientCustomEvent,
  logFormSubmitClientCustomEvent
} from "../../../../Library/ClientCustomLogger";
import { calculatePrice } from "../../../../Library/DynamicPrice";
import { isInIframe } from "../../../../Library/Window";
import { enableRedirectParentWindowInIframe } from "../../../../Library/GateKeeper/formViewerGateKeeper";
import { paymentTypeEnum } from "../../../../FlowTypes/paymentType";
import { convertStringRep2TagFilledString } from "../../../../Component/UICore/UICoreTagInput/InputManager";
import {
  getQuestionIdPipedAnswerMap,
  getTagInputOptions
} from "../../../../Library/AnswerPiping";
import { getJumptoQuestionID } from "../../../../Middlewares/LogicJumpMiddleware/helper";

export const SUBMIT_ANSWER = "SUBMIT_ANSWER";
export const INITIALIZE_ANSWER_OBJ = "INITIALIZE_ANSWER_OBJ";
export const POST_ANSWERS = "POST_ANSWERS";
export const NEXT_QUESTION = "NEXT_QUESTION";
export const PREV_QUESTION = "PREV_QUESTION";
export const DID_POST_ANSWERS = "DID_POST_ANSWER";
export const DID_GET_QUESTIONS = "DID_GET_QUESTIONS";
export const UPDATE_VISIBLE_QUESTIONS = "UPDATE_VISIBLE_QUESTIONS";
export const SET_CURRENT_QUESTION = "SET_CURRENT_QUESTION";
export const POSTING_ANSWERS = "POSTING_ANSWERS";
export const UPDATE_ANSWER = "UPDATE_ANSWER";
export const UPDATE_VIEWED_QUESTIONS = "UPDATE_VIEWED_QUESTIONS";
export const ADD_VALIDATION_MESSAGE = "ADD_VALIDATION_MESSAGE";
export const REMOVE_ALL_VALIDATION_MESSAGES = "REMOVE_ALL_VALIDATION_MESSAGES";
export const FAILED_POSTING = "FAILED_POSTING";
export const ADD_TIME_TO_COMPLETE = "ADD_TIME_TO_COMPLETE";
export const UPDATE_ANSWER_WITHOUT_UPDATE_CURRENT_QUESTION =
  "UPDATE_ANSWER_WITHOUT_UPDATE_CURRENT_QUESTION";

export function submit_answer(answer) {
  return (dispatch, getState) => {
    setTimeout(() => {
      console.log("Socre: ==========> ", getTotalScore(getState().Answers));
    }, 10);
    // check if answer is valid
    //use questionID instead of index because index is of invisible filtered
    //but questions contains all of the questions
    const questionID = answer.questionID;
    const allVisibleQuestions = getVisibleQuestions(getState().Answers);
    const question = allVisibleQuestions.find(
      question => question.question_id === questionID
    );
    logQuestionSubmitClientCustomEvent(question.title);
    const customized_button_text = safeGet(
      _ => getState().Answers.configurations.buttonText
    );
    const is_valid = validateAnswer(
      answer,
      question,
      dispatch,
      customized_button_text
    );
    //if answer is valid, submit and proceed to another question
    if (is_valid) {
      //if user haven't attempted submission
      //proced to next question
      if (!getState().Answers.submit_attempted) {
        dispatch({
          type: SUBMIT_ANSWER,
          answer
        });
      }
      //if user already attempted submision
      //proces to next invalid question
      else {
        dispatch(update_answer(answer));
        const invalid_answers = getState().Answers.invalid_answers;
        const next_index = getNextInvalidQuestionID(
          answer.questionID,
          invalid_answers,
          allVisibleQuestions
        );
        //delay 200ms to prevent scroll animation conflict with update answer
        //because update anser will set curentquestion id cause shift
        setTimeout(() => {
          dispatch(set_current_question(next_index));
        }, 200);
      }
    }
  };
}

export function add_validation_message(questionID, message) {
  return {
    type: ADD_VALIDATION_MESSAGE,
    questionID,
    message
  };
}

export function remove_all_validation_message(questionID) {
  return {
    type: REMOVE_ALL_VALIDATION_MESSAGES,
    questionID
  };
}

export function update_viewed_questions(answer) {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_VIEWED_QUESTIONS, answer });
  };
}

export function update_answer(answer, should_validate = false) {
  return (dispatch, getState) => {
    setTimeout(() => {
      console.log("Socre: ==========> ", getTotalScore(getState().Answers));
    }, 10);
    if (should_validate) {
      const index = answer.index;
      const question = getState().Answers.questions.questions[index];
      const customized_button_text = safeGet(
        _ => getState().Answers.configurations.buttonText
      );
      validateAnswer(answer, question, dispatch, customized_button_text);
      dispatch({
        type: UPDATE_ANSWER,
        answer
      });
    } else {
      dispatch({
        type: UPDATE_ANSWER,
        answer
      });
    }
  };
}

export function updateAnswerWithouUpdateCurrentQuestionIndex(answer) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_ANSWER_WITHOUT_UPDATE_CURRENT_QUESTION,
      answer
    });
  };
}

export function add_time_to_complete() {
  return {
    type: ADD_TIME_TO_COMPLETE
  };
}

export function next_question() {
  return {
    type: NEXT_QUESTION
  };
}

export function prev_question() {
  return {
    type: PREV_QUESTION
  };
}

export function initialize_answer_obj(answer_info) {
  return {
    type: INITIALIZE_ANSWER_OBJ,
    answer_info
  };
}

export function did_post_answers() {
  //clear start timestamp for recording form completion time
  WebStorage.saveToWebStorage("start_time", "");
  return {
    type: DID_POST_ANSWERS
  };
}

export function posting_answers() {
  return {
    type: POSTING_ANSWERS
  };
}

export function failed_posting() {
  return {
    type: FAILED_POSTING
  };
}

function _getMonetaryQuestionType(answers) {
  const hasPayment = hasPaymentQuestion(answers);
  const hasSubscription = hasSubscriptionQuestion(answers);
  if (hasPayment && hasSubscription) {
    return paymentTypeEnum.subscriptionAndPayment;
  } else {
    if (hasPayment) {
      return paymentTypeEnum.oneTime;
    } else {
      return paymentTypeEnum.subscription;
    }
  }
}

export function post_answers(form_name, publisher_name, apiPostForm) {
  return async (dispatch, getState) => {
    dispatch(posting_answers());

    /**
     * Get stripe token if has payment type
     */
    let stripeToken = null;
    if (needToGetStripeToken(getState().Answers)) {
      stripeToken = await getToken();
    }

    /**
     * Get uesr's answer
     */
    const questions = getVisibleQuestions(getState().Answers);
    const answers = getState().Answers.answers;

    /**
     * Validate user answer
     */

    const customized_button_text = safeGet(
      _ => getState().Answers.configurations.buttonText
    );

    const is_valid = validateForm(
      questions,
      answers,
      customized_button_text,
      dispatch
    );

    // FINAL PAGE CONTENT
    let finalPageContent = null;
    if (getRedirectURL(getState().Answers)) {
      finalPageContent = {
        type: "redirect_on_submission",
        content: getRedirectURL(getState().Answers)
      };
    } else {
      if (
        getState().Answers.pages &&
        getState().Answers.pages.outcomePage &&
        getState().Answers.pages.outcomePage.pageContentConfig
      ) {
        // Outcome Pages logic
        const outcomes = getEligibleOutcomes(getState().Answers);
        finalPageContent = {
          type: "outcome_page",
          content: "",
          outcomes: outcomes.outcomeConfig.outcomes
        };
      } else if (
        getState().Answers.pages &&
        getState().Answers.pages.thankyouPages &&
        getState().Answers.pages.thankyouPages.pages &&
        getState().Answers.pages.thankyouPages.pages.length != 0
      ) {
        let TYPageID =
          getJumptoQuestionID(
            { jumpLogic: getState().Answers.pages.thankyouPages.displayLogic },
            getState().Answers.answers,
            {},
            getState().Answers.questions.questions
          ) || 0;

        finalPageContent = {
          type: "thankyou_page",
          content:
            getState().Answers.pages.thankyouPages.pages[toNumber(TYPageID)][
              "thankyouNote"
            ]
        };
      } else {
        finalPageContent = {
          type: "thankyou_page",
          content: "Default Thank You Page"
        };
      }
    }

    /**
     * Post form if answers passed validation
     */
    if (is_valid == "true") {
      const urlVars = getUrlVars();
      getState().Answers.answers.map(obj =>
        Object.assign(obj, {
          title: convertStringRep2TagFilledString(
            String(obj.title),
            getQuestionIdPipedAnswerMap(toArray(getState().Answers.answers))
          )
        })
      );
      // Set the form view uuid
      let form_view_uuid = "";
      let viewed_questions = {};
      if (getState().Answers && getState().Answers.viewed_questions) {
        viewed_questions = getState().Answers.viewed_questions;
      }
      if (
        getState().Answers &&
        getState().Answers.form_id &&
        localStorage.getItem(getState().Answers.form_id)
      ) {
        form_view_uuid = localStorage.getItem(getState().Answers.form_id);
      }
      apiPostForm({
        ...getState().Answers,
        table_id: toStringOrNull(urlVars["table"]),
        row_id: toStringOrNull(urlVars["row"]),
        column_id: toStringOrNull(urlVars["col"]),
        contact_id:
          urlVars["contact"] === "0"
            ? null
            : toStringOrNull(urlVars["contact"]),
        stripe: {
          type: _getMonetaryQuestionType(getState().Answers),
          plan_id: getSubscriptionPlanIfAny(getState().Answers),
          price: calculatePrice(
            paymentQuestionLogic(getState().Answers),
            paymentQuestionDefaultAmount(getState().Answers),
            getState().Answers.answers
          ),
          sourceID: stripeToken,
          paymentQuestionID: paymentQuestionID(getState().Answers),
          paymentQuestionCurrency: paymentQuestionCurrency(getState().Answers),
          paymentQuestionIndex: paymentQuestionIndexInAllQuestions(
            getState().Answers
          ),
          subscriptionQuestionID: subscriptionQuestionID(getState().Answers),
          subscriptionQuestionIndex: subscriptionQuestionIndexInAllQuestions(
            getState().Answers
          ),
          email: getFirstEmailAnswer(getState().Answers)
        },
        variables: {
          score: getScorePercentage(getState().Answers)
        },
        existing_form_view_uuid: form_view_uuid,
        viewed_questions: viewed_questions,
        finalPageContent: finalPageContent
      })
        .then(response => {
          //delay to show spinner
          setTimeout(() => {
            dispatch(did_post_answers());
            //redirect if redirect link is set
            if (getState().Answers && getState().Answers.form_id) {
              if (isLocalStorageAvailable()) {
                localStorage.setItem(
                  "submitted-" + getState().Answers.form_id,
                  localStorage.getItem(getState().Answers.form_id)
                );
                localStorage.removeItem(getState().Answers.form_id);
              }
            }
            if (getRedirectURL(getState().Answers)) {
              const redirectURL = getRedirectURL(getState().Answers);
              if (isInIframe() && enableRedirectParentWindowInIframe()) {
                executeCallbackSafely(_ => {
                  window.top.location.href = redirectURL;
                });
              } else {
                window.location.assign(redirectURL);
              }
            }
          }, 400);
          //log to custom GA
          logFormSubmitClientCustomEvent();
        })
        .catch(function (error) {
          dispatch(failed_posting());
          dispatch(
            showNotification(
              "error",
              "Submission failed :(",
              "Please try agian in a few seconds"
            )
          );
        });
    } else {
      const first_index = is_valid;
      //delay 300 seconds to allow submit area to be completed removed
      //otherwise question wont be positioned at center
      setTimeout(() => {
        dispatch(set_current_question(first_index));
        dispatch(failed_posting());
      }, 500);
    }
  };
}

export function did_get_questions(questions) {
  return {
    type: DID_GET_QUESTIONS,
    questions: questions
  };
}

export function update_visible_questions(questions) {
  return {
    type: UPDATE_VISIBLE_QUESTIONS,
    questions: questions
  };
}

export function set_current_question(id) {
  return {
    type: SET_CURRENT_QUESTION,
    id: id
  };
}
