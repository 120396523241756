// @flow

const kMaxMobileScreenWidth = 480;
const kMaxSmallMobileScreenWidth = 350;

export type screenSizeType = {|
  width: number,
  height: number
|};

export function getScreenSize(): screenSizeType {
  const height = window.innerHeight;
  const width = window.innerWidth;

  return {
    width,
    height
  };
}

export function getScreenWidth(): number {
  return window.innerWidth;
}

export function isMobileScreen(): boolean {
  const screenWidth = getScreenSize().width;
  return screenWidth <= kMaxMobileScreenWidth;
}

/*
  iPhone SE, 5, 4
*/
export function isSmallMobileScreen(): boolean {
  const screenWidth = getScreenSize().width;
  return screenWidth <= kMaxSmallMobileScreenWidth;
}

export function isTablet(): boolean {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTablet =
    /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
      userAgent
    );
  return isTablet;
}

export function isInIframe(): boolean {
  try {
    return window.location !== window.parent.location;
  } catch (error) {
    return false;
  }
}

export function isIosSDevice(): boolean {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
}
