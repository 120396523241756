// @flow
import "./style.css";

export function applyFlashAnimation(
  targetElement: ?HTMLElement,
  duration?: number
) {
  targetElement && targetElement.classList.add("Animation-flash");
  setTimeout(() => {
    targetElement && targetElement.classList.remove("Animation-flash");
  }, duration ? duration : 600);
}
