//@flow
import {
  type cellValueType,
  type columnIDType,
  type tableType,
  type tableIDType,
  type cellColumnIdMapType,
  type rowIDType,
  type rowIndexType,
  type tableDataRowType,
  type tableSchemaColumnType,
  type wispformIDType,
  type columnConfigType,
  type tableMetaDataType,
  type tableDatasType,
  type columnTypes
} from "../../Configuration";
import {
  getTables as apiGetTables,
  getTable as apiGetTable,
  updateRow as apiUpdateRow,
  createRow as apiCreateRow,
  deleteRow as apiDeleteRow,
  type apiFormsAndWorkspacesreturnType,
  getFormsAndWorkspaces as apiGetFormsAndWorkspaces,
  updateSchema as apiUpdateSchema,
  type apiTableReturnType,
  addTableFromWispform as apiAddTableFromWispform,
  deleteTable as apiDeleteTable,
  updateTableName as apiUpdateTableName,
  saveTableMetaDatas as apiSaveTableMetaDatas,
  createEmptyTable as apiCreateEmptyTable
} from "../../api";
import { showWarning } from "../../../FormBuilderPage/States/actions";
import {
  addTable,
  removeTable,
  getRow,
  addRow,
  getNewColumnID,
  addNewColumn as addNewColumnHelper,
  getPositionByColumnID,
  updateColumn,
  getFirstTableID,
  updateTable,
  getUpdatedRows
} from "./helper";
import { type stateType } from "./reducer";
import {
  getCurrentTableID,
  getCurrentRows,
  getMetaDatas,
  getTableDatas,
  getCurrentEditingColumnID
} from "./selectors";

export const ADD_TABLE_FROM_WISPFORM = "ADD_TABLE_FROM_WISPFORM";
export const CREATE_EMPTY_TABLE = "CREATE_EMPTY_TABLE";
export const CREATE_ROW = "CREATE_ROW";
export const DELETE_ROWS = "DELETE_ROWS";
export const UPDATE_CELL = "UPDATE_CELL";
export const ADD_COLUMN = "ADD_COLUMN";
export const DELETE_CURRENT_EDITING_COLUMN = "DELETE_CURRENT_EDITING_COLUMN";
export const DID_FETCH_TABLES = "DID_FETCH_TABLE_IDS";
export const DID_FETCH_TABLE = "DID_FETCH_TABLE";
export const DID_FETCH_WISPFORMS_AND_WORKSPACES =
  "DID_FETCH_WISPFORMS_AND_WORKSPACES";
export const UPDATE_CURRENT_EDITING_COLUMN = "UPDATE_CURRENT_EDITING_COLUMN";
export const SET_CURRENT_TABLE_ID = "SET_CURRENT_TABLE_ID";
export const SET_CURRENT_EDITING_COLUMN_ID = "SET_CURRENT_EDITING_COLUMN_ID";
export const DELETE_TABLE = "DELETE_TABLE";
export const UPDATE_TABLE_NAME = "UPDATE_TABLE_NAME";
export const UPDATE_COLUMN_CONFIG = "UPDATE_COLUMN_CONFIG";
export const UPDATE_COLUMN_WIDTH = "UPDATE_COLUMN_WIDTH";
export const SAVE_META_DATA = "SAVE_META_DATA";
export const UPDATE_TABLE_META_DATAS = "UPDATE_TABLE_META_DATAS";

export type addTableFromWispformPayloadType = {|
  type: typeof ADD_TABLE_FROM_WISPFORM,
  addedTableID: tableIDType,
  tables: Array<tableType>
|};

export function addTableFromWispform(
  wispformID: wispformIDType,
  cb?: () => void
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const currentTables = TableState.tables;
    apiAddTableFromWispform(wispformID)
      .then((addedTable: tableType) => {
        dispatch({
          type: ADD_TABLE_FROM_WISPFORM,
          addedTableID: addedTable.id,
          tables: addTable(addedTable, currentTables)
        });
        cb && cb();
      })
      .catch(error => {});
  };
}

export type createEmptyTablePayloadType = {|
  type: typeof CREATE_EMPTY_TABLE,
  addedTableID: tableIDType,
  tables: Array<tableType>
|};

export function createEmptyTable(cb?: () => void) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const currentTables = TableState.tables;
    apiCreateEmptyTable()
      .then((createdTable: tableType) => {
        dispatch({
          type: CREATE_EMPTY_TABLE,
          addedTableID: createdTable.id,
          tables: addTable(createdTable, currentTables)
        });
        cb && cb();
      })
      .catch(error => {});
  };
}

export type addColumnReturnType = {|
  type: typeof ADD_COLUMN,
  updatedColumns: Array<tableSchemaColumnType>,
  newColumnID: columnIDType
|};
export type positionType = "left" | "right" | "end";
/**
 *
 * @param {positionType} position:
 *  if not supplied, default to the end
 *  left and right are relative to current position
 */
export function addColumn(position?: positionType) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const currentColumns = TableState.tableSchemas.columns;
    const tableID: ?tableIDType = TableState.currentTableID;
    if (!tableID) {
      return;
    }
    const newColumnID: columnIDType = getNewColumnID();
    const CurrentEditingColumnID: ?columnIDType =
      TableState.currentEditingColumnID;

    //calculate target insertion position if position is specified
    let targetPosition: ?number = null;
    if (CurrentEditingColumnID) {
      const currentEditingColumnPosition = getPositionByColumnID(
        CurrentEditingColumnID,
        currentColumns
      );
      if (position == "left") {
        targetPosition = currentEditingColumnPosition;
      }
      if (position == "right") {
        targetPosition = currentEditingColumnPosition + 1;
      }
    }

    const updatedColumns = addNewColumnHelper(
      currentColumns,
      newColumnID,
      targetPosition
    );
    //optimistically update redux
    dispatch({
      type: ADD_COLUMN,
      updatedColumns: updatedColumns,
      newColumnID: newColumnID
    });
    apiUpdateSchema(tableID, updatedColumns)
      .then(response => {})
      .catch(error => {
        //TODO: @taoxiang
        //display error message
        //save api request to db and retry
      });
  };
}

export type updateColumnWidthPayloadType = {|
  type: typeof UPDATE_COLUMN_WIDTH,
  tableMetaDatas: tableMetaDataType
|};
export function updateColumnWidth(columnID: columnIDType, width: number) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const currentTableMetaDatas = getMetaDatas(getState().WispTable);
    dispatch({
      type: UPDATE_COLUMN_WIDTH,
      tableMetaDatas: {
        ...currentTableMetaDatas,
        columnWidth: {
          ...currentTableMetaDatas.columnWidth,
          [columnID]: width
        }
      }
    });
  };
}

export type deleteCurrentEditingColumnPayloadType = {|
  type: typeof DELETE_CURRENT_EDITING_COLUMN,
  updatedColumns: Array<tableSchemaColumnType>
|};

export function deleteCurrentEditingColumn() {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const CurrentEditingColumnID: ?columnIDType =
      TableState.currentEditingColumnID;
    const tableID: ?tableIDType = TableState.currentTableID;
    if (!CurrentEditingColumnID || !tableID) {
      return;
    }
    const currentColumns: Array<tableSchemaColumnType> =
      TableState.tableSchemas.columns;
    const currentEditingColumnPosition: number = getPositionByColumnID(
      CurrentEditingColumnID,
      currentColumns
    );
    const updatedColumns = [
      ...currentColumns.slice(0, currentEditingColumnPosition),
      ...currentColumns.slice(currentEditingColumnPosition + 1)
    ];

    dispatch({
      type: DELETE_CURRENT_EDITING_COLUMN,
      updatedColumns: updatedColumns
    });
    apiUpdateSchema(tableID, updatedColumns)
      .then(response => {})
      .catch(error => {
        //TODO: @taoxiang
        //display error message
        //save api request to db and retry
      });
  };
}

type setCurrentEditingColumnIDPayloadType = {|
  type: typeof SET_CURRENT_EDITING_COLUMN_ID,
  columnID: columnIDType
|};

export function setCurrentEditingColumnID(columnID: columnIDType) {
  return {
    type: SET_CURRENT_EDITING_COLUMN_ID,
    columnID: columnID
  };
}

type createRowPayloadType = {|
  type: typeof CREATE_ROW,
  rows: Array<tableDataRowType>
|};

export function createRow() {
  //$FlowFixMe
  return function(dispatch, getState) {
    const currentTableID = getCurrentTableID(getState().WispTable);
    const currentRows = getCurrentRows(getState().WispTable);

    if (!currentTableID) return;
    apiCreateRow(currentTableID).then((newRow: tableDataRowType) => {
      dispatch({
        type: CREATE_ROW,
        rows: addRow(currentRows, newRow)
      });
    });
  };
}

export type deleteRowsPayloadType = {|
  type: typeof DELETE_ROWS,
  rows: Array<tableDataRowType>
|};

export function deleteRows(rowIDs: Array<rowIDType>) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const currentTableID = getCurrentTableID(getState().WispTable);
    const currentRows = getCurrentRows(getState().WispTable);
    if (!currentTableID) return;
    const updatedRows: Array<tableDataRowType> = currentRows.filter(
      (row: tableDataRowType) => !rowIDs.includes(row.id)
    );
    //proactively update for better UX
    dispatch({
      type: DELETE_ROWS,
      rows: updatedRows
    });
    apiDeleteRow(rowIDs, currentTableID)
      .then(data => {
        //nothing
      })
      .catch(err => {
        //revert back
        dispatch({
          type: DELETE_ROWS,
          rows: currentRows
        });
      });
  };
}

export type updateCellReturnType = {|
  type: typeof UPDATE_CELL,
  tableDatas: tableDatasType
|};

export function updateCell(
  rowID: rowIDType,
  columnID: columnIDType,
  cellValue: cellValueType
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const rows = getTableDatas(getState().WispTable);
    const row = getRow(rows, rowID);
    if (!row) {
      return;
    }
    const currentCellColumnIdMap = row.cellColumnIdMap;
    const currentCellValue =
      currentCellColumnIdMap && currentCellColumnIdMap[columnID];
    const updatedCellColumnIdMap = {
      ...currentCellColumnIdMap,
      [columnID]: cellValue
    };
    //optimistically update the redux
    //for better UX
    dispatch({
      type: UPDATE_CELL,
      tableDatas: getUpdatedRows(rows, rowID, columnID, cellValue)
    });
    apiUpdateRow(
      getState().WispTable.currentTableID,
      rowID,
      updatedCellColumnIdMap
    )
      .then(response => {})
      .catch(function(error) {
        //reverted back to current value if update failed in server
        dispatch({
          type: UPDATE_CELL,
          tableDatas: rows
        });
        dispatch(showWarning());
      });
  };
}

export function fetchTableList() {
  //$FlowFixMe
  return function(dispatch, getState) {
    apiGetTables()
      .then((response: Array<tableType>) => {
        const FirstTableID = response[0].id;
        dispatch(setCurrentTableID(FirstTableID));
        dispatch(didFetchTables(response));
      })
      .catch(function(error) {
        //TODO: @taoxiang handle error instead of using action from builder
        dispatch(showWarning());
      });
  };
}

export function fetchFormsAndWorkspaces() {
  //$FlowFixMe
  return function(dispatch, getState) {
    apiGetFormsAndWorkspaces()
      .then((formsAndWorkspaces: apiFormsAndWorkspacesreturnType) => {
        dispatch(didFetchWispformsAndWorkspaces(formsAndWorkspaces));
      })
      .catch(function(error) {
        //TODO: @taoxiang handle error instead of using action from builder
        dispatch(showWarning());
      });
  };
}

type didFetchWispformsAndWorkspacesReturnType = {|
  type: typeof DID_FETCH_WISPFORMS_AND_WORKSPACES,
  formsAndWorkspaces: apiFormsAndWorkspacesreturnType
|};

export function didFetchWispformsAndWorkspaces(
  formsAndWorkspaces: apiFormsAndWorkspacesreturnType
) {
  return {
    type: DID_FETCH_WISPFORMS_AND_WORKSPACES,
    formsAndWorkspaces
  };
}

type fetchTablesReturnType = {|
  type: typeof DID_FETCH_TABLES,
  tables: Array<tableType>
|};

export function didFetchTables(tableIDs: Array<tableType>) {
  return {
    type: DID_FETCH_TABLES,
    tables: tableIDs
  };
}

export function fetchTable(id: tableIDType) {
  //$FlowFixMe
  return function(dispatch, getState) {
    apiGetTable(id)
      .then((response: apiTableReturnType) => {
        dispatch(didFetchTable(response));
      })
      .catch(function(error) {
        //TODO: @taoxiang handle error instead of using action from builder
        dispatch(showWarning());
      });
  };
}

export type deleteTablePayloadType = {|
  type: typeof DELETE_TABLE,
  currentTableID: tableIDType,
  tables: Array<tableType>
|};

export function deleteTable(tableID: tableIDType, cb?: () => void) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const currentTables = TableState.tables;
    apiDeleteTable(tableID)
      .then(response => {
        const updatedTables = removeTable(tableID, currentTables);
        dispatch({
          type: DELETE_TABLE,
          currentTableID: getFirstTableID(updatedTables),
          tables: updatedTables
        });
        cb && cb();
      })
      .catch(error => {});
  };
}

export type updateTableNamePayloadType = {|
  type: typeof UPDATE_TABLE_NAME,
  tables: Array<tableType>
|};

export function updateTableName(
  tableID: tableIDType,
  tableName: string,
  cb?: () => void
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const currentTables = TableState.tables;
    const updatedTables = updateTable(
      { name: tableName },
      tableID,
      currentTables
    );

    //optimisitcally update
    dispatch({
      type: UPDATE_TABLE_NAME,
      tables: updatedTables
    });

    apiUpdateTableName(tableID, tableName)
      .then(response => {})
      .catch(error => {
        dispatch({
          type: UPDATE_TABLE_NAME,
          tables: currentTables
        });
      });
  };
}

type fetchTableReturnType = {|
  type: typeof DID_FETCH_TABLE,
  table: apiTableReturnType
|};

export function didFetchTable(table: apiTableReturnType) {
  return {
    type: DID_FETCH_TABLE,
    table: table
  };
}

type updateColumnConfigPayloadType = {|
  type: typeof UPDATE_COLUMN_CONFIG,
  updatedColumns: Array<tableSchemaColumnType>
|};

export function updateColumnConfig(
  columnID: columnIDType,
  updatedConfig: columnConfigType
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const TableID = TableState.currentTableID;
    if (!TableID) {
      return;
    }
    const CurrentColumns = TableState.tableSchemas.columns;

    const UpdatedColumns = updateColumn(
      CurrentColumns,
      {
        config: updatedConfig
      },
      columnID
    );

    dispatch({
      type: UPDATE_COLUMN_CONFIG,
      updatedColumns: UpdatedColumns
    });
    apiUpdateSchema(TableID, UpdatedColumns)
      .then(response => {})
      .catch(error => {
        //revert back
        dispatch({
          type: UPDATE_COLUMN_CONFIG,
          updatedColumns: CurrentColumns
        });
      });
  };
}

/*
  For now, when change column type, we will assign a new column id to get rid off old cell value

  TODO: @taoxiang convert old cell value to new cell
*/
export function changeCurrentEditingColumnType(newColumnType: columnTypes) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const newColumnID: columnIDType = getNewColumnID();
    dispatch(
      updateCurrentEditingColumn({
        id: newColumnID,
        type: newColumnType,
        config: {}
      })
    );
    dispatch(setCurrentEditingColumnID(newColumnID));
  };
}

export function changeCurrentEditingFormColumnLinkedInForm(
  newFormID: wispformIDType
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const newColumnID: columnIDType = getNewColumnID();
    dispatch(
      updateCurrentEditingColumn({
        id: newColumnID,
        config: {
          formID: newFormID
        }
      })
    );
    dispatch(setCurrentEditingColumnID(newColumnID));
  };
}

type updateCurrentEditingColumnReturnType = {|
  type: typeof UPDATE_CURRENT_EDITING_COLUMN,
  updatedColumns: Array<tableSchemaColumnType>
|};

export function updateCurrentEditingColumn(
  updatedAttributes: $Shape<tableSchemaColumnType>
) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const TableState: stateType = getState().WispTable;
    const CurrentEditingColumnID: ?columnIDType =
      TableState.currentEditingColumnID;
    const TableID: ?tableIDType = TableState.currentTableID;
    if (!CurrentEditingColumnID || !TableID) {
      return;
    }
    const CurrentColumns: Array<tableSchemaColumnType> =
      TableState.tableSchemas.columns;

    const UpdatedColumns: Array<tableSchemaColumnType> = updateColumn(
      CurrentColumns,
      updatedAttributes,
      CurrentEditingColumnID
    );
    dispatch({
      type: UPDATE_CURRENT_EDITING_COLUMN,
      updatedColumns: UpdatedColumns
    });
    apiUpdateSchema(TableID, UpdatedColumns);
  };
}

type setCurrentTableIDReturnType = {|
  type: typeof SET_CURRENT_TABLE_ID,
  currentTableID: tableIDType
|};
export function setCurrentTableID(currentTableID: tableIDType) {
  return {
    type: SET_CURRENT_TABLE_ID,
    currentTableID: currentTableID
  };
}

export function saveMetaData() {
  //$FlowFixMe
  return function(dispatch, getState) {
    const currentTableID = getCurrentTableID(getState().WispTable);
    const tableMetaDatas = getMetaDatas(getState().WispTable);
    if (currentTableID) {
      apiSaveTableMetaDatas(currentTableID, tableMetaDatas);
    }
  };
}

type updateMetaDatasPayloadType = {|
  type: typeof UPDATE_TABLE_META_DATAS,
  tableMetaDatas: tableMetaDataType
|};

export function updateMetaDatas(updatedAttributes: $Shape<tableMetaDataType>) {
  //$FlowFixMe
  return function(dispatch, getState) {
    const CurrentTableMetaDatas = getMetaDatas(getState().WispTable);
    const currentTableID = getCurrentTableID(getState().WispTable);
    dispatch({
      type: UPDATE_TABLE_META_DATAS,
      tableMetaDatas: {
        ...CurrentTableMetaDatas,
        ...updatedAttributes
      }
    });
    if (currentTableID) {
      apiSaveTableMetaDatas(currentTableID, {
        ...CurrentTableMetaDatas,
        ...updatedAttributes
      })
        .then(response => null)
        .catch(e => {
          //error saving to backend, revert back
          dispatch({
            type: UPDATE_TABLE_META_DATAS,
            tableMetaDatas: {
              ...CurrentTableMetaDatas,
              ...updatedAttributes
            }
          });
        });
    }
  };
}

export type actionTypes =
  | createRowPayloadType
  | deleteRowsPayloadType
  | updateCellReturnType
  | addColumnReturnType
  | deleteCurrentEditingColumnPayloadType
  | setCurrentEditingColumnIDPayloadType
  | fetchTablesReturnType
  | fetchTableReturnType
  | didFetchWispformsAndWorkspacesReturnType
  | updateCurrentEditingColumnReturnType
  | setCurrentTableIDReturnType
  | addTableFromWispformPayloadType
  | deleteTablePayloadType
  | updateTableNamePayloadType
  | updateColumnConfigPayloadType
  | updateColumnWidthPayloadType
  | updateMetaDatasPayloadType;
