//@flow

import React, { Component } from "react";
import ChoiceBuilderComponent from "../ChoiceBuilderComponent";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MultichoiceOtherOptionComponent from "../MultichoiceOtherOptionComponent";
import {
  add_choice,
  remove_choice,
  edit_choice,
  saveToBackend,
  reorder_multichoice_choice
} from "../../../Pages/FormBuilderPage/States/actions";
import { SortableContainer } from "react-sortable-hoc";
/*
  props:
  onQuestionComplete:function
  numOfchoices: int
  choices:array of string
*/

type Props = {|
  contents: Array<string>,
  answer: { answer: Array<Object> },
  pageNumber: number,
  edit_choice: (number, string, number) => mixed,
  saveToBackend: () => mixed,
  addChoice: (number, number) => mixed,
  removeChoice: (number, number) => mixed,
  config: Object
|};
class UI_MultiChoices extends Component<Props> {
  _renderOther = () => {
    if (this.props.config && this.props.config["Add other"]) {
      return <MultichoiceOtherOptionComponent isInBuilder={true} />;
    }
  };

  render() {
    return (
      <div className="UI_MultiChoices">
        {this.props.contents &&
          this.props.contents.map(
            function(item: string, index: number) {
              return (
                <ChoiceBuilderComponent
                  addChoice={() =>
                    this.props.addChoice(this.props.pageNumber, index)
                  }
                  removeChoice={() =>
                    this.props.removeChoice(this.props.pageNumber, index)
                  }
                  prev_answer={this.props.answer && this.props.answer.answer}
                  className="UI_MultiChoices-Choice"
                  index={index}
                  choiceNumber={index}
                  isInBuilder={true}
                  choiceContent={item}
                  updateChoice={content =>
                    this.props.edit_choice(
                      index,
                      content,
                      this.props.pageNumber
                    )
                  }
                  saveToBackend={this.props.saveToBackend}
                />
              );
            }.bind(this)
          )}
        {this._renderOther()}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addChoice: (pageNumber, index) => dispatch(add_choice(pageNumber, index)),
    removeChoice: (pageNumber, choice_index) => {
      dispatch(remove_choice(pageNumber, choice_index));
    },
    edit_choice: (
      choiceIndex: number,
      choiceContent: string,
      pageNumber: number
    ) => {
      dispatch(edit_choice(choiceIndex, choiceContent, pageNumber));
    },
    saveToBackend: () => {
      dispatch(saveToBackend());
    }
  };
};

const SortableMultiChoicesBuilderComponent = SortableContainer(
  connect(null, mapDispatchToProps)(UI_MultiChoices)
);

const mapDispatchToPropsWrapperComponent = (dispatch, ownProps) => {
  return {
    reorder_multichoice_choice: (pageNumber, currentIndex, newIndex) =>
      dispatch(reorder_multichoice_choice(pageNumber, currentIndex, newIndex))
  };
};

/*
  wrapper component to serve sortableList specific props
  such as onSortEnd and lockAxis
*/

type WrapperProps = Props & {|
  reorder_multichoice_choice: (number, number, number) => mixed
|};

function getScrollContainer() {
  const WindowWrapper = document.getElementsByClassName(
    "FormBuilderPag-ViewWindow-Container"
  )[0];
  return WindowWrapper.children[0] && WindowWrapper.children[0].children[0];
}

export default connect(
  null,
  mapDispatchToPropsWrapperComponent
)(({ reorder_multichoice_choice, ...props }: WrapperProps) => {
  return (
    <SortableMultiChoicesBuilderComponent
      onSortEnd={({ oldIndex, newIndex }) =>
        reorder_multichoice_choice(props.pageNumber, oldIndex, newIndex)
      }
      lockAxis="y"
      getContainer={getScrollContainer}
      lockToContainerEdges={true}
      distance={1}
      {...props}
    />
  );
});
