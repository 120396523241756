//@flow
import LinkerComponent from "../../Components/CellComponents/LinkerComponent";
import LinkerDataUI from "../../Components/DataUIComponents/LinkerDataUI";
import { type DataType, type linkerCellType, type filterType } from "../index";
import { toArray } from "../../../../Library/Util";

const LinkerType = {
  icon: "fa fa-external-link-square",
  cellComponent: LinkerComponent,
  dataUIComponent: LinkerDataUI,
  displayName: "Link in another table",
  sortFunction: null,
  filter: {
    operators: ["contains", "not contains", "is", "is not"],
    getOptions: () => new Map([]),
    filterFunction: filter,
    optionInputType: "text"
  }
};

function filter(value: ?linkerCellType, filterDetail: filterType) {
  //only apply filter when user typed something
  if (!filterDetail.value) return true;
  switch (filterDetail.operator) {
    case "is":
      return toArray(value).some(
        linker => linker.linkingRowDisplayName === filterDetail.value
      );
    case "is not":
      return toArray(value).every(
        linker => linker.linkingRowDisplayName !== filterDetail.value
      );

    case "contains":
      return toArray(value).some(linker =>
        String(linker.linkingRowDisplayName).includes(
          String(filterDetail.value)
        )
      );
    case "not contains":
      return toArray(value).every(
        linker =>
          !String(linker.linkingRowDisplayName).includes(
            String(filterDetail.value)
          )
      );

    default:
      return true;
  }
}

export default LinkerType;
