//@flow
import * as React from "react";
import "./style.css";
import {
  type linkerCellType,
  type tableIDType,
  type tableType,
  type linkerConfigType,
  type tableSchemaColumnType,
  type tableDataRowType,
  type linkerCellUnitType,
  type rowIDType,
  type DataComponentPropsType
} from "../../../Configuration";
import {
  UICoreBox,
  UICoreText,
  UICoreSearchBox,
  UICoreIconButton,
  UICoreButton
} from "../../../../../Component/UICore";
import { connect } from "react-redux";
import { getTableByTableID } from "../../../State/TableState/selectors";
import { type stateType } from "../../../State/TableState/reducer";
import CellPopup from "../../CellPopup";
import { getTable as apiGetTable, type apiTableReturnType } from "../../../api";
import RecordOverview from "./RecordOverview";
import Scrollable from "../../../../../Component/Scrollable";
import RecordModal from "../../RecordModal";
import withTableData from "./withTableData";
import { toArray } from "../../../../../Library/Util";
import RecordList from "./RecordList";
type Props = {|
  ...DataComponentPropsType,
  config: linkerConfigType,
  columns: Array<tableSchemaColumnType>,
  rows: Array<tableDataRowType>,
  getTableByTableID: tableIDType => tableType,
  updateCell: linkerCellType => mixed,
  getTableData: tableIDType => void
|};
type State = {|
  showRecordListSelector: boolean,
  showSelectedRecords: boolean,
  showRecordModal: boolean,
  displayedModalRowID: ?rowIDType
|};
class LinkerComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  state = {
    showRecordListSelector: false,
    showSelectedRecords: false,
    showRecordModal: false,
    displayedModalRowID: null
  };

  _openRecordListSelector = () => {
    this.setState({
      showRecordListSelector: true
    });
  };

  _closeRecordListSelector = () => {
    this.setState({
      showRecordListSelector: false
    });
  };

  _handleLinkerCellClick = () => {
    this.props.getTableData(this.props.config.linkingTableID);
    if (Array.isArray(this.props.value) && this.props.value.length > 0) {
      this._openSelectedRecordsPopup();
    } else {
      this._openRecordListSelector();
    }
  };

  _filterSelectedRecords = (linkedInTableRow: tableDataRowType) => {
    if (!Array.isArray(this.props.value)) return true;
    let alreadySelected = false;
    for (let selectedRecord of this.props.value) {
      //just for flow typing checking
      if (typeof selectedRecord !== "object") continue;
      if (selectedRecord.linkingRowID === linkedInTableRow.id)
        alreadySelected = true;
    }
    if (alreadySelected) return false;
    else return true;
  };

  _updateCell = (clickedRecord: linkerCellUnitType) => {
    let newLinkerCellValue: Array<linkerCellUnitType> = [clickedRecord];
    if (Array.isArray(this.props.value)) {
      for (let linkerCellUnit of this.props.value) {
        if (
          typeof linkerCellUnit === "object" &&
          linkerCellUnit.linkingRowID !== clickedRecord.linkingRowID
        ) {
          newLinkerCellValue.push(linkerCellUnit);
        }
      }
    }
    this.props.updateCell(newLinkerCellValue);
  };

  _renderLinkerCell = () => {
    if (!Array.isArray(this.props.value)) {
      return null;
    }
    let returnCellComponents = [];
    for (const item of this.props.value) {
      if (typeof item === "object") {
        returnCellComponents.push(
          <UICoreBox
            shape="rounded"
            color="lightGrey"
            marginRight="xm"
            paddingLeft="xm"
            paddingRight="xm"
          >
            <UICoreText size="xs">{item.linkingRowDisplayName}</UICoreText>
          </UICoreBox>
        );
      }
    }
    return returnCellComponents;
  };

  _openSelectedRecordsPopup = () => {
    this.setState({
      showSelectedRecords: true
    });
  };

  _closeSelectedRecordsPopup = () => {
    this.setState({
      showSelectedRecords: false
    });
  };

  _getRowByRowID = (rowID: rowIDType): ?tableDataRowType => {
    return this.props.rows.find(row => row.id === rowID);
  };

  _handleSelectedRecordClicked = (rowID: rowIDType) => {
    this._openRecordModal(rowID);
  };

  _removeLinker = (rowID: rowIDType) => {
    const linkersWithTargetRemoved = toArray(this.props.value).filter(
      linker => linker.linkingRowID !== rowID
    );
    this.props.updateCell(linkersWithTargetRemoved);
  };

  _handleAddRecordButtonClick = () => {
    this.setState({
      showRecordListSelector: true,
      showSelectedRecords: false
    });
  };

  _renderRecordListSelector = () => {
    const linkedInTableID: tableIDType = this.props.config.linkingTableID;
    const title =
      "Add a record from " + this.props.getTableByTableID(linkedInTableID).name;
    const rows = this.props.rows.filter(this._filterSelectedRecords);
    return (
      <RecordList
        onCellPopupDismiss={this._closeRecordListSelector}
        title={title}
        columns={this.props.columns}
        rows={rows}
        onRecordClick={this._updateCell}
      />
    );
  };

  _renderSelectedRecords = () => {
    const linkedInTableID: tableIDType = this.props.config.linkingTableID;
    const rows = toArray(this.props.value)
      .map(linkerCell => this._getRowByRowID(linkerCell.linkingRowID))
      .filter(row => row != null);
    const title = `Added records from “${
      this.props.getTableByTableID(linkedInTableID).name
    }”`;
    return (
      <RecordList
        onRecordClick={linkerCell =>
          this._handleSelectedRecordClicked(linkerCell.linkingRowID)
        }
        columns={this.props.columns}
        hideSearch={true}
        buttonText="Add a record"
        onButtonClick={this._handleAddRecordButtonClick}
        //$FlowFixMe: null and undefined has already filtered out
        rows={rows}
        title={title}
        onDeleteRecordClick={this._removeLinker}
        onCellPopupDismiss={this._closeSelectedRecordsPopup}
      />
    );
  };

  _openRecordModal = (rowID: rowIDType) => {
    this.setState({
      showRecordModal: true,
      displayedModalRowID: rowID,
      showRecordListSelector: false,
      showSelectedRecords: false
    });
  };

  _closeRecordModal = () => {
    this.setState({
      showRecordModal: false
    });
  };

  _renderdRecordModal = () => {
    const linkedInTableID: tableIDType = this.props.config.linkingTableID;
    if (!this.state.displayedModalRowID) {
      return null;
    }
    const row: ?tableDataRowType = this._getRowByRowID(
      this.state.displayedModalRowID
    );
    if (!row) {
      return null;
    }
    return (
      <RecordModal
        onDismiss={this._closeRecordModal}
        row={row}
        columns={this.props.columns}
      />
    );
  };

  render() {
    if (this.state.showRecordListSelector) {
      return this._renderRecordListSelector();
    } else if (this.state.showSelectedRecords) {
      return this._renderSelectedRecords();
    } else if (this.state.showRecordModal) {
      return this._renderdRecordModal();
    } else {
      return (
        <UICoreBox
          padding="xm"
          direction="row"
          width="100%"
          height="100%"
          onClick={this._handleLinkerCellClick}
          className="LinkerComponent"
          alignItems="start"
          overflowX="hidden"
          overflowY="hidden"
        >
          {this._renderLinkerCell()}
        </UICoreBox>
      );
    }
  }
}

const mapStateToProps = (state: { WispTable: stateType }, ownProps) => {
  return {
    getTableByTableID: getTableByTableID(state.WispTable)
  };
};

export default connect(
  mapStateToProps,
  null
)(withTableData(LinkerComponent));
