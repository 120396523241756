// @flow
import * as React from "react";
import { connect } from "react-redux";
import {
  type TGenericContentConfig,
  GENERIC_FORM_CONTENT_SECTION_TYPE
} from "../../../../Component/GenericFormContent/type";
import GenericFormContent from "../../../../Component/GenericFormContent";
import { UICoreBox } from "../../../../Component/UICore";
import {
  type formStyleType,
  type backgroundCssType
} from "../../../../FlowTypes/wispformStyleTypes";
import withStyles from "../../../../Helper_HOC/WithStyles";
import { safeGet } from "../../../../Library/Util";
import {
  update_outcome_page,
  update_outcome_page_just_redux
} from "../../../FormBuilderPage/States/actions";
import { getEligibleOutcomes } from "../../States/Answers/selectors";

type Props = {|
  isEditMode: boolean,
  outcomePageContentConfigForBuilder: ?TGenericContentConfig,
  outcomePageContentConfigForForm: ?TGenericContentConfig,
  update_outcome_page: TGenericContentConfig => void,
  update_outcome_page_just_redux: TGenericContentConfig => void
|};
type State = {||};
class OutcomePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    // this.state = {};
  }
  render() {
    return (
      <GenericFormContent
        config={{
          ...(this.props.isEditMode
            ? this.props.outcomePageContentConfigForBuilder
            : this.props.outcomePageContentConfigForForm),
          supportedSections: [
            GENERIC_FORM_CONTENT_SECTION_TYPE.MEDIA,
            GENERIC_FORM_CONTENT_SECTION_TYPE.HEADER,
            GENERIC_FORM_CONTENT_SECTION_TYPE.SUB_HEADER,
            GENERIC_FORM_CONTENT_SECTION_TYPE.OUTCOMES
          ]
        }}
        isEditMode={this.props.isEditMode}
        onUpdate={(config, isHighFrequencyUpdate) => {
          if (isHighFrequencyUpdate) {
            this.props.update_outcome_page_just_redux(config);
          } else {
            this.props.update_outcome_page(config);
          }
        }}
      />
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    update_outcome_page: (config: TGenericContentConfig) => {
      dispatch(update_outcome_page(config));
    },
    update_outcome_page_just_redux: (config: TGenericContentConfig) => {
      dispatch(update_outcome_page_just_redux(config));
    }
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    outcomePageContentConfigForBuilder: safeGet(
      _ => state.Question.pages.outcomePage.pageContentConfig
    ),
    outcomePageContentConfigForForm: getEligibleOutcomes(state.Answers)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OutcomePage);
