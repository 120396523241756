//@flow
import TextComponent from "../../Components/CellComponents/TextComponent";
import TextDataUI from "../../Components/DataUIComponents/TextDataUI";
import { type DataType, type textCellType } from "../index";

const EmailType = {
  icon: "fa fa-envelope",
  cellComponent: TextComponent,
  dataUIComponent: TextDataUI,
  displayName: "Email",
  sortFunction: sorter,
  filter: null
};

export default EmailType;

function sorter(first: textCellType, second: textCellType) {
  if (first < second) {
    return 1;
  } else {
    return 0;
  }
}
