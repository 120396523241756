import React, { Component } from "react";
import QuestionTypes from "../../../QuestionTypes";
import "./style.css";
import PropTypes from "prop-types";
import { UICoreText, UICoreBox } from "../../../../Component/UICore";

class QuestionType extends Component {
  constructor(props) {
    super(props);
    this.QuestionTypes = new QuestionTypes().types;
    this.colors = ["#7590FE", "#FBD477", "#FF7373"];
  }

  getColor() {
    return this.colors[this.props.index % 3];
  }

  colorStyle() {
    return {
      "background-color": this.getColor()
    };
  }

  render() {
    return (
      <div
        onClick={e => {
          e.stopPropagation();
          this.props.onClick();
        }}
        className="QuestionType"
      >
        <div style={this.colorStyle()} className="QuestionType-Icon">
          <i
            className={
              "fa fa-3x " + this.QuestionTypes[this.props.Type].iconClassName
            }
            aria-hidden="true"
          />
        </div>
        <UICoreBox marginLeft="xxm" paddingLeft="xm">
          <UICoreText color="black" size="sm">
            {this.QuestionTypes[this.props.Type] &&
              this.QuestionTypes[this.props.Type].displayName}
          </UICoreText>
        </UICoreBox>
      </div>
    );
  }
}

QuestionType.propTypes = {
  Type: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

export default QuestionType;
