//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "../../../../Component/UICore";
import BrowserUI from "../BrowserUI";
type Props = {|
  settingColumn: React.Node,
  browserView: React.Node
|};
type State = {||};
class ShareSectionSkeleton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox
        width="calc(100% - 224px)"
        height="100%"
        direction="row"
        paddingLeft="md"
        paddingRight="md"
      >
        <UICoreBox marginRight="md" width="250px" name="section" marginTop="sm">
          {this.props.settingColumn}
        </UICoreBox>
        <UICoreBox
          height="calc((100vw - 570px)*3.5/5)"
          width="calc(100vw - 570px)"
          maxHeight="80vh"
          position="relative"
        >
          <BrowserUI>{this.props.browserView}</BrowserUI>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default ShareSectionSkeleton;
