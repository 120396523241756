//@flow
import YesNoComponent from "../../Components/CellComponents/YesNoComponent";
import YesNoDataUI from "../../Components/DataUIComponents/YesNoDataUI";
import {
  filterOperators,
  type DataType,
  type yesNoCellType,
  type cellValueType,
  type filterType,
  type filterOperatorType
} from "../index";

const YesNoType = {
  icon: "fa fa-dot-circle-o",
  cellComponent: YesNoComponent,
  dataUIComponent: YesNoDataUI,
  displayName: "Yes/No",
  sortFunction: sorter,
  filter: {
    operators: ["is", "is not"],
    getOptions: () =>
      new Map([
        ["yes", { display: "checked", value: "yes" }],
        ["no", { display: "unchecked", value: "no" }]
      ]),
    filterFunction: filter,
    optionInputType: "dropdown"
  }
};

function sorter(first: yesNoCellType, second: yesNoCellType) {
  if (first === second) return 0;
  else if (first === "yes") return -1;
  else if (second === "yes") return 1;
  else return 0;
}

function filter(value: ?yesNoCellType, filterDetail: filterType) {
  const unNullableValue = value ? value : "no";
  if (filterDetail.operator === filterOperators.is) {
    return (
      (unNullableValue === "yes" && filterDetail.value === "yes") ||
      (unNullableValue === "no" && filterDetail.value === "no")
    );
  } else if (filterDetail.operator === filterOperators["is not"]) {
    return (
      (unNullableValue === "yes" && filterDetail.value === "no") ||
      (unNullableValue === "no" && filterDetail.value === "yes")
    );
  } else return true;
}

export default YesNoType;
