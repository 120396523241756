import { serverAddress, domainName } from "../../../config";
import { browserHistory } from "react-router";
import { saveUserInfo } from "../../../Library/UserInfo";
import { logCollaborationEvent } from "../../../Library/Logger";
import {
  getRequest,
  postRequest,
  deleteRequest,
  patchRequest
} from "../../../Library/Request";
import { showNotification } from "../../WispTable/State/NotificationState/action";
import { getOrgName } from "../../../Library";

export const REQUEST_FORMS = "REQUEST_FORMS";
export const RECEIVE_FORMS = "RECEIVE_FORMS";
export const RECEIVE_COLLABORATORS = "RECEIVE_COLLABORATORS";
export const REQUEST_WORKSPACES = "REQUEST_WORKSPACES";
export const RECEIVE_WORKSPACES = "RECEIVE_WORKSPACES";
export const NEW_FORM = "NEW_FORM";
export const DUPLICATE_FORM = "DUPLICATE_FORM";
export const DESTROY_FORM = "DESTROY_FORM";
export const SHOW_WARNING = "SHOW_WARNING";
export const HIDE_WARNING = "HIDE_WARNING";

export function requestForms() {
  return {
    type: REQUEST_FORMS
  };
}

export function receiveForms(forms) {
  return {
    type: RECEIVE_FORMS,
    forms
  };
}

export function receiveCollaborators(collaborators) {
  return {
    type: RECEIVE_COLLABORATORS,
    collaborators
  };
}

export function requestWorkspaces() {
  return {
    type: REQUEST_WORKSPACES
  };
}

export function receiveWorkspaces(workspaces) {
  return {
    type: RECEIVE_WORKSPACES,
    workspaces
  };
}

export function newForm() {
  return {
    type: NEW_FORM
  };
}

export function duplicateForm() {
  return {
    type: DUPLICATE_FORM
  };
}

export function destroyForm() {
  return {
    type: DESTROY_FORM
  };
}

export function showWarning() {
  return {
    type: SHOW_WARNING
  };
}

export function hideWarning() {
  return {
    type: HIDE_WARNING
  };
}

export function fetchWorkspaces() {
  return function (dispatch) {
    dispatch(requestWorkspaces());
    getRequest("api/v1/workspaces?org_name=" + getOrgName(), {
      "Cache-Control": "no-store, no-cache, must-revalidate, max-age=0"
    })
      .then(response => {
        dispatch(receiveWorkspaces(response.data.data.workspaces));
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function fetchForms(workspace_index) {
  return function (dispatch) {
    dispatch(requestForms());
    getRequest(
      "api/v1/forms?workspace_index=" +
        workspace_index +
        "&org_name=" +
        getOrgName(),
      {
        "Cache-Control": "no-store, no-cache, must-revalidate, max-age=0"
      }
    )
      .then(response => {
        setLocalStorageFormNames(response.data.data.forms);
        dispatch(receiveForms(response.data.data.forms));
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function fetchCollaborators(workspace_index) {
  return function (dispatch) {
    getRequest("api/v1/workspaces/" + workspace_index + "/collaborators", {
      "Cache-Control": "no-store, no-cache, must-revalidate, max-age=0"
    })
      .then(response => {
        dispatch(receiveCollaborators(response.data.data.collaborators));
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function createForm(name, workspace_index, contentTypeToCreate) {
  return function (dispatch) {
    dispatch(newForm());
    postRequest("api/v1/forms", {
      name: name,
      workspace_index: workspace_index,
      form_type: contentTypeToCreate
    })
      .then(response => {
        browserHistory.push(
          `/form/formbuilder?id=${response.data.data.forms.uuid}`
        );
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function createFormWithTemplate(name, workspaceIndex, templateID) {
  return function (dispatch, getState) {
    dispatch(newForm());
    postRequest("api/v1/forms", {
      name: name,
      workspace_index: workspaceIndex,
      template_id: templateID
    })
      .then(response => {
        browserHistory.push(
          `/form/formbuilder?id=${response.data.data.forms.uuid}`
        );
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function createWorkspace(name) {
  return function (dispatch) {
    postRequest("api/v1/workspaces?org_name=" + getOrgName(), { name: name })
      .then(response => {
        dispatch(fetchWorkspaces());
        let workspace_index = response.data.data.workspace_count - 1;
        browserHistory.push("/workspace/" + workspace_index);
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function addCollaborators(email, workspace_index) {
  return function (dispatch) {
    postRequest(
      "api/v1/workspaces/" +
        workspace_index +
        "/collaborators?org_name=" +
        getOrgName(),
      {
        email: email,
        workspace_index: workspace_index
      }
    )
      .then(response => {
        window.location.reload();
        logCollaborationEvent("collaboratorAdded");
      })
      .catch(function (error) {
        dispatch(
          showNotification(
            "error",
            error.response.data.meta,
            "",
            "Add Member to Team",
            () => {
              browserHistory.push("/setting/team");
            }
          )
        );
        logCollaborationEvent("addCollaboratorFailed");
      });
  };
}

export function copyForm(id) {
  return function (dispatch) {
    dispatch(duplicateForm());
    postRequest("api/v1/forms/" + id + "/copy")
      .then(response => {
        window.location.reload();
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function deleteForm(id) {
  return function (dispatch) {
    dispatch(destroyForm());
    deleteRequest("api/v1/forms/" + id)
      .then(response => {
        window.location.reload();
      })
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

export function moveForm(id, workspace_id) {
  return function (dispatch) {
    postRequest("api/v1/forms/" + id + "/move", { workspace_id: workspace_id })
      .then(response => {
        dispatch(
          showNotification(
            "succeed",
            "Move Form Successful",
            "Your form was successfully moved to the new workspace"
          )
        );
        window.location.reload();
      })
      .catch(function (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.meta
        ) {
          dispatch(
            showNotification(
              "error",
              "Move Form Failed",
              error.response.data.meta
            )
          );
        } else {
          dispatch(
            showNotification(
              "error",
              "Move Form Failed",
              "Please contact the administrator at admin@wispform.com or via the chat bubble on the bottom right."
            )
          );
        }
      });
  };
}

export function deleteWorkspace(workspace_id) {
  return function (dispatch) {
    deleteRequest("api/v1/workspaces/" + workspace_id)
      .then(response => {
        dispatch(
          showNotification(
            "succeed",
            "Workspace Deleted",
            "Your workspace was successfully deleted"
          )
        );
        window.location.href = "/workspace/0";
      })
      .catch(function (error) {
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.meta
        ) {
          dispatch(
            showNotification(
              "error",
              "Delete Workspace Failed",
              error.response.data.meta
            )
          );
        } else {
          dispatch(
            showNotification(
              "error",
              "Delete Workspace Failed",
              "Please contact the administrator at admin@wispform.com or via the chat bubble on the bottom right."
            )
          );
        }
      });
  };
}

export function updateFormName(newName, id) {
  return function (dispatch) {
    patchRequest("api/v1/forms/" + id + "/name", { name: newName })
      .then(response => {})
      .catch(function (error) {
        dispatch(showWarning());
      });
  };
}

function setLocalStorageFormNames(forms) {
  let names = {};
  forms.forEach(form => {
    names[form.uuid] = form.name;
  });
  localStorage.setItem("form_names", JSON.stringify(names));
}
