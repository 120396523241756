//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreBox,
  UICoreInput,
  UICoreText
} from "../../../../../../Component/UICore";
type Props = {|
  name: ?string,
  email: ?string,
  onNameChange: string => void,
  onEmailChange: string => void
|};
type State = {||};
class ContactInfo extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleNameInput = (
    e: SyntheticInputEvent<HTMLInputElement>,
    value: string
  ) => this.props.onNameChange(value);

  _handleEmailInput = (
    e: SyntheticInputEvent<HTMLInputElement>,
    value: string
  ) => this.props.onEmailChange(value);

  render() {
    return (
      <UICoreBox>
        <UICoreBox>
          <UICoreText size="xs" weight="bold">
            Name
          </UICoreText>
          <UICoreInput
            onChange={this._handleNameInput}
            value={this.props.name}
            placeholder="name"
          />
        </UICoreBox>
        <UICoreBox marginTop="xm">
          <UICoreText size="xs" weight="bold">
            Email
          </UICoreText>
          <UICoreInput
            onChange={this._handleEmailInput}
            value={this.props.email}
            placeholder="email"
          />
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default ContactInfo;
