import React, { Component } from 'react';
import WithResizeHandler from '../ResizeHandler';

class Mobile extends Component {
    
    render() {
        if (this.props.isMobile){
            return this.props.children;
        }
        else{
            return (<div></div>)
        }
    }
}

export default WithResizeHandler(Mobile);