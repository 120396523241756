//@flow
import * as React from "react";
import ReactDOM from "react-dom";
import {
  UICoreBox,
  UICoreText,
  UICoreDivider,
  UICoreSearchBox
} from "../index";
import "./style.css";
type Props = {|
  size?: "sm" | "md" | "lg" | "xl",
  body?: React.Node,
  footer?: React.Node,
  header: string,
  onDismiss: () => void,
  dismissText?: string
|};
type State = {||};

class UICoreModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _backgroundNode: ?HTMLElement = null;

  _getWidth = (): number => {
    switch (this.props.size) {
      case "sm":
        return 400;
      case "md":
        return 500;
      case "lg":
        return 800;
      case "xl":
        return 1000;
      default:
        return 400;
    }
  };

  _handleClick = (e: Event) => {
    if (e.target === this._backgroundNode) {
      this.props.onDismiss();
    }
  };

  _renderModal = (): React.Node => {
    return (
      <div
        ref={ref => (this._backgroundNode = ref)}
        onClick={this._handleClick}
        className="UICoreModal"
      >
        <UICoreBox
          direction="column"
          shape="rounded"
          width={this._getWidth()}
          color="white"
          className="UICoreModal-modal"
        >
          <UICoreBox
            width="100%"
            justifyContent="center"
            paddingTop="xm"
            paddingBottom="xm"
            paddingLeft="sm"
            paddingRight="sm"
            position="relative"
          >
            {this.props.dismissText ? (
              <UICoreBox
                shape="rounded"
                hoverable={true}
                position="absolute"
                right="8px"
                paddingLeft="xm"
                paddingRight="xm"
                paddingBottom="xxm"
                paddingTop="xxm"
                onClick={this.props.onDismiss}
              >
                <UICoreText weight="bold" hexColor="#6669FF">
                  {this.props.dismissText}
                </UICoreText>
              </UICoreBox>
            ) : (
              <div
                onClick={this.props.onDismiss}
                className="UICoreModal-closeIcon"
              >
                <i className="ion-close" />
              </div>
            )}
            <UICoreBox marginLeft="sm" marginRight="sm">
              <UICoreText
                color="black"
                alignment="center"
                weight="bold"
                size="lg"
              >
                {this.props.header}
              </UICoreText>
            </UICoreBox>
          </UICoreBox>
          <UICoreDivider />
          {this.props.body}
          {this.props.footer}
        </UICoreBox>
      </div>
    );
  };

  render() {
    if (document.body) {
      //$FlowFixMe flow is going crazy here.. already checked the nullity of document.body
      return ReactDOM.createPortal(this._renderModal(), document.body);
    } else {
      return null;
    }
  }
}
export default UICoreModal;
