import React, { Component } from 'react';
import GlobalNavBar from '../GlobalNavBar';
import {getUrlVars} from '../../Library';
import './style.css';

class FormApp extends Component {

    constructor(props){
        super(props);
        this.state = {
            page_name:null
        }
    }
    
    componentWillMount() {
        this.form_id = getUrlVars()['id'];
        this.setPageName();
    }

    setPageName(){
        const new_page_name = window.location.pathname.split('/')[2];
        if (this.state.page_name != new_page_name){
            this.setState({
                page_name: new_page_name
            })
        }
    }

    componentWillUpdate(nextProps, nextState) {
        this.setPageName();
    }
    
    
    
    render() {
        return (
            <div className="FormApp">
                <GlobalNavBar
                    form_id = {this.form_id}
                    page_name = {this.state.page_name}
                />
                {this.props.children}
            </div>
        );
    }
}

export default FormApp;