//@flow
import * as React from "react";
import { UICoreBox } from "../index";
import "./style.css";
import UICoreText from "../UICoreText";
import { getFontSize } from "../../../Library/Font";

type HorizontalProps = {|
  sections: Array<React.Node>,
  activeSectionIndex: number,
  onChange: (SyntheticInputEvent<>, number) => void,
  fontSize?: any,
  margin?: any,
  overflow?: any
|};

type HorizontalState = {||};
class Horizontal extends React.Component<HorizontalProps, HorizontalState> {
  constructor(props: HorizontalProps) {
    super(props);
  }
  render() {
    return (
      <UICoreBox direction="row">
        {this.props.sections.map(
          (section: any, index: number): React.Node => {
            const isSelected = this.props.activeSectionIndex === index;
            const fontSize = this.props.fontSize ? this.props.fontSize : "md";
            const margin = this.props.margin ? this.props.margin : "xm";
            const overflow = this.props.overflow ? this.props.overflow : "wrap";
            return (
              <UICoreBox
                className="UICoreSectionItem"
                hoverable={isSelected ? false : true}
                shape="rounded"
                paddingLeft="sm"
                paddingRight="sm"
                paddingTop="xxm"
                paddingBottom="xxm"
                // color={isSelected ? "skyBlue" : "white"}
                marginTop={margin}
                marginBottom={margin}
                // borderBottomWidth={isSelected ? 3 : 0}
                dangerous_style={
                  isSelected
                    ? {
                        borderBottom: "3px solid black",
                        borderRadius: "0px"
                      }
                    : {}
                }
                onClick={e => this.props.onChange(e, index)}
              >
                <UICoreText
                  overflow={overflow}
                  color={"black"}
                  size={fontSize}
                  weight={isSelected ? "bold" : "normal"}
                  disableNewLine={true}
                >
                  {section}
                </UICoreText>
              </UICoreBox>
            );
          }
          //   (section, index) => (
          //   <UICoreBox
          //     className="UICoreSection-Section"
          //     paddingTop="xm"
          //     paddingBottom="xm"
          //     paddingLeft="md"
          //     paddingRight="md"
          //     shape="circle"
          //     color={
          //       index === this.props.activeSectionIndex ? "skyBlue" : undefined
          //     }
          //     marginRight="sm"
          //     onClick={e => this.props.onChange(e, index)}
          //   >
          //     {section}
          //   </UICoreBox>
          // )
        )}
      </UICoreBox>
    );
  }
}

type VerticalProps = {|
  sections: Array<string>,
  activeSectionIndex: number,
  onChange: (SyntheticInputEvent<>, number) => void,
  fontSize?: any,
  fontWeight?: any,
  margin?: any,
  overflow?: any
|};
type VerticalState = {||};
class Vertical extends React.Component<VerticalProps, VerticalState> {
  constructor(props: VerticalProps) {
    super(props);
  }
  render() {
    return (
      <UICoreBox maxWidth="300px" width="100%" direction="column">
        {this.props.sections.map(
          (section: string, index: number): React.Node => {
            const isSelected = this.props.activeSectionIndex === index;
            const fontSize = this.props.fontSize ? this.props.fontSize : "md";
            const margin = this.props.margin ? this.props.margin : "xm";
            const overflow = this.props.overflow ? this.props.overflow : "wrap";
            return (
              <UICoreBox
                className="UICoreSectionItem"
                width="100%"
                hoverable={isSelected ? false : true}
                shape="rounded"
                paddingLeft="sm"
                paddingRight="sm"
                paddingTop="xxm"
                paddingBottom="xxm"
                color={isSelected ? "skyBlue" : "white"}
                marginTop={margin}
                marginBottom={margin}
                onClick={e => this.props.onChange(e, index)}
              >
                <UICoreText
                  overflow={overflow}
                  color={isSelected ? "blue" : "darkGray"}
                  size={fontSize}
                  weight={isSelected ? "bold" : "normal"}
                  disableNewLine={true}
                >
                  {section}
                </UICoreText>
              </UICoreBox>
            );
          }
        )}
      </UICoreBox>
    );
  }
}

export default {
  Horizontal: Horizontal,
  Vertical: Vertical
};
