//@flow
import * as React from "react";
import { connect } from "react-redux";
import {
  UICoreText,
  UICoreImage,
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreInput
} from "../../../../../Component/UICore";
import FileUploadButton, {
  Status as fileUploadButtonStatus,
  type successPayload
} from "../../../../../Component/FileUploadButton";
import { addLogo } from "../../../../FormBuilderPage/States/actions";
import "./style.css";
import { getBrandLogoAwsBucketKey } from "../../../../../Library/BrandSetting";
import * as UserInfo from "../../../../../Pages/../Library/UserInfo";
type Props = {|
  addLogo: (?string) => void,
  configurations: { logo: ?string }
|};
type State = {};

const hasLogo = configurations => {
  return configurations && configurations.logo;
};
class BrandingSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <UICoreBox padding="sm" alignItems="start">
        <UICoreBox alignItems="start" width="100%">
          <UICoreBox paddingBottom="sm">
            <UICoreText color="black" size="md" weight="bold">
              Add Custom Branding to Your Wispform
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              You can remove the Wispform logo in your form and add your own!
              Click on the button below and upload your logo. It will add your
              logo to the top left corner of your form as well as replace the
              Wispform logo on the Thankyou page. If you are using PDF export,
              this logo will also appear on the top of your generated PDF.
            </UICoreText>
          </UICoreBox>
          <UICoreBox paddingBottom="md">
            <UICoreText overflow="wrap">
              We recommend you to upload a square-shaped image. Otherwise, your
              logo might be shrunk to fit into our size constraints.
            </UICoreText>
          </UICoreBox>
          {hasLogo(this.props.configurations) && (
            <UICoreBox paddingBottom="md" paddingLeft="sm">
              <UICoreImage
                width={50}
                height={50}
                src={String(this.props.configurations.logo)}
              />
            </UICoreBox>
          )}
          <UICoreBox paddingBottom="md">
            <FileUploadButton
              status={
                hasLogo(this.props.configurations)
                  ? fileUploadButtonStatus.uploaded
                  : fileUploadButtonStatus.default
              }
              awsFileName={getBrandLogoAwsBucketKey(
                UserInfo.getUserName(),
                UserInfo.getFormUid()
              )}
              onUploading={() => {}}
              onUploaded={(data: successPayload) => {
                this.props.addLogo(data.url);
              }}
              buttonClickedInUploadedState={() => {
                this.props.addLogo(null);
              }}
              buttonText="Upload"
              maxSizeMb={0.01}
            />
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    configurations: state.Question.configurations
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    addLogo: url => {
      dispatch(addLogo(url));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandingSection);
