import React, { Component } from "react";
import "./style.css";
import PropTypes from "prop-types";
import { saveToBackend } from "../../States/actions";
import { UICoreText, UICoreBox } from "../../../../Component/UICore";

const DropDownEditor = ({
  pageNumber,
  add_dropdown_choice,
  contents,
  saveToBackend
}) => {
  return (
    <div className="DropDownEditor">
      <UICoreBox marginBottom="xm">
        <UICoreText size="xs">Choices</UICoreText>
      </UICoreBox>
      <div className="DropDownEditor-Textarea">
        <textarea
          id="DropDownEditor-Textarea"
          value={contents.join("\n")}
          onBlur={() => saveToBackend()}
          onChange={e => add_dropdown_choice(pageNumber, e.target.value)}
          /* eslint-disable */
          placeholder="Please add one choice per line. A minimum of 8 choices is required, otherwise, please use multiple choices question type."
          /* eslint-enable */
        />
      </div>
    </div>
  );
};

DropDownEditor.propTypes = {
  pageNumber: PropTypes.number,
  add_dropdown_choice: PropTypes.func,
  contents: PropTypes.array
};

export default DropDownEditor;
