//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreInput,
  UICoreTooltip
} from "../../../../../Component/UICore";
import { updateQuestionContent } from "../../../../FormBuilderPage/States/actions";
import { connect } from "react-redux";
import "./style.css";

type opinionScaleQuestionContentsType = {|
  steps?: number
|};

type Props = {|
  questionIndex: number,
  contents?: opinionScaleQuestionContentsType,
  updateQuestionContent: (number, opinionScaleQuestionContentsType) => {}
|};
type State = {||};

class ScalerSteps extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleStepsUpdate = (_: any, value: string) => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(this.props.questionIndex, {
        ...this.props.contents,
        steps: parseInt(value)
      });
  };

  _getSteps = () => {
    if (this.props.contents && !isNaN(this.props.contents.steps)) {
      return this.props.contents.steps;
    }
    return "";
  };

  _validateScalerInput = (input: string) => {
    if (isNaN(input)) {
      return "Scaler steps need to be a number";
    }
    if (parseInt(input) < 1 || parseInt(input) > 11) {
      return "Scaler steps need to be a number between 1 - 11";
    }
    return "";
  };

  render() {
    return (
      <UICoreBox>
        <UICoreInput
          validator={this._validateScalerInput}
          clickToSelect={true}
          onChange={this._handleStepsUpdate}
          type="numeric"
          width={80}
          size="xxs"
          placeholder="10"
          value={String(this._getSteps())}
        />
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(updateQuestionContent(pageNumber, contents));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScalerSteps);
