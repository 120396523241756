// @flow
export type comperatorType = {|
  typeName: $Keys<ComperatorsType>,
  displayName: string,
  targetSelector: $Values<typeof targetSelector>,
  compare: (asnwer: any, targetAnswer: any) => boolean
|};

export const targetSelector = {
  text: 0,
  choices: 1,
  numeric: 2
};

export type comperatorNameTypes = $Keys<ComperatorsType>;

export type ComperatorsType = {|
  is: comperatorType,
  isNot: comperatorType,
  contain: comperatorType,
  notContain: comperatorType,
  ratingIs: comperatorType,
  ratingIsNot: comperatorType,
  ratingLessThan: comperatorType,
  ratingMoreThan: comperatorType,
  numericIs: comperatorType,
  numericIsNot: comperatorType,
  numericLessThan: comperatorType,
  numericMoreThan: comperatorType,
  textStartWith: comperatorType,
  textEndWith: comperatorType,
  fileIs: comperatorType,
  multiSelectContain: comperatorType,
  multiSelectNotContain: comperatorType,
  multiSelectIs: comperatorType,
  "is not": comperatorType
|};
