//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreButton,
  UICoreModal,
  UICoreBox,
  UICoreText,
  UICoreDropdown,
  UICoreInput,
  UICoreTooltip
} from "../../../../../Component/UICore";
import { connect } from "react-redux";
import {
  updateScoreCalculation,
  toggleSetScoreModal
} from "../../../States/actions";
import { type questionType } from "../../../../QuestionTypes";
import {
  type questionScoreCalculationsType,
  type questionScoreCalculationType,
  type questionScoreCalculationOperationType
} from "../../../../QuestionTypes";
import {
  toArray,
  toNumber,
  isNonEmptyArray
} from "../../../../../Library/Util";
import formConfiguration from "../../../../QuestionTypes";
import {
  getVisibleQuestions,
  getQuestionIDIndexMap
} from "../../../States/selectors";
import { Scrollbars } from "react-custom-scrollbars";
import { type questionIDType } from "../../../../WispformTypings";

//$FlowFixMe
const questionTypes = new formConfiguration().types;

type Props = {|
  questionID: questionIDType,
  questions: Array<questionType>,
  scoreCalculations: questionScoreCalculationsType,
  updateScoreCalculation: (
    questionIDType,
    questionScoreCalculationsType
  ) => void,
  toggleSetScoreModal: boolean => void,
  showSetScoreModal: boolean
|};

const operators = [
  { value: "add", display: "add" },
  { value: "subtract", display: "subtract" }
];
class ScoreButton extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  _showScoreSettingModal = () => {
    this.props.toggleSetScoreModal(true);
  };

  _closeScoreSettingModal = () => this.props.toggleSetScoreModal(false);

  _addNewScoreCalculation = () => {
    this.props.updateScoreCalculation(this.props.questionID, [
      ...toArray(this.props.scoreCalculations),
      {
        targetAnswer: this._getFirstChoice(),
        operation: "add",
        score: 10
      }
    ]);
  };

  _removeCalculation = (index: number) => {
    this.props.updateScoreCalculation(this.props.questionID, [
      ...toArray(this.props.scoreCalculations).slice(0, index),
      ...toArray(this.props.scoreCalculations).slice(index + 1)
    ]);
  };

  _updateCalculation = (
    index: number,
    updatedAttributes: $Shape<questionScoreCalculationType>
  ) => {
    this.props.updateScoreCalculation(this.props.questionID, [
      ...toArray(this.props.scoreCalculations).slice(0, index),
      { ...toArray(this.props.scoreCalculations)[index], ...updatedAttributes },
      ...toArray(this.props.scoreCalculations).slice(index + 1)
    ]);
  };

  _hasScoreCalculation = () => {
    return isNonEmptyArray(this.props.scoreCalculations);
  };

  _renderButton = () => {
    if (this._hasScoreCalculation()) {
      return (
        <UICoreButton
          key={1}
          onClick={this._showScoreSettingModal}
          color="blue"
          size="sm"
        >
          {" Update "}
        </UICoreButton>
      );
    } else {
      return (
        <UICoreTooltip.WrapAround
          size={200}
          text="Apply score calculations to the question and show a final score on the Thankyou page."
        >
          <UICoreButton
            key={2}
            onClick={this._showScoreSettingModal}
            color="lightBlue"
            size="sm"
          >
            {" Add "}
          </UICoreButton>
        </UICoreTooltip.WrapAround>
      );
    }
  };

  _getQuestionByID = (questionID: questionIDType): ?questionType => {
    return toArray(this.props.questions).find(
      q => q.question_id === questionID
    );
  };

  _getAnswerChoices = () => {
    const question = this._getQuestionByID(this.props.questionID);
    if (!question) return [];
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return [];
    return (
      questionConfig.getAnswerChoices &&
      questionConfig.getAnswerChoices(question)
    );
  };

  _getFirstChoice = () => {
    const firstChoice = toArray(this._getAnswerChoices())[0];
    return firstChoice ? firstChoice.value : "";
  };

  _getSelectedTargetAnswer = (targetAnswerValue: number | string) => {
    return toArray(this._getAnswerChoices()).find(
      answerChoice => answerChoice.value === targetAnswerValue
    );
  };

  _handleTargetAnswerSelect = (
    index: number,
    targetAnswer: string | number
  ) => {
    this._updateCalculation(index, {
      targetAnswer: targetAnswer
    });
  };

  _handleOperationClick = (
    index,
    operation: questionScoreCalculationOperationType
  ) => {
    this._updateCalculation(index, { operation });
  };

  _handleScoreChange = (index: number, score: string) => {
    this._updateCalculation(index, {
      score: score === "" ? null : toNumber(score)
    });
  };

  _renderScoreCalculations = () => {
    return toArray(this.props.scoreCalculations).map(
      (scoreCalculation, index) => (
        <UICoreBox
          marginBottom="sm"
          width="100%"
          padding="sm"
          shape="rounded"
          boxShadow={true}
          position="relative"
          key={index}
        >
          <UICoreBox
            onClick={() => {
              this._removeCalculation(index);
            }}
            width="20px"
            height="20px"
            shape="circle"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="-8px"
            right="-8px"
            hoverable={true}
            color="lightGrey"
          >
            <i style={{ color: "#888888" }} className="ion-close" />
          </UICoreBox>
          <UICoreBox>
            <UICoreText size="xs" weight="bold">
              If answer contains:
            </UICoreText>
          </UICoreBox>
          <UICoreBox marginTop="xxm" width="100%">
            <UICoreDropdown
              selectChoice={this._getSelectedTargetAnswer(
                scoreCalculation.targetAnswer
              )}
              dropdownMaxHeight={150}
              choices={this._getAnswerChoices()}
              width="100%"
              dropdownWidth={336}
              onChoiceClick={answer =>
                this._handleTargetAnswerSelect(index, answer)
              }
            />
          </UICoreBox>
          <UICoreBox marginTop="xm" marginBottom="xxm">
            <UICoreText weight="bold" size="xs">
              Then:
            </UICoreText>
          </UICoreBox>
          <UICoreBox direction="row">
            <UICoreDropdown
              selectChoice={{
                display: scoreCalculation.operation,
                value: scoreCalculation.operation
              }}
              choices={operators}
              onChoiceClick={operation =>
                this._handleOperationClick(index, operation)
              }
            />
            <UICoreBox width="100px" marginLeft="xm">
              <UICoreInput
                clickToSelect={true}
                size="xs"
                onChange={(e, v) => this._handleScoreChange(index, v)}
                value={scoreCalculation.score}
                type="numeric"
              />
            </UICoreBox>
          </UICoreBox>
          <UICoreBox marginTop="xm">
            <UICoreText weight="bold" size="xs">
              To:
            </UICoreText>
          </UICoreBox>
          <UICoreBox
            marginTop="xxm"
            padding="xm"
            shape="rounded"
            color="lightGrey"
            width="100px"
          >
            <UICoreText size="xs">Score</UICoreText>
          </UICoreBox>
        </UICoreBox>
      )
    );
  };

  _renderModal = () => {
    return (
      this.props.showSetScoreModal && (
        <UICoreModal
          dismissText="Save"
          header="Score Calculator"
          size="sm"
          onDismiss={this._closeScoreSettingModal}
          body={
            <UICoreBox>
              <Scrollbars autoHeight autoHeightMax="70vh">
                <UICoreBox paddingTop="sm" paddingLeft="sm" paddingRight="sm">
                  {this._renderScoreCalculations()}
                </UICoreBox>
                <UICoreBox
                  marginBottom="sm"
                  justifyContent="center"
                  direction="row"
                >
                  <UICoreButton onClick={this._addNewScoreCalculation}>
                    Add a calculation
                  </UICoreButton>
                </UICoreBox>
              </Scrollbars>
            </UICoreBox>
          }
        />
      )
    );
  };

  render() {
    return [this._renderButton(), this._renderModal()];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: getVisibleQuestions(state.Question),
    showSetScoreModal: state.Question.showSetScoreModal
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    toggleSetScoreModal: (show: boolean) => dispatch(toggleSetScoreModal(show)),
    updateScoreCalculation: (
      questionID: questionIDType,
      updatedScoreCalculation: questionScoreCalculationsType
    ) => {
      dispatch(updateScoreCalculation(questionID, updatedScoreCalculation));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScoreButton);
