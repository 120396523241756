//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "../../../../../Component/UICore";
// import RecordOverview from "../../CellComponents/LinkerComponent/RecordOverView";
import withTableData, {
  type InjectedProps
} from "../../CellComponents/LinkerComponent/withTableData";
import {
  type linkerCellType,
  type rowIDType,
  type tableDataRowType,
  type DataComponentPropsType
} from "../../../Configuration";
import RecordOverview from "../../CellComponents/LinkerComponent/RecordOverview";

type Props = {|
  ...DataComponentPropsType,
  updateCell?: linkerCellType => mixed,
  ...InjectedProps
|};

type State = {||};
class LinkerDataUI extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    if (
      this.props.config &&
      this.props.getTableData &&
      this.props.config.linkingTableID &&
      typeof this.props.config.linkingTableID === "number"
    ) {
      this.props.getTableData(this.props.config.linkingTableID);
    }
  }

  _getRowByRowID = (rowID: rowIDType): ?tableDataRowType => {
    if (!this.props.rows) return null;
    return this.props.rows.find(row => row.id === rowID);
  };

  _renderLinkedRecords = () => {
    if (!this.props.value || !Array.isArray(this.props.value)) {
      return null;
    }
    const linkedRecordComponents = [];

    for (let record of this.props.value) {
      if (typeof record !== "object") continue;
      const row = this._getRowByRowID(record.linkingRowID);
      if (row && this.props.columns) {
        linkedRecordComponents.push(
          <UICoreBox width="100%" marginBottom="xm">
            <RecordOverview
              onClick={() => {}}
              row={row}
              columns={this.props.columns}
            />
          </UICoreBox>
        );
      }
    }
    return linkedRecordComponents;
  };

  render() {
    return <UICoreBox width="100%">{this._renderLinkedRecords()}</UICoreBox>;
  }
}
export default withTableData(LinkerDataUI);
