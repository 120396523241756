//@flow
import * as React from "react";
import {
  type numberCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import "./style.css";
import { UICoreBox } from "../../../../../Component/UICore";
type Props = {|
  ...DataComponentPropsType,
  updateCell: numberCellType => mixed
|};
type State = {||};
class RatingComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  //index starts from 1 to 5
  _handleStarClick = (index: number) => {
    if (index == 1 && Number(this.props.value) == 1) {
      this.props.updateCell(0);
    } else {
      this.props.updateCell(index);
    }
  };

  _renderStar = (): Array<any> => {
    return [1, 2, 3, 4, 5].map((index: number) => {
      if (index <= Number(this.props.value)) {
        return (
          <i
            onClick={() => this._handleStarClick(index)}
            class="RatingComponent-star fa fa-star"
          />
        );
      }
      return (
        <i
          onClick={() => this._handleStarClick(index)}
          class="RatingComponent-star RatingComponent-star-empty fa fa-star"
        />
      );
    });
  };
  render() {
    return (
      <UICoreBox
        direction="row"
        width="100%"
        justifyContent="center"
        padding="xm"
      >
        {this._renderStar()}
      </UICoreBox>
    );
  }
}
export default RatingComponent;
