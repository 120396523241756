// @flow
import * as React from "react";
import { onlyNonNull } from "../../../Library/Primitives/Array";
import {
  isNonNull,
  isNullOrUndefined,
  toArray,
  toUnNullable
} from "../../../Library/Util";
import { UICoreBox } from "../../UICore";
import { type TGenericFormComponentPro } from "../type";
import GFC_Outcome_Group from "./GFC_Outcome_Group";
type GFC_OutcomeProps = {|
  ...TGenericFormComponentPro
|};
type State = {||};
class GFC_Outcome extends React.Component<GFC_OutcomeProps, State> {
  constructor(props: GFC_OutcomeProps) {
    super(props);
    // this.state = {};
  }

  render() {
    return (
      <UICoreBox wrap="wrap" direction="row" width="100%" marginTop="sm">
        <GFC_Outcome_Group
          contents={onlyNonNull(
            toArray(
              this.props.config.outcomeConfig &&
                this.props.config.outcomeConfig.outcomes
            )
          )}
          layout={
            this.props.config.outcomeConfig &&
            this.props.config.outcomeConfig.layout
          }
          updatePictureChoices={outcomes => {
            this.props.onUpdate(
              {
                ...this.props.config,
                outcomeConfig: {
                  ...toUnNullable(this.props.config.outcomeConfig),
                  outcomes: outcomes
                }
              },
              false //is high frequent update
            );
          }}
          updatePictureChoicesWithouPersistence={outcomes => {
            this.props.onUpdate(
              {
                ...this.props.config,
                outcomeConfig: {
                  ...toUnNullable(this.props.config.outcomeConfig),
                  outcomes: outcomes
                }
              },
              true //is high frequent update
            );
          }}
          isInBuilder={this.props.isEditMode}
        />
      </UICoreBox>
    );
  }
}
export default GFC_Outcome;
