//@flow
import * as React from "react";
import {
  UICoreModal,
  UICoreBox,
  UICoreText
} from "../../../../Component/UICore";
import "./style.css";
import { getRecordName } from "../CellComponents/LinkerComponent/helper";
import WisptableConfig, {
  type tableSchemaColumnType,
  type tableDataRowType
} from "../../Configuration";
import DataUIContainer from "../DataUIContainer";
import { Scrollbars } from "react-custom-scrollbars";

type Props = {|
  columns: Array<tableSchemaColumnType>,
  row: tableDataRowType,
  onDismiss: () => void
|};
type State = {||};

class RecordModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _WisptableDataTypes = WisptableConfig.dataTypes;

  _renderRecord = () => {
    const itemComponents = [];
    for (let column of this.props.columns) {
      if (!this._WisptableDataTypes[column.type]) {
        continue;
      }
      const rowValue =
        this.props.row.cellColumnIdMap &&
        this.props.row.cellColumnIdMap[column.id];
      itemComponents.push(
        <UICoreBox alignItems="start" paddingBottom="md">
          <UICoreBox direction="row" alignItems="center">
            <i
              className={
                "RecordModal-Icon " + this._WisptableDataTypes[column.type].icon
              }
            />
            <UICoreText overflow="wrap" weight="bold">
              {column.name}
            </UICoreText>
          </UICoreBox>
          <UICoreBox width="100%" alignItems="start" paddingTop="sm">
            <DataUIContainer
              config={column.config}
              type={column.type}
              value={rowValue}
              columnID={column.id}
              rowId={this.props.row.id}
            />
          </UICoreBox>
        </UICoreBox>
      );
    }
    return itemComponents;
  };

  _getModalMaxHeight = () => {
    const defaultHeight = 450;
    const padding = 100;
    const windowHeight: ?number = window.innerHeight;
    if (!windowHeight) return defaultHeight;
    const idealHeight = windowHeight - padding;
    return idealHeight > defaultHeight ? idealHeight : defaultHeight;
  };

  render() {
    return (
      <UICoreModal
        size="md"
        header={getRecordName(this.props.columns, this.props.row)}
        onDismiss={this.props.onDismiss}
        body={
          <Scrollbars autoHeight autoHeightMax={this._getModalMaxHeight()}>
            <UICoreBox paddingTop="md" paddingLeft="md" paddingRight="md">
              {this._renderRecord()}
            </UICoreBox>
          </Scrollbars>
        }
      />
    );
  }
}
export default RecordModal;
