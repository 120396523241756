import React, { Component } from "react";
import { submit_answer } from "../../States/Answers/actions";
import PropTypes from "prop-types";
import formConfiguration from "../../../QuestionTypes";
import { connect } from "react-redux";
import { toArray } from "../../../../Library/Util";
import withStyles from "../../../../Helper_HOC/WithStyles";
import "./style.css";

class Question extends Component {
  constructor(props) {
    super(props);
    this.questionTypes = new formConfiguration().types;
  }

  onQuestionComplete(answer) {
    const { question_index, questions, nextQuestion, questionID } = this.props;
    this.props.submit_answer({
      questionID: questionID,
      index: question_index - 1,
      type: questions ? questions[question_index - 1].type : "",
      answer: answer
    });
  }

  onQuestionUpdate(answer, should_validate) {
    const {
      question_index,
      questions,
      nextQuestion,
      answers,
      questionID
    } = this.props;
    this.props.update_answer(
      {
        questionID: questionID,
        index: question_index - 1,
        type: questions ? questions[question_index - 1].type : "",
        answer: answer
      },
      should_validate
    );
  }

  onQuestionUpdateWithouUpdatingCurrentQuestionIndex(answer) {
    const {
      question_index,
      questions,
      nextQuestion,
      answers,
      questionID
    } = this.props;
    this.props.update_answer_without_update_current_question_index({
      questionID: questionID,
      index: question_index - 1,
      type: questions ? questions[question_index - 1].type : "",
      answer: answer
    });
  }

  getAnswer(answers, questionID) {
    return (
      answers &&
      toArray(answers).find(answer => answer.question_id === questionID)
    );
  }

  renderQuestions() {
    const { questions, question_index, answers, questionID } = this.props;
    let answer = this.getAnswer(answers, questionID);

    /* eslint-disable */
    let questionType =
      questions && questions[question_index - 1]
        ? questions[question_index - 1].type
        : "";
    let config =
      questions[question_index - 1] && questions[question_index - 1].config;
    let UI_Component = this.questionTypes[questionType].formUIComponent;
    return (
      <UI_Component
        isCurrentQuestion={
          this.props.question_index === this.props.currentQuestionNumber
        }
        config={config}
        answer={answer}
        onQuestionUpdate={this.onQuestionUpdate.bind(this)}
        onQuestionComplete={this.onQuestionComplete.bind(this)}
        onQuestionUpdateWithouUpdatingCurrentQuestionIndex={this.onQuestionUpdateWithouUpdatingCurrentQuestionIndex.bind(
          this
        )}
        contents={questions[question_index - 1].contents}
        dropdownDefault={questions[question_index - 1].dropdownDefault}
        styles={this.props.styles}
        answerPrimaryColor={this.props.answerPrimaryColor}
        answerSecondaryColor={this.props.answerSecondaryColor}
      />
    );
  }

  render() {
    if (this.props.answers.length) {
      return (
        <div className={this.props.className}>{this.renderQuestions()}</div>
      );
    } else {
      return <div>Loading...</div>;
    }
  }
}

Question.propTypes = {
  className: PropTypes.string.isRequired,
  question_index: PropTypes.number.isRequired,
  questions: PropTypes.array.isRequired,
  nextQuestion: PropTypes.func.isRequired,
  dispatch: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    answers: state.Answers.answers
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (
      pageNumber: number,
      content: any,
      shouldPersist: boolean
    ) => {
      dispatch(updateQuestionContent(pageNumber, content, shouldPersist));
    }
  };
};

export default connect(mapStateToProps, null)(withStyles(Question));
