import React, { Component } from "react";
import ClickFlash from "../../Animations/ClickFlash";
import WithStyles from "../../../Helper_HOC/WithStyles";
import "./style.css";

class Option extends Component {
  optionNode: ?HTMLDivElement;

  composedAnswer(item, index) {
    return [
      {
        choiceContent: item,
        index: index
      }
    ];
  }

  addSelectedClass() {
    return this.props.selected ? "dropDownOptions-options-selected" : "";
  }

  handleOptionClick(answer) {
    this.props.onQuestionComplete(answer);
    document.getElementsByClassName("dropDownOptions-options-selected")[0] &&
      document
        .getElementsByClassName("dropDownOptions-options-selected")[0]
        .classList.remove("dropDownOptions-options-selected");
  }

  render() {
    const composed_answer = this.composedAnswer(
      this.props.option,
      this.props.index
    );
    const borderWidth = this.props.selected ? "2px" : "1px";
    const backgroundColor = this.props.selected
      ? this.props.answerPrimaryColor
      : this.props.answerSecondaryColor;
    return (
      <ClickFlash onClick={() => this.handleOptionClick(composed_answer)}>
        <div
          ref={e => (this.optionNode = e)}
          style={{
            border: `solid ${borderWidth} ${this.props.styles.answer}`,
            color: this.props.styles.answer,
            backgroundColor: backgroundColor,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
          onMouseOver={e =>
            this.optionNode &&
            (this.optionNode.style.backgroundColor = this.props.answerPrimaryColor)
          }
          onMouseLeave={e =>
            this.optionNode &&
            (this.optionNode.style.backgroundColor = backgroundColor)
          }
          className={"dropDownOptions-options " + this.addSelectedClass()}
        >
          {this.props.option}
        </div>
      </ClickFlash>
    );
  }
}

export default WithStyles(Option);
