//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreText,
  UICoreImage,
  UICoreButton,
  UICoreImageUploader
} from "../../../../UICore";
import { type formStyleType } from "../../../../../FlowTypes/wispformStyleTypes";
import {
  type questionMediaTypeEnumType,
  questionMediaTypeEnum
} from "../../../../../FlowTypes/questionTypes";
import { uploadFileToAWS } from "../../../../../Library/AwsHelper";
import Dropzone from "react-dropzone";
import TextareaAutosize from "react-autosize-textarea";
import { flash } from "../../../../../Library/Animation";
import {
  logFeatureEvent,
  logReferralEvent
} from "../../../../../Library/Logger";
import {
  getScreenWidth,
  isSmallMobileScreen
} from "../../../../../Library/Window";
import "./style.css";
import {
  nonNullOrThrow,
  isLocalStorageAvailable
} from "../../../../../Library/Util";
import { getFormUid } from "../../../../../Library/ViewerContext/formViewerContext";
import { patchRequest } from "../../../../../Library/Request";
import EditableButton from "../../../../EditableButton";
import WithStyles from "../../../../../Helper_HOC/WithStyles";
import { type TOutcomeCTAData, type TOutcomeData } from "../../../type";
import { executeIfNotNull } from "../../../../../Library/Primitives/Function";
import { type TGenericContentOutcomeLayout } from "../../../type";

type SingleChoiceProps = {|
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  buttonPrimaryColor: string,
  buttonTextColor: string,
  styles: formStyleType,
  outcomeData: TOutcomeData,
  isInBuilder: boolean,
  isSelected: boolean,
  layout: ?TGenericContentOutcomeLayout,
  removePictureChoice: () => void,
  updateChoiceText: string => void,
  updateChoiceTextWithoutPersistence: string => void,
  updateChoiceDescription?: string => void,
  updateChoiceDescriptionWithoutPersistence?: string => void,
  updateChoicePic: string => void,
  onChoiceClick: () => void,
  updateChoicePrimaryButton: TOutcomeCTAData => void,
  updateChoiceSecondaryButton: TOutcomeCTAData => void
|};
type SingleChoiceState = {|
  uploading: boolean,
  showImageModal: boolean
|};

const kImageHeight = "160px";

export type pictureChoice = {|
  text: string,
  imageUrl: string
|};

class GFC_Outcome_Instance extends React.Component<
  SingleChoiceProps,
  SingleChoiceState
> {
  constructor(props: SingleChoiceProps) {
    super(props);
  }

  state = {
    uploading: false,
    showImageModal: false
  };

  _storeImage = (
    url: string,
    type: ?questionMediaTypeEnumType,
    originalWidth: ?number,
    originalHeight: ?number
  ) => {
    this.props.updateChoicePic(url);
  };

  _renderAddImageModal = () => {
    return (
      this.state.showImageModal && (
        <UICoreImageUploader
          onDismiss={() => {
            this.setState({ showImageModal: false });
          }}
          storeImage={this._storeImage}
          unsplashOrientation="landscape"
        />
      )
    );
  };

  _handleFileDrop = (files: Array<File>) => {
    logFeatureEvent("pictureChoice/uploadPictureChoiceClick");
    const image = files[0];
    uploadFileToAWS(
      image,
      {}, //config
      () => {
        logFeatureEvent("pictureChoice/startUploadingPictureChoice");
        this.setState({ uploading: true });
      },
      null,
      data => {
        this.setState({ uploading: false });
        this.props.updateChoicePic(data.Location);
      },
      () => {
        logFeatureEvent("pictureChoice/uploadPictureChoiceFailed");
      }
    );
  };

  _renderDefaultImageUploader = () => {
    return (
      <UICoreBox
        hexColor={this.props.answerPrimaryColor}
        height={this._isHorizontalLayout() ? "100%" : kImageHeight}
        shape="rounded"
        justifyContent="center"
        alignItems="center"
        className="UI_PictureChoice-uploader"
        onClick={_ => {
          logFeatureEvent("formbuilderPage/imageUploaderOutcomePage");
          this.setState({ showImageModal: true });
        }}
      >
        <UICoreBox
          direction="column"
          className="UI_PictureChoice-uploader-uploader"
          alignItems="center"
          justifyContent="center"
        >
          <UICoreBox paddingBottom="xm">
            <i
              style={{
                color: this.props.styles.answer,
                fontSize: "28px"
              }}
              className="fa fa-image"
            />
          </UICoreBox>
          <UICoreText hexColor={this.props.styles.answer} size="xs">
            Upload an image
          </UICoreText>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderSpinner = () => {
    return (
      <div class="UI_PictureChoice-spinner">
        <div
          style={{ backgroundColor: this.props.styles.answer }}
          class="bounce1"
        />
        <div
          style={{ backgroundColor: this.props.styles.answer }}
          class="bounce2"
        />
        <div
          style={{ backgroundColor: this.props.styles.answer }}
          class="bounce3"
        />
      </div>
    );
  };

  _handleChoiceClick = (event: SyntheticInputEvent<>) => {
    if (this.props.isInBuilder) return;
    //$FlowFixMe
    flash(event.currentTarget, 400, () => {
      this.props.onChoiceClick();
    });
  };

  _renderCloseButton = _ => {
    return (
      <UICoreBox
        color="white"
        width="25px"
        height="25px"
        shape="circle"
        justifyContent="center"
        alignItems="center"
        hoverable={true}
        boxShadow={true}
        position="absolute"
        top="0px"
        right="0px"
        className="UI_PictureChoice-CloseButton"
        onClick={this.props.removePictureChoice}
      >
        <i className="ion-close UI_PictureChoice-Close" />
      </UICoreBox>
    );
  };

  _isHorizontalLayout() {
    if (this.props.layout) {
      return this.props.layout === "horizontal";
    }
    return true;
  }

  _isVerticalLayout() {
    return this.props.layout === "vertical";
  }

  _renderMedia = () => {
    return (
      <UICoreBox
        width={this._isHorizontalLayout() ? "200px" : undefined}
        height={this._isHorizontalLayout() ? "133.33px" : undefined}
        paddingBottom="xxm"
      >
        {this.state.uploading ? (
          <UICoreBox
            hexColor={this.props.answerPrimaryColor}
            height={kImageHeight}
            shape="rounded"
            justifyContent="center"
            alignItems="center"
          >
            {this._renderSpinner()}
          </UICoreBox>
        ) : this.props.outcomeData.media || !this.props.isInBuilder ? (
          <UICoreBox className="UI_PictureChoice-pic" position="relative">
            <UICoreImage
              height={this._isHorizontalLayout() ? "133.33px" : kImageHeight}
              src={nonNullOrThrow(this.props.outcomeData.media)}
            />
            {this.props.isInBuilder && (
              <UICoreBox
                className="UI_PictureChoice-update"
                position="absolute"
                width="100%"
                height="100%"
                top="0px"
                left="0px"
                shape="rounded"
                justifyContent="center"
                alignItems="center"
              >
                <UICoreButton
                  size="sm"
                  color="white"
                  onClick={_ => {
                    logFeatureEvent("formbuilderPage/imageUploaderOutcomePage");
                    this.setState({ showImageModal: true });
                  }}
                >
                  Update
                </UICoreButton>
              </UICoreBox>
            )}
          </UICoreBox>
        ) : (
          this._renderDefaultImageUploader()
        )}
      </UICoreBox>
    );
  };

  _renderTitle = _ => {
    return (
      <UICoreBox marginTop="xm" position="relative" minHeight="30px">
        {this.props.isInBuilder ? (
          [
            !this.props.outcomeData.title && (
              <span
                style={{ color: this.props.styles.answer }}
                className="UI_PictureChoice-default"
              >
                Title...
              </span>
            ),
            <TextareaAutosize
              style={{
                color: this.props.styles.answer,
                borderBottomColor: this.props.styles.answer,
                fontFamily: this.props.styles && this.props.styles.fontFamily,
                fontWeight: "bold"
              }}
              className="UI_PictureChoice-textArea"
              value={this.props.outcomeData.title}
              onBlur={(e: SyntheticInputEvent<any>) =>
                this.props.updateChoiceText(e.target.value)
              }
              onInput={(e: SyntheticInputEvent<any>) =>
                this.props.updateChoiceTextWithoutPersistence(e.target.value)
              }
            />
          ]
        ) : (
          <UICoreText
            fontFamily={this.props.styles && this.props.styles.fontFamily}
            weight="bold"
            overflow="wrap"
            hexColor={this.props.styles.answer}
            className="gfc-outcome-instance-title"
          >
            {this.props.outcomeData.title}
          </UICoreText>
        )}
      </UICoreBox>
    );
  };

  _renderDescription = _ => {
    return (
      <UICoreBox position="relative" minHeight="30px" marginBottom="xm">
        {this.props.isInBuilder ? (
          [
            !this.props.outcomeData.description && (
              <span
                style={{ color: this.props.styles.answer, size: "14px" }}
                className="UI_PictureChoice-default"
              >
                description...
              </span>
            ),
            <TextareaAutosize
              style={{
                color: this.props.styles.answer,
                borderBottomColor: this.props.styles.answer,
                fontFamily: this.props.styles && this.props.styles.fontFamily,
                fontSize: "14px"
              }}
              className="UI_PictureChoice-textArea"
              value={this.props.outcomeData.description}
              onBlur={(e: SyntheticInputEvent<any>) =>
                executeIfNotNull(
                  this.props.updateChoiceDescription,
                  e.target.value
                )
              }
              onInput={(e: SyntheticInputEvent<any>) =>
                executeIfNotNull(
                  this.props.updateChoiceDescriptionWithoutPersistence,
                  e.target.value
                )
              }
            />
          ]
        ) : this.props.outcomeData.description ? (
          <UICoreText
            fontFamily={this.props.styles && this.props.styles.fontFamily}
            overflow="wrap"
            hexColor={this.props.styles.answer}
            size="xs"
            className="gfc-outcome-instance-description"
          >
            {this.props.outcomeData.description}
          </UICoreText>
        ) : (
          <div />
        )}
      </UICoreBox>
    );
  };

  _withHttp = (link: string) => {
    if (link.indexOf("http") >= 0) {
      return link;
    }
    return "http://" + link;
  };

  _handleCTAClick = () => {
    let url =
      (this.props.outcomeData.primaryCTA &&
        this.props.outcomeData.primaryCTA.url) ||
      "";
    if (url === "") return;
    let redirectUrl = this._withHttp(String(url));
    patchRequest(`api/v1/forms/${getFormUid()}/form_views/1`, {
      existing_form_view_uuid: isLocalStorageAvailable()
        ? localStorage.getItem("submitted-" + getFormUid())
        : null,
      finalPageButtonClicked: true,
      redirection: redirectUrl
    })
      .then(_ => {})
      .catch(_ => {});

    logReferralEvent("outcomePageReferalButtonClicked");

    // redirectUrl = convertStringRep2TagFilledString(
    //   String(redirectUrl),
    //   getQuestionIdPipedAnswerMap(toArray(this.props.answers))
    // );
    // if (
    //   this._isRedirectToWispform() ||
    //   (this.props.thankyouPageConfig &&
    //     this.props.thankyouPageConfig.sameTabRedirect)
    // ) {
    //   //Don't open a new tab when restart wispform
    //   window.location = redirectUrl;
    // } else {
    // if (isInIframe() && enableRedirectParentWindowInIframe()) {
    //   executeCallbackSafely(_ => {
    //     window.top.location.href = redirectUrl;
    //   });
    // } else {
    window.open(redirectUrl);
    // }
    // }
  };

  _renderCTA = () => {
    return (
      <UICoreBox
        dangerous_style={
          this._isHorizontalLayout()
            ? { marginLeft: "auto", marginRight: "auto" }
            : { marginTop: "auto" }
        }
        width={this._isHorizontalLayout() ? "120px" : undefined}
        height={this._isHorizontalLayout() ? "45px" : undefined}
      >
        {this.props.isInBuilder ? (
          <EditableButton
            buttonLink={
              (this.props.outcomeData.primaryCTA &&
                this.props.outcomeData.primaryCTA.url) ||
              ""
            }
            onButtonTextBlur={() => {}}
            onButtonTextUpdate={(_, text: string) => {
              this.props.updateChoicePrimaryButton({
                ...this.props.outcomeData.primaryCTA,
                text: text
              });
            }}
            onLinkUpdate={(url: string) => {
              this.props.updateChoicePrimaryButton({
                ...this.props.outcomeData.primaryCTA,
                url: url
              });
            }}
            buttonPrimaryColor={this.props.buttonPrimaryColor}
            buttonText={
              (this.props.outcomeData.primaryCTA &&
                this.props.outcomeData.primaryCTA.text) ||
              ""
            }
            buttonTextColor={this.props.buttonTextColor}
            buttonTextFontFamily={this.props.styles.fontFamily}
          />
        ) : (
          this.props.outcomeData &&
          this.props.outcomeData.primaryCTA != null &&
          this.props.outcomeData.primaryCTA.text && (
            <UICoreButton
              onClick={this._handleCTAClick}
              hexColor={this.props.buttonPrimaryColor}
              textHexColor={this.props.buttonTextColor}
              // newTab={true}
              // link={
              //   (this.props.outcomeData.primaryCTA &&
              //     this.props.outcomeData.primaryCTA.url) ||
              //   ""
              // }
              // buttonTextFontFamily={this.props.styles.fontFamily}
              size="lg"
              DEPRECATED_font_size="xs"
            >
              {(this.props.outcomeData.primaryCTA &&
                this.props.outcomeData.primaryCTA.text) ||
                " "}
            </UICoreButton>
          )
        )}
      </UICoreBox>
    );
  };

  _renderHorizontal() {
    return (
      <UICoreBox
        className="UI_PictureChoice-Choice"
        position="relative"
        width="100%"
        padding={this.props.isInBuilder ? "xm" : "sm"}
        justifyContent="center"
        alignItems="center"
      >
        {this.props.isInBuilder && this._renderAddImageModal()}
        {this.props.isInBuilder && this._renderCloseButton()}
        <UICoreBox
          // borderColor={this.props.styles.answer}
          // borderWidth={this.props.isSelected ? 3 : 1}
          shape="rounded"
          hexColor={
            this.props.isSelected
              ? this.props.answerPrimaryColor
              : this.props.answerSecondaryColor
          }
          verticalPadding="xm"
          paddingLeft="md"
          paddingRight="md"
          paddingTop="sm"
          paddingBottom="sm"
          height="100%"
          maxWidth="850px"
          alignItems="center"
          width="100%"
          className="GFC-outcome-instance-horizontal"
          // maxWidth="650px"
        >
          {" "}
          <UICoreBox className="GFC-outcome-instance-left">
            {this._renderMedia()}
          </UICoreBox>
          <UICoreBox className="GFC-outcome-instance-right">
            <UICoreBox className="GFC-outcome-instance-right-text">
              {this._renderTitle()}
              {this._renderDescription()}
            </UICoreBox>
            <UICoreBox
              justifyContent="center"
              className="GFC-outcome-instance-right-cta"
            >
              {this._renderCTA()}
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>{" "}
      </UICoreBox>
    );
  }

  _renderVertical() {
    return (
      <UICoreBox
        className="UI_PictureChoice-Choice"
        position="relative"
        padding="xm"
        width="50%"
      >
        {this.props.isInBuilder && this._renderAddImageModal()}
        {this.props.isInBuilder && this._renderCloseButton()}
        <UICoreBox
          borderColor={this.props.styles.answer}
          shape="rounded"
          hexColor={
            this.props.isSelected
              ? this.props.answerPrimaryColor
              : this.props.answerSecondaryColor
          }
          borderWidth={this.props.isSelected ? 3 : 1}
          padding="xm"
          height="100%"
        >
          {this._renderMedia()}
          <UICoreBox>
            {this._renderTitle()}
            {this._renderDescription()}
          </UICoreBox>
          {this._renderCTA()}
        </UICoreBox>
      </UICoreBox>
    );
  }

  render() {
    return this._isVerticalLayout()
      ? this._renderVertical()
      : this._renderHorizontal();
  }
}

export default WithStyles(GFC_Outcome_Instance);
