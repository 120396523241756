import React, { Component } from "react";
import HorizontalBarChart from "../PrimitiveCharts/HorizontalBarChart";

class MultichoiceChart extends Component {
  sorter(first, second) {
    return second.value - first.value;
  }

  getData() {
    let data = [];
    let raw_data = this.props.data.data;
    if (raw_data) {
      for (let prop in raw_data) {
        if (!raw_data.hasOwnProperty(prop)) continue;
        data.push({
          label: prop,
          value: raw_data[prop]
        });
      }
    }
    const sortedData = data.sort(this.sorter);
    return { data: sortedData };
  }
  render() {
    return <HorizontalBarChart data={this.getData()} type={this.props.type} />;
  }
}

export default MultichoiceChart;
