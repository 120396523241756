//@flow

export function executeIfNotNull<T, R>(
  func?: (...rest: Array<T>) => R,
  ...params: Array<T>
): ?R {
  if (func) {
    func(...params);
  }
}

export function throttle(fn: any => any, waitInMilliseconds: number) {
  var time = Date.now();
  return function () {
    if (time + waitInMilliseconds - Date.now() < 0) {
      fn();
      time = Date.now();
    }
  };
}
