// @flow
import { type logicJumpType } from "./jumpLogicTypes";

export const questionMediaTypeEnum = {
  image: 0,
  video: 1,
  youtube: 2,
  vimeo: 3
};
export type questionMediaTypeEnumType = $Values<typeof questionMediaTypeEnum>;
export type questionMediaType = {|
  type: questionMediaTypeEnumType,
  src: string,
  originalWidth?: number,
  originalHeight?: number
|};
