import HeaderBar from "../../Components/HeaderBar";
import { connect } from "react-redux";
import {
  saveToBackend,
  questionChanged,
  isPreviousBuilderStateSameAsCurrentBuilderState
} from "../../States/actions";
import React, { Component } from "react";

class HeaderBarContainer extends Component {
  constructor(props) {
    super(props);
  }

  _isJustLoaded: boolean = false;

  isLoadedAfterPreviousQuestions(NextQuestion) {
    return this.props.Question.form_id != NextQuestion.form_id;
  }

  componentDidMount() {
    this._isJustLoaded = true;
  }

  componentWillUpdate(nextProps, nextState) {
    if (
      !isPreviousBuilderStateSameAsCurrentBuilderState(
        this.props.Question,
        nextProps.Question,
        ["isQuestionChanged", "last_edited_question"]
      ) &&
      !this.isLoadedAfterPreviousQuestions(nextProps.Question)
    ) {
      if (!this._isJustLoaded) {
        this.props.questionChanged();
      }
      this._isJustLoaded = false;
    }
  }

  render() {
    return (
      <HeaderBar
        isChanged={this.props.Question.isQuestionChanged}
        showPreview={this.props.showPreview}
        saveToBackend={() => this.props.saveToBackend()}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    Question: state.Question
  };
};

function mapDispatchToProps(dispatch) {
  return {
    questionChanged: () => dispatch(questionChanged()),
    saveToBackend: () => dispatch(saveToBackend())
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderBarContainer);
