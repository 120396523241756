//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox } from "..";
type Props = {|
  iconClassName: string,
  iconColor?: "gray" | "black",
  onClick?: () => void,
  size?: "xs" | "md" | "lg" | "xl"
|};
type State = {||};
class UICoreIconButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getColorClass = () => {
    switch (this.props.iconColor) {
      case "gray":
        return "UICoreIconButton-gray";

      case "black":
        return "UICoreIconButton-black";

      default:
        return "UICoreIconButton-gray";
    }
  };

  _getSizeStyle = () => {
    switch (this.props.size) {
      case "xs":
        return {
          ["font-size"]: "13px"
        };

      case "md":
        return {
          ["font-size"]: "16px"
        };
      case "lg":
        return {
          ["font-size"]: "18px"
        };

      case "xl":
        return {
          ["font-size"]: "22px"
        };

      default:
        return {
          ["font-size"]: "13px"
        };
    }
  };

  render() {
    return (
      <UICoreBox
        justifyContent="center"
        alignItems="center"
        onClick={this.props.onClick}
      >
        <i
          style={{
            ...this._getSizeStyle()
          }}
          className={`UICoreIconButton ${
            this.props.iconClassName
          } ${this._getColorClass()}`}
        />
      </UICoreBox>
    );
  }
}
export default UICoreIconButton;
