// @flow
import * as React from "react";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import {
  type questionConfig,
  QuestionTypeEnum,
  type QuestionTypes
} from "../../../Pages/QuestionTypes";
import { type questionComponentTypes } from "../../../FlowTypes/questionComponentTypes";
import {
  type subscriptionQuestionContentsType,
  type subscriptionPlanStripeType,
  currencyEnum,
  intervalEnum
} from "../../../FlowTypes/paymentType";
import { UICoreBox, UICoreRadioButtons, UICoreText } from "../../UICore";
import { type UICoreRadioButtonLabelType } from "../../UICore/UICoreRadioButtons";
import PaymentCard from "../Shared/PaymentCard";
import TextSubmitButton from "../ShortTextInput/TextSubmitButton";
import { isMobile } from "../../Views/helper";
import { getRequest } from "../../../Library/Request";
import { convertArrayToDict } from "../../../Library/Primitives/Array";
import { getFormUUID } from "../../../Library/ViewerContext";
import { mapDict } from "../../../Library/Primitives/Dict";
import { safeGet, toArray, nonNullOrThrow } from "../../../Library/Util";
import withStripeSubscriptionPlans from "../../../Helper_HOC/withStripeSubscriptionPlans";
import { getSubscriptionName } from "../../../Library/Payment";
import { isSmallMobileScreen } from "../../../Library/Window";
import { firstMonetaryQuestionType } from "../../../Pages/Form/States/Answers/selectors";
import { connect } from "react-redux";

type Props = {|
  ...questionComponentTypes,
  contents: ?subscriptionQuestionContentsType,
  availablePlansFromStripe: { [string]: subscriptionPlanStripeType },
  firstMonetaryQuestionType: QuestionTypes
|};
type State = {|
  selectedPlan: ?string
|};

class UI_Subscription extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedPlan: null
    };
  }

  _getPlanLabels = (): Array<UICoreRadioButtonLabelType> => {
    const allPlans = mapDict(this.props.availablePlansFromStripe, plan => {
      return { text: getSubscriptionName(plan), extra: plan.plan_id };
    });
    // If no available plan selected, return all
    if (!this.props.contents) {
      if (this.props.availablePlansFromStripe) {
        return allPlans;
      }
      return [];
    }
    return allPlans.filter(
      plan =>
        this.props.contents &&
        this.props.contents.availablePlans.some(planID => planID === plan.extra)
    );
  };

  _submitAnswer = () => {
    if (isMobile()) {
      //give it some time to close keyboard first
      //otherwise, the scroll position will be fucked
      setTimeout(() => {
        this.props.onQuestionComplete &&
          // $FlowFixMe
          this.props.onQuestionComplete(safeGet(_ => this.props.answer.answer));
      }, 350);
    } else {
      this.props.onQuestionComplete &&
        // $FlowFixMe
        this.props.onQuestionComplete(safeGet(_ => this.props.answer.answer));
    }
  };

  _renderSubmit = () => {
    if (this.props.isInBuilder) {
      return null;
    } else {
      return (
        <UICoreBox width="100%" marginTop="xm">
          <TextSubmitButton
            onClick={() => {
              this._submitAnswer();
            }}
          />
        </UICoreBox>
      );
    }
  };

  render() {
    return (
      <UICoreBox>
        <UICoreRadioButtons
          selectedLocator={label => label.extra === this.state.selectedPlan}
          labels={this._getPlanLabels()}
          onChange={(index: number, planID: ?string) => {
            this.setState({
              selectedPlan: planID
            });
            const nonNullPlanID = nonNullOrThrow(planID);
            this.props.onQuestionUpdate &&
              // $FlowFixMe
              this.props.onQuestionUpdate({
                name: safeGet(_ =>
                  getSubscriptionName(
                    this.props.availablePlansFromStripe[nonNullPlanID]
                  )
                ),
                planID: planID
              });
          }}
          customStyling={{
            selectedLabelBoxStyle: {
              hexColor: this.props.answerSecondaryColor,
              dangerous_style: {
                borderRadius: "12px"
              },
              padding: "sm"
            },
            defaultLabelBoxStyle: {
              padding: "sm"
            },
            selectedLabelTextStyle: {
              hexColor: this.props.styles.answer,
              weight: "normal",
              fontFamily: this.props.styles && this.props.styles.fontFamily
            },
            defaultLabelTextStyle: {
              hexColor: this.props.styles.answer,
              weight: "normal",
              fontFamily: this.props.styles && this.props.styles.fontFamily
            },
            outterCircleColor: this.props.answerPrimaryColor,
            innerCircleColor: this.props.styles.answer
          }}
        ></UICoreRadioButtons>
        {!this.props.isInBuilder &&
        this.props.firstMonetaryQuestionType !==
          QuestionTypeEnum.Subscription ? (
          <UICoreBox paddingTop="sm">
            <UICoreText size="xs" hexColor={this.props.answerSecondaryColor}>
              *The subscription fee will be charged to the same card you entered
              above.
            </UICoreText>
          </UICoreBox>
        ) : (
          <UICoreBox paddingTop="md">
            <PaymentCard
              fontSize={isSmallMobileScreen() ? "14px" : "16px"}
              answerPrimaryColor={this.props.answerPrimaryColor}
              answerSecondaryColor={this.props.answerSecondaryColor}
              styles={this.props.styles}
              onQuestionUpdateWithouUpdatingCurrentQuestionIndex={_ => {}}
              answer={safeGet(
                _ => this.props.answer && this.props.answer.answer
              )}
            />
          </UICoreBox>
        )}
        {this._renderSubmit()}
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    firstMonetaryQuestionType: firstMonetaryQuestionType(state.Answers)
  };
};

export default connect(
  mapStateToProps,
  null
)(withStripeSubscriptionPlans(UI_Subscription));
