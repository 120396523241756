//@flow
import {
  stringReverse,
  isNonEmptyString,
  toArray,
  toNumber
} from "../../Library/Util";
import { isArray } from "util";
import {
  type ComperatorsType,
  targetSelector
} from "../../FlowTypes/jumpLogicComperatorTypes";

const Comperators: ComperatorsType = {
  is: {
    typeName: "is",
    displayName: "is",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      //For backward compatibility of older forms which uses "is" instead of "contains only"
      if (isArray(answer)) {
        if (answer.length === 1 && answer[0].choiceContent === targetAnswer) {
          return true;
        } else {
          return false;
        }
      }
      //For all the other cases, just compare them with the targetAnswer directly
      return answer === targetAnswer;
    }
  },
  isNot: {
    typeName: "isNot",
    displayName: "is not",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      //For backward compatibility of older forms which uses "is" instead of "contains only"
      if (isArray(answer)) {
        if (answer.length === 1 && answer[0].choiceContent === targetAnswer) {
          return false;
        } else {
          return true;
        }
      }
      return answer !== targetAnswer;
    }
  },
  contain: {
    typeName: "contain",
    displayName: "contains",
    targetSelector: targetSelector.text,
    compare: (answer: any, targetAnswer: any) => {
      return String(answer)
        .toLowerCase()
        .includes(String(targetAnswer).toLowerCase());
    }
  },
  textStartWith: {
    typeName: "textStartWith",
    displayName: "begins with",
    targetSelector: targetSelector.text,
    compare: (answer: any, targetAnswer: any) => {
      return (
        String(answer)
          .toLowerCase()
          .indexOf(String(targetAnswer).toLowerCase()) === 0
      );
    }
  },
  textEndWith: {
    typeName: "textEndWith",
    displayName: "ends with",
    targetSelector: targetSelector.text,
    compare: (answer: any, targetAnswer: any) => {
      return (
        stringReverse(answer)
          .toLowerCase()
          .indexOf(stringReverse(targetAnswer).toLowerCase()) === 0
      );
    }
  },
  notContain: {
    typeName: "notContain",
    displayName: "not contains",
    targetSelector: targetSelector.text,
    compare: (answer: any, targetAnswer: any) => {
      return !String(answer)
        .toLowerCase()
        .includes(String(targetAnswer).toLowerCase());
    }
  },
  ratingIs: {
    typeName: "ratingIs",
    displayName: "is",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      return toNumber(answer) === targetAnswer;
    }
  },
  ratingIsNot: {
    typeName: "ratingIsNot",
    displayName: "is not",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      return toNumber(answer) !== targetAnswer;
    }
  },
  ratingLessThan: {
    typeName: "ratingLessThan",
    displayName: "is less than",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      return answer < targetAnswer;
    }
  },
  ratingMoreThan: {
    typeName: "ratingMoreThan",
    displayName: "is more than",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      return answer > targetAnswer;
    }
  },
  numericIs: {
    typeName: "numericIs",
    displayName: "is",
    targetSelector: targetSelector.numeric,
    compare: (answer: any, targetAnswer: any) => {
      return toNumber(answer) === toNumber(targetAnswer);
    }
  },
  numericIsNot: {
    typeName: "numericIsNot",
    displayName: "is not",
    targetSelector: targetSelector.numeric,
    compare: (answer: any, targetAnswer: any) => {
      return toNumber(answer) !== toNumber(targetAnswer);
    }
  },
  numericLessThan: {
    typeName: "numericLessThan",
    displayName: "is less than",
    targetSelector: targetSelector.numeric,
    compare: (answer: any, targetAnswer: any) => {
      return toNumber(answer) < toNumber(targetAnswer);
    }
  },
  numericMoreThan: {
    typeName: "numericMoreThan",
    displayName: "is more than",
    targetSelector: targetSelector.numeric,
    compare: (answer: any, targetAnswer: any) => {
      return toNumber(answer) > toNumber(targetAnswer);
    }
  },
  fileIs: {
    typeName: "fileIs",
    displayName: "is",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      if (targetAnswer === "uploaded") {
        return isNonEmptyString(targetAnswer);
      }
      return false;
    }
  },
  multiSelectContain: {
    typeName: "multiSelectContain",
    displayName: "contains",
    targetSelector: targetSelector.choices,
    compare: (answers: Array<{ choiceContent: string }>, targetAnswer: any) => {
      if (
        toArray(answers).some(answer => answer.choiceContent === targetAnswer)
      ) {
        return true;
      }
      return false;
    }
  },
  multiSelectNotContain: {
    typeName: "multiSelectNotContain",
    displayName: "doesn't contain",
    targetSelector: targetSelector.choices,
    compare: (answers: Array<{ choiceContent: string }>, targetAnswer: any) => {
      if (
        !toArray(answers).some(answer => answer.choiceContent === targetAnswer)
      ) {
        return true;
      }
      return false;
    }
  },
  multiSelectIs: {
    typeName: "multiSelectIs",
    displayName: "only contains",
    targetSelector: targetSelector.choices,
    compare: (answers: Array<{ choiceContent: string }>, targetAnswer: any) => {
      if (
        toArray(answers).length === 1 &&
        answers[0].choiceContent === targetAnswer
      ) {
        return true;
      }
      return false;
    }
  },
  //for backward compatibility purpose
  "is not": {
    typeName: "isNot",
    displayName: "is not",
    targetSelector: targetSelector.choices,
    compare: (answer: any, targetAnswer: any) => {
      return answer !== targetAnswer;
    }
  }
};

export default Comperators;
