//@flow
import * as React from "react";
import "./style.css";
import { width } from "window-size";
import { UICoreBox } from "..";
import ReactDOM from "react-dom";
import Placeholder from "../../Placeholder";
import { isNonEmptyString, executeCallbackSafely } from "../../../Library/Util";
type Props = {|
  src: string,
  fallbackSrc?: string,
  onLoadingError?: () => void,
  width?: number | string,
  height?: number | string,
  maxHeight?: number | string,
  maxWidth?: number | string,
  shape?: "rounded" | "topRounded" | "circle"
|};
type State = {|
  showLoading: boolean
|};
class UICoreImage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showLoading: true
  };

  _imageContainerNode: ?HTMLImageElement = null;

  _imageNode: ?HTMLImageElement = null;

  _adjustImageDimension = (
    imageNaturalWidth: number,
    imageNaturalHeight: number,
    containerWidth: number,
    containerHeight: number
  ) => {
    //image is bigger than container in any dimension
    if (
      imageNaturalHeight > containerHeight &&
      imageNaturalWidth > containerWidth
    ) {
      imageNaturalWidth / containerWidth > imageNaturalHeight / containerHeight
        ? this._setImageDimension(
            // width is bigger, fit height first
            imageNaturalWidth * (containerHeight / imageNaturalHeight),
            containerHeight
          )
        : this._setImageDimension(
            // height is bigger, fit width first
            containerWidth,
            imageNaturalHeight * (containerWidth / imageNaturalWidth)
          );
    } else if (imageNaturalHeight > containerHeight) {
      this._setImageDimension(
        // height is bigger, fit width first
        containerWidth,
        imageNaturalHeight * (containerWidth / imageNaturalWidth)
      );
    } else if (imageNaturalWidth > containerWidth) {
      this._setImageDimension(
        // width is bigger, fit height first
        imageNaturalWidth * (containerHeight / imageNaturalHeight),
        containerHeight
      );
    }
    //image is smaller than container
    else {
      containerWidth / imageNaturalWidth > containerHeight / imageNaturalHeight
        ? this._setImageDimension(
            // height is bigger, fit width first
            containerWidth,
            imageNaturalHeight * (containerWidth / imageNaturalWidth)
          )
        : this._setImageDimension(
            // width is bigger, fit height first
            imageNaturalWidth * (containerHeight / imageNaturalHeight),
            containerHeight
          );
    }
  };

  _setImageDimension = (width: number, height: number) => {
    if (!this._imageNode) return;
    this._imageNode.style.width = width + "px";
    this._imageNode.style.height = height + "px";
    this._imageNode.style.maxWidth = "";
  };

  _handleImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    this.setState({ showLoading: false });
    // $FlowFixMe
    const imageNaturalWidth = e.target.naturalWidth;
    // $FlowFixMe
    const imageNaturalHeight = e.target.naturalHeight;
    const containerWidth =
      this._imageContainerNode && this._imageContainerNode.offsetWidth;
    const containerHeight =
      this._imageContainerNode && this._imageContainerNode.offsetHeight;
    if (
      imageNaturalWidth &&
      imageNaturalHeight &&
      containerWidth &&
      containerHeight
    ) {
      this._adjustImageDimension(
        imageNaturalWidth,
        imageNaturalHeight,
        containerWidth,
        containerHeight
      );
    }
  };

  render() {
    return this.props.src ? (
      [
        <UICoreBox
          ref={e => {
            //$FlowFixMe
            this._imageContainerNode = ReactDOM.findDOMNode(e);
          }}
          height={this.props.height}
          width={this.props.width}
          maxHeight={String(this.props.maxHeight)}
          maxWidth={String(this.props.maxWidth)}
          shape={this.props.shape || "rounded"}
          justifyContent="center"
          alignItems="center"
          overflowX="hidden"
          overflowY="hidden"
          position="relative"
        >
          <img
            ref={e => {
              this._imageNode = e;
            }}
            onLoad={this._handleImageLoad}
            className="UICoreImage"
            src={this.props.src}
            style={{
              //set a maxWidth first and later removed by setter
              //so container box's dimension can be initialized properly
              maxWidth: "100%",
              height: "auto"
            }}
            onError={e => {
              if (isNonEmptyString(this.props.fallbackSrc)) {
                e.target.src = this.props.fallbackSrc;
              }
              executeCallbackSafely(this.props.onLoadingError);
            }}
          />
          {this.state.showLoading && (
            <UICoreBox
              height={this.props.height || "100%"}
              width={this.props.width || "100%"}
              maxHeight={String(this.props.maxHeight)}
              maxWidth={String(this.props.maxWidth)}
              minHeight={200}
              shape="rounded"
              position="absolute"
              className="UICoreImagePlaceholder"
            >
              <Placeholder />
            </UICoreBox>
          )}
        </UICoreBox>
      ]
    ) : (
      <UICoreBox
        maxHeight={String(this.props.maxHeight)}
        maxWidth={String(this.props.maxWidth)}
        height={String(this.props.height)}
        width={String(this.props.width)}
        justifyContent="center"
        alignItems="center"
        color="lightGrey"
        shape="rounded"
      >
        <i className="fa fa-image" />
      </UICoreBox>
    );
  }
}
export default UICoreImage;
