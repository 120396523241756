//@flow
export function toStringArray(arrayLike: any): Array<string> {
  if (!Array.isArray(arrayLike)) {
    return [];
  }
  return arrayLike.filter((item: any) => typeof item === "string");
}

export function toStringOrNull(stringLike: any): string | null {
  if (stringLike) {
    return String(stringLike);
  } else {
    return null;
  }
}

export function unNullString(stringLike: any): string {
  if (!stringLike) {
    return "";
  } else return stringLike;
}

export function isEmptyArray(array: Array<any>): boolean {
  return array.length === 0;
}

export function isNonEmptyArray(maybeArray: any): boolean {
  if (Array.isArray(maybeArray)) {
    return maybeArray.length !== 0;
  } else {
    return false;
  }
}

export function toArray(maybeArray: any): Array<any> {
  return isNonEmptyArray(maybeArray) ? maybeArray : [];
}

export function isIdenticalArray<T>(arr1: Array<T>, arr2: Array<T>): boolean {
  if (arr1.length !== arr2.length) {
    return false;
  }
  return arr1.every((val, index) => {
    return val === arr2[index];
  });
}

export function replaceElementByAnchor<T>(
  list: Array<T>,
  anchor: T => boolean,
  replacer: T => T
): Array<T> {
  return list.map(item => {
    if (anchor(item)) {
      return replacer(item);
    } else {
      return item;
    }
  });
}

function isArrayEqualLength(first: Array<any>, second: Array<any>): boolean {
  return first.length === second.length;
}

export function isNull(value: any): boolean {
  return value === null;
}

export function removeDuplicate(list: Array<any>): Array<any> {
  var seen = {};
  return list.filter(function (item) {
    return seen.hasOwnProperty(item) ? false : (seen[item] = true);
  });
}

export function isString(value: any): boolean {
  return typeof value === "string";
}

export function lastChar(str: string): string {
  if (isNonEmptyString(str)) {
    return str[str.length - 1];
  }
  return "";
}

export function countOccurenceOfChar(str: string, char: string): number {
  var letter_Count = 0;
  for (var position = 0; position < str.length; position++) {
    if (str.charAt(position) === char) {
      letter_Count += 1;
    }
  }
  return letter_Count;
}

export function lastItem<T>(array: Array<T>): T {
  return array[array.length - 1];
}

export function stringReverse(maybeString: any): string {
  if (isString(maybeString)) {
    return maybeString.split("").reverse().join("");
  }
  return "";
}

export function isUndefined(value: any): boolean {
  return value === undefined;
}

export function isNonEmptyString(maybeString: any): boolean {
  return (
    isString(maybeString) &&
    !isUndefined(maybeString) &&
    !isNull(maybeString) &&
    maybeString.length > 0
  );
}

export function getNonEmptyStringWithFallback(
  maybeString: any,
  fallback: string
): string {
  if (isNonEmptyString(maybeString)) {
    return maybeString;
  } else {
    return fallback;
  }
}

export function isNullOrUndefined(value: any): boolean {
  return isNull(value) || isUndefined(value);
}

export function isNonNull(value: any): boolean {
  return !isNullOrUndefined(value);
}

export function nonNullOrThrow<T>(a: ?T): T {
  if (a !== null && a !== undefined) {
    return a;
  }
  throw "expected non null, but got null";
}

export function toUnNullable(value: any): Object {
  return isNullOrUndefined(value) ? {} : value;
}

export function isArrayEqualOneLevel(
  first: Array<Object>,
  second: Array<Object>
): boolean {
  if (!isArrayEqualLength(first, second)) {
    return false;
  } else {
    for (let i = 0; i < first.length; i++) {
      if (first[i] !== second[i]) {
        return false;
      }
    }
    return true;
  }
}

export function toNumber(numerString: any) {
  return isNaN(parseInt(numerString)) ? 0 : parseInt(numerString);
}

export function isNumber(maybyNumber: any) {
  return (
    maybyNumber !== null &&
    maybyNumber !== undefined &&
    !isNaN(maybyNumber) &&
    typeof maybyNumber === "number"
  );
}

export function isStringNumericOrNull(maybeNumber: any) {
  if (isNullOrUndefined(maybeNumber)) {
    return true;
  } else if (!isNaN(parseFloat(maybeNumber)) && isFinite(maybeNumber)) {
    return true;
  } else if (maybeNumber === "") {
    return true;
  }
  return false;
}

export function explode(num: number): Array<number> {
  let return_arr = [];
  for (let i = 0; i < num; i++) {
    return_arr.push(i);
  }
  return return_arr;
}

export function isStringAllLetters(str: string): boolean {
  var letters = /^[A-Za-z]+$/;
  if (str.match(letters)) {
    return true;
  } else {
    return false;
  }
}

export function duplicateItemForNTimes<T>(item: T, num: number): Array<T> {
  if (num == 0) return [];
  var a = [item];
  while (a.length * 2 <= num) a = a.concat(a);
  if (a.length < num) a = a.concat(a.slice(0, num - a.length));
  return a;
}

export function getMaxFromNumberArray(numbers: Array<number>) {
  return numbers.reduce((max, number) => {
    return number > max ? number : max;
  }, 0);
}

export function replace<T>(arr: Array<T>, element: T, index: number): Array<T> {
  return [...arr.slice(0, index), element, ...arr.slice(index + 1)];
}

export function removeElemenent(items: Array<any>, index: number) {
  return [
    ...toArray(items).slice(0, index),
    ...toArray(items).slice(index + 1)
  ];
}

export function insertElementAt<T>(
  arr: Array<T>,
  element: T,
  index: number
): Array<T> {
  return [...arr.slice(0, index), element, ...arr.slice(index)];
}

export function swap(
  arr: Array<any>,
  previousIndex: number,
  newIndex: number
): Array<any> {
  var array = arr.slice(0);
  if (newIndex >= array.length) {
    var k = newIndex - array.length;
    while (k-- + 1) {
      array.push(undefined);
    }
  }
  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);
  return array;
}

export function extractHostname(url: string) {
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf("//") > -1) {
    hostname = url.split("/")[2];
  } else {
    hostname = url.split("/")[0];
  }

  //find & remove port number
  hostname = hostname.split(":")[0];
  //find & remove "?"
  hostname = hostname.split("?")[0];

  return hostname;
}

export function extractRootDomain(url: string) {
  var domain = extractHostname(url),
    splitArr = domain.split("."),
    arrLen = splitArr.length;

  //extracting the root domain here
  //if there is a subdomain
  if (arrLen > 2) {
    domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
    //check to see if it's using a Country Code Top Level Domain (ccTLD) (i.e. ".me.uk")
    if (splitArr[arrLen - 2].length == 2 && splitArr[arrLen - 1].length == 2) {
      //this is using a ccTLD
      domain = splitArr[arrLen - 3] + "." + domain;
    }
  }
  return domain;
}

export function nullSafe<T>(accessor: () => T): ?T {
  try {
    return accessor();
  } catch (error) {
    return null;
  }
}

export function applyLogic(a: ?any, b: ?any, logic: "and" | "or") {
  if (a == null && b != null) return b;
  if (a != null && b == null) return a;
  if (logic == "and") return a && b;
  else return a || b;
}

export function getReferral() {
  const agent = navigator.userAgent;
  if (document.referrer) {
    return extractRootDomain(document.referrer);
  } else if (agent.indexOf("MicroMessenger") !== -1) {
    return "wechat.com";
  } else if (agent.indexOf("FBAV") !== -1) {
    return "facebook.com";
  } else {
    return "other";
  }
}

export function isIE() {
  if (
    navigator.appName == "Microsoft Internet Explorer" ||
    !!(
      navigator.userAgent.match(/Trident/) || navigator.userAgent.match(/rv:11/)
    )
  ) {
    return true;
  } else {
    return false;
  }
}

export function getCurrentDayTime() {
  var today = new Date();
  var date =
    today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();
  var time =
    today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  return date + " " + time;
}

export function resolveInTime(
  timeInSeconds: number,
  cb: any => void
): Promise<boolean> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      cb && cb();
      resolve(true);
    }, timeInSeconds);
  });
}

export function delayOneCycle(cb: any) {
  setTimeout(() => {
    cb && cb();
  }, 0);
}

export function safeGet<T>(getter: () => T): ?T {
  try {
    return getter();
  } catch (error) {
    return null;
  }
}

type callbackType = (...args: Array<any>) => mixed;
export function executeCallbackSafely(
  cb: ?callbackType,
  ...args: Array<mixed>
): mixed {
  if (cb) {
    try {
      return cb(...args);
    } catch (error) {
      if (process.env.NODE_ENV == "development") {
        throw error;
      }
      return null;
    }
  }
  return null;
}

export function injectArgs(
  cb: callbackType,
  ...injectedArgs: Array<any>
): callbackType {
  return (...args) => {
    cb(...injectedArgs, ...args);
  };
}

export function filterNull(arr: Array<any>): Array<any> {
  return arr.filter(a => !isNullOrUndefined(a));
}

export function isLocalStorageAvailable() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}
