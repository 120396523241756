// @flow

export const UICoreIconTypeEnum = {
  image: 0,
  video: 1,
  youtube: 2,
  vimeo: 3,
  checkMark: 4,
  calculator: 5,
  trash: 6,
  clone: 7,
  plus: 8,
  bolt: 9,
  rocket: 10,
  unlock: 11,
  close: 12,
  clipboard: 13,
  network: 14,
  iosCalculator: 15
};
