// @flow
import * as React from "react";
import FontPicker from "font-picker-react";
import { connect } from "react-redux";
import { update_style } from "../../../States/actions";
import {
  type formStyleType,
  type background,
  styleAttributes
} from "../../../../../FlowTypes/wispformStyleTypes";
import "./style.css";
import {
  UICoreText,
  UICoreBox,
  UICoreButton
} from "../../../../../Component/UICore";

type Props = {|
  styles: formStyleType,
  updateStyle: ($Keys<formStyleType>, string | background) => any
|};
type State = {||};

class DesignPanelFontSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox>
        <UICoreBox paddingBottom="sm">
          <UICoreText size="md" color="black" weight="medium">
            Text Font
          </UICoreText>
        </UICoreBox>
        <UICoreBox>
          <UICoreText size="xs" color="black">
            Wispform supports font customization through google font.
          </UICoreText>
          <UICoreBox paddingTop="xm">
            <UICoreText size="xs" color="black">
              Selected font will be applied to question title, description,
              buttons as well as user input.
            </UICoreText>
          </UICoreBox>
        </UICoreBox>
        <UICoreBox paddingTop="sm" direction="row">
          <FontPicker
            limit={300}
            apiKey="AIzaSyB4mLZ9bBKLD_zDHoms3zYG38z7jrH7nEo"
            activeFontFamily={
              (this.props.styles && this.props.styles.fontFamily) || "Roboto"
            }
            onChange={nextFont => {
              this.props.updateStyle(
                styleAttributes.fontFamily,
                nextFont.family
              );
            }}
          />
          {this.props.styles && this.props.styles.fontFamily && (
            <UICoreBox paddingLeft="xm">
              <UICoreButton
                onClick={() => {
                  this.props.updateStyle(styleAttributes.fontFamily, "Roboto");
                }}
              >
                Reset
              </UICoreButton>
            </UICoreBox>
          )}
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    styles: state.Question.styles
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateStyle: (style, value) => dispatch(update_style(style, value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesignPanelFontSection);
