//@flow
import React, { Component } from "react";
import logo_svg from "../newLogoSVG";
import logo_svg2 from "../newLogoSVG2";
import "./style.css";

type Props = {|
  width?: number,
  height?: number
|};

type State = {||};
class Logo extends Component<Props, State> {
  render() {
    return (
      <div>
        <div
          className="Logo"
          dangerouslySetInnerHTML={{
            __html: logo_svg2(this.props.width, this.props.height)
          }}
        />
      </div>
    );
  }
}

export default Logo;
