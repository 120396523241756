//@flow
import * as React from "react";
import { UICoreBox } from "../../../../Component/UICore";
import CellPopupWrapper from "./CellPopupWrapper";
import "./style.css";
type Props = {|
  width?: number | string,
  children: React.Node,
  onDismiss: () => void
|};
type State = {||};
class CellPopup extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <CellPopupWrapper
        width={this.props.width}
        onDismiss={this.props.onDismiss}
      >
        <div className="CellPopup">
          <UICoreBox className="CellPopup-Content">
            {this.props.children}
          </UICoreBox>
        </div>
      </CellPopupWrapper>
    );
  }
}
export default CellPopup;
