// @flow
import * as React from "react";
import "./style.css";
import { type questionComponentTypes } from "../../../FlowTypes/questionComponentTypes";
import ShortTextInput from "../ShortTextInput";

type Props = {|
  ...questionComponentTypes
|};
type State = {||};

class UI_Number extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <ShortTextInput inputType="numeric" {...this.props} />;
  }
}

export default UI_Number;
