//@flow
import RatingComponent from "../../Components/CellComponents/RatingComponent";
import RatingDataUI from "../../Components/DataUIComponents/RatingDataUI";
import {
  type DataType,
  type numberCellType,
  type columnConfigType,
  type filterType
} from "../index";
const RatingType = {
  icon: "fa fa-star",
  cellComponent: RatingComponent,
  dataUIComponent: RatingDataUI,
  displayName: "Rating",
  sortFunction: sorter,
  filter: {
    operators: ["=", "≠", ">", "≥", "<", "≤"],
    getOptions: () =>
      new Map([
        ["1", { display: "⭐️", value: "1" }],
        ["2", { display: "⭐️⭐️", value: "2" }],
        ["3", { display: "⭐️⭐️⭐️", value: "3" }],
        ["4", { display: "⭐️⭐️⭐️⭐️", value: "4" }],
        ["5", { display: "⭐️⭐️⭐️⭐️⭐️", value: "5" }]
      ]),
    filterFunction: filter,
    optionInputType: "dropdown"
  }
};

function sorter(first: numberCellType, second: numberCellType) {
  const unNullFirst = !first ? 0 : first;
  const unNullSecond = !second ? 0 : second;
  return unNullFirst - unNullSecond;
}

function filter(value: ?numberCellType, filterDetail: filterType) {
  const unNullValue = value == null ? 0 : value;
  const filterValue: number = parseInt(filterDetail.value);
  switch (filterDetail.operator) {
    case ">":
      return unNullValue > filterValue;
    case "<":
      return unNullValue < filterValue;
    case "≥":
      return unNullValue >= filterValue;
    case "≤":
      return unNullValue <= filterValue;
    case "=":
      return unNullValue === filterValue;
    case "≠":
      return unNullValue !== filterValue;
    default:
      return true;
  }
}

export default RatingType;
