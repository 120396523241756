//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreBox,
  UICoreText,
  UICoreImage,
  UICoreButton
} from "../../../Component/UICore";
import Logo from "../../../Component/Logo/Logo";
import privatePageImage from "../../../asset/PrivatePage.png";
import Desktop from "../../../Component/Views/Desktop";
import Mobile from "../../../Component/Views/Mobile";
import { browserHistory } from "react-router";
import { domainName } from "../../../config";
type Props = {||};
type State = {||};
class PrivatePage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _toLoginPage = () => {
    window.location = `http://${domainName}/publishers/sign_in`;
  };

  render() {
    return (
      <UICoreBox
        justifyContent="center"
        alignItems="center"
        padding="sm"
        height="100vh"
        width="100vw"
        position="relative"
      >
        <UICoreBox
          position="absolute"
          width="100%"
          direction="row"
          alignItems="center"
          top="0px"
          left="0px"
          padding="sm"
        >
          <UICoreBox paddingRight="xm">
            <Logo />
          </UICoreBox>
          <UICoreText size="md" weight="bold">
            Wispform
          </UICoreText>
        </UICoreBox>

        <UICoreBox>
          <UICoreImage width="300px" src={privatePageImage} />
        </UICoreBox>

        <UICoreBox width="100%" alignItems="center">
          <UICoreText
            overflow="wrap"
            alignment="center"
            size="lg"
            weight="bold"
          >
            This Wispform is private
          </UICoreText>
          <Desktop>
            <UICoreBox width="60%" paddingTop="sm">
              <UICoreText
                alignment="center"
                size="md"
                color="Grey"
                overflow="wrap"
              >
                Please contact form creator regarding this issue. If you are the
                form creator, you can either upgrade to wispform Pro or you can 
                remove the pro features in your form builder.
              </UICoreText>
            </UICoreBox>
          </Desktop>
          <Mobile>
            <UICoreBox width="80%" paddingTop="sm">
              <UICoreText
                alignment="center"
                size="md"
                color="Grey"
                overflow="wrap"
              >
                Please contact form creator regarding this issue. If you are the
                form creator, you can either upgrade to wispform Pro or you can 
                remove the pro features in your form builder.
              </UICoreText>
            </UICoreBox>
          </Mobile>
          <UICoreBox marginTop="md">
            <UICoreButton onClick={this._toLoginPage} size="md">
              Log in
            </UICoreButton>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default PrivatePage;
