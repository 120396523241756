//@flow
import * as React from "react";
import {
  UICoreBox,
  UICoreInput,
  UICoreTooltip
} from "../../../../../Component/UICore";
import { updateQuestionContent } from "../../../../FormBuilderPage/States/actions";
import { connect } from "react-redux";
import "./style.css";
import { isNumber } from "../../../../../Library/Util";

type longTextQuestionContentsType = {|
  maxWordLimit?: number
|};

type Props = {|
  questionIndex: number,
  contents?: longTextQuestionContentsType,
  updateQuestionContent: (number, longTextQuestionContentsType) => {}
|};
type State = {||};

class MaxWordLimit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleWordCountUpdate = (_: any, value: string) => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(this.props.questionIndex, {
        ...this.props.contents,
        maxWordLimit: parseInt(value)
      });
  };

  _getMaxWordCount = () => {
    if (this.props.contents && isNumber(this.props.contents.maxWordLimit)) {
      return this.props.contents.maxWordLimit;
    }
    return "";
  };

  _validateInput = (input: string) => {
    if (isNaN(input)) {
      return "This field needs to be a number";
    }
    if (parseInt(input) > 99999) {
      return "We do not support word limit over 99999";
    }
    return "";
  };

  render() {
    return (
      <UICoreBox>
        <UICoreInput
          validator={this._validateInput}
          clickToSelect={true}
          onChange={this._handleWordCountUpdate}
          type="numeric"
          width={80}
          size="xxs"
          placeholder="Unlimited"
          value={String(this._getMaxWordCount())}
        />
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (pageNumber, contents) => {
      dispatch(
        updateQuestionContent(pageNumber, contents, true /* auto save */)
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaxWordLimit);
