// @flow

export function getImageFileDimension(
  file: File,
  cb: ({|
    width: number,
    height: number
  |}) => void
): void {
  if (!file || !String(file.type).includes("image")) {
    cb({
      width: 0,
      height: 0
    });
  }
  let fr = new FileReader();
  fr.onload = function () {
    // file is loaded
    var img = new Image();
    img.onload = function () {
      cb({
        width: img.width,
        height: img.height
      });
    };
    img.src = String(fr.result); // is the data URL because called with readAsDataURL
  };

  fr.readAsDataURL(file);
}
