/* external dependencies*/
import React, { Component } from "react";
import { connect } from "react-redux";
/* internal dependencies*/
import MultiChoicesCreator from "../../Components/MultiChoicesCreator";
import {
  edit_choice,
  add_choice,
  remove_choice,
  saveToBackend
} from "../../States/actions";
import "./style.css";
import PropTypes from "prop-types";
import Tooltip from "../../../../Component/Tooltip";
import ReactDOM from "react-dom";
import { UICoreText, UICoreBox } from "../../../../Component/UICore";

class MultiChoicesCreatorGroup extends Component {
  constructor(props) {
    super(props);
  }

  renderChoiceEditor() {
    const {
      question,
      remove_choice,
      add_choice,
      edit_choice,
      last_edited_question,
      saveToBackend
    } = this.props;

    return question.contents.map((choice, index) => (
      <MultiChoicesCreator
        remove_choice={remove_choice}
        add_choice={add_choice}
        index={index}
        value={choice}
        edit_choice={edit_choice}
        last_edited_question={last_edited_question}
        saveToBackend={saveToBackend}
      />
    ));
  }

  render() {
    return (
      <div className="MultiChoicesCreatorGroup">
        <UICoreBox marginBottom="xm">
          <UICoreText size="xs">Choices</UICoreText>
        </UICoreBox>
        <div className="MultiChoicesCreatorGroup-Editor">
          {this.renderChoiceEditor()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    last_edited_question: state.Question.last_edited_question
  };
}

function mapDispatchToProps(dispatch) {
  return {
    edit_choice: (choice_index, choice_content, pageNumber) => {
      dispatch(edit_choice(choice_index, choice_content, pageNumber));
    },
    add_choice: pageNumber => dispatch(add_choice(pageNumber)),
    remove_choice: (pageNumber, choice_index) => {
      dispatch(remove_choice(pageNumber, choice_index));
    },
    saveToBackend: () => dispatch(saveToBackend())
  };
}

MultiChoicesCreatorGroup.propTypes = {
  question: PropTypes.object.isRequired,
  remove_choice: PropTypes.func.isRequired,
  add_choice: PropTypes.func.isRequired,
  edit_choice: PropTypes.func.isRequired,
  last_edited_question: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultiChoicesCreatorGroup);
