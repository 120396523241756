// @flow
import * as React from "react";
import UICoreBox from "../UICoreBox";
import { UICoreIcon } from "..";
import UICoreText from "../UICoreText";
import { UICoreIconTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreIconType";
import { SizeTypeEnum } from "../../../FlowTypes/sizeTypes";
import "./style.css";

export type UICoreCheckboxLabelType = {|
  text: string,
  extra?: any
|};

type Props = {|
  labels: Array<UICoreCheckboxLabelType>,
  selectedLabelLocator?: UICoreCheckboxLabelType => boolean,
  onSelect: (number, UICoreCheckboxLabelType, boolean) => void
|};
type State = {||};

class UICoreCheckbox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <UICoreBox>
        {this.props.labels.map((label, index) => {
          const isSelected =
            this.props.selectedLabelLocator &&
            this.props.selectedLabelLocator(label);
          return (
            <UICoreBox
              paddingBottom="sm"
              direction="row"
              name="row"
              alignItems="center"
              className="UICoreCheckbox-Row"
              onClick={_ => this.props.onSelect(index, label, !isSelected)}
            >
              <UICoreBox
                width="20px"
                height="20px"
                justifyContent="center"
                alignItems="center"
                shape="rounded"
                hoverable={true}
                color="lightGrey"
              >
                {isSelected ? (
                  <UICoreIcon
                    size={SizeTypeEnum.xm}
                    name={UICoreIconTypeEnum.checkMark}
                  />
                ) : null}
              </UICoreBox>
              <UICoreBox paddingLeft="xm">
                <UICoreText color={isSelected ? "black" : "Grey"}>
                  {label.text}
                </UICoreText>
              </UICoreBox>
            </UICoreBox>
          );
        })}
      </UICoreBox>
    );
  }
}

export default UICoreCheckbox;
