//@flow
import * as React from "react";
import {
  UICoreText,
  UICoreBox,
  UICoreButton,
  UICoreImage
} from "../../Component/UICore";
import errorImage from "../../asset/error.png";
import Desktop from "../../Component/Views/Desktop";
import Mobile from "../../Component/Views/Mobile";
import { isIE } from "../../Library/Util";
type Props = {|
  description?: string,
  button?: string,
  disableButton?: boolean,
  onButtonClick?: () => void
|};
type State = {||};
class DefaultErrorPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _refreshPage = () => {
    window.location.reload();
  };

  _getDefaultDescription = () => {
    return `An error occurred, and our engineering team is notified of this error. ${
      isIE()
        ? " It seems like you are using IE, please use chrome, safari, edge or firefox instead."
        : ""
    }`;
  };

  _handleButtonClick = () => {
    if (this.props.onButtonClick) {
      this.props.onButtonClick();
    } else {
      this._refreshPage();
    }
  };

  render() {
    return (
      <UICoreBox
        width="100vw"
        height="100vh"
        alignItems="center"
        justifyContent="center"
        padding="sm"
      >
        <Desktop>
          <UICoreImage width="40vw" src={errorImage} />
        </Desktop>
        <Mobile>
          <UICoreImage width="70vw" src={errorImage} />
        </Mobile>

        <UICoreText alignment="center" size="xl" color="darkGray" weight="bold">
          Uh oh!
        </UICoreText>
        <UICoreText overflow="wrap" alignment="center" size="lg" color="Grey">
          {this.props.description || this._getDefaultDescription()}
        </UICoreText>
        <UICoreBox marginTop="md">
          {!this.props.disableButton && (
            <UICoreButton onClick={this._handleButtonClick} size="lg">
              {this.props.button || "Try again"}
            </UICoreButton>
          )}
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default DefaultErrorPage;
