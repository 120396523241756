//@flow
import TextComponent from "../../Components/CellComponents/TextComponent";
import LongTextDataUI from "../../Components/DataUIComponents/LongTextDataUI";
import { type DataType, type textCellType } from "../index";

const LongTextType = {
  icon: "fa fa-font",
  cellComponent: TextComponent,
  dataUIComponent: LongTextDataUI,
  displayName: "Long Text",
  sortFunction: sorter,
  filter: null
};

function sorter(first: textCellType, second: textCellType) {
  if (first < second) {
    return 1;
  } else {
    return 0;
  }
}

export default LongTextType;
