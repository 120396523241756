// @flow

import { BUILDER_PATH } from "../../App";
import * as builderViewerContext from "../UserInfo";
import * as formViewerContext from "./formViewerContext";

export function isInBuilder() {
  return (
    window.location &&
    window.location.pathname &&
    window.location.pathname.includes(BUILDER_PATH)
  );
}

export function getFormUUID() {
  if (isInBuilder()) {
    return builderViewerContext.getFormUid();
  } else {
    return formViewerContext.getFormUid();
  }
}

export function getPublisherID() {
  if (isInBuilder()) {
    return builderViewerContext.getUserID();
  } else {
    return formViewerContext.getPublisherID();
  }
}
