//@flow strict-local
import React, { Component } from "react";
import ClickFlash from "../../Animations/ClickFlash";
import { UICoreBox, UICoreButton, UICoreText } from "../../UICore";
import {
  type formStyleType,
  type backgroundCssType
} from "../../../FlowTypes/wispformStyleTypes";
import withStyles from "../../../Helper_HOC/WithStyles";
import ReactDOM from "react-dom";
import "./style.css";

type Props = {|
  onClick: () => mixed,
  buttonPrimaryColor: string,
  buttonTextColor: string,
  styles: formStyleType
|};

class UI_SubmitMultiSelectFooter extends Component<Props> {
  getBackWardCompatableButtonColor() {
    if (this.props.styles.buttonColor) {
      return this.props.styles.buttonColor;
    } else {
      return "#FFFFFF";
    }
  }

  getBackWardCompatableButtonTextColor() {
    if (this.props.styles.buttonColor) {
      return this.props.buttonTextColor;
    } else {
      return "rgba(0,0,0,0.7)";
    }
  }

  render() {
    const formBody = document.getElementsByClassName("Form-Body")[0];

    return ReactDOM.createPortal(
      <div className="Form-nextbutton">
        <ClickFlash onClick={this.props.onClick}>
          <UICoreBox
            className="Form-nextbutton-button"
            hexColor={this.getBackWardCompatableButtonColor()}
          >
            <UICoreText
              hexColor={this.getBackWardCompatableButtonTextColor()}
              size="lg"
              className="Form-nextbutton-button"
            >
              OK
            </UICoreText>
          </UICoreBox>
        </ClickFlash>
      </div>,
      formBody
    );
  }
}

export default withStyles(UI_SubmitMultiSelectFooter);
