//@flow
import * as React from "react";
import CellPopup from "../../../CellPopup";
import {
  UICoreBox,
  UICoreText,
  UICoreSearchBox,
  UICoreIconButton,
  UICoreButton
} from "../../../../../../Component/UICore";
import Scrollable from "../../../../../../Component/Scrollable";
import RecordOverview from "../RecordOverview";
import {
  type tableSchemaColumnType,
  type linkerCellUnitType,
  type tableDataRowType,
  type rowIDType
} from "../../../../Configuration";
import "./style.css";
type Props = {|
  title: string,
  rows: Array<tableDataRowType>,
  columns: Array<tableSchemaColumnType>,
  hideSearch?: boolean,
  buttonText?: string,
  onRecordClick: linkerCellUnitType => void,
  onCellPopupDismiss: () => void,
  onDeleteRecordClick?: rowIDType => void,
  onButtonClick?: () => void
|};
type State = {||};
class RecordList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _renderCloseIcon = (linkedInTableRow: tableDataRowType) => {
    return (
      <UICoreBox
        position="absolute"
        top="0px"
        right="0px"
        paddingTop="xm"
        paddingBottom="xm"
        paddingRight="xm"
        paddingLeft="md"
      >
        <UICoreIconButton
          onClick={() =>
            this.props.onDeleteRecordClick &&
            this.props.onDeleteRecordClick(linkedInTableRow.id)
          }
          iconClassName="ion-close"
        />
      </UICoreBox>
    );
  };

  _renderRecordList = (): Array<?React.Node> => {
    return this.props.rows.map((linkedInTableRow: tableDataRowType) => {
      return (
        <UICoreBox position="relative" marginBottom="xm">
          <RecordOverview
            onClick={this.props.onRecordClick}
            columns={this.props.columns}
            row={linkedInTableRow}
          />
          {this.props.onDeleteRecordClick &&
            this._renderCloseIcon(linkedInTableRow)}
        </UICoreBox>
      );
    });
  };

  _renderSearch = () => {
    return (
      <UICoreBox marginTop="sm">
        <UICoreSearchBox
          value=""
          onChange={() => {
            return;
          }}
          placeholder="Search a record"
        />
      </UICoreBox>
    );
  };

  _renderButton = () => {
    if (this.props.buttonText && this.props.onButtonClick) {
      return (
        <UICoreBox alignItems="start" paddingTop="sm">
          <UICoreButton onClick={this.props.onButtonClick} color="blue">
            {this.props.buttonText}
          </UICoreButton>{" "}
        </UICoreBox>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <CellPopup width={400} onDismiss={this.props.onCellPopupDismiss}>
        <UICoreBox padding="sm">
          <UICoreBox>
            <UICoreText alignment="left" weight="bold">
              {this.props.title}
            </UICoreText>
          </UICoreBox>
          {!this.props.hideSearch && this._renderSearch()}
          {this._renderButton()}
          <UICoreBox height={250} marginTop="sm">
            <Scrollable>{this._renderRecordList()}</Scrollable>
          </UICoreBox>
        </UICoreBox>
      </CellPopup>
    );
  }
}
export default RecordList;
