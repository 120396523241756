import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import addPolyfill from "./Library/polyfill";
import "./CSS/vendors/font-awesome/css/font-awesome.css";
import "./CSS/vendors/ionicons/css/ionicons.css";
import "./CSS/vendors/bootstrap/css/bootstrap.min.css";
import "./index.css";
import initSentry from "./Library/SentryErrorLogging";

//to better support IE browser
addPolyfill();

// Register error logging
initSentry();

ReactDOM.render(<App />, document.getElementById("root"));
