//@flow
import * as React from "react";
import "./style.css";
import { UICoreText, UICoreBox } from "../../../../../../Component/UICore";
import { type colorType } from "../../../../../../Component/UICore/UICoreBox";
import UICoreIconButton from "../../../../../../Component/UICore/UICoreIconButton";
type Props = {|
  text: string,
  withDeleteButton?: boolean,
  onDelete?: () => void,
  deleteChoice?: string => void,
  color?: colorType
|};
type State = {||};

class ChoiceComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    if (this.props.withDeleteButton) {
      return (
        <UICoreBox
          direction="row"
          shape="rounded"
          paddingLeft="xm"
          paddingRight="xm"
          marginRight="xm"
          className="Wiaptable-ChoiceComponent"
          position="relative"
          color={this.props.color}
        >
          <UICoreText size="xs" color="darkGray">
            {this.props.text}
          </UICoreText>
          <UICoreBox
            justifyContent="center"
            alignItems="center"
            paddingLeft="xm"
          >
            <UICoreIconButton
              onClick={() =>
                this.props.deleteChoice &&
                this.props.deleteChoice(this.props.text)
              }
              iconClassName="ion-close"
            />
          </UICoreBox>
        </UICoreBox>
      );
    } else {
      return (
        <UICoreBox
          direction="row"
          shape="rounded"
          paddingLeft="xm"
          paddingRight="xm"
          marginRight="xm"
          className="Wiaptable-ChoiceComponent"
          color={this.props.color}
        >
          <UICoreText size="xs" color="darkGray">
            {this.props.text}
          </UICoreText>
        </UICoreBox>
      );
    }
  }
}
export default ChoiceComponent;
