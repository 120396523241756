import React, { Component } from 'react';
import {DropdownButton} from 'react-bootstrap';
import {MenuItem} from 'react-bootstrap';
import {domainName} from '../../../../config';
import './style.css';

class PublisherMenu extends Component {
    render() {
        return (
            <DropdownButton className={"PublisherMenu "+this.props.className} title={localStorage.getItem('name') || 'New User'}>
                <MenuItem eventKey="1" onClick={()=>{localStorage.clear();}}
                href={`https://${domainName}/sign_out`}>Log out</MenuItem>
            </DropdownButton>
        );
    }
}

export default PublisherMenu;
