import React, { Component } from 'react';
import './style.css';


/*
    label
    value
    num_data
*/
class VeritcalBar extends Component {
    getPercentage(){
        const percentage = (this.props.value * 100)/this.props.num_data;
        const percentage_str = percentage.toString();
        const pos_of_dot = percentage_str.indexOf('.');
        const rounded_percentage = pos_of_dot == -1?percentage_str:percentage_str.substr(0, pos_of_dot+2);
        return rounded_percentage+'%';
    }
    render() {
        return (
            <div className="VerticalBar">
               <div className="VerticalBar-Bar">
                <div className="VerticalBar-active"
                 style={{height:this.getPercentage()}}>
                </div>
                <div className="VerticalBar-percentage">{this.getPercentage()}</div>
               </div>
               <div className="VerticalBar-Label">{this.props.label}</div>
            </div>
        );
    }
}

export default VeritcalBar;