//@flow
import React, { Component } from "react";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import { applyFlashAnimation } from "../../Animations/Animations";
import TextareaAutosize from "react-autosize-textarea";
import { UICoreBox, UICoreText } from "../../UICore";
import { connect } from "react-redux";
import { updateQuestionContent } from "../../../Pages/FormBuilderPage/States/actions";
import "./style.css";

const kDefaultPositiveText = "Yes";
const kDefaultNegativeText = "No";

type UIYesNoQuestionContentType = {|
  positiveText?: string,
  negativeText?: string
|};
type Props = {|
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  styles: formStyleType,
  onQuestionComplete?: (?string) => mixed,
  onQuestionUpdate?: (?string) => mixed,
  updateQuestionContent?: (
    pageNumber: number,
    content: UIYesNoQuestionContentType,
    shouldPersist: boolean
  ) => void,
  pageNumber: number,
  isInBuilder: boolean,
  contents: UIYesNoQuestionContentType
|};

type State = {|
  selectedOption: ?optionType
|};

type optionType = "yes" | "no";
const option = {
  yes: "yes",
  no: "no"
};

class UI_YesNo extends Component<Props, State> {
  state: State = {
    selectedOption: null
  };

  _yesOptionNode = null;

  _noOptionNode = null;

  _handleOptionClick = (e: ?HTMLElement, option: optionType) => {
    //don't apply any animation or style if in the builder
    if (this.props.isInBuilder) {
      return;
    }
    //apply flash animation
    const animationTime = 400;
    applyFlashAnimation(e, animationTime);

    //setback to null if click on the same option
    //otherwise, set to new option
    let updatedOption: ?optionType = null;
    if (option !== this.state.selectedOption) {
      updatedOption = option;
    }

    //submit option
    this.setState(
      {
        selectedOption: updatedOption
      },
      () => {
        setTimeout(() => {
          if (this.state.selectedOption) {
            this.props.onQuestionComplete &&
              this.props.onQuestionComplete(this.state.selectedOption);
          } else {
            this.props.onQuestionUpdate &&
              this.props.onQuestionUpdate(this.state.selectedOption);
          }
        }, animationTime);
      }
    );
  };

  _onHoverCSSClass = () => {
    return this.props.isInBuilder ? "" : " UI_YesNo-InteractableOption ";
  };

  _updateContent = (content: UIYesNoQuestionContentType) => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(this.props.pageNumber, content, false);
  };

  _updatePositiveText = (positiveText: string) => {
    this._updateContent({
      ...this.props.contents,
      positiveText: positiveText
    });
  };

  _updateNegativeText = (negativeText: string) => {
    this._updateContent({
      ...this.props.contents,
      negativeText: negativeText
    });
  };

  _saveText = () => {
    this.props.updateQuestionContent &&
      this.props.updateQuestionContent(
        this.props.pageNumber,
        this.props.contents,
        true
      );
  };

  _getPositiveText = () => {
    if (this.props.contents && this.props.contents.positiveText !== undefined) {
      return this.props.contents.positiveText;
    } else {
      return kDefaultPositiveText;
    }
  };

  _getNegativeText = () => {
    if (this.props.contents && this.props.contents.negativeText !== undefined) {
      return this.props.contents.negativeText;
    } else {
      return kDefaultNegativeText;
    }
  };

  _renderChoiceTextInForm = (text: string) => {
    return (
      <UICoreBox paddingLeft="xm" paddingRight="xm" paddingBottom="sm">
        <UICoreText
          overflow="wrap"
          size="md"
          alignment="center"
          hexColor={this.props.styles.answer}
          fontFamily={this.props.styles && this.props.styles.fontFamily}
        >
          {text}
        </UICoreText>
      </UICoreBox>
    );
  };

  _renderChoiceTextEditor = (value: string, onInput) => {
    return (
      <UICoreBox paddingBottom="sm" paddingLeft="xm" paddingRight="xm">
        <TextareaAutosize
          style={{
            color: this.props.styles.answer,
            borderBottomColor: this.props.styles.answer,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
          className="UI_YesNo-textArea"
          value={value}
          onBlur={this._saveText}
          onInput={(e: SyntheticInputEvent<any>) => {
            onInput(e.target.value);
          }}
        />
      </UICoreBox>
    );
  };

  render() {
    const optionStyle = {
      backgroundColor: this.props.answerSecondaryColor,
      borderColor: this.props.styles.answer
    };
    const textStyle = {
      color: this.props.styles.answer,
      fontFamily: this.props.styles && this.props.styles.fontFamily
    };
    const selectedOptionStyle = {
      backgroundColor: this.props.answerPrimaryColor,
      borderColor: this.props.styles.answer,
      borderWidth: "2px"
    };
    return (
      <div className="UI_YesNo">
        <div
          ref={node => (this._yesOptionNode = node)}
          onClick={() =>
            this._handleOptionClick(this._yesOptionNode, option.yes)
          }
          onMouseOver={() => {
            !this.props.isInBuilder &&
              this._yesOptionNode &&
              (this._yesOptionNode.style.backgroundColor = this.props.answerPrimaryColor);
          }}
          onMouseLeave={() => {
            !this.props.isInBuilder &&
              this._yesOptionNode &&
              (this._yesOptionNode.style.backgroundColor =
                this.state.selectedOption === option.yes
                  ? this.props.answerPrimaryColor
                  : this.props.answerSecondaryColor);
          }}
          style={
            this.state.selectedOption === option.yes
              ? selectedOptionStyle
              : optionStyle
          }
          className={"UI_YesNo-Option " + this._onHoverCSSClass()}
        >
          <div className="UI_YesNo-Icon">
            <i style={textStyle} className="ion-checkmark" />
          </div>
          {this.props.isInBuilder
            ? this._renderChoiceTextEditor(
                this._getPositiveText(),
                this._updatePositiveText
              )
            : this._renderChoiceTextInForm(this._getPositiveText())}
        </div>
        <div
          ref={node => (this._noOptionNode = node)}
          onClick={() => this._handleOptionClick(this._noOptionNode, option.no)}
          onMouseOver={() => {
            !this.props.isInBuilder &&
              this._noOptionNode &&
              (this._noOptionNode.style.backgroundColor = this.props.answerPrimaryColor);
          }}
          onMouseLeave={() => {
            !this.props.isInBuilder &&
              this._noOptionNode &&
              (this._noOptionNode.style.backgroundColor =
                this.state.selectedOption === option.no
                  ? this.props.answerPrimaryColor
                  : this.props.answerSecondaryColor);
          }}
          style={
            this.state.selectedOption === option.no
              ? selectedOptionStyle
              : optionStyle
          }
          className={"UI_YesNo-Option " + this._onHoverCSSClass()}
        >
          <div className="UI_YesNo-Icon">
            <i style={textStyle} className="ion-close" />
          </div>
          {this.props.isInBuilder
            ? this._renderChoiceTextEditor(
                this._getNegativeText(),
                this._updateNegativeText
              )
            : this._renderChoiceTextInForm(this._getNegativeText())}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateQuestionContent: (
      pageNumber: number,
      content: UIYesNoQuestionContentType,
      shouldPersist: boolean
    ) => {
      dispatch(updateQuestionContent(pageNumber, content, shouldPersist));
    }
  };
};

export default WithStyles(connect(null, mapDispatchToProps)(UI_YesNo));
