// @flow

const whitelistedImage = {
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+56.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+51.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+52.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+54.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+57.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+53.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+55.png": true,
  "https://wispformapp.s3.us-east-2.amazonaws.com/template/Group+47.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-28%20at%20120828%20AM_1582877347218.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-28%20at%20120828%20AM_1582877334219.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-28%20at%20120805%20AM_1582877410025.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-28%20at%20120805%20AM_1582877400861.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-25%20at%20105805%20PM-min_1582700653748.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-25%20at%20105805%20PM-min_1582700649793.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-25%20at%20105428%20PM-min-min_1582700837586.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-25%20at%20105428%20PM-min-min_1582700826714.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-25%20at%20105733%20PM-min_1582700600189.png": true,
  "https://wispform-file.s3.us-east-2.amazonaws.com/Screen%20Shot%202020-02-25%20at%20105733%20PM-min_1582700596921.png": true
};

export function isWhitelistedBackgroundImage(imageURL: string) {
  return whitelistedImage[imageURL];
}
