//@flow
import * as React from "react";
import { UICoreBox, UICoreText, UICoreButton } from "../../../Component/UICore";
import "./style.css";
import { dashboardAddress } from "../../../config";
type Props = {||};
type State = {||};
class UpgradeSnackBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _toCheckoutPage = () => {
    window.open(`http://${dashboardAddress}/setting/checkout`, "_blank");
  };

  render() {
    return (
      <UICoreBox
        className="UpgradeSnackBar"
        top="0px"
        left="0px"
        position="fixed"
        width="100%"
        paddingTop="xm"
        paddingBottom="xm"
        paddingLeft="sm"
        paddingRight="sm"
        direction="row"
        justifyContent="between"
      >
        <UICoreBox justifyContent="center">
          <UICoreText overflow="wrap" size="md" color="white">
            This wispform is only visible to you because it includes premium
            features. Upgrade to make it public.
          </UICoreText>
        </UICoreBox>
        <UICoreBox>
          <UICoreButton onClick={this._toCheckoutPage} size="md" color="white">
            Upgrade
          </UICoreButton>
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default UpgradeSnackBar;
