import axios from 'axios';
import {serverAddress} from '../../../config';

export const SAVE_TO_BACK_END = 'SAVE_TO_BACK_END';
export const SUBSCRIBE = 'SUBSCRIBE';
export const PREVIEW = 'PREVIEW';
export const WILLSAVEPREVIEW = 'WILLSAVEPREVIEW';
export const DIDSAVEPREVIEW = 'DIDSAVEPREVIEW';
export const CLOSE_PREVIEW='CLOSE_PREVIEW';
export const DID_SAVE_TO_BACKEND = 'DID_SAVE_TO_BACKEND';
export const SHOW_WARNING = 'SHOW_WARNING';

export function subscribe(email){
    return function (dispatch){
        axios.post(serverAddress+'api/v1/subscribe', {
            email:email
        }
        ).then((response)=>{
            dispatch(didSaveToBackend(response.data.data));
        }).catch(function(error) {
            dispatch(showWarning());
        });
    }
}

export function didSaveToBackend() {
    return {
      type: DID_SAVE_TO_BACKEND,
    };
}

export function showWarning() {
    return {
      type: SHOW_WARNING,
    };
  }

export function showPreview(){
    return function(dispatch, getState) {
        let json_save_to_backend = {
          last_edited_question: getState().Question.last_edited_question,
          question_count: getState().Question.question_count,
          questions: getState().Question.questions,
          subscriber_email:localStorage.getItem('email'),
        };
        dispatch(willSavePreview());
        axios.post(serverAddress+'api/v1/fake_preview', json_save_to_backend,
          ).then((response)=>{
          dispatch(didSavePreview(response.data.data.url));
        }).catch(function(error) {
          dispatch(showWarning());
        });
    };
}

export function willSavePreview(){
    return {
        type:WILLSAVEPREVIEW
    }
}

export function didSavePreview(url){
    return {
        type:DIDSAVEPREVIEW,
        previewURL:url
    }
}

export function closePreview(){
    return {
        type:CLOSE_PREVIEW
    }
}