import React, { Component } from "react";
import { VelocityComponent, velocityHelpers } from "velocity-react";
import Animation_Flash from "./Animation_Flash";
import PropTypes from "prop-types";

/*
  Add the animation of flash when clicking
  need to provide:A
  1)onClick
  2)composedAnswer
*/

class ClickFlash extends Component {
  constructor(props) {
    super(props);
    this.state = {
      choiceClicked: false
    };
    this.wrappingComponent = null;
  }

  handleChoiceClicked() {
    this.setState(
      {
        choiceClicked: true
      },
      () => {
        setTimeout(() => {
          this.props.onClick && this.props.onClick();
          this.setState({
            choiceClicked: false
          });
        }, 350);
      }
    );
  }

  render() {
    return (
      <Animation_Flash showAnimation={this.state.choiceClicked}>
        <div onClick={this.handleChoiceClicked.bind(this)}>
          {this.props.children}
        </div>
      </Animation_Flash>
    );
  }
}

ClickFlash.propTypes = {
  index: PropTypes.string.isRequired,
  choiceContent: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default ClickFlash;
