//@flow
import * as React from "react";
import "./style.css";
import {
  type formCellType,
  type formCellStateType,
  type cellValueType,
  type DataComponentPropsType,
  type tableSchemaColumnType,
  type tableDataRowType,
  type tableIDType,
  type linkerConfigType,
  type rowIDType
} from "../../../Configuration";
import {
  UICoreBox,
  UICoreButton,
  UICoreText
} from "../../../../../Component/UICore";
import SendForm from "./SendForm";
import { isEmptyArray, toArray } from "../../../../../Library/Util";
import RecordList from "../LinkerComponent/RecordList";
import RecordModal from "../../RecordModal";
import withFormData, { type InjectedProps } from "./withFormData";
import { getWispformResponses as apiGetWispformResponses } from "../../../api";
type Props = {|
  ...DataComponentPropsType,
  ...InjectedProps,
  columns: Array<tableSchemaColumnType>,
  rows: Array<tableDataRowType>,
  updateCell: formCellType => mixed
|};
type State = {|
  UIStage: $Keys<typeof UIStages>,
  displayedModalRowID: ?rowIDType
|};

const UIStages = {
  empty: "empty",
  sendForm: "sendForm",
  responseList: "responseList",
  responseDetail: "responseDetail"
};

class FormComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      UIStage: UIStages.empty,
      displayedModalRowID: null
    };
  }

  _showSendFormPopup = () =>
    this.setState({
      UIStage: UIStages.sendForm
    });

  _showCell = () => this.setState({ UIStage: UIStages.empty });

  _handleSendFormButtonClick = () => this._showSendFormPopup();

  _numberOfResponses = (): number => {
    if (!this.props.value || !this.props.value.responses) {
      return 0;
    }
    const responses = toArray(this.props.value.responses);
    return responses.length;
  };

  _showResponseList = () =>
    this.setState({
      UIStage: UIStages.responseList
    });

  _handleViewResponsesClick = () => {
    if (!this.props.config || !this.props.config.formID) {
      return;
    }
    if (!this.props.value || !this.props.value.responses) {
      return;
    }
    this.props.getFormQuestionAndResponses(
      String(this.props.config.formID),
      //$FlowFixMe checked in if
      toArray(this.props.value.responses).map(
        response => response.linkingResponseID
      )
    );
    this._showResponseList();
  };

  _getRowByRowID = (rowID: rowIDType): ?tableDataRowType => {
    return this.props.rows.find(row => row.id === rowID);
  };

  _updateCellState = (state: formCellStateType) => {
    if (
      this.props.value &&
      typeof this.props.value === "object" &&
      !Array.isArray(this.props.value)
    ) {
      this.props.updateCell({
        ...this.props.value,
        status: state
      });
    }
  };

  _renderdResponseModal = () => {
    if (!this.props.config || !this.props.config.formID) {
      return null;
    }

    if (!this.state.displayedModalRowID) {
      return null;
    }
    const row: ?tableDataRowType = this._getRowByRowID(
      this.state.displayedModalRowID
    );
    if (!row) {
      return null;
    }
    return (
      <RecordModal
        onDismiss={this._showCell}
        row={row}
        columns={this.props.columns}
      />
    );
  };

  _handleResponseClick = (rowID: rowIDType) => {
    this.setState({
      UIStage: UIStages.responseDetail,
      displayedModalRowID: rowID
    });
  };

  render() {
    if (this.state.UIStage === UIStages.sendForm) {
      return (
        <SendForm
          updateCellState={this._updateCellState}
          columnID={this.props.columnID}
          rowId={this.props.rowId}
          config={this.props.config}
          showCell={this._showCell}
        />
      );
    } else if (this.state.UIStage === UIStages.responseList) {
      if (!this.props.value || !this.props.value.responses) {
        return null;
      }

      const rows = toArray(this.props.value && this.props.value.responses)
        .map(linkerCell => this._getRowByRowID(linkerCell.linkingResponseID))
        .filter(row => row != null);
      return (
        <RecordList
          title={`Received response(s)`}
          onCellPopupDismiss={this._showCell}
          //$FlowFixMe null & undefined is filtered out
          rows={rows}
          columns={this.props.columns}
          onRecordClick={linkerCell =>
            this._handleResponseClick(linkerCell.linkingRowID)
          }
          hideSearch={true}
          buttonText="Get a new response"
          onButtonClick={this._showSendFormPopup}
        />
      );
    } else if (this.state.UIStage === UIStages.responseDetail) {
      return this._renderdResponseModal();
    } else {
      if (this._numberOfResponses() > 0) {
        return (
          <UICoreBox
            width="100%"
            height="100%"
            justifyContent="center"
            padding="xm"
            alignItems="center"
          >
            <UICoreBox
              onClick={this._handleViewResponsesClick}
              paddingLeft="xm"
              paddingRight="xm"
              shape="rounded"
              color="lightGrey"
            >
              <UICoreText size="xs">
                {`${this._numberOfResponses()} response${
                  this._numberOfResponses() > 1 ? "s" : ""
                }`}
              </UICoreText>
            </UICoreBox>
          </UICoreBox>
        );
      } else {
        return (
          <UICoreBox
            className="FormComponent"
            width="100%"
            height="100%"
            justifyContent="center"
            paddingLeft="sm"
            paddingRight="sm"
          >
            <UICoreButton onClick={this._handleSendFormButtonClick} size="sm">
              Send form
            </UICoreButton>
          </UICoreBox>
        );
      }
    }
  }
}

export default withFormData(FormComponent);
