import React, { Component } from "react";
import MultiChoicesCreatorGroup from "../../Containers/MultiChoicesCreatorGroup";
import DropDownEditorContainer from "../../Containers/DropDownEditorContainer";
import "./style.css";
import PropTypes from "prop-types";
import {
  UICoreText,
  UICoreInput,
  UICoreBox,
  UICoreTextArea
} from "../../../../Component/UICore";
/* eslint-disable */
const QuestionBasics = ({
  question,
  onTitleChange,
  last_edited_question,
  onDescriptionChange
}) => {
  /* eslint-enable */

  const renderChoiceEditorGroup = () => {
    if (question && question.type == "MultiChoices") {
      return (
        <MultiChoicesCreatorGroup
          question={question}
          last_edited_question={last_edited_question}
        />
      );
    } else {
      return <div />;
    }
  };

  const renderDropDownEditor = () => {
    if (question && question.type == "DropDown") {
      return <DropDownEditorContainer />;
    } else {
      return <div />;
    }
  };

  return (
    <div className="QuestionBasics">
      <div className="QuestionBasics-QuestionDescription">
        <UICoreBox paddingBottom="xm">
          <UICoreText color="black" size="xs">
            Description
          </UICoreText>
        </UICoreBox>
        <UICoreTextArea
          size="xs"
          maxRows={10}
          minRows={5}
          width="100%"
          value={question ? question.description : ""}
          onChange={(_, value) =>
            onDescriptionChange(value, last_edited_question)
          }
        />
      </div>
      {/* {renderChoiceEditorGroup()} */}
      {renderDropDownEditor()}
      {/*
        <div className="QuestionBasics-QuestionImage">
          <p>Upload Image</p>
          <div className="QuestionBasics-QuestionImage-UploadImage">
            <i className="fa fa-plus fa-3x plusIcon" aria-hidden="true"></i>
          </div>
        </div>
        */}
    </div>
  );
};

QuestionBasics.propTypes = {
  // question object, contains index, type, title, description, choices.etc.
  question: PropTypes.object,
  // the question number that is current editing, starts from 0
  last_edited_question: PropTypes.number,
  onDescriptionChange: PropTypes.func,
  onTitleChange: PropTypes.func
};

export default QuestionBasics;
