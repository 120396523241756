import React, { Component } from "react";
import Logo from "../Logo/Logo";
import LogoWithName from "../Logo/LogoWithName";
import LogoutBurron from "../HeaderBar/LogoutButton";
import { connect } from "react-redux";
import { Link } from "react-router";
import Desktop from "../../Component/Views/Desktop";
import FormPreviewModal from "../FormPreviewModal";
import Links from "./Links";
import { saveToBackend } from "../../Pages/FormBuilderPage/States/actions";
import { logNewUserOnboarding } from "../../Library/Logger";
import "./style.css";
import "./style.mobile.css";
import { UICoreBox, UICoreText } from "../UICore";
import WithFormConfig from "../../Helper_HOC/WithFormConfig";
import { safeGet } from "../../Library/Util";

/*
    @props form_name {string}
    @props form_id {number}
    @props publisher_name {string}
    @props link_name {string}
*/

const isInBuilder = (pageName: string) => pageName === "formbuilder";

class GlobalNavBar extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    showFormPreviewModal: false
  };

  getFormName() {
    const form_names = JSON.parse(localStorage.getItem("form_names"));
    const form_name_in_local = form_names[this.props.form_id];
    if (form_name_in_local) {
      return form_name_in_local;
    }
    //newly created form
    else {
      return this.props.form_name;
    }
  }

  renderLogo() {
    const formDisplayName = safeGet(_ => this.props.formConfig.displayName);
    if (this.props.form_name) {
      return [
        <Logo />,
        <UICoreBox direction="row" paddingLeft="xm">
          {formDisplayName && (
            <UICoreText size="md" color="black" weight="bold">
              {`${formDisplayName} / `}
            </UICoreText>
          )}
          <UICoreBox maxWidth="150px" overflowX="hidden">
            <UICoreText disableNewLine={true} overflow="ellipsis" size="md">
              {this.getFormName(this.props.page_name)}
            </UICoreText>
          </UICoreBox>
        </UICoreBox>
      ];
    } else {
      return <LogoWithName />;
    }
  }

  _showFormPreviewModal = () => {
    this.props.saveToBackend();
    logNewUserOnboarding("previewTabClicked");
    setTimeout(() => {
      this.setState({
        showFormPreviewModal: true
      });
    }, 500);
  };

  _hideFormPreviewModal = () => {
    this.setState({
      showFormPreviewModal: false
    });
  };

  _renderPreviewButton = () => {
    return (
      <UICoreBox
        marginRight="md"
        className="GlobalNavBar-Preview"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <UICoreBox
          height="65%"
          shape="rounded"
          hexColor="#e4fbef"
          justifyContent="center"
          alignItems="center"
          width="100%"
          paddingLeft="sm"
          paddingRight="sm"
          direction="row"
          className="GlobalNavBar-Preview-CTA"
          onClick={this._showFormPreviewModal}
        >
          <i
            style={{ color: "#00B25D", fontSize: "20px" }}
            className="ion-eye"
          />
          <UICoreBox paddingLeft="xm">
            <UICoreText size="xs" hexColor="#00B25D">
              Preview
            </UICoreText>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  };

  render() {
    return [
      <header className="GlobalNavBar">
        <Link to="/workspace/0" className="GlobalNavBar-NameLogo">
          {this.renderLogo()}
        </Link>
        {isInBuilder(this.props.page_name) && this._renderPreviewButton()}
        <Desktop>
          <Links
            showFormPreviewModal={this._showFormPreviewModal}
            page_name={this.props.page_name}
            form_id={this.props.form_id}
          />
        </Desktop>
        <UICoreBox
          height="100%"
          alignItems="center"
          position="absolute"
          right="0px"
          direction="row"
        >
          {isInBuilder(this.props.page_name) && this._renderPreviewButton()}
          <div className="GlobalNavBar-User">
            <LogoutBurron publisher={localStorage.getItem("name")} />
          </div>
        </UICoreBox>
      </header>,
      this.state.showFormPreviewModal && (
        <FormPreviewModal onDismiss={this._hideFormPreviewModal} />
      )
    ];
  }
}

function mapStateToProps(state) {
  return {
    form_name: state.Question.form_name
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveToBackend: () => {
      dispatch(saveToBackend());
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithFormConfig(GlobalNavBar));
