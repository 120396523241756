//@flow
import * as React from "react";
import { buttonTextConfig as getButtonTextFromForm } from "../../Pages/Form/States/Answers/selectors";
import { getButtonText as getButtonTextFromBuilder } from "../../Pages/FormBuilderPage/States/selectors";
import { compose } from "redux";
import { connect } from "react-redux";
import { BUILDER_PATH } from "../../App";
import {
  type buttonConfigItemType,
  customizableButtonList,
  ButtonType
} from "./config";

type State = {||};

export type buttonConfigType = {
  [number]: string
};

export type InjectedProps = {|
  buttonTextConfig: buttonConfigType
|};

export const buttonTextConfigWithDefaultFilled = (
  savedButtonTextConfig: buttonConfigType
) => {
  return customizableButtonList.reduce(
    (accumulator, customizableButtonItem: buttonConfigItemType) => {
      const currentButtonType = customizableButtonItem.buttonType;
      return {
        ...accumulator,
        [currentButtonType]:
          savedButtonTextConfig[currentButtonType] == null
            ? customizableButtonItem.defaultText
            : savedButtonTextConfig[currentButtonType]
      };
    },
    {}
  );
};

function withConfigurableButtonTextHOC<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    render() {
      const isBuilder =
        window.location &&
        window.location.pathname &&
        window.location.pathname.includes(BUILDER_PATH);
      if (isBuilder) {
        return (
          <WrappedComponent
            {...this.props}
            buttonTextConfig={buttonTextConfigWithDefaultFilled(
              this.props.buttonTextFromBuilder
            )}
          />
        );
      } else {
        return (
          <WrappedComponent
            {...this.props}
            buttonTextConfig={buttonTextConfigWithDefaultFilled(
              this.props.buttonTextFromForm
            )}
          />
        );
      }
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    buttonTextFromForm: getButtonTextFromForm(state.Answers),
    buttonTextFromBuilder: getButtonTextFromBuilder(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export type buttonConfigItemTypeFromHOC = buttonConfigItemType;
export const ButtonTypeFromHOC = ButtonType;

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withConfigurableButtonTextHOC
);
