//@flow

import { operation } from "../../../Pages/WispformTypings";
import { FinalPageType, PreviewListSection } from "./FormConfigEnums";
import { type QuestionTypes } from "../../../Pages/QuestionTypes";
import { type TCreationModal } from "./Mixins/CreationModalMixin";

export interface IFormConfig {
  displayName: string;
  toolBars: Array<"view" | "setting" | "theme">;
  availableQuestionOperations: Array<$Keys<typeof operation>>;
  /**
   * Determins the section layout of left panel.
   */
  previewListSection: Array<$Keys<typeof PreviewListSection>>;
  finalPageType: $Keys<typeof FinalPageType>;
  // question types that are not supported by form
  notSupportedContentTypes: Array<QuestionTypes>;
  creationModalConfig: TCreationModal;
}
