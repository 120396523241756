//@flow

export const UICoreListMediaTypeEnum = {
  image: 0,
  icon: 1,
  checkBox: 2
};

export type UICoreListItemTypeEnumType = $Values<typeof UICoreListItemTypeEnum>;
export const UICoreListItemTypeEnum = {
  stringItem: 0,
  header: 1
};
export type UICoreListMediaSpecType = {|
  type: $Values<typeof UICoreListItemTypeEnum>,
  src: string | number | boolean
|};
export type UICoreListItemType = {|
  title: string,
  leftMediaSpec?: UICoreListMediaSpecType,
  rightMediaSpec?: UICoreListMediaSpecType,
  itemType?: UICoreListItemTypeEnumType,
  extra?: any,
  onClick?: (SyntheticEvent<HTMLDivElement>, ?any) => void
|};
