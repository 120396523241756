//@flow
import ContactComponent from "../../Components/CellComponents/ContactComponent";
import ContactDataUI from "../../Components/DataUIComponents/ContactDataUI";
import { type DataType, type textCellType } from "../index";

const ContactType = {
  icon: "fa fa-user",
  cellComponent: ContactComponent,
  dataUIComponent: ContactDataUI,
  displayName: "Contact",
  sortFunction: null,
  filter: null
};

export default ContactType;
