import React, { Component } from 'react';
import {VelocityComponent, velocityHelpers} from 'velocity-react';


class Animation_Flash extends Component {
    animationProps(){
        if (this.props.showAnimation){
            return {
                animation: "callout.flash"
            }
        }
        else{
            return {
                animation: {
                    opacity:1
                }
            }
        }
    } 

    render() {
        return (
            <VelocityComponent  duration={300} {...this.animationProps()}>
                {this.props.children}
            </VelocityComponent>
        );
    }
}

export default Animation_Flash;