//@flow
import { type apiTableReturnType, type apiTablesReturnType } from "../api";
export const mockTablesData: apiTablesReturnType = {
  tables: [
    {
      id: 12,
      name: "Students",
      uuid: "string",
      created_at: "string",
      updated_at: "string"
    },
    {
      id: 13,
      name: "Teachers",
      uuid: "string",
      created_at: "string",
      updated_at: "string"
    }
  ]
};
export const mockData: apiTableReturnType = {
  table_detail: {
    id: 1,
    primary_column_id: "cl_asd2ewdasdas",
    columns: [
      {
        id: "cl_asd2ewdasdas",
        name: "student name",
        type: "ShortText",
        config: {} //store column specific info. Eg, choices, linking table column.etc.
      },
      {
        id: "cl_asd4ewdasdas",
        name: "background",
        type: "LongText",
        config: {} //store column specific info. Eg, choices, linking table column.etc.
      },
      {
        id: "cl_assdswdasdas",
        name: "years",
        type: "MultiSelect",
        config: {
          choices: ["freshman", "sophomore", "junior", "senior", "old"]
        }
      },
      {
        id: "cl_asd5ewdasdas",
        name: "graduated",
        type: "YesNo",
        config: {} //store column specific info. Eg, choices, linking table column.etc.
      },
      {
        id: "cl_asd6ewdasdas",
        name: "satisfication",
        type: "Rating",
        config: {} //store column specific info. Eg, choices, linking table column.etc.
      },
      {
        id: "cl_asd7ewdasdas",
        name: "contact",
        type: "Email",
        config: {} //store column specific info. Eg, choices, linking table column.etc.
      },
      {
        id: "cl_asd8ewdasdas",
        name: "contact",
        type: "FileUpload",
        config: {} //store column specific info. Eg, choices, linking table column.etc.
      }
    ],
    views: [{}]
  },
  rows: [
    {
      id: 1,
      created_at: "12312312323",
      cellColumnIdMap: {
        cl_asd2ewdasdas: "Tao Xiang",
        cl_asd4ewdasdas:
          "UCLA students, first met in Feb 2018, interested in tech",
        cl_assdswdasdas: Array("senior"),
        cl_asd5ewdasdas: "no",
        cl_asd6ewdasdas: 1,
        cl_asd7ewdasdas: "asdasd@gmail.com",
        cl_asd8ewdasdas: {
          link:
            "https://wispform-file.s3.amazonaws.com/4-5%20-%20Ch%204_1540075088718.pdf",
          fileName: "tao Xiang resume",
          fileType: "application/pdf",
          fileSize: 2332
        }
      }
    },
    {
      id: 2,
      created_at: "1321313123",
      cellColumnIdMap: {
        cl_asd2ewdasdas: "Shaun Liu",
        cl_asd4ewdasdas:
          "UCLA students, first met in Feb 2018, interested in tech",
        cl_assdswdasdas: Array("old", "freshman", "senior"),
        cl_asd5ewdasdas: "yes",
        cl_asd6ewdasdas: 4,
        cl_asd7ewdasdas: "shaun965@hotmail.com",
        cl_asd8ewdasdas: {
          link:
            "https://wispform-file.s3.amazonaws.com/4-5%20-%20Ch%204_1540075088718.pdf",
          fileName: "tao Xiang resume",
          fileType: "application/pdf",
          fileSize: 2332
        }
      }
    }
  ],
  table_meta_datum: {
    detail: {
      id: 1,
      filterGroup: null,
      sortGroup: null,
      colorGroup: null,
      columnWidth: {}
    }
  }
};
