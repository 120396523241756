//@flow
import React, { Component } from "react";
import "./style.css";
import PropTypes from "prop-types";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { isMobile } from "../../Views/helper";
import TextSubmitButton from "./TextSubmitButton";
import { UICoreBox } from "../../UICore";
import { isTablet } from "../../../Library/Window";
import { type questionComponentTypes } from "../../../FlowTypes/questionComponentTypes";
import { isStringNumericOrNull, nonNullOrThrow } from "../../../Library/Util";
import {
  type autoCompleteType,
  autoCompleteEnum
} from "../../../FlowTypes/autoCompleteTypes";
/*
  props:
    className:CSS attach to className will be attach to the container div.
    index: the index of each choices, it should be upper case letter A B C D.etc.
    choiceContent: the content of each choice.
*/

const tabKeyCode = 9;

type inputType = $Keys<typeof kInputType>;

export const kInputType: { [inputType]: string } = {
  numeric: "numeric",
  email: "email",
  anyString: "anyString"
};

type Props = {|
  ...questionComponentTypes,
  isCurrentQuestion?: boolean,
  inputType?: inputType,
  autoComplete?: autoCompleteType
|};
type State = {|
  builderInputValue: string
|};

class ShortTextInput extends React.Component<Props, State> {
  constructor(props) {
    super(props);
  }

  state = {
    builderInputValue: ""
  };

  componentDidMount() {
    //form loaded, and is the first question
    if (this.props.isCurrentQuestion) {
      this._focusInput();
    }
  }

  componentWillUpdate(nextProps, nextState) {
    //just become the current question
    if (nextProps.isCurrentQuestion && !this.props.isCurrentQuestion) {
      this._focusInput();
    }
  }

  _inputNode: ?HTMLElement = null;

  _focusInput = () => {
    //need to wait for animationTime to prevent glitch
    const animationTime = 200;
    if (!isMobile() && !isTablet()) {
      setTimeout(() => {
        this._inputNode && this._inputNode.focus();
      }, animationTime);
    }
  };

  _blurInput = () => {
    this._inputNode && this._inputNode.blur();
  };

  getAnswer() {
    return this.props.answer && this.props.answer.answer;
  }

  onUserInput(value) {
    if (
      this.props.inputType === kInputType.numeric &&
      !isStringNumericOrNull(value)
    ) {
      return;
    }

    if (
      this.props.inputType === kInputType.email &&
      this.props.onQuestionUpdate
    ) {
      nonNullOrThrow(this.props.onQuestionUpdate)(String(value).trim());
    } else {
      this.props.onQuestionUpdate && this.props.onQuestionUpdate(value);
    }
  }

  _handleEnter = event => {
    const keyCode = event.keyCode;
    const hasShiftKeyPressed = event.shiftKey;
    const enterKeyCode = 13;
    if (keyCode === enterKeyCode && !hasShiftKeyPressed) {
      this._submitAnswer();
    }
  };

  _submitAnswer = () => {
    if (isMobile() && this._inputNode === document.activeElement) {
      //close keyboard first
      //otherwise, the scroll position will be fucked
      this._blurInput();
      setTimeout(() => {
        this.props.onQuestionComplete &&
          // $FlowFixMe
          this.props.onQuestionComplete(this.getAnswer());
      }, 200);
    } else {
      this.props.onQuestionComplete &&
        // $FlowFixMe
        this.props.onQuestionComplete(this.getAnswer());
      this._blurInput();
    }
  };

  renderSubmitButton() {
    if (this.props.isInBuilder) {
      return null;
    } else {
      return (
        <TextSubmitButton
          onClick={() => {
            this._submitAnswer();
          }}
        />
      );
    }
  }

  _renderIcon = () => {
    //show signifier keyboard icon when
    //(1) in builder and no value
    //(2) in form and no value
    if (
      (!this.props.isInBuilder && !this.getAnswer()) ||
      (this.props.isInBuilder && this.state.builderInputValue === "")
    ) {
      return (
        <div className="UI_ShortText-keyboard">
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-keyboard-o"
          />
        </div>
      );
    }
  };

  _handleKeyDown = event => {
    const keyCode = event.keyCode;
    if (keyCode === tabKeyCode) {
      event.preventDefault();
    }
  };

  _getInputType = () => {
    switch (this.props.inputType) {
      case kInputType.numeric:
        return "number";
      case kInputType.email:
      case kInputType.anyString:
        return "text";
      default:
        return "text";
    }
  };

  _getPattern = () => {
    switch (this.props.inputType) {
      case kInputType.numeric:
        return "\\d*";
      default:
        return "";
    }
  };

  render() {
    return (
      <div className="UI_ShortText">
        {this.props.isInBuilder ? (
          <input
            disabled
            value={this.state.builderInputValue}
            onChange={e => this.setState({ builderInputValue: e.target.value })}
            className="UI_ShortText-InputBox"
            style={{
              backgroundColor: this.props.answerSecondaryColor,
              color: this.props.styles.answer,
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
          />
        ) : (
          <input
            autoComplete={this.props.autoComplete || autoCompleteEnum.off}
            type={this._getInputType()}
            pattern={this._getPattern()}
            ref={e => (this._inputNode = e)}
            value={this.getAnswer()}
            onKeyUp={this._handleEnter}
            onKeyDown={this._handleKeyDown}
            onChange={e => this.onUserInput(e.target.value)}
            className="UI_ShortText-InputBox"
            style={{
              backgroundColor: this.props.answerSecondaryColor,
              color: this.props.styles.answer,
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
          />
        )}
        <UICoreBox width="100%" marginTop="sm">
          {this.renderSubmitButton()}
        </UICoreBox>
        {this._renderIcon()}
      </div>
    );
  }
}

export default WithStyles(ShortTextInput);
