// @flow

import { ColorTypeEnum } from "../FlowTypes/colorTypes";
import { isNonEmptyString, nonNullOrThrow } from "./Util";

export function getHexCode(color: ?$Values<typeof ColorTypeEnum>): string {
  switch (color) {
    case ColorTypeEnum.black:
      return "#434343";

    case ColorTypeEnum.lightGrey:
      return "#ECECEC";
    case ColorTypeEnum.grey:
      return "#A3A3A3";

    default:
      return "#434343";
  }
}

export function isValidHexCode(color: ?string): boolean {
  if (isNonEmptyString(color)) {
    return /^#[0-9A-F]{6}$/i.test(nonNullOrThrow(color));
  }
  return false;
}
