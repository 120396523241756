/* external dependencies*/
import React, { Component } from "react";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import { Router, Route, Link, browserHistory } from "react-router";
import thunkMiddleware from "redux-thunk";
import createLogger from "redux-logger";
/* internal dependencies*/
import logo from "./logo.svg";
import rootReducer from "./root_reducer";
import WithAsync from "./Helper_HOC/WithAsync";
import WithAuthentication from "./Helper_HOC/WithAuthentication";
import withAuthCode from "./Helper_HOC/WithAuthCode";
import FormApp from "./Component/FormApp";
import FormView from "./Pages/WispTable/Components/FormView";
import "velocity-animate";
import "velocity-animate/velocity.ui";
import WithErrorBoundary from "./Helper_HOC/WithErrorBoundary";
import errorImage from "./asset/error.png";
import AppWrapper from "./Component/AppWrapper";
import UpgradeModalMiddleware from "./Middlewares/UpgradeModalMiddleware";
import LogicJumpMiddleware from "./Middlewares/LogicJumpMiddleware";
import AddPaymentQuestionMiddleware from "./Middlewares/AddPaymentQuestionMiddleware";
import ScoreCalculationMiddleware from "./Middlewares/ScoreCalculationMiddleware";
import FormBuilderPage from "./Pages/FormBuilderPage";

const loggerMiddleware = createLogger();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const Store = createStore(
  rootReducer,
  /* preloadedState, */ composeEnhancers(
    applyMiddleware(
      ScoreCalculationMiddleware,
      LogicJumpMiddleware,
      UpgradeModalMiddleware,
      AddPaymentQuestionMiddleware,
      thunkMiddleware, // lets us dispatch() functions
      loggerMiddleware // neat middleware that logs actions
    )
  )
);

const AsyncWorkSpace = WithAsync(() =>
  import(/*webpackChunkName: "WorkSpace"*/ "./Pages/Workspace")
);
const AsyncSetting = WithAsync(() =>
  import(/*webpackChunkName: "WorkSpace"*/ "./Pages/Setting")
);
const AsyncCheckout = WithAsync(() =>
  import(
    /*webpackChunkName: "WorkSpace"*/ "./Pages/Setting/Container/CheckoutContainer"
  )
);
const AsyncAccount = WithAsync(() =>
  import(
    /*webpackChunkName: "WorkSpace"*/ "./Pages/Setting/Container/AccountContainer"
  )
);
const AsyncTeam = WithAsync(() =>
  import(/*webpackChunkName: "Team"*/ "./Pages/Setting/Container/TeamContainer")
);
const AsyncFormBuilder = WithAsync(() =>
  import(/*webpackChunkName: "FormBuilder"*/ "./Pages/FormBuilderPage")
);
const AsyncResultPage = WithAsync(() =>
  import(/*webpackChunkName: "ResultPage"*/ "./Pages/ResultPage")
);
const AsyncGridView = WithAsync(() =>
  import(
    /*webpackChunkName: "GridView"*/ "./Pages/ResultPage/Container/GridContainer"
  )
);
const AsyncOverView = WithAsync(() =>
  import(
    /*webpackChunkName: "OverView"*/ "./Pages/ResultPage/Container/OverviewContainer"
  )
);
const AsyncAnalyticsView = WithAsync(() =>
  import(
    /*webpackChunkName: "AnalyticsView"*/ "./Pages/ResultPage/Container/AnalyticsContainer"
  )
);

const AsyncUserInsightView = WithAsync(() =>
  import(
    /*webpackChunkName: "UserInsightView"*/ "./Pages/ResultPage/Container/UserInsightContainer"
  )
);
// const AsyncTryoutPage = WithAsync(() =>
//   import(/*webpackChunkName: "TryoutPage"*/ "./Pages/TryoutPage")
// );
const AsyncForm = WithAsync(
  () => import(/*webpackChunkName: "Form"*/ "./Pages/Form"),
  "formLoadingScreen" /* loading screen type */
);
const AsyncTest = WithAsync(() =>
  import(/*webpackChunkName: "Test"*/ "./Pages/Test")
);
const AsyncShare = WithAsync(() =>
  import(/*webpackChunkName: "Share"*/ "./Pages/Share")
);
const AsyncConfigure = WithAsync(() =>
  import(/*webpackChunkName: "Share"*/ "./Pages/Configure")
);
const AsyncWelcome = WithAsync(() =>
  import(
    /*webpackChunkName: "Welcome"*/ "./Pages/LandingPages/Page_V2/page_v2.js"
  )
);
const AsyncWispTable = WithAsync(() =>
  import(/*webpackChunkName: "WispTable"*/ "./Pages/WispTable")
);

const AsyncWispTableFormView = WithAsync(() =>
  import(
    /*webpackChunkName: "WispTableFormView"*/ "./Pages/WispTable/Components/FormView"
  )
);
const AsyncFormNotFound = WithAsync(() =>
  import(
    /*webpackChunkName: "FormNotFound"*/ "./Pages/Form/Components/FormNotFound"
  )
);

// routes
export const routeFormNotFoundPage = "/formNotFound";

export const BUILDER_PATH = "formbuilder";

class App extends Component {
  /*
  componentWillMount() {
    // document.getElementsByTagName("BODY")[0].requestFullscreen &&
    //   document.getElementsByTagName("BODY")[0].requestFullscreen();
  }
  */

  render() {
    return (
      <Provider store={Store}>
        <Router history={browserHistory}>
          <Route path="/" component={AppWrapper}>
            <Route
              path="/workspace"
              component={withAuthCode(WithAuthentication(AsyncWorkSpace))}
            />
            <Route
              path="/Workspace/:id"
              component={WithAuthentication(AsyncWorkSpace)}
            />
            <Route path="/Setting" component={WithAuthentication(AsyncSetting)}>
              <Route path="Account" component={AsyncAccount} />
              <Route path="Checkout" component={AsyncCheckout} />
              <Route
                path="Team"
                component={withAuthCode(WithAuthentication(AsyncTeam))}
              />
            </Route>
            <Route path="/Form" component={WithAuthentication(FormApp)}>
              <Route path="Share" component={AsyncShare} />
              <Route path="Configure" component={AsyncConfigure} />
              <Route path={BUILDER_PATH} component={FormBuilderPage} />
              <Route path="Result" component={AsyncResultPage}>
                <Route path="GridView" component={AsyncGridView} />
                <Route path="OverView" component={AsyncOverView} />
                <Route path="AnalyticsView" component={AsyncAnalyticsView} />
                <Route
                  path="UserInsightView"
                  component={AsyncUserInsightView}
                />
              </Route>
            </Route>
            {/* <Route path="/try" component={AsyncTryoutPage} /> */}
            <Route path="/tb/:tableID" component={AsyncWispTableFormView} />
            <Route
              path="/WispTable"
              component={WithAuthentication(AsyncWispTable)}
            />
            <Route path="/test" component={AsyncTest} />
            <Route path="/try" component={AsyncWelcome} />
            <Route path={routeFormNotFoundPage} component={AsyncFormNotFound} />
            <Route path="*" component={AsyncForm} />
          </Route>
        </Router>
      </Provider>
    );
  }
}

export default WithErrorBoundary(App);
