// @flow
import * as React from "react";
import { UICoreBox, UICoreFlyOut, UICoreTextEditable } from "../UICore";
import UICoreTagInput from "../UICore/UICoreTagInput";
import { type tagInputTagMapType } from "../../FlowTypes/UICoreTagInputTypes";
type Props = {|
  buttonText: string,
  buttonLink?: string,
  onButtonTextUpdate: (
    e: SyntheticInputEvent<HTMLInputElement>,
    value: string
  ) => void,
  onButtonTextBlur: () => void,
  onLinkUpdate: (value: string) => void,
  buttonPrimaryColor: string,
  buttonTextColor: string,
  buttonTextFontFamily: ?string,
  tagOptionGroups?: Array<{|
    header?: string,
    tags: tagInputTagMapType
  |}>
|};
type State = {|
  showLinkEditor: boolean
|};
class EditableButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showLinkEditor: false
    };
  }

  _buttonNode: ?HTMLElement;

  _closeLinkEditor = () => this.setState({ showLinkEditor: false });

  _showLinkEditor = () => this.setState({ showLinkEditor: true });

  _renderLinkEditor = () => {
    if (this.state.showLinkEditor) {
      return (
        <UICoreFlyOut
          onDismiss={this._closeLinkEditor}
          anchor={this._buttonNode}
          size="lg"
          showCaret={false}
          idealDirection="up"
          forceDirection={true}
        >
          <UICoreBox
            color="lightGrey"
            shape="rounded"
            padding="xm"
            hoverable={true}
            width="100%"
          >
            <UICoreTagInput.Editor
              textStyleProps={{
                children: null
              }}
              onInputUpdate={this.props.onLinkUpdate}
              input={String(this.props.buttonLink)}
              tagHexColor="#88C8FE"
              tagTextStyle={{
                color: "darkGray",
                children: null,
                size: "xs"
              }}
              dropdownDirection="up"
              tagOptionGroups={this.props.tagOptionGroups || []}
              placeHolder={
                String(this.props.buttonLink)
                  ? ""
                  : "Redirect URL e.g. https://www.google.com"
              }
            />
          </UICoreBox>
        </UICoreFlyOut>
      );
    } else {
      return null;
    }
  };

  render() {
    return [
      this._renderLinkEditor(),
      <UICoreBox
        paddingTop="xxm"
        paddingLeft="sm"
        paddingBottom="xxm"
        paddingRight="sm"
        shape="rounded"
        hexColor={this.props.buttonPrimaryColor}
        innerRef={node => {
          this._buttonNode = node;
        }}
      >
        <UICoreTextEditable
          alignment="center"
          overflow="ellipsis"
          maxWidth="100%"
          size="sm"
          onInput={this.props.onButtonTextUpdate}
          onBlur={this.props.onButtonTextBlur}
          autoSelect={true}
          onClick={this._showLinkEditor}
          hexColor={this.props.buttonTextColor}
          fontFamily={this.props.buttonTextFontFamily}
        >
          {this.props.buttonText}
        </UICoreTextEditable>
      </UICoreBox>
    ];
  }
}
export default EditableButton;
