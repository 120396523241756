//@flow

import { isNullOrUndefined } from "../../../../Library/Util";
import { type stripeValidationErrorType } from "../../../../FlowTypes/paymentType";

let getTokenCallBack: ?() => Promise<any> = null;
let hasValidToken: boolean = false;
let stripeValidationError: ?stripeValidationErrorType = null;

export async function getToken(): Promise<any> {
  if (getTokenCallBack) {
    let stripeToken = await getTokenCallBack();
    if (!isNullOrUndefined(stripeToken)) {
      hasValidToken = true;
    }
    return stripeToken;
  }
  return null;
}

export function hasValidStripeToken(): boolean {
  return hasValidToken;
}

export function saveGetTokenCallBack(cb: () => Promise<any>) {
  getTokenCallBack = cb;
}

export function setStripeValidationError(error: stripeValidationErrorType) {
  stripeValidationError = error;
}

export function getStripeValidationError(): ?stripeValidationErrorType {
  return stripeValidationError;
}
