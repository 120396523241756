// @flow

import React, { Component } from "react";
import { UICoreBox, UICoreList } from "../../../../../Component/UICore";
import { UICoreListMediaTypeEnum } from "../../../../../FlowTypes/UICoreTypes/UICoreListType";
import { UICoreIconTypeEnum } from "../../../../../FlowTypes/UICoreTypes/UICoreIconType";
import { connect } from "react-redux";
import {
  delete_question,
  delete_thankyou_page,
  copy_question,
  copy_thankyou_page
} from "../../../States/actions";
import { executeCallbackSafely } from "../../../../../Library/Util";
import { getAllQuestions } from "../../../States/selectors";
import formConfiguration, {
  type questionType,
  type QuestionTypes
} from "../../../../QuestionTypes";

type Props = {|
  questionIndex: number,
  isThankyouPage: boolean,
  questionType: QuestionTypes,
  allQuestions: Array<questionType>,
  deleteQuestion: number => void,
  deleteThankyouPage: number => void,
  copyQuestion: number => void,
  copyThankyouPage: number => void,
  closeDropdown: () => void
|};
type State = {||};

const QuestionActionTypeEnum = {
  copy: 0,
  delete: 1
};

// $FlowFixMe
const questionConfigs = new formConfiguration().types;

class QuestionListActionMenu extends Component<Props, State> {
  executeAndCloseDropdown = cb => {
    executeCallbackSafely(cb);
    this.props.closeDropdown();
  };

  _hasQuestionTypeCreated = questionType => {
    return this.props.allQuestions.some(
      question => question.type === this.props.questionType
    );
  };

  _getActions = () => {
    const questionConfig = questionConfigs[this.props.questionType];
    if (
      questionConfig.onlyOneQuestionPerForm &&
      this._hasQuestionTypeCreated()
    ) {
      return [
        {
          title: "delete",
          leftMediaSpec: {
            type: UICoreListMediaTypeEnum.icon,
            src: UICoreIconTypeEnum.trash
          },
          extra: QuestionActionTypeEnum.delete,
          onClick: (_, extra) => {
            this.executeAndCloseDropdown(() => {
              this.props.isThankyouPage
                ? this.props.deleteThankyouPage(this.props.questionIndex)
                : this.props.deleteQuestion(this.props.questionIndex);
            });
          }
        }
      ];
    } else {
      return [
        {
          title: "copy",
          leftMediaSpec: {
            type: UICoreListMediaTypeEnum.icon,
            src: UICoreIconTypeEnum.clone
          },
          extra: QuestionActionTypeEnum.copy,
          onClick: (_, extra) => {
            this.executeAndCloseDropdown(() => {
              this.props.isThankyouPage
                ? this.props.copyThankyouPage(this.props.questionIndex)
                : this.props.copyQuestion(this.props.questionIndex);
            });
          }
        },
        {
          title: "delete",
          leftMediaSpec: {
            type: UICoreListMediaTypeEnum.icon,
            src: UICoreIconTypeEnum.trash
          },
          extra: QuestionActionTypeEnum.delete,
          onClick: (_, extra) => {
            this.executeAndCloseDropdown(() => {
              this.props.isThankyouPage
                ? this.props.deleteThankyouPage(this.props.questionIndex)
                : this.props.deleteQuestion(this.props.questionIndex);
            });
          }
        }
      ];
    }
  };

  render() {
    return (
      <UICoreBox width="100%">
        <UICoreList items={this._getActions()} />
      </UICoreBox>
    );
  }
}

function mapStateToProps(state) {
  return {
    allQuestions: getAllQuestions(state.Question)
  };
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    deleteQuestion: questionIndex => {
      dispatch(delete_question(questionIndex));
    },
    deleteThankyouPage: thankyouPageIndex => {
      dispatch(delete_thankyou_page(thankyouPageIndex));
    },
    copyQuestion: questionIndex => {
      dispatch(copy_question(questionIndex));
    },
    copyThankyouPage: thankyouPageIndex => {
      dispatch(copy_thankyou_page(thankyouPageIndex));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionListActionMenu);
