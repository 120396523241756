import * as Sentry from "@sentry/browser";
import { getUserID, getUserName } from "./UserInfo";

export default function initSentry() {
  Sentry.init({
    dsn:
      "https://f6ee793a00f74ab19dc8bb882f2688c6@o380012.ingest.sentry.io/5205395"
  });

  //Set user info
  Sentry.configureScope(function(scope) {
    scope.setUser({ id: getUserID(), username: getUserName() });
  });
}
