//@flow

/*
    withCloseFormSetting enhences FormPageContainer

    It checks if a form uses pro feature and 
    if publisher of the form is a paid account.

    If not, don't display the form
*/
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  closeFormSetting,
  customCloseFormText
} from "../../Pages/Form/States/Answers/selectors";
import { toArray } from "../../Library/Util";
import { UICoreBox, UICoreText, UICoreImage } from "../../Component/UICore";
import ClosedPage from "./ClosedPage";

type State = {||};
type InjectedProps = {||};

function withCloseFormSetting<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    _isClosedForm = () => {
      if (this.props.closeFormSetting && this.props.closeFormSetting != "") {
        let d = new Date();
        let d2 = new Date(this.props.closeFormSetting);
        if (d2 < d) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    render() {
      if (this._isClosedForm() && !this.props.isInPreview) {
        return (
          <ClosedPage customClosedFormText={this.props.customCloseFormText} />
        );
      } else {
        return (
          <WrappedComponent
            {...this.props}
            isInPreview={this.props.isInPreview}
          />
        );
      }
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    closeFormSetting: closeFormSetting(state.Answers),
    customCloseFormText: customCloseFormText(state.Answers)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {};
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withCloseFormSetting
);
