//@flow
import ReactGA, { clientTrackerName, isCustomGAInitialized } from "../GA";
import { getCurrentDayTime } from "../Library/Util";
import { getFormUID } from "./index";

export function logClientCustomEvent(
  category: string,
  action: string,
  label: string
) {
  ReactGA.event(
    {
      action,
      category,
      label
    },
    [clientTrackerName]
  );
}

export function logQuestionSubmitClientCustomEvent(questionTitle: string) {
  if (isCustomGAInitialized()) {
    logClientCustomEvent(
      "wispformQuestionSubmitted",
      getFormUID(),
      questionTitle
    );
  }
}

export function logFormSubmitClientCustomEvent() {
  if (isCustomGAInitialized()) {
    logClientCustomEvent(
      "wispformSubmitted",
      getFormUID(),
      getCurrentDayTime()
    );
  }
}
