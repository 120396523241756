//@flow
import * as React from "react";
import WisptableConfig, {
  type tableSchemaColumnType,
  type tableDataRowType,
  type columnIDType,
  type linkerCellUnitType,
  type rowIDType
} from "../../../../Configuration";
import "./style.css";
import { UICoreBox, UICoreText } from "../../../../../../Component/UICore";
import { getRow as apiGetRow } from "../../../../api";
import { getRecordName } from "../helper";
import { toArray } from "../../../../../../Library/Util";
type Props = {|
  columns: Array<tableSchemaColumnType>,
  row: tableDataRowType,
  onClick: linkerCellUnitType => void
|};
type State = {||};
class RecordOverview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _handleRecordClick = () => {
    const rowID: rowIDType = this.props.row.id;
    const rowName: string = getRecordName(this.props.columns, this.props.row);
    this.props.onClick({
      linkingRowID: rowID,
      linkingRowDisplayName: rowName
    });
  };

  _renderDataUI = (column: tableSchemaColumnType) => {
    const type = column.type;
    if (!WisptableConfig.dataTypes[type]) return null;
    const UIComponent = WisptableConfig.dataTypes[type].cellComponent;
    if (!this.props.row.cellColumnIdMap) return null;
    const value = this.props.row.cellColumnIdMap[column.id];
    if (!value) return null;
    return (
      <UIComponent
        rowId={this.props.row.id}
        value={value}
        updateCell={() => {
          return null;
        }}
        config={column.config}
        columnID={column.id}
      />
    );
  };

  _renderDataPreview = (column: tableSchemaColumnType) => {
    return (
      <UICoreBox
        flex={1}
        direction="column"
        marginRight="xm"
        alignItems="start"
        overflowX="hidden"
        overflowY="hidden"
        position="relative"
      >
        <UICoreBox>
          <UICoreText size="xxs" color="Grey">
            {column.name}
          </UICoreText>
        </UICoreBox>
        <UICoreBox width="100%" alignItems="start" justifyContent="center">
          {this._renderDataUI(column)}
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderDatasPreview = (): Array<React.Node> => {
    return toArray(this.props.columns)
      .slice(0, 3)
      .map(column => this._renderDataPreview(column));
  };

  render() {
    return (
      <UICoreBox
        onClick={this._handleRecordClick}
        className="RecordOverview"
        padding="xm"
        shape="rounded"
      >
        <UICoreText alignment="left" weight="bold" size="xs">
          {getRecordName(this.props.columns, this.props.row)}
        </UICoreText>
        <UICoreBox
          overflowX="hidden"
          overflowY="hidden"
          direction="row"
          marginTop="xm"
        >
          {this._renderDatasPreview()}
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default RecordOverview;
