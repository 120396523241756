// @flow
import {
  type hiddenFieldVariablesType,
  type hiddenFieldVariableValueType
} from "../FlowTypes/hiddenFieldTypes";
import { getUrlVars } from ".";
import {
  toArray,
  safeGet,
  isNullOrUndefined,
  isEmptyArray,
  isNonEmptyString
} from "./Util";

const kDefaultValue = "xxxx";

export function getHiddenFieldQueestionIdValueMap(
  hiddenFields: hiddenFieldVariablesType
): { [string]: string } {
  const urlKeyValuePair = getUrlVars();
  return toArray(hiddenFields).reduce((prev, field, index) => {
    return {
      ...prev,
      [field.hiddenFieldQuestionID]: safeGet(_ =>
        window.decodeURIComponent(urlKeyValuePair[field.variableName])
      )
    };
  }, {});
}

export function getParamList(
  hiddenFeilds: ?hiddenFieldVariablesType,
  hiddenFieldValue: ?hiddenFieldVariableValueType
): string {
  if (
    isNullOrUndefined(hiddenFeilds) ||
    // $FlowFixMe
    isEmptyArray(hiddenFeilds)
  ) {
    return "";
  }
  try {
    const params = toArray(hiddenFeilds)
      .map((field, index) => {
        if (safeGet(_ => field.variableName)) {
          const value =
            hiddenFieldValue && hiddenFieldValue[field.variableName];
          return `${index > 0 ? "&" : ""}${
            field.variableName
          }=${window.encodeURIComponent(value || kDefaultValue)}`;
        } else {
          return "";
        }
      })
      .join("");
    return `?${params}`;
  } catch (error) {
    return "";
  }
}

export function getUrlWithHiddenParams(
  formUrl: string,
  hiddenFeilds: ?hiddenFieldVariablesType,
  hiddenFieldValue: ?hiddenFieldVariableValueType
): string {
  return formUrl + getParamList(hiddenFeilds, hiddenFieldValue);
}

export function getUrlWithHiddenParamsWithDefaultValue(
  formUrl: string,
  hiddenFeilds: ?hiddenFieldVariablesType,
  hiddenFieldValue: ?hiddenFieldVariableValueType
): string {
  const paramList = getParamList(hiddenFeilds, hiddenFieldValue);
  const sampleParamList = `?name=${kDefaultValue}`;
  if (isNonEmptyString(paramList)) {
    return formUrl + paramList;
  } else {
    return formUrl + sampleParamList;
  }
}
