//@flow
import * as React from "react";
import "./style.css";
import { UICoreText, UICoreBox } from "../../../../../Component/UICore";
import {
  type textCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import TextareaAutosize from "react-autosize-textarea";
import CellPopup from "../../CellPopup";
import Editor from "react-simple-code-editor";
import Prism from "prismjs";
import "./prism.css";

type Props = {|
  ...DataComponentPropsType,
  updateCell: textCellType => mixed
|};
type State = {|
  showEditor: boolean,
  cellValue: string
|};
class CodeBlockComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showEditor: false,
    //we make TextComponent own the value prop
    //so we can update the redux and make api call after blur
    //https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
    cellValue: String(this.props.value ? this.props.value : "")
  };

  _showEditor = () => {
    this.setState({
      showEditor: true,
      cellValue: String(this.props.value ? this.props.value : "")
    });
  };

  _handleTextAreaInput = (e: SyntheticInputEvent<>) => {
    const value = e.target.value;
    this.setState({
      cellValue: value
    });
  };

  _handleCodeEditorBlur = () => {
    this.props.updateCell(this.state.cellValue);
  };

  _closePopup = () => this.setState({ showEditor: false });

  _renderCellContent = () => {
    if (this.state.showEditor) {
      return (
        <CellPopup width="500px" onDismiss={this._closePopup}>
          <UICoreBox minHeight="250px" className="UI_CodeBlock" shape="rounded">
            <Editor
              autoFocus={true}
              onBlur={this._handleCodeEditorBlur}
              placeholder="Type your code here..."
              value={this.state.cellValue}
              onValueChange={value => this.setState({ cellValue: value })}
              highlight={code =>
                Prism.highlight(code, Prism.languages.javascript, "javascript")
              }
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 14
              }}
            />
          </UICoreBox>
        </CellPopup>
      );
    } else {
      return (
        <UICoreBox
          onClick={this._showEditor}
          alignItems="start"
          width="100%"
          height="100%"
          padding="xm"
          overflowX="hidden"
          overflowY="hidden"
        >
          <UICoreBox
            paddingLeft="xm"
            paddingRight="xm"
            shape="rounded"
            className="CodeBlockComponent"
          >
            <UICoreText
              className="CodeBlockComponent-code"
              alignment="left"
              size="xs"
              color="darkGray"
              overflow="ellipsis"
            >
              {String(
                this.props.value ? String(this.props.value).split("\n")[0] : ""
              )}
            </UICoreText>
          </UICoreBox>
        </UICoreBox>
      );
    }
  };

  render() {
    return (
      <UICoreBox
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this._renderCellContent()}
      </UICoreBox>
    );
  }
}
export default CodeBlockComponent;
