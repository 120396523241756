//@flow
import TextComponent from "../../Components/CellComponents/TextComponent";
import TextDataUI from "../../Components/DataUIComponents/TextDataUI";
import { type DataType, type textCellType } from "../index";

export type shortTextCellType = string;
const ShortTextType = {
  icon: "fa fa-font",
  cellComponent: TextComponent,
  dataUIComponent: TextDataUI,
  displayName: "Short Text",
  sortFunction: sorter,
  filter: null
};

function sorter(first: textCellType, second: textCellType) {
  if (first < second) {
    return 1;
  } else {
    return 0;
  }
}

export default ShortTextType;
