// @flow
import {
  type logicJumpType,
  type branchType,
  type userInputType,
  type dynamicPricingOutcomeType
} from "../FlowTypes/jumpLogicTypes";
import {
  toArray,
  isNullOrUndefined,
  toNumber,
  isNonEmptyString
} from "../Library/Util";
import { isConditionSatisfied } from "../Middlewares/LogicJumpMiddleware/helper";

function calculateOutcomeAmount(outcome: ?dynamicPricingOutcomeType): number {
  if (isNullOrUndefined(outcome)) return 0;
  if (outcome && outcome.operation === "add") {
    return toNumber(outcome.amount);
  } else if (outcome && outcome.operation === "subtract") {
    return -toNumber(outcome.amount);
  }
  return 0;
}

function positiveOrZero(amount: number): number {
  return amount >= 0 ? amount : 0;
}

function noMoreThanMax(amount: number): number {
  return amount > 10000 ? 10000 : amount;
}

function getPriceFromTargetNumericQuestion(
  questionID: string,
  answers: Array<any>
): number {
  const targetAnswer = toArray(answers).find(
    ans => ans.question_id === questionID
  );
  if (isNullOrUndefined(targetAnswer)) {
    return 0;
  } else if (targetAnswer && targetAnswer.type !== "Number") {
    // We only support number question type for dynamic price
    return 0;
  } else {
    return (
      // $FlowFixMe
      targetAnswer &&
      noMoreThanMax(positiveOrZero(toNumber(targetAnswer.answer)))
    );
  }
}

function getPriceFromInput(
  inputContent: ?string | ?userInputType,
  answers: Array<any>
) {
  // Pipe from a numeric question
  if (inputContent && inputContent.targetQuestionID) {
    return getPriceFromTargetNumericQuestion(
      String(inputContent.targetQuestionID),
      answers
    );
  } else {
    // static price
    return positiveOrZero(toNumber(String(inputContent)));
  }
}

export function calculatePrice(
  dynamicPriceLogic: ?logicJumpType,
  defaultAmount: ?string | userInputType,
  answers: Array<any>
): ?number {
  // First priority, check computed logic
  if (!isNullOrUndefined(dynamicPriceLogic)) {
    let hasMatchedAtLeasetOneBranch = false;
    let cumulativePrice: number = toArray(
      Object(dynamicPriceLogic).branches
    ).reduce((price: number, branch: branchType, index) => {
      if (isConditionSatisfied(branch, answers, {})) {
        hasMatchedAtLeasetOneBranch = true;
        return price + calculateOutcomeAmount(branch.outcome);
      } else {
        return price;
      }
    }, 0);

    if (hasMatchedAtLeasetOneBranch) {
      return positiveOrZero(cumulativePrice);
    }
  }

  return getPriceFromInput(defaultAmount, answers);
}
