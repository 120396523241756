// @flow
import { FormTypeEnum, type TFormType } from "../../FlowTypes/forms";
import ClassicFormConfig from "./FormConfigs/ClassicFormConfig";
import { IFormConfig } from "./FormConfigs/IFormConfig";
import OutcomeQuizConfig from "./FormConfigs/OutcomeQuizConfig";
import CalculatorFormConfig from "./FormConfigs/CalculatorFormConfig";

class Form {
  static config = null;
  static setFormConfig(type: TFormType) {
    this.config = this.getAnyFormConfig(type);
  }
  static getAnyFormConfig(type: TFormType) {
    switch (type) {
      case FormTypeEnum.Classic:
        return new ClassicFormConfig();
      case FormTypeEnum.OutcomeQuiz:
        return new OutcomeQuizConfig();
      case FormTypeEnum.Calculator:
        return new CalculatorFormConfig();
      default:
        return new ClassicFormConfig();
    }
  }
}

export default Form;
