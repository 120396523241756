//@flow

import { patchRequest } from "./Request";
import { isNullOrUndefined, isLocalStorageAvailable } from "./Util";
import { getFormUid } from "./ViewerContext/formViewerContext";
import { type questionType } from "../Pages/QuestionTypes";

let reportedQuestions: Set<string> = new Set();

export function hasQuestionReported(question: questionType): boolean {
  return reportedQuestions.has(question.question_id);
}

export function reportQuestionViewIfNecessary(
  question: questionType,
  viewed_questions: any
) {
  // skip if the question has been reported
  if (hasQuestionReported(question)) {
    return;
  }
  reportedQuestions.add(question.question_id);
  // post to server
  // TODO: update endpoint and params

  patchRequest(`api/v1/forms/${getFormUid()}/form_views/1`, {
    reportedQuestions: reportedQuestions,
    question_id: question.question_id,
    existing_form_view_uuid: isLocalStorageAvailable()
      ? localStorage.getItem(getFormUid())
      : null,
    viewed_questions: viewed_questions
  })
    .then(_ => {})
    .catch(_ => {});
}
