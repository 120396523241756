// @flow

export const FormTypeEnum = {
  Classic: "Classic",
  OutcomeQuiz: "OutcomeQuiz",
  Assesment: "Assesment",
  Calculator: "Calculator"
};

export type TFormType = $Keys<typeof FormTypeEnum>;
