//@flow

export const SHOW_ACCOUNT_UPGRADE_MODAL = "SHOW_ACCOUNT_UPGRADE_MODAL";
export const CLOSE_ACCOUNT_UPGRADE_MODAL = "CLOSE_ACCOUNT_UPGRADE_MODAL";

type showAccountUpgradeModalPayloadType = {|
  type: typeof SHOW_ACCOUNT_UPGRADE_MODAL,
  featureName: string,
  featureAction: () => void
|};
export function showAccountUpgradeModal(
  featureName: string,
  featureAction: () => void
) {
  return {
    type: SHOW_ACCOUNT_UPGRADE_MODAL,
    featureName: featureName,
    featureAction: featureAction
  };
}

type closeAccountUpgradeModalPayloadType = {|
  type: typeof CLOSE_ACCOUNT_UPGRADE_MODAL
|};
export function closeAccountUpgradeModal() {
  return {
    type: CLOSE_ACCOUNT_UPGRADE_MODAL
  };
}

export type actionType =
  | showAccountUpgradeModalPayloadType
  | closeAccountUpgradeModalPayloadType;
