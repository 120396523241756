import { publisherNameMatches } from "./formViewerCondition";

//@flow

export function shouldUseNormalWeightTitle() {
  return (
    publisherNameMatches("jaromit34") || publisherNameMatches("universitennet")
  );
}

export function enableRedirectParentWindowInIframe() {
  return true;
}
