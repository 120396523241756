//@flow
import * as React from "react";
import "./style.css";
import UICoreText from "../UICoreText";
import UICoreBox from "../UICoreBox";
type Props = {|
  text?: string,
  size?: "sm" | "xs"
|};
type State = {||};
class UICoreDivider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <div className="UICoreDivider">
        <div className="UICoreDivider-leftLine" />
        {this.props.text && (
          <UICoreBox paddingLeft="md" paddingRight="md">
            <UICoreText size={this.props.size} color="Grey">
              {this.props.text}
            </UICoreText>
          </UICoreBox>
        )}
        <div className="UICoreDivider-rightLine" />
      </div>
    );
  }
}
export default UICoreDivider;
