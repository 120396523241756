//@flow
import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { getRequest, getRequestWithoutAuth } from "../Library/Request";
import { getFormUUID, getPublisherID } from "../Library/ViewerContext";
import { convertArrayToDict } from "../Library/Primitives/Array";
import { type subscriptionPlanStripeType } from "../FlowTypes/paymentType";
import { toArray, safeGet, isNonEmptyArray } from "../Library/Util";
import { isInBuilder } from "../Library/ViewerContext";
import { getAvailableSubscriptionPlans } from "../Pages/Form/States/Answers/selectors";

type State = {|
  availablePlansFromStripe: { [string]: subscriptionPlanStripeType }
|};

export type InjectedProps = {|
  availablePlansFromStripe: { [string]: subscriptionPlanStripeType }
|};

function withStripeSubscriptionPlans<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    constructor(PassedProps) {
      super(PassedProps);
      this.state = {
        availablePlansFromStripe: {}
      };
    }

    componentDidMount() {
      if (isInBuilder()) {
        getRequest(this._stripePlanEndpoint(), {
          publisher_id: getPublisherID()
        }).then(data => {
          this.setState({
            availablePlansFromStripe: convertArrayToDict(
              toArray(safeGet(_ => data.data.data.plans)),
              plan => plan.plan_id
            )
          });
        });
      } else {
        // use get request without auth
        getRequestWithoutAuth(this._stripePlanEndpoint(), {
          publisher_id: getPublisherID(),
          plan_ids: isNonEmptyArray(this.props.availableSubscriptions)
            ? this.props.availableSubscriptions
            : null
        }).then(data => {
          this.setState({
            availablePlansFromStripe: convertArrayToDict(
              toArray(safeGet(_ => data.data.data.plans)),
              plan => plan.plan_id
            )
          });
        });
      }
    }

    _stripePlanEndpoint = () => {
      return `api/v1/forms/${getFormUUID()}/stripe_plans`;
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          availablePlansFromStripe={this.state.availablePlansFromStripe}
        />
      );
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    availableSubscriptions: getAvailableSubscriptionPlans(state.Answers)
  };
};

export default compose(
  connect(mapStateToProps, null),
  withStripeSubscriptionPlans
);
