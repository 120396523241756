//@flow

export type buttonConfigItemType = {|
  buttonType: number,
  buttonTitle: string,
  description: string,
  defaultText: string
|};

export const ButtonType = {
  submitButton: 0,
  submitButtonSubText: 1,
  prevButton: 2,
  nextButton: 3,
  progressText: 4,
  questionNumberText: 5,
  welcomePageTitleText: 6,
  multichoiceOther: 7,
  newlineInstructionText: 9,
  pressEnterText: 10,
  requiredValidation: 11,
  emailValidation: 12,
  fileSizeValidation: 13,
  dateValidation: 14,
  wordCountValidation: 15,
  dropdownDefaultText: 16
};

export const kDefaultTextForNewlineInstruction =
  "Shift + Enter to make a new line";

export const kDefaultTextForPressEnterToContinue = "press enter";

export const customizableButtonList: Array<buttonConfigItemType> = [
  {
    buttonType: ButtonType.submitButton,
    buttonTitle: "Submit Button",
    description:
      "The button to submit the form. It appears at the end of the form.",
    defaultText: "Submit"
  },
  {
    buttonType: ButtonType.submitButtonSubText,
    buttonTitle: "Submit Button Subtext",
    description: "The descriptive text in the submit button.",
    defaultText: "Never submit Passwords!"
  },
  {
    buttonType: ButtonType.prevButton,
    buttonTitle: "Previous Button",
    description:
      "The button to switch to previous question. It appears at the footer",
    defaultText: "Prev"
  },
  {
    buttonType: ButtonType.nextButton,
    buttonTitle: "Next Button",
    description:
      "The button to switch to next question. It appears at the footer",
    defaultText: "Next"
  },
  {
    buttonType: ButtonType.progressText,
    buttonTitle: "Progress",
    description:
      "The text indicating the percentage of questions completed: _ % completed.",
    defaultText: "Completed"
  },
  {
    buttonType: ButtonType.questionNumberText,
    buttonTitle: "Question Number",
    description:
      "The text in the form that identifies the question number of each question",
    defaultText: "Question"
  },
  {
    buttonType: ButtonType.multichoiceOther,
    buttonTitle: "Multichoice Other Option",
    description:
      'Placeholder text in multiple choice questions when you enable add "other" setting',
    defaultText: "other"
  },
  {
    buttonType: ButtonType.pressEnterText,
    buttonTitle: "Press Enter Prompt",
    description:
      "Text under input questions that prompts users to press the enter button to continue.",
    defaultText: kDefaultTextForPressEnterToContinue
  },
  {
    buttonType: ButtonType.newlineInstructionText,
    buttonTitle: "New Line Instruction for Long Text Question",
    description:
      "Text under the long text question to educate user to use SHIFT + ENTER to start a new line.",
    defaultText: kDefaultTextForNewlineInstruction
  },
  {
    buttonType: ButtonType.requiredValidation,
    buttonTitle: "Error when required question is empty",
    description:
      'Error message when a user tries to submit a form with no answer for a "required" question',
    defaultText: "Oops, you need to complete this question."
  },
  {
    buttonType: ButtonType.emailValidation,
    buttonTitle: "Error when email is incorrectly formatted",
    description:
      "Error message when a user tries enter an incorrectly formatted email for an email quesiton",
    defaultText: "Hm.. This doesn't look like a valid email"
  },
  {
    buttonType: ButtonType.fileSizeValidation,
    buttonTitle: "Error when file size exceeds the maximum",
    description:
      "Error message when a user tries to upload a file greater than the maximum",
    defaultText: "File size should be less than 5MB"
  },
  {
    buttonType: ButtonType.dateValidation,
    buttonTitle: "Error when date field is incorrectly formatted",
    description:
      "Error message when a user tries enter an incorrectly formatted date for a date quesiton",
    defaultText: "Hm.. This doesn't look like a valid date"
  },
  {
    buttonType: ButtonType.wordCountValidation,
    buttonTitle: "Error when word count exceeds the maximum",
    description:
      "If you set a maximum word count for your long answer question, this error message is shown if user exceeds that amount",
    defaultText: "Hm.. You have exceeded the word limit for this question"
  },
  {
    buttonType: ButtonType.dropdownDefaultText,
    buttonTitle: "Dropdown selector default text",
    description:
      "The default text displayed in the dropdown question selector UI",
    defaultText: "select an option"
  }
];
