import React, { Component } from "react";
import { connect } from "react-redux";

// import {submit_answer} from '../Action/answer';
import UI_Choice from "../../../../Component/QuestionComponents/UI_Choice";
import UI_HeaderBar from "../../../../Component/QuestionComponents/UI_HeaderBar";
import UI_Description from "../../../../Component/QuestionComponents/UI_Description";
import UI_QuestionTitle from "../../../../Component/QuestionComponents/UI_QuestionTitle";
import { UICoreBox, UICoreImage } from "../../../../Component/UICore";
import QuestionTypes, { QuestionTypeEnum } from "../../../QuestionTypes";
import {
  saveToBackend,
  update_question_title,
  update_question_description,
  updateQuestionContent
} from "../../States/actions";
import "./style.css";
import PropTypes from "prop-types";
import Scrollable from "../../../../Component/Scrollable";
import { Scrollbars } from "react-custom-scrollbars";
import CompletePage from "../../../Form/Components/CompletePage";
import { logo, getThankyouPages } from "../../States/selectors";
import { isNonEmptyArray, isNonEmptyString } from "../../../../Library/Util";
import { getVisibleQuestionsWithAnswerPipeDecorator } from "../../States/selectors";
import QuestionMediaRender from "../../../../Component/QuestionMediaRender";
import withStyles from "../../../../Helper_HOC/WithStyles";
import OutcomePage from "../../../Form/Components/OutcomePage";

/*
  props:
    currentQuestionNumber
    nextQuestion:function
    previousQuestion:function
    questions:array of questions
      questions[n]:
        type
        choices
        title
        options
        index

*/

class ViewWindow extends Component {
  constructor(props) {
    super(props);
    this.QuestionTypes = new QuestionTypes().types;
  }

  renderQuestions(question) {
    const { questions, last_edited_question } = this.props;
    let UI_Component = this.QuestionTypes[question.type].formBuilderUIComponent;
    return (
      <UI_Component
        contents={
          questions[last_edited_question]
            ? questions[last_edited_question].contents
            : []
        }
        config={
          questions[last_edited_question]
            ? questions[last_edited_question].config
            : {}
        }
        pageNumber={this.props.last_edited_question}
        isInBuilder={true}
        updateQuestionContent={this.props.updateQuestionContent}
        styles={this.props.styles}
        answerPrimaryColor={this.props.answerPrimaryColor}
        answerSecondaryColor={this.props.answerSecondaryColor}
      />
    );
  }

  renderQuestionPreview(question) {
    const {
      last_edited_question,
      update_question_title,
      saveToBackend,
      update_question_description
    } = this.props;
    return (
      <UICoreBox
        position="relative"
        paddingTop="md"
        paddingBottom="md"
        height="100%"
      >
        {this.props.logo && (
          <UICoreBox paddingLeft="md" position="absolute">
            <UICoreImage src={this.props.logo} width={75} height={75} />
          </UICoreBox>
        )}
        <div className="Question-Wrapper">
          {/* MEDIA */}
          <UICoreBox width="70%" paddingBottom="sm">
            <QuestionMediaRender question={question} />
          </UICoreBox>

          {/* TITLE */}
          <UICoreBox width="70%" zIndex={3}>
            <UI_QuestionTitle
              isInBuilder={true}
              className="ViewWindow-QuestionTitle-Title"
              editable={true}
              last_edited_question={last_edited_question}
              update_question_title={update_question_title}
              titleContent={question.title}
              QuestionType={question.type}
              saveToBackend={saveToBackend}
              questionNumberBase0={this.props.last_edited_question}
            />
          </UICoreBox>

          {/* DESCRIPTION */}

          <UICoreBox paddingTop="sm" width="70%">
            <UI_Description
              last_edited_question={last_edited_question}
              editable={true}
              update_question_description={update_question_description}
              saveToBackend={saveToBackend}
              description={question.description}
              questionNumberBase0={this.props.last_edited_question}
            />
          </UICoreBox>

          {/* QUESTION BODY */}
          <UICoreBox
            dangerous_style={{ fontSize: "20px" }}
            paddingTop="md"
            width="70%"
            paddingBottom="lg"
          >
            {this.renderQuestions(question)}
          </UICoreBox>
        </div>
      </UICoreBox>
    );
  }

  renderViewWindowContent() {
    const { questions, last_edited_question } = this.props;
    if (
      questions &&
      questions[last_edited_question] &&
      questions[last_edited_question].type
    ) {
      return this.renderQuestionPreview(questions[last_edited_question]);
    }
  }

  render() {
    if (
      this.props.last_edited_question === "thankyouPage" &&
      isNonEmptyArray(this.props.thankyouPages)
    ) {
      return (
        <UICoreBox width="100%" height="100%" color="white">
          <CompletePage
            isInBuilder={true}
            thankyouPageConfig={
              this.props.thankyouPages[this.props.last_edited_thankyou_page]
            }
          />
        </UICoreBox>
      );
    } else if (
      this.props.last_edited_question === QuestionTypeEnum.OutcomePage
    ) {
      return (
        <UICoreBox width="100%" height="100%" color="white">
          <OutcomePage isEditMode={true} />
        </UICoreBox>
      );
    } else {
      return <Scrollbars>{this.renderViewWindowContent()}</Scrollbars>;
    }
  }
}

function mapStateToProps(state) {
  return {
    questions: getVisibleQuestionsWithAnswerPipeDecorator(state.Question),
    last_edited_question: state.Question.last_edited_question,
    last_edited_thankyou_page: state.Question.last_edited_thankyou_page,
    thankyouPages: getThankyouPages(state.Question),
    logo: logo(state.Question)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    saveToBackend: () => dispatch(saveToBackend()),
    /* eslint-disable */
    update_question_title: (title, pageNumber) =>
      dispatch(update_question_title(title, pageNumber)),
    update_question_description: (description, last_edited_question) =>
      dispatch(update_question_description(description, last_edited_question)),
    updateQuestionContent: (
      pageNumber: number,
      content: any,
      shouldPersist: boolean
    ) => {
      dispatch(updateQuestionContent(pageNumber, content, shouldPersist));
    }
    /* eslint-enable */
  };
}

ViewWindow.propTypes = {
  questions: PropTypes.array.isRequired,
  last_edited_question: PropTypes.number.isRequired,
  update_question_title: PropTypes.func.isRequired,
  update_question_description: PropTypes.func.isRequired,
  last_edited_question: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(ViewWindow));
