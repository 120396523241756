import React, { Component } from "react";
import Bar from "./Bar";
import "./style.css";

const TEXT_HEIGHT = 13;
const MAX_HEIGHT = "315px";

class HorizontalBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_read_all: false,
      show_collapse: false
    };
    this.num_data = null;
    this.chart_node = null;
  }

  componentDidMount() {
    if (!this.chart_node || !this.props.data) return;
    const question_num = this.props.data.data.length;
    if (question_num > 6) {
      this.chart_node.style.height = MAX_HEIGHT;
      this.setState({ show_read_all: true });
    }
  }

  getNumData(data) {
    return data.reduce((accu, data) => {
      return accu + data.value;
    }, 0);
  }

  renderBar() {
    return (
      this.props.data &&
      this.props.data.data.map(data => {
        return (
          <Bar
            label={data.label}
            value={data.value}
            num_data={this.num_data}
            type={this.props.type}
          />
        );
      })
    );
  }

  handleReadAllClick = () => {
    this.expandSection(this.chart_node);
    this.setState({
      show_read_all: false,
      show_collapse: true
    });
  };

  handleCollapseClick = () => {
    this.collapseSection(this.chart_node);
    this.setState({
      show_read_all: true,
      show_collapse: false
    });
  };

  collapseSection(element) {
    element.style.height = MAX_HEIGHT;
  }

  expandSection(element) {
    var sectionHeight = element.scrollHeight;
    element.style.height = sectionHeight + TEXT_HEIGHT + "px";
  }

  render() {
    if (this.props.data) {
      this.num_data = this.getNumData(this.props.data.data);
    }
    if (this.props.data.data) {
      return (
        <div
          ref={node => (this.chart_node = node)}
          className="HorizontalBarChart"
        >
          {this.renderBar()}
          {this.state.show_read_all && (
            <div className="HorizontalBarChart-ViewMore" />
          )}
          {this.state.show_read_all && (
            <div
              onClick={this.handleReadAllClick}
              className="HorizontalBarChart-ViewMore-Text"
            >
              Read all <i className="fa fa-angle-down" />
            </div>
          )}
          {this.state.show_collapse && (
            <div
              onClick={this.handleCollapseClick}
              className="HorizontalBarChart-collapse-Text"
            >
              Collapse responses <i className="fa fa-angle-up" />
            </div>
          )}
        </div>
      );
    } else {
      return <div>Loading</div>;
    }
  }
}

export default HorizontalBarChart;
