// @flow
import React, { Component } from "react";
import Logo from "../Logo/Logo";
import "./style.css";
import { UICoreText, UICoreBox, UICoreImage } from "../UICore";
import { getBrandLogoAwsUrl } from "../../Library/BrandSetting";
import * as FormViewerContext from "../../Library/ViewerContext/formViewerContext";
import { getFormUid } from "../../Library/UserInfo";

type loadingScreenStyle = "withLogo" | "formLoadingScreen";

const kLogoWidth = 80;

type Props = {|
  style?: loadingScreenStyle
|};
type State = {|
  showFallbackLogo: boolean
|};

class LoadingScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showFallbackLogo: false
    };
  }
  render() {
    if (this.props.style === "formLoadingScreen") {
      return (
        <div className="LoadingPage">
          {this.state.showFallbackLogo ? (
            <UICoreBox key={1}>
              <Logo width={kLogoWidth} height={kLogoWidth} />
            </UICoreBox>
          ) : (
            <UICoreBox key={2}>
              <UICoreImage
                width={kLogoWidth}
                height={kLogoWidth}
                onLoadingError={_ => this.setState({ showFallbackLogo: true })}
                src={getBrandLogoAwsUrl(
                  FormViewerContext.getPublisherName(),
                  FormViewerContext.getFormUid()
                )}
              />
            </UICoreBox>
          )}
          <div className="sp sp-3balls" />
        </div>
      );
    }
    return (
      <div className="LoadingPage">
        <Logo width={kLogoWidth} height={kLogoWidth} />
        <div className="sp sp-3balls" />
      </div>
    );
  }
}

export default LoadingScreen;
