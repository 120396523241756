// @flow
import * as React from "react";
import "./style.css";
import { UICoreBox, UICoreText, UICoreTextEditable } from "../UICore";
import {
  type formStyleType,
  type backgroundCssType
} from "../../FlowTypes/wispformStyleTypes";
import withStyles from "../../Helper_HOC/WithStyles";
import {
  isNonEmptyString,
  isNonNull,
  nonNullOrThrow,
  toUnNullable,
  isNull,
  isUndefined
} from "../../Library/Util";
import {
  type TOutcomeCTAData,
  type TOutcomeData,
  GENERIC_FORM_CONTENT_SECTION_TYPE,
  type TGenericContentConfig,
  type TGenericFormComponentPro
} from "./type";
import GFC_Header from "./GFC_Header";
import GFC_Outcome from "./GFC_Outcome";
import { Scrollbars } from "react-custom-scrollbars";
import QuestionMediaRender from "../QuestionMediaRender";

type State = {|
  scrollContainer: ?HTMLElement
|};

class GenericFormContent extends React.Component<
  TGenericFormComponentPro,
  State
> {
  constructor(props: TGenericFormComponentPro) {
    super(props);
    this.state = {
      scrollContainer: null
    };
  }

  _isHorizontalLayout() {
    let layout =
      this.props.config.outcomeConfig && this.props.config.outcomeConfig.layout;
    return isNull(layout) || isUndefined(layout) || layout === "horizontal";
  }

  _renderGFCOutcomes(element: React.Element<any>) {
    return (
      <UICoreBox
        className="gfc-component"
        width={
          this._isHorizontalLayout()
            ? this.props.isEditMode
              ? "80%"
              : "70%"
            : this.props.isEditMode
            ? "60%"
            : "40%"
        }
        maxWidth={this._isHorizontalLayout() ? undefined : "650px"}
        paddingBottom="md"
      >
        {element}
      </UICoreBox>
    );
  }

  _renderComponent(element: React.Element<any>) {
    return (
      <UICoreBox className="gfc-component" width="60%" alignItems="center">
        {element}
      </UICoreBox>
    );
  }

  _hasMedia = () => {
    return isNonNull(this.props.config.media);
  };

  render() {
    const config = this.props.config;
    return (
      <UICoreBox
        width="100%"
        height="100%"
        position="relative"
        justifyContent="center"
        dangerous_style={{
          ...this.props.backgroundCss
        }}
        innerRef={ref => {
          if (!this.state.scrollContainer) {
            this.setState({ scrollContainer: ref });
          }
        }}
      >
        <Scrollbars
          autoHeight
          autoHeightMax={
            this.state.scrollContainer &&
            this.state.scrollContainer.offsetHeight
          }
        >
          <UICoreBox verticalPadding="md" alignItems="center" width="100%">
            {config.supportedSections.includes(
              GENERIC_FORM_CONTENT_SECTION_TYPE.HEADER
            ) &&
              this._renderComponent(
                <UICoreBox paddingTop="lg" paddingBottom="sm">
                  <GFC_Header {...this.props} isSubheader={false} />
                </UICoreBox>
              )}
            {config.supportedSections.includes(
              GENERIC_FORM_CONTENT_SECTION_TYPE.SUB_HEADER
            ) &&
              this._renderComponent(
                <GFC_Header {...this.props} isSubheader={true} />
              )}
            {config.supportedSections.includes(
              GENERIC_FORM_CONTENT_SECTION_TYPE.MEDIA
            ) &&
              this._hasMedia() &&
              this._renderComponent(
                <UICoreBox
                  maxWidth="450px"
                  width="100%"
                  marginBottom="xm"
                  alignItems="center"
                  paddingTop="md"
                >
                  <QuestionMediaRender
                    maxHeight="300px"
                    questionMedia={this.props.config.media}
                  />
                </UICoreBox>
              )}
            {config.supportedSections.includes(
              GENERIC_FORM_CONTENT_SECTION_TYPE.OUTCOMES
            ) &&
              this._renderGFCOutcomes(
                <UICoreBox>
                  <GFC_Outcome {...this.props} />
                </UICoreBox>
              )}
          </UICoreBox>
        </Scrollbars>
      </UICoreBox>
    );
  }
}
export default withStyles(GenericFormContent);
