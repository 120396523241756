// @flow
import * as React from "react";
import {
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreCheckbox,
  UICoreText
} from "../../../../../Component/UICore";
import { type UICoreCheckboxLabelType } from "../../../../../Component/UICore/UICoreCheckbox";
import withStripeSubscriptionPlans from "../../../../../Helper_HOC/withStripeSubscriptionPlans";
import {
  type subscriptionPlanStripeType,
  type subscriptionQuestionContentsType
} from "../../../../../FlowTypes/paymentType";
import { mapDict } from "../../../../../Library/Primitives/Dict";
import {
  isNullOrUndefined,
  insertElementAt
} from "../../../../../Library/Util";
import { insertIfNonExist } from "../../../../../Library/Primitives/Array";
import { getSubscriptionName } from "../../../../../Library/Payment";
import { Scrollbars } from "react-custom-scrollbars";
type Props = {|
  questionContent: subscriptionQuestionContentsType,
  availablePlansFromStripe: { [string]: subscriptionPlanStripeType },
  updateQuestionContent: (content: subscriptionQuestionContentsType) => void
|};
type State = {|
  showEditor: boolean
|};
class AvailableSubscriptionPlans extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showEditor: false
    };
  }

  _handlePlanSelect = (
    index: number,
    label: UICoreCheckboxLabelType,
    changeToSelected: boolean
  ) => {
    if (isNullOrUndefined(this.props.questionContent)) {
      // Question content empty means user hasn't edited yet.
      // By default it shows all plans as available.
      // Remove the selected plan from the available list and mark everythig else as available
      const allPlans = mapDict(this.props.availablePlansFromStripe, plan => {
        return plan.plan_id;
      });
      this.props.updateQuestionContent({
        availablePlans: allPlans.filter(plan => plan.planID !== label.extra)
      });
    } else {
      let updatedAvailablePlans = [];
      if (changeToSelected) {
        updatedAvailablePlans = insertIfNonExist(
          this.props.questionContent.availablePlans,
          String(label.extra)
        );
      } else {
        updatedAvailablePlans = this.props.questionContent.availablePlans.filter(
          planID => planID !== label.extra
        );
      }
      this.props.updateQuestionContent({
        availablePlans: updatedAvailablePlans
      });
    }
  };

  render() {
    return [
      <UICoreBox>
        <UICoreButton
          onClick={() => {
            this.setState({
              showEditor: true
            });
          }}
          key={0}
          color="lightBlue"
          size="sm"
        >
          {"Edit"}
        </UICoreButton>
      </UICoreBox>,
      this.state.showEditor && (
        <UICoreModal
          header="Available Plans"
          onDismiss={() => {
            this.setState({
              showEditor: false
            });
          }}
          body={
            <UICoreBox padding="sm">
              <UICoreBox paddingBottom="sm">
                <UICoreText>
                  Toggle the plans that you'd like users to choose from.
                </UICoreText>
              </UICoreBox>
              <Scrollbars autoHeight autoHeightMax="250px">
                <UICoreCheckbox
                  labels={mapDict(this.props.availablePlansFromStripe, plan => {
                    return {
                      text: getSubscriptionName(plan),
                      extra: plan.plan_id
                    };
                  })}
                  selectedLabelLocator={label => {
                    if (
                      isNullOrUndefined(
                        this.props.questionContent &&
                          this.props.questionContent.availablePlans
                      )
                    ) {
                      return true;
                    }
                    return this.props.questionContent.availablePlans.some(
                      planID => planID === label.extra
                    );
                  }}
                  onSelect={this._handlePlanSelect}
                />
              </Scrollbars>
            </UICoreBox>
          }
        />
      )
    ];
  }
}
export default withStripeSubscriptionPlans(AvailableSubscriptionPlans);
