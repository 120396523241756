//@flow
import * as React from "react";
import "./style.css";
import {
  UICoreButton,
  UICoreModal,
  UICoreBox,
  UICoreText,
  UICoreDropdown,
  UICoreTooltip,
  UICoreInput
} from "../../../../../Component/UICore";
import {
  type choiceType,
  type dropdownFooterButtonType
} from "../../../../../Component/UICore/UICoreDropdown";
import { connect } from "react-redux";
import { getAllQuestions, hasAnyJumpLogic } from "../../../States/selectors";
import { type questionType } from "../../../../QuestionTypes";
import {
  type logicJumpType,
  type branchType,
  type conditionType,
  type jointLogicType,
  comperators,
  type branchOutcomeRenderPropTypes,
  type dynamicPricingOutcomeType,
  DEFAULT_END
} from "../../../../../FlowTypes/jumpLogicTypes";
import {
  toArray,
  isNonEmptyArray,
  stringReverse,
  isNullOrUndefined
} from "../../../../../Library/Util";
import { update_jump_logic, saveToBackend } from "../../../States/actions";
import { Scrollbars } from "react-custom-scrollbars";
import formConfiguration from "../../../../QuestionTypes";
import { showAccountUpgradeModal } from "../../../States/AccountUpgradeModal/actions";
import { isPro, isPremium, isBusiness } from "../../../../../Library/UserInfo";
import { logMonetizationEvent } from "../../../../../Library/Logger";
import {
  type comperatorNameTypes,
  type comperatorType,
  targetSelector
} from "../../../../../FlowTypes/jumpLogicComperatorTypes";
import Comperators from "../../../../../Middlewares/LogicJumpMiddleware/comperators";
import { type questionIDType } from "../../../../WispformTypings";
import {
  UICoreListItemTypeEnum,
  UICoreListMediaTypeEnum
} from "../../../../../FlowTypes/UICoreTypes/UICoreListType";
import { UICoreIconTypeEnum } from "../../../../../FlowTypes/UICoreTypes/UICoreIconType";
import {
  isTargetFormScore,
  getFormScoreTargetID
} from "../../../../../Library/JumpLogic";

//$FlowFixMe
const questionTypes = new formConfiguration().types;

const endOfForm = "End of form";

const arrayOffSet = 1;
type Props = {|
  jumpToTargets: Array<choiceType>,
  jumpToTargetsDropdownFooterButton?: dropdownFooterButtonType,
  textContent: {|
    modalTitle: string,
    addNewBranchButton: string,
    addAnotherBranchButton: string,
    branchTarget: string,
    fallbackTarget: string,
    branchTitlePrefix: string,
    toolTip: string
  |},
  addButtonHint: string,
  showUpdateButtonState: ?boolean,
  questions: Array<questionType>,
  questionID: questionIDType,
  jumpLogic: ?logicJumpType,
  hasAnyJumpLogic: boolean,
  defaultTargetQuestion: choiceType,
  updateJumpLogic: (questionIDType, logicJumpType) => void,
  saveToBackend: () => void,
  showAccountUpgradeModal: (string, () => void) => void,
  renderDefaultBlock?: () => React.Node,
  renderBranchOutcome?: branchOutcomeRenderPropTypes,
  branchOutcomeDefaultValue?: dynamicPricingOutcomeType
|};
type State = {|
  showJumpLogicModal: boolean
|};
class JumpLogic extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showJumpLogicModal: false
  };

  _showJumpLogicModal = () => {
    //first time using jumplogic for a free tier user
    if (
      !isPro() &&
      !isPremium() &&
      !isBusiness() &&
      !this.props.hasAnyJumpLogic
    ) {
      logMonetizationEvent("modalViewed-Add Jump Logic");
      this.props.showAccountUpgradeModal("Add Jump Logic", () => {
        this.setState({ showJumpLogicModal: true });
        logMonetizationEvent("tryClicked-Add Jump Logic");
      });
    } else {
      this.setState({ showJumpLogicModal: true });
    }
  };

  _closeJumpLogicModal = () => {
    this.setState({ showJumpLogicModal: false });
    this.props.saveToBackend();
  };

  _getDisplayTitle = (title: ?string, questionID: ?questionIDType): string => {
    return `${this._getQuestionIndexByID(questionID) + arrayOffSet}.${String(
      title
    )}`;
  };

  _getAllTargetQuestionsCandidate = () => {
    return this._filterOutUnSupportedQuestion(this.props.questions).map(
      question => {
        return {
          value: question.question_id,
          display: this._getDisplayTitle(question.title, question.question_id)
        };
      }
    );
  };

  _getQuestionByID = (questionID: questionIDType): ?questionType => {
    return toArray(this.props.questions).find(
      q => q.question_id === questionID
    );
  };

  _getQuestionIndexByID = (questionID: ?questionIDType): number => {
    return this.props.questions.findIndex(q => q.question_id === questionID);
  };

  _getSelectedDefaultQuestion = () => {
    if (
      this.props.jumpLogic &&
      !isNullOrUndefined(this.props.jumpLogic.default)
    ) {
      if (this.props.jumpLogic.default === DEFAULT_END) {
        return {
          display: endOfForm,
          value: DEFAULT_END
        };
      }
      return this.props.jumpToTargets.find(
        // $FlowFixMe
        target => target.value === this.props.jumpLogic.default
      );
    }
    //default to the next question
    return this.props.defaultTargetQuestion;
  };

  _handleDefaultChoiceClick = (value: questionIDType) => {
    this.props.updateJumpLogic(this.props.questionID, {
      ...this.props.jumpLogic,
      default: value
    });
  };

  _clearDefaultJumpTo = () => {
    this.props.updateJumpLogic(this.props.questionID, {
      ...this.props.jumpLogic,
      default: null
    });
  };

  _getQuestionByID = (questionID: questionIDType): ?questionType => {
    const question = toArray(this.props.questions).find(
      q => q.question_id === questionID
    );
    return question ? question : null;
  };

  _getQuestionTitle = (question: ?questionType) =>
    question ? question.title : null;

  _getQuestionChoice = (questionID: questionIDType) => {
    if (questionID === DEFAULT_END) {
      return {
        value: DEFAULT_END,
        display: endOfForm
      };
    } else if (isTargetFormScore(questionID)) {
      return {
        value: getFormScoreTargetID(),
        display: "Form Score"
      };
    }
    return questionID
      ? {
          value: questionID,
          display: this._getDisplayTitle(
            this._getQuestionTitle(this._getQuestionByID(questionID)),
            questionID
          )
        }
      : null;
  };

  _getTargetChoice = (questionID: questionIDType) => {
    if (questionID === DEFAULT_END) {
      return {
        value: DEFAULT_END,
        display: endOfForm
      };
    }
    return !isNullOrUndefined(questionID)
      ? this.props.jumpToTargets.find(target => target.value === questionID)
      : null;
  };

  _filterOutUnSupportedQuestion = (questions: Array<questionType>) => {
    return questions.filter(q => this._isJumpLogicSupportedQuestion(q));
  };

  _getBranches = () => {
    if (this.props.jumpLogic && this.props.jumpLogic.branches) {
      return this.props.jumpLogic.branches;
    } else {
      return [];
    }
  };

  _updateBranch = (index: number, branch: branchType) => {
    this.props.updateJumpLogic(this.props.questionID, {
      ...this.props.jumpLogic,
      branches: [
        ...this._getBranches().slice(0, index),
        branch,
        ...this._getBranches().slice(index + 1)
      ]
    });
  };

  _updateJumpToQuestion = (choice: questionIDType, index: number) => {
    this._updateBranch(index, {
      ...this._getBranches()[index],
      jumpToQuestionID: choice
    });
  };

  _getConditions = (branch: branchType) => {
    if (branch && branch.conditions) return branch.conditions;
    else return [];
  };

  _getJointLogics = (branch: branchType) => {
    if (branch && branch.jointLogics) return branch.jointLogics;
    else return [];
  };

  _updateCondition = (
    branchIndex: number,
    conditionIndex: number,
    newCondition: conditionType
  ) => {
    const currentBranch = this._getBranches()[branchIndex];
    this._updateBranch(branchIndex, {
      ...currentBranch,
      conditions: [
        ...this._getConditions(currentBranch).slice(0, conditionIndex),
        newCondition,
        ...this._getConditions(currentBranch).slice(conditionIndex + 1)
      ]
    });
  };

  _removeCondition = (branchIndex: number, conditionIndex: number) => {
    const currentBranch = this._getBranches()[branchIndex];
    //if there is no condition after the removal, delete the entire branch
    if (this._getConditions(currentBranch).length <= 1) {
      this._deleteBranch(branchIndex);
    } else {
      this._updateBranch(branchIndex, {
        ...currentBranch,
        conditions: [
          ...this._getConditions(currentBranch).slice(0, conditionIndex),
          ...this._getConditions(currentBranch).slice(conditionIndex + 1)
        ],
        jointLogics:
          conditionIndex === 0
            ? [
                ...this._getJointLogics(currentBranch).slice(0, conditionIndex),
                ...this._getJointLogics(currentBranch).slice(conditionIndex + 1)
              ]
            : [
                ...this._getJointLogics(currentBranch).slice(
                  0,
                  conditionIndex - 1
                ),
                ...this._getJointLogics(currentBranch).slice(conditionIndex)
              ]
      });
    }
  };

  _addCondition = (branchIndex: number, conditionIndex: number) => {
    const currentBranch = this._getBranches()[branchIndex];
    const targetQuestionID =
      this._getCurrentQuestionIdOrFirstJumpLogicSupportedQuestionId(
        this.props.questionID
      );
    if (!targetQuestionID) return;
    this._updateBranch(branchIndex, {
      ...currentBranch,
      conditions: [
        ...this._getConditions(currentBranch).slice(0, conditionIndex + 1),
        {
          targetQuestionID: targetQuestionID,
          comperator:
            this._getFirstComperator(targetQuestionID) &&
            this._getFirstComperator(targetQuestionID).typeName,
          targetAnswer: this._getFirstChoice(targetQuestionID)
        },
        ...this._getConditions(currentBranch).slice(conditionIndex + 1)
      ],
      jointLogics: [
        ...this._getJointLogics(currentBranch).slice(0, conditionIndex),
        "&&",
        ...this._getJointLogics(currentBranch).slice(conditionIndex)
      ]
    });
  };

  _updateConditionTargetQuestion = (
    branchIndex: number,
    conditionIndex: number,
    currentCondition: conditionType,
    selectedQuestionID: questionIDType
  ) => {
    this._updateCondition(branchIndex, conditionIndex, {
      ...currentCondition,
      targetQuestionID: selectedQuestionID,
      comperator:
        this._getFirstComperator(selectedQuestionID) &&
        this._getFirstComperator(selectedQuestionID).typeName,
      targetAnswer: this._getFirstChoice(selectedQuestionID)
    });
  };

  _updateConditionComperator = (
    branchIndex: number,
    conditionIndex: number,
    currentCondition: conditionType,
    comperatorName: comperatorNameTypes
  ) => {
    this._updateCondition(branchIndex, conditionIndex, {
      ...currentCondition,
      comperator: comperatorName
    });
  };

  _updateConditionTargetAnswer = (
    branchIndex: number,
    conditionIndex: number,
    currentCondition: conditionType,
    choice: number | string
  ) => {
    this._updateCondition(branchIndex, conditionIndex, {
      ...currentCondition,
      targetAnswer: choice
    });
  };

  _getComperatorChoices = (comperators: Array<comperatorType>) => {
    return comperators.map(comperator => {
      return { display: comperator.displayName, value: comperator.typeName };
    });
  };

  _getAnswerChoices = (questionID: questionIDType) => {
    const question = this._getQuestionByID(questionID);
    if (!question) return [];
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return [];
    return (
      questionConfig.getAnswerChoices &&
      questionConfig.getAnswerChoices(question)
    );
  };

  _updateJointLogic = (
    branchIndex: number,
    conditionIndex: number,
    jointLogic: jointLogicType
  ) => {
    const currentBranch = this._getBranches()[branchIndex];
    const currentJointLogics = this._getJointLogics(currentBranch);
    this._updateBranch(branchIndex, {
      ...currentBranch,
      jointLogics: [
        ...currentJointLogics.slice(0, conditionIndex),
        jointLogic,
        ...currentJointLogics.slice(conditionIndex + 1)
      ]
    });
  };

  _renderJointLogics = (
    jointLogic: jointLogicType,
    branchIndex: number,
    conditionIndex: number
  ) => {
    if (!jointLogic) return null;
    return (
      <UICoreBox marginTop="xm" direction="row">
        <UICoreButton
          onClick={() =>
            this._updateJointLogic(branchIndex, conditionIndex, "&&")
          }
          color={jointLogic === "&&" ? "lightBlue" : "grey"}
        >
          and
        </UICoreButton>
        <UICoreBox marginLeft="xm">
          <UICoreButton
            onClick={() =>
              this._updateJointLogic(branchIndex, conditionIndex, "||")
            }
            color={jointLogic === "||" ? "lightBlue" : "grey"}
          >
            or
          </UICoreButton>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderTargetAnswerSelector = (
    selectorName,
    condition,
    branchIndex,
    conditionIndex
  ) => {
    switch (selectorName) {
      case targetSelector.choices:
        return (
          <UICoreDropdown
            selectChoice={{
              value: condition.targetAnswer,
              display: condition.targetAnswer
            }}
            choices={this._getAnswerChoices(condition.targetQuestionID)}
            width="100%"
            dropdownWidth={248}
            onChoiceClick={choice => {
              this._updateConditionTargetAnswer(
                branchIndex,
                conditionIndex,
                condition,
                choice
              );
            }}
          />
        );
      case targetSelector.text:
        return (
          <UICoreInput
            size="xs"
            onChange={(_, value: string) => {
              this._updateConditionTargetAnswer(
                branchIndex,
                conditionIndex,
                condition,
                value
              );
            }}
            placeholder="type your target answer here..."
            value={condition.targetAnswer}
          />
        );
      case targetSelector.numeric:
        return (
          <UICoreInput
            size="xs"
            onChange={(_, value: string) => {
              this._updateConditionTargetAnswer(
                branchIndex,
                conditionIndex,
                condition,
                value
              );
            }}
            type="numeric"
            placeholder="type your target answer here..."
            value={condition.targetAnswer}
          />
        );
      default:
        return null;
    }
  };

  _renderConditions = (
    conditions: Array<conditionType>,
    joinLogics: Array<jointLogicType>,
    branchIndex: number
  ) => {
    return toArray(conditions).map((condition, conditionIndex) => {
      return (
        <UICoreBox>
          <UICoreBox name="Select Question" marginTop="xm" marginBottom="xm">
            <UICoreDropdown
              selectChoice={this._getQuestionChoice(condition.targetQuestionID)}
              choices={[
                {
                  display: "Questions",
                  value: "questions",
                  type: UICoreListItemTypeEnum.header
                },
                ...this._getAllTargetQuestionsCandidate(),
                {
                  display: "Variables",
                  value: "variables",
                  type: UICoreListItemTypeEnum.header
                },
                {
                  display: "Form Score",
                  value: getFormScoreTargetID(),
                  leftMediaSpec: {
                    type: UICoreListMediaTypeEnum.icon,
                    src: UICoreIconTypeEnum.calculator
                  },
                  type: UICoreListItemTypeEnum.stringItem
                }
              ]}
              width="100%"
              dropdownWidth={436}
              onChoiceClick={choice => {
                this._updateConditionTargetQuestion(
                  branchIndex,
                  conditionIndex,
                  condition,
                  choice
                );
              }}
            />
          </UICoreBox>
          <UICoreBox name="comperator + target answer" direction="row">
            <UICoreBox width="20%" marginRight="xm">
              <UICoreDropdown
                selectChoice={{
                  value: condition.comperator,
                  display: Comperators[condition.comperator].displayName
                }}
                choices={this._getComperatorChoices(
                  this._getQuestionComperatorsByQuestionID(
                    condition.targetQuestionID
                  )
                )}
                width="100%"
                onChoiceClick={choice => {
                  this._updateConditionComperator(
                    branchIndex,
                    conditionIndex,
                    condition,
                    choice
                  );
                }}
              />
            </UICoreBox>
            <UICoreBox width="57%">
              {this._renderTargetAnswerSelector(
                Comperators[condition.comperator] &&
                  Comperators[condition.comperator].targetSelector,
                condition,
                branchIndex,
                conditionIndex
              )}
            </UICoreBox>
            <UICoreBox marginLeft="xm">
              <UICoreButton
                onClick={() => this._addCondition(branchIndex, conditionIndex)}
                color="lightBlue"
              >
                +
              </UICoreButton>
            </UICoreBox>

            <UICoreBox marginLeft="xm">
              <UICoreButton
                onClick={() =>
                  this._removeCondition(branchIndex, conditionIndex)
                }
                color="lightBlue"
              >
                -
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
          {this._renderJointLogics(
            joinLogics[conditionIndex],
            branchIndex,
            conditionIndex
          )}
        </UICoreBox>
      );
    });
  };

  _deleteBranch = (branchIndex: number) => {
    this.props.updateJumpLogic(this.props.questionID, {
      ...this.props.jumpLogic,
      branches: toArray(
        this.props.jumpLogic && this.props.jumpLogic.branches
      ).filter((val, index) => index !== branchIndex)
    });
  };

  _getJumpToTargetsDropdownFooterButton = ():
    | dropdownFooterButtonType
    | typeof undefined => {
    if (this.props.jumpToTargetsDropdownFooterButton) {
      return {
        buttonText:
          (this.props.jumpToTargetsDropdownFooterButton &&
            this.props.jumpToTargetsDropdownFooterButton.buttonText) ||
          "",
        onClick: () => {
          this.props.jumpToTargetsDropdownFooterButton &&
            this.props.jumpToTargetsDropdownFooterButton.onClick &&
            this.props.jumpToTargetsDropdownFooterButton.onClick();

          //Close modal
          this._closeJumpLogicModal();
        }
      };
    }
  };

  _renderBranchOutCome = (branchIndex: number, branch: branchType) => {
    return [
      <UICoreBox marginTop="sm">
        <UICoreText color="darkGray" size="xs" weight="bold">
          {this.props.textContent.branchTarget}
        </UICoreText>
      </UICoreBox>,
      <UICoreBox width="100%" marginTop="xm">
        <UICoreDropdown
          onChoiceClick={choice =>
            this._updateJumpToQuestion(choice, branchIndex)
          }
          placeholder="Chose a question"
          choices={this.props.jumpToTargets}
          width="100%"
          dropdownWidth={436}
          dropdownMaxHeight={150}
          dropdownFooterButton={this._getJumpToTargetsDropdownFooterButton()}
          // $FlowFixMe TODO: fix it
          selectChoice={this._getTargetChoice(branch.jumpToQuestionID)}
        />
      </UICoreBox>
    ];
  };

  _renderBranches = () => {
    const branches = this.props.jumpLogic && this.props.jumpLogic.branches;
    return toArray(branches).map((branch, index) => {
      return (
        <UICoreBox
          marginTop="xm"
          marginBottom="xm"
          boxShadow={true}
          shape="rounded"
          marginLeft="sm"
          marginRight="sm"
          padding="sm"
          position="relative"
        >
          <UICoreText color="darkGray" size="xs" weight="bold">
            {`${this.props.textContent.branchTitlePrefix} ${
              index + arrayOffSet
            } - If following condition(s) are met:`}
          </UICoreText>
          {this._renderConditions(branch.conditions, branch.jointLogics, index)}
          {this.props.renderBranchOutcome
            ? this.props.renderBranchOutcome(branch, updatedBranch => {
                this._updateBranch(index, updatedBranch);
              })
            : this._renderBranchOutCome(index, branch)}
          <UICoreBox
            onClick={() => this._deleteBranch(index)}
            width="20px"
            height="20px"
            shape="circle"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top="-8px"
            right="-8px"
            hoverable={true}
            color="lightGrey"
          >
            <i style={{ color: "#888888" }} className="ion-close" />
          </UICoreBox>
        </UICoreBox>
      );
    });
  };

  _getFirstChoice = (questionID: questionIDType) => {
    if (isTargetFormScore(questionID)) {
      return 0;
    }
    const answerChoices = this._getAnswerChoices(questionID);
    if (answerChoices) {
      return answerChoices[0].value;
    } else {
      return "";
    }
  };

  _getQuestionComperatorsByQuestionID = (questionID: questionIDType) => {
    if (isTargetFormScore(questionID)) {
      return [
        Comperators.numericIs,
        Comperators.numericLessThan,
        Comperators.numericMoreThan
      ];
    }
    const question = this._getQuestionByID(questionID);
    if (!question) return [];
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return [];
    return toArray(questionConfig.comperators);
  };

  _getFirstComperator = (questionID: questionIDType) => {
    if (isTargetFormScore(questionID)) {
      return Comperators.numericMoreThan;
    }
    return toArray(this._getQuestionComperatorsByQuestionID(questionID))[0];
  };

  /*
    For questions that don't have comperator option, use the first question thst has one.
    For thankyou page, which doesn't have a comperator, use the first question in queue as well.
  */
  _getCurrentQuestionIdOrFirstJumpLogicSupportedQuestionId = (
    questionID: ?questionIDType
  ): ?questionIDType => {
    const currentQuestion = questionID && this._getQuestionByID(questionID);
    if (
      currentQuestion &&
      this._isJumpLogicSupportedQuestion(currentQuestion)
    ) {
      return questionID;
    } else {
      const firstJumpLogicSupportedQuestion: ?questionType = toArray(
        this.props.questions
      ).find((question: questionType) =>
        this._isJumpLogicSupportedQuestion(question)
      );
      return (
        firstJumpLogicSupportedQuestion &&
        firstJumpLogicSupportedQuestion.question_id
      );
    }
  };

  _getBranchOutcomeDefaultValue = (): ?{
    outcome: dynamicPricingOutcomeType
  } => {
    return this.props.branchOutcomeDefaultValue
      ? {
          outcome: this.props.branchOutcomeDefaultValue
        }
      : null;
  };

  _addJumpLogic = () => {
    const currentBranches = toArray(
      this.props.jumpLogic && this.props.jumpLogic.branches
    );
    const targetQuestionID =
      this._getCurrentQuestionIdOrFirstJumpLogicSupportedQuestionId(
        this.props.questionID
      );
    if (!targetQuestionID) return;
    this.props.updateJumpLogic(this.props.questionID, {
      ...this.props.jumpLogic,
      branches: [
        ...currentBranches,
        {
          conditions: [
            {
              targetQuestionID: targetQuestionID,
              comperator: this._getFirstComperator(targetQuestionID).typeName,
              targetAnswer: this._getFirstChoice(targetQuestionID)
            }
          ],
          jointLogics: [],
          jumpToQuestionID: this.props.defaultTargetQuestion.value,
          ...this._getBranchOutcomeDefaultValue()
        }
      ]
    });
  };

  _isJumpLogicSupportedQuestion = (question: ?questionType) => {
    if (!question) return false;
    const questionConfig = questionTypes[question.type];
    if (!questionConfig) return false;
    return isNonEmptyArray(questionConfig.comperators);
  };

  _getAddLogicButton = () => {
    if (
      this.props.jumpLogic &&
      isNonEmptyArray(this.props.jumpLogic.branches)
    ) {
      return (
        <UICoreButton key={1} onClick={this._addJumpLogic}>
          {this.props.textContent.addAnotherBranchButton}
        </UICoreButton>
      );
    } else {
      return (
        <UICoreButton key={2} onClick={this._addJumpLogic}>
          {this.props.textContent.addNewBranchButton}
        </UICoreButton>
      );
    }
  };

  _renderDefaultJumpTo = (description: string) => {
    return (
      <UICoreBox
        marginTop="sm"
        marginBottom="sm"
        boxShadow={true}
        shape="rounded"
        marginLeft="sm"
        marginRight="sm"
        padding="sm"
      >
        <UICoreText color="darkGray" size="xs" weight="bold">
          {description}
        </UICoreText>
        <UICoreBox
          position="relative"
          paddingTop="xm"
          width="100%"
          direction="row"
        >
          <UICoreDropdown
            direction="up"
            onChoiceClick={this._handleDefaultChoiceClick}
            choices={this.props.jumpToTargets}
            width="100%"
            selectChoice={this._getSelectedDefaultQuestion()}
            dropdownWidth={436}
            dropdownMaxHeight={90}
            dropdownFooterButton={this._getJumpToTargetsDropdownFooterButton()}
          />
          {this.props.jumpLogic &&
            !isNullOrUndefined(this.props.jumpLogic.default) && (
              <UICoreBox
                paddingLeft="xm"
                paddingRight="xm"
                position="absolute"
                right="24px"
                top="16px"
                onClick={this._clearDefaultJumpTo}
              >
                <i className="ion-close JumpLogic-close" />
              </UICoreBox>
            )}
        </UICoreBox>
      </UICoreBox>
    );
  };

  _renderTutorialBanner = () => {
    const currentQuestion = this._getQuestionByID(this.props.questionID);
    if (!currentQuestion) return null;
    //allow multiple selection
    if (
      currentQuestion.config &&
      currentQuestion.config["Multiple Selection"]
    ) {
      return (
        <UICoreBox padding="sm">
          <UICoreBox padding="xm" shape="rounded" color="skyBlue">
            <UICoreText color="Grey" overflow="wrap" size="xs">
              Since you have turned on multiselect, we recommend you to go
              through our quick tutorial.{" "}
              <a
                target="_blank"
                href="https://wispform.com/guide/how-to-setup-logic-jump#multiselect"
              >
                Read more
              </a>
            </UICoreText>
          </UICoreBox>
        </UICoreBox>
      );
    } else {
      return null;
    }
  };

  _renderJumpLogicModal = () => {
    return (
      this.state.showJumpLogicModal && (
        <UICoreModal
          dismissText="Save"
          size="md"
          header={this.props.textContent.modalTitle}
          onDismiss={this._closeJumpLogicModal}
          body={
            <UICoreBox overflowY="visible" name="modal wrap">
              {this._renderTutorialBanner()}
              <Scrollbars autoHeight autoHeightMax="90vh">
                <UICoreBox marginTop="xm" marginBottom="xm">
                  {this._renderBranches()}
                </UICoreBox>
                <UICoreBox
                  marginTop="xm"
                  direction="row"
                  justifyContent="center"
                >
                  {this._getAddLogicButton()}
                </UICoreBox>
                {this.props.renderDefaultBlock
                  ? this.props.renderDefaultBlock()
                  : this._renderDefaultJumpTo(
                      this.props.textContent.fallbackTarget
                    )}
              </Scrollbars>
            </UICoreBox>
          }
        />
      )
    );
  };

  _renderButton = () => {
    const hasUserDefinedLogic =
      this.props.jumpLogic &&
      (isNonEmptyArray(this.props.jumpLogic.branches) ||
        // $FlowFixMe
        !isNullOrUndefined(this.props.jumpLogic.default));

    // TODO: refactor this logic to outside.
    // For now, we priotize passed in state.
    const showUpdateButton = isNullOrUndefined(this.props.showUpdateButtonState)
      ? hasUserDefinedLogic
      : this.props.showUpdateButtonState;
    if (showUpdateButton && this.props.addButtonHint) {
      return (
        <UICoreButton
          key={this.props.addButtonHint}
          onClick={this._showJumpLogicModal}
          color="blue"
          size="sm"
          maxWidth="130px"
        >
          {this.props.addButtonHint}
        </UICoreButton>
      );
    } else {
      return (
        <UICoreTooltip.WrapAround
          size={200}
          text={this.props.textContent.toolTip}
        >
          <UICoreButton
            key={1}
            onClick={this._showJumpLogicModal}
            color="lightBlue"
            size="sm"
          >
            {" Add "}
          </UICoreButton>
        </UICoreTooltip.WrapAround>
      );
    }
  };

  render() {
    return [this._renderButton(), this._renderJumpLogicModal()];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: getAllQuestions(state.Question),
    hasAnyJumpLogic: hasAnyJumpLogic(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    saveToBackend: () => {
      dispatch(saveToBackend());
    },
    showAccountUpgradeModal: (
      featureName: string,
      featureAction: () => void
    ) => {
      dispatch(showAccountUpgradeModal(featureName, featureAction));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JumpLogic);
