/* external dependencies*/
import React, { Component } from "react";
/* internal dependencies*/
import "./style.css";
import PropTypes from "prop-types";

const HeaderBarButton = ({
  buttonType,
  buttonTitle,
  buttonColor,
  onClick,
  className
}) => {
  // const handleViewButtonClick = (ViewWindowElement)=>{
  //    ViewWindowElement.classList.add('HeaderBar-ViewWindow-Max');
  // }

  const renderButtonIcon = () => {
    switch (buttonType) {
      case "view":
        return (
          <div onClick={onClick} className={"HeaderBarButton-Icon "}>
            <i className="ion-ios-play HeaderBarButton-Icon-Icon" />
          </div>
        );
        break;
      case "save":
        return (
          <div onClick={onClick} className={"HeaderBarButton-Icon "}>
            <i className="ion-ios-download HeaderBarButton-Icon-Icon" />
          </div>
        );
      case "theme":
        return (
          <div onClick={onClick} className={"HeaderBarButton-Icon"}>
            <i className="ion-android-color-palette HeaderBarButton-Icon-Icon" />
          </div>
        );
      case "setting":
        return (
          <div onClick={onClick} className={"HeaderBarButton-Icon"}>
            <i className="fa fa-cog HeaderBarButton-Icon-Icon" />
          </div>
        );
      default:
    }
  };

  return (
    <div className={"HeaderBarButton " + className}>{renderButtonIcon()}</div>
  );
};

HeaderBarButton.propTypes = {
  buttonType: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string,
  buttonColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default HeaderBarButton;
