//@flow
import * as React from "react";
import { connect } from "react-redux";
import withNotifications, {
  type InjectedProps
} from "../../../../../Helper_HOC/withNotifications";
import { logFeatureEvent } from "../../../../../Library/Logger";
import {
  UICoreText,
  UICoreImage,
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreInput
} from "../../../../../Component/UICore";
import {
  type buttonTextConfigType,
  updateButtonText
} from "../../../States/actions";
import { getButtonText } from "../../../States/selectors";
import { Scrollbars } from "react-custom-scrollbars";
import {
  type buttonConfigItemType,
  customizableButtonList,
  ButtonType
} from "../../../../../Helper_HOC/WithConfigurableButtonText/config";
import withConfigurableButtonTextHOC, {
  type InjectedProps as injectedPropsFromConfigurableButtonTextHOC
} from "../../../../../Helper_HOC/WithConfigurableButtonText";
import { enableFormSubmissionPasswordWarningCustomization } from "../../../../../Library/GateKeeper/builderViewerGateKeeper";

type Props = {|
  ...injectedPropsFromConfigurableButtonTextHOC,
  updateButtonText: buttonTextConfigType => void
|};

type State = {|
  scrollContainer: ?HTMLElement
|};

const getCurrentButtonText = (
  buttonTextConfig: ?buttonTextConfigType,
  buttonType: number,
  defaultButtonText: string
) => {
  if (!buttonTextConfig || buttonTextConfig[buttonType] == null)
    return defaultButtonText;
  return buttonTextConfig[buttonType];
};

class ButtonTextSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    scrollContainer: null
  };

  _resetButtonText = (buttonConfig: buttonConfigItemType) => {
    this.props.updateButtonText({
      ...this.props.buttonTextConfig,
      [buttonConfig.buttonType]: buttonConfig.defaultText
    });
  };

  _renderButtonTextEditor = (buttonConfig: buttonConfigItemType) => {
    return (
      <UICoreBox key={buttonConfig.buttonType} paddingTop="sm">
        <UICoreBox>
          <UICoreText size="xs" weight="bold" color="black">
            {buttonConfig.buttonTitle}
          </UICoreText>
        </UICoreBox>
        <UICoreBox>
          <UICoreText color="Grey" size="xxs">
            {buttonConfig.description}
          </UICoreText>
        </UICoreBox>
        <UICoreBox direction="row" alignItems="center">
          <UICoreBox paddingTop="xm">
            <UICoreInput
              onChange={(_, text) => {
                this.props.updateButtonText({
                  ...this.props.buttonTextConfig,
                  [buttonConfig.buttonType]: text
                });
              }}
              width={200}
              size="xs"
              value={this.props.buttonTextConfig[buttonConfig.buttonType]}
            />
          </UICoreBox>
          <UICoreBox paddingTop="xm" paddingLeft="xm">
            <UICoreButton
              onClick={() => {
                this._resetButtonText(buttonConfig);
              }}
              color="white"
              size="sm"
            >
              Reset
            </UICoreButton>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  };

  _filterButtonTextConfiguration(buttonConfig: buttonConfigItemType) {
    if (buttonConfig.buttonType === ButtonType.submitButtonSubText) {
      return enableFormSubmissionPasswordWarningCustomization();
    }
    return true;
  }

  render() {
    return (
      <UICoreBox
        paddingLeft="sm"
        paddingTop="sm"
        alignItems="start"
        height="100%"
        width="100%"
      >
        <UICoreBox alignItems="start" width="100%" height="100%">
          <UICoreBox paddingBottom="sm">
            <UICoreText color="black" size="md" weight="bold">
              Customize button text in your Wispform
            </UICoreText>
          </UICoreBox>
          <UICoreBox
            innerRef={ref => {
              if (!this.state.scrollContainer) {
                this.setState({ scrollContainer: ref });
              }
            }}
            width="100%"
            height="calc(100% - 42px)"
            overflowY="hidden"
          >
            <Scrollbars
              autoHeight
              autoHeightMax={
                this.state.scrollContainer &&
                this.state.scrollContainer.offsetHeight
              }
            >
              {customizableButtonList
                .filter(c => this._filterButtonTextConfiguration(c))
                .map(this._renderButtonTextEditor)}
              <UICoreBox paddingBottom="sm">{""}</UICoreBox>
            </Scrollbars>
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    buttonText: getButtonText(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateButtonText: (updatedButtonText: buttonTextConfigType) => {
      dispatch(updateButtonText(updatedButtonText));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNotifications(withConfigurableButtonTextHOC(ButtonTextSection)));
