import { awsS3UrlPrefix } from "./AwsHelper";
import * as Cryptor from "./Crypto";

// @flow

// brand logo bucket key sould be unique per publisher + form
export function getBrandLogoAwsBucketKey(
  publisherName: string,
  formUID: string
): string {
  return Cryptor.cipher(`${publisherName}.${formUID}`);
}

export function getBrandLogoAwsUrl(
  publisherName: string,
  formUID: string
): string {
  return `${awsS3UrlPrefix}${getBrandLogoAwsBucketKey(publisherName, formUID)}`;
}
