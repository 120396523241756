//@flow strict-local
import React, { Component } from "react";
import UI_Choice from "../UI_Choice";
import PropTypes from "prop-types";
import MultichoiceOtherOptionComponent from "../../FormBuilderQuestionComponents/MultichoiceOtherOptionComponent";
import { type questionConfig } from "../../../Pages/QuestionTypes";
import UI_SubmitMultiSelectFooter from "../UI_SubmitMultiSelectFooter";
import ReactDOM from "react-dom";
/*
  props:
  onQuestionComplete:function
  numOfchoices: int
  choices:array of string
*/

export type multichoiceAnswerType = {|
  index: number,
  choiceContent: string
|};

type questionAnswerType = {|
  answer: Array<multichoiceAnswerType>,
  question_id: string,
  title: string,
  type: string
|};

type Props = {|
  config: questionConfig,
  contents: Array<string>,
  answer: questionAnswerType,
  onQuestionUpdate: (Array<multichoiceAnswerType>, boolean) => mixed,
  onQuestionComplete: (Array<multichoiceAnswerType>) => mixed,
  isCurrentQuestion: boolean
|};

class UI_MultiChoices extends Component<Props> {
  is_multi_select: boolean = false;

  componentWillMount() {
    this.is_multi_select =
      this.props.config && this.props.config["Multiple Selection"];
  }

  _renderOther = () => {
    const index = this.props.contents.length;
    if (this.props.config && this.props.config["Add other"]) {
      return (
        <MultichoiceOtherOptionComponent
          isChoiceSelected={this.isChoiceSelected(index)}
          onClick={item => {
            this._handleChoiceClick(index, item);
          }}
        />
      );
    }
  };

  composedAnswer(index: number, choiceContent: string) {
    const current_answer = {
      index: index,
      choiceContent: choiceContent
    };
    //Adding prev answers if allows multi-select
    if (this.is_multi_select) {
      const prev_answer = this.props.answer && this.props.answer.answer;
      const new_answer = this.getNewAnswer(current_answer, prev_answer);
      return new_answer;
    }
    return [current_answer];
  }

  //handle the case of multi select
  //if the answer is already in the prev_answers, remove it
  //else add it to the answer
  getNewAnswer(
    current_answer: multichoiceAnswerType,
    prev_answers: Array<multichoiceAnswerType>
  ): Array<multichoiceAnswerType> {
    //checking
    if (!prev_answers || !current_answer) {
      return [];
    }
    const index = current_answer.index;
    let target_pos = null;
    for (let i = 0; i < prev_answers.length; i++) {
      //check choiceContent in case user modify 'other' option
      //which should be considered as an update instead of deselect
      if (
        prev_answers[i].index === current_answer.index &&
        prev_answers[i].choiceContent === current_answer.choiceContent
      ) {
        target_pos = i;
        break;
      }
    }
    //found
    let return_answers = prev_answers.slice();
    if (target_pos != null) {
      return_answers.splice(target_pos, 1);
    }
    //not found
    else {
      return_answers.push(current_answer);
    }
    return return_answers;
  }

  _handleChoiceClick = (index: number, choiceContent: string) => {
    if (!this.props.onQuestionUpdate && !this.props.onQuestionComplete) {
      return;
    }
    const composedAnswer = this.composedAnswer(index, choiceContent);
    if (this.is_multi_select) {
      this.props.onQuestionUpdate(composedAnswer, true);
    } else {
      this.props.onQuestionComplete(composedAnswer);
    }
  };

  _renderMultiSelectSubmitFooter = () => {
    const isMultiSelect =
      this.props.config && this.props.config["Multiple Selection"];
    if (
      this.props.isCurrentQuestion &&
      isMultiSelect &&
      this.props.answer &&
      Array.isArray(this.props.answer.answer) &&
      this.props.answer.answer.length > 0
    ) {
      return (
        <UI_SubmitMultiSelectFooter
          onClick={() => {
            this.props.onQuestionComplete(this.props.answer.answer);
          }}
        />
      );
    }
  };

  render() {
    return [
      <div className="UI_MultiChoices">
        {this.props.contents &&
          this.props.contents.map(
            function (item, index) {
              return (
                <UI_Choice
                  prev_answer={this.props.answer && this.props.answer.answer}
                  isChoiceSelected={this.isChoiceSelected(index)}
                  className="UI_MultiChoices-Choice"
                  index={index}
                  choiceContent={item}
                  onClick={() => {
                    this._handleChoiceClick(index, item);
                  }}
                />
              );
            }.bind(this)
          )}
        {this._renderOther()}
      </div>,
      this._renderMultiSelectSubmitFooter()
    ];
  }

  isChoiceSelected(choiceIndex: number) {
    let hasAnswer = this.props.answer && this.props.answer.answer;
    if (hasAnswer) {
      for (let i = 0; i < this.props.answer.answer.length; i++) {
        if (choiceIndex == this.props.answer.answer[i].index) {
          return true;
        }
      }
    } else {
      return false;
    }
  }
}

export default UI_MultiChoices;
