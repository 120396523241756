import React, { Component } from 'react';
import './ScrollBar.css';

class ScrollBar extends Component {
    constructor(props){
        super(props);
        //bind and save eventhandler
        this.mouseMove = this.mouseMove.bind(this);
        this.mouseUp = this.mouseUp.bind(this);
        this.mouseDown = this.mouseDown.bind(this);

        this.scrollbar_node = null;
        this.container_node = null;
        this.children_node = null;
    }

    componentDidMount() {
        this.scrollbar_node = document.getElementById(this.props.scrollbar_id);
        this.container_node = document.getElementById(this.props.container_id);
        this.children_node = document.getElementById(this.props.children_id);
        this.scrollbar_node.addEventListener("mousedown", this.mouseDown);
        this.setScrollBar();
    }
    
    componentDidUpdate(prevProps, prevState) {
        this.setScrollBar();
    }

    getCSSStyle(node, property){
        return window.getComputedStyle(node)[property];
    }

    mouseMove(e){
        //prevent bubling and default
        if(e.stopPropagation) e.stopPropagation();
        if(e.preventDefault) e.preventDefault();
        e.cancelBubble=true;
        e.returnValue=false;
        //get initial cursor position
        if (!this.startClientY){
            this.startClientY = e.clientY;
        }
        //get initial scrollbar top position
        if (!this.start_top){
            this.start_top = parseInt(this.getCSSStyle(this.scrollbar_node, "top"));
        }
        let scroll_range = this.container_height - this.scrollbar_height;
        let mouse_displacement = e.clientY - this.startClientY;
        //get new top property of scroll bar 
        let new_top = mouse_displacement + this.start_top;
        if (new_top < 0){
            new_top = 0;
        }
        else if (new_top>scroll_range){
            new_top = scroll_range;
        }
        //set top property of scroll bar
        this.scrollbar_node.style.top = new_top+"px";
        this.container_node.scrollTop = new_top*this.children_height/this.container_height;
    }

    mouseDown(){
        document.addEventListener("mousemove", this.mouseMove);
        document.addEventListener("mouseup", this.mouseUp);
        this.scrollbar_node.classList.add("TX-ScrollBar-Select");
    }

    mouseUp(){
        document.removeEventListener("mousemove", this.mouseMove);
        document.removeEventListener("mouseup", this.mouseUp);
        this.startClientY = null;
        this.start_top = null;
        this.scrollbar_node.classList.remove("TX-ScrollBar-Select");
    }

    setScrollBar(){
        this.setScrollBarHeight();
        this.showScrollBar();
        this.handleScroll();
    }

    getContainerHeight(){
       if (this.container_node.clientHeight){
            return this.container_node.clientHeight;
       }
       else{// transition height, so get target height of wrapper directly
            const wrapper_node = this.container_node.parentNode && this.container_node.parentNode.parentNode;
            if (wrapper_node == null || wrapper_node.style.height == ""){
                return 0;
            }
            else{
                return parseInt(wrapper_node.style.height);
            }
       }
    }

    setScrollBarHeight(){
        if (this.container_node&&this.children_node){
            this.container_height = this.getContainerHeight();
            this.children_height = this.children_node.clientHeight;
            this.scrollbar_height = this.container_height*(this.container_height/this.children_height);
            this.scrollbar_node.setAttribute("style",`height:${this.scrollbar_height}px`);
        }
    }

    showScrollBar(){
        //shold show scrollbar
        if (this.children_height > this.container_height){
            this.scrollbar_node.style.display = "block"
        }
        else{
            this.scrollbar_node.style.display = "none"
        }
    }

    handleScroll(){
        this.container_node && this.container_node.addEventListener('scroll', (e)=>{
            let scollOffset = this.container_node.scrollTop;
            let scrollbar_top_offset = this.scrollbar_height * (scollOffset/this.container_height);
            this.scrollbar_node.style.top=`${scrollbar_top_offset}px`;
        })
    }
    
    render() {
        return (
            <div id={this.props.scrollbar_id} className="TX-ScrollBar">
            </div>
        )
    }
}

export default ScrollBar;