// @flow

const kFormScoreIDPrefix = "form_score";
/**
 * Logic jump can target two types:
 * 1. question
 * 2. form score
 *
 * Due to backward compatibility, we store both question and form score ids in the field questionID.
 * Given a questionID, we can tell either it is a question or score by calling this function.
 * score ID should begin with form_score
 */
export function isTargetFormScore(questionID: string) {
  return String(questionID).includes(kFormScoreIDPrefix);
}

export function getFormScoreTargetID() {
  return `${kFormScoreIDPrefix}_1`;
}
