//@flow
import * as React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { saveGetTokenCallBack, setStripeValidationError } from "./stripeToken";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import { Elements, StripeProvider } from "react-stripe-elements";

import "./style.css";
type Props = {|
  fontSize: string,
  answerSecondaryColor: string,
  answerPrimaryColor: string,
  styles: formStyleType,
  onQuestionUpdateWithouUpdatingCurrentQuestionIndex: string => void,
  stripe: {|
    createSource: any => Promise<any>,
    createToken: any => Promise<any>
  |}
|};
type State = {||};

class PaymentCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this._setStripComponentColor();
    saveGetTokenCallBack(this.submit.bind(this));
  }

  _setStripComponentColor = () => {
    const stipeElement = document.getElementsByClassName(
      "StripePaymentContainer"
    )[0];
    if (stipeElement) {
      stipeElement.style.background = this.props.answerSecondaryColor;
    }
  };

  async submit(ev) {
    const stripeInfo = await this.props.stripe.createToken({
      type: "card"
    });
    if (stripeInfo && stripeInfo.token && stripeInfo.token.id) {
      // Add "hasToken" to the payment answer indicating this question is completed.
      // Other wise, it will fail the require validator.
      this.props.onQuestionUpdateWithouUpdatingCurrentQuestionIndex &&
        this.props.onQuestionUpdateWithouUpdatingCurrentQuestionIndex(
          "hasToken"
        );
      return stripeInfo.token.id;
    }
    return null;
  }

  _createOptions = (fontSize: string, padding: ?string) => {
    return {
      style: {
        base: {
          fontSize,
          color: this.props.styles.answer,
          letterSpacing: "0.025em",
          fontFamily: "Sans-Serif",
          "::placeholder": {
            color: this.props.answerPrimaryColor
          },
          background: "black",
          ":-webkit-autofill": {
            color: this.props.styles.answer
          },
          ...(padding ? { padding } : {})
        },
        invalid: {
          color: "#9e2146"
        }
      }
    };
  };

  render() {
    return (
      <CardElement
        onChange={e => {
          setStripeValidationError(e.error);
        }}
        {...this._createOptions(this.props.fontSize)}
        className="payment-form-input"
      />
    );
  }
}

export default injectStripe(PaymentCard);
