//@flow

function saveToWebStorage(key: string, value: string) {
  try {
    localStorage && localStorage.setItem(key, value);
  } catch (error) {
    //TODO: add a crash report
  }
}

function getFromWebStorage(key: string): ?string {
  try {
    const value = localStorage && localStorage.getItem(key);
    return value;
  } catch (error) {
    return null;
  }
}

export default {
  saveToWebStorage,
  getFromWebStorage
};
