//@flow
import * as React from "react";
import "./style.css";
import { UICoreBox, UICoreText } from "../../../../../Component/UICore";
import { connect } from "react-redux";
import WithStyles from "../../../../../Helper_HOC/WithStyles";
import { getStyleBackground } from "../../../States/selectors";
import {
  type background,
  backgroundType,
  styleAttributes,
  type formStyleType
} from "../../../../../FlowTypes/wispformStyleTypes";
import { updateStyleBackground } from "../../../States/actions";
import DropdownColorPicker from "../../../../../Component/ColorPicker/DropdownColorPicker";

const DEFAULT_COLOR = "#ffffff";

type Props = {|
  background: background,
  updateStyleBackground: background => void,
  updateStyle: ($Keys<formStyleType>, string | background) => any,
  styles: formStyleType
|};

class DesignPanelColorSection extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  getColor(name: ?$Keys<formStyleType>): string {
    const styleValue = this.props.styles && this.props.styles[String(name)];
    if (styleValue == null) {
      if (
        name == styleAttributes.buttonColor &&
        this.props.styles &&
        this.props.styles.answer
      ) {
        return this.props.styles.answer;
      } else {
        return DEFAULT_COLOR;
      }
    }
    if (typeof styleValue === "string") {
      return styleValue;
    } else {
      return DEFAULT_COLOR;
    }
  }

  render() {
    return (
      <UICoreBox>
        <UICoreBox paddingBottom="sm">
          <UICoreText size="md" color="black" weight="medium">
            Text Color
          </UICoreText>
        </UICoreBox>
        <UICoreBox
          width={310}
          direction="row"
          alignItems="center"
          paddingBottom="sm"
        >
          {" "}
          <UICoreText size="xs" color="black">
            Color for the titles and descriptions of this Wispform
          </UICoreText>
          <UICoreBox paddingLeft="sm">
            <DropdownColorPicker
              selectedHexColor={this.getColor(styleAttributes.text)}
              onColorSelected={color => {
                this.props.updateStyle(styleAttributes.text, color);
              }}
            />
          </UICoreBox>
        </UICoreBox>

        <UICoreBox paddingBottom="sm">
          <UICoreText size="md" color="black" weight="medium">
            Answer Color
          </UICoreText>
        </UICoreBox>
        <UICoreBox
          width={310}
          direction="row"
          alignItems="center"
          paddingBottom="sm"
        >
          {" "}
          <UICoreText size="xs" color="black">
            Color for the answer areas of this Wispform
          </UICoreText>
          <UICoreBox paddingLeft="sm">
            <DropdownColorPicker
              selectedHexColor={this.getColor(styleAttributes.answer)}
              onColorSelected={color => {
                this.props.updateStyle(styleAttributes.answer, color);
              }}
            />
          </UICoreBox>
        </UICoreBox>

        <UICoreBox paddingBottom="sm">
          <UICoreText size="md" color="black" weight="medium">
            Button Color
          </UICoreText>
        </UICoreBox>
        <UICoreBox
          width={310}
          direction="row"
          alignItems="center"
          paddingBottom="sm"
        >
          {" "}
          <UICoreText size="xs" color="black">
            Color for buttons throughout this Wispform
          </UICoreText>
          <UICoreBox paddingLeft="sm">
            <DropdownColorPicker
              selectedHexColor={this.getColor(styleAttributes.buttonColor)}
              onColorSelected={color => {
                this.props.updateStyle(styleAttributes.buttonColor, color);
              }}
            />
          </UICoreBox>
        </UICoreBox>
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    background: getStyleBackground(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateStyleBackground: (background: background) => {
      dispatch(updateStyleBackground(background));
    }
  };
};

export default WithStyles(
  connect(mapStateToProps, mapDispatchToProps)(DesignPanelColorSection)
);
