//@flow
//withNotifications HOC provides a function to let you display notifications on user's screen

import * as React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { showNotification } from "../Pages/WispTable/State/NotificationState/action";
import { type notificationType } from "../Component/Notification";
import { IFormConfig } from "../Library/Forms/FormConfigs/IFormConfig";
import { Form } from "react-bootstrap/lib/Navbar";
import ClassicFormConfig from "../Library/Forms/FormConfigs/ClassicFormConfig";

type State = {||};
export type WithFormConfigInjectedProps = {|
  formConfig: IFormConfig, // nonnull with fallback to classic form
  formConfigNullable: ?IFormConfig
|};

function WithFormConfig<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, WithFormConfigInjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          formConfig={this.props.formConfig || new ClassicFormConfig()}
          formConfigNullable={this.props.formConfig}
        />
      );
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  return {
    formConfig: state.FormConfig.formConfig
  };
};

export default compose(connect(mapStateToProps, null), WithFormConfig);
