// @flow

import * as React from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import { Elements, StripeProvider } from "react-stripe-elements";
import PaymentCardUI from "./PaymentCardUI";

const kStripeKey = "pk_live_0hJ6IN8gfioSJ7TvKscPUBD6";

type Props = {|
  fontSize?: string,
  answerSecondaryColor: string,
  answerPrimaryColor: string,
  styles: formStyleType,
  onQuestionUpdateWithouUpdatingCurrentQuestionIndex: string => void,
  answer: mixed
|};

type State = {||};
class PaymentCard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <StripeProvider apiKey={kStripeKey}>
        <div className="StripePaymentContainer">
          <Elements>
            <PaymentCardUI {...this.props} />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}

export default PaymentCard;
