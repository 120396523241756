// @flow

import { logEvent } from "./index";

export const DebugEvents = {
  emailValidationFailed: "email_validation_failed",
  accountPageDataLoadingFailure: "account_page_data_loading_failure"
};

export function logDebugEvent(
  eventName: $Values<typeof DebugEvents>,
  extra: string
) {
  logEvent("debug", eventName, extra);
}
