import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css";
import AnswerReviewBox from "../../Components/AnswerReviewBox";
import { get_all_answers } from "../../States/Answers/actions";
import PropTypes from "prop-types";

class ReviewAnswers extends Component {
  constructor(props) {
    super(props);
  }

  renderAnswerReviewBoxes() {
    const { answers } = this.props;
    return (
      <div>
        {answers &&
          answers.answers.map(function(item, index) {
            return <AnswerReviewBox answer={item} />;
          })}
      </div>
    );
  }

  render() {
    return (
      <div className={"ReviewAnswers " + this.props.className}>
        <div className="ReviewAnswers-AnswersContainer">
          {this.renderAnswerReviewBoxes()}
        </div>
      </div>
    );
  }
}

ReviewAnswers.propTypes = {
  answers: PropTypes.array.isRequired,
  className: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    answers: state.Answers
  };
}

export default connect(
  mapStateToProps,
  null
)(ReviewAnswers);
