import React, { Component } from "react";
import UI_Rating_Icon from "./UI_Rating_Icon";
import { velocityHelpers } from "velocity-react";
import "./style.css";
import PropTypes from "prop-types";
import WithStyles from "../../../Helper_HOC/WithStyles";
/*
  Animation implementation:

  UI_Rating_Icon will set current component's states to notify clicked event occure and which icon is clicked.

  icon index starts from 1

  getAnimationProps will return corresponding animation props depending on either icons are selected. 
 
  when icon is clicked, it will modify the states first, after that it will white out selected icons and finally submit the answer to reducer. 
*/

class UI_Rating extends Component {
  constructor(props) {
    super(props);
    this.onQuestionComplete = this.onQuestionComplete.bind(this);
    this.state = {
      clicked: false,
      index_Icon_clicked: -1
    };
  }

  getSelectedStarIndex() {
    if (this.props.answer) return this.props.answer.answer;
    else return -1;
  }

  getAnimationProps(IconIndex) {
    if (this.state.clicked && this.state.index_Icon_clicked >= IconIndex) {
      return {
        animation: "callout.flash"
      };
    } else {
      return {
        animation: {
          opacity: 1
        }
      };
    }
  }

  setSelectedIconBackgroundColorToWhite(index) {
    for (let i = 1; i <= 5; i++) {
      if (i <= index) {
        this.setIconToWhite(i);
      } else {
        this.setIconToHorrow(i);
      }
    }
  }

  setIconToWhite(index) {
    document
      .getElementById(this._getRatingIconID(index))
      .classList.remove("fa-star-o");
    document
      .getElementById(this._getRatingIconID(index))
      .classList.add("fa-star");
  }

  setIconToHorrow(index) {
    document
      .getElementById(this._getRatingIconID(index))
      .classList.add("fa-star-o");
    document
      .getElementById(this._getRatingIconID(index))
      .classList.remove("fa-star");
  }

  onQuestionComplete(index) {
    this.setState(
      {
        clicked: true,
        index_Icon_clicked: index
      },
      () => {
        this.whiteOutSelectedIcons(index);
        this.submitAnswer(index);
      }
    );
  }

  whiteOutSelectedIcons(index) {
    setTimeout(() => {
      this.setSelectedIconBackgroundColorToWhite(index);
    }, 170);
  }

  submitAnswer(index) {
    setTimeout(() => {
      this.props.onQuestionComplete && this.props.onQuestionComplete(index);
    }, 350);
  }

  _getRatingIconID = (index: number) => {
    return (
      "UI_Rating-Star " +
      String(index) +
      String(this.props.answer && this.props.answer.question_id)
    );
  };

  renderIcons() {
    let return_Icons_JSX = [];
    for (let index = 1; index <= 5; index++) {
      return_Icons_JSX.push(
        this.props.isInBuilder ? (
          <UI_Rating_Icon
            animationProps={null}
            SelectedStarIndex={5}
            index={index}
            id={this._getRatingIconID(index)}
            onQuestionComplete={() => {}}
          />
        ) : (
          <UI_Rating_Icon
            animationProps={this.getAnimationProps(index)}
            SelectedStarIndex={this.getSelectedStarIndex()}
            index={index}
            id={this._getRatingIconID(index)}
            onQuestionComplete={this.onQuestionComplete}
          />
        )
      );
    }
    return return_Icons_JSX;
  }

  render() {
    return (
      <div
        className={"UI_Rating " + this.props.className}
        style={{ backgroundColor: this.props.answerSecondaryColor }}
      >
        {this.renderIcons()}
      </div>
    );
  }
}

// props validation
UI_Rating.propTypes = {
  onQuestionComplete: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired
};

export default WithStyles(UI_Rating);
