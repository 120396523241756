//@flow
import * as React from "react";
import "./style.css";
import withFormDataFetcher from "../../../../Helper_HOC/withFormDataFetcher";
import FormPageContainer from "../../../Form/Containers/FormPageContainer";
import formConfiguration, {
  type formDataType,
  type questionType,
  type AnswerReduxStateType,
  type AnswerType
} from "../../../QuestionTypes";
import {
  type tableIDType,
  type tableSchemaColumnType,
  type columnTypes,
  type tableSchemaType,
  type cellColumnIdMapType
} from "../../Configuration";
import {
  getTableDetail as apiGetTableDetail,
  type apiTableReturnType,
  submitWisptableForm as apiSubmitWisptableForm
} from "../../api";
import { toArray } from "../../../../Library/Util";
import { getUrlVars, getFirstSubDomain } from "../../../../Library";
import { backgroundType } from "../../../../FlowTypes/wispformStyleTypes";
import { FormTypeEnum } from "../../../../FlowTypes/forms";

//$FlowFixMe
const formConfig = new formConfiguration().types;

function getTableID(): string {
  return String(window.location.pathname.split("/")[2]);
}

function wispformSupportedType(column: tableSchemaColumnType) {
  return (
    column.type === "MultiSelect" ||
    column.type === "ShortText" ||
    column.type === "LongText" ||
    column.type === "YesNo" ||
    column.type === "Rating" ||
    column.type === "Email" ||
    column.type === "FileUpload"
  );
}

function getWispformQuestionType(wisptableType: columnTypes) {
  return wisptableType === "MultiSelect" ? "MultiChoices" : wisptableType;
}

function getContents(column: tableSchemaColumnType) {
  if (column.config && column.config.choices) {
    return toArray(column.config.choices);
  } else {
    return [];
  }
}

function getWispformQuestionConfig(wisptableType: columnTypes) {
  //Allow multiple selection for the multiSelect data type
  if (wisptableType === "MultiSelect") {
    return {
      ["Multiple Selection"]: true
    };
  } else {
    return {};
  }
}

function convertTableColumnsToQuestions(
  columns: ?Array<tableSchemaColumnType>
): Array<questionType> {
  return toArray(columns)
    .filter(wispformSupportedType)
    .map((column: tableSchemaColumnType) => {
      return {
        //$FlowFixMe: filter already took care of the type checking
        type: getWispformQuestionType(column.type),
        title: column.name,
        config: getWispformQuestionConfig(column.type),
        contents: getContents(column),
        isRequired: false,
        validators: [],
        description: "",
        question_id: column.id,
        resultPageWidth: 250,
        onlyOneQuestionPerForm: false
      };
    });
}

function convertTableDataToFormData(
  tableDetail: tableSchemaType
): formDataType {
  if (!tableDetail) {
    throw "not valid tableID";
  }

  return {
    publisher_name: null,
    publisherID: 0,
    created_at: "",
    form_id: tableDetail.id,
    id: tableDetail.id,
    last_edited_question: 0,
    questions: convertTableColumnsToQuestions(
      tableDetail && tableDetail.columns
    ),
    styles: {
      answer: "#ffffff",
      background: {
        color: "#8d7cf7",
        type: backgroundType.color
      },
      button: "#ffffff",
      text: "#ffffff"
    },
    updated_at: "",
    form_plan: "basic",
    configurations: {},
    form_type: FormTypeEnum.Classic
  };
}

function apiGetWispformData(tableIDFromURL: string): Promise<formDataType> {
  const tableID: tableIDType = Number.parseInt(tableIDFromURL);
  return apiGetTableDetail(tableID, getFirstSubDomain()).then(
    (tableDetail: tableSchemaType) => {
      return convertTableDataToFormData(tableDetail);
    }
  );
}

type urlParams = {|
  col: string,
  contact: string,
  row: string,
  table: string
|};

function convertFormAnswerToTableRow(
  answers: Array<AnswerType>
): cellColumnIdMapType {
  return answers.reduce((prev: cellColumnIdMapType, current: AnswerType) => {
    const tableData = formConfig[current.type].toWisptableData(current.answer);
    return { [current.question_id]: tableData, ...prev };
  }, {});
}

function apiPostForm(
  answer: AnswerReduxStateType,
  tableID: string
): Promise<any> {
  const urlVars: urlParams = getUrlVars();
  const publisherName = getFirstSubDomain();

  return apiSubmitWisptableForm(
    convertFormAnswerToTableRow(answer.answers),
    Number.parseInt(tableID),
    Number.parseInt(urlVars.table),
    Number.parseInt(urlVars.row),
    urlVars.col,
    Number.parseInt(urlVars.contact),
    publisherName
  );
}

export default withFormDataFetcher(
  apiGetWispformData,
  getTableID,
  apiPostForm
)(FormPageContainer);
