import {
  RECEIVE_FORMS,
  RECEIVE_COLLABORATORS,
  SHOW_WARNING,
  HIDE_WARNING,
  REQUEST_FORMS,
  REQUEST_WORKSPACES
} from "./actions";
import update from "react-addons-update";

let defaultState = {
  forms: [],
  collaborators: [],
  workspaces: [],
  publisher: {},
  warning: false,
  authenticated: false,
  loadingForms: false,
  loadingWorkspaces: false
};

export default function Workspace(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_FORMS:
      return update(state, {
        loadingForms: { $set: true }
      });
    case "RECEIVE_FORMS":
      return update(state, {
        forms: { $set: action.forms },
        loadingForms: { $set: false }
      });
    case "RECEIVE_COLLABORATORS":
      return update(state, {
        collaborators: { $set: action.collaborators }
      });
    case REQUEST_WORKSPACES:
      return update(state, {
        loadingWorkspaces: { $set: true }
      });
    case "RECEIVE_WORKSPACES":
      return update(state, {
        workspaces: { $set: action.workspaces },
        loadingWorkspaces: { $set: false }
      });
    case "SHOW_WARNING":
      return update(state, {
        warning: { $set: true },
        loadingForms: { $set: false }
      });
    case "HIDE_WARNING":
      return update(state, {
        warning: { $set: false }
      });
    default:
      return state;
  }
}
