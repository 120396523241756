//@flow
import * as React from "react";
import "./style.css";
import HeaderBarButton from "../../../../Component/HeaderBarButton";
import { DropdownButton } from "react-bootstrap";
import { MenuItem } from "react-bootstrap";
import PublisherMenu from "../PublisherMenu";
import LogoWithName from "../../../../Component/Logo/LogoWithName";
import PropTypes from "prop-types";
import ModalContainer from "../../../../Component/ModalContainer";
import StyleInspector from "../StyleInspector";
import { type formStyleType } from "../../../../FlowTypes/wispformStyleTypes";
import {
  UICoreTooltip,
  UICoreModal,
  UICoreBox,
  UICoreSection
} from "../../../../Component/UICore";
import { logFeatureEvent } from "../../../../Library/Logger";
import FormSetting from "../FormSetting";
import WithFormConfig, {
  type WithFormConfigInjectedProps
} from "../../../../Helper_HOC/WithFormConfig";
import { safeGet } from "../../../../Library/Util";

type Props = {
  ...WithFormConfigInjectedProps,
  isChanged: boolean,
  showPreview: () => any,
  saveToBackend: () => any,
  styles: formStyleType,
  updateStyle: () => any
};

type State = {|
  showFormSetting: boolean
|};
class HeaderBar extends React.Component<Props, State> {
  style_inspector: ModalContainer;
  constructor(props: Props) {
    super(props);
    this.style_inspector = null;
  }

  state = {
    showFormSetting: false
  };

  renderChangeIndicator() {
    if (this.props.isChanged) {
      return <div className="HeaderBar-User-Save-Indicator-red" />;
    } else {
      return <div className="HeaderBar-User-Save-Indicator-green" />;
    }
  }

  renderSaveButton() {
    if (this.props.isChanged) {
      return "Save";
    } else {
      return "Saved";
    }
  }

  _handleThemeButtonClick = () => {
    logFeatureEvent("theme/themeButtonClick");
    this.style_inspector.open();
  };

  _handleFormSettingButtonClick = () => {
    this.setState({ showFormSetting: true });
  };

  render() {
    //$FlowFixMe
    const supportedToolbars = safeGet(_ => this.props.formConfig.toolBars);
    return [
      <div className="HeaderBar">
        <div className="HeaderBar-Buttons">
          {supportedToolbars && supportedToolbars.includes("view") && (
            <UICoreTooltip.WrapAround text="preview" size={80}>
              <HeaderBarButton
                className="HeaderBar-Buttons-Button"
                onClick={this.props.showPreview}
                buttonType="view"
                buttonTitle="View"
                buttonColor="HeaderBarButton-Green"
              />
            </UICoreTooltip.WrapAround>
          )}
          {supportedToolbars && supportedToolbars.includes("theme") && (
            <UICoreTooltip.WrapAround text="theme" size={70}>
              <HeaderBarButton
                className="HeaderBar-Buttons-Button"
                onClick={this._handleThemeButtonClick}
                buttonType="theme"
                buttonTitle="Theme"
                buttonColor="HeaderBarButton-Red"
              />
            </UICoreTooltip.WrapAround>
          )}
          {supportedToolbars && supportedToolbars.includes("setting") && (
            <UICoreTooltip.WrapAround text="setting" size={80}>
              <HeaderBarButton
                onClick={this._handleFormSettingButtonClick}
                className="HeaderBar-Buttons-Button"
                buttonType="setting"
                buttonTitle="Setting"
                buttonColor="HeaderBarButton-Yellow"
              />
            </UICoreTooltip.WrapAround>
          )}
        </div>

        <div className="HeaderBar-User">
          <div className="HeaderBar-User-Save">
            <div
              onClick={this.props.saveToBackend}
              className="HeaderBar-User-Save-Button"
            >
              {this.renderSaveButton()}
            </div>
            {this.renderChangeIndicator()}
          </div>
        </div>
      </div>,
      <ModalContainer
        className="HeaderBar-StyleInspector"
        ref={e => {
          this.style_inspector = e;
        }}
      >
        <StyleInspector />
      </ModalContainer>,
      this.state.showFormSetting && (
        <FormSetting
          onDismiss={() => this.setState({ showFormSetting: false })}
        />
      )
    ];
  }
}

HeaderBar.propTypes = {
  showPreview: PropTypes.func.isRequired,
  saveToBackend: PropTypes.func.isRequired,
  isChanged: PropTypes.bool.isRequired
};

export default WithFormConfig(HeaderBar);
