// @flow

export const intervalEnum = {
  month: "month",
  day: "day",
  week: "week",
  year: "year"
};

export const currencyEnum = {
  usd: "usd",
  mxn: "mxn"
};

export const paymentTypeEnum = {
  subscription: "subscription",
  oneTime: "one_time",
  subscriptionAndPayment: "subscription_and_payment"
};

export type subscriptionPlanStripeType = {|
  name: string,
  price: number,
  currency: $Values<typeof currencyEnum>,
  period: $Values<typeof intervalEnum>,
  plan_id: string
|};

/**
 * Persisted into the server to indicate the plans to display
 */

export type subscriptionQuestionContentsType = {|
  availablePlans: Array<string> //planID
|};

export type stripeValidationErrorType = {|
  code: string,
  message: string,
  type: string
|};
