import React, { Component } from 'react';
import {isMobile as isMobileAgent} from './helper';

const withResizeHandler = function(WrappedComponent){
    return class ResizeHandler extends Component {
        constructor(props){
            super(props);
            this.state = {
                window_width : window.innerWidth
            }
            this.resizeHandler = this.resizeHandler.bind(this);
        }
    
        resizeHandler(e){
            this.setState({
                window_width:e.target.innerWidth
            })
        }
    
        componentDidMount() {
            window.addEventListener('resize', this.resizeHandler)
        }
    
        componentWillUnmount() {
            window.removeEventListener('resize', this.resizeHandler);
        }

        isMobile(){
            return (isMobileAgent() || this.state.window_width < 480)
        }
    
        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    isMobile = {this.isMobile()}
                />
            )
        }
    }
}

export default withResizeHandler;