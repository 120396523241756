//@flow
import * as React from "react";
import { domainName } from "../../config";
import { getUrlVars, getOrgName } from "../../Library";
import { logNewUserOnboarding } from "../../Library/Logger";
import { isInOrganization } from "../../Library/UserInfo";
import WebStorage from "../../Library/WebStorage";
import {
  UICoreText,
  UICoreSection,
  UICoreBox,
  UICoreInput,
  UICoreButton,
  UICoreDropdown
} from "../../Component/UICore";
import BrowserUI from "./Components/BrowserUI";
import LinkSection from "./Components/LinkSection";
import EmbedFormSection from "./Components/EmbedFormSection";
import EmbedButtonSection from "./Components/EmbedButtonSection";
import { fetchFormDetail } from "../FormBuilderPage/States/actions";
import {
  getHiddenFieldVariables,
  getHiddenFieldValues
} from "../FormBuilderPage/States/selectors";
import { connect } from "react-redux";
import {
  type hiddenFieldVariablesType,
  type hiddenFieldVariableValuesType
} from "../../FlowTypes/hiddenFieldTypes";
import "./style.css";
import "./style.mobile.css";
import * as HiddenFieldUtil from "../../Library/HiddenFieldUtil";
import { safeGet } from "../../Library/Util";
import {
  getPublisherName,
  getFormUid
} from "../../Library/ViewerContext/formViewerContext";

type Props = {|
  hiddenFieldVariables: hiddenFieldVariablesType,
  hiddenFieldValues: hiddenFieldVariableValuesType,
  fetchFormDetail: () => void
|};
type State = {|
  ShareSectionIndex: number
|};

const section = {
  LINK: 0,
  EMBED_FORM: 1,
  EMBED_BUTTON: 2
};

export const getShareLink = () => {
  if (isInOrganization()) {
    return `https://${String(getOrgName())}.${domainName}/${
      getUrlVars()["id"]
    }`;
  } else {
    return `https://${String(
      WebStorage.getFromWebStorage("name")
    )}.${domainName}/${getUrlVars()["id"]}`;
  }
};

export const getShareLinkForForm = () => {
  return `https://${String(getPublisherName())}.${domainName}/${getFormUid()}`;
};

class Share extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    this.props.fetchFormDetail();
  }

  state = {
    ShareSectionIndex: section.LINK
  };

  _handleSectionClick = (e: SyntheticInputEvent<>, index: number) => {
    this.setState({
      ShareSectionIndex: index
    });
    if (index === section.EMBED_FORM) {
      logNewUserOnboarding("embedSectionClicked");
    }
  };

  _renderEmbedSection = () => {
    logNewUserOnboarding("shareEmbedTabClicked");
  };

  _getUrlWithParam = () => {
    return HiddenFieldUtil.getUrlWithHiddenParams(
      getShareLink(),
      this.props.hiddenFieldVariables,
      safeGet(_ => this.props.hiddenFieldValues[0])
    );
  };

  _getLinkInfo = (): {| name: string, id: string |} => {
    return {
      name: isInOrganization()
        ? String(getOrgName())
        : String(WebStorage.getFromWebStorage("name")),
      id:
        getUrlVars()["id"] +
        HiddenFieldUtil.getParamList(
          this.props.hiddenFieldVariables,
          safeGet(_ => this.props.hiddenFieldValues[0])
        )
    };
  };

  render() {
    return (
      <UICoreBox direction="row" padding="md" className="Share" height="100%">
        <UICoreBox
          height="100%"
          width="224px"
          className="Share-Nav"
          paddingRight="md"
        >
          <UICoreText color="black" weight="bold" size="lg">
            Share
          </UICoreText>
          <UICoreBox marginTop="sm" width="200px">
            <UICoreSection.Vertical
              sections={["Link", "Embed Form", "Embed Button"]}
              activeSectionIndex={this.state.ShareSectionIndex}
              onChange={this._handleSectionClick}
            />
          </UICoreBox>
        </UICoreBox>
        {this.state.ShareSectionIndex === section.LINK && (
          <LinkSection shareLink={this._getUrlWithParam()} />
        )}
        {this.state.ShareSectionIndex === section.EMBED_FORM && (
          <EmbedFormSection shareLink={this._getUrlWithParam()} />
        )}
        {this.state.ShareSectionIndex === section.EMBED_BUTTON && (
          <EmbedButtonSection linkInfo={this._getLinkInfo()} />
        )}
      </UICoreBox>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    hiddenFieldVariables: getHiddenFieldVariables(state.Question),
    hiddenFieldValues: getHiddenFieldValues(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchFormDetail: () => {
      dispatch(fetchFormDetail());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Share);
