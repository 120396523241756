// @flow
import * as React from "react";
import {
  UICoreBox,
  UICoreButton,
  UICoreModal,
  UICoreCheckbox,
  UICoreText,
  UICoreTextArea,
  UICoreImage,
  UICoreLink
} from "../../../../../Component/UICore";
import UICoreTagInput from "../../../../../Component/UICore/UICoreTagInput";
import { connect } from "react-redux";
import { update_thankyou_page, saveToBackend } from "../../../States/actions";
import { type thankyouPageType } from "../../../../WispformTypings";
import {
  getThankyouPages,
  getLastEditedThankyouPageIndex,
  getAllQuestions,
  hasScoreSetting
} from "../../../States/selectors";
import {
  replace,
  isNumber,
  toNumber,
  isNullOrUndefined,
  nonNullOrThrow,
  toUnNullable,
  safeGet,
  resolveInTime
} from "../../../../../Library/Util";
import { getShareLink } from "../../../../Share";
import { getSocialShareContent } from "../../../../../Library/thankyouPageHelper";
import { getTagInputOptions } from "../../../../../Library/AnswerPiping";
import { type questionType } from "../../../../QuestionTypes";

type Props = {|
  thankyouPages: Array<thankyouPageType>,
  lastEditedThankyouPageIndex: ?number,
  allQuestions: Array<questionType>,
  hasScore: boolean,
  updateThankyouPages: (pages: Array<thankyouPageType>) => void,
  saveToBackend: () => void
|};
type State = {|
  showEditor: boolean
|};
class SocialShare extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showEditor: false
    };
  }

  _updateCurrentEditingThankyouPage = (
    updatedAttributes: $Shape<thankyouPageType>
  ) => {
    if (isNumber(this.props.lastEditedThankyouPageIndex)) {
      this.props.updateThankyouPages(
        replace(
          this.props.thankyouPages,
          {
            ...this._getCurrentThankyouPage(),
            ...updatedAttributes
          },
          toNumber(this.props.lastEditedThankyouPageIndex)
        )
      );
    }
  };

  _getCurrentThankyouPage = (): ?thankyouPageType => {
    if (isNumber(this.props.lastEditedThankyouPageIndex)) {
      return this.props.thankyouPages[
        toNumber(this.props.lastEditedThankyouPageIndex)
      ];
    }
    return null;
  };

  _getCurrentThankyouPageShouldShowSocialShare = (): boolean => {
    const currentThankyouPage = this._getCurrentThankyouPage();
    if (isNullOrUndefined(currentThankyouPage)) {
      return false;
    } else {
      return toUnNullable(currentThankyouPage).showSocialShare;
    }
  };

  _getCurrentThankyouPageSocialShareContent = (): ?string => {
    const currentThankyouPage = this._getCurrentThankyouPage();
    if (isNullOrUndefined(currentThankyouPage)) {
      return "";
    } else {
      return safeGet(
        _ => toUnNullable(currentThankyouPage).socialShareConfig.shareText
      );
    }
  };

  render() {
    return [
      <UICoreBox>
        <UICoreButton
          onClick={() => {
            if (!this._getCurrentThankyouPageShouldShowSocialShare()) {
              // Add delay for the UI purpose
              return resolveInTime(1000, _ => {
                this._updateCurrentEditingThankyouPage({
                  showSocialShare: true
                });
                this.setState({
                  showEditor: true
                });
              });
            } else {
              this.setState({
                showEditor: true
              });
            }
          }}
          key={String(this._getCurrentThankyouPageShouldShowSocialShare())}
          color="lightBlue"
          size="sm"
        >
          {this._getCurrentThankyouPageShouldShowSocialShare()
            ? "Edit"
            : "Enable"}
        </UICoreButton>
      </UICoreBox>,
      this.state.showEditor && (
        <UICoreModal
          size="md"
          header="Social Share Icons"
          onDismiss={() => {
            this.setState({
              showEditor: false
            });
            this.props.saveToBackend();
          }}
          body={
            <UICoreBox paddingTop="sm" paddingLeft="sm" paddingRight="sm">
              <UICoreBox paddingBottom="sm">
                <UICoreText color="black">
                  Social share icons enable people to easily share your form
                  with their social network.
                </UICoreText>
                <UICoreBox
                  paddingTop="sm"
                  justifyContent="center"
                  alignItems="center"
                >
                  <UICoreBox width="300px" height="58.14px">
                    <UICoreImage src="https://wispformapp.s3.us-east-2.amazonaws.com/guide/social+share.png"></UICoreImage>
                  </UICoreBox>
                </UICoreBox>
                <UICoreBox paddingTop="sm">
                  <UICoreBox paddingBottom="xm">
                    <UICoreText size="sm" weight="medium">
                      Default Share Content
                    </UICoreText>
                    <UICoreBox paddingTop="xm">
                      <UICoreText size="xs">
                        Below content will be filled as default text when user
                        clicks on the social icon.
                      </UICoreText>
                    </UICoreBox>
                  </UICoreBox>
                  <UICoreBox
                    color="lightGrey"
                    shape="rounded"
                    padding="xm"
                    hoverable={true}
                  >
                    <UICoreTagInput.Editor
                      input={getSocialShareContent(
                        this._getCurrentThankyouPageSocialShareContent()
                      )}
                      onInputUpdate={(content: string) => {
                        this._updateCurrentEditingThankyouPage({
                          socialShareConfig: {
                            ...safeGet(
                              _ =>
                                toUnNullable(this._getCurrentThankyouPage())
                                  .socialShareConfig
                            ),
                            shareText: content
                          }
                        });
                      }}
                      textStyleProps={{ color: "black", children: null }}
                      tagHexColor="#88C8FE"
                      tagTextStyle={{
                        color: "darkGray",
                        children: null,
                        size: "xs"
                      }}
                      tagOptionGroups={[
                        {
                          header: "Answer from Questions:",
                          tags: getTagInputOptions(
                            this.props.allQuestions,
                            Infinity
                          )
                        },
                        {
                          header: "Variables",
                          tags: {
                            ...(this.props.hasScore
                              ? {
                                  formScore: {
                                    id: "formScore",
                                    content: "score"
                                  }
                                }
                              : {})
                          }
                        }
                      ]}
                    />
                    <UICoreBox paddingTop="sm">
                      <UICoreLink href={getShareLink()}>
                        <UICoreText color="lightBlue">
                          {getShareLink()}
                        </UICoreText>
                      </UICoreLink>
                    </UICoreBox>
                  </UICoreBox>
                </UICoreBox>
              </UICoreBox>
              <UICoreBox paddingTop="sm" paddingBottom="sm">
                <UICoreButton
                  onClick={async () => {
                    return resolveInTime(1000, _ => {
                      this._updateCurrentEditingThankyouPage({
                        showSocialShare: false
                      });
                      this.setState({
                        showEditor: false
                      });
                      this.props.saveToBackend();
                    });
                  }}
                  color="red"
                >
                  Remove
                </UICoreButton>
              </UICoreBox>
            </UICoreBox>
          }
        />
      )
    ];
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    allQuestions: getAllQuestions(state.Question),
    thankyouPages: getThankyouPages(state.Question),
    lastEditedThankyouPageIndex: getLastEditedThankyouPageIndex(state.Question),
    hasScore: hasScoreSetting(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateThankyouPages: (pages: Array<thankyouPageType>) =>
      dispatch(update_thankyou_page(pages)),
    saveToBackend: () => dispatch(saveToBackend())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SocialShare);
