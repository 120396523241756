//@flow
import * as React from "react";
import ReactDOM from "react-dom";
import Dropzone from "react-dropzone";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  UICoreBox,
  UICoreText,
  UICoreSection,
  UICoreInput,
  UICoreButton,
  UICoreLink
} from "../index";
import "./style.css";
import { injectArgs } from "../../../Library/Util";
import { isPaidUser } from "../../../Library/UserInfo";
import { uploadFileToAWS } from "../../../Library/AwsHelper";
import { logFeatureEvent } from "../../../Library/Logger";

import AWS from "aws-sdk";
import { getImageFileDimension } from "../../../Library/File";
import { logErrorInfo } from "../../../Helper_HOC/WithErrorBoundary";
import withNotifications, {
  type notificationTypes,
  type InjectedProps
} from "../../../Helper_HOC/withNotifications";
import {
  type questionMediaTypeEnumType,
  questionMediaTypeEnum
} from "../../../FlowTypes/questionTypes";

type Props = {|
  ...InjectedProps,
  size?: "sm" | "md" | "lg" | "xl",
  footer?: React.Node,
  onDismiss: () => void,
  dismissText?: string,
  storeImage: any,
  defaultSearchText?: string,
  unsplashOrientation?: string
|};
type State = {|
  activeSectionIndex: number,
  unsplash_data: Array<Object>,
  page: number,
  imageSearchText: string,
  uploadingMedia: boolean,
  uploadingProgress: number
|};
const oneMB = 1000000;

class UICoreImageUploader extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.fetchImages = this.fetchImages.bind(this);
  }

  state = {
    activeSectionIndex: 0,
    unsplash_data: [],
    page: 1,
    imageSearchText: "",
    uploadingMedia: false,
    uploadingProgress: 0
  };

  componentWillMount() {
    this.fetchImages();
  }

  fetchImages = async () => {
    const data = await fetch(
      `https://api.unsplash.com/search/photos?page=${this.state.page}&query=${
        this.state.imageSearchText === ""
          ? this.props.defaultSearchText
            ? this.props.defaultSearchText
            : "Nature"
          : this.state.imageSearchText
      }&client_id=${process.env.REACT_APP_UNSPLASH_CLIENT_ID || ""}${
        this.props.unsplashOrientation
          ? "&orientation=" + this.props.unsplashOrientation
          : ""
      }`
    );
    const dataJ = await data.json();
    const result = dataJ.results;
    this.setState({
      unsplash_data: [...this.state.unsplash_data, ...result],
      page: this.state.page + 1
    });
  };

  _startUploadingMedia = () =>
    this.setState({
      uploadingMedia: true
    });

  _finishUploadingMedia = () => {
    this.setState({ uploadingMedia: false });
    this.props.onDismiss();
  };

  _handleProgress = (progress: AWS.S3.ManagedUpload.Progress) => {
    const progressInPercentage = Math.round(
      (progress.loaded / progress.total) * 100
    );
    this.setState({
      uploadingProgress: progressInPercentage
    });
  };

  _handleMediaUpload = (
    mediaType: questionMediaTypeEnumType,
    files: Array<File>,
    rejectedFiles: Array<File>
  ) => {
    logFeatureEvent("formbuilderPage/customImageUsed");
    const file = files[0];
    if (!file) {
      this.props.showNotification(
        "error",
        "Invalid image file",
        "You can only upload image file with .jpeg .png"
      );
      return;
    }
    let maxMB = 5;
    if (isPaidUser()) {
      maxMB = 50;
    }
    if (file.size > maxMB * oneMB) {
      logErrorInfo("fileupload too big", "");
      this.props.showNotification(
        "error",
        "Failed to upload image",
        `Image size should be less than ${maxMB} MB`
      );
      return;
    }
    uploadFileToAWS(
      file,
      {}, //config
      this._startUploadingMedia,
      this._handleProgress,
      data => {
        getImageFileDimension(file, dimension => {
          this.props.storeImage(
            data.Location,
            mediaType,
            dimension.width,
            dimension.height
          );
          this._finishUploadingMedia();
        });
      },
      null
    );
  };

  _backgroundNode: ?HTMLElement = null;

  _getWidth = (): number => {
    switch (this.props.size) {
      case "sm":
        return 450;
      case "md":
        return 500;
      case "lg":
        return 800;
      case "xl":
        return 1000;
      default:
        return 500;
    }
  };

  _handleClick = (e: Event) => {
    if (e.target === this._backgroundNode) {
      this.props.onDismiss();
    }
  };

  _renderModal = (): React.Node => {
    return (
      <div
        ref={ref => (this._backgroundNode = ref)}
        onClick={this._handleClick}
        className="UICoreImageUploader"
      >
        <UICoreBox
          direction="column"
          shape="rounded"
          width={this._getWidth()}
          color="white"
          className="UICoreImageUploader-modal"
        >
          <UICoreBox
            width="100%"
            justifyContent="center"
            paddingTop="xm"
            paddingBottom="xm"
            paddingLeft="sm"
            paddingRight="sm"
            position="relative"
          >
            {this.props.dismissText ? (
              <UICoreBox
                shape="rounded"
                hoverable={true}
                position="absolute"
                right="8px"
                paddingLeft="xm"
                paddingRight="xm"
                paddingBottom="xxm"
                paddingTop="xxm"
                onClick={this.props.onDismiss}
              >
                <UICoreText weight="bold" hexColor="#6669FF">
                  {this.props.dismissText}
                </UICoreText>
              </UICoreBox>
            ) : (
              <div
                onClick={this.props.onDismiss}
                className="UICoreImageUploader-closeIcon"
              >
                <i className="ion-close" />
              </div>
            )}
            <UICoreBox marginLeft="sm" marginRight="sm">
              <UICoreText
                color="black"
                alignment="center"
                weight="bold"
                size="lg"
              >
                Add Image
              </UICoreText>
            </UICoreBox>
          </UICoreBox>

          <UICoreBox paddingLeft="md" paddingRight="md">
            <UICoreBox>
              <UICoreSection.Horizontal
                onChange={(_, index) =>
                  this.setState({
                    activeSectionIndex: index
                  })
                }
                activeSectionIndex={this.state.activeSectionIndex}
                sections={["Images", "Upload"]}
              />
            </UICoreBox>
            <UICoreBox>
              {this.state.activeSectionIndex === 0 && this.renderPhotoSection()}
              {this.state.activeSectionIndex === 1 &&
                this.renderUploadSection()}
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </div>
    );
  };

  renderUploadSection() {
    return (
      <Dropzone
        accept="image/*"
        onDrop={injectArgs(
          this._handleMediaUpload,
          questionMediaTypeEnum.image
        )}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <UICoreBox
                height="450px"
                width="100%"
                color="lightGrey"
                marginTop="sm"
                marginBottom="md"
                justifyContent="center"
                className="imageUploaderBox"
              >
                <UICoreBox
                  dangerous_style={{
                    fontSize: "60px",
                    alignItems: "center"
                  }}
                  direction="column"
                >
                  {" "}
                  <i
                    style={{
                      color: "grey"
                    }}
                    className="fa fa-cloud-upload"
                  />
                  {this.state.uploadingMedia ? (
                    <UICoreText weight="medium">
                      Uploading your image...
                      {this.state.uploadingProgress + "%"}
                    </UICoreText>
                  ) : (
                    <UICoreText weight="medium">
                      Click to upload your image here
                    </UICoreText>
                  )}
                </UICoreBox>
              </UICoreBox>{" "}
            </div>
          );
        }}
      </Dropzone>
    );
  }

  onSearchInputUpdated = event => {
    this.setState({
      imageSearchText: event.target.value
    });
  };

  renderPhotoSection() {
    return (
      <UICoreBox
        height="450px"
        width="100%"
        // color="lightGrey"
        marginTop="sm"
        marginBottom="md"
        justifyContent="center"
      >
        <UICoreBox direction="row">
          <UICoreInput
            onChange={this.onSearchInputUpdated}
            value={this.state.imageSearchText}
            placeholder={"Search for your image here"}
            autoFocus={true}
            autoSelect={true}
            backgroundColor={"white"}
            withBorder={true}
            width={"350px"}
            onKeyUp={e => {
              if (e.key === "Enter") {
                logFeatureEvent("formbuilderPage/unsplashImageSearched");
                this.setState({ unsplash_data: [] });
                this.fetchImages();
              }
            }}
          />{" "}
          <UICoreBox paddingLeft="sm">
            <UICoreButton
              onClick={e => {
                logFeatureEvent("formbuilderPage/unsplashImageSearched");
                this.setState({ unsplash_data: [] });
                this.fetchImages();
              }}
              size="lg"
              color="lightBlue"
            >
              Search
            </UICoreButton>
          </UICoreBox>
        </UICoreBox>
        <div
          style={{
            overflowY: "scroll",
            width: "100%",
            height: "450px",
            paddingTop: "30px"
          }}
        >
          {(this.state.unsplash_data &&
            this.state.unsplash_data === undefined) ||
          this.state.unsplash_data.length == 0 ? (
            <UICoreText>No images found, try a different keyword...</UICoreText>
          ) : (
            <InfiniteScroll
              dataLength={this.state.unsplash_data.length}
              next={this.fetchImages}
              hasMore={true}
              loader={<h4>Loading...</h4>}
              height={450}
              onScroll={() => {
                logFeatureEvent("formbuilderPage/unsplashImageScrolled");
              }}
            >
              <ResponsiveMasonry columnsCountBreakPoints={{ [300]: 2 }}>
                <Masonry gutter="20px">
                  {this.state.unsplash_data.map(val => {
                    return (
                      <UICoreBox className="imageUploader-unsplashImage">
                        <a
                          href={
                            val.user.links.html +
                            "?utm_source=wispform&utm_medium=referral"
                          }
                          onClick={e => {
                            e.preventDefault();
                            this.props.storeImage(
                              val.urls.regular,
                              questionMediaTypeEnum.image,
                              val.width,
                              val.height
                            );
                            fetch(
                              `${val.links.download_location}?client_id=${
                                process.env.REACT_APP_UNSPLASH_CLIENT_ID || ""
                              }`
                            );
                            this.props.onDismiss();
                            logFeatureEvent(
                              "formbuilderPage/unsplashImageUsed"
                            );
                          }}
                        >
                          <UICoreBox position="relative">
                            {/* <a href={val.urls.small}> */}
                            <img
                              src={val.urls.small}
                              alt="val.alt_description"
                              className="unsplash-image-ui-core-uploader"
                            />
                            <div className="imageUploader-unsplashImageCredit unsplash-image-ui-core-uploader">
                              By {val.user.name} from Unsplash
                            </div>
                            {/* </a> */}
                          </UICoreBox>
                        </a>
                      </UICoreBox>
                    );
                  })}
                </Masonry>
              </ResponsiveMasonry>
            </InfiniteScroll>
          )}
        </div>
      </UICoreBox>
    );
  }

  render() {
    if (document.body) {
      //$FlowFixMe flow is going crazy here.. already checked the nullity of document.body
      return ReactDOM.createPortal(this._renderModal(), document.body);
    } else {
      return null;
    }
  }
}
export default withNotifications(UICoreImageUploader);
