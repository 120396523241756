//@flow
import WisptableConfig, {
  type tableDataRowType,
  type rowIDType,
  type rowIndexType,
  type cellColumnIdMapType,
  type columnIDType,
  type cellValueType,
  type tableSchemaColumnType,
  type tableSchemaType,
  type tableType,
  type tableIDType,
  type sortType
} from "../../Configuration";
import { isNullOrUndefined } from "../../../../Library/Util";

export function addTable(
  tableToBeAdded: tableType,
  currentTables: Array<tableType>
) {
  //table to be added already exsited
  if (currentTables.find(table => table.id === tableToBeAdded.id)) {
    return currentTables;
  } else {
    return [...currentTables, tableToBeAdded];
  }
}

export function updateTable(
  attributes: $Shape<tableType>,
  tableID: tableIDType,
  currentTables: Array<tableType>
): Array<tableType> {
  return currentTables.map((table: tableType) => {
    if (table.id === tableID)
      return {
        ...table,
        ...attributes
      };
    else return table;
  });
}

export function removeTable(
  tableIDToBeRemoved: tableIDType,
  currentTables: Array<tableType>
): Array<tableType> {
  return currentTables.filter(table => {
    return table.id !== tableIDToBeRemoved;
  });
}

export function getFirstTableID(tables: Array<tableType>): ?tableIDType {
  if (tables[0] && tables[0].id !== null && tables[0].id !== undefined) {
    return tables[0].id;
  } else {
    return null;
  }
}

export function getRow(
  rows: Array<tableDataRowType>,
  rowID: rowIDType
): ?tableDataRowType {
  return rows.find((tableData: tableDataRowType) => tableData.id === rowID);
}

function getRowIndex(
  rows: Array<tableDataRowType>,
  rowID: rowIDType
): ?rowIndexType {
  return rows.findIndex(
    (tableData: tableDataRowType) => tableData.id === rowID
  );
}

/*
  targetRowID specifies a target row position your new row to be inserted
  position specifies either your new row is inserted before or after the target 

  both targetRowID and position is optional. If not specified, default to the last
*/
export function addRow(
  currentRows: Array<tableDataRowType>,
  newRow: tableDataRowType,
  targetRowID?: rowIDType,
  position?: "before" | "after"
): Array<tableDataRowType> {
  //default to last
  if (!targetRowID || !position) {
    return [...currentRows, newRow];
  } else {
    const targetRowIndex = currentRows.findIndex(row => row.id === targetRowID);
    if (position === "before") {
      return [
        ...currentRows.slice(0, targetRowIndex),
        newRow,
        ...currentRows.slice(targetRowIndex)
      ];
    } else {
      return [
        ...currentRows.slice(0, targetRowIndex + 1),
        newRow,
        ...currentRows.slice(targetRowIndex + 1)
      ];
    }
  }
}

export function getUpdatedRows(
  rows: Array<tableDataRowType>,
  rowID: rowIDType,
  columnID: columnIDType,
  cellValue: cellValueType
): Array<tableDataRowType> {
  const targetRow = getRow(rows, rowID);
  const rowIndex = getRowIndex(rows, rowID);

  if (
    rowIndex === -1 ||
    targetRow === undefined ||
    targetRow === null ||
    rowIndex === undefined ||
    rowIndex === null
  )
    return [];
  const updatedTargetRow = {
    ...targetRow,
    cellColumnIdMap: {
      ...targetRow.cellColumnIdMap,
      [columnID]: cellValue
    }
  };
  const rowsBeforeTargetRow = rows.slice(0, rowIndex);
  const rowsAfterTargetRow = rows.slice(rowIndex + 1);
  return [...rowsBeforeTargetRow, updatedTargetRow, ...rowsAfterTargetRow];
}

/*
  if position is not provided, default to the last
*/
export function addNewColumn(
  columns: Array<tableSchemaColumnType>,
  columnID: columnIDType,
  position?: ?number
): Array<tableSchemaColumnType> {
  //if position is not specified, default to the end
  if (position == null) {
    position = columns.length;
  }
  return [
    ...columns.slice(0, position),
    {
      id: columnID,
      name: "New Column",
      type: "ShortText",
      config: {}
    },
    ...columns.slice(position)
  ];
}

export function getNewColumnID(): columnIDType {
  const currentTime = new Date().getTime();
  return `cl_${String(currentTime)}`;
}

export function updateColumn(
  columns: Array<tableSchemaColumnType>,
  updatedSchema: $Shape<tableSchemaColumnType>,
  columnID: columnIDType
) {
  const columnIndex = getPositionByColumnID(columnID, columns);
  return getUpdatedColumns(
    columns,
    {
      ...columns[columnIndex],
      ...updatedSchema
    },
    columnIndex
  );
}

/*
  get position (starts from 0) of column whose id is columnID in the columns array
  return -1 if not found
*/
export function getPositionByColumnID(
  columnID: columnIDType,
  columns: Array<tableSchemaColumnType>
): number {
  const position: number = columns.findIndex(
    (column: tableSchemaColumnType) => column.id === columnID
  );
  return position;
}

/*
  Insert updatedColumn into position
  return a new columns

  if position is not given, default to the last
*/
export function getUpdatedColumns(
  columns: Array<tableSchemaColumnType>,
  updatedColumn: tableSchemaColumnType,
  position: number
): Array<tableSchemaColumnType> {
  return [
    ...columns.slice(0, position),
    updatedColumn,
    ...columns.slice(position + 1)
  ];
}

/**
 * Table data synced from wispform contains: welcompage
 * which is not supported in the wisptable
 */
export function filterOutUnsupporttedType(
  tableSchemaFromApi: ?tableSchemaType
): tableSchemaType {
  if (!tableSchemaFromApi) {
    return { columns: [], id: 0, primary_column_id: "0", views: [] };
  }
  const filter = (column: tableSchemaColumnType) => {
    return WisptableConfig.dataTypes[column.type] != undefined;
  };
  return {
    id: tableSchemaFromApi.id,
    primary_column_id: tableSchemaFromApi.primary_column_id,
    columns: tableSchemaFromApi.columns.filter(filter),
    views: tableSchemaFromApi.views
  };
}
