//@flow

/********************
 *
 *  Configurations for Wisp Table
 *
 ********************/
import TextComponent from "../Components/CellComponents/TextComponent";
import MultiselectComponent from "../Components/CellComponents/MultiselectComponent";
import YesNoComponent from "../Components/CellComponents/YesNoComponent";
import RatingComponent from "../Components/CellComponents/RatingComponent";
import FileComponent from "../Components/CellComponents/FileComponent";
import LinkerComponent from "../Components/CellComponents/LinkerComponent";
import ContactComponent from "../Components/CellComponents/ContactComponent";
import FormComponent from "../Components/CellComponents/FormComponent";
import CodeBlockComponent from "../Components/CellComponents/CodeBlockComponent";

import YesNoDataUI from "../Components/DataUIComponents/YesNoDataUI";
import TextDataUI from "../Components/DataUIComponents/TextDataUI";
import RatingDataUI from "../Components/DataUIComponents/RatingDataUI";
import MultiselectDataUI from "../Components/DataUIComponents/MultiselectDataUI";
import LinkerDataUI from "../Components/DataUIComponents/LinkerDataUI";
import LongTextDataUI from "../Components/DataUIComponents/LongTextDataUI";
import FileDataUI from "../Components/DataUIComponents/FileDataUI";
import ContactDataUI from "../Components/DataUIComponents/ContactDataUI";
import FormDataUI from "../Components/DataUIComponents/FormDataUI";
import CodeBlockDataUI from "../Components/DataUIComponents/CodeBlockDataUI";

import YesNoType from "./TableDataTypes/YesNoType";
import ShortTextType from "./TableDataTypes/ShortTextType";
import LongTextType from "./TableDataTypes/LongTextType";
import RatingType from "./TableDataTypes/RatingType";
import EmailType from "./TableDataTypes/EmailType";
import MultiSelectType from "./TableDataTypes/MultiSelectType";
import FileType from "./TableDataTypes/FileType";
import LinkerType from "./TableDataTypes/LinkerType";
import ContactType from "./TableDataTypes/ContactType";
import FormType from "./TableDataTypes/FormType";
import CodeBlockType from "./TableDataTypes/CodeBlockType";

import * as React from "react";

import { type dialogConfigType } from "../Components/ColumnHeader";
import { UICoreListItemTypeEnum } from "../../../FlowTypes/UICoreTypes/UICoreListType";
import { type choiceType } from "../../../Component/UICore/UICoreDropdown";

export type DataType = {
  icon: string,
  cellComponent:
    | typeof TextComponent
    | typeof MultiselectComponent
    | typeof YesNoComponent
    | typeof RatingComponent
    | typeof FileComponent
    | typeof LinkerComponent
    | typeof ContactComponent
    | typeof FormComponent
    | typeof CodeBlockComponent,
  dataUIComponent:
    | typeof TextComponent
    | typeof YesNoDataUI
    | typeof TextDataUI
    | typeof RatingDataUI
    | typeof LinkerDataUI
    | typeof LongTextDataUI
    | typeof MultiselectDataUI
    | typeof FileDataUI
    | typeof ContactDataUI
    | typeof FormDataUI
    | typeof CodeBlockDataUI,
  displayName: string,
  sortFunction: ?(any, any) => number,
  filter: ?filterConfigType,
  columnActions?: {
    [string]: columnActionType
  }
};

export type showDialogFunctionType = ($Shape<dialogConfigType>) => void;
export type columnActionType = {|
  actionName: string,
  actionIconClass: string,
  actionFunction: (
    tableDatasType,
    tableSchemaColumnType,
    showDialogFunctionType
  ) => void
|};

export type DataComponentPropsType = {|
  value: ?cellValueType,
  config: ?columnConfigType,
  columnID: columnIDType,
  rowId: rowIDType
|};

export type filterOptionType = choiceType;

export type optionInputType = "text" | "dropdown";
export type filterConfigType = {|
  operators: Array<filterOperatorType>,
  filterFunction: (value: any, filterDetail: filterType) => boolean,
  getOptions: (?columnConfigType) => Map<any, filterOptionType>,
  optionInputType: optionInputType
|};

const WisptableConfig: { dataTypes: { [columnTypes]: DataType } } = {
  dataTypes: {
    Linker: LinkerType,
    ShortText: ShortTextType,
    LongText: LongTextType,
    MultiSelect: MultiSelectType,
    YesNo: YesNoType,
    Rating: RatingType,
    Email: EmailType,
    FileUpload: FileType,
    Contact: ContactType,
    Form: FormType,
    CodeBlock: CodeBlockType
  }
};
export type columnTypes =
  | "Linker"
  | "ShortText"
  | "LongText"
  | "MultiSelect"
  | "YesNo"
  | "Rating"
  | "Email"
  | "FileUpload"
  | "Contact"
  | "Form"
  | "CodeBlock";

export default WisptableConfig;

/********************
 *
 *  Datastructure type for Wisp Table
 *
 ********************/
/*
    tables
*/
export type tableType = {|
  id: tableIDType,
  name: string,
  uuid: string,
  created_at: string,
  updated_at: string
|};

/*
    tableSchema
*/
export type tableSchemaType = {|
  id: tableIDType,
  primary_column_id: string,
  columns: Array<tableSchemaColumnType>,
  views: Array<tableViewType> //view related configuration eg. grid view, calender view.etc.
|};

export type tableIDType = number;

export type tableSchemaColumnType = {|
  id: columnIDType,
  name: string,
  type: columnTypes,
  config: columnConfigType //store column specific info. Eg, choices, linking table column.etc.
|};

export type columnIDType = string;

export type columnConfigType =
  | {}
  | linkerConfigType
  | multipleSelectConfigType
  | formConfigType;

export type linkerConfigType = {|
  linkingTableID: tableIDType,
  aggregator?: {|
    currentTableColumnID: columnIDType,
    linkingTableColumnID: columnIDType,
    method: "containes" | "is"
  |}
|};

export type formConfigType = {|
  formID: wispformIDType
|};

type multipleSelectConfigType = {|
  choices: Array<string>
|};

type tableViewType = {||};

/*
    tableDatas
*/
export type tableDatasType = Array<tableDataRowType>;
export type cellColumnIdMapType = { [columnIDType]: cellValueType };

export type dataUIComponentPropType = {|
  value: cellValueType,
  config: ?columnConfigType,
  updateCell: cellValueType => void
|};

export type tableDataRowType = {|
  id: rowIDType,
  created_at: string,
  cellColumnIdMap: ?cellColumnIdMapType
|};

export type rowIDType = number;
export type rowIndexType = number;

export type cellValueType =
  | textCellType
  | numberCellType
  | formCellType
  | linkerCellType
  | multipleSelectCellType
  | fileUploadCellType
  | contactCellType
  | yesNoCellType;

export type textCellType = string;
export type numberCellType = number;
export type linkerCellUnitType = {|
  linkingRowID: rowIDType,
  linkingRowDisplayName: string
|};

export type contactIDType = number;

export type linkerCellType = Array<linkerCellUnitType>;
export type contactCellType = {|
  name: string,
  email: string,
  id: contactIDType
|};

export type formCellUnitType = {|
  linkingResponseID: number
|};

export type formCellStateType = "pending" | "default";
export type formCellType = {|
  responses: Array<formCellUnitType>,
  status: formCellStateType
|};

export type multipleSelectCellType = Array<string>;
export type fileType = "application/pdf" | "image/jpeg" | "image/png";
export type fileUploadCellType = {|
  link: string,
  fileName: string,
  fileType: fileType,
  fileSize: number
|};
export type yesNoCellType = "yes" | "no";

/*
    tableMetaData
*/
export type tableMetaDataType = {|
  id: tableIDType,
  filterGroup: ?filterGroupType,
  sortGroup: ?sortGroupType,
  colorGroup: ?colorGroupType,
  columnWidth: ?columnWidthType
|};

type columnWidthType = {
  [columnIDType]: number
};

export type filterConjunctionType = "and" | "or";

export type filterGroupType = {
  conjunction: filterConjunctionType,
  filters: Array<filterType>
};

export type filterOperatorType = $Keys<typeof filterOperators>;

export const filterOperators = {
  contains: "contains",
  ["not contains"]: "not contains",
  is: "is",
  ["is not"]: "is not",
  ["is empty"]: "is empty",
  ["is not empty"]: "is not empty",
  [">"]: ">",
  ["<"]: "<",
  ["="]: "=",
  ["≠"]: "≠",
  ["≥"]: "≥",
  ["≤"]: "≤"
};

export type filterType = {|
  columnID: ?columnIDType, //we can use columnID to find out column type
  operator: ?filterOperatorType,
  value: ?string
|};

type sortGroupType = {|
  sorts: Array<sortType>
|};

export type sortType = {|
  columnID: ?columnIDType,
  ascending: boolean
|};

type colorGroupType = { [rowIDType]: string };

/**
 *
 * Typing for wispform
 * Used for sync data between wispform and wisptable
 */
export type wispformIDType = string;
