//@flow
import MultiselectComponent from "../../Components/CellComponents/MultiselectComponent";
import MultiselectDataUI from "../../Components/DataUIComponents/MultiselectDataUI";
import {
  type DataType,
  type multipleSelectCellType,
  type filterType,
  type columnConfigType,
  type filterOptionType,
  filterOperators
} from "../index";
import { toArray, toStringArray } from "../../../../Library/Util";

const MultiSelectType = {
  icon: "fa fa-bars",
  cellComponent: MultiselectComponent,
  dataUIComponent: MultiselectDataUI,
  displayName: "Multi Select",
  sortFunction: null,
  filter: {
    operators: ["contains", "not contains"],
    getOptions: getOptions,
    filterFunction: filter,
    optionInputType: "dropdown"
  }
};

function getOptions(
  columnConfig: ?columnConfigType
): Map<any, filterOptionType> {
  if (columnConfig && columnConfig.choices) {
    const optionMap: Array<[any, filterOptionType]> = toStringArray(
      columnConfig.choices
    ).map(choice => [choice, { display: String(choice), value: choice }]);
    return new Map(optionMap);
  } else {
    return new Map([]);
  }
}

function filter(value: ?multipleSelectCellType, filterDetail: filterType) {
  if (filterDetail.operator === filterOperators.contains) {
    return toStringArray(value).includes(filterDetail.value);
  } else if (filterDetail.operator === filterOperators["not contains"]) {
    return !toStringArray(value).includes(filterDetail.value);
  }
  return true;
}

export default MultiSelectType;
