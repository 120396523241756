//@flow
import React from "react";
import "./style.css";
import { ProgressBar } from "react-bootstrap";
import Button from "../../Components/Button";
import "./desktop_style.css";
import PropTypes from "prop-types";
import {
  type formStyleType,
  type footerStyleCssType
} from "../../../../FlowTypes/wispformStyleTypes";
import withConfigurableButtonTextHOC, {
  type InjectedProps as InjectedPropsFromConfigurableButtonTextHOC,
  ButtonTypeFromHOC
} from "../../../../Helper_HOC/WithConfigurableButtonText";
import { UICoreText, UICoreBox } from "../../../../Component/UICore";
import { isMobile } from "../../../../Component/Views/helper";
import withStyles from "../../../../Helper_HOC/WithStyles";

type Props = {
  ...InjectedPropsFromConfigurableButtonTextHOC,
  className: string,
  currentQuestionNumber: number,
  numAnsweared: number,
  previousQuestion: () => any,
  nextQuestion: () => any,
  question_count: number,
  styles: formStyleType,
  footerStyleCss: footerStyleCssType
};

const getMaxWidth = () => {
  if (isMobile()) {
    return 110;
  } else {
    return 250;
  }
};
class UI_Footer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  renderHeaderBarTitle() {
    const percentage = Math.floor(
      (this.props.numAnsweared * 100) / this.props.question_count
    );
    return `${percentage}% ${
      this.props.buttonTextConfig[ButtonTypeFromHOC.progressText]
    }`;
  }

  renderFooterCenter() {
    return (
      <div style={this._textStyle()} className="UI_Footer-progressiveBar-stats">
        {this.renderHeaderBarTitle()}
        <ProgressBar
          now={(this.props.numAnsweared * 100) / this.props.question_count}
        />
      </div>
    );
  }

  _textStyle = () => {
    return {
      color: this.props.styles.text
    };
  };

  render() {
    return (
      <UICoreBox
        dangerous_style={{ ...this.props.footerStyleCss }}
        className={"UI_Footer " + this.props.className}
        direction="row"
        justifyContent="between"
      >
        <div>
          <div
            onClick={this.props.previousQuestion}
            className="UI_Footer-Icon UI_Footer-leftIcon"
            aria-hidden="true"
          >
            <UICoreBox direction="row">
              <UICoreText hexColor={this.props.styles.text}>
                <i className="UI-Footer-fa-direction fa fa-angle-left" />
              </UICoreText>
              <UICoreBox maxWidth={getMaxWidth()} paddingLeft="xm">
                <UICoreText
                  overflow="ellipsis"
                  weight="medium"
                  hexColor={this.props.styles.text}
                >
                  {this.props.buttonTextConfig[ButtonTypeFromHOC.prevButton]}
                </UICoreText>
              </UICoreBox>
            </UICoreBox>
          </div>
        </div>
        <div className="UI_Footer-Center">{this.renderFooterCenter()}</div>
        <div>
          <div
            onClick={this.props.nextQuestion}
            className="UI_Footer-Icon UI_Footer-rightIcon"
            aria-hidden="true"
          >
            <UICoreBox direction="row">
              <UICoreBox maxWidth={getMaxWidth()} paddingRight="xm">
                <UICoreText
                  weight="medium"
                  overflow="ellipsis"
                  hexColor={this.props.styles.text}
                >
                  {this.props.buttonTextConfig[ButtonTypeFromHOC.nextButton]}
                </UICoreText>
              </UICoreBox>
              <UICoreText hexColor={this.props.styles.text}>
                <i className="UI-Footer-fa-direction fa fa-angle-right" />
              </UICoreText>
            </UICoreBox>
          </div>
        </div>
      </UICoreBox>
    );
  }
}

UI_Footer.propTypes = {
  currentQuestionNumber: PropTypes.string.isRequired,
  question_count: PropTypes.number.isRequired,
  previousQuestion: PropTypes.func.isRequired,
  nextQuestion: PropTypes.func.isRequired
};

export default withConfigurableButtonTextHOC(withStyles(UI_Footer));
