//@flow
import * as React from "react";
import "./style.css";
type Props = {||};
type State = {||};

class Placeholder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return <div className="Placeholder" />;
  }
}
export default Placeholder;
