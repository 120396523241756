//@flow
import * as React from "react";
import "./style.css";
import { UICoreText, UICoreBox } from "../../../../../Component/UICore";
import {
  type textCellType,
  type DataComponentPropsType
} from "../../../Configuration";
import TextareaAutosize from "react-autosize-textarea";
import CellPopup from "../../CellPopup";

type Props = {|
  ...DataComponentPropsType,
  updateCell: textCellType => mixed
|};
type State = {|
  showEditor: boolean,
  cellValue: string
|};
class TextComponent extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  state = {
    showEditor: false,
    //we make TextComponent own the value prop
    //so we can update the redux and make api call after blur
    //https://reactjs.org/blog/2018/06/07/you-probably-dont-need-derived-state.html#recommendation-fully-controlled-component
    cellValue: String(this.props.value ? this.props.value : "")
  };

  _showEditor = () => {
    this.setState({
      showEditor: true,
      cellValue: String(this.props.value ? this.props.value : "")
    });
  };

  _handleTextAreaInput = (e: SyntheticInputEvent<>) => {
    const value = e.target.value;
    this.setState({
      cellValue: value
    });
  };

  _handleTextAreaBlur = () => {
    this.props.updateCell(this.state.cellValue);
  };

  _closePopup = () => this.setState({ showEditor: false });

  _renderCellContent = () => {
    if (this.state.showEditor) {
      return (
        <CellPopup width="120%" onDismiss={this._closePopup}>
          <TextareaAutosize
            onBlur={this._handleTextAreaBlur}
            autoFocus
            inputRef={tag => tag.focus()}
            value={this.state.cellValue}
            onChange={this._handleTextAreaInput}
            className="TextComponent-editor"
          />
        </CellPopup>
      );
    } else {
      return (
        <UICoreBox
          onClick={this._showEditor}
          alignItems="start"
          width="100%"
          height="100%"
          padding="xm"
        >
          <UICoreText
            overflow="ellipsis"
            alignment="left"
            size="xs"
            color="darkGray"
          >
            {String(this.props.value ? this.props.value : "")}
          </UICoreText>
        </UICoreBox>
      );
    }
  };

  render() {
    return (
      <UICoreBox
        width="100%"
        height="100%"
        alignItems="center"
        justifyContent="center"
      >
        {this._renderCellContent()}
      </UICoreBox>
    );
  }
}
export default TextComponent;
