//@flow
import { type questionMediaType } from "../FlowTypes/questionTypes";
import { type socialShareConfigType } from "../FlowTypes/thankyouPageTypes";

//TODO: @taoxiang move all type related from questiontypes to this file
export type formIDType = number;

export type welcomePageType = {||};

export type thankyouPageType = {|
  buttonText: string,
  redirectURL: ?string,
  backgroundImage: ?string,
  mediaSpec?: ?questionMediaType,
  thankyouNote: string,
  showScore?: boolean,
  sameTabRedirect?: boolean,
  showSocialShare?: boolean,
  socialShareConfig?: socialShareConfigType
|};

export const operation = {
  image: "image",
  score: "score",
  jumpLogic: "jumpLogic",
  scalerSteps: "scalerSteps",
  imageCompression: "imageCompression",
  scalerLeftUnit: "scalerLeftUnit",
  scalerRightUnit: "scalerRightUnit",
  thankyouPageDisplayLogic: "thankyouPageDisplayLogic",
  dynamicPricing: "dynamicPricing",
  currency: "currency",
  maxWordLimit: "maxWordLimit",
  dateFormat: "dateFormat",
  availableSubscriptionPlans: "availableSubscriptionPlans",
  socialShare: "socialShare",
  outcomeMapping: "outcomeMapping",
  maxOutcome2Display: "maxOutcome2Display",
  outcomeLayout: "outcomeLayout",
  questionValue: "questionValue",
  calculator: "calculator"
};

export type formPlan = "basic" | "Pro" | "Premium";

export type questionIDType = string;
