// @flow
import React, { Component } from "react";
import WithStyles from "../../../Helper_HOC/WithStyles";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import Dropzone from "react-dropzone";
import { getAwsTemporaryCredential as getAwsTemporaryCredentialApi } from "../../../Library/Request";
import AWS from "aws-sdk";
import { AWS_REGION } from "../../../config";
import { validateFileSize } from "../../../Pages/QuestionTypes";
import "./style.css";
import { connect } from "react-redux";
import {
  type awsCredentialType,
  uploadFileToAWS
} from "../../../Library/AwsHelper";
import imageCompression from "browser-image-compression";
import { UICoreText } from "../../UICore";
import { isNonEmptyString } from "../../../Library/Util";
import { isPaidUser } from "../../../Library/UserInfo";
type Props = {|
  styles: formStyleType,
  answerSecondaryColor: string,
  answerPrimaryColor: string,
  onQuestionComplete: submitContentType => mixed,
  isInBuilder?: boolean,
  isFormFromPaidPlan?: boolean,
  contents?: {|
    imageCompressionMaxSizeInMB: number
  |}
|};

type State = {|
  uploadStatus: uploadStatusType,
  fileSize: ?number,
  fileName: ?string,
  fileType: ?recognizedFileType,
  percentageUploaded: ?number
|};

export type submitContentType = {|
  fileSize: ?number,
  fileName: ?string,
  fileType: ?recognizedFileType,
  link: string
|};

type uploadStatusType =
  | "default"
  | "compressing"
  | "gettingCredential"
  | "uploading"
  | "uploaded";

type recognizedFileType = "application/pdf" | "image/jpeg" | "image/png";

const uploadStatus = {
  default: "default",
  compressing: "compressing",
  gettingCredential: "gettingCredential",
  uploading: "uploading",
  uploaded: "uploaded"
};

const animationTime = 600;

class UI_FileUpload extends Component<Props, State> {
  state = {
    uploadStatus: uploadStatus.default,
    fileName: null,
    fileType: null,
    fileSize: null,
    percentageUploaded: 0
  };

  _uploaderNode: ?HTMLDivElement = null;

  _setFileInfo = file => {
    if (!file) {
      return;
    }
    const fileName = file.name;
    const fileType = file.type;
    const fileSize = file.size;
    this.setState({
      fileName,
      // $FlowFixMe
      fileType,
      fileSize
    });
  };

  _applyFlashAnimation = () => {
    this._uploaderNode && this._uploaderNode.classList.add("flashit");
    setTimeout(() => {
      this._uploaderNode && this._uploaderNode.classList.remove("flashit");
    }, animationTime);
  };

  _submit = (link: string) => {
    setTimeout(() => {
      this.props.onQuestionComplete({
        fileSize: this.state.fileSize,
        fileName: this.state.fileName,
        fileType: this.state.fileType,
        link: link
      });
    }, animationTime);
  };

  _uploadFile = (file: File) => {
    const fileSize = file.size;
    this._setFileInfo(file);
    //check file size
    if (isNonEmptyString(validateFileSize({ fileSize }))) {
      this._submit("");
      this.setState({
        uploadStatus: uploadStatus.uploaded
      });
      this._clearUpload();
      return;
    }
    this.setState({
      uploadStatus: uploadStatus.gettingCredential
    });

    uploadFileToAWS(
      file,
      {}, //config
      () => {
        this.setState({
          uploadStatus: uploadStatus.uploading
        });
      },
      event => {
        this.setState({
          percentageUploaded:
            (parseInt(event.loaded) * 100) / parseInt(event.total)
        });
      },
      data => {
        this.setState({
          uploadStatus: uploadStatus.uploaded
        });
        this._applyFlashAnimation();
        this._submit(data.Location);
      }
    );
  };

  _isFileOfImageType = file => {
    const fileType = file && file.type;
    return ["image/jpeg", "image/png"].includes(fileType);
  };

  _fileSizeNeedToCompress = (file: File) => {
    // Only to compress the file if the file size is larger than the target max file size.
    if (
      !this.props.contents ||
      !this.props.contents.imageCompressionMaxSizeInMB
    ) {
      return false;
    }
    if (
      file.size <=
      this.props.contents.imageCompressionMaxSizeInMB * 1000000
    ) {
      return false;
    }
    return true;
  };

  _handleFileDrop = files => {
    const file = files[0];
    // Question configuered to have image compression. Compress the image before uploading.
    if (
      this.props.contents &&
      this.props.contents.imageCompressionMaxSizeInMB &&
      this._isFileOfImageType(file) &&
      this._fileSizeNeedToCompress(file)
    ) {
      const compressOptions = {
        maxSizeMB: this.props.contents.imageCompressionMaxSizeInMB,
        useWebWorker: true
      };
      this.setState({
        uploadStatus: uploadStatus.compressing
      });
      imageCompression(file, compressOptions).then((compressedFile: File) => {
        this.setState({
          uploadStatus: uploadStatus.uploading
        });
        this._uploadFile(compressedFile);
      });
    }
    // If no image compression configured, try upload it normally.
    else {
      this._uploadFile(file);
    }
  };

  _getFileIcon = () => {
    switch (this.state.fileType) {
      case "application/pdf":
        return (
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-file-pdf-o"
          />
        );

      case "image/jpeg":
      case "image/png":
        return (
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-picture-o"
          />
        );

      default:
        return (
          <i
            style={{
              color: this.props.styles.answer
            }}
            className="fa fa-file-text"
          />
        );
    }
  };

  _renderFileLogo = () => {
    return (
      <i
        style={{
          color: this.props.styles.answer
        }}
        className="fa fa-cloud-upload"
      />
    );
  };
  _clearUpload = () => {
    this.setState({
      uploadStatus: uploadStatus.default,
      fileName: null,
      fileType: null,
      percentageUploaded: 0
    });
  };

  _renderUploadedState = () => {
    return [
      <div className="UI_FileUpload-Logo">{this._getFileIcon()}</div>,
      <div className="UI_FileUpload-Text">
        <div>
          <UICoreText
            fontFamily={this.props.styles && this.props.styles.fontFamily}
            overflow="wrap"
            hexColor={this.props.styles.answer}
          >
            {String(this.state.fileName || "")}
          </UICoreText>
        </div>
      </div>,
      <div onClick={this._clearUpload} className="UI_FileUpload-Clear">
        <i className="fa fa-times-circle" />
      </div>
    ];
  };

  _renderCompressingState = () => {
    return [
      <div className="UI_FileUpload-Logo">{this._renderFileLogo()}</div>,
      <div className="UI_FileUpload-Text">
        <div
          style={{
            color: this.props.styles.answer,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
        >
          Compressing...
        </div>
      </div>
    ];
  };

  _isPaidPlan() {
    if (this.props.isInBuilder) {
      return isPaidUser();
    } else {
      return this.props.isFormFromPaidPlan;
    }
  }

  _renderDefaultState = () => {
    return [
      <div className="UI_FileUpload-Logo">{this._renderFileLogo()}</div>,
      <div className="UI_FileUpload-Text">
        <div
          style={{
            color: this.props.styles.answer,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
        >
          Choose a file
        </div>
      </div>,
      <div className="UI_FileUpload-Description">
        <div
          style={{
            color: this.props.answerPrimaryColor,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
        >
          {this._isPaidPlan()
            ? "*file size limit 25 MB"
            : "*file size limit 5 MB"}
        </div>
      </div>
    ];
  };

  _renderUploadingState = () => {
    const width = `${String(this.state.percentageUploaded)}%`;
    return [
      <div className="UI_FileUpload-Logo">{this._renderFileLogo()}</div>,
      <div className="UI_FileUpload-Progress">
        <div
          style={{ backgroundColor: this.props.answerSecondaryColor }}
          className="UI_FileUpload-ProgressBar"
        >
          <div
            style={{ backgroundColor: this.props.styles.answer, width: width }}
            className="UI_FileUpload-ProgressBar-Bar"
          />
        </div>
      </div>
    ];
  };

  _renderGettingCredentialState = () => {
    return [
      <div className="UI_FileUpload-Logo">{this._renderFileLogo()}</div>,
      <div className="UI_FileUpload-GettingCredential">Uploading...</div>
    ];
  };

  _renderUploadContent = () => {
    switch (this.state.uploadStatus) {
      case uploadStatus.default:
        return this._renderDefaultState();
      case uploadStatus.compressing:
        return this._renderCompressingState();
      case uploadStatus.gettingCredential:
        return this._renderGettingCredentialState();
      case uploadStatus.uploaded:
        return this._renderUploadedState();
      case uploadStatus.uploading:
        return this._renderUploadingState();

      default:
        return this._renderDefaultState();
    }
  };

  render() {
    if (
      this.state.uploadStatus === uploadStatus.default &&
      !this.props.isInBuilder
    ) {
      return (
        <Dropzone onDrop={this._handleFileDrop} className="UI_FileUpload-zone">
          {({ getRootProps, getInputProps }) => (
            <div
              {...getRootProps()}
              style={{
                border: `solid 1px ${this.props.styles.answer}`,
                color: this.props.styles.answer,
                backgroundColor: this.props.answerSecondaryColor,
                fontFamily: this.props.styles && this.props.styles.fontFamily
              }}
              ref={node => (this._uploaderNode = node)}
              className={
                "UI_FileUpload " +
                (this.props.isInBuilder ? "" : "UI_FileUpload_Interactable ")
              }
            >
              <input {...getInputProps()} />
              <div className="UI_FileUpload-Content">
                {this._renderUploadContent()}
              </div>
            </div>
          )}
        </Dropzone>
      );
    } else {
      return (
        <div
          style={{
            border: `solid 1px ${this.props.styles.answer}`,
            color: this.props.styles.answer,
            backgroundColor: this.props.answerSecondaryColor,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
          ref={node => (this._uploaderNode = node)}
          className="UI_FileUpload"
        >
          <div className="UI_FileUpload-Content">
            {this._renderUploadContent()}
          </div>
        </div>
      );
    }
  }
}

function isFormFromPaidPlan(state) {
  // Similar to isPro isBusiness isPaid but for forms
  return (
    state.form_plan === "Premium" ||
    state.form_plan === "Pro" ||
    state.form_plan === "Business"
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFormFromPaidPlan: isFormFromPaidPlan(state.Answers)
  };
};

export default WithStyles(connect(mapStateToProps, null)(UI_FileUpload));
