//@flow
import { QuestionTypeEnum } from "../../../Pages/QuestionTypes";
import { operation } from "../../../Pages/WispformTypings";
import BaseFormConfig from "./BaseFormConfig";
import { FinalPageType, PreviewListSection } from "./FormConfigEnums";
import { IFormConfig } from "./IFormConfig";

export default class CalculatorFormConfig
  extends BaseFormConfig
  implements IFormConfig
{
  displayName = "Calculator";
  availableQuestionOperations = [operation.questionValue, operation.calculator];
  previewListSection = [PreviewListSection.QUESTION, PreviewListSection.RESULT];
  notSupportedContentTypes = [QuestionTypeEnum.ThankyouPage];
  creationModalConfig = {
    title: "Create a Calculator Form",
    inputPlaceholder: "new calculator",
    description: `To begin creating a calculator, please first give it a name 😊`,
    enableTemplate: false
  };
}
