//@flow
import * as React from "react";
import "./CellPopupWrapper.css";
import { UICoreBox } from "../../../../Component/UICore";
import OutsideEventBehavior from "../../../../Component/UICore/UICoreFlyOut/OutsideEventBehavior";

type Props = {|
  width?: number | string,
  children: React.Node,
  onDismiss: () => void
|};
type State = {||};
class CellPopupWrapper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return [
      <UICoreBox
        className="CellPopupWrapper-background"
        position="fixed"
        width="100vw"
        height="100vh"
      >
        {""}
      </UICoreBox>,
      <OutsideEventBehavior onClick={this.props.onDismiss}>
        <div
          style={{ width: this.props.width ? this.props.width : 200 }}
          className="CellPopupWrapper"
        >
          {this.props.children}
        </div>
      </OutsideEventBehavior>
    ];
  }
}
export default CellPopupWrapper;
