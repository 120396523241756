//@flow
import * as React from "react";
import "./style.css";
import { toNumber, executeCallbackSafely } from "../../Library/Util";
import { getDaysInMonth } from "./daysInMonth";

export const ArrowDown = "ArrowDown";
export const ArrowLeft = "ArrowLeft";
export const ArrowUp = "ArrowUp";
export const ArrowRight = "ArrowRight";
export const Backspace = "Backspace";
export const Tab = "Tab";
export const Enter = "Enter";

export const DateFormats = {
  MMDDYYYY: "MMDDYYYY",
  DDMMYYYY: "DDMMYYYY",
  YYYMMDD: "YYYMMDD"
};

export const FormattedDateSections = {
  MONTH_SECTION: "MONTH_SECTION",
  DATE_SECTION: "DATE_SECTION",
  YEAR_SECTION: "YEAR_SECTION"
};

const MMDDYYFormate = {
  MONTH_SECTION: { start: 0, end: 2 },
  DATE_SECTION: { start: 3, end: 5 },
  YEAR_SECTION: { start: 6, end: 10 }
};

const DDMMYYFormate = {
  DATE_SECTION: { start: 0, end: 2 },
  MONTH_SECTION: { start: 3, end: 5 },
  YEAR_SECTION: { start: 6, end: 10 }
};

function max12String(value: string): string {
  const numberValue = toNumber(value);
  const maxValue = numberValue > 12 ? 12 : value;
  return String(maxValue);
}

function dateWithinMonthRange(
  day: string,
  month: string,
  year: string
): string {
  const numberDay = toNumber(day);
  if (numberDay > getDaysInMonth(month, year)) {
    return String(getDaysInMonth(month, year));
  } else {
    return day;
  }
}

function nextDateSection(
  currentSection: $Keys<typeof FormattedDateSections>,
  format?: $Keys<typeof DateFormats>
): $Keys<typeof FormattedDateSections> {
  if (!format) {
    format = DateFormats.MMDDYYYY;
  }
  if (format === DateFormats.DDMMYYYY) {
    if (currentSection == FormattedDateSections.DATE_SECTION) {
      return FormattedDateSections.MONTH_SECTION;
    } else if (currentSection == FormattedDateSections.MONTH_SECTION) {
      return FormattedDateSections.YEAR_SECTION;
    } else {
      return currentSection;
    }
  } else if (format === DateFormats.MMDDYYYY) {
    if (currentSection == FormattedDateSections.MONTH_SECTION) {
      return FormattedDateSections.DATE_SECTION;
    } else if (currentSection == FormattedDateSections.DATE_SECTION) {
      return FormattedDateSections.YEAR_SECTION;
    } else {
      return currentSection;
    }
  }
  return FormattedDateSections.DATE_SECTION;
}

function previousDateSection(
  currentSection: $Keys<typeof FormattedDateSections>,
  format?: $Keys<typeof DateFormats>
) {
  if (!format) {
    format = DateFormats.MMDDYYYY;
  }
  if (format === DateFormats.DDMMYYYY) {
    if (currentSection == FormattedDateSections.YEAR_SECTION) {
      return FormattedDateSections.MONTH_SECTION;
    } else if (currentSection == FormattedDateSections.MONTH_SECTION) {
      return FormattedDateSections.DATE_SECTION;
    } else {
      return currentSection;
    }
  } else if (format === DateFormats.MMDDYYYY) {
    if (currentSection == FormattedDateSections.YEAR_SECTION) {
      return FormattedDateSections.DATE_SECTION;
    } else if (currentSection == FormattedDateSections.DATE_SECTION) {
      return FormattedDateSections.MONTH_SECTION;
    } else {
      return currentSection;
    }
  }
}

export class DateSection {
  remainingInput: number;
  name: string;
  defaultFmt: string;
  defaultLength: number;
  constructor(defaultFmt: string, defaultLength: number, name: string) {
    this.name = name;
    this.defaultFmt = defaultFmt;
    this.defaultLength = defaultLength;
    this.remainingInput = defaultLength;
  }
  reset() {
    this.remainingInput = this.defaultLength;
  }
}

interface State {
  values: {
    MONTH_SECTION: DateSection,
    DATE_SECTION: DateSection,
    YEAR_SECTION: DateSection
  };
  selectedDateSection: $Keys<typeof FormattedDateSections>;
}

type DateInputProps = {
  onChange?: string => void,
  dateFormat?: $Keys<typeof DateFormats>,
  onEnter?: () => void,
  value: string,
  style: Object,
  isEditing: boolean
};

// $FlowFixMe
Number["isInteger"] =
  Number["isInteger"] ||
  function (value) {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };

export default class DateInputComponent extends React.Component<
  DateInputProps,
  State
> {
  constructor(props: DateInputProps) {
    super(props);
    this.state = {
      selectedDateSection: FormattedDateSections.MONTH_SECTION,
      values: {
        DATE_SECTION: new DateSection("DD", 2, "date"),
        MONTH_SECTION: new DateSection("MM", 2, "month"),
        YEAR_SECTION: new DateSection("YYYY", 4, "year")
      }
    };
  }

  _dateInputComponent: ?DateInput;

  focusOnFirstSection = () => {
    this._dateInputComponent &&
      executeCallbackSafely(this._dateInputComponent.focusOnFirstSection);
  };

  formattedDate(fmtMonth: string, fmtDay: string, fmtYear: string) {
    if (
      this.props.dateFormat &&
      this.props.dateFormat == DateFormats.DDMMYYYY
    ) {
      return `${fmtDay}/${fmtMonth}/${fmtYear}`;
    } else {
      return `${fmtMonth}/${fmtDay}/${fmtYear}`;
    }
  }
  selectedFormat() {
    if (
      this.props.dateFormat &&
      this.props.dateFormat == DateFormats.DDMMYYYY
    ) {
      return DDMMYYFormate;
    } else {
      return MMDDYYFormate;
    }
  }

  _getDay() {
    const parsedValue = (this.props.value || "").split("-"); // yyyy-mm-dd
    return parsedValue[2] || "DD";
  }

  _getMonth() {
    const parsedValue = (this.props.value || "").split("-"); // yyyy-mm-dd
    return parsedValue[1] || "MM";
  }

  _getYear() {
    const parsedValue = (this.props.value || "").split("-"); // yyyy-mm-dd
    return parsedValue[0] || "YYYY";
  }

  _getValueBySection(section: $Keys<typeof FormattedDateSections>) {
    switch (section) {
      case FormattedDateSections.DATE_SECTION:
        return this._getDay();
      case FormattedDateSections.MONTH_SECTION:
        return this._getMonth();
      case FormattedDateSections.YEAR_SECTION:
        return this._getYear();
    }
    return "";
  }

  dateValue() {
    const date = this.state.values.DATE_SECTION;
    const month = this.state.values.MONTH_SECTION;
    const year = this.state.values.YEAR_SECTION;

    //yyyy-mm-dd

    const fmtDay = !!this._getDay()
      ? this.stringPadding(date.defaultLength, this._getDay())
      : date.defaultFmt;
    const fmtMonth = !!this._getMonth()
      ? this.stringPadding(month.defaultLength, this._getMonth())
      : month.defaultFmt;
    const fmtYear = !!this._getYear()
      ? this.stringPadding(year.defaultLength, this._getYear())
      : year.defaultFmt;
    return this.formattedDate(fmtMonth, fmtDay, fmtYear);
  }

  setNewSelectionRange(section: $Keys<typeof FormattedDateSections>) {
    const selectedSection = this.state.values[FormattedDateSections[section]];
    selectedSection.remainingInput = selectedSection.defaultLength;
    this.state.values[FormattedDateSections[section]] = selectedSection;
    this.state.selectedDateSection = section;
    this.setState(this.state);
  }

  onSelect(start: number, end: number) {
    let selectedSelection;
    const format = this.selectedFormat();
    for (let dateSection in format) {
      if (format.hasOwnProperty(dateSection)) {
        if (
          start >= format[dateSection].start &&
          start <= format[dateSection].end
        ) {
          selectedSelection = FormattedDateSections[dateSection];
        }
      }
    }
    // $FlowFixMe
    this.setNewSelectionRange(selectedSelection);
  }

  onKeydown(key: string, e: Event) {
    let nextSection;
    const selectedIndex = this.state.selectedDateSection;
    const selectedValue = this._getValueBySection(selectedIndex);
    const selectedSection = this.state.values[
      FormattedDateSections[selectedIndex]
    ];

    if (key == ArrowRight) {
      nextSection = this.nextDateSection(selectedIndex);
    } else if (key == ArrowLeft) {
      nextSection = this.previousDateSection(selectedIndex);
    } else if (key == Tab) {
      nextSection = this.nextDateSection(selectedIndex);
      if (selectedIndex === nextSection) {
        return;
      }
    } else if (key == Backspace) {
      if (selectedValue == "") {
        nextSection = this.previousDateSection(selectedIndex);
      } else {
        nextSection = selectedIndex;
        selectedSection.reset();
        this.state.values[
          FormattedDateSections[selectedIndex]
        ] = selectedSection;
        this.setState(this.state);
        this._updateWithSection(selectedIndex, "");
      }
    } else if (key == ArrowDown) {
      nextSection = selectedIndex;
      const selectedSection = this.state.values[
        FormattedDateSections[selectedIndex]
      ];
      if (selectedValue != "" && parseInt(selectedValue) >= 0) {
        const newValue = parseInt(selectedValue) + 1;
        this.state.values[
          FormattedDateSections[selectedIndex]
        ] = selectedSection;
        this.setState(this.state);
        this._updateWithSection(selectedIndex, newValue.toString());
      }
    } else if (key == ArrowUp) {
      nextSection = selectedIndex;
      const selectedSection = this.state.values[
        FormattedDateSections[selectedIndex]
      ];
      if (selectedValue != "" && parseInt(selectedValue) > 0) {
        const newValue = parseInt(selectedValue) - 1;
        this.state.values[
          FormattedDateSections[selectedIndex]
        ] = selectedSection;
        this.setState(this.state);
        this._updateWithSection(selectedIndex, newValue.toString());
      }
    } else if (key == Enter) {
      this.props.onEnter && this.props.onEnter();
    }

    e.preventDefault();
    if (typeof nextSection !== "undefined") {
      this.setNewSelectionRange(nextSection);
    }
  }

  _updateWithSection(
    selectedDateSection: $Keys<typeof FormattedDateSections>,
    value: string
  ) {
    if (this.props.onChange) {
      const year =
        selectedDateSection === FormattedDateSections.YEAR_SECTION
          ? value
          : this._getYear();
      const month =
        selectedDateSection === FormattedDateSections.MONTH_SECTION
          ? value
          : this._getMonth();
      const date =
        selectedDateSection === FormattedDateSections.DATE_SECTION
          ? value
          : this._getDay();
      if (
        (year === "YYYY" || year === "") &&
        (month === "MM" || month === "") &&
        (date === "DD" || date === "")
      ) {
        this.props.onChange("");
      } else {
        // $FlowFixMe: checked
        this.props.onChange(
          `${year}-${max12String(month)}-${dateWithinMonthRange(
            date,
            month,
            year
          )}`
        );
      }
    }
  }

  onChange(keyChar: number, e: any) {
    e.preventDefault();
    let { selectedDateSection } = this.state;

    const section = this.state.values[
      FormattedDateSections[selectedDateSection]
    ];

    let sectionValue = this._getValueBySection(selectedDateSection);

    if (section.remainingInput == section.defaultLength) {
      sectionValue = e.key || e.data;
      section.remainingInput -= 1;
    } else if (section.remainingInput > 0) {
      sectionValue = sectionValue.concat(e.key || e.data);
      section.remainingInput -= 1;
    }

    this.state.values[FormattedDateSections[selectedDateSection]] = section;

    this.setState(this.state);
    this._updateWithSection(selectedDateSection, sectionValue);

    if (section.remainingInput == 0) {
      selectedDateSection = this.nextDateSection(selectedDateSection);
      this.setNewSelectionRange(selectedDateSection);
    }
  }
  stringPadding(length: number, str: string) {
    const pad = "0000".substring(0, length);
    const ans = pad.substring(0, pad.length - str.length) + str;
    return ans;
  }
  nextDateSection(currentSection: $Keys<typeof FormattedDateSections>) {
    return nextDateSection(currentSection, this.props.dateFormat);
  }
  previousDateSection(currentSection: $Keys<typeof FormattedDateSections>) {
    return previousDateSection(currentSection, this.props.dateFormat);
  }
  render() {
    return (
      <DateInput
        ref={ref => (this._dateInputComponent = ref)}
        dateFormate={this.props.dateFormat || DateFormats.MMDDYYYY}
        onChange={(key, e) => {
          this.onChange(key, e);
        }}
        onKeyDown={(keyCode, e) => {
          this.onKeydown(keyCode, e);
        }}
        onSelect={(start, end) => this.onSelect(start, end)}
        value={this.dateValue()}
        selectedDateSection={this.state.selectedDateSection}
        style={this.props.style}
        isEditing={this.props.isEditing}
      />
    );
  }
}

interface InputProps {
  onKeyDown(key: string, e: Event): void;
  onSelect(start: number, end: number): void;
  onChange(keyCode: number, e: Event): void;
  selectedDateSection: $Keys<typeof FormattedDateSections>;
  dateFormate: $Keys<typeof DateFormats>;
  value: any;
  style: Object;
  isEditing: boolean;
}

export class DateInput extends React.Component<InputProps, {}> {
  el: HTMLInputElement;
  getSelectedDateFormat() {
    if (this.props.dateFormate === DateFormats.DDMMYYYY) {
      return DDMMYYFormate;
    } else {
      return MMDDYYFormate;
    }
  }
  componentDidUpdate() {
    if (this.props.isEditing) {
      const { start, end } = this._getSelectionRange();
      this.el.setSelectionRange(start, end);
    }
  }

  focusOnFirstSection = () => {
    const { start, end } = this._getSelectionRange();
    this.el.focus();
    this.el.setSelectionRange(start, end);
  };

  _getSelectionRange = () => {
    const selectedSection =
      FormattedDateSections[this.props.selectedDateSection];
    let selectedFormate = this.getSelectedDateFormat();
    return selectedFormate[selectedSection];
  };

  handleInput = (event: any) => {
    if (this.isSupportedDigits(event.data)) {
      // $FlowFixMe
      this.props.onChange(undefined, event);
    }
  };
  componentDidMount() {
    this.el.addEventListener("input", this.handleInput);
  }
  componentWillUnmount() {
    this.el.removeEventListener("input", this.handleInput);
  }
  isSupportedDigits(key: string) {
    // $FlowFixMe
    return Number["isInteger"](parseInt(key));
  }
  render() {
    const { onKeyDown, onSelect, onChange } = this.props;

    return (
      <input
        // $FlowFixMe
        ref={ref => (this.el = ref)}
        type="text"
        className="DateInput"
        value={this.props.value}
        onChange={e => {}}
        onKeyDown={e => {
          if (this.isSupportedDigits(e.key)) {
            onChange(e.keyCode, e);
          } else {
            onKeyDown(e.key, e);
          }
        }}
        onSelect={e => {
          e.preventDefault();
          const [start, end] = [this.el.selectionStart, this.el.selectionEnd];
          onSelect(start, end);
        }}
        style={this.props.style}
      />
    );
  }
}
