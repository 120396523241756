//@flow
import {
  type tableIDType,
  type rowIDType,
  type tableDataRowType,
  type tableSchemaType,
  type tableSchemaColumnType,
  type tableDatasType,
  type tableMetaDataType,
  type tableType,
  type wispformIDType,
  type columnIDType,
  type cellValueType,
  type cellColumnIdMapType,
  type contactCellType,
  type contactIDType
} from "./Configuration";
import {
  getRequest,
  postRequest,
  patchRequest,
  deleteRequest
} from "../../Library/Request";
import { type AnswerType } from "../QuestionTypes";

/*
  *****************
  *****************
  *****************
    API CALL
  *****************
  *****************
  *****************
*/

/*
  [get] request to get table names and ids
*/
export function getTables(): Promise<Array<tableType>> {
  return getRequest("api/v1/tables").then(
    response => response.data.data.tables
  );
}

/*
  [delete] request to delete a table from backend. (the entire table will be wiped out from db)
*/
export function deleteTable(tableID: tableIDType): Promise<{}> {
  return deleteRequest(`api/v1/tables/${tableID}`).then(
    response => response.data.data
  );
}

/*
  [get] request to get data for current table schema as well as row data
  
*/
export function getTable(tableID: tableIDType): Promise<apiTableReturnType> {
  return getRequest(`api/v1/tables/${tableID}`).then(
    response => response.data.data
  );
}

/*
  [get] request to get table detail 
*/
export function getTableDetail(
  tableID: tableIDType,
  publisherName: string
): Promise<tableSchemaType> {
  return getRequest(`api/v1/table_details/${tableID}`, {
    name: publisherName
  }).then(response => response.data.data.table_detail);
}

/*
  [post] request to submit wisptable form
*/
export function submitWisptableForm(
  cellColumnIdMap: cellColumnIdMapType,
  tableID: tableIDType,
  friendTableID: tableIDType,
  friendRowID: rowIDType,
  friendColumnID: columnIDType,
  contactID: contactIDType,
  publisherName: string
): Promise<tableDataRowType> {
  return postRequest(`api/v1/table_details`, {
    table_id: tableID,
    cellColumnIdMap: cellColumnIdMap,
    friend_row_id: friendRowID,
    friend_column_id: friendColumnID,
    contact_id: contactID,
    friend_table_id: friendTableID,
    publisher_name: publisherName
  }).then(response => response.data.data.row);
}

export type wispformResponseType = {|
  created_at: string,
  form_id: number,
  id: number,
  response_detail: Array<AnswerType>,
  updated_at: string
|};

export function getWispformResponses(
  formID: wispformIDType,
  responseIDs: Array<number>
): Promise<Array<wispformResponseType>> {
  return getRequest(
    `api/v1/forms/${formID}/responses/${responseIDs.join(",")}`
  ).then(response => response.data.data.responses);
}

/*
  [patch] request to patch data for current table schema as well as row data
  
*/
export function updateTableName(
  tableID: tableIDType,
  tableName: string
): Promise<{}> {
  return patchRequest(`api/v1/tables/${tableID}`, {
    name: tableName
  });
}

/*
  [get] request to get all wispforms 
 */
type workspaceNameType = string;
type formIDType = string;
export type formWorkspaceInfoType = {|
  form_name: string,
  form_uuid: string,
  workspace_name: string
|};
export type apiFormsAndWorkspacesreturnType = Array<formWorkspaceInfoType>;

export function getFormsAndWorkspaces(): Promise<apiFormsAndWorkspacesreturnType> {
  return getRequest("api/v1/get_forms_and_workspaces").then(response => {
    return response.data.data.forms;
  });
}

/*
  [get] request to get linking record
*/
export function getRow(
  tableID: tableIDType,
  rowID: rowIDType
): ?Promise<getRowReturnType> {
  return getRequest("api/v1/rows/" + rowID, {
    table_id: tableID
  }).then(response => response.data.data);
}

type getRowReturnType = tableDataRowType;

/**
 * [post] request to sync wisptable data from a given wispform
 */

export function addTableFromWispform(
  wispformID: wispformIDType
): Promise<tableType> {
  return postRequest("api/v1/sync_form_responses_to_table", {
    id: wispformID
  }).then(response => {
    return response.data.data.table;
  });
}

/**
 * [post] request to crete an empty table
 */
export function createEmptyTable(): Promise<tableType> {
  return postRequest("api/v1/tables", {}).then(
    response => response.data.data.table
  );
}

/*
  [post] request to create a new column, modify existed column.etc.
*/
type updateSchemaReturnType = {|
  msg: "SUCCESS" | "FAILED",
  data: {}
|};

export function updateSchema(
  tableID: tableIDType,
  columns: Array<tableSchemaColumnType>
): Promise<updateSchemaReturnType> {
  return postRequest("api/v1/update_columns", {
    id: tableID,
    columns: columns
  });
}

/*
  [patch] request to update a row
*/
type updateRowReturnType = {|
  msg: "Row update successful" | "Row update failed"
|};
export function updateRow(
  tableID: tableIDType,
  rowID: rowIDType,
  cellColumnIdMap: cellColumnIdMapType
): Promise<updateRowReturnType> {
  return patchRequest(`api/v1/rows/${rowID}`, {
    table_id: tableID,
    cellColumnIdMap: cellColumnIdMap
  }).then(response => {
    return { msg: response.data.meta };
  });
}

/*
  [post] request to create a new empty row
*/

export function createRow(tableID: tableIDType): Promise<tableDataRowType> {
  return postRequest(`api/v1/rows`, {
    table_id: tableID,
    cellColumnIdMap: {}
  }).then(response => response.data.data.row);
}

/*
  [delete] request to delete rows in batch
*/
export function deleteRow(
  rowIDs: Array<rowIDType>,
  tableID: tableIDType
): Promise<any> {
  //first rowID is just a placeholder for the backend
  return deleteRequest(`api/v1/rows/${rowIDs[0]}`, {
    row_list: rowIDs,
    table_id: tableID
  });
}

/*
  [post] request to create, update, delete table meta data.
*/
export function saveTableMetaDatas(
  tableID: tableIDType,
  tableMetaDatas: tableMetaDataType
): saveTableMetaDatasReturnType {
  return postRequest("api/v1/update_table_meta_datum", {
    id: tableID,
    meta_data_detail: tableMetaDatas
  }).then(response => response);
}
type saveTableMetaDatasReturnType = Promise<{|
  msg: "meta data updated successful" | "meta data update failed",
  data: {}
|}>;

/*
  *****************
  *****************
  *****************
    DATA STRUCTURE
  *****************
  *****************
  *****************
*/
export type apiTablesReturnType = {|
  tables: Array<tableType>
|};

export type apiTableReturnType = {|
  table_detail: ?tableSchemaType, //only tableSchema of current viewing table
  rows: tableDatasType, //only tabledata of current viewing table
  table_meta_datum: ?{ detail: tableMetaDataType } //only table metadata of current viewing table
|};

/*
  [get] request to get contacts 
*/
export function getContacts(): Promise<Array<contactCellType>> {
  return getRequest("api/v1/contacts").then(response => {
    return response.data.data.contacts;
  });
}

/*
  [post] request to create new contact
*/
export function createContact(
  name: string,
  email: string
): Promise<contactCellType> {
  return postRequest("api/v1/contacts", { name, email }).then(
    response => response.data.data.contact
  );
}

/*
  [patch] request to update new contact
*/
export function updateContact(
  id: contactIDType,
  name: string,
  email: string
): Promise<contactCellType> {
  return patchRequest(`api/v1/contacts/${id}`, { name, email }).then(
    response => response.data.data.contact
  );
}

/*
  [post] request to send form view url to contacts
*/
type contactInfo = [string, string, string];
export function sendFormsToContacts(
  receivers: Array<contactInfo>
): Promise<any> {
  return postRequest(`api/v1/send_form_from_table`, {
    email_list: receivers
  });
}
