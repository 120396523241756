//@flow
import * as React from "react";
import {
  type columnConfigType,
  type contactCellType,
  type contactIDType,
  type cellValueType,
  type DataComponentPropsType
} from "../../../Configuration";
import "./style.css";
import {
  UICoreText,
  UICoreBox,
  UICoreSearchBox,
  UICoreInput,
  UICoreButton
} from "../../../../../Component/UICore";
import CellPopup from "../../CellPopup";
import withContacts from "./withContacts";
import ContactInfo from "./ContactInfo";
import { isNonEmptyArray } from "../../../../../Library/Util";
import { Scrollbars } from "react-custom-scrollbars";

const CellState = {
  cell: "cell",
  selectContact: "selectContact",
  editContact: "editContact",
  createContact: "createContact"
};

type Props = {|
  ...DataComponentPropsType,
  contacts: Array<contactCellType>,
  getContacts: () => void,
  updateContact: (contactIDType, string, string) => Promise<contactCellType>,
  createContact: (string, string) => Promise<contactCellType>,
  updateCell: cellValueType => mixed
|};
type State = {|
  contactSearchTearm: string,
  cellUIStage: $Keys<typeof CellState>,
  createNameInput: string,
  createEmailInput: string,
  updateNameInput: string,
  updateEmailInput: string
|};

class ContactComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      cellUIStage: CellState.cell,
      contactSearchTearm: "",
      createNameInput: "",
      createEmailInput: "",
      updateNameInput: "",
      updateEmailInput: ""
    };
  }

  _showEditContactUI = () =>
    this.setState({
      cellUIStage: CellState.editContact
    });

  _showSelectContactUI = () =>
    this.setState({
      cellUIStage: CellState.selectContact
    });

  _prefillContactEditForm = () => {
    if (
      this.props.value &&
      !Array.isArray(this.props.value) &&
      typeof this.props.value === "object" &&
      this.props.value.name &&
      this.props.value.email
    ) {
      const name = this.props.value.name;
      const email = this.props.value.email;
      this.setState({
        updateNameInput: name,
        updateEmailInput: email
      });
    }
  };

  _hasContact = (): boolean => {
    if (this.props.value && this.props.value.name && this.props.value.email) {
      return true;
    } else {
      return false;
    }
  };

  _handleCellClick = () => {
    if (this._hasContact()) {
      this._prefillContactEditForm();
      this._showEditContactUI();
    } else {
      this.props.getContacts();
      this._showSelectContactUI();
    }
  };

  _handleContactSearch = (e, value) => {
    this.setState({
      contactSearchTearm: value
    });
  };

  _closeCellPopup = () => this.setState({ cellUIStage: CellState.cell });

  _searchTearm = (contact: contactCellType) =>
    contact.name
      .toLowerCase()
      .includes(this.state.contactSearchTearm.toLowerCase()) ||
    contact.email
      .toLowerCase()
      .includes(this.state.contactSearchTearm.toLowerCase());

  _searchTearmExactMatchContact = () =>
    this.props.contacts.some(
      contact =>
        contact.name.toLowerCase() ===
          this.state.contactSearchTearm.toLowerCase() ||
        contact.email.toLowerCase() ===
          this.state.contactSearchTearm.toLowerCase()
    );

  _handleContactClick = (contact: contactCellType) => {
    this.props.updateCell(contact);
    this._closeCellPopup();
  };

  _renderAvailableContacts = () => {
    return this.props.contacts.filter(this._searchTearm).map(contact => (
      <UICoreBox
        paddingTop="xxm"
        paddingBottom="xxm"
        paddingLeft="xm"
        paddingRight="xm"
        hoverable={true}
        onClick={() => this._handleContactClick(contact)}
      >
        <UICoreBox>
          <UICoreText weight="bold" size="xs">
            {contact.name}
          </UICoreText>
        </UICoreBox>
        <UICoreBox>
          <UICoreText size="xxs" color="Grey">
            {contact.email}
          </UICoreText>
        </UICoreBox>
      </UICoreBox>
    ));
  };

  _showCreateContactUI = () => {
    this.setState({
      cellUIStage: CellState.createContact
    });
  };

  _handleNewContactNameInput = (value: string) => {
    this.setState({
      createNameInput: value
    });
  };

  _handleNewContactEmailInput = (value: string) => {
    this.setState({
      createEmailInput: value
    });
  };

  _handleUpdateContactNameInput = (value: string) => {
    this.setState({
      updateNameInput: value
    });
  };

  _handleUpdateContactEmailInput = (value: string) => {
    this.setState({
      updateEmailInput: value
    });
  };

  _handleCreateNewContactButtonClick = () => {
    //if no search tearm, just show the create contact UI
    if (this.state.contactSearchTearm === "") {
      this._showCreateContactUI();
      return;
    }
    //prefill email
    if (this.state.contactSearchTearm.includes("@")) {
      this.setState({
        createEmailInput: this.state.contactSearchTearm
      });
    } else {
      //prefill name
      this.setState({
        createNameInput: this.state.contactSearchTearm
      });
    }
    this._showCreateContactUI();
  };

  _renderAddNewContact = () => {
    if (
      this.state.contactSearchTearm != "" &&
      !this._searchTearmExactMatchContact()
    ) {
      return (
        <UICoreBox
          onClick={this._handleCreateNewContactButtonClick}
          hoverable={true}
          padding="xm"
          direction="row"
        >
          <UICoreText size="xs" color="darkGray">
            create contact
          </UICoreText>
          <UICoreBox
            color="lightBlue"
            shape="rounded"
            paddingLeft="xm"
            paddingRight="xm"
          >
            <UICoreText size="xs">{this.state.contactSearchTearm}</UICoreText>
          </UICoreBox>
        </UICoreBox>
      );
    } else return null;
  };

  _hasAvailableContacts = (): boolean => {
    return isNonEmptyArray(this.props.contacts);
  };

  _renderAddContactButton = () => {
    return (
      <UICoreBox paddingLeft="xm" paddingRight="xm">
        <UICoreButton onClick={this._handleCreateNewContactButtonClick}>
          Add contact
        </UICoreButton>
      </UICoreBox>
    );
  };

  _renderSelectContactUI = () => {
    return (
      <CellPopup width={250} onDismiss={this._closeCellPopup}>
        <UICoreBox paddingTop="xm" paddingBottom="xm">
          <UICoreBox paddingLeft="xm" paddingRight="xm">
            <UICoreSearchBox
              value={this.state.contactSearchTearm}
              onChange={this._handleContactSearch}
              placeholder="Find or create a contact"
            />
          </UICoreBox>
          <UICoreBox marginTop="xm">
            <Scrollbars autoHeight autoHeightMax={200}>
              {this._hasAvailableContacts()
                ? this._renderAvailableContacts()
                : this._renderAddContactButton()}
            </Scrollbars>
          </UICoreBox>
          {this._renderAddNewContact()}
        </UICoreBox>
      </CellPopup>
    );
  };

  _handleRemoveContactButtonClick = () => {
    this.props.updateCell("");
    this._closeCellPopup();
  };

  _renderEditContactUI = () => {
    return (
      <CellPopup width={250} onDismiss={this._closeCellPopup}>
        <UICoreBox padding="xm">
          <ContactInfo
            onNameChange={this._handleUpdateContactNameInput}
            onEmailChange={this._handleUpdateContactEmailInput}
            name={this.state.updateNameInput}
            email={this.state.updateEmailInput}
          />
          <UICoreBox direction="row" justifyContent="end" marginTop="xm">
            <UICoreBox>
              <UICoreButton
                onClick={this._handleRemoveContactButtonClick}
                color="lightBlue"
                size="sm"
              >
                Remove
              </UICoreButton>
            </UICoreBox>
            <UICoreBox marginLeft="xm">
              <UICoreButton
                onClick={this._updateContact}
                color="lightBlue"
                size="sm"
              >
                Update
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </CellPopup>
    );
  };

  _createNewContact = () => {
    this.props
      .createContact(this.state.createNameInput, this.state.createEmailInput.trim())
      .then((contact: contactCellType) => {
        this.props.updateCell({
          name: contact.name,
          email: contact.email,
          id: contact.id
        });
        this._closeCellPopup();
      });
  };

  _updateContact = () => {
    if (!this.props.value || typeof this.props.value.id !== "number") {
      return;
    }
    this.props
      .updateContact(
        this.props.value.id,
        this.state.updateNameInput,
        this.state.updateEmailInput
      )
      .then((contact: contactCellType) => {
        this.props.updateCell({
          name: contact.name,
          email: contact.email,
          id: contact.id
        });
        this._closeCellPopup();
      });
  };

  _renderCreateContactUI = () => {
    return (
      <CellPopup width={250} onDismiss={this._closeCellPopup}>
        <UICoreBox padding="xm">
          <ContactInfo
            onNameChange={this._handleNewContactNameInput}
            onEmailChange={this._handleNewContactEmailInput}
            name={this.state.createNameInput}
            email={this.state.createEmailInput}
          />
          <UICoreBox direction="row" justifyContent="end" marginTop="xm">
            <UICoreBox marginLeft="xm">
              <UICoreButton
                onClick={this._createNewContact}
                color="lightBlue"
                size="sm"
              >
                Add
              </UICoreButton>
            </UICoreBox>
          </UICoreBox>
        </UICoreBox>
      </CellPopup>
    );
  };

  _renderCellUI = () => {
    if (this.props.value && typeof this.props.value.name === "string") {
      return (
        <UICoreBox
          padding="xm"
          direction="row"
          alignItems="center"
          justifyContent="start"
          width="100%"
          height="100%"
          onClick={this._handleCellClick}
          overflowX="hidden"
          overflowY="hidden"
        >
          <UICoreBox
            paddingLeft="xm"
            paddingRight="xm"
            shape="rounded"
            color="lightGrey"
          >
            <UICoreText size="xs">{this.props.value.name}</UICoreText>
          </UICoreBox>
        </UICoreBox>
      );
    } else {
      return (
        <UICoreBox
          padding="xm"
          direction="row"
          alignItems="center"
          justifyContent="start"
          width="100%"
          height="100%"
          onClick={this._handleCellClick}
        >
          {""}
        </UICoreBox>
      );
    }
  };

  render() {
    if (this.state.cellUIStage === CellState.cell) {
      return this._renderCellUI();
    } else if (this.state.cellUIStage === CellState.selectContact) {
      return this._renderSelectContactUI();
    } else if (this.state.cellUIStage === CellState.editContact) {
      return this._renderEditContactUI();
    } else if (this.state.cellUIStage === CellState.createContact) {
      return this._renderCreateContactUI();
    } else {
      return null;
    }
  }
}
export default withContacts(ContactComponent);
