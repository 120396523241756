// @flow
import * as React from "react";
import { executeCallbackSafely, toUnNullable } from "../../../Library/Util";
import UICoreBox from "../UICoreBox";
import UICoreText from "../UICoreText";
type Props = {|
  /* attributes */
  initValue?: number, // between 0 - 100
  sliderTrackWidth?: number,
  leftLabel?: string,
  rightLabel?: string,
  bottomLabel?: string,
  /* functions */
  onScalerDragging: number => void,
  onScalerDragEnd?: number => void
|};
type State = {|
  percentage: number
|};

type sliderTrackPosType = {|
  left: number,
  right: number,
  length: number
|};

const kSliderWidth = 18;
const kSliderTrackHeight = 5;
const kDefaultSliderTrackWidth = 200;

class UICoreScaler extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      percentage: props.initValue || 0
    };
  }

  _slider: ?HTMLElement = null;

  _sliderTrack: ?HTMLElement = null;

  _sliderContainer: ?HTMLElement = null;

  _container: ?HTMLElement = null;

  _initialTouchPointPosX: number = 0;

  _initialSliderLeft: number = 0;

  _draggingStarted = false;

  _sliderTrackPos: sliderTrackPosType = {
    left: 0,
    right: 0,
    length: 0
  };

  _setSliderTrackPosition = () => {
    if (!this._sliderTrack) return;
    const sliderTrackRec = this._sliderTrack.getBoundingClientRect();
    this._sliderTrackPos.left = sliderTrackRec.left;
    this._sliderTrackPos.length = sliderTrackRec.width;
    this._sliderTrackPos.right = sliderTrackRec.left + sliderTrackRec.width;
  };

  _handlePointerDown = (e: Event) => {
    this._draggingStarted = true;
    // $FlowFixMe
    this._initialTouchPointPosX = e.clientX;
    this._initialSliderLeft =
      // $FlowFixMe
      this._slider.getBoundingClientRect().left - this._sliderTrackPos.left;
  };

  _getSliderClientX = (movement: number): number => {
    //for flow typing
    if (!this._sliderTrackPos || !this._slider) return 0;

    const targetSliderLeft = this._initialSliderLeft + movement;
    // moved out of slider bar
    if (targetSliderLeft < 0) return 0;
    // mover out of slider bar
    if (targetSliderLeft + kSliderWidth > this._sliderTrackPos.length)
      return this._sliderTrackPos.length - kSliderWidth;
    // moved within range
    return targetSliderLeft;
  };

  _updatePercentage = (sliderLeft: number) => {
    const range = this._sliderTrackPos.length - kSliderWidth;
    const percentage = Math.round((sliderLeft / range) * 100);
    this.setState({
      percentage: percentage
    });
    this.props.onScalerDragging(percentage);
  };

  _handlePointerMove = (e: Event) => {
    if (!this._draggingStarted) return;
    e.stopPropagation();
    e.preventDefault();

    // Only slider is draggable
    // if (e.target != this._slider) return;
    // $FlowFixMe
    const movement = e.clientX - this._initialTouchPointPosX;
    const targetSliderLeft = this._getSliderClientX(movement);
    this._updatePercentage(targetSliderLeft);
    // $FlowFixMe
    this._slider.style.transform = `translateX(${targetSliderLeft}px)`;
  };

  _handlePointerUp = (e: Event) => {
    this._draggingStarted = false;
    this.props.onScalerDragEnd &&
      this.props.onScalerDragEnd(this.state.percentage);
  };

  _handlePointerLeave = (e: Event) => {
    this._draggingStarted = false;
    this.props.onScalerDragEnd &&
      this.props.onScalerDragEnd(this.state.percentage);
  };

  _setSliderPosition = () => {
    if (!this._slider) return;
    toUnNullable(
      this._slider
    ).style.transform = `translateX(${this._getInitSliderX()}px)`;
  };

  componentDidMount() {
    this._setSliderTrackPosition();
    this._setSliderPosition();
    this._slider &&
      this._slider.addEventListener("pointerdown", this._handlePointerDown);
    this._container &&
      this._container.addEventListener("pointermove", this._handlePointerMove);
    this._container &&
      this._container.addEventListener("pointerup", this._handlePointerUp);
    this._container &&
      this._container.addEventListener(
        "pointerleave",
        this._handlePointerLeave
      );
  }

  _getTrackWidth = () => {
    return this.props.sliderTrackWidth || kDefaultSliderTrackWidth;
  };

  _getSliderWidth = () => {
    return kSliderWidth;
  };

  _getInitSliderX = () => {
    const initPrecentage = this.props.initValue || 0;
    const range = this._getTrackWidth() - this._getSliderWidth();
    return (range * initPrecentage) / 100;
  };

  render() {
    return (
      <UICoreBox
        width={this._getTrackWidth()}
        innerRef={ref => {
          this._container = ref;
        }}
      >
        <UICoreBox
          name="Slider Track"
          shape="rounded"
          height={kSliderTrackHeight}
          width={this.props.sliderTrackWidth || kDefaultSliderTrackWidth}
          color="lightGrey"
          position="relative"
          innerRef={e => {
            this._sliderTrack = e;
          }}
        >
          <UICoreBox
            name="Slider"
            position="absolute"
            top={`-${(kSliderWidth - kSliderTrackHeight) / 2}px`}
            left="0px"
            width={kSliderWidth}
            height={kSliderWidth}
            hexColor="#515151"
            shape="circle"
            justifyContent="center"
            alignItems="center"
            innerRef={e => {
              this._slider = e;
            }}
            children={null}
          ></UICoreBox>
        </UICoreBox>

        <UICoreBox
          marginTop="sm"
          direction="row"
          width="100%"
          justifyContent={
            this.props.leftLabel && this.props.rightLabel ? "between" : "center"
          }
        >
          {this.props.leftLabel && (
            <UICoreBox>
              <UICoreText size="xs">{this.props.leftLabel}</UICoreText>
            </UICoreBox>
          )}
          <UICoreBox>
            <UICoreText size="xs" weight="medium" color="black">
              {`${
                this.props.bottomLabel ? this.props.bottomLabel + " - " : ""
              }${this.state.percentage}%`}
            </UICoreText>
          </UICoreBox>
          {this.props.rightLabel && (
            <UICoreBox>
              <UICoreText size="xs">{this.props.rightLabel}</UICoreText>
            </UICoreBox>
          )}
        </UICoreBox>
      </UICoreBox>
    );
  }
}
export default UICoreScaler;
