//@flow

import React, { Component } from "react";
import ClickFlash from "../../Animations/ClickFlash";
import WithStyles from "../../../Helper_HOC/WithStyles";
import TextareaAutosize from "react-autosize-textarea";
import { type formStyleType } from "../../../FlowTypes/wispformStyleTypes";
import "./style.css";
import "./style.mobile.css";

const CHOICE_EDITOR_PLACEHOLDER = "Type a choice here...";

type Props = {|
  answerPrimaryColor: string,
  answerSecondaryColor: string,
  styles: formStyleType,
  setContentChoiceNode: ?(?HTMLInputElement) => any,
  isChoiceSelected: boolean,
  isInBuilder: boolean,
  prev_answer: Array<any>,
  onClick: (any, boolean) => any,
  index: number,
  choiceContent: string,
  className: string
|};

class UI_Choice extends Component<Props> {
  choiceNode: ?HTMLDivElement;

  convertIndexToLetter(index) {
    return String.fromCharCode(65 + index);
  }

  render() {
    const choiceBackgroundColor = this.props.isChoiceSelected
      ? this.props.answerPrimaryColor
      : this.props.answerSecondaryColor;
    const choiceBorder = this.props.isChoiceSelected ? "2px" : "1px";

    if (this.props.isInBuilder) {
      return (
        <div
          /*onClick={this.handleChoiceClicked.bind(this)}*/
          className={"UI_Choice " + this.props.className}
          onMouseOver={e =>
            this.choiceNode &&
            (this.choiceNode.style.backgroundColor = this.props.answerPrimaryColor)
          }
          onMouseLeave={e =>
            this.choiceNode &&
            (this.choiceNode.style.backgroundColor = choiceBackgroundColor)
          }
          ref={e => (this.choiceNode = e)}
          style={{
            color: this.props.styles.answer,
            borderColor: this.props.styles.answer,
            backgroundColor: choiceBackgroundColor,
            borderWidth: choiceBorder,
            fontFamily: this.props.styles && this.props.styles.fontFamily
          }}
        >
          <div
            style={{
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
            className="UI_Choice-index"
          >
            {this.convertIndexToLetter(this.props.index)}
          </div>
          {!this.props.choiceContent && (
            <span
              style={{
                fontFamily: this.props.styles && this.props.styles.fontFamily
              }}
              className="UI_Choice-content-placeholder"
            >
              {CHOICE_EDITOR_PLACEHOLDER}
            </span>
          )}
          <TextareaAutosize
            innerRef={(e: ?HTMLInputElement) =>
              this.props.setContentChoiceNode &&
              this.props.setContentChoiceNode(e)
            }
            style={{
              fontFamily: this.props.styles && this.props.styles.fontFamily
            }}
            className="UI_Choice-content UI_Choice-content-input"
            value={this.props.choiceContent}
          />
        </div>
      );
    } else {
      return (
        <ClickFlash onClick={this.props.onClick}>
          <div
            /*onClick={this.handleChoiceClicked.bind(this)}*/
            className={"UI_Choice " + this.props.className}
            onMouseOver={e =>
              this.choiceNode &&
              (this.choiceNode.style.backgroundColor = this.props.answerPrimaryColor)
            }
            onMouseLeave={e =>
              this.choiceNode &&
              (this.choiceNode.style.backgroundColor = choiceBackgroundColor)
            }
            ref={e => (this.choiceNode = e)}
            style={{
              color: this.props.styles.answer,
              borderColor: this.props.styles.answer,
              backgroundColor: choiceBackgroundColor,
              borderWidth: choiceBorder
            }}
          >
            <div
              style={{
                fontFamily: this.props.styles && this.props.styles.fontFamily
              }}
              className="UI_Choice-index"
            >
              {this.convertIndexToLetter(this.props.index)}
            </div>
            <div className="UI_Choice-content">
              <div
                style={{
                  fontFamily: this.props.styles && this.props.styles.fontFamily
                }}
                className="UI_Choice-content-text"
              >
                {" "}
                {" " + this.props.choiceContent}
              </div>
            </div>
          </div>
        </ClickFlash>
      );
    }
  }
}

export default WithStyles(UI_Choice);
