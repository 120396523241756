// @flow

import { toArray, safeGet, isNullOrUndefined, nonNullOrThrow } from "./Util";

export function isFormPage() {
  const path = safeGet(_ => window.location.pathname.split("/")[1]);
  if (
    path == "form" ||
    path == "workspace" ||
    path == "try" ||
    path == "test" ||
    path == "view"
  ) {
    return false;
  } else {
    // form ID should always be 8
    return path && path.length === 8;
  }
}

export function constructURL(
  origin: string,
  pathName: string,
  params: { [string]: string }
): string {
  const paramList = Object.keys(params)
    .map(name => `${name}=${encodeURIComponent(params[name])}`)
    .join("&");
  return `${origin}${pathName}?${paramList}`;
}

export function searchStringToMap(searchString: string): { [string]: string } {
  try {
    const searchStringWithoutQuestionMark =
      searchString[0] === "?" ? searchString.slice(1) : searchString;
    return JSON.parse(
      '{"' +
        searchStringWithoutQuestionMark
          .replace(/&/g, '","')
          .replace(/=/g, '":"') +
        '"}',
      function (key, value) {
        return key === "" ? value : decodeURIComponent(value);
      }
    );
  } catch (error) {
    return {};
  }
}

export function isUrlParamAllowed(paramName: ?string) {
  if (isNullOrUndefined(paramName)) {
    return false;
  }
  return nonNullOrThrow(paramName).match("^[A-Za-z0-9-_.!~*'()]*$");
}
