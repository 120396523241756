import { throttle } from "../../../../Library/Primitives/Function";
import { FormPageContainerMobileLogoContainerClassName } from "../../Containers/FormPageContainer";

let activeQuestionID: ?number = null;

function getQuestion(id) {
  const class_id = "wispform" + id;
  return document.getElementById(class_id);
}

function removeActiveClass() {
  const class_name = "active";
  const active_nodes = document.getElementsByClassName(class_name);
  Array.prototype.forEach.call(active_nodes, node => {
    node.classList.remove(class_name);
  });
}

export function setActiveQuestion(id, onActiveQuestionChange: number => void) {
  if (activeQuestionID === id) {
    // no-op
  } else {
    activeQuestionID = id;
    onActiveQuestionChange(id);
  }
  removeActiveClass();
  const class_name = "active";
  const node = getQuestion(id);
  node && node.classList.add(class_name);
}

export function findCurrentQuestion() {
  const questions = document.getElementsByClassName("Question");
  const length = questions.length;
  for (let i = 0; i < length; i++) {
    const question = questions[i];
    const top = question.getBoundingClientRect().top;
    const bottom = question.getBoundingClientRect().bottom;
    const middle = window.innerHeight / 2;
    if (top <= middle && bottom >= middle) {
      return i;
    }
  }
  return null;
}

export function getCurrentQuestion() {
  const id = findCurrentQuestion();
  return document.getElementsByClassName("Question")[id];
}

function getContainer() {
  return document.getElementsByClassName("Questions")[0].parentElement
    .parentElement;
}

export function handleScroll(
  setCurrentquestion,
  onActiveQuestionChange: number => void
) {
  //because we wrap the questions with scrollable component,
  //we need to go extra two layrs to get container
  const container = getContainer();
  container.addEventListener("scroll", function handleScroll() {
    let id = findCurrentQuestion();
    setActiveQuestion(id, onActiveQuestionChange);
  });
}

export function getInnerHeight(e) {
  const style = getComputedStyle(e);
  const height = parseInt(style.height);
  const padding_top = parseInt(style.paddingTop);
  const padding_bottom = parseInt(style.paddingBottom);
  return height - (padding_top + padding_bottom);
}

export function getPaddingTop(e) {
  const style = getComputedStyle(e);
  const padding_top = parseInt(style.paddingTop);
  return padding_top;
}

function getWarningHeight(question) {
  const first_element = question.children[0];
  if (first_element && first_element.className == "Question-Warning-Wrapper") {
    return first_element.clientHeight;
  } else {
    return 0;
  }
}

function isCustomBrandingLogoVisible() {
  const logoContainer = document.getElementsByClassName(
    FormPageContainerMobileLogoContainerClassName
  )[0];
  return logoContainer !== undefined;
}

export function questionPosition(question) {
  if (!question) {
    return 0;
  }
  const top = question.getBoundingClientRect().top;
  const window_height = window.innerHeight;
  const question_height = question.clientHeight;
  const inner_height = getInnerHeight(question);
  const warning_height = getWarningHeight(question);
  const logoSpace = 50;
  const footerHeight = 55;
  const potentialCustomBrandingLogoHeight = isCustomBrandingLogoVisible()
    ? logoSpace
    : 0;
  /*
        if element is smaller than window
        position it in center

        add warining_height to position the question and wraning at center
    */
  if (
    footerHeight +
      inner_height +
      warning_height +
      potentialCustomBrandingLogoHeight <
    window_height
  ) {
    const target_top = (window_height - question_height + warning_height) / 2;
    return top - target_top;
  } else {
    /*
        if element is bigger than window
        positin it at top

        minus warning to prevent warning from hiding behind scene
    */
    const top_space = 50;
    return (
      top +
      getPaddingTop(question) -
      top_space -
      warning_height -
      potentialCustomBrandingLogoHeight
    );
  }
}
//scroll to specific question
export function scrollToQuestion(id) {
  const question = getQuestion(id);
  const container = getContainer();
  const position = container.scrollTop + questionPosition(question);
  scrollTo(container, position, 200);
}

function scrollTo(element, to, duration) {
  var start = element.scrollTop,
    change = to - start,
    currentTime = 0,
    increment = 20;

  var animateScroll = function () {
    currentTime += increment;
    var val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
}

//t = current time
//b = start value
//c = change in value
//d = duration
function easeInOutQuad(t, s, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + s;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + s;
}

export function scrollToSubmitButton() {
  const container = getContainer();
  scrollTo(container, container.scrollHeight, 400);
}
