//@flow
import * as React from "react";
import UICoreText, { type Props as UICoreTextProps } from "../UICoreText";
import { type inputType } from "../UICoreInput";
import "./style.css";
import { UICoreBox, UICoreInput, UICoreTextArea } from "..";
import TryoutPage from "../../../Pages/TryoutPage/state/reducer";
import { width } from "window-size";
type Props = {|
  ...UICoreTextProps,
  width?: number | string,
  maxWidth?: number | string,
  allowMultipleLines?: boolean,
  autoFocus?: boolean,
  autoSelect?: boolean,
  type?: inputType,
  placeholder?: string,
  placeholderColor?: string,
  inputBoxColor?: string,
  onInput: (SyntheticInputEvent<HTMLInputElement>, value: string) => void,
  onBlur?: () => void,
  onClick?: () => void
|};
type State = {|
  showEditor: boolean
|};
class UICoreTextEditable extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showEditor: false
    };
  }

  _showEditor = () =>
    this.setState({
      showEditor: true
    });

  _handleTextClick = () => {
    this._showEditor();
    this.props.onClick && this.props.onClick();
  };

  _handleInputBlur = () => {
    this.setState({ showEditor: false });
    this.props.onBlur && this.props.onBlur();
  };

  render() {
    if (this.state.showEditor) {
      return this.props.allowMultipleLines ? (
        <UICoreTextArea
          autoFocus={this.props.autoFocus}
          autoSelect={this.props.autoSelect}
          width={this.props.width || this.props.maxWidth}
          value={String(this.props.children)}
          onBlur={this._handleInputBlur}
          size={this.props.size}
          onChange={this.props.onInput}
        />
      ) : (
        <UICoreInput
          type={this.props.type}
          onChange={this.props.onInput}
          autoFocus={this.props.autoFocus}
          autoSelect={this.props.autoSelect}
          width={this.props.width || this.props.maxWidth}
          onBlur={this._handleInputBlur}
          size={this.props.size}
          value={String(this.props.children)}
        />
      );
    } else {
      return (
        <UICoreBox
          className="UICoreTextEditable"
          paddingTop="xm"
          paddingBottom="xm"
          paddingLeft="xm"
          paddingRight="xm"
          minHeight="35px"
          shape="rounded"
          hoverable={true}
          onClick={this._handleTextClick}
          width={this.props.width}
          maxWidth={this.props.maxWidth}
        >
          {this.props.children ? (
            <UICoreText
              hexColor={this.props.hexColor}
              color={this.props.color}
              size={this.props.size}
              weight={this.props.weight}
              alignment={this.props.alignment}
              overflow={this.props.overflow}
              hexColor={this.props.hexColor}
              fontFamily={this.props.fontFamily}
            >
              {this.props.children}
            </UICoreText>
          ) : (
            <UICoreText
              hexColor={
                this.props.placeholderColor
                  ? this.props.placeholderColor
                  : "#DBDBDB"
              }
              size={this.props.size}
              weight={this.props.weight}
              alignment={this.props.alignment}
              overflow={this.props.overflow}
              fontFamily={this.props.fontFamily}
            >
              {this.props.placeholder ? this.props.placeholder : ""}
            </UICoreText>
          )}
        </UICoreBox>
      );
    }
  }
}
export default UICoreTextEditable;
