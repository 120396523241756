//@flow
import * as React from "react";
import DefaultErrorPage from "../../Component/DefaultErrorPage";
import { postRequestToNode } from "../../Library/Request";
import { getUserInfo, type userInfoType } from "../../Library/UserInfo";

type State = {|
  hasError: boolean
|};
type InjectedProps = {||};

function getTime() {
  const time = new Date().toTimeString();
  const date = new Date().toDateString();
  return `${date} ${time}`;
}

export function logErrorInfo(errorMessage: string, componentInfo: string) {
  const userInfo: ?userInfoType = getUserInfo();
  postRequestToNode("api/crashReport/logCrashReportToWisptable", {
    time: getTime(),
    errorMessage: errorMessage,
    componentInfo: componentInfo,
    userName: userInfo && userInfo.name,
    url: window && window.location && window.location.href,
    browser: window && window.navigator && window.navigator.userAgent
  });
}

export default function withErrorBoundary<PassedProps: Object>(
  WrappedComponent: React.ComponentType<PassedProps>
): React.ComponentType<$Diff<PassedProps, InjectedProps>> {
  return class Wrapper extends React.Component<PassedProps, State> {
    constructor(props: PassedProps) {
      super(props);
      this.state = { hasError: false };
    }

    componentDidCatch(
      error: Error,
      info: {
        componentStack: string
      }
    ) {
      // Display fallback UI
      this.setState({ hasError: true });
      //log error to wisptbale
      if (process.env.NODE_ENV == "production") {
        logErrorInfo(error.stack, info.componentStack);
      }
    }

    render() {
      if (this.state.hasError) {
        return <DefaultErrorPage />;
      }
      return <WrappedComponent {...this.props} />;
    }
  };
}
