//@flow
import * as React from "react";
import JumpLogic from "../JumpLogic";
import { type questionType } from "../../../../QuestionTypes";
import {
  getVisibleQuestions,
  getQuestionIDIndexMap
} from "../../../States/selectors";
import { connect } from "react-redux";
import {
  type logicJumpType,
  type branchType,
  type conditionType,
  type jointLogicType,
  comperators,
  DEFAULT_END
} from "../../../../../FlowTypes/jumpLogicTypes";
import { toArray, removeDuplicate } from "../../../../../Library/Util";
import { update_jump_logic } from "../../../States/actions";
import { type questionIDType } from "../../../../WispformTypings";

type Props = {|
  questionID: questionIDType,
  questions: Array<questionType>,
  questionIdIndexMap: { [questionIDType]: number },
  updateJumpLogic: (questionIDType, logicJumpType) => void
|};
type State = {||};

const endOfForm = "End of form";
const arrayOffSet = 1;

class JumpLogicContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  _getQuestionIndexByID = (questionID: ?questionIDType): number => {
    return this.props.questions.findIndex(q => q.question_id === questionID);
  };

  _getDisplayTitle = (title: ?string, questionID: ?questionIDType): string => {
    return `${this._getQuestionIndexByID(questionID) + arrayOffSet}.${String(
      title
    )}`;
  };

  _getJumpToTargets = () => {
    const defaultEnd = {
      value: DEFAULT_END,
      display: endOfForm
    };

    return [
      ...this.props.questions.map((question, index) => {
        return {
          value: question.question_id,
          display: this._getDisplayTitle(question.title, question.question_id)
        };
      }),
      defaultEnd
    ];
  };

  _getCurrentQuestion = () => {
    return toArray(this.props.questions).find(
      question => question.question_id === this.props.questionID
    );
  };

  _getJumpLogic = () => {
    const currentQuestion = this._getCurrentQuestion();
    return currentQuestion && currentQuestion.jumpLogic;
  };

  _getJumptoCandidates = () => {
    const jumpLogic = this._getJumpLogic();
    const branches = (jumpLogic && jumpLogic.branches) || [];

    let jumptoCandidates = [];
    jumptoCandidates = [
      ...toArray(branches).map(branch => {
        return branch.jumpToQuestionID === DEFAULT_END
          ? "end"
          : this.props.questionIdIndexMap[branch.jumpToQuestionID] +
              arrayOffSet;
      })
    ];
    const defaultJumpto = jumpLogic && jumpLogic.default;
    if (defaultJumpto) {
      if (defaultJumpto === DEFAULT_END) {
        jumptoCandidates.push("end");
      } else {
        jumptoCandidates.push(
          this.props.questionIdIndexMap[defaultJumpto] + arrayOffSet
        );
      }
    }

    return removeDuplicate(
      jumptoCandidates
        .filter(candidate => candidate === "end" || !isNaN(candidate))
        .map(candidate => (candidate === "end" ? "end" : "#" + candidate))
    );
  };

  _getAddButtonHint = () => {
    return ` To ${this._getJumptoCandidates().join(", ")} `;
  };

  _getDefaultTargetQuestion = () => {
    //default to the next question
    const currentQuestionIndex = this.props.questions.findIndex(
      q => q.question_id === this.props.questionID
    );
    const nextQuestion = this.props.questions[currentQuestionIndex + 1];
    return nextQuestion
      ? {
          display: "Next question",
          value: nextQuestion.question_id
        }
      : {
          display: endOfForm,
          value: DEFAULT_END
        };
  };

  render() {
    return (
      <JumpLogic
        jumpLogic={this._getJumpLogic()}
        jumpToTargets={this._getJumpToTargets()}
        textContent={{
          modalTitle: "Jump Logic",
          addNewBranchButton: "Add a Jump Logic",
          addAnotherBranchButton: "Add Another Jump Logic",
          branchTarget: "Then jump to question:",
          fallbackTarget: "In all other cases, jump to:",
          branchTitlePrefix: "Jump logic",
          toolTip:
            "Use jump logic to redirect users to different questions of the form based on their answer."
        }}
        addButtonHint={this._getAddButtonHint()}
        updateJumpLogic={this.props.updateJumpLogic}
        defaultTargetQuestion={this._getDefaultTargetQuestion()}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    questions: getVisibleQuestions(state.Question),
    questionIdIndexMap: getQuestionIDIndexMap(state.Question)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateJumpLogic: (
      questionID: questionIDType,
      updatedJumpLogic: logicJumpType
    ) => {
      dispatch(update_jump_logic(questionID, updatedJumpLogic));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JumpLogicContainer);
