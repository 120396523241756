import React, { Component } from 'react';
import ScrollBar from './ScrollBar';
import './style.css';

class Scrollable extends Component {
    constructor(props){
        super(props);
        this.id = Scrollable.prototype.currentId;
        this.container_id = "TX_Container_"+this.id;
        this.children_id = "TX_Children_"+this.id;
        this.scrollbar_id = "TX_ScrollBar_"+this.id;
        //currentId is used to give unique id to each scrollable component
        Scrollable.prototype.currentId = Scrollable.prototype.currentId+1;
        //node
        this.Container = null;
        this.Children = null;
    }


    componentDidMount() {
        this.Container = document.getElementById(this.container_id);
        this.Children = document.getElementById(this.children_id);
        // this.Container.addEventListener("mouseenter", this.mouseEnterHandler);
        // this.Container.addEventListener("mouseleave", this.mouseLeaveHandler);
        this.positionChildren();
    }

    componentDidUpdate(prevProps, prevState) {
        this.Container = document.getElementById(this.container_id);
        this.positionChildren();
    }

    positionChildren(){
        let ScrollContainerHeight = this.Container.clientHeight;
        let ChildrenHeight = this.Children.clientHeight;
        if (ChildrenHeight > ScrollContainerHeight){
            this.positionChildrenOnTop();
        }
        else if (this.props.center){
            this.positionChildrenOnCenter();
        }
    }

    positionChildrenOnTop(){
        this.Container.classList.remove('TX-Container-Center');
    }

    positionChildrenOnCenter(){
        this.Container.classList.add('TX-Container-Center');
    }
    
    render() {
        return (
            <div className="TX-Scrollable">
                <div className="TX-Container" id={this.container_id}>
                    <div className="TX-Children" id={this.children_id}>
                        {this.props.children}
                    </div>
                </div>
                <ScrollBar
                    scrollbar_id = {this.scrollbar_id}
                    container_id = {this.container_id}
                    children_id = {this.children_id}
                />
            </div>
        );
    }
}

Scrollable.prototype.currentId = 0;

export default Scrollable;
