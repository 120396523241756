//@flow
import * as React from "react";
import ReactDOM from "react-dom";
import "./style.css";

const LEFT = "left";
const RIGHT = "right";
const TOP = "top";
const BOTTOM = "bottom";
const DURATION = 6000;

type State = {
  showTooltip: boolean
};

type Props = {
  children: React.Node,
  anchor: ?HTMLElement,
  className?: string,
  after_close?: () => any,
  after_unmount?: () => any,
  selfClose?: boolean,
  direction: "left" | "right" | "top" | "bottom"
};

class Tooltip extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showTooltip: true
    };
  }

  componentDidMount() {
    if (this.props.selfClose) {
      setTimeout(() => {
        this.setState({
          showTooltip: false
        });
        this.props.after_close && this.props.after_close();
      }, DURATION);
    }
  }

  componentWillUnmount() {
    this.props.after_unmount && this.props.after_unmount();
  }

  _getDirectionClassName = () => {
    switch (this.props.direction) {
      case LEFT:
        return "Tooltip-Left";

      case RIGHT:
        return "Tooltip-Right";

      case TOP:
        return "Tooltip-Top";

      case BOTTOM:
        return "Tooltip-Bottom";

      default:
        return "Tooltip-Left";
    }
  };

  _handleClose = (e: SyntheticEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      showTooltip: false
    });
    this.props.after_close && this.props.after_close();
  };

  render() {
    if (this.props.anchor && this.state.showTooltip) {
      return ReactDOM.createPortal(
        <div
          onClick={(e: SyntheticEvent<any>) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className={`Tooltip ${
            this.props.className ? this.props.className : ""
          }`}
        >
          <div className="Tooltip-Content">{this.props.children}</div>
          <div onClick={this._handleClose} className="Tooltip-Close">
            <i className="ion-close" />
          </div>
          <div
            className={`Tooltip-Triangle ${this._getDirectionClassName()}`}
          />
        </div>,
        // $FlowFixMe @taoxiang already checked in if statement
        this.props.anchor
      );
    } else {
      return <div />;
    }
  }
}

export default Tooltip;
